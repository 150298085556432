import { Box, Button, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import ChatIcon from '@material-ui/icons/Chat';
import PhoneIcon from '@material-ui/icons/Phone';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { emails } from '../../service/config';
import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';

const useStyles = makeStyles((theme) => ({
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  paddingTop2: {
    paddingTop: theme.spacing(2),
  },
  prefDescriptionOff: {
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  prefDescriptionOn: {
    marginBottom: theme.spacing(8),
    whiteSpace: 'pre-line',
  },
  prefHeaderContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const AutoRenewalPreference: React.FC = observer(function AutoRenewalPreference() {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount', {});
  const {
    dashboardStore: {
      insurance: {
        auto: { mainPolicy },
      },
    },
  } = useStores();

  return (
    <Grid className={classes.marginTop2} container>
      <Grid className={classes.prefHeaderContainer} item>
        <Typography variant="h3"> {t('drawer.autoRenewalPreference.preferenceHeader')} </Typography>
      </Grid>
      <Grid container>
        <Grid item>
          {mainPolicy.autoRenewalAuthorizedInd ? (
            <Typography className={classes.prefDescriptionOn} variant="body2">
              {t('drawer.autoRenewalPreference.prefDescriptionOn')}
            </Typography>
          ) : (
            <Typography className={classes.prefDescriptionOff} variant="body2">
              {t('drawer.autoRenewalPreference.prefDescriptionOff')}
            </Typography>
          )}
        </Grid>
        <Grid container item>
          <Typography variant="body2">
            {t('drawer.autoRenewalPreference.pleaseContact')}{' '}
            <Link href={`mailto:${emails.customerServices}`} noWrap variant="body2">
              {emails.customerServices}
            </Link>
          </Typography>
          <Box sx={{ display: 'none' }}>
            <Grid container direction="column" item lg={4} xs={5}>
              <Grid item>
                <Button
                  color="primary"
                  data-testid="live-chat-button"
                  onClick={() => {
                    eventTrack('Live Chat Button Clicked', { type: TRACK_TYPE.CHAT });
                  }}
                  startIcon={<ChatIcon />}
                  variant="contained"
                >
                  {t('drawer.autoRenewalPreference.liveChat')}
                </Button>
                <Grid className={classes.paddingTop2} item>
                  <Typography variant="body2">{t('drawer.autoRenewalPreference.24hrsOpen')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid alignItems="flex-end" container direction="column" item lg={8} xs={7}>
              <Grid item>
                <Button
                  color="secondary"
                  data-testid="telephone-button"
                  onClick={() => {
                    eventTrack('Telephone Button Clicked');
                  }}
                  startIcon={<PhoneIcon />}
                  variant="outlined"
                >
                  {t('drawer.autoRenewalPreference.phoneNumber')}
                </Button>
              </Grid>
              <Grid alignItems="flex-end" className={classes.paddingTop2} container direction="column" item>
                <Typography variant="body2">{t('drawer.autoRenewalPreference.openingHours')}</Typography>
                <Typography variant="body2">{t('drawer.autoRenewalPreference.monToFri')}</Typography>
                <Typography variant="body2">{t('drawer.autoRenewalPreference.satToSun')}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
});
