import { Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { EDIT_STEPS, LOOKUP } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';

import { ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListRemoveDriver: React.FC<ReviewListType> = observer(function ReviewListRemoveDriver({ onEdit }) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          mainDriver,
          policySelected: { drivers: allDrivers },
        },
      },
    },
    mtaStore: {
      pendedMTA: { driverFullName, removedDrivers, drivers },
    },
  } = useStores();

  const classes = useListStyles({ isDesktop });

  const deletedDrivers = allDrivers.filter((driver) => removedDrivers.includes(driver.uuid));
  const newMainDriver = drivers.find((driver) => driver.mainDriver === true);
  const hasNewMainDriver = mainDriver.uuid !== newMainDriver?.uuid;
  return (
    <>
      {deletedDrivers.length > 0 && (
        <>
          <BlueTableContainer title={t('MTAJourney.reviewAll.driverChanges')} className={classes.root}>
            <>
              <Grid container className={classes.columnsContainer} direction="column" justifyContent="space-between">
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewRemoveDriver.removeDriver')}</Typography>
                    <Typography variant="h4">
                      {deletedDrivers.map((driver, idx) => {
                        return (
                          <Fragment key={idx}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: driverFullName(driver),
                              }}
                            />
                            <br />
                          </Fragment>
                        );
                      })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_REMOVE} />
                  </Grid>
                </Grid>

                {hasNewMainDriver && (
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.mainDriver')}</Typography>
                      {newMainDriver && (
                        <Typography variant="h4">{`${getLookup(
                          LOOKUP.TITLE,

                          newMainDriver.person.titleCode,
                        )} ${driverFullName(newMainDriver)}`}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton
                        onEdit={onEdit}
                        onEditType={EDIT_STEPS.DRIVER_MAIN_CHANGED}
                        uuid={deletedDrivers[0].uuid}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          </BlueTableContainer>
        </>
      )}
    </>
  );
});
