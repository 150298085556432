import { Divider, Grid, Link, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { EDIT_STEPS, LOOKUP, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../../../../service/util/customHooks/useDecodedLookups';
import { useFetchQuote } from '../../carDetails/utils';
import { MTAFooter } from '../components/footer';
import { useStepperContext } from '../utils/stepperContext';

import { EditCarModified } from './editCarModified';
import { EditCarOvernight } from './editCarOvernight';
import { EditCarOwnership } from './editCarOwnership';
import { EditClassOfUse } from './editClassOfUse';
import { EditDateOfBirth } from './editDateOfBirth';
import { EditDaytimeLocation } from './editDaytimeLocation';
import { EditDriverHistory } from './editDriverHistory';
import { EditDriverLicense } from './editDriverLicense';
import { EditDriverMaritalStatus } from './editDriverMaritalStatus';
import { EditDriverName } from './editDriverName';
import { EditDriverOccupation } from './editDriverOccupation';
import { EditDriverOtherCars } from './editDriverOtherCars';
import { EditDriverRelationship } from './editDriverRelationship';
import { EditHomeAddress } from './editHomeAddress';
import { EditInsuredMaritalStatus } from './editInsuedMaritalStatus';
import { EditInsuredName } from './editInsuredName';
import { EditInsuredNumberOfCars } from './editInsuredNumberOfCars';
import { EditInsuredNumberOfChildren } from './editInsuredNumberOfChildren';
import { EditLegalOwner } from './editLegalOwner';
import { EditMainDriver } from './editMainDriver';
import { EditRegisteredKeeper } from './editRegisteredKeeper';
import { EditRemoveDriver } from './editRemoveDriver';
import { EditResidentialStatus } from './editResidentialStatus';
import { EditSecurityFeatures } from './editSecurityFeatures';
import { EditStartDate } from './editStartDate';
import { ReviewListAddDriver } from './reviewListAddDriver';
import { ReviewListChangeOfCar } from './reviewListChangeOfCar';
import { ReviewListChangeOfRegistration } from './reviewListChangeOfRegistration';
import { ReviewListChangeOfUse } from './reviewListChangeOfUse';
import { ReviewListRemoveDriver } from './reviewListRemoveDriver';
import { ReviewListStartDate } from './reviewListStartDate';
import { ReviewListUpdateDriver } from './reviewListUpdateDriver';
import { ReviewListYourDetails } from './reviewListYourDetails';

export interface PageValues {
  defaultOption: string;
  lookup: LOOKUP;
}
export interface EditPageProps {
  handleBack: () => void;
  uuid?: string;
}

export interface ReviewListType {
  onEdit: (editType: string) => void;
}

interface ReviewAllEditButtonProps {
  compId?: string;
  onEdit: (editType: string, uuid?: string) => void;
  onEditType?: string;
  uuid?: string;
}

export const useListStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  columnsContainer: {
    flexWrap: ({ isDesktop }) => (isDesktop ? 'wrap' : 'nowrap'),
    gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
    padding: spacing(2, 3),
  },
  divider: { marginLeft: spacing(3), marginRight: spacing(3) },
  editLink: {
    cursor: 'pointer',
  },
  footer: {
    marginTop: spacing(3),
  },
  root: {
    marginTop: spacing(2),
  },
}));

export const ReviewAllEditButton: React.FC<ReviewAllEditButtonProps> = ({ onEdit, onEditType, uuid, compId }) => {
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const classes = useListStyles({ isDesktop });

  const testId = compId ?? `reviewChangesEdit-${onEditType?.toLowerCase() ?? 'unspecifiedEditType'}`;

  return (
    <Link
      className={classes.editLink}
      data-testid={testId}
      onClick={() => {
        onEdit(onEditType ?? '', uuid);
      }}
    >
      Edit
    </Link>
  );
};

export const ReviewAllDivider: React.FC = () => {
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const classes = useListStyles({ isDesktop });
  return <Divider orientation="horizontal" className={classes.divider} />;
};

export const ReviewAll: React.FC = observer(function ReviewAll() {
  const { t } = useTranslation('myAccount');
  const { handleFetchQuote } = useFetchQuote();
  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ options: ['vehicle', 'insured'] });
  const [currentEdit, setCurrentEdit] = useState('');
  const [currentUuid, setCurrentUuid] = useState('');
  const { setTitle } = useStepperContext();
  const {
    interfaceStore: { isDesktop, hasQuoteError },
  } = useStores();
  const classes = useListStyles({ isDesktop });
  const history = useHistory();

  useEffect(() => {
    setTitle(t('MTAJourney.reviewAll.title'));
    window.scrollTo(0, 0);
  }, []);

  const onEdit = (editType: string, uuid = '') => {
    setCurrentEdit(editType);
    setCurrentUuid(uuid);
  };

  const onBack = () => {
    setCurrentEdit('');
    setTitle(t('MTAJourney.reviewAll.title'));
  };

  const isValid = useCallback((): boolean => {
    if (hasQuoteError) {
      history.push(ROUTE.QUOTE_INELIGIBLE);
      return false;
    }
    return true;
  }, [hasQuoteError, history, ROUTE]);

  return (
    <Grid container direction="column">
      {isDecodedLookupsFetched && (
        <>
          {currentEdit === EDIT_STEPS.START_DATE && <EditStartDate handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.CLASS_OF_USE && <EditClassOfUse handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.SOCIAL_MILEAGE_CODE && <EditClassOfUse handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.BUSINESS_MILEAGE_CODE && <EditClassOfUse handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.PURCHASE_DATE && <EditCarOwnership handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.ESTIMATED_VALUE && <EditCarOwnership handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.CAR_OWNER && <EditCarOwnership handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.SECURITY_DEVICE_DECLARED && <EditSecurityFeatures handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.TRACKING_DEVICE_FITTED && <EditSecurityFeatures handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.MODIFICATIONS && <EditCarModified handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.OVERNIGHT_LOCATION && <EditCarOvernight handleBack={onBack} />}{' '}
          {currentEdit === EDIT_STEPS.DAYTIME_LOCATION && <EditDaytimeLocation handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.LEGAL_OWNER && <EditLegalOwner handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.KEEPER && <EditRegisteredKeeper handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.RESIDENTIAL_STATUS && <EditResidentialStatus handleBack={onBack} />}
          {/* Personal details edits */}
          {currentEdit === EDIT_STEPS.FULL_NAME && <EditInsuredName handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.MARITAL_STATUS && <EditInsuredMaritalStatus handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.NUMBER_OF_CHILDREN && <EditInsuredNumberOfChildren handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.CARS_IN_HOUSEHOLD && <EditInsuredNumberOfCars handleBack={onBack} />}
          {currentEdit === EDIT_STEPS.ADDRESS && <EditHomeAddress handleBack={onBack} />}
          {/* Driver edits */}
          {currentEdit === EDIT_STEPS.DRIVER_NAME && <EditDriverName handleBack={onBack} uuid={currentUuid} />}
          {currentEdit === EDIT_STEPS.DATE_OF_BIRTH && <EditDateOfBirth handleBack={onBack} uuid={currentUuid} />}
          {currentEdit === EDIT_STEPS.UK_RESIDENT_SINCE_BIRTH && (
            <EditDateOfBirth handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_MARITAL_STATUS && (
            <EditDriverMaritalStatus handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_RELATIONSHIP && (
            <EditDriverRelationship handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_OCCUPATION && (
            <EditDriverOccupation handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_LICENCE_TYPE && (
            <EditDriverLicense handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_OTHER_CARS && (
            <EditDriverOtherCars handleBack={onBack} uuid={currentUuid} />
          )}
          {currentEdit === EDIT_STEPS.DRIVER_HISTORY && <EditDriverHistory handleBack={onBack} uuid={currentUuid} />}
          {currentEdit === EDIT_STEPS.DRIVER_REMOVE && <EditRemoveDriver handleBack={onBack} uuid={currentUuid} />}
          {currentEdit === EDIT_STEPS.DRIVER_MAIN_CHANGED && <EditMainDriver handleBack={onBack} uuid={currentUuid} />}
          {/* Review components */}
          {currentEdit === '' && (
            <>
              <Typography variant="body1">{t('MTAJourney.reviewAll.content')}</Typography>
              <ReviewListStartDate onEdit={onEdit} />
              <ReviewListChangeOfCar onEdit={onEdit} />
              <ReviewListChangeOfUse onEdit={onEdit} />
              <ReviewListChangeOfRegistration onEdit={onEdit} />
              <ReviewListYourDetails onEdit={onEdit} />
              <ReviewListAddDriver onEdit={onEdit} />
              <ReviewListUpdateDriver onEdit={onEdit} />
              <ReviewListRemoveDriver onEdit={onEdit} />
              <Typography variant="body1" className={classes.footer}>
                {t('MTAJourney.ReviewChanges.footer')}
              </Typography>
              <MTAFooter
                backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_REVIEW_CHANGES}
                cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_REVIEW_CHANGES}
                primaryButton={{
                  buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_REVIEW_CHANGES,
                  handleContinue: handleFetchQuote,
                  text: t('MTAJourney.button.quote'),
                }}
                isValid={isValid}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
});
