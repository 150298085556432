import { ButtonBase, CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { track } from '../../lib/optimizely';
import { COMPONENT_ID, getDeviceSession } from '../../service/analytics';
import { PANEL_TYPE } from '../../service/constant';
import { IDriver } from '../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';
import SelfService from '../../service/util/selfService';
import IconSure from '../common/icon';

export const insuredUseStyles = makeStyles((theme) => ({
  nameFontWeight: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    textAlign: 'left',
  },
  namesOnMobile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    gridColumnGap: theme.spacing(0.5),
    wordBreak: 'break-word',
  },
  profileFontWeight: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    opacity: '0.7',
    textAlign: 'left',
  },
  widthButtonBase: {
    '& > div': {
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '60vw',
    },
    display: 'block',
    height: '100%',
    width: '100%',
  },
}));

export const DriverCard: React.FC<{ driver: IDriver }> = observer(function DriverCard({ driver }) {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();
  const classes = insuredUseStyles();

  const {
    dashboardStore: {
      insurance: {
        auto: {
          mainDriver,
          policySelected: { policyStatus },
          setSelectedDriverUuid,
        },
      },
    },
    interfaceStore: { panel },
  } = useStores();

  const isMainDriver = driver.uuid === mainDriver.uuid;
  const driverType = isMainDriver ? t('driverCards.typePolicy') : t('driverCards.typeAdditional');
  const icon = isMainDriver ? 'driver' : 'add-driver';
  const trackEventKey = isMainDriver
    ? 'My_Account_policy_holder_drawer_pageview'
    : 'My_Account_additional_driver_drawer_pageview';
  const isPolicyExpired = SelfService.isPolicyExpired(policyStatus);

  const handleOpenPanel = () => {
    if (isPolicyExpired) return;

    track(trackEventKey);
    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.DRIVER_CARD,
      item: driverType,
      label: isMainDriver ? t('driverCards.typePolicy') : t('driverCards.typeAdditional'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });
    setSelectedDriverUuid(driver.uuid);
    panel.openPanel({
      title: driverType,
      type: PANEL_TYPE.DRIVER,
    });
  };

  return (
    <ButtonBase className={classes.widthButtonBase} onClick={handleOpenPanel}>
      <CommonContainer color="secondary" variant="outlined">
        <Grid container justifyContent="space-between">
          <Grid item>
            <IconSure color="secondary" icon={icon} />
          </Grid>
          <Grid item>{!isPolicyExpired && <IconSure color="secondary" icon="chevron-right" />}</Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography className={classes.profileFontWeight} variant="body2">
              {driverType}
            </Typography>
          </Grid>
          <Grid className={classes.namesOnMobile} container item>
            <Typography className={classes.nameFontWeight} variant="h4">
              {driver.person.fullName()}
            </Typography>
          </Grid>
        </Grid>
      </CommonContainer>
    </ButtonBase>
  );
});
