import { Instance, types } from 'mobx-state-tree';

import { DateTime } from '../../types/dateTime';

const string = types.optional(types.string, '');
const stringNull = types.maybeNull(string);
const numberNull = types.maybeNull(types.number);
const boolNull = types.maybeNull(types.boolean);
const numberOptional = types.optional(types.number, 0);

export const Person = types
  .model('Person', {
    carsInHousehold: string,
    dateOfBirth: types.maybeNull(types.string),
    employmentStatusCode: types.maybeNull(types.string),
    firstName: string,
    genderCode: types.maybeNull(types.string),
    hasSecondaryOccupation: boolNull,
    maritalStatusCode: string,
    numberOfChildren: stringNull,
    primaryOccupationCode: types.maybeNull(types.string),
    primaryOccupationIndustryCode: types.maybeNull(types.string),
    residencyStatusCode: string,
    residentSinceBirth: boolNull,
    residentSinceMonthsCode: types.optional(types.string, ''),
    residentSinceYearsCode: string,
    secondaryOccupationCode: types.maybeNull(types.string),
    secondaryOccupationIndustryCode: types.maybeNull(types.string),
    surname: string,
    titleCode: string,
  })
  .views(({ firstName, surname }) => ({
    fullName() {
      return `${firstName} ${surname}`;
    },
  }));

export const Licence = types.model('Licence', {
  licenceDate: stringNull,
  licenceTypeCode: string,
  licenseDateMonthsCode: string,
  licenseDateYearsCode: string,
  licenseRestrictionCode: string,
});

export const Claims = types.model('Claims', {
  claimCode: string,
  claimDate: stringNull,
  claimNumber: stringNull,
  claimStatus: string,
});

export const Convictions = types.model('Convictions', {
  banLength: numberNull,
  convictionCode: string,
  convictionDate: stringNull,
  licenceSuspended: boolNull,
});

export const Driver = types.model('Driver', {
  claims: types.optional(types.array(Claims), () => []),
  claimsFive: boolNull,
  convictions: types.optional(types.array(Convictions), () => []),
  declinedOnSpecialTerms: boolNull,
  driverSpecificExcess: numberNull,
  licence: types.optional(Licence, () => Licence.create()),
  mainDriver: boolNull,
  person: types.optional(Person, () => Person.create()),
  prosecutionsOnOffences: boolNull,
  relationshipToProposerCode: string,
  unspentConvictions: boolNull,
  useOfOtherCarsCode: string,
  uuid: types.optional(types.identifier, ''),
});

export const DriverAssignments = types.model('DriverAssignments', {
  driverUuid: string,
  mainDriverInd: boolNull,
});

export const VehiclePackCoverages = types.model('VehiclePackCoverages', {
  coverageCd: string,
  gwtAnnualPremium: numberNull,
  nwtAnnualPremium: numberNull,
  packType: string,
});

export const BaseCoverage = types.model('BaseCoverage', {
  coverageCd: string,
  deductibleAmount: numberNull,
  limitAmount: numberNull,
  limitUnit: stringNull,
});

export const PackSubCoverages = types.model('PackSubCoverages', {
  excess: stringNull,
  limit: stringNull,
  limitUnit: stringNull,
  subCoverageCode: string,
});

export const PackCoverages = types.model('PackCoverages', {
  coverageCode: string,
  subCoverages: types.optional(types.array(PackSubCoverages), () => []),
});

export const PackPremium = types.model('PackPremium', {
  premium: numberNull,
  premiumWithTax: numberNull,
  taxAmount: numberNull,
});

export const BasePackPremium = types.model('BasePackPremium', {
  premiumWithTax: types.optional(types.number, 0),
});

export const PackOptions = types.model('PackOptions', {
  code: string,
  coverages: types.optional(types.maybeNull(types.array(PackCoverages)), () => []),
  name: string,
  premium: types.optional(PackPremium, () => PackPremium.create()),
  selected: boolNull,
});

export const OptionPacks = types.model('OptionPacks', {
  code: string,
  name: string,
  options: types.optional(types.array(PackOptions), () => []),
});

export const BasePack = types.model('BasePack', {
  coexistencePacks: types.optional(types.array(PackOptions), () => []),
  optionPacks: types.optional(types.array(OptionPacks), () => []),
  premium: types.optional(BasePackPremium, () => BasePackPremium.create()),
});

export const PolicyDocumentHistory = types.model('PolicyDocumentHistory', {
  documentId: string,
  documentTypeCode: string,
  expirationDate: DateTime,
  fileName: string,
  policyRevisionNumber: types.optional(types.number, 0),
});

export const PolicyDocument = types.model('Document', {
  creationDate: DateTime,
  description: string,
  documentId: string,
  documentTypeCode: string,
  fileName: string,
  history: types.optional(types.array(PolicyDocumentHistory), () => []),
  policyRevisionNumber: types.optional(types.number, 0),
  upgradeDoc: boolNull,
});

export const InsuredAddress = types.model('InsuredAddress', {
  country: string,
  county: string,
  houseName: stringNull,
  houseNumber: string,
  locality: stringNull,
  organisation: stringNull,
  postalTown: string,
  postcode: string,
  street: string,
  subPremises: string,
});

export const RenewalModification = types.model('Modification', {
  modificationCd: string,
  uuid: string,
});

export const Modification = types.model('Modification', {
  modificationCode: string,
  uuid: string,
});

export const Vehicle = types.model('Vehicle', {
  bodyTypeCode: string,
  businessMileageCode: types.maybeNull(types.string),
  carModificationsInd: types.maybeNull(types.boolean),
  classOfUse: string,
  coverType: stringNull,
  coverages: types.optional(types.array(BaseCoverage), () => []),
  daytimeLocation: string,
  driverAssignments: types.optional(types.array(DriverAssignments), () => []),
  engineSize: numberOptional,
  estimatedValue: numberNull,
  fuelTypeCode: string,
  legalOwner: string,
  make: string,
  manufactureYear: string,
  model: string,
  modifications: types.optional(types.array(Modification), () => []),
  ncdProtected: types.optional(types.boolean, false),
  ncdYears: string,
  notPurchasedYet: types.optional(types.boolean, false),
  numberOfDoors: numberOptional,
  numberOfSeats: numberOptional,
  overnightAddress: types.optional(InsuredAddress, () => InsuredAddress.create()),
  overnightLocation: string,
  overnightPostcode: types.optional(types.string, ''),
  ownRepairerExcess: numberNull,
  packCoverages: types.optional(types.array(VehiclePackCoverages), () => []),
  purchaseDate: types.maybeNull(string),
  registeredKeeper: string,
  residentialStatus: string,
  sameOvernightAddress: types.optional(types.boolean, false),
  securityDeviceDeclared: string,
  socialMileageCode: types.optional(string, ''),
  subCoverages: types.optional(types.array(BaseCoverage), () => []),
  totalDeclaredMileageCode: string,
  trackingDeviceFitted: boolNull,
  transmissionTypeCode: string,
  voluntaryExcessCode: string,
  vrm: string,
  windscreenRepairExcess: numberOptional,
  windscreenReplacementExcess: numberOptional,
});

export const TransactionsCardDetails = types.model('TransactionsCardDetails', {
  creditCardType: types.maybeNull(types.string),
  maskedCardNumber: types.string,
});

export const Transaction = types.model('Transaction', {
  amount: types.number,
  paymentMethod: TransactionsCardDetails,
  transactionDate: DateTime,
  type: types.string,
});

export const Payments = types.model('Payments', {
  billablePremium: types.optional(types.number, 0),
  paymentPlan: string,
  transactions: types.optional(types.array(Transaction), () => []),
});

export const Insured = types.model('Insured', {
  address: types.optional(InsuredAddress, () => InsuredAddress.create()),
  person: types.optional(Person, () => Person.create()),
});

export const TIAVehicle = types.model('TIA Vehicle', {
  make: string,
  model: string,
  vrm: string,
});

export const CreditCardDetails = types.model('Credit card details', {
  cardHolderName: string,
  expirationDate: string,
  number: stringNull,
  type: string,
});
export const AutoRenewalOption = types.model('Auto renewal option', {
  creditCardDetails: types.optional(CreditCardDetails, () => CreditCardDetails.create()),
  type: string,
});

export const PremiumBreakdown = types.model('PremiumBreakdown', {
  interest: numberOptional,
});

export const PremiumNetBreakdown = types.model('PremiumNetBreakdown', {
  net: numberOptional,
});

export const InstallmentSchedule = types.model('Installment Schedule', {
  amount: numberNull,
  dueDate: string,
});

export const AddOns = types.model('Addons Cost', {
  name: string,
  premiumBreakdown: PremiumNetBreakdown,
});

export const BasePackNetBreakdown = types.model('BasePackNetBreakdown', {
  premiumBreakdown: types.optional(PremiumNetBreakdown, () => PremiumNetBreakdown.create()),
});

export const PersonRenewal = types
  .model('PersonRenewal', {
    carsInHouseholdCd: string,
    dateOfBirth: types.maybeNull(types.string),
    employmentStatusCd: types.maybeNull(types.string),
    firstName: string,
    genderCd: types.maybeNull(types.string),
    maritalStatusCd: string,
    numberOfChildrenCd: string,
    primaryOccupationCd: types.maybeNull(types.string),
    primaryOccupationIndustryCd: types.maybeNull(types.string),
    residencyStatusCd: string,
    residentSinceBirth: types.maybeNull(types.boolean),
    residentSinceMonthsCd: string,
    residentSinceYearsCd: string,
    secondaryOccupation: types.maybeNull(types.boolean),
    secondaryOccupationCd: types.maybeNull(types.string),
    secondaryOccupationIndustryCd: types.maybeNull(types.string),
    surname: string,
    titleCd: string,
  })
  .views(({ firstName, surname }) => ({
    fullName() {
      return `${firstName} ${surname}`;
    },
  }));

export const RenewalLicense = types.model('RenewalLicense', {
  licenceDate: stringNull,
  licenceTypeCd: string,
  licenseDateMonthsCd: string,
  licenseDateYearsCd: string,
  licenseRestrictionCd: string,
});

export const ClaimsRenewal = types.model('ClaimsRenewal', {
  claimDate: stringNull,
  claimNumber: stringNull,
  claimStatus: string,
  claimTypeCd: string,
});

export const ConvictionsRenewal = types.model('ConvictionsRenewal', {
  banLength: numberNull,
  convictionDate: stringNull,
  convictionTypeCd: string,
  licenceSuspended: boolNull,
  penaltyPoints: numberNull,
});

export const RenewalDriver = types.model('RenewalDriver', {
  claims: types.optional(types.array(ClaimsRenewal), () => []),
  claimsFive: boolNull,
  convictions: types.optional(types.array(ConvictionsRenewal), () => []),
  declinedOnSpecialTerms: boolNull,
  driverSpecificExcess: numberNull,
  licence: types.optional(RenewalLicense, () => RenewalLicense.create()),
  main: boolNull,
  person: types.optional(PersonRenewal, () => PersonRenewal.create()),
  prosecutionsOnOffences: boolNull,
  relationshipToProposerCd: string,
  unspentConvictions: boolNull,
  useOfOtherCarsCd: string,
  uuid: types.optional(types.identifier, ''),
});

export const RenewalVehicle = types.model('RenewalVehicle', {
  businessMileageCd: types.maybeNull(types.string),
  carModifications: types.maybeNull(types.boolean),
  classOfUseCd: string,
  coverTypeCd: string,
  coverages: types.optional(types.array(BaseCoverage), () => []),
  daytimeLocationCd: string,
  estimatedValue: numberNull,
  legalOwnerCd: string,
  make: string,
  model: string,
  modelShort: string,
  modifications: types.optional(types.array(RenewalModification), () => []),
  ncdProtected: types.optional(types.boolean, false),
  ncdYearsCd: string,
  notPurchasedYet: types.optional(types.boolean, false),
  overnightAddress: types.optional(InsuredAddress, () => InsuredAddress.create()),
  overnightLocationCd: string,
  overnightPostcode: types.optional(types.string, ''),
  ownRepairerExcess: numberNull,
  packCoverages: types.optional(types.array(VehiclePackCoverages), () => []),
  purchaseDate: types.maybeNull(string),
  registeredKeeperCd: string,
  sameOvernightAddress: types.optional(types.boolean, false),
  securityDeviceDeclaredCd: string,
  socialMileageCd: string,
  subCoverages: types.optional(types.array(BaseCoverage), () => []),
  trackingDeviceFitted: boolNull,
  voluntaryExcessCd: string,
  vrm: string,
});

export const RenewalInsured = types.model('RenewalInsured', {
  address: types.optional(InsuredAddress, () => InsuredAddress.create()),
  person: types.optional(PersonRenewal, () => PersonRenewal.create()),
});

export const RenewalPendingMTAQuote = types.model('PolicyRenewalDetails', {
  addons: types.optional(types.array(AddOns), () => []),
  addonsCost: numberOptional,
  apr: numberOptional,
  basePack: types.optional(BasePackNetBreakdown, () => BasePackNetBreakdown.create()),
  brandCd: string,
  changedDrivers: types.optional(types.array(types.string), []),
  currentYearMonthlyCost: numberOptional,
  currentYearTotalCost: numberOptional,
  drivers: types.optional(types.array(RenewalDriver), () => []),
  inceptionDate: DateTime,
  installmentSchedule: types.optional(types.array(InstallmentSchedule), () => []),
  insured: types.optional(RenewalInsured, () => RenewalInsured.create()),
  lastActionReasonCd: string,
  numberOfInstallments: numberOptional,
  policyCostWithoutAddons: numberOptional,
  policyCostWithoutInterest: numberOptional,
  policyNumber: string,
  policyStatusCd: string,
  premiumBreakdown: types.optional(PremiumBreakdown, () => PremiumBreakdown.create()),
  previousYearMonthlyCost: numberOptional,
  previousYearTotalCost: numberOptional,
  removedDrivers: types.optional(types.array(types.string), []),
  selectedDriverUUID: string,
  startDate: DateTime,
  transactionTypeCd: string,
  vehicle: types.optional(RenewalVehicle, () => RenewalVehicle.create()),
  version: numberOptional,
});

export const AutoPolicyRenewalQuoteDetails = types.model('PolicyRenewalDetails', {
  addons: types.optional(types.array(AddOns), () => []),
  addonsCost: numberOptional,
  apr: numberOptional,
  basePack: types.optional(BasePackNetBreakdown, () => BasePackNetBreakdown.create()),
  brandCd: string,
  changedDrivers: types.optional(types.array(types.string), []),
  currentYearMonthlyCost: numberOptional,
  currentYearTotalCost: numberOptional,
  drivers: types.optional(types.array(RenewalDriver), () => []),
  inceptionDate: DateTime,
  installmentSchedule: types.optional(types.array(InstallmentSchedule), () => []),
  insured: types.optional(RenewalInsured, () => RenewalInsured.create()),
  lastActionReasonCd: string,
  numberOfInstallments: numberOptional,
  pendingMTAQuote: types.maybeNull(RenewalPendingMTAQuote),
  policyCostWithoutAddons: numberOptional,
  policyCostWithoutInterest: numberOptional,
  policyNumber: string,
  policyStatusCd: string,
  premiumBreakdown: types.optional(PremiumBreakdown, () => PremiumBreakdown.create()),
  previousYearMonthlyCost: numberOptional,
  previousYearTotalCost: numberOptional,
  removedDrivers: types.optional(types.array(types.string), []),
  selectedDriverUUID: string,
  startDate: DateTime,
  transactionTypeCd: string,
  vehicle: types.optional(RenewalVehicle, () => RenewalVehicle.create()),
  version: numberOptional,
});

export const AutoPolicy = types.model('AutoPolicy', {
  adminFee: numberNull,
  autoRenewal: boolNull,
  autoRenewalAuthorizedInd: boolNull,
  autoRenewalOption: types.optional(AutoRenewalOption, () => AutoRenewalOption.create()),
  brand: string,
  cancellationFee: numberNull,
  cancelledOnFraud: boolNull,
  channelCd: string,
  coverageCd: string,
  customerNumber: string,
  declinedOnSpecialTerms: boolNull,
  documents: types.optional(types.array(PolicyDocument), () => []),
  drivers: types.optional(types.array(Driver), () => []),
  effectiveDate: DateTime,
  esrProductTypeCd: string,
  expiration: DateTime,
  expirationDate: string,
  fetchingRenewalData: boolNull,
  futureMtaEffectiveDate: DateTime,
  futureRenewalEffectiveDate: DateTime,
  hasPendingInstallments: boolNull,
  homeAddress: types.optional(InsuredAddress, () => InsuredAddress.create()),
  insured: types.optional(Insured, () => Insured.create()),
  lastCompletedMtaDate: types.optional(DateTime, null),
  loadingFailed: boolNull,
  migrated: boolNull,
  paymentPlan: string,
  payments: types.optional(Payments, () => Payments.create()),
  policyNumber: string,
  policyRenewalQuoteDetails: types.optional(AutoPolicyRenewalQuoteDetails, () =>
    AutoPolicyRenewalQuoteDetails.create(),
  ),
  policyStatus: string,
  policyStatusCd: string,
  policyTypeCd: string,
  prosecutionsOnOffences: boolNull,
  renewalDocuments: types.optional(types.array(PolicyDocument), () => []),
  renewalStatusRequestPending: boolNull,
  shouldUpdatePolicy: types.optional(types.boolean, false),
  tiaPolicy: types.optional(types.boolean, false),
  tiaVehicle: types.optional(TIAVehicle, () => TIAVehicle.create()),
  timedPolicyStatusCd: string,
  totalPremium: types.optional(types.number, 0),
  transactionType: types.optional(types.string, ''),
  transactionTypeCd: types.optional(types.string, ''),
  unspentConvictions: boolNull,
  vehicles: types.optional(types.array(Vehicle), () => []),
});

export type IAutoPolicy = Instance<typeof AutoPolicy>;
export type IAutoPolicyRenewalQuoteDetails = Instance<typeof AutoPolicyRenewalQuoteDetails>;
export type IRenewalPendingMTAQuote = Instance<typeof RenewalPendingMTAQuote>;
export type IVehicle = Instance<typeof Vehicle>;
export type IVehiclePackCoverages = Instance<typeof VehiclePackCoverages>;
export type IDriver = Instance<typeof Driver>;
export type IPolicyDocument = Instance<typeof PolicyDocument>;
export type IInstallmentSchedule = Instance<typeof InstallmentSchedule>;
export type IBasePack = Instance<typeof BasePack>;
export type IPackOptions = Instance<typeof PackOptions>;
export type IPackSubCoverages = Instance<typeof PackSubCoverages>;
export type IClaims = Instance<typeof Claims>;
export type IConvictions = Instance<typeof Convictions>;
export type IInsuredAddress = Instance<typeof InsuredAddress>;
export type IInsured = Instance<typeof Insured>;
export type IOptionPacks = Instance<typeof OptionPacks>;
export type IPayments = Instance<typeof Payments>;
export type ITransaction = Instance<typeof Transaction>;
export type IBasePackPremium = Instance<typeof BasePackPremium>;
export type IModification = Instance<typeof Modification>;
export type IRenewalModification = Instance<typeof RenewalModification>;
export type IPerson = Instance<typeof Person>;
export type IDriverAssignment = Instance<typeof DriverAssignments>;
export type ILicence = Instance<typeof Licence>;
export type IAutoRenewalOption = Instance<typeof AutoRenewalOption>;
export type IBaseCoverage = Instance<typeof BaseCoverage>;
