import { ReactSDKClient, createInstance, setLogLevel } from '@optimizely/react-sdk';

import { retrieveCookieSettingsFromCookies } from '../../component/cookies/CookieWrapper';
import { uaParser } from '../../service/analytics';
import config from '../../service/config';
import { getDateTimeNow } from '../../service/util/dateUtils';

export let optimizelyClient: undefined | ReactSDKClient;

export let userId: string;
const today = getDateTimeNow();

export interface iAttributes {
  Browser_Type?: string;
  Browser_Version?: string;
  Device?: string;
  My_Account_New_or_Returning_User?: '' | 'New user' | 'Returning user';
  My_Account_Policy_type?: '' | 'home' | 'motor' | 'multi car';
  Operating_System?: string;
  QA_Cookie?: boolean;
  ScreenWidthPX?: number;
  User_Brand?: '' | 'Esure' | 'Flex' | 'SW';
  User_Payment_Type?: '' | 'Annual' | 'Monthly';
  Weekday_Users?: boolean;
  Weekend_Users?: boolean;
}

let attributes: iAttributes = {
  Browser_Type: uaParser.getBrowser().name ?? '',
  Browser_Version: uaParser.getBrowser().version ?? '',
  Device: uaParser.getDevice().type ?? '',
  My_Account_New_or_Returning_User: '',
  My_Account_Policy_type: 'motor',
  Operating_System: uaParser.getOS().name ?? '',
  QA_Cookie: document.cookie.indexOf('optimizelyQA=test') > -1,
  ScreenWidthPX: window.screen.width,
  User_Brand: 'Flex',
  User_Payment_Type: '',
  Weekday_Users: !(today.weekday === 6 || today.weekday === 7),
  Weekend_Users: today.weekday === 6 || today.weekday === 7,
};

export const track = (eventKey: string): void => {
  if (optimizelyClient?.isReady()) {
    optimizelyClient.track(eventKey, userId, attributes);
  }
};

export const setAttributes = (newAttributes: iAttributes): void => {
  attributes = { ...attributes, ...newAttributes };
};

export const setUser = (id: string): void => {
  userId = id;
};

function loadOptimizelyScript() {
  const script = document.createElement('script');

  script.src = 'https://cdn.optimizely.com/js/20878461497.js';
  script.async = true;

  document.body.appendChild(script);
}

export const initOptimizely = (): undefined | ReactSDKClient => {
  const cookies = retrieveCookieSettingsFromCookies();
  if (cookies.Performance_and_Analytics && !optimizelyClient) {
    loadOptimizelyScript();
    optimizelyClient = createInstance({
      eventBatchSize: 10,
      eventFlushInterval: 1000,
      sdkKey: config.optimizely.sdkKey,
    });
    //set to debug for output or '' for none
    setLogLevel('');
  }
  return optimizelyClient;
};
