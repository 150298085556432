import { url } from '../../service/config';

import { ListPurposeObject } from './ListPurpose';

export const consentCategory = {
  ANALYTICS: 'Performance_And_Analytics',
  MARKETING: 'Marketing',
  NECESSARY: 'necessary',
};
export const consentCategoryKeys = Object.keys(consentCategory) as CookieConsentCategory[];
export type CookieConsentCategory = keyof typeof consentCategory;

export const REQUIRED_COOKIES = ['Marketing', 'Performance_and_Analytics'] as const;

export const BANNER_COOKIES = ['BANNER_LOADED', 'BANNER_VIEWED'];

export const MODAL_COOKIES = ['MODAL_LOADED', 'MODAL_VIEWED'];

export type RequiredCookie = typeof REQUIRED_COOKIES[number];

export type CookiesOptions = Partial<Record<RequiredCookie, boolean>>;

export interface Category {
  defaultChecked?: boolean;
  description: string;
  disabled?: boolean;
  listPurpose?: ListPurposeObject[];
  title: string;
  url: string;
}

export const categories: Record<CookieConsentCategory, Category> = {
  ANALYTICS: {
    description:
      "These cookies let us collect information about how our website is used so that we can understand how it's performing and to help us make improvements. For example, they show us the number of visits made and how customers move around the site. These cookies also tell us which pages are the most popular and any pages that may need our attention.",
    title: 'Performance & Analytics Cookies (non-essential)',
    url: `https://www.${url.domain}/cookies-policy#performancecookies`,
  },
  MARKETING: {
    description:
      'We collect data from these cookies to make your browsing experience on our website more personal. Marketing cookies allow us to:',
    listPurpose: [
      {
        description: 'Show you tailored offers, promotions and marketing material when you visit other websites.',
        id: '1',
      },
      {
        description:
          'Share pages and content with you from our website (that you find interesting) through third-party social partners and other networking websites.',
        id: '2',
      },
    ],
    title: 'Marketing Cookies (non-essential)',
    url: `https://www.${url.domain}/cookies-policy#marketingcookies`,
  },
  NECESSARY: {
    description:
      'These cookies are essential to make sure our website works properly and cannot be switched off within our website. They help us to give you the best possible online experience. These cookies are also vital as they allow us to give you a quote, and for you to buy an insurance policy from us.',
    disabled: true,
    title: 'Essential Cookies',
    url: `https://www.${url.domain}/cookies-policy#essentialcookies`,
  },
};
