import { Button, ListItemIcon, makeStyles } from '@esure-cloud/react-components';

import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Success } from '../../../../../../animations/success';
import { BlueBanner } from '../../../../../../component/common/blueBanner';
import IconSure from '../../../../../../component/common/icon';
import { ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

const useAutoRenewalConfirmationStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    '& .buttonArea': {
      textAlign: 'center',
    },
    '& .heading': {
      maxWidth: '340px',
      textAlign: 'center',
    },
    '& .list': {
      justifyContent: 'center',
      maxWidth: '500px',
    },
    '& .spacing': {
      '&--3': {
        marginBottom: spacing(3),
      },
    },
    [breakpoints.down('xs')]: {
      marginBottom: spacing(6),
    },
  },
}));

export const AutoRenewalConfirmation: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const classes = useAutoRenewalConfirmationStyles();
  const { eventTrack } = useSegment();
  const {
    dashboardStore: {
      fetchRenewalDetails,
      insurance: {
        auto: {
          policySelected: { policyNumber, effectiveDate },
        },
      },
    },
  } = useStores();

  const bullets: string[] = t('policyManagement.autoRenewalDetails.confirmation.bullets', {
    returnObjects: true,
  });

  useEffect(() => {
    eventTrack('Renewal | Auto Renew Opt Out Success Updating Policy Viewed', {
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  const handleGoToPolicy = async () => {
    await fetchRenewalDetails(policyNumber, effectiveDate, false);

    history.push(ROUTE.POLICY_DETAILS);
  };

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('policyManagement.autoRenewalDetails.confirmation.heading')} />
      <Grid container direction="column" alignItems="center" item xs={12} className="spacing--3">
        <Success />
        <Typography variant="h1" className="spacing--3 heading">
          {t('policyManagement.autoRenewalDetails.confirmation.title')}
        </Typography>
        <Grid container className="spacing--3 list">
          {bullets.map((text, i) => (
            <Grid container key={i}>
              <Grid item xs={1}>
                <ListItemIcon style={{ minWidth: 0 }}>
                  <IconSure icon="circle" color={'secondary'} strokeWidth="2.5" />
                </ListItemIcon>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">{text}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className="buttonArea">
        <Button
          data-testid="backToPolicy"
          color="primary"
          className="button"
          variant="contained"
          size="large"
          onClick={handleGoToPolicy}
        >
          {t('buttons.goToMyPolicy')}
        </Button>
      </Grid>
    </Grid>
  );
};
