import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { COMPONENT_ID } from '../../../service/analytics';
import { DIALOG_ORIGIN } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { manageLogout } from '../../../service/util/handleLogout';
import { CommonButton } from '../../common/button/commonButton';

import { DialogTemplate } from './dialogTemplate';

interface Props {
  onContinue?: () => void;
  onLeave?: () => void;
  origin?: DIALOG_ORIGIN;
}

export const CancelConfirmationDialog: React.FC<Props> = ({ onContinue, onLeave, origin }) => {
  const { t } = useTranslation('myAccount');
  const {
    clearAllStores,
    interfaceStore: {
      dialog: { closeDialog },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const handleContinueClick = () => {
    if (origin === DIALOG_ORIGIN.ROUTER_PROMPT) {
      onContinue?.();
    } else {
      eventTrack('cancel confirmation popup closed', { type: TRACK_TYPE.MODAL });
      closeDialog();
    }
  };

  const handleLeaveClick = () => {
    if (origin === DIALOG_ORIGIN.ROUTER_PROMPT) {
      onLeave?.();
    } else {
      manageLogout();
      clearAllStores();
    }
  };

  return (
    <DialogTemplate
      closeIconColor="dark"
      topImage={<SVGWrapper alt="icon" height="80%" width="100" src="cancel-popup.svg" />}
      title={t('cancelConfirmationPopup.title')}
      mainContent={t('cancelConfirmationPopup.mainContent')}
      buttons={[
        <CommonButton
          color="secondary"
          variant="outlined"
          text={t('cancelConfirmationPopup.button.continue')}
          onClick={handleContinueClick}
          size="large"
          data-testid={COMPONENT_ID.MTA_CANCEL_DIALOG_CONTINUE_BUTTON}
        />,

        <CommonButton
          color="primary"
          onClick={handleLeaveClick}
          text={t('cancelConfirmationPopup.button.leave')}
          variant="contained"
          size="large"
          data-testid={COMPONENT_ID.MTA_CANCEL_DIALOG_LEAVE_BUTTON}
        />,
      ]}
    />
  );
};
