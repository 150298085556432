import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../service/state/store';
import { HomeRenewalsDecline } from '../home/homeRenewalsDecline';
import { MotorRenewalsDecline } from '../motor/motorRenewalsDecline';

export const RenewalsDecline: React.FC = observer(() => {
  const {
    dashboardStore: {
      insurance: { isHome },
    },
  } = useStores();

  return isHome ? <HomeRenewalsDecline /> : <MotorRenewalsDecline />;
});
