import { Grid, IconButton, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../component/common/blueBanner';
import { getCreditCardImage } from '../../../../component/common/utils';
import { DATE_TYPES, DIALOG_TYPE, ROUTE } from '../../../../service/constant';
import { useStores } from '../../../../service/state/store';
import { formatDate } from '../../../../service/util/formatDate';
import SelfService from '../../../../service/util/selfService';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .card': {
      '&-header_text': {
        textTransform: 'capitalize',
      },
      '&.expired': {
        backgroundColor: palette.background.subtle,
        border: `2px solid ${palette.divider}`,
        overflow: 'hidden',
      },
      border: `2px solid ${palette.secondary.main}`,
      borderRadius: spacing(1),
      marginBottom: spacing(2),
      padding: spacing(2),
    },
    '& .content': {
      '&-section': {
        '&_btn': {
          '&:hover': {
            backgroundColor: palette.background.paper,
          },
        },
        '&_icon': {
          backgroundColor: palette.secondary.main,
          borderRadius: spacing(0.5),
          color: palette.secondary.contrastText,
          marginRight: spacing(1),
          padding: spacing(0),
        },
        paddingBottom: spacing(4),
      },
      padding: spacing(3, 2),
    },
    '& a': {
      cursor: 'pointer',
    },
    '& img': {
      width: spacing(7),
    },
  },
}));

export const PaymentMethods: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      isMobile,
      dialog: { openDialog },
    },
    dashboardStore: { fetchPaymentMethods, paymentMethods },
  } = useStores();
  const [fetched, setFetched] = useState(false);

  const handleClick = (dialogType: DIALOG_TYPE) => {
    openDialog({
      title: t('fetchAdviserPopup.details.title'),
      type: dialogType,
    });
  };

  useEffect(() => {
    if (paymentMethods.methods.length === 0) {
      fetchPaymentMethods().then(() => setFetched(true));
    } else {
      setFetched(true);
    }
  }, []);

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('policyManagement.paymentMethods.title')} route={ROUTE.ACCOUNT} />
      <Grid className="content">
        {fetched ? (
          <>
            {paymentMethods.methods.length === 0 ? (
              <Grid data-testid="no-payment-methods">
                <Typography className="content-section" variant="h2">
                  {t('policyManagement.paymentMethods.noPaymentMethods')}
                </Typography>
                <Typography className="content-section" variant="h3">
                  {t('policyManagement.paymentMethods.saveCard')}
                </Typography>
              </Grid>
            ) : (
              <Grid xs={isMobile ? 12 : 6} item className="cards">
                {paymentMethods.methods.map(
                  ({ creditCardDetails: { cardHolderName, creditCardType, expirationDate, number } }) => {
                    const currentDate = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate);

                    return (
                      <Grid key={number} className={clsx('card', currentDate <= -1 && 'expired')}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="h3" className="card-header_text">
                            {creditCardType} {`****${number}`}
                          </Typography>
                          <img alt="Card icon" src={getCreditCardImage(creditCardType)} />
                        </Grid>
                        <Typography variant="body1">
                          {t('policyManagement.paymentMethods.expiryDate', {
                            value: formatDate(
                              SelfService.calculatePolicyEndDate(expirationDate).toString(),
                              DATE_TYPES.SHORT_SLASH_MONTH_YEAR,
                            ),
                          })}
                        </Typography>
                        <Grid container justifyContent="space-between">
                          <Typography variant="body1">{cardHolderName}</Typography>
                          <Typography variant="body1">
                            <Link
                              target="_blank"
                              onClick={() => handleClick(DIALOG_TYPE.FETCH_ADVISER_DELETE_CARD)}
                              data-testid="delete-btn"
                            >
                              {t('policyManagement.paymentMethods.delete')}
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  },
                )}
              </Grid>
            )}
          </>
        ) : (
          <></>
        )}
        <Grid className="content-section">
          <IconButton
            className="content-section_btn"
            color="secondary"
            aria-label="Add new payment method"
            size="small"
            data-testid="new-payment-btn"
            onClick={() => handleClick(DIALOG_TYPE.FETCH_ADVISER_ADD_CARD)}
          >
            <AddIcon fontSize="small" className="content-section_icon" />
            <Link target="_blank">{t('policyManagement.paymentMethods.newPaymentMethod')}</Link>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
