import { sendDownloadFileHook } from '../../../lib/caura-bridge';
import { track } from '../../../lib/optimizely';
import { apiURL } from '../../../service/config';
import { fetchDocumentById } from '../../../service/network/apiRequests';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { downloadFileAsPdf } from '../../../service/util/downloadFile';
import { Header } from '../../../service/util/interfaceModels/interfaceModels';

interface IDownloadDocuments {
  documentId: string;
  eventTrack?: ReturnType<typeof useSegment>['eventTrack'];
  header: Header;
  headerText: string;
  isCaura: boolean;
  onFail?: () => void;
  optimizelyTrackKey?: string;
  policyNumber?: string | null;
}

export const downloadDocument = ({
  documentId,
  policyNumber,
  header,
  headerText,
  optimizelyTrackKey,
  eventTrack,
  onFail,
  isCaura,
}: IDownloadDocuments): void => {
  optimizelyTrackKey && track(optimizelyTrackKey);

  if (documentId && policyNumber) {
    if (isCaura) {
      const url = `${apiURL.documentById}/${policyNumber}/documents/${documentId}`;
      sendDownloadFileHook(url, headerText, header);
      return;
    }

    fetchDocumentById(policyNumber, documentId, header)
      .then((response) => {
        downloadFileAsPdf(response.data, headerText);
        eventTrack?.('Document Downloaded', { item: headerText, type: TRACK_TYPE.DOWNLOAD });
      })
      .catch(() => onFail?.());
  }
};
