import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { ReusableRadioPage } from '../../common/components/reusableRadioPage';
import { StepTemplate } from '../../common/components/stepTemplate';
import { useStepperContext } from '../../common/utils/stepperContext';

export const CarsInHousehold: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    setTitle,
    stepsData: { reviewStep },
    setStep,
  } = useStepperContext();

  useEffect(() => {
    setTitle(t('MTAJourney.carsInHousehold.title'));
  }, []);
  const backToReviewPage = () => setStep(reviewStep);

  return (
    <>
      <StepTemplate>
        <ReusableRadioPage
          radioTitle={t('MTAJourney.carsInHousehold.radioTitle')}
          id={LOOKUP_TYPE_PAGE.CARS_IN_HOUSEHOLD}
          handleBack={backToReviewPage}
          handleSave={backToReviewPage}
        />
      </StepTemplate>
    </>
  );
});
