import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DIALOG_TYPE, JOURNEY_TYPE, ROUTE } from '../../../../../../../service/constant';
import { useStores } from '../../../../../../../service/state/store';
import { removeCompletedJourneyByTitle } from '../../../../../../../service/util/multiMTAUtil';
import { ItemList, NavigationLayout } from '../common/components/navigationLayout';
import { useStepperContext } from '../common/utils/stepperContext';

export const YourDetails: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const cards: ItemList[] = t('changeYourDetails.cards', { returnObjects: true });

  const {
    interfaceStore: {
      dialog: { openDialog },
    },
    mtaStore: { completedMTAJourneys },
  } = useStores();

  const { isMultiMTA } = useStepperContext();
  const assistedJourneyPopup = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route?: string) => {
    if (route === ROUTE.CHANGE_EMPLOYMENT_DETAILS) {
      event.preventDefault();
      openDialog({
        chatbotAction: JOURNEY_TYPE.CHANGE_EMPLOYMENT_DETAILS,
        title: t('fetchAdviserPopup.details.title'),
        type: DIALOG_TYPE.FETCH_ADVISER_EMPLOYMENT,
      });
    }
  };

  if (isMultiMTA) removeCompletedJourneyByTitle(cards, completedMTAJourneys);

  return (
    <NavigationLayout
      mtaType="changeYourDetails"
      cards={cards}
      banner={t('changeYourDetails.banner')}
      title={t('changeYourDetails.title')}
      onClick={assistedJourneyPopup}
      section={t('policyDetailsSection.yourDetails')}
    />
  );
});
