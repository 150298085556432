import { Typography } from '@esure-cloud/react-components';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CarDetails } from '..';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';
import { Drivers } from '../../drivers';
import { YourDetails } from '../../yourDetails';

export const MoreChanges: React.FC = () => {
  const { t } = useTranslation('myAccount');

  const { setTitle } = useStepperContext();

  useEffect(() => {
    setTitle(t('MTAJourney.MoreChanges.title'));
  }, []);

  return (
    <>
      <Typography>{t('MTAJourney.MoreChanges.description')}</Typography>
      <CarDetails />
      <YourDetails />
      <Drivers />
      <MTAFooter
        backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_MORE_CHANGES}
        cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_MORE_CHANGES}
        primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_MORE_CHANGES, text: t('MTAJourney.button.skip') }}
      />
    </>
  );
};
