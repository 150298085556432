import { FormControlLabel, Radio, RadioGroup } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps, PageValues } from './reviewAll';

export const EditInsuredNumberOfChildren: React.FC<EditPageProps> = observer(function EditInsuredNumberOfChildren({
  handleBack,
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { insured, setInsuredPerson },
    },
  } = useStores();
  const { setTitle } = useStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();

  const getPageVal: () => PageValues = () => {
    return { defaultOption: insured.person.numberOfChildren ?? '', lookup: LOOKUP.NUMBER_OF_CHILDREN };
  };
  const [value, setValue] = useState(getPageVal().defaultOption);
  const { isLookupsFetched } = useLookups({
    lookups: [getPageVal().lookup],
  });
  const { t } = useTranslation('myAccount');
  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[getPageVal().lookup]);
    }
  }, [isLookupsFetched, lookupsByName]);
  useEffect(() => {
    setTitle(t('MTAJourney.howManyChildren.title'));
  }, []);
  const handleSaveChanges = () => {
    setInsuredPerson({ ...insured.person, numberOfChildren: value });
    handleBack();
  };

  return (
    <>
      {isLookupsFetched && (
        <>
          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}

      <EditFooter
        id="editInsuredNumberChildren"
        validPage={true}
        handleBack={handleBack}
        handleSave={handleSaveChanges}
      />
    </>
  );
});
