import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationPaymentBlock } from '../../../../../../component/common/confirmationPaymentBlock';
import { ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';

export const HomeRenewalSetupSuccess: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        home: {
          policySelected: { policyNumber },
        },
      },
      fetchPolicy,
    },
    paymentStore: { updatePaymentComplete },
  } = useStores();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPolicy(policyNumber, true).then(() => setIsLoading(false));
    updatePaymentComplete(false);
  }, []);

  return (
    <ConfirmationPaymentBlock
      loading={isLoading}
      button={t('policyManagement.homeRenewalSetup.setupSuccess.button')}
      subtitle={t('policyManagement.homeRenewalSetup.setupSuccess.subtitle')}
      thankYouText={t('policyManagement.homeRenewalSetup.setupSuccess.thankYouText')}
      emailConfirmation={t('policyManagement.homeRenewalSetup.setupSuccess.emailConfirmation')}
      url={ROUTE.DOCUMENTS}
      hasMultiplePoliciesBanner={false}
    />
  );
};
