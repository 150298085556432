import { Instance, types } from 'mobx-state-tree';

import { DateTime } from '../../types/dateTime';

import { AutoRenewalOption, Insured, InsuredAddress, PolicyDocument } from './autoPolicyModel';

const string = types.optional(types.string, '');
const numberOptional = types.optional(types.number, 0);
const boolNull = types.maybeNull(types.boolean);
const stringNull = types.maybeNull(types.string);
const numberNull = types.maybeNull(types.number);

export const Excess = types.model('Excess', {
  code: string,
  compulsoryExcess: numberOptional,
  totalExcess: numberOptional,
  voluntaryExcess: numberOptional,
});

export const SubCoverage = types.model('SubCoverage', {
  code: string,
  coverAwayFromHome: boolNull,
  description: string,
  limit: numberOptional,
});

export const Coverage = types.model('Coverage', {
  accidentalDamage: numberOptional,
  code: string,
  excesses: types.optional(types.array(Excess), () => []),
  limit: numberOptional,
  subCoverages: types.optional(types.array(SubCoverage), () => []),
});

export const Person = types
  .model('Person', {
    firstName: string,
    surname: string,
    title: string,
  })
  .views(({ firstName, surname }) => ({
    fullName() {
      return `${firstName} ${surname}`;
    },
  }));

export const HomeInsured = types.model('Insured', {
  person: types.optional(Person, () => Person.create()),
  relationshipToProposerCd: string,
});

export const HomeProtection = types.model('Home Protection', {
  burglarAlarm: boolNull,
  doorsLockTypeCd: string,
  locksOnWindowsAndDoors: boolNull,
  prevFlooded: boolNull,
  windowsLockTypeCd: string,
});

export const HomeBuildingDeclarationQuestion = types.model('Home Building Declaration Question', {
  buildingInProgress: boolNull,
  prevDamage: boolNull,
  repairState: boolNull,
});

export const HomeConstructionInfo = types.model('Home Construction Info', {
  builtYear: numberOptional,
  listedBuildingCd: string,
  numberOfBathrooms: numberOptional,
  numberOfBedrooms: numberOptional,
  ownershipTypeCd: string,
  percentageFlatRoofCd: string,
  propertyTypeCd: string,
  rebuildCost: numberOptional,
  roofBuildMaterialCd: string,
  wallBuildMaterialCd: string,
});

export const HomePropertyUse = types.model('Home Property Use', {
  businessUseTypeCd: string,
  numberOfAdults: numberOptional,
  numberOfChildren: numberOptional,
  occupantsCd: string,
  unoccupiedForLongPeriod: boolNull,
});

export const Claims = types.model('Claims', {
  claimAmount: numberOptional,
  claimCd: string,
  claimDate: string,
  claimTypeCd: string,
});

const CustomerIndividualDetail = types.model('CustomerIndividualDetail', {
  firstName: string,
});

export const RiskAddress = types.model('RiskAddress', {
  country: stringNull,
  county: stringNull,
  houseName: stringNull,
  houseNumber: stringNull,
  locality: stringNull,
  postalTown: stringNull,
  postcode: stringNull,
  street: stringNull,
  subPremises: stringNull,
});

const Dwellings = types
  .model('Dwellings', {
    riskAddress: types.optional(RiskAddress, () => RiskAddress.create()),
  })
  .views(({ riskAddress: { houseNumber, street } }) => ({
    addressDetails() {
      return `${houseNumber ?? ''} ${street ?? ''}`;
    },
  }));

export const PremiumBreakdown = types.model('PremiumBreakdown', {
  interest: numberNull,
});

export const InstallmentSchedule = types.model('Installment Schedule', {
  amount: numberNull,
  dueDate: string,
});

export const PolicyPaymentPlan = types.model('Policy Payment Plan', {
  addonsCost: numberOptional,
  apr: numberNull,
  installmentSchedule: types.optional(types.array(InstallmentSchedule), () => []),
  numberOfInstallments: numberOptional,
  policyCostWithoutAddons: numberOptional,
  policyCostWithoutInterest: numberOptional,
  premiumBreakdown: types.optional(PremiumBreakdown, () => PremiumBreakdown.create()),
});

export const HomePolicyRenewalQuoteDetails = types
  .model('HomePolicyRenewalDetails', {
    brandCd: string,
    coverType: string,
    currentYearMonthlyCost: numberOptional,
    currentYearTotalCost: numberOptional,
    customerIndividualDetail: types.optional(CustomerIndividualDetail, () => CustomerIndividualDetail.create()),
    dwellings: types.optional(types.array(Dwellings), () => []),
    inceptionDate: string,
    lastActionReasonCd: string,
    policyPaymentPlan: types.optional(PolicyPaymentPlan, () => PolicyPaymentPlan.create()),
    previousYearMonthlyCost: numberOptional,
    previousYearTotalCost: numberOptional,
    renewalPolicyStatusCode: string,
    startDate: string,
    transactionTypeCd: string,
  })
  .views(({ dwellings }) => ({
    mainAddress() {
      return dwellings.length ? dwellings[0] : Dwellings.create();
    },
  }));

export const HomePolicy = types.model('HomePolicy', {
  autoRenewal: boolNull,
  autoRenewalOption: types.optional(AutoRenewalOption, () => AutoRenewalOption.create()),
  brand: string,
  channelCd: string,
  claims: types.optional(types.array(Claims), () => []),
  coverTypeCd: string,
  coverages: types.optional(types.array(Coverage), () => []),
  documents: types.optional(types.array(PolicyDocument), () => []),
  effectiveDate: DateTime,
  expirationDate: string,
  fetchingRenewalData: boolNull,
  futureMtaEffectiveDate: string,
  futureRenewalEffectiveDate: DateTime,
  hasPendingInstallments: boolNull,
  homeAddress: types.optional(InsuredAddress, () => InsuredAddress.create()),
  homeBuildingDeclarationQuestion: types.optional(HomeBuildingDeclarationQuestion, () =>
    HomeBuildingDeclarationQuestion.create(),
  ),
  homeConstructionInfo: types.optional(HomeConstructionInfo, () => HomeConstructionInfo.create()),
  homePropertyUse: types.optional(HomePropertyUse, () => HomePropertyUse.create()),
  homeProtection: types.optional(HomeProtection, () => HomeProtection.create()),
  inceptionDate: DateTime,
  insured: types.optional(Insured, () => Insured.create()),
  insureds: types.optional(types.array(HomeInsured), () => []),
  loadingFailed: boolNull,
  migrated: string,
  paymentPlan: string,
  policyNumber: string,
  policyRenewalQuoteDetails: types.optional(HomePolicyRenewalQuoteDetails, () =>
    HomePolicyRenewalQuoteDetails.create(),
  ),
  policyStatus: string,
  policyStatusCd: string,
  policyTypeCd: string,
  prevConvictions: boolNull,
  productType: string,
  renewalDocuments: types.optional(types.array(PolicyDocument), () => []),
  renewalStatusRequestPending: boolNull,
  tiaPolicy: types.optional(types.boolean, false),
  timedPolicyStatusCd: string,
});

export type IHomePolicy = Instance<typeof HomePolicy>;
export type IHomeInsured = Instance<typeof HomeInsured>;
export type IHomeExcess = Instance<typeof Excess>;
export type ISubCoverage = Instance<typeof SubCoverage>;
export type ICoverage = Instance<typeof Coverage>;
export type IHomePolicyRenewalQuoteDetails = Instance<typeof HomePolicyRenewalQuoteDetails>;
export type IHomePolicyPaymentPlan = Instance<typeof PolicyPaymentPlan>;
