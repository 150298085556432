import { FormControlLabel, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { MtaTooltip } from '../../../../makeAchange/mta/common/components/mtaTooltip';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const RegisteredKeeperRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { vehicle, setVehicle },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();

  const [value, setValue] = useState(vehicle.registeredKeeperCd);
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.KEEPER],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[LOOKUP.KEEPER]);
    }
  }, [isLookupsFetched, lookupsByName]);

  const handleContinue = () => {
    setVehicle({ ...vehicle, registeredKeeperCd: value });
    if (value === '') return;
    nextStep();
  };

  useEffect(() => {
    setTitle(t('MTAJourney.registeredKeeper.title'));
  }, []);

  return (
    <>
      {isLookupsFetched && (
        <>
          <MtaTooltip
            description={<Typography variant="body1">{t('MTAJourney.registeredKeeper.subtitle')}</Typography>}
            tooltipTitle={t('tooltips.changeYourCar.registeredKeeper')}
          />

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_BACK_BUTTON_REGISTERED_KEEPER}
        cancelButtonId={COMPONENT_ID.RENEWAL_CANCEL_BUTTON_REGISTERED_KEEPER}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_CONTINUE_BUTTON_REGISTERED_KEEPER, handleContinue }}
      />
    </>
  );
});
