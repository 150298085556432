import { Instance, types } from 'mobx-state-tree';

import SelfService from '../../../util/selfService';

import { IAutoRenewalOption, IPolicyDocument } from './autoPolicyModel';
import { HomePolicy, IHomePolicy, IHomePolicyRenewalQuoteDetails } from './homePolicyModel';

const string = types.optional(types.string, '');

export const HomePolicies = types
  .model('HomePolicies', {
    homePolicies: types.optional(types.array(HomePolicy), () => []),
    selectedDocumentTypeCode: string,
    selectedPolicyNumber: string,
  })
  .actions((self) => ({
    addDocuments(documents: IPolicyDocument[], policyNumber: string) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].documents.replace(documents);
    },
    addPolicy(policy: IHomePolicy) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policy.policyNumber);
      policy.policyStatus = SelfService.getPolicyStatus(policy);
      if (index === -1) {
        self.homePolicies.push({ ...policy, homeAddress: policy.homeAddress });
      } else {
        self.homePolicies[index] = policy;
      }
    },
    addRenewalDocuments(renewalDocuments: IPolicyDocument[], policyNumber: string) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].renewalDocuments.replace(renewalDocuments);
    },
    addRenewalInfo(autoRenewalOption: IAutoRenewalOption, autoRenewal: boolean | null, policyNumber: string) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].autoRenewalOption = autoRenewalOption;
      self.homePolicies[index].autoRenewal = autoRenewal;
    },
    addRenewalQuoteDetails(policyNumber: string, renewalQuoteDetails: IHomePolicyRenewalQuoteDetails) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].policyRenewalQuoteDetails = renewalQuoteDetails;
    },
    setFetchingRenewalData(policyNumber: string, value: boolean) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].fetchingRenewalData = value;
    },
    setRenewalStatusRequestPending(renewalStatusRequestPending: boolean, policyNumber: string) {
      const index = self.homePolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.homePolicies[index].renewalStatusRequestPending = renewalStatusRequestPending;
    },
    setSelectedDocumentTypeCode(documentTypeCode: string) {
      self.selectedDocumentTypeCode = documentTypeCode;
    },
    setSelectedPolicyNumber(policyNumber: string) {
      self.selectedPolicyNumber = policyNumber;
    },
  }))
  .views((self) => ({
    getPolicyByNumber(policyNumber: string): IHomePolicy | undefined {
      return self.homePolicies.find((item) => item.policyNumber === policyNumber);
    },
    get mainPolicy(): IHomePolicy {
      const { homePolicies: policies } = self;
      return policies.length ? policies[0] : HomePolicy.create();
    },
    get policySelected(): IHomePolicy {
      return self.homePolicies.find((item) => item.policyNumber === self.selectedPolicyNumber) ?? this.mainPolicy;
    },
  }));
export type IHomePolicies = Instance<typeof HomePolicies>;
