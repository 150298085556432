import { makeStyles } from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarOvernightForm from '../../../../../../../../component/common/mta/carOvernight';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { LOOKUP } from '../../../../../../../../service/constant';
import {
  IInsuredAddress,
  InsuredAddress,
} from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { CONTACT_FLOW_TYPE } from '../../../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .home-address': {
      fontWeight: 'bold',
    },
    '& .overnight-address': {
      gap: spacing(2),
    },
    display: 'grid',
    gap: spacing(2),
    width: 'fit-content',
  },
}));

export interface CarOvernightProps {
  handleBack: () => void;
  handleSave: () => void;
}

export const CarOvernight: React.FC<CarOvernightProps> = observer(({ handleBack, handleSave }) => {
  const { setTitle, setEditMode } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: {
            insured: { address },
          },
        },
      },
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { setVehicle, vehicle },
    },
    interfaceStore: { chatBot },
  } = useStores();

  const { isLookupsFetched } = useLookups({ lookups: [LOOKUP.OVERNIGHT_LOCATION] });
  const chatAttrs = useMTAChatBotAttributes();
  const { overnightLocation, sameOvernightAddress, overnightAddress } = vehicle;

  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | undefined>();
  const [nightLocation, setNightLocation] = useState<string | undefined>(overnightLocation);
  const [sameOvernight, setSameOvernight] = useState(sameOvernightAddress);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.CarOvernight.title'));
    if (overnightAddress.postcode !== '') {
      setSelectedAddress(toJS(overnightAddress));
    }
  }, []);

  useEffect(() => {
    setOptions(lookupsByName[LOOKUP.OVERNIGHT_LOCATION]);
  }, [isLookupsFetched, lookupsByName]);

  const handleSaveChanges = () => {
    if (attempts >= 3) {
      chatBot.startChat({
        contactFlowType: CONTACT_FLOW_TYPE.MTA,
        customContactAttributes: { ...chatAttrs?.customContactAttributes, ssMTAVehiclePostCode: 'true' },
        initiator: 'Chat with a specialist button',
      });
    } else {
      if (!sameOvernight && !selectedAddress) return;
      setVehicle({
        ...vehicle,
        overnightAddress: sameOvernight ? InsuredAddress.create() : (selectedAddress as IInsuredAddress),
        overnightLocation: nightLocation as string,
        overnightPostcode: sameOvernight ? address.postcode : (selectedAddress as IInsuredAddress).postcode,
        sameOvernightAddress: sameOvernight,
      });
      handleSave();
      setEditMode(false);
    }
  };

  return (
    <CarOvernightForm
      address={address}
      handleBack={handleBack}
      handleSave={handleSaveChanges}
      nightLocation={nightLocation}
      options={options}
      sameOvernight={sameOvernight}
      selectedAddress={selectedAddress}
      setAttempts={setAttempts}
      setNightLocation={setNightLocation}
      setSameOvernight={setSameOvernight}
      setSelectedAddress={setSelectedAddress}
      attempts={attempts}
      classes={classes}
    />
  );
});
