import { Grid, RegistrationPlate, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import SelfService from '../../../../../../../../service/util/selfService';
import { useDisplayDate } from '../utils';

import { YourPremiumDetails } from './yourPremiumDetails';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .bold-item': {
      fontWeight: 700,
    },
    '& .summary': {
      '&__item': {
        '&:not(:nth-last-child(1))': {
          borderBottom: `1px solid ${palette.divider}`,
        },
        '&:nth-last-child(2)': {
          borderBottom: `1px solid ${palette.secondary.light}50`,
        },
        gap: spacing(1),
        margin: spacing(0, 3),
        padding: spacing(2, 0),
      },
    },
    gap: spacing(4),
  },
}));

export const QuoteSummaryChanges: React.FC = observer(function QuoteSummaryChanges() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    mtaStore: {
      pendedMTA: { endorsementDate: startDate, insured, driverFullName, removedDrivers, selectedDriver, drivers },
      completedMTAJourneys,
      quote: { vehicleDetails: quoteVehicle },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          policySelected: { drivers: policyDrivers },
        },
      },
    },
  } = useStores();
  const deletedDrivers = policyDrivers.filter((driver) => removedDrivers.includes(driver.uuid));
  const addedDrivers = drivers.filter((driver) => driver.uuid.includes('emptyDriver'));
  const [completedJourneys, setCompletedJourneys] = useState<string[]>([]);

  const findCompletedJourneys = () => {
    completedMTAJourneys.map((journey) => {
      return setCompletedJourneys((completedJourneys) => [...completedJourneys, journey.completedJourney]);
    });
  };
  useEffect(() => {
    findCompletedJourneys();
  }, []);

  return (
    <div className={classes.root}>
      <BlueTableContainer title={t('MTAJourney.QuoteReady.summaryOfChanges')}>
        <Grid container>
          <Grid container className="summary__item">
            <Typography variant="body1">{t('MTAJourney.SummaryChanges.startDate')}</Typography>

            <Grid container justifyContent="space-between">
              <Grid>
                <Typography variant="body1" className="bold-item">
                  {useDisplayDate(startDate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {completedJourneys.includes(JOURNEY_TYPE.CHANGE_YOUR_CAR) && (
            <Grid container className="summary__item">
              <Typography variant="body1">{t('MTAJourney.SummaryChanges.newCarDetails')}</Typography>

              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography variant="body1" className="bold-item">
                    {`${quoteVehicle.make} ${quoteVehicle.model}`}
                    <RegistrationPlate label={quoteVehicle.vrm} active={true} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {completedJourneys.includes(JOURNEY_TYPE.REGISTRATION_NUMBER) && (
            <Grid container className="summary__item">
              <Typography variant="body1">{t('MTAJourney.SummaryChanges.registrationNumber')}</Typography>

              <Grid container justifyContent="space-between">
                <Grid>
                  <RegistrationPlate label={quoteVehicle.vrm} active={true} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {completedJourneys.includes(JOURNEY_TYPE.CHANGE_HOME_ADDRESS) && (
            <Grid container className="summary__item">
              <Typography variant="body1">
                {t('MTAJourney.ReviewChanges.reviewHomeAddressChanges.newAddress')}
              </Typography>

              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography variant="body1" className="bold-item">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: SelfService.formatAddress(insured.address, true).full,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {deletedDrivers.length > 0 && (
            <Grid container className="summary__item">
              <Typography variant="body1">{t('MTAJourney.SummaryChanges.removeDrivers')}</Typography>
              <Grid container justifyContent="space-between">
                <Grid>
                  {deletedDrivers.map((driver, idx) => {
                    return (
                      <Typography variant="body1" className="bold-item" key={idx}>
                        {' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: driverFullName(driver),
                          }}
                        />
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
          {addedDrivers.length > 0 && (
            <Grid container className="summary__item">
              <Typography variant="body1">{t('MTAJourney.SummaryChanges.addDriver')}</Typography>
              <Grid container justifyContent="space-between">
                <Grid>
                  {addedDrivers.map((driver, idx) => {
                    return (
                      <Typography variant="body1" className="bold-item" key={idx}>
                        {`${getLookup(LOOKUP.TITLE, selectedDriver.person.titleCode)} ${driverFullName(
                          selectedDriver,
                        )}`}
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}

          <YourPremiumDetails />
        </Grid>
      </BlueTableContainer>
    </div>
  );
});
