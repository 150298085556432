import {
  ClickAwayListener,
  IconButton,
  Tooltip as MuiTooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useStores } from '../../service/state/store';

import IconSure from './icon';

export interface TooltipProps {
  title: string;
}

export const Tooltip = withStyles(({ breakpoints }) => ({
  tooltip: {
    [breakpoints.up('md')]: {
      maxWidth: 411,
      minWidth: 288,
    },
    background: `#EBF1FC`,
  },
}))(MuiTooltip);

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const BasicTooltip: React.FC<{ title: string }> = observer(function BasicTooltip({ title }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const {
    interfaceStore: { isMobile },
  } = useStores();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMobile = () => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        disableHoverListener={isMobile}
        disableTouchListener={isMobile}
        disableFocusListener
        onClose={handleClose}
        onClick={handleOpen}
        onOpen={handleOpen}
        open={open}
        title={
          <div>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
        }
      >
        <span className={classes.root}>
          <IconButton
            aria-haspopup="true"
            aria-label="info"
            size="small"
            data-testid="open"
            onClick={handleCloseMobile}
          >
            <IconSure icon="info-circle" size="20" type="solid" color="secondary" />
          </IconButton>
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
});
