import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

import { DriverEditPageProps } from './editDriver';

export const DriverRelationship: React.FC<DriverEditPageProps> = observer(
  ({ handleBackDriverEdit, handleSaveDriverEdit }) => {
    const {
      dashboardStore: {
        lookups: { lookupsByName },
        insurance: {
          auto: {
            policySelected: { drivers },
          },
        },
      },
      mtaStore: {
        pendedMTA: { selectedDriver, setSelectedDriver },
      },
    } = useStores();
    const [relationship, setRelationshipList] = useState<ILookupItem[] | undefined>();
    const [relationshipSelected, setRelationshipSelected] = useState(selectedDriver.relationshipToProposerCode);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation('myAccount');

    const {
      nextStep,
      setTitle,
      stepsData: { reviewStep, journeyType },
      editMode,
      setStep,
    } = useStepperContext();

    const isChangeDriverDetailsJourney = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

    const isNotValid = () => {
      return relationshipSelected === '' || (relationshipSelected === 'S' && hasSpouse);
    };

    const handlePendedMta = () => {
      setIsSubmitting(true);
      !isNotValid() && setSelectedDriver({ ...selectedDriver, relationshipToProposerCode: relationshipSelected });
    };

    const handleSave = () => {
      handlePendedMta();
      if (!isNotValid()) {
        isChangeDriverDetailsJourney ? handleSaveDriverEdit?.() : setStep(reviewStep);
      }
    };

    const handleContinue = () => {
      handlePendedMta();
      !isNotValid() && nextStep();
    };

    const handleBack = () => {
      isChangeDriverDetailsJourney ? handleBackDriverEdit?.() : setStep(reviewStep);
    };

    const { isLookupsFetched } = useLookups({
      lookups: [LOOKUP.DRIVER_RELATIONSHIP],
    });
    useEffect(() => {
      setTitle(t('MTAJourney.driverRelationship.title'));
    }, []);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isLookupsFetched && lookupsByName[LOOKUP.DRIVER_RELATIONSHIP] !== undefined) {
        setRelationshipList(lookupsByName[LOOKUP.DRIVER_RELATIONSHIP]);
      }
    }, [isLookupsFetched, lookupsByName]);

    const hasSpouse = drivers.find((driver) => driver.relationshipToProposerCode === 'S');

    return (
      <>
        <Typography variant="body1">{t('MTAJourney.driverRelationship.description')}</Typography>
        <RadioGroup
          value={relationshipSelected}
          onChange={(event) => {
            setRelationshipSelected(event.target.value);
          }}
        >
          {relationship?.map((item, index) => {
            return (
              <FormControlLabel
                key={`${item.code}-${index}`}
                value={item.code}
                control={<Radio />}
                label={item.value}
              />
            );
          })}
        </RadioGroup>
        {relationshipSelected === '' && isSubmitting && (
          <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
        )}
        {relationshipSelected === 'S' && isSubmitting && hasSpouse && (
          <FormHelperText error>{t('validations.spouseError')}</FormHelperText>
        )}

        {editMode ? (
          <EditFooter
            id="driverRelationship"
            validPage={!isNotValid()}
            handleSave={handleSave}
            handleBack={handleBack}
          />
        ) : (
          <MTAFooter
            backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_DRIVER_RELATIONSHIP}
            cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_DRIVER_RELATIONSHIP}
            primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_DRIVER_RELATIONSHIP, handleContinue }}
          />
        )}
      </>
    );
  },
);
