import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IDriver } from '../../service/state/models/dashboard/autoPolicyModel';
import { IHomeInsured } from '../../service/state/models/dashboard/homePolicyModel';
import { HomeInsuredCard } from '../../view/pages/policies/homePolicy/components/homeInsuredCard';
import { DriverCard } from '../common/driverCard';
import { DriverCounter } from '../common/driverCounter';

const useStyles = makeStyles((theme) => ({
  containerDriver: {
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
    },
  },
  containerSection: {
    paddingTop: theme.spacing(5),
  },
  title: {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

export const Insureds: React.FC<{ drivers?: IDriver[]; insureds?: IHomeInsured[] }> = observer(function Insureds({
  drivers = [],
  insureds = [],
}) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  return (
    <Grid item className={classes.containerSection}>
      <Grid container wrap="nowrap">
        <Grid item>
          <Grid container>
            <Typography className={classes.title} variant="h2">
              {drivers.length > 0 ? t('drivers.title') : t('homePolicyDetailsPage.sectionTitle')}
            </Typography>
            <DriverCounter number={drivers.length > 0 ? drivers.length : insureds.length} />
          </Grid>
        </Grid>
      </Grid>

      {drivers.length > 0 && (
        <Grid container wrap="nowrap" className={classes.containerDriver} spacing={2}>
          {drivers.map((driver, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <DriverCard driver={driver} />
            </Grid>
          ))}
          {/*hidden as required in BDILMM-1289*/}
          {/*<Grid item xs={12} sm={4} md={4} >*/}
          {/*  <DriverCardCTA />*/}
          {/*</Grid>*/}
        </Grid>
      )}

      {insureds.length > 0 && (
        <Grid container wrap="nowrap" className={classes.containerDriver} spacing={2}>
          {insureds.map((homeInsured, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <HomeInsuredCard homeInsured={homeInsured} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
});
