import axios, { AxiosResponse } from 'axios';

import config, { apiURL, featureFlagURL, trustPilotURL } from '../config';
import {
  Feature,
  FeatureTogglesResults,
  Header,
  TrustPilotReviewsResults,
  TrustPilotStarsResults,
} from '../util/interfaceModels/interfaceModels';

export interface Destination {
  creationName: string;
}

const {
  trustPilot: { apiKey, businessUnitID },
} = config;

export const fetchDocumentById = async (
  policyNumber: string,
  documentId: string,
  header: Header,
): Promise<AxiosResponse> => {
  return await axios.get(`${apiURL.documentById}/${policyNumber}/documents/${documentId}`, {
    data: {},
    headers: header,
    responseType: 'arraybuffer',
  });
};

export const fetchTrustPilotReviews = async (): Promise<AxiosResponse<TrustPilotReviewsResults>> => {
  return await axios.get(`${trustPilotURL.totalReviews}${businessUnitID}/?apikey=${apiKey}`);
};

export const fetchTrustPilotStars = async (stars: number): Promise<AxiosResponse<TrustPilotStarsResults>> => {
  return await axios.get(`${trustPilotURL.totalStars}${stars}?apikey=${apiKey}`);
};

export const fetchFeatureToggles = async (): Promise<Feature[]> => {
  let fetchedFeatureFlags: Feature[] = [];

  const isItem = (propertyName: string): boolean => {
    return propertyName.includes('FeatureFlags') && propertyName.includes('.id');
  };

  const convertToFeatureArray = (data: FeatureTogglesResults): Feature[] => {
    const newData: Feature[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (data !== undefined) {
      const sources = data.propertySources[0].source;
      const keys = Object.keys(sources);
      const values = Object.values(sources);
      keys.forEach((key: string, index: number) => {
        if (isItem(key)) {
          newData.push({ id: String(values[index]), value: String(values[index + 1]) });
        }
      });
    }
    return newData;
  };

  try {
    //Fetch the remote feature flag data from the server
    const { data } = await axios.get<FeatureTogglesResults>(featureFlagURL.server);
    fetchedFeatureFlags = convertToFeatureArray(data);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return fetchedFeatureFlags;
};

export const fetchDestinationForWriteKey = async (writeKey: string): Promise<string[]> => {
  const destinations: string[] = [];
  await axios.get<Destination[]>(`https://cdn.segment.com/v1/projects/${writeKey}/integrations`).then((res) => {
    if (res.status !== 200) {
      throw new Error(`Failed to fetch integrations list`);
    }

    for (const destination of res.data) {
      destinations.push(destination.creationName);
    }
  });

  return destinations;
};
