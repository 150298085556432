import { Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { DIALOG_TYPE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';

import { DialogTemplate } from './dialogTemplate';

export type NavigationType = 'navigate' | 'reload' | 'back_forward' | 'prerender';

export interface TimingNavigation extends PerformanceEntry {
  type: NavigationType;
}

export const ErrorDialog: React.FC<{ type: DIALOG_TYPE }> = observer(function ErrorDialog({ type }) {
  const { t } = useTranslation('myAccount');

  const {
    interfaceStore: {
      dialog: { closeDialog },
      setRefreshAttempts,
      totalErrors,
      chatBot: { startChat },
    },
  } = useStores();

  const refreshPage = () => {
    window.location.reload();
    setRefreshAttempts();
    closeDialog();
  };

  const initiateChat = () => {
    startChat({ initiator: 'Live Chat Button' });
    closeDialog();
  };

  useEffect(() => {
    const isReloaded = (performance.getEntriesByType('navigation')[0] as TimingNavigation).type === 'reload';

    if (isReloaded && totalErrors >= 1) {
      setRefreshAttempts();
    }
  }, [totalErrors]);

  return (
    <DialogTemplate
      topImage={<SVGWrapper alt="icon" src="something_went_wrong.svg" />}
      title={t('errorModal.title')}
      testid="error-dialog"
      mainContent={t('errorModal.mainContent')}
      secondaryContent={
        type === DIALOG_TYPE.ERROR_ATTEMPT_1
          ? t('errorModal.firstAttempt.secondaryContent')
          : t('errorModal.multipleAttempts.secondaryContent')
      }
      buttons={[
        <Button
          color="primary"
          onClick={type === DIALOG_TYPE.ERROR_ATTEMPT_1 ? refreshPage : initiateChat}
          variant="contained"
        >
          {type === DIALOG_TYPE.ERROR_ATTEMPT_1
            ? t('errorModal.firstAttempt.refreshBtn')
            : t('errorModal.multipleAttempts.chatBtn')}
        </Button>,
      ]}
    />
  );
});
