import { Grid, alpha, makeStyles } from '@esure-cloud/react-components';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarOwnershipForm from '../../../../../../../../component/common/mta/carOwnership';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .error-message': {
      color: palette.error.main,
      paddingTop: spacing(1),
    },
    '& .icon': {
      marginRight: spacing(1),
    },
    '& .info-box': {
      '& .box-body': {
        color: palette.error.dark,
      },
      '& .box-button': {
        marginLeft: -spacing(2),
        marginTop: spacing(2),
      },
      '& .box-title': {
        color: palette.error.dark,
        fontWeight: 'bold',
      },
      '&.info-box-error': {
        backgroundColor: alpha(palette.error.dark, 0.08),
        border: `1px solid ${palette.error.dark}`,
      },
      backgroundColor: alpha(palette.secondary.main, 0.08),
      border: `1px solid ${palette.secondary.main}`,
      marginTop: spacing(1),
    },
    '& .input': {
      '&__container': {
        gap: spacing(2),
        marginTop: spacing(2),
      },
      '&__item': {
        width: '100%',
      },
    },
    '& .radio-section': {
      gap: 20,
    },
    '& .secondary-title': {
      marginBottom: spacing(1),
    },
    display: 'grid',
  },
}));

export const CarOwnership: React.FC = observer(function CarOwnership() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const {
    nextStep,
    setStep,
    setTitle,
    editMode,
    stepsData: { reviewStep },
  } = useStepperContext();
  const {
    interfaceStore: { isMobile, getStepAutoComplete, setStepAutoComplete },
    mtaStore: {
      pendedMTA: { vehicle, setVehicle, endorsementDate: startDate },
    },
  } = useStores();
  const [isCarOwned, setIsCarOwned] = useState<null | boolean>(null);
  const [estimatedValue, setEstimatedValue] = useState<number | null>(null);
  const [purchaseDate, setPurchaseDate] = useState<string | null>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateError, setDateError] = useState(false);
  const carOwnershipTag1 = 'carOwnership1';
  const carOwnershipTag2 = 'carOwnership2';
  const carOwnershipTag3 = 'carOwnership3';

  /* istanbul ignore next */
  useEffect(() => {
    setTitle(t('MTAJourney.CarOwnership.title'));
    if (estimatedValue !== null && purchaseDate !== null) {
      setIsCarOwned(true);
    } else if (estimatedValue !== null && purchaseDate == null) {
      setIsCarOwned(false);
    } else {
      if (getStepAutoComplete(carOwnershipTag1) === 'true') {
        setIsCarOwned(true);
      } else if (getStepAutoComplete(carOwnershipTag1) === 'false') {
        setIsCarOwned(false);
      }
      if (!isNaN(Number(getStepAutoComplete(carOwnershipTag2)))) {
        setEstimatedValue(Number(getStepAutoComplete(carOwnershipTag2)));
      } else {
        setEstimatedValue(null);
      }
      if (getStepAutoComplete(carOwnershipTag3) !== '') {
        setPurchaseDate(getStepAutoComplete(carOwnershipTag3) ?? '');
      } else {
        setPurchaseDate('');
      }
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'true';
    setIsCarOwned(value);
  };

  const isValid = (): boolean => {
    if (isCarOwned === null) {
      return false;
    }
    if (isCarOwned && (purchaseDate === '' || purchaseDate === null || estimatedValue === null || dateError)) {
      return false;
    }
    if (!isCarOwned && estimatedValue === null) {
      return false;
    }
    if (isCarOwned && ((startDate && purchaseDate && DateTime.fromISO(purchaseDate) > startDate) || dateError)) {
      return false;
    }
    return true;
  };

  /* istanbul ignore next */
  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      if (isCarOwned) {
        setVehicle({ ...vehicle, estimatedValue, notPurchasedYet: false, purchaseDate });
        setStepAutoComplete(carOwnershipTag3, String(purchaseDate));
      } else {
        setVehicle({ ...vehicle, estimatedValue, notPurchasedYet: true, purchaseDate: null });
      }
      setStepAutoComplete(carOwnershipTag1, String(isCarOwned));
      setStepAutoComplete(carOwnershipTag2, String(estimatedValue));
    }
  };

  const handleSave = () => {
    handlePendedMta();
    isValid() && setStep(reviewStep);
  };

  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };

  return (
    <Grid container item direction="column" xs={isMobile ? 12 : 6} className={classes.root}>
      <CarOwnershipForm
        isCarOwned={isCarOwned}
        estimatedValue={estimatedValue}
        setEstimatedValue={setEstimatedValue}
        purchaseDate={purchaseDate}
        setPurchaseDate={setPurchaseDate}
        isSubmitting={isSubmitting}
        dateError={dateError}
        setDateError={setDateError}
        startDate={startDate}
        isMobile={isMobile}
        handleChange={handleChange}
        setStep={setStep}
      />
      {editMode ? (
        <EditFooter
          id="carOwnership"
          validPage={isValid()}
          handleSave={handleSave}
          handleBack={() => setStep(reviewStep)}
        />
      ) : (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_OWNERSHIP}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_OWNERSHIP}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_OWNERSHIP, handleContinue }}
        />
      )}
    </Grid>
  );
});
