import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../service/state/store';
import { CommonButton } from '../common/button/commonButton';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  containerGrid: {
    gap: 15,
  },
}));

export interface EmptyStateProps {
  content: string;
  title: string;
}

export const EmptyState: React.FC<EmptyStateProps> = observer(function EmptyState({ title, content }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const {
    interfaceStore: { isMobile, isTablet },
  } = useStores();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.containerGrid}>
      <Typography variant="h2" align="center">
        {title}
      </Typography>
      <Typography variant="body1">{content}</Typography>
      <Grid item xs={isMobile || isTablet ? 12 : 3} className={classes.buttonContainer}>
        <CommonButton
          size="large"
          xl
          onClick={() => {
            window.location.reload();
          }}
          text={t('docsFailedLoading.refreshBtn')}
        ></CommonButton>
      </Grid>
    </Grid>
  );
});
