import { useTranslation } from 'react-i18next';

import { ROUTE } from '../../constant';
import { useStores } from '../../state/store';

interface Breadcrumbs {
  label: string;
  path: ROUTE;
}

interface BreadcrumbsRouteMap {
  breadcrumbs: Breadcrumbs[];
  path: ROUTE;
}

export const useBreadcrumbs = (): BreadcrumbsRouteMap[] => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          mainVehicle,
          policySelected: { policyRenewalQuoteDetails },
        },
        home: {
          policySelected: { homeAddress },
        },
        isHome,
      },
    },
  } = useStores();

  const policiesCrumbs = { label: t('breadcrumbs.policies'), path: ROUTE.DASHBOARD };

  const policyCrumbs = isHome
    ? {
        label: `${homeAddress.houseName ?? ''} ${homeAddress.houseNumber} ${homeAddress.street}`.trim(),
        path: ROUTE.POLICY_DETAILS,
      }
    : { label: `${mainVehicle.make} ${mainVehicle.model}`, path: ROUTE.POLICY_DETAILS };

  const makeAChangeCrumbs = { label: t('breadcrumbs.makeAChange'), path: ROUTE.MAKE_CHANGE };
  const changeCarDetailsCrumbs = { label: t('breadcrumbs.carDetails'), path: ROUTE.CAR_DETAILS };
  const changeRegistrationNumberCrumbs = {
    label: t('breadcrumbs.registrationNumber'),
    path: ROUTE.CHANGE_YOUR_REG_NO,
  };
  const changeYourCarCrumbs = {
    label: t('breadcrumbs.changeYourCar'),
    path: ROUTE.CHANGE_YOUR_CAR,
  };
  const changeCarMileageAndUsageCrumbs = {
    label: t('breadcrumbs.carMileageAndUsage'),
    path: ROUTE.CAR_MILEAGE_AND_USAGE,
  };
  const changeCarModificationsCrumbs = {
    label: t('breadcrumbs.carModifications'),
    path: ROUTE.CAR_MODIFICATIONS,
  };
  const changeOtherChangesCrumbs = {
    label: t('breadcrumbs.otherChanges'),
    path: ROUTE.OTHER_CHANGES,
  };
  const changeYourDetailsCrumbs = { label: t('breadcrumbs.changeYourDetails'), path: ROUTE.YOUR_DETAILS };
  const changeHomeAddressCrumbs = {
    label: t('breadcrumbs.changeHomeAddress'),
    path: ROUTE.CHANGE_HOME_ADDRESS,
  };
  const changePersonalDetailsCrumbs = {
    label: t('breadcrumbs.changePersonalDetails'),
    path: ROUTE.CHANGE_PERSONAL_DETAILS,
  };
  const changeDriversCrumbs = { label: t('breadcrumbs.changeDrivers'), path: ROUTE.DRIVERS };
  const changeAddDriversCrumbs = { label: t('breadcrumbs.addDriver'), path: ROUTE.ADD_DRIVER };
  const changeRemoveDriversCrumbs = { label: t('breadcrumbs.removeDriver'), path: ROUTE.REMOVE_DRIVER };
  const settingsCrumbs = { label: t('breadcrumbs.settings'), path: ROUTE.ACCOUNT };
  const settingsAccountsCrumbs = { label: t('breadcrumbs.account'), path: ROUTE.ACCOUNT };
  const settingsCommunicationCrumbs = { label: t('breadcrumbs.communication'), path: ROUTE.COMMUNICATION };
  const documentsCrumbs = { label: t('breadcrumbs.documents'), path: ROUTE.DOCUMENTS };
  const olderDocumentsCrumbs = { label: t('breadcrumbs.olderDocuments'), path: ROUTE.OLDER_DOCUMENTS };
  const paymentsCrumbs = { label: t('breadcrumbs.payments'), path: ROUTE.PAYMENTS };
  const paymentMethodsCrumbs = { label: t('breadcrumbs.paymentMethods'), path: ROUTE.PAYMENT_METHODS };
  const yourQuoteRenewalCrumbs = { label: t('breadcrumbs.yourRenewal'), path: ROUTE.RENEWAL_QUOTE_DETAILS };
  const autoRenewalCrumbs = { label: t('breadcrumbs.autoRenewal'), path: ROUTE.YOUR_RENEWAL_DETAILS };
  const autoRenewalChangePendingCrumbs = { label: t('breadcrumbs.autoRenewal'), path: ROUTE.RENEWAL_CHANGE_PENDING };
  const typesOfUseCrumbs = { label: t('breadcrumbs.typesOfUse'), path: ROUTE.TYPES_OF_USE };
  const ifYouClaimCrumbs = { label: t('breadcrumbs.ifYouClaim'), path: ROUTE.IF_YOU_CLAIM };
  const whatsIncluded = { label: t('breadcrumbs.whatsIncluded'), path: ROUTE.WHATS_INCLUDED };
  const yourRenewalQuotesCrumbs = { label: t('breadcrumbs.yourRenewalQuotes'), path: ROUTE.YOUR_RENEWAL_QUOTES };
  const quoteIneligableCrumbs = { label: t('breadcrumbs.yourQuote'), path: ROUTE.QUOTE_INELIGIBLE };

  const breadcrumbsRouteMap = [
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs],
      path: ROUTE.POLICY_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, quoteIneligableCrumbs],
      path: ROUTE.QUOTE_INELIGIBLE,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, whatsIncluded],
      path: ROUTE.WHATS_INCLUDED,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, ifYouClaimCrumbs],
      path: ROUTE.IF_YOU_CLAIM,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, typesOfUseCrumbs],
      path: ROUTE.TYPES_OF_USE,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, autoRenewalCrumbs],
      path: ROUTE.YOUR_RENEWAL_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, autoRenewalChangePendingCrumbs],
      path: ROUTE.RENEWAL_CHANGE_PENDING,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, yourRenewalQuotesCrumbs],
      path: ROUTE.YOUR_RENEWAL_QUOTES,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, autoRenewalCrumbs],
      path: ROUTE.YOUR_RENEWAL_DETAILS_AUTORENEWAL_CONFIRMATION,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, paymentsCrumbs],
      path: ROUTE.PAYMENTS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, paymentsCrumbs],
      path: ROUTE.POLICY_BREAKDOWN,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, paymentsCrumbs],
      path: ROUTE.INSTALMENTS,
    },
    {
      breadcrumbs: [settingsCrumbs, paymentMethodsCrumbs],
      path: ROUTE.PAYMENT_METHODS,
    },
    {
      breadcrumbs:
        // if pending quote
        policyRenewalQuoteDetails.pendingMTAQuote
          ? [policiesCrumbs, policyCrumbs, yourRenewalQuotesCrumbs, yourQuoteRenewalCrumbs]
          : [policiesCrumbs, policyCrumbs, yourQuoteRenewalCrumbs],
      path: ROUTE.RENEWAL_QUOTE_DETAILS,
    },
    {
      breadcrumbs: [],
      path: ROUTE.YOUR_SAVED_QUOTE,
    },
    {
      breadcrumbs: [],
      path: ROUTE.YOUR_RENEWAL_SUMMARY,
    },
    {
      breadcrumbs: [],
      path: ROUTE.YOUR_COVER_DETAILS,
    },
    {
      breadcrumbs: [],
      path: ROUTE.RENEWAL_PAYMENT,
    },
    {
      breadcrumbs: [],
      path: ROUTE.YOUR_NEW_QUOTE,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, documentsCrumbs, olderDocumentsCrumbs],
      path: ROUTE.OLDER_DOCUMENTS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, documentsCrumbs],
      path: ROUTE.DOCUMENTS,
    },
    {
      breadcrumbs: [settingsCrumbs, settingsCommunicationCrumbs],
      path: ROUTE.COMMUNICATION,
    },
    {
      breadcrumbs: [settingsCrumbs, settingsAccountsCrumbs],
      path: ROUTE.ACCOUNT,
    },
    {
      breadcrumbs: [settingsCrumbs],
      path: ROUTE.ACCOUNT,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeDriversCrumbs, changeAddDriversCrumbs],
      path: ROUTE.ADD_DRIVER,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeDriversCrumbs, changeRemoveDriversCrumbs],
      path: ROUTE.REMOVE_DRIVER,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeDriversCrumbs],
      path: ROUTE.DRIVERS,
    },
    {
      breadcrumbs: [
        policiesCrumbs,
        policyCrumbs,
        makeAChangeCrumbs,
        changeYourDetailsCrumbs,
        changePersonalDetailsCrumbs,
      ],
      path: ROUTE.CHANGE_PERSONAL_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeYourDetailsCrumbs, changeHomeAddressCrumbs],
      path: ROUTE.CHANGE_HOME_ADDRESS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeYourDetailsCrumbs],
      path: ROUTE.YOUR_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeCarDetailsCrumbs, changeOtherChangesCrumbs],
      path: ROUTE.OTHER_CHANGES,
    },
    {
      breadcrumbs: [
        policiesCrumbs,
        policyCrumbs,
        makeAChangeCrumbs,
        changeCarDetailsCrumbs,
        changeCarModificationsCrumbs,
      ],
      path: ROUTE.CAR_MODIFICATIONS,
    },
    {
      breadcrumbs: [
        policiesCrumbs,
        policyCrumbs,
        makeAChangeCrumbs,
        changeCarDetailsCrumbs,
        changeCarMileageAndUsageCrumbs,
      ],
      path: ROUTE.CAR_MILEAGE_AND_USAGE,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeCarDetailsCrumbs, changeYourCarCrumbs],
      path: ROUTE.CHANGE_YOUR_CAR,
    },
    {
      breadcrumbs: [
        policiesCrumbs,
        policyCrumbs,
        makeAChangeCrumbs,
        changeCarDetailsCrumbs,
        changeRegistrationNumberCrumbs,
      ],
      path: ROUTE.CHANGE_YOUR_REG_NO,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs, changeCarDetailsCrumbs],
      path: ROUTE.CAR_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs, makeAChangeCrumbs],
      path: ROUTE.MAKE_CHANGE,
    },
    {
      breadcrumbs: [policiesCrumbs, policyCrumbs],
      path: ROUTE.POLICY_DETAILS,
    },
    {
      breadcrumbs: [policiesCrumbs],
      path: ROUTE.DASHBOARD,
    },
  ];

  return breadcrumbsRouteMap;
};
