import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { DownloadInsuranceDocument } from '../../../../../../component/common/button/downloadInsuranceDocument';
import CommonAccordion, { CommonAccordionProps } from '../../../../../../component/common/commonAccordion';
import { HowMuchYouAreCovered } from '../../../../../../component/layout/howMuchYouAreCoverd';
import { DOCUMENT_TYPE_CODE, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { MotorRenewalNotification } from '../../renewals/components/motorRenewalNotification';

import { PolicyExcesses } from './excesses';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  excessesContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  expireNoticeContainer: {
    marginTop: theme.spacing(2),
  },
  howMuchYouAreCoveredContainer: {
    marginTop: theme.spacing(4),
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-1, -2, 0),
    },
  },
}));

export const IfYouClaim: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const [open, setOpen] = useState<number | null>(null);

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policyCover: { ncdpPack, upgradedCourtesyCar, standardCourtesyCar },
          policySelected,
          mainVehicle: { ncdYears },
        },
      },
    },
  } = useStores();

  const accordions: CommonAccordionProps[] = [
    {
      columns: ncdpPack
        ? [
            {
              list: t('ifYouClaim.ncdProtection.protected.protectedList', { returnObjects: true }),
              title: t('ifYouClaim.ncdProtection.protected.title'),
            },
            {
              icon: 'times',
              list: t('ifYouClaim.ncdProtection.protected.notIncluded.notIncludedList', { returnObjects: true }),
              title: t('ifYouClaim.ncdProtection.protected.notIncluded.title'),
            },
          ]
        : [
            {
              list: t('ifYouClaim.ncdProtection.notProtected.firstColumn', { returnObjects: true }),
              title: t('ifYouClaim.ncdProtection.notProtected.title'),
            },
            {
              list: t('ifYouClaim.ncdProtection.notProtected.secondColumn', { returnObjects: true }),
            },
          ],
      headerIcon: 'shield-check-line',
      headerIconType: 'solid',
      headerInfo: `${ncdYears} ${t('ifYouClaim.ncdProtection.yearsNcd')}`,
      headerLabel: ncdpPack ? t('policyStatus.protected') : t('policyStatus.notProtected'),
      headerLabelColour: ncdpPack ? 'success' : 'error',
      headerTitle: t('ifYouClaim.ncdProtection.title'),
    },
  ];

  const standardCourtesyAccordion: CommonAccordionProps = {
    columns: [
      {
        list: t('ifYouClaim.courtesyCar.includedItems', { returnObjects: true }),
        title: t('ifYouClaim.courtesyCar.thisIncludes'),
      },
      {
        icon: 'times',
        list: t('ifYouClaim.courtesyCar.notIncludedItems', { returnObjects: true }),
        title: t('ifYouClaim.courtesyCar.notIncluded'),
      },
    ],
    headerIcon: 'hire-car',
    headerIconType: 'solid',
    headerLabel: t('policyStatus.included'),
    headerLabelColour: 'success',
    headerTitle: t('ifYouClaim.courtesyCar.title'),
  };

  const upgradedCourtesyAccordion: CommonAccordionProps = {
    columns: [
      {
        list: t('ifYouClaim.upgradedCourtesyCar.includedItemsCol1', { returnObjects: true }),
        title: t('ifYouClaim.upgradedCourtesyCar.ifYourCar'),
      },
      {
        icon: 'check',
        list: t('ifYouClaim.upgradedCourtesyCar.includedItemsCol2', { returnObjects: true }),
      },
    ],
    downloadLink: (
      <DownloadInsuranceDocument
        documentTypeCode={DOCUMENT_TYPE_CODE.MOTOR_IPID_CHIRE}
        buttonName={t('ifYouClaim.upgradedCourtesyCar.downloadLink')}
      />
    ),
    headerIcon: 'hire-car',
    headerIconType: 'solid',
    headerLabel: t('policyStatus.included'),
    headerLabelColour: 'success',
    headerTitle: t('ifYouClaim.upgradedCourtesyCar.title'),
  };

  standardCourtesyCar && accordions.push(standardCourtesyAccordion);
  upgradedCourtesyCar && accordions.push(upgradedCourtesyAccordion);

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('breadcrumbs.ifYouClaim')} route={ROUTE.POLICY_DETAILS} />
      <Grid item xs={12} className={classes.expireNoticeContainer}>
        {policySelected.futureRenewalEffectiveDate === null && <MotorRenewalNotification policy={policySelected} />}
      </Grid>
      <Grid item xs={12} className={classes.howMuchYouAreCoveredContainer}>
        <HowMuchYouAreCovered />
      </Grid>
      <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
        {accordions.map((props, index) => (
          <CommonAccordion
            key={Symbol(index).toString()}
            onChange={() => setOpen(open === index ? null : index)}
            expanded={open === index}
            {...props}
          />
        ))}
      </Grid>
      <Grid item xs={12} className={classes.excessesContainer}>
        <PolicyExcesses />
      </Grid>
    </Grid>
  );
});
