import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { CloseBtn } from '../../common/button/closeBtn';
import { CommonButton } from '../../common/button/commonButton';

import { DialogTemplate } from './dialogTemplate';

export const SpeakToSpecialistDialog: React.FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation('myAccount');

  return (
    <DialogTemplate
      topImage={<SVGWrapper alt="chat-logo" src="speakToSpecialist.svg" height="25%" width="25%" />}
      title={title}
      bottomContent={t('speakToSpecialistPopup.bottomContent')}
      buttons={[
        <CommonButton
          isExternalURL={true}
          text={t('claims.old.phone')}
          startIcon={'phone'}
          iconType="solid"
          size="large"
          url={`tel:${t('claims.old.phone')}`}
        />,
        <CloseBtn />,
      ]}
    />
  );
};
