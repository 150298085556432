import { CommonContainer, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from '@esure-cloud/react-components';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  bodyPlaceholder: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 1),
    },
    padding: theme.spacing(4, 8, 2),
  },
  borderRadius1: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(1.2),
    },
  },
  marginBottom1: {
    marginBottom: theme.spacing(1),
  },
  margins: {
    margin: theme.spacing(0, 1, 2, 0),
  },
  paddingRight1: {
    paddingRight: theme.spacing(1),
  },
  policyCardPlaceholder: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(-4, -2, 0),
    },
  },
  sectionTitlePlaceholder: {
    padding: theme.spacing(3, 0, 2),
  },
}));

export const PolicyPageSkeleton: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const NavCard = () => {
    return (
      <CommonContainer>
        <Grid>
          <Hidden xsDown>
            <Grid container>
              <Skeleton variant="circle" width="2.5%" className={classes.margins} />
              <Skeleton variant="rect" width="15%" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" width="100%" />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Skeleton variant="circle" width="14%" className={classes.margins} />
            <Skeleton variant="rect" width="80%" />
          </Hidden>
        </Grid>
      </CommonContainer>
    );
  };

  const SmallNavCard = () => {
    return (
      <CommonContainer>
        <Grid>
          <Grid item>
            <Skeleton variant="circle" width={isSmallDevice ? '14%' : '4%'} className={classes.margins} />
          </Grid>
          <Grid item className={classes.marginBottom1}>
            <Skeleton variant="rect" width={isSmallDevice ? '80%' : '13%'} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width="100%" />
          </Grid>
        </Grid>
      </CommonContainer>
    );
  };

  const SectionTitle = () => {
    return (
      <Grid className={classes.sectionTitlePlaceholder}>
        <Skeleton variant="rect" width={151} height={17} />
      </Grid>
    );
  };

  return (
    <>
      <Grid>
        <Grid item className={classes.policyCardPlaceholder}>
          <Skeleton variant="rect" width="100%" height={isMobile ? 479 : 166} className={classes.borderRadius1} />
        </Grid>
        <SectionTitle />
        <Grid container>
          <Grid item xs={6} className={classes.paddingRight1}>
            <NavCard />
          </Grid>
          <Grid item xs={6}>
            <NavCard />
          </Grid>
        </Grid>
        <SectionTitle />
        <Grid container>
          <Grid item xs={6} sm={4} className={classes.paddingRight1}>
            <SmallNavCard />
          </Grid>
          <Grid item xs={6} sm={4} className={classes.paddingRight1}>
            <SmallNavCard />
          </Grid>
          <Hidden xsDown>
            <Grid item xs={4}>
              <SmallNavCard />
            </Grid>
          </Hidden>
        </Grid>
        <SectionTitle />
        <Grid item xs={12} sm={12} md={6}>
          <CommonContainer>
            <Grid container>
              <Grid item xs={11}>
                <Grid item>
                  <Skeleton variant="circle" width={isSmallDevice ? '6%' : '3%'} className={classes.margins} />
                </Grid>
                <Grid item className={classes.marginBottom1}>
                  <Skeleton variant="rect" width={isSmallDevice ? '50%' : '70%'} />
                </Grid>
              </Grid>
            </Grid>
          </CommonContainer>
        </Grid>
      </Grid>
    </>
  );
};
