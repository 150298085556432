import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: theme.spacing(1),
  },
}));

export interface PolicyExtras {
  amount: number;
  code: string;
  details?: string;
  item: string;
}

export interface PolicyData {
  items?: PolicyExtras[];
  title: string;
}

export interface ContainerMultipleLinesProps {
  dataPolicy: PolicyData;
}

export const ContainerMultipleLines: React.FC<ContainerMultipleLinesProps> = ({ dataPolicy }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <CommonContainer color="secondary" variant="outlined">
      <Grid alignItems="flex-start" container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography align="left" color="textPrimary" variant="h4">
            {dataPolicy.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" item justifyContent="space-between" className={classes.itemsContainer}>
        {dataPolicy.items?.map((data, index) => {
          return (
            <Grid alignItems="flex-start" container item justifyContent="space-between" key={Symbol(index).toString()}>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  {data.item}
                </Typography>
              </Grid>
              <Grid item>
                {data.details ? (
                  <Typography color="textSecondary" variant="body2">
                    {data.details}
                    {data.amount > -1 && <>{t('core:price.poundNoDecimal', { value: data.amount })}</>}
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="body2">
                    {t('core:price.poundNoDecimal', { value: data.amount })}
                  </Typography>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </CommonContainer>
  );
};
