import { TrackProvider, useTheme } from '@esure-cloud/react-components';

import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk';
import { Settings as luxonSettings } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ChatBotWidget } from '../../component/common/chatBotWidget/chatBotWidget';
import { CookieWrapper } from '../../component/cookies/CookieWrapper';
import { getToken, initializeBridge } from '../../lib/caura-bridge';
import { initOptimizely, setUser } from '../../lib/optimizely/';
import initSatismeter from '../../lib/satismeter';
import { getDeviceSession } from '../../service/analytics';
import { PARAMS_CODE, ROUTE } from '../../service/constant';
import { fetchFeatureToggles } from '../../service/network/apiRequests';
import { useStores } from '../../service/state/store';
import { EventProps, TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';
import { LOCAL_TIMEZONE } from '../../service/util/dateUtils';
import { manageLogout } from '../../service/util/handleLogout';
import { decodeToken } from '../../service/util/jwtUtils';

import { HomePage } from './homePage';
import { Landing } from './landing';
import { Login } from './login';

/**
 * For browser automation testing purposes
 */
declare global {
  interface Window {
    __tests__: {
      setCauraMode: (value: boolean) => void;
    };
  }
}

export const App: React.FC = observer(function App() {
  const history = useHistory();
  const theme = useTheme();
  const { clearAllStores, interfaceStore, userStore } = useStores();
  const [optimizelyClient, setOptimizelyClient] = useState<ReactSDKClient | undefined>(initOptimizely);
  const [togglesLoaded, setTogglesLoaded] = useState(false);
  const [tokenInitialised, setTokenInitialised] = useState(false);
  const { eventTrack } = useSegment();

  useEffect(() => {
    interfaceStore.setBrand(theme.themeName);
  }, [interfaceStore]);

  /**
   * This is only for browser test automation
   * @param value
   */
  window.__tests__ = {
    setCauraMode: (value: boolean) => interfaceStore.setIsCaura(value),
  };

  function loadIovationScript() {
    const script = document.createElement('script');

    script.src = './scripts/iovation-loader.js';
    script.async = true;

    document.body.appendChild(script);
  }

  useEffect(() => {
    onPathChange();
    if (interfaceStore.expireDate > 0 && interfaceStore.expireDate - 1000 * 60 * 10 < Date.now()) {
      manageLogout();
      clearAllStores();
    }

    // clear both localStorage and stores for refreshing users in different tab in same browser(SSOP-2564)
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('accessCode') || searchParams.has('activationCode')) {
      clearAllStores();
      localStorage.clear();
    }

    eventTrack('Portal Loaded', { type: TRACK_TYPE.INFO });
    loadIovationScript();
  }, []);

  useEffect(() => {
    /* Satismeter code */
    interfaceStore.setPagesViewed(0);
  }, []);

  // If we have existing chat details, then show the minimised icon
  useEffect(() => {
    if (
      interfaceStore.chatBot.participantToken &&
      interfaceStore.chatBot.contactId &&
      interfaceStore.chatBot.participantId
    ) {
      interfaceStore.chatBot.setMinimised(true);
    }
  }, [interfaceStore.chatBot]);

  useEffect(() => {
    const onTokenUpdate = () => {
      const token = getToken();
      if (token) interfaceStore.setAccessToken(token);
    };
    initializeBridge(onTokenUpdate).then(() => {
      const token = getToken();
      if (token) interfaceStore.setAccessToken(token);
      if (interfaceStore.accessToken) {
        const { customerId, customerEmail, expireTime } = decodeToken(interfaceStore.accessToken);
        const expireDate = expireTime * 1000;
        const isLoggedIn = expireDate >= Date.now();

        if (isLoggedIn) {
          if (!tokenInitialised) {
            setTokenInitialised(true);
            userStore.user.setUserLoggedIn(isLoggedIn);
            interfaceStore.setExpireDate(expireDate);
            //Initialise user from user store with data that comes from forgerock access token
            userStore.user.setUserId(customerId);
            userStore.user.setEmail(customerEmail);

            //satismeter
            initSatismeter(customerId);

            //pass to optimisely
            setUser(customerId);

            eventTrack('Login Completed', {
              customerNumber: customerId,
              description: 'Login succeeded and goal reached',
              goal: 'Login',
              step: 'login-completed',
            });

            //Identify logged user in Segment and track event when application is initialised
            window.analytics.identify(customerId);
          }
        }
      } else {
        setTokenInitialised(false);
      }
    });
  }, [interfaceStore.accessToken, tokenInitialised]);

  const onPathChange = () => {
    history.listen(() => {
      interfaceStore.onPathChange();
      window.scrollTo(0, 0);
    });
  };

  const handleResize = useCallback(() => {
    interfaceStore.setBreakpoints();
  }, [interfaceStore]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const duration = 1000 * 60 * 60 * 6; // 6 hours
    const handleLogout = () => {
      clearTimeout(interactionIntervalID);
      manageLogout();
      clearAllStores();
    };
    const interactionIntervalID = setTimeout(handleLogout, duration);
    return () => {
      clearTimeout(interactionIntervalID);
    };
  }, [clearAllStores]);

  useEffect(() => {
    let mounted = true;
    if (!togglesLoaded) {
      fetchFeatureToggles().then((data) => {
        if (mounted) {
          setTogglesLoaded(true);
          interfaceStore.setFeatureToggles(data);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [interfaceStore, interfaceStore.setFeatureToggles, togglesLoaded]);

  const renderPage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isMagicLink = Object.values(PARAMS_CODE).some((param) => searchParams.get(param));

    switch (true) {
      case userStore.user.userLoggedIn && togglesLoaded:
        return optimizelyClient ? (
          <OptimizelyProvider
            optimizely={optimizelyClient}
            user={{
              id: userStore.user.userId,
            }}
          >
            <HomePage />
          </OptimizelyProvider>
        ) : (
          <HomePage />
        );
      case isMagicLink || window.location.pathname === `${process.env.PUBLIC_URL}${ROUTE.LOGIN}`:
        return <Login />;
      case !userStore.user.userLoggedIn:
        return <Landing />;
    }
  };

  document.addEventListener('loadAnalytics', () => {
    setOptimizelyClient(initOptimizely());
  });

  const { eventTrackInteraction } = useSegment();

  useEffect(() => {
    luxonSettings.defaultZoneName = LOCAL_TIMEZONE;
  }, [luxonSettings]);

  const searchParams = new URLSearchParams(window.location.search);
  const isChatModeOnly = searchParams.get('launchChat') === 'true';
  if (isChatModeOnly) {
    setTimeout(() => interfaceStore.chatBot.startChat({ initiator: 'Caura app' }), 500);
    return (
      <ChatBotWidget
        initiated={interfaceStore.chatBot.initiated}
        hasNotification={interfaceStore.chatBot.hasNotification}
        minimised={interfaceStore.chatBot.minimised}
        setIsHidden={interfaceStore.chatBot.setIsHidden}
      />
    );
  }

  return (
    <>
      <CookieWrapper>
        <TrackProvider
          getSessionId={getDeviceSession}
          track={(eventName, data) => {
            eventTrackInteraction(eventName, data as EventProps);
          }}
        >
          {renderPage()}
        </TrackProvider>
        <ChatBotWidget
          initiated={interfaceStore.chatBot.initiated}
          hasNotification={interfaceStore.chatBot.hasNotification}
          minimised={interfaceStore.chatBot.minimised}
          setIsHidden={interfaceStore.chatBot.setIsHidden}
        />
      </CookieWrapper>
    </>
  );
});
