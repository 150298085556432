import { Grid, Typography, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonAccordion, { CommonAccordionProps } from '../../../../../../../component/common/commonAccordion';
import { useStores } from '../../../../../../../service/state/store';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export interface AccordionItem {
  accordion: (value: number) => CommonAccordionProps;
  codes: string[];
}

export const PolicyLimits: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const [open, setOpen] = useState<number | null>(null);

  const {
    interfaceStore: { isDesktop },
    dashboardStore: {
      subCoverages,
      insurance: {
        auto: {
          policyCover: { accidentPerPeriod, accidentPerPerson },
        },
      },
    },
  } = useStores();

  const personalAccidentPayList = [
    t('personalAccident.payFor.perDriver', {
      value: t('core:price.poundNoDecimal', { value: accidentPerPerson?.limitAmount }),
    }),
    t('personalAccident.payFor.perPeriod', {
      value: t('core:price.poundNoDecimal', { value: accidentPerPeriod?.limitAmount }),
    }),
  ].filter((item) => !item.includes('NaN'));

  const accordions: AccordionItem[] = [
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('carAudioEquipment.coverIncludes.included', { returnObjects: true }),
            title: t('carAudioEquipment.coverIncludes.title'),
          },
          {
            icon: 'circle',
            list: [t('carAudioEquipment.pleaseNote.notes', { value: t('core:price.poundNoDecimal', { value }) })],
            title: t('carAudioEquipment.pleaseNote.title'),
          },
        ],
        headerIcon: 'audio',
        headerInfo: t('carAudioEquipment.description', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('carAudioEquipment.title'),
      }),
      codes: [
        'fire_carAudioNavigationEntertainmentSystem',
        'theft_carAudioNavigationEntertainmentSystem',
        'ad_carAudioNavigationEntertainmentSystem',
      ],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('stolenCarKeys.coverIncludes.included', { returnObjects: true }),
            title: t('stolenCarKeys.coverIncludes.title'),
          },
          {
            icon: 'circle',
            list: t('stolenCarKeys.payUpTo.payForList', { returnObjects: true }),
            title: t('stolenCarKeys.payUpTo.title', { value: t('core:price.poundNoDecimal', { value }) }),
          },
        ],
        footerSubtitle: t('stolenCarKeys.pleaseNote.description'),
        footerTitle: t('stolenCarKeys.pleaseNote.title'),
        headerIcon: 'keys',
        headerInfo: t('stolenCarKeys.description', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('stolenCarKeys.title'),
      }),
      codes: ['theft_stolenCarKeys'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('personalAccident.coverIncludes.included', { returnObjects: true }),
            title: t('personalAccident.coverIncludes.title'),
          },
          {
            icon: 'circle',
            list: personalAccidentPayList,
            title: t('personalAccident.payFor.title'),
          },
        ],
        headerIcon: 'accident',
        headerInfo: t('personalAccident.description', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('personalAccident.title'),
      }),
      codes: ['ad_personalAccident', 'ad_personalAccidentPerPeriod'],
    },
    {
      accordion: (value: number) => ({
        headerIcon: 'medical',
        headerInfo: t('medicalExpenses.description', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('medicalExpenses.title'),
        summary: t('medicalExpenses.content', { value: t('core:price.poundNoDecimal', { value }) }),
      }),
      codes: ['tpd_emergencyMedicalExpenses', 'tpi_emergencyMedicalExpenses', 'ad_medicalExpenses'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('personalBelongings.coverIncludes.included', { returnObjects: true }),
            title: t('personalBelongings.coverIncludes.title'),
          },
          {
            icon: 'circle',
            list: t('personalBelongings.pleaseNote.notes', { returnObjects: true }),
            title: t('personalBelongings.pleaseNote.title'),
          },
        ],
        headerIcon: 'personal',
        headerInfo: t('personalBelongings.description', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('personalBelongings.title'),
      }),
      codes: ['ad_personalBelongings'],
    },
  ];

  return (
    <>
      <Grid data-testid="howmuchyouarecovered-Container" className={classes.gridContainer} container>
        <Grid item xs={12}>
          <Typography variant={isDesktop ? 'body1' : 'body2'}>{t('policyLimits.description')}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
        {subCoverages.policyExtras.map((item, index) => {
          const accordion = accordions.find((x) => x.codes.includes(item.code))?.accordion;

          return accordion ? (
            <CommonAccordion
              key={Symbol(index).toString()}
              onChange={() => setOpen(open === index ? null : index)}
              expanded={open === index}
              {...accordion(item.amount)}
            />
          ) : null;
        })}
      </Grid>
    </>
  );
});
