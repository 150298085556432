import { Grid, alpha, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarFoundDetails from '../../../../../../../../component/common/mta/carFoundDetails';
import { useChatBotAttributes } from '../../../../../../../../component/common/utils/useChatBotAttributes';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

import { CarRegistrationManualChange } from './carRegistrationManualChange';
import { DvlaCheck } from './dvlaCheck';

const useStyles = makeStyles(({ palette, spacing }) => ({
  box: {
    '& .car-properties': {
      '&__item': {
        '&:first-child': {
          paddingRight: spacing(2),
        },
        '&:not(:first-child)': {
          '&:before': {
            background: alpha(palette.common.black, 0.26),
            content: `''`,
            height: 15,
            left: -1,
            position: 'absolute',
            top: 6,
            width: 2,
          },
          padding: spacing(0, 2),
        },
        position: 'relative',
      },
      backgroundColor: palette.grey[100],
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    '& .content': {
      '&__grid': {
        gap: 18,
        width: 'fit-content',
      },
      '&__subtitle': {
        marginBottom: spacing(1),
      },
      borderColor: alpha(palette.secondary.main, 0.5),
      borderTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .title-box': {
      backgroundColor: alpha(palette.secondary.main, 0.08),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: alpha(palette.secondary.main, 0.5),
    },
  },
}));

interface CarLookupResultProps {
  changeCarJourney?: boolean;
}

export const CarLookupResult: React.FC<CarLookupResultProps> = observer(function CarLookupResult({ changeCarJourney }) {
  const [manualRegistrationChange, setManualRegistrationChange] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const {
    mtaStore: {
      pendedMTA: { vehicle, isCarRegistrationManuallyChanged, vehicleFound },
    },
    interfaceStore: {
      isMobile,
      chatBot: { startChat },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const chatBotAttr = useChatBotAttributes();

  const { setTitle, setStep, step } = useStepperContext();

  useEffect(() => {
    if (manualRegistrationChange && vehicleFound) {
      setTitle(t('MTAJourney.CarRegistration.carFound.foundInDvla.title'));
    } else if (isCarRegistrationManuallyChanged) {
      setTitle(t('MTAJourney.CarRegistration.carRegistrationManualChange.title'));
    } else if (!vehicleFound) {
      setTitle(t('MTAJourney.CarRegistration.carNotFound.missingInDvla.title'));
    } else {
      setTitle(t('MTAJourney.CarFound.checkDetails.regFoundTitle'));
    }
    window.scrollTo(0, 0);
  }, [manualRegistrationChange, isCarRegistrationManuallyChanged]);

  const handleChat = useCallback(() => {
    const journeyType = JOURNEY_TYPE.CHANGE_YOUR_CAR;
    startChat({
      initiator: `Not my car - ${journeyType} - step 3`,
      ...(chatBotAttr ?? {}),
    });
    eventTrack('Not my car speak to specialist link clicked', { journeyType });
  }, [startChat, eventTrack, chatBotAttr]);

  if (isCarRegistrationManuallyChanged)
    return <CarRegistrationManualChange setManualRegistrationChange={setManualRegistrationChange} />;

  return vehicleFound ? (
    <Grid item className={classes.box} xs={isMobile ? 12 : 6}>
      <CarFoundDetails
        vehicle={vehicle}
        manualRegistrationChange={manualRegistrationChange}
        setManualRegistrationChange={setManualRegistrationChange}
        changeCarJourney={changeCarJourney}
        handleChat={handleChat}
      />
      {!manualRegistrationChange && (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_FOUND_CAR}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_FOUND_CAR}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_FOUND_CAR }}
        />
      )}
    </Grid>
  ) : (
    <DvlaCheck registrationNo={vehicle.vrm} handleBack={() => setStep(step - 1)} />
  );
});
