import { useTheme } from '@esure-cloud/react-components';

import { THEME_NAME } from '../service/constant';

export interface ImageProps {
  alt?: string;
  className?: string;
  height?: string | number;
  src: string;
  width?: string | number;
}

export const SVGWrapper: React.FC<ImageProps> = ({ alt = '', className = '', height = '', src, width = '' }) => {
  const theme = useTheme();
  const isSheilas = theme.themeName === THEME_NAME.SHEILASWHEELS;
  const isFa = theme.themeName === THEME_NAME.FIRSTALTERNATIVE;
  let imgPath = './assets/images';
  if (isSheilas) imgPath += '/sheilasWheels';
  if (isFa) imgPath += '/firstAlternative';
  return <img alt={alt} className={className} src={imgPath + '/' + src} width={width} height={height} />;
};
