import { Instance, types } from 'mobx-state-tree';

import { PANEL_TYPE } from '../../../constant';

export const Panel = types
  .model('Panel', {
    open: types.optional(types.boolean, false),
    packCode: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    type: types.maybeNull(types.enumeration<PANEL_TYPE>(Object.values(PANEL_TYPE))),
  })
  .actions((self) => ({
    closePanel() {
      self.title = '';
      self.type = null;
      self.packCode = '';
      self.open = false;
    },
    openPanel(options: PanelOptions) {
      self.title = options.title ?? '';
      self.type = options.type;
      self.packCode = options.packCode ?? '';
      self.open = true;
    },
  }));

export type IPanel = Instance<typeof Panel>;
export interface PanelOptions {
  packCode?: string;
  title?: string;
  type: PANEL_TYPE;
}
