import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CarModifiedQuestion } from '../../../../../../../../component/common/carModifiedQuestion';
import { IModification } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { CarModifications } from '../../carDetails/components/carModifications';
import { EditFooter } from '../../common/components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& .error': {
      color: palette.error.dark,
    },
    '& .radio-section': {
      display: 'grid',
      gap: 20,
      width: 'fit-content',
    },
  },
}));

export const EditCarModified: React.FC<EditPageProps> = observer(function EditCarModified({ handleBack }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { setTitle } = useStepperContext();

  const {
    mtaStore: {
      pendedMTA: { setModifications, vehicle, setHasModifications },
    },
  } = useStores();
  const [state, setState] = useState<{ selections: IModification[] }>({ selections: [...vehicle.modifications] });

  const [wasCarModified, setWasCarModified] = useState<null | boolean>(vehicle.carModificationsInd);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setTitle(t('MTAJourney.CarModified.title'));
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'true';
    setWasCarModified(value);
  };
  const isValid = (): boolean => {
    return wasCarModified === null || (wasCarModified && state.selections.length === 0) ? false : true;
  };
  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      setModifications(state.selections);
      if (wasCarModified !== null) {
        setHasModifications(wasCarModified);
      }
    }
  };

  const handleSave = () => {
    handlePendedMta();
    isValid() && handleBack();
  };

  return (
    <Grid container item direction="column" className={classes.root}>
      <CarModifiedQuestion
        handleChangeRadio={handleChangeRadio}
        isSubmitting={isSubmitting}
        wasCarModified={wasCarModified}
      />

      {wasCarModified && (
        <CarModifications isSubmitting={isSubmitting} options={state} setOptions={setState} showTitle />
      )}

      <EditFooter id="editCarModified" validPage={isValid()} handleSave={handleSave} handleBack={handleBack} />
    </Grid>
  );
});
