export enum ROUTE {
  ACCOUNT = '/settings/account',
  ACTIVE_CLAIM = '/claims/active/:claimId',
  ACTIVE_CLAIM_SUMMARY = '/claims/summary/:claimId',
  ADD_DRIVER = '/policies/policy/make-a-change/drivers/add-driver',
  CAR_DETAILS = '/policies/policy/make-a-change/car-details',
  CAR_MILEAGE_AND_USAGE = '/policies/policy/make-a-change/car-details/car-mileage-and-usage',
  CAR_MODIFICATIONS = '/policies/policy/make-a-change/car-details/car-modifications',
  CHANGE_DRIVER_DETAILS = '/policies/policy/make-a-change/drivers/change-driver-details',
  CHANGE_EMPLOYMENT_DETAILS = '/policies/policy/make-a-change/your-details/employment-details',
  CHANGE_HOME_ADDRESS = '/policies/policy/make-a-change/your-details/home-address',
  CHANGE_PAYMENT = '/policies/policy/make-a-change/payment',
  CHANGE_PAYMENT_CONFIRMATION = '/policies/policy/make-a-change/payment-confirmation',
  CHANGE_PAYMENT_FAILED = '/policies/policy/make-a-change/payment-error',
  CHANGE_PERSONAL_DETAILS = '/policies/policy/make-a-change/your-details/personal-details',
  CHANGE_YOUR_CAR = '/policies/policy/make-a-change/car-details/change-your-car',
  CHANGE_YOUR_REG_NO = '/policies/policy/make-a-change/car-details/change-your-registration-number',
  CLAIMS = '/claims',
  CLAIMS_MOBILE_TAB = '/claims',
  COMMUNICATION = '/settings/communication',
  COOKIE_NOTICE = '/cookie-notice',
  COOKIE_NOTICE_CORE = '/cookie-notice-core',
  COOKIE_NOTICE_CORE_MANAGEMENT = '/cookie-notice-core/management',
  COOKIE_NOTICE_MANAGEMENT = '/cookie-notice/management',
  DASHBOARD = '/policies',
  DOCUMENTS = '/policies/policy/documents',
  DOCUMENTS_MOBILE_TAB = '/policies/documents',
  DRIVERS = '/policies/policy/make-a-change/drivers',
  EXCESSES = '/policies/policy/policy-details/if-you-claim/excesses',
  HOME_ACTIVE_CLAIM = '/claims/active-home/:claimId',
  HOME_CHECKER = '/claims/check-if-covered',
  HOME_EMERGENCY_COVERED = '/claims/home-emergency-covered',
  HOME_EMERGENCY_NOT_COVERED = '/claims/home-emergency-not-covered',
  HOW_TO_MAKE_A_CLAIM_HOME = '/how-to-make-a-claim',
  IF_YOU_CLAIM = '/policies/policy/policy-details/if-you-claim',
  INSTALMENTS = '/payments/direct-debit/instalments',
  LANDING = '/welcome',
  LEGAL_PROTECTION_COVERED = '/claims/legal-protection-covered',
  LEGAL_PROTECTION_NOT_COVERED = '/claims/legal-protection-not-covered',
  LOGIN = '/login',
  MAKE_CHANGE = '/policies/policy/make-a-change',
  MORE_CHANGES = '/policies/policy/make-a-change/more-changes',
  MTA_QUOTE_READY = '/policies/policy/make-a-change/quote-ready',
  MTA_REVIEW_CHANGES = '/policies/policy/make-a-change/review-changes',
  MY_ACCOUNT = '/my-account',
  OLDER_DOCUMENTS = '/documents/documents/older-versions',
  OTHER_CHANGES = '/policies/policy/make-a-change/car-details/other-changes',
  PAYMENTS = '/policies/policy/payments',
  PAYMENT_ACTIVITY = '/payments/direct-debit/payment-activity',
  PAYMENT_METHODS = '/payment-methods',
  PEST_COVERED = '/claims/pest-covered',
  PEST_NOT_COVERED = '/claims/pest-not-covered',
  POLICY_BREAKDOWN = '/payments/direct-debit/policy-breakdown',
  POLICY_DETAILS = '/policies/policy',
  POLICY_LIMITS = '/policies/policy/policy-details/if-you-claim/policy-limits',
  QUOTE_INELIGIBLE = '/quote-ineligible',
  REMOVE_DRIVER = '/policies/policy/make-a-change/drivers/remove-driver',
  RENEWAL_CHANGE_CONFIRMATION = '/policies/policy/renewal-change-confirmation',
  RENEWAL_CHANGE_PENDING = '/policies/policy/renewal-change-pending',
  RENEWAL_PAYMENT = '/policies/policy/your-renewal-payment',
  RENEWAL_PAYMENT_FAILED = '/policies/policy/renewal-payment-error',
  RENEWAL_PAYMENT_SUCCESS = '/policies/policy/renewal-payment-success',
  RENEWAL_QUOTE_DETAILS = '/policies/policy/your-quote-renewal',
  RENEWAL_SETUP_SUCCESS = '/policies/policy/renewal-setup-success',
  START_NEW_CLAIM = '/claims/start-a-new-claim',
  TYPES_OF_USE = '/policies/policy/policy-details/types-of-use',
  WHATS_INCLUDED = '/policies/policy/whats-included',
  YOUR_COVER_DETAILS = '/policies/policy/your-cover-details',
  YOUR_COVER_DETAILS_ADD_DRIVER = '/policies/policy/your-cover-details/add-driver',
  YOUR_COVER_DETAILS_CARS_AT_HOME = '/policies/policy/your-cover-details/cars-at-home',
  YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE = '/policies/policy/your-cover-details/car-mileage-and-usage',
  YOUR_COVER_DETAILS_CHANGE_CAR = '/policies/policy/your-cover-details/change-your-car',
  YOUR_COVER_DETAILS_CHANGE_MODIFICATIONS = '/policies/policy/your-cover-details/change-your-modifications',
  YOUR_COVER_DETAILS_CHANGE_REG = '/policies/policy/your-cover-details/change-your-registration',
  YOUR_COVER_DETAILS_CHANGE_SECURITY = '/policies/policy/your-cover-details/change-security-device',
  YOUR_COVER_DETAILS_CHILDREN_AT_HOME = '/policies/policy/your-cover-details/children-at-home',
  YOUR_COVER_DETAILS_DAYTIME_LOCATION = '/policies/policy/your-cover-details/daytime-location',
  YOUR_COVER_DETAILS_DRIVER_HISTORY = '/policies/policy/your-cover-details/driver-history',
  YOUR_COVER_DETAILS_DRIVER_LICENSE = '/policies/policy/your-cover-details/driver-license',
  YOUR_COVER_DETAILS_DRIVER_NAME = '/policies/policy/your-cover-details/driver-name',
  YOUR_COVER_DETAILS_DRIVER_OCCUPATION = '/policies/policy/your-cover-details/driver-occupation',
  YOUR_COVER_DETAILS_DRIVER_RELATIONSHIP = '/policies/policy/your-cover-details/driver-relationship',
  YOUR_COVER_DETAILS_HOME_ADDRESS = '/policies/policy/your-cover-details/home-address',
  YOUR_COVER_DETAILS_LEGAL_OWNER = '/policies/policy/your-cover-details/legal-owner',
  YOUR_COVER_DETAILS_MARITAL_STATUS = '/policies/policy/your-cover-details/marital-status',
  YOUR_COVER_DETAILS_NAME = '/policies/policy/your-cover-details/name',
  YOUR_COVER_DETAILS_OCCUPATION = '/policies/policy/your-cover-details/occupation',
  YOUR_COVER_DETAILS_OVERNIGHT_LOCATION = '/policies/policy/your-cover-details/overnight-location',
  YOUR_COVER_DETAILS_REGISTERED_KEEPER = '/policies/policy/your-cover-details/registered-keeper',
  YOUR_COVER_DETAILS_RELATIONSHIP_STATUS = '/policies/policy/your-cover-details/relationship-status',
  YOUR_COVER_DETAILS_REMOVE_DRIVER = '/policies/policy/your-cover-details/remove-driver',
  YOUR_COVER_DETAILS_RESIDENTIAL_STATUS = '/policies/policy/your-cover-details/residential-status',
  YOUR_COVER_DETAILS_YOUR_LICENSE = '/policies/policy/your-cover-details/your-license',
  YOUR_DETAILS = '/policies/policy/make-a-change/your-details',
  YOUR_NEW_QUOTE = '/policies/policy/your-new-quote',
  YOUR_RENEWAL_DECLINE = '/policies/policy/your-renewal-decline',
  YOUR_RENEWAL_DETAILS = '/policies/policy/your-renewal-details',
  YOUR_RENEWAL_DETAILS_AUTORENEWAL_CONFIRMATION = '/policies/policy/your-renewal-details/autorenewal-confirmation',
  YOUR_RENEWAL_QUOTES = '/policies/policy/your-renewal-quotes',
  YOUR_RENEWAL_SUMMARY = '/policies/policy/your-renewal-summary',
  YOUR_SAVED_QUOTE = '/policies/policy/your-saved-quote',
}

export enum RENEWAL_CHATBOT_PAGES {
  YOUR_QUOTE_RENEWAL = 'your-quote-renewal',
  YOUR_RENEWAL_DECLINE = 'your-renewal-decline',
}

export enum NEED_HELP_HELP_CENTRE_PAGES {
  POLICIES = ROUTE.DASHBOARD,
  POLICY = ROUTE.POLICY_DETAILS,
  RENEWAL = ROUTE.RENEWAL_QUOTE_DETAILS,
}

export enum LOOKUP {
  ACCESS_TO_OTHER_CARS = 'AccessToOtherCars',
  BODY_TYPE = 'BodyTypeCode',
  BUSINESS_TYPE = 'TypeOfBusiness',
  CARS_IN_HOUSEHOLD = 'CarsInHousehold',
  CAUSE_OF_LOSS = 'CauseOfLoss',
  CLAIM_BUILDOR = 'ClaimBuildorCont',
  CLAIM_TYPE = 'ClaimType',
  CONVICTION_CODE = 'ConvictionCode',
  COVERAGE_DESCRIPTION = 'CoverageDescription',
  COVER_TYPE = 'CoverType',
  DAYTIME_LOCATION = 'DaytimeLocation',
  DRIVER_RELATIONSHIP = 'RelationToInsured',
  EMPLOYMENT = 'Employment',
  FUEL_TYPE = 'FuelType',
  HOME_CLAIM_TYPE = 'HomeClaimType',
  HOME_COVER_TYPE = 'HomeCoverType',
  INDUSTRY = 'Industry',
  KEEPER = 'Keeper',
  LICENCE_TYPE = 'LicenceType',
  LICENSE_HELD_MONTHS = 'LicenceHeldMonths',
  LICENSE_HELD_YEARS = 'LicenceHeldYears',
  LICENSE_RESTRICTIONS = 'LicenceRestrictions',
  LISTED_BUILDINGS = 'ListedBuilding',
  MAIN_ENTRANCE_LOCK = 'MainEntLock',
  MARITAL_STATUS = 'MaritalStatus',
  MILEAGE = 'Mileage',
  MODIFICATIONS = 'Modifications',
  MOTOR_COMPANY_DECLINED_REASON = 'MotorCompanyDeclineReason',
  NUMBER_OF_CHILDREN = 'Children',
  OCCUPATION = 'Occupation',
  OVERNIGHT_LOCATION = 'OvernightLocation',
  OWNER = 'Owner',
  OWNERSHIP = 'OwnershipType',
  PORTAL_REG_CHANGE = 'PortalRegChange',
  PROPERTY_TYPE = 'PropertyType',
  RESIDENTIAL_STATUS = 'ResidentialStatus',
  RESIDENTS = 'OccupiedBy',
  ROOF_FLAT = 'RoofFlat',
  ROOF_TYPE = 'RoofType',
  TITLE = 'Title',
  TRACKER = 'Tracker',
  TRANSMISSION = 'Transmission',
  USAGE = 'Usage',
  VEHICLE_SECURITY = 'VehicleSecurity',
  WALL_TYPE = 'WallType',
  WINDOW_LOCK = 'WindowLock',
  YES_NO_UNKNOWN = 'YesNoUnknown',
}

export enum PACKS {
  BASE_FLEX_COMP = 'flexComp',
  BASE_TIA_COMP = 'comprehensive',
  CAR_HIRE_COVER = 'chcPack',
  FOREIGN_TRAVELLER = 'foreignTravellerPack',
  FULL_PROTECTION = 'fullProtectionPack',
  JUST_IN_CASE = 'justInCasePack',
  KEY_COVER_PACK = 'keyCoverPack',
  LONG_DISTANCE_TRAVELLER = 'longDistanceTravellerPack',
  MISFUELLING_PACK = 'misfuellingCoverPack',
  MOTOR_LEGAL_PROTECTION = 'mlpPack',
  NCDP = 'ncdpPack',
  PERSONAL_ACCIDENT_PACK = 'pibPack',
  RESRHV11 = 'RESRHV11',
  RESRHV12 = 'RESRHV12',
  RESROV11 = 'RESROV11',
  RESROV13 = 'RESROV13',
  RESRV11 = 'RESRV11',
  RESRV12 = 'RESRV12',
  SAFETY_NET = 'safetyNetPack',
}

export const BREAKDOWN_CODES = [
  'RESRV1',
  'RESRV2',
  'RESRV3',
  'RESRV11',
  'RESRV12',
  'RESRV13',
  'RSWRV1',
  'RSWRV2',
  'RSWRV3',
  'RFARV1',
  'RFARV2',
  'RFARV3',
  'RESRHV1',
  'RESRHV2',
  'RESRHV3',
  'RESRHV11',
  'RESRHV12',
  'RESRHV13',
  'RSWRHV1',
  'RSWRHV2',
  'RSWRHV3',
  'RFARHV1',
  'RFARHV2',
  'RFARHV3',
  'RESROV1',
  'RESROV2',
  'RESROV3',
  'RESROV11',
  'RESROV13',
  'RESROV15',
  'RSWROV1',
  'RSWROV2',
  'RSWROV3',
  'RFAROV1',
  'RFAROV2',
  'RFAROV3',
  'RESRAV1',
  'RESRAV2',
  'RESRAV3',
  'RSWRAV1',
  'RSWRAV2',
  'RSWRAV3',
  'RFARAV1',
  'RFARAV2',
  'RFARAV3',
  'RESRAAV1',
  'RESRAAV2',
  'RESRAAV3',
  'RSWRAAV1',
  'RSWRAAV2',
  'RSWRAAV3',
  'RFARAAV1',
  'RFARAAV2',
  'RFARAAV3',
  'RESROAV1',
  'RESROAV2',
  'RESROAV3',
  'RSWROAV1',
  'RSWROAV2',
  'RSWROAV3',
  'RFAROAV1',
  'RFAROAV2',
  'RFAROAV3',
];

export enum COVERAGE {
  ACCIDENT_PER_PERIOD = 'ad_personalAccidentPerPeriod',
  ACCIDENT_PER_PERSON = 'ad_personalAccidentPerPerson',
  DRIVING_OTHER_CARS = 'tpi_drivingOtherCars',
  STANDARD_CC = 'standardCC',
}

export type PHONE_TYPE = 'LANDLINE' | 'MOBILE';

export enum DIMENSION {
  NAME = 'channel',
  VALUE = 'DIRECT',
}

export enum HEADER {
  AUTH_KEY = 'Authorization',
  BRAND = 'x-brand',
  CUSTOMER_KEY = 'X-Customer-Number',
  ESBRAND = 'esure',
  FABRAND = 'firstAlt',
  SWBRAND = 'sheilasWheels',
}

export enum ERROR {
  NOT_FOUND = 'NOT_FOUND',
}

export enum POLICY_STATUS_CODE {
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  COMPANY_DECLINED = 'companyDeclined',
  CUSTOMER_DECLINED = 'customerDeclined',
  EXPIRED = 'expired',
  IN_FORCE = 'inForce',
  IN_FORCE_PENDING = 'inForcePending',
  ISSUED = 'issued',
  PROPOSED = 'proposed',
}

export enum POLICY_STATUS {
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  INCLUDED = 'Included',
  LAPSED = 'Lapsed',
  PENDING = 'Pending',
  PROTECTED = 'Protected',
  VERIFIED = 'Verified',
}

export enum POLICY_TYPE {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  PENDING = 'Pending',
}

export enum CHANNEL_TYPE {
  EMAIL = 'Email',
  PHONE = 'Phone',
  POST = 'Post',
  SMS = 'SMS',
}

export enum PANEL_TYPE {
  AUTO_RENEWAL = 'AutoRenewal',
  CAR_DETAILS = 'Car details',
  DRIVER = 'Driver',
  LIFESTYLE_PACK = 'Lifestyle',
  NCD = 'NCD',
  YOUR_HOME = 'Your home',
}

export enum DIALOG_TYPE {
  ACCIDENT_RECOVERY = 'Accident Recovery',
  BROKEN_GLASS = 'Broken Glass',
  CANCEL_CONFIRMATION = 'Cancel Confirmation',
  CANCEL_POLICY_CLOSED = 'Cancel Policy Out Of Office',
  CANCEL_POLICY_OPEN = 'Cancel Policy Open Hours',
  DD_CUSTOMER_ASSISTED_PAYMENT = 'Fetch Advisor DD Customer Assisted Payment',
  DD_RENEWAL_TECHNICAL_PROBLEM = 'Technical problem with dd',
  ERROR_ATTEMPT_1 = 'Error Attempt 1',
  ERROR_ATTEMPT_2 = 'Error Attempt 2',
  FETCH_ADVISER_ADD_CARD = 'Fetch Adviser - Add payment method',
  FETCH_ADVISER_ASSISTED_PAYMENT = 'Fetch Adviser Assisted Payment',
  FETCH_ADVISER_CHANGES = 'Fetch Adviser Changes',
  FETCH_ADVISER_CHANGES_REN = 'Make a Change - Renewal',
  FETCH_ADVISER_CHANGE_FIRSTNAME = 'Fetch Adviser Change First name',
  FETCH_ADVISER_CLAIMS_CONV = 'Fetch Adviser Claims and Convictions',
  FETCH_ADVISER_DELETE_CARD = 'Fetch Adviser Dialog - Delete card',
  FETCH_ADVISER_EMPLOYMENT = 'Fetch Adviser Employment',
  FETCH_ADVISER_RENEWAL_QUOTE = 'Fetch Adviser Dialog - Renewal quote',
  FETCH_ADVISER_RENEWAL_QUOTE_PAY = 'Fetch Adviser Dialog - Renewal quote pay now',
  FETCH_ADVISOR_RENEWAL_MTA_UNAVAILABLE_ONLINE = 'Fetch Adviser Renewal MTA Unavailable Online',
  FETCH_QUOTE = 'Fetch Quote',
  FETCH_QUOTE_SECONDARY_DELAY = 'Fetch Quote Secondary Delay',
  GENERIC_TECHNICAL_PROBLEM = 'Generic Technical Problem',
  LOST_KEYS = 'Lost Keys',
  MAKE_CHANGE = 'Make a Change',
  MTA_TECHNICAL_PROBLEMS = 'MTA Technical Problems',
  PROCESSING_PAYMENT = 'Processing Payment',
  SAVE_CHANGES = 'Save Changes',
  SPEAK_TO_SPECIALIST = 'Speak to specialist',
  TIA_POLICY = 'TIA Policy',
  UNABLE_TO_HELP_ONLINE = 'Unable to help online',
  UPDATING_DETAILS = 'UpdatingDetails',
  WELCOME_TO_ACCOUNT = 'Welcome To Account',
}

export const NONE = 'None';
export const NON_FITTED = 'None fitted';
export const FITTED = 'Fitted';

export enum IOVATION {
  BRAND = 'ESB',
}

export enum LINK_TYPE {
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
}

export const DOCUMENT_TYPE_CODE = {
  HOME_CORE_BKLT: 'HOME_CORE_BKLT',
  HOME_FLEX_BKLT: 'HOME_FLEX_BKLT',
  HOME_IPID_BCC: 'HOME_IPID_BCC',
  HOME_IPID_BLC: 'HOME_IPID_BLC',
  HOME_IPID_CC: 'HOME_IPID_CC',
  HOME_IPID_FLP: 'HOME_IPID_FLP',
  HOME_IPID_HE: 'HOME_IPID_HE',
  HOME_IPID_PC: 'HOME_IPID_PC',
  MOTOR_IPID_BKDN: 'MOTOR_IPID_BKDN',
  MOTOR_IPID_CHIRE: 'MOTOR_IPID_CHIRE',
  MOTOR_IPID_MLP: 'MOTOR_IPID_MLP',
};

export const PRODUCT_TYPE = {
  CORE: 'core',
  FLEX: 'flex',
};

export enum CHATBOT_ACTION {
  MAKE_CHANGE_OTHER = 'Make a change - other',
}

export enum LOGIN_TYPE {
  ACTIVATION = 'ACTIVATION',
  CIBA = 'CIBA',
  LOGIN = 'LOGIN',
  NONE = 'NONE',
}

export enum PARAMS_CODE {
  ACCESS_CODE = 'accessCode',
  ACTIVATION_CODE = 'activationCode',
  UID = 'uid',
  VERIFICATION_CODE = 'verificationCode',
}

export enum BREADCRUMBS_TABS_PATH {
  IF_YOU_CLAIM = 'if-you-claim',
  TYPES_OF_USE = 'types-of-use',
}

export enum BREADCRUMBS_TABS_LABEL {
  IF_YOU_CLAIM = 'About your cover: if you claim',
  TYPES_OF_USE = 'About your cover: types of use',
}

export enum ERROR_CODE {
  CODE_EXPIRED = 'CUST_ACC_ERR_005',
  FAILED_MTA_BUSINESS_003 = 'CUST_MTA_ERR_003',
  FAILED_MTA_BUSINESS_004 = 'CUST_MTA_ERR_004',
  FAILED_MTA_BUSINESS_005 = 'CUST_MTA_ERR_005',
  FAILED_MTA_BUSINESS_006 = 'CUST_MTA_ERR_006',
  FAILED_MTA_TECH_000 = 'CUST_MTA_ERR_000',
  FAILED_MTA_TECH_001 = 'CUST_MTA_ERR_001',
  FRAUD = 'FRAUD',
  INVALID_CUSTOMER = 'CUST_ACC_ERR_010',
  NOT_FOUND = 'NOT_FOUND',
  QUOTE_LOAD_FAILED = 'QUOTE_LOAD_FAILED',
  QUOTE_PARAMS_MISSING = 'QUOTE_PARAMS_MISSING',
  QUOTE_TIMEOUT = 'QUOTE_TIMEOUT',
  SERVER_DOWN = 'CUST_ACC_ERR_001',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  UNKNOWN = 'UNKNOWN',
}

export enum MTA_QUOTE_FAILURE_REASON {
  QUOTE_INELIGIBLE = 'QUOTE_INELIGIBLE',
}

export enum CARD_TYPE {
  MASTER_CARD = 'mc',
  VISA = 'visa',
}

export enum PAYMENT_METHOD {
  ACCOUNT = 'ACCOUNT',
  CARD = 'CARD',
}

export enum DD_STATUS {
  MISSED_PAYMENT = 'Missed Payment',
  PAID = 'Paid',
  PENDING = 'Pending',
}

export enum DD_PREMIUM_DETAILS_TYPE {
  INSTALMENTS = 'Instalments',
  PAYMENT_ACTIVITY = 'Payment Activity',
  POLICY_BREAKDOWN = 'Policy Breakdown',
}

export enum HOME_COVER_CODES {
  BUILDING = 'BUILDINGS',
  BUILDING_CONTENT = 'BUILDINGS_AND_CONTENTS',
  CONTENT = 'CONTENTS',
}

export enum BUTTON_ORIGIN {
  ASSISTED_PAYMENT = 'Assisted Payment Dialog',
  CANCEL_CONFIRMATION = 'Cancel Confirmation Popup',
  CANCEL_POLICY = 'Cancel Policy Dialog',
  FETCH_ADVISER_ADD_CARD = 'Fetch Adviser - Add payment method',
  FETCH_ADVISER_DELETE_CARD = 'Fetch Adviser Dialog - Delete card',
  FETCH_ADVISER_MTA = 'Fetch Adviser Dialog - MTA',
  FETCH_ADVISER_MTA_REN = 'Fetch Adviser Dialog - MTA - Renewal',
  FETCH_ADVISER_RENEWAL_QUOTE = 'Fetch Adviser Dialog - Renewal quote',
  FETCH_ADVISER_RENEWAL_QUOTE_PAY = 'Fetch Adviser Dialog - Renewal quote pay now',
  NONE = 'None',
  RENEWAL_MTA_UNAVAILABLE_ONLINE = 'Renewal MTA Unavailable Online',
  TIA_POLICY = 'TIA Policy Dialog',
}

export enum DIALOG_ORIGIN {
  LOGOUT = 'Dialog from logout link',
  ROUTER_PROMPT = 'Dialog from prompt',
}

export enum LOOKUP_TYPE_PAGE {
  ACCESS_TO_OTHER_CARS = 'useOfOtherCarsCode',
  BUSINESS_MILEAGE = 'businessMileageCode',
  CARS_IN_HOUSEHOLD = 'carsInHousehold',
  DATE_OF_BIRTH = 'dateOfBirth',
  DAYTIME_LOCATION = 'daytimeLocation',
  EMPLOYMENT = 'employmentStatusCode',
  LEGAL_OWNER = 'legalOwner',
  LICENCE_DATE_MONTHS = 'licenseDateMonthsCode',
  LICENCE_DATE_YEARS = 'licenseDateYearsCode',
  LICENCE_RESTRICTION = 'licenseRestrictionCode',
  LICENCE_TYPE = 'licenceTypeCode',
  MAIN_DRIVER = 'mainDriver',
  MARITAL_STATUS = 'maritalStatusCode',
  NUMBER_OF_CHILDREN = 'children',
  OVERNIGHT_POSTCODE = 'overnightPostcode',
  PRIMARY_INDUSTRY = 'primaryOccupationIndustryCode',
  PRIMARY_OCCUPATION = 'primaryOccupationCode',
  REGISTERED_KEEPER = 'registeredKeeper',
  RELATIONSHIP_STATUS = 'relationshipStatus',
  RELATIONSHIP_TO_PROPOSER = 'relationshipToProposerCode',
  RESIDENTIAL_STATUS = 'residentialStatus',
  RESIDENT_SINCE_BIRTH = 'residentSinceBirth',
  SECONDARY_INDUSTRY = 'secondaryOccupationIndustryCode',
  SECONDARY_OCCUPATION = 'secondaryOccupationCode',
  SOCIAL_MILEAGE = 'socialMileageCode',
  START_DATE = 'startDate',
  SURNAME = 'surname',
  TITLE = 'titleCode',
  USAGE = 'classOfUse',
}

export enum EDIT_SUMMARY {
  STEP_1 = 'Start Date',
  STEP_2 = 'Car Registration',
  STEP_3 = 'Car Found',
  STEP_4 = 'Car Ownership',
  STEP_5 = 'Security Features',
  STEP_6 = 'Car Modified',
}

export enum JOURNEY_TYPE {
  ADD_DRIVER = 'Add driver',
  CARS_AT_HOME = 'Change cars in the household',
  CAR_MILEAGE_AND_USAGE = 'Car mileage and usage',
  CAR_MODIFICATIONS = 'Car modifications',
  CHANGE_AUTO_RENEWAL = 'Change auto renewal',
  CHANGE_DRIVER = 'Change driver details',
  CHANGE_EMPLOYMENT_DETAILS = 'Change employment details',
  CHANGE_HOME_ADDRESS = 'Change home address',
  CHANGE_PERSONAL_DETAILS = 'Change personal details',
  CHANGE_YOUR_CAR = 'Change your car',
  CHILDREN_AT_HOME = 'Change children in the household',
  DAYTIME_LOCATION = 'Daytime location',
  DRIVER_LICENSE = 'Change driver details',
  DRIVER_OCCUPATION = 'Change driver details',
  DRIVER_RELATIONSHIP = 'Change driver details',
  HOME_ADDRESS = 'Change home address',
  MORE_CHANGES = 'More changes',
  NAME = 'Change your mame',
  NONE = 'None',
  OCCUPATION = 'Change your occupation',
  OTHER_CHANGES = 'Other changes',
  OVERNIGHT_LOCATION = 'Overnight location',
  QUOTE_READY = 'Quote ready',
  REGISTRATION_NUMBER = 'Registration number',
  RELATIONSHIP_STATUS = 'Change your relationship status',
  REMOVE_DRIVER = 'Remove driver',
  RESIDENTIAL_STATUS = 'Change residential status',
  REVIEW_CHANGES = 'Review changes',
}

export enum FETCH_ADVISOR_REASON {
  ASSISTED_PAYMENT = 'Assisted payments',
  CHANGE_EMPLOYMENT_DETAILS = 'Change Employment Details',
  CHANGE_FIRSTNAME = 'Change first name',
  CLAIMS_CONV = 'Claims and Convictions',
  DD_CUSTOMER = 'DD Customer Assisted Payments',
  FETCH_ADVISER_ADD_CARD = 'Fetch Adviser - Add payment method',
  FETCH_ADVISER_DELETE_CARD = 'Fetch Adviser - Delete card',
  FETCH_ADVISER_RENEWAL_QUOTE = 'Fetch Adviser Dialog - Renewal quote',
  FETCH_ADVISER_RENEWAL_QUOTE_PAY = 'Fetch Adviser Dialog - Renewal quote pay now',
  MAKE_CHANGES = 'Make changes',
  MAKE_CHANGES_REN = 'Make changes renewal',
  RENEWAL_MTA_UNAVAILABLE_ONLINE = 'Renewal MTA Unavailable Online',
}

export enum PAYMENT_TYPE {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

export enum ADYEN_RESULT_CODE {
  AUTHORISED = 'AUTHORISED',
  ERROR = 'ERROR',
  REFUSED = 'REFUSED',
}

export enum MTA_TYPE {
  CANCEL_POLICY = 'Cancel policy',
  CAR_DETAILS = 'Car details',
  DRIVERS = 'Drivers',
  YOUR_DETAILS = 'Your details',
}

export enum THEME_NAME {
  ESURE = 'themeEsure',
  ESUREPCW = 'themeEsurePCW',
  FIRSTALTERNATIVE = 'themeFirstAlternative',
  SHEILASWHEELS = 'themeSheilasWheels',
}

export enum DATE_TYPES {
  LONG = 'long',
  LONG_TIME = 'longTime',
  LONG_YEAR_LINE = 'LONG_YEAR_LINE',
  MINI = 'mini',
  MINI_YEAR_LINE = 'miniYear-line',
  MONTH = 'month',
  MONTH_YEAR = 'monthYear',
  SHORT = 'short',
  SHORT_SLASH_MONTH_YEAR = 'shortSlashMonthYear',
  SHORT_SPACE = 'shortSpace',
  SHORT_TIME = 'shortTime',
  SHORT_TIME_WITH_COMMA = 'shortTimeWithComma',
  WEEKDAY_LONG = 'weekdayLong',
  WEEKDAY_SHORT = 'weekdayShort',
}

export enum BRAND {
  ESURE = 'esure',
  FIRST_ALTERNATIVE = 'firstAlt',
  SHEILA = 'sheilasWheels',
}

export enum POLICY_TYPE_CODE {
  EIS_AUTO = 'AUTO',
  EIS_HOME = 'HOME',
  TIA_AUTO = 'MTR',
  TIA_HOME = 'HME',
}

export enum EDIT_STEPS {
  ADDRESS = 'ADDRESS',
  BUSINESS_MILEAGE_CODE = 'BUSINESSMILEAGECODE',
  CARS_IN_HOUSEHOLD = 'CARS_IN_HOUSEHOLD',
  CAR_OWNER = 'CAROWNER',
  CLASS_OF_USE = 'CLASSOFUSE',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  DAYTIME_LOCATION = 'DAYTIMELOCATION',
  DRIVER_HISTORY = 'DRIVER_HISTORY',
  DRIVER_LICENCE_TYPE = 'DRIVER_LICENCE_TYPE',
  DRIVER_MAIN_CHANGED = 'DRIVER_MAIN_CHANGED',
  DRIVER_MARITAL_STATUS = 'DRIVER_MARITAL_STATUS',
  DRIVER_NAME = 'DRIVER_NAME',
  DRIVER_OCCUPATION = 'DRIVER_OCCUPATION',
  DRIVER_OTHER_CARS = 'DRIVER_OTHER_CARS',
  DRIVER_RELATIONSHIP = 'DRIVER_RELATIONSHIP',
  DRIVER_REMOVE = 'DRIVER_REMOVE',
  ESTIMATED_VALUE = 'ESTIMATEDVALUE',
  FULL_NAME = 'FULL_NAME',
  KEEPER = 'KEEPER',
  LEGAL_OWNER = 'LEGAL_OWNER',
  MARITAL_STATUS = 'MARITAL_STATUS',
  MODIFICATIONS = 'MODIFICATIONS',
  NUMBER_OF_CHILDREN = 'NUMBER_OF_CHILDREN',
  OVERNIGHT_LOCATION = 'OVERNIGHTLOCATION',
  OWNER = 'OWNER',
  PURCHASE_DATE = 'PURCHASEDATE',
  REGISTRATION = 'REGISTRATION',
  RESIDENTIAL_STATUS = 'RESIDENTIALSTATUS',
  SECURITY_DEVICE_DECLARED = 'SECURITYDEVICEDECLARED',
  SOCIAL_MILEAGE_CODE = 'SOCIALMILEAGECODE',
  START_DATE = 'STARTDATE',
  TRACKING_DEVICE_FITTED = 'TRACKING_DEVICEFITTED',
  UK_RESIDENT_SINCE_BIRTH = 'UK_RESIDENT_SINCE_BIRTH',
}

export enum ENDORSEMENT_REASONS {
  DETAILS_CHANGE = 'DETAILS_CHANGE',
  DRIVER_CHANGE = 'DRIVER_CHANGE',
  MILEAGE_CHANGE = 'MILEAGE_CHANGE',
  VEHICLE_CHANGE = 'VEHICLE_CHANGE',
}

export const MTA_COMMON_ROUTES: string[] = [ROUTE.MORE_CHANGES, ROUTE.MTA_REVIEW_CHANGES, ROUTE.MTA_QUOTE_READY];

export enum NON_DVLA_REGISTRATION_NUMBER_CHANGE {
  CCTP = 'CCTP',
  CORR = 'CORR',
  NONE = 'NONE',
  RPP = 'RPP',
}
