import { Grid, Theme, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { useStores } from '../../../../../../../../service/state/store';

import { EditPageRows } from './editPageRows';
import { Row } from './multipleColumnsEditPage';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  root: {
    '& .columns-container': {
      gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
      padding: spacing(3),
    },
    marginTop: spacing(3),
  },
}));

export interface SingleColumnEditPageProps {
  driverUuid?: string;
  handleEdit: (id: string) => void;
  rows: Row[];
  tableHeader: string;
}

export const SingleColumnEditPage: React.FC<SingleColumnEditPageProps> = observer(function SingleColumnEditPage({
  tableHeader,
  rows,
  handleEdit,
  driverUuid,
}) {
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const classes = useStyles({ isDesktop });

  return (
    <BlueTableContainer title={tableHeader} className={classes.root}>
      <Grid container className="columns-container">
        <EditPageRows rows={rows} handleEdit={handleEdit} tableHeader={tableHeader} driverUuid={driverUuid} />
      </Grid>
    </BlueTableContainer>
  );
});
