import i18next from 'i18next';

export const setWelcomeMessage = (userName: string, hour: number | null): string | null => {
  let greet = '';
  if (hour !== null) {
    if (hour < 12) greet = i18next.t('myAccount:appbar.greetMorning');
    else if (hour >= 12 && hour <= 18) greet = i18next.t('myAccount:appbar.greetNoon');
    else if (hour >= 18 && hour <= 24) greet = i18next.t('myAccount:appbar.greetEvening');
    return `${greet} ${userName}!`;
  } else return null;
};
