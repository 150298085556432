import { CssBaseline, MuiPickersUtilsProvider, MuiThemeProvider } from '@esure-cloud/react-components';

import LuxonUtils from '@date-io/luxon';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ErrorHandler } from './component/common';
import { applySegmentMiddleware, fireSegmentPageEvent } from './service/analytics';
import { i18nLoader } from './service/i18n';
import { checkTheme } from './service/util/checkTheme';
import { useSegment } from './service/util/customHooks/useSegment';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { App } from './view/pages';

// Apply segment middleware to add our extra properties onto the context object in each event
applySegmentMiddleware();

// Trigger the initial page load event
fireSegmentPageEvent();

(() => {
  const i18n = i18nLoader;
  // root render view for the high level react/utility/routing wrappers
  const RootRender: React.FC = () => {
    const { eventTrack } = useSegment();
    return (
      <StrictMode>
        <MuiThemeProvider theme={checkTheme()}>
          <CssBaseline />
          <I18nextProvider i18n={i18n}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <ErrorHandler eventTrackHandler={eventTrack}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                  <Switch>
                    <Route component={App} exact />
                  </Switch>
                </BrowserRouter>
              </ErrorHandler>
            </MuiPickersUtilsProvider>
          </I18nextProvider>
        </MuiThemeProvider>
      </StrictMode>
    );
  };

  // boot react with initial mount to the dom
  render(<RootRender />, document.getElementById('root'));
})();
serviceWorkerRegistration.register();
