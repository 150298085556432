import { CommonContainer, Grid, RegistrationPlate, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { LOOKUP } from '../../service/constant';
import { IVehicle } from '../../service/state/models/dashboard/autoPolicyModel';
import { IRenewalVehicle } from '../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../service/state/store';
import { useDecodedLookupsMotor } from '../../service/util/customHooks/useDecodedLookups';

export const DisplayFoundCar: React.FC<{
  children?: React.ReactNode;
  simpleVariant?: boolean;
  vehicleObject: IVehicle | IRenewalVehicle;
}> = observer(function DisplayFoundCar({ vehicleObject, children, simpleVariant }) {
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
  } = useStores();

  const isNotRenewal: boolean = 'fuelTypeCode' in vehicleObject;

  const vehicle: IVehicle = vehicleObject as IVehicle;

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ vehicle });

  const GetVehicleProps: React.FC = () => {
    const propsArray = [
      getLookup(LOOKUP.FUEL_TYPE, vehicle.fuelTypeCode),
      `${vehicle.engineSize}cc`,
      getLookup(LOOKUP.TRANSMISSION, vehicle.transmissionTypeCode),
      `${vehicle.numberOfDoors} doors`,
      `${vehicle.numberOfSeats} seats`,
    ];
    return (
      <>
        {propsArray.map((item, i) => (
          <Typography key={i} variant="body1" className="car-properties__item">
            {item}
          </Typography>
        ))}
      </>
    );
  };

  return (
    <CommonContainer className="content">
      <Grid container className="content__grid" direction="column">
        <Typography variant="h3">
          {vehicle.make} {vehicle.model}
        </Typography>
        <RegistrationPlate active={true} label={vehicle.vrm} />
        {isNotRenewal && !simpleVariant && (
          <CommonContainer variant="filled" className="car-properties">
            {isDecodedLookupsFetched && <GetVehicleProps />}
          </CommonContainer>
        )}
        {children}
      </Grid>
    </CommonContainer>
  );
});
