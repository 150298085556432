import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../../../service/state/store';
import { MainDriver } from '../components/mainDriver';

import { DriverEditPageProps } from './editDriver';

export const MainDriverAdd: React.FC<DriverEditPageProps> = observer(
  ({ handleBackDriverEdit, handleSaveDriverEdit }) => {
    const {
      mtaStore: {
        pendedMTA: { drivers },
      },
    } = useStores();

    const mainDriver = drivers.find((item) => item.mainDriver === true)?.uuid;

    return (
      <MainDriver
        drivers={drivers}
        defaultMainDriver={mainDriver}
        handleBackDriverEdit={handleBackDriverEdit}
        handleSaveDriverEdit={handleSaveDriverEdit}
      />
    );
  },
);
