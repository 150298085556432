import { Divider, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DD_PREMIUM_DETAILS_TYPE } from '../../../../../../service/constant';
import SelfService from '../../../../../../service/util/selfService';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    '& .summary': {
      '&-content': {
        '&__item': {
          gap: 30,
        },
        gap: 8,
      },
      '&-divider': {
        width: '100%',
      },
      '&-footer': {
        '&__total': {
          '&:last-child': {
            textAlign: 'right',
          },
          maxWidth: 150,
        },
      },
      '&-link': {
        '&__disabled': {
          color: palette.grey[600],
        },
        cursor: 'pointer',
        textDecoration: 'none',
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      backgroundColor: palette.grey[100],
      borderRadius: 8,
      gap: 15,
      maxWidth: 600,
      padding: spacing(2),
    },
    [breakpoints.up('md')]: {
      padding: spacing(4, 0, 0, 3),
    },
    [breakpoints.down('sm')]: {
      padding: spacing(4, 0),
    },
    gap: 15,
  },
}));

export interface DDPremiumDetailsProps {
  type: DD_PREMIUM_DETAILS_TYPE;
}

export const DDPremiumDetails: React.FC<DDPremiumDetailsProps> = () => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className="summary" container item sm={12}>
        <Typography variant="h2">{t('payments.summary.title')}</Typography>

        <Grid container className="summary-content">
          <Grid
            alignItems="center"
            className="summary-content__item"
            container
            item
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Typography variant="h3">{t('DDPremiumDetails.totalAnnual')}</Typography>
            <Typography variant="body1">{SelfService.toCurrency(726.28)}</Typography>
          </Grid>

          <Grid
            alignItems="center"
            className="summary-content__item"
            container
            item
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Typography variant="body1">{t('DDPremiumDetails.chargeCredit')}</Typography>
            <Typography variant="body1">{SelfService.toCurrency(113.11)}</Typography>
          </Grid>

          <Grid className="summary-content__item" container item justifyContent="space-between" wrap="nowrap">
            <Grid item xs={6}>
              <Typography variant="h3">{t('DDPremiumDetails.totalAmount')}</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Typography variant="h3">{SelfService.toCurrency(839.39)}</Typography>
              <Typography variant="body2">{t('DDPremiumDetails.breakdownInfo')}</Typography>
            </Grid>
          </Grid>
          {/*<Grid className="summary-content__item" container item justifyContent="space-between" wrap="nowrap">*/}
          {/*  <Grid item xs={8}>*/}
          {/*    {type !== DD_PREMIUM_DETAILS_TYPE.POLICY_BREAKDOWN ? (*/}
          {/*      <Link to={ROUTE.POLICY_BREAKDOWN} className="summary-link">*/}
          {/*        <Typography variant="body1">{t('DDPremiumDetails.showBreakdown')}</Typography>*/}
          {/*      </Link>*/}
          {/*    ) : (*/}
          {/*      <Typography variant="body1" className="summary-link__disabled">*/}
          {/*        {t('DDPremiumDetails.showingBreakdown')}*/}
          {/*      </Typography>*/}
          {/*    )}*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={4}>*/}
          {/*    <Typography variant="body2" style={{ textAlign: 'right' }}>*/}
          {/*      {t('DDPremiumDetails.breakdownInfo')}*/}
          {/*    </Typography>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}

          {/*<Divider className="summary-divider" />*/}

          {/*<Grid*/}
          {/*  alignItems="center"*/}
          {/*  className="summary-content__item"*/}
          {/*  container*/}
          {/*  item*/}
          {/*  justifyContent="space-between"*/}
          {/*  wrap="nowrap"*/}
          {/*>*/}
          {/*  <Typography variant="h3">{t('DDPremiumDetails.nextPayment')}</Typography>*/}
          {/*  <Typography variant="body1" style={{ textAlign: 'right' }}>*/}
          {/*    {t('core:date.weekdayLong', { date: DateTime.create('2022-06-23T13:42:47.304Z') })}*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          {/*{type !== DD_PREMIUM_DETAILS_TYPE.INSTALMENTS ? (*/}
          {/*  <Link to={ROUTE.INSTALMENTS} className="summary-link">*/}
          {/*    <Typography variant="body1">{t('DDPremiumDetails.viewInstalments')}</Typography>*/}
          {/*  </Link>*/}
          {/*) : (*/}
          {/*  <Typography variant="body1" className="summary-link__disabled">*/}
          {/*    {t('DDPremiumDetails.viewingInstalments')}*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </Grid>

        <Divider className="summary-divider" />

        <Grid className="summary-footer" container justifyContent="space-between">
          <Grid item xs={6} className="summary-footer__total">
            <Typography variant="h3">{t('DDPremiumDetails.monthlyPayment')}</Typography>
          </Grid>

          <Grid item xs={6} className="summary-footer__total">
            <Typography variant="h2" data-testid="total-premium-amount">
              {SelfService.toCurrency(63.71)}
            </Typography>
            <Typography variant="body2"> {t('DDPremiumDetails.breakdownInfo')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
