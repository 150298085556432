import { Checkbox, alpha, makeStyles } from '@esure-cloud/react-components';

import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationMessageProps {
  checked: boolean;
  hasError?: boolean;
  isRenewalMessage?: boolean;
  message: string;
  setChecked: (value: boolean) => void;
}

const useStyles = makeStyles(({ palette, spacing, shape }) => ({
  'error-container': {
    backgroundColor: alpha(palette.error.dark, 0.08),
    border: `1px solid ${palette.error.light}`,
  },
  root: {
    '& .error-text': {
      color: palette.error.dark,
    },
    '& .message-container': {
      '&__checkbox': {
        backgroundColor: 'transparent',
        paddingTop: spacing(0),
      },
      alignItems: 'flex-start',
      display: 'flex',
      gap: spacing(1),
    },
    border: '1px solid red',
    borderRadius: shape.borderRadius,
    gap: spacing(2),
    padding: spacing(2),
  },
  'warning-container': {
    backgroundColor: `${palette.warning.light}40`,
    border: `1px solid ${palette.warning.dark}`,
  },
}));

export const ConfirmationMessage: React.FC<ConfirmationMessageProps> = ({
  message,
  checked,
  setChecked,
  hasError,
  isRenewalMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.root, hasError ? classes['error-container'] : classes['warning-container'])}
    >
      {!isRenewalMessage && (
        <Typography variant="h3" className={hasError ? 'error-text' : ''}>
          {t('confirmationMessage.title')}
        </Typography>
      )}
      <Grid className="message-container">
        <Checkbox
          checked={checked}
          color="primary"
          onChange={() => setChecked(!checked)}
          className="message-container__checkbox"
        />
        <Typography
          className={hasError ? 'error-text' : ''}
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </Grid>
    </Grid>
  );
};
