import {
  Alert,
  AlertProps,
  CircularProgress,
  Collapse,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
  alpha,
  makeStyles,
} from '@esure-cloud/react-components';

import { AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import clsx from 'clsx';
import { validate as validateEmail } from 'email-validator';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Footer } from '../../../component/common';
import { CommonButton } from '../../../component/common/button/commonButton';
import { EsureLayoutNavbar } from '../../../component/common/esureLayoutNavbar';
import IconSure from '../../../component/common/icon';
import { SVGWrapper } from '../../../images/SVGWrapper';
import { COMPONENT_ID } from '../../../service/analytics';
import { DIALOG_TYPE, ERROR_CODE } from '../../../service/constant';
import { APIResponse } from '../../../service/network';
import { authenticateCustomer, getAccessToken } from '../../../service/network/api/dashboard';
import { useStores } from '../../../service/state/store';
import { useRouteContext } from '../../../service/util/customHooks/routeContext';
import { useForm } from '../../../service/util/customHooks/useForm';
import { useSegment } from '../../../service/util/customHooks/useSegment';
import SelfService from '../../../service/util/selfService';
import { stringifyError } from '../../../service/util/stringUtils';

interface LoginForm {
  email: string;
}

export interface ViewProps {
  alert?: {
    description: string;
    severity?: AlertProps['severity'];
    title?: string;
  };
  button: string;
  description?: string;
  form?: {
    label: string;
    placeholder: string;
  };
  icon: string;
  id: string;
  showEditLink?: boolean;
  showResendLink?: boolean;
  title: string;
}

export const enum ViewType {
  CHECK_EMAIL = 'checkEmail',
  LINK_EXPIRED = 'linkExpired',
  LOGIN_FAILURE = 'loginFailure',
  LOGIN_LOADER = 'loginLoader',
  RESEND_EMAIL = 'resendEmail',
  SEND_EMAIL = 'sendEmail',
  SERVER_ERROR = 'serverError',
}

type ViewOptions = Record<ViewType, ViewProps>;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  header: {
    '& .navbar': {
      height: 80,
      position: 'relative',
    },
  },
  root: {
    '& .login': {
      '&__bg': {
        '&__image': {
          bottom: 0,
          display: 'none',
          left: 0,
          objectFit: 'contain',
          position: 'absolute',
          width: '100%',
          zIndex: 1,
          [breakpoints.up('sm')]: {
            display: 'block',
          },
        },
        flex: 1,
        position: 'relative',
        [breakpoints.up('sm')]: {
          padding: spacing(6, 0),
        },
      },
      '&__box': {
        '& a': {
          cursor: 'pointer',
        },
        '&-description': {
          '& br': {
            content: `''`,
            display: 'block',
            margin: spacing(2, 0),
          },
          lineHeight: 1.5,
        },
        '&-icon': {
          height: 64,
          position: 'absolute',
          top: -32,
          width: 64,
        },
        '&-input': {
          '&.Mui-error': {
            color: palette.error.dark,
          },
        },
        '&-label': {
          fontSize: 16,
        },
        backgroundColor: palette.common.white,
        borderRadius: 12,
        gap: 30,
        margin: 'auto',
        maxWidth: 430,
        padding: spacing(6, 2, 5),
        position: 'relative',
        width: '100%',
        zIndex: 2,

        [breakpoints.up('sm')]: {
          boxShadow: '0 4px 40px rgba(12,65,170, 0.1)',
          padding: spacing(6, 5, 5),
        },
      },
    },
    '& .spinner-hide': {
      opacity: 0,
    },
    minHeight: '100vh',

    [breakpoints.up('sm')]: {
      backgroundColor: alpha(palette.secondary.main, 0.08),
    },
  },
}));

export const Login: React.FC = observer(function Login() {
  const { eventTrack } = useSegment();
  const classes = useStyles();
  const { t } = useTranslation('common');

  const view: ViewOptions = t('auth', { returnObjects: true });
  const [content, setContent] = useState<ViewProps>(view.sendEmail);
  const history = useHistory();

  const {
    interfaceStore,
    userStore: {
      user: { userLoggedIn },
    },
  } = useStores();

  useEffect(() => {
    const checkParams = async () => {
      const data = SelfService.getAccessTokenBody(window.location.search);
      if (data) {
        interfaceStore.setIsLoading(true);
        setContent(view.loginLoader);

        try {
          eventTrack('Login Magic Link Clicked Through', {
            description: 'Capturing the request to login, either via an activation or login magic link',
            goal: 'Login',
            linkType: data.loginType,
            step: 'link-clicked-through',
          });

          const response = await getAccessToken(data);

          if (response.data.results[0].accessToken) {
            interfaceStore.setAccessToken(response.data.results[0].accessToken);
          }
        } catch (err) {
          interfaceStore.setIsLoading(false);

          if (axios.isAxiosError(err) && err.response) {
            // When the API is taken down, we get a 503 response from Nginx where the data is just a html string of the 503 error page
            const data = err.response.data as APIResponse<unknown> | string;
            const errors = typeof data === 'string' ? [] : data.errors;

            switch (true) {
              case errors.length !== 0 && errors[0]['code'] === ERROR_CODE.CODE_EXPIRED:
                eventTrack('Login Expired', {
                  description: 'Log in refused because magic link expired',
                  goal: 'Login',
                  step: 'login-expired',
                });
                setContent(view.linkExpired);
                interfaceStore.dialog.openDialog({
                  type: DIALOG_TYPE.WELCOME_TO_ACCOUNT,
                });
                break;
              case errors.length !== 0 && errors[0]['code'] === ERROR_CODE.INVALID_CUSTOMER:
                eventTrack('Login Refused Forgerock', {
                  description: 'Login refused due to Forgerock authentication check',
                  error: stringifyError(err),
                  goal: 'Login',
                  step: 'login-refused',
                });
                setContent(view.loginFailure);
                break;
              default:
                eventTrack('Login Refused Axios error', {
                  description: 'Login refused due to axios error',
                  error: stringifyError(err),
                  goal: 'Login',
                  step: 'login-refused',
                });
                setContent(view.serverError);
            }
          } else {
            eventTrack('Login Refused Server Down', {
              description: 'Login refused due to server down',
              error: stringifyError(err as Error),
              goal: 'Login',
              step: 'login-refused',
            });
            setContent(view.serverError);
          }
        }
      }
    };
    checkParams();
  }, []);

  useRouteContext();

  const handleBack = () => {
    handleResetForm();
    setContent(view.sendEmail);
  };

  const handleLogin = (isResend = false): Promise<boolean> => {
    return new Promise((resolve) => {
      const { email = '' } = formData;

      authenticateCustomer(email)
        .then(() => {
          if (isResend) {
            setContent({
              ...view.resendEmail,
              description: t('auth.resendEmail.description', { email }),
            });
          } else {
            setContent({
              ...view.checkEmail,
              description: t('auth.checkEmail.description', { email }),
            });
          }
          resolve(true);
        })
        .catch(() => {
          setContent(view.serverError);
          resolve(false);
        });
    });
  };

  useEffect(() => {
    if (userLoggedIn) {
      history.push('/');
    }
  }, [userLoggedIn]);

  const isEmailAddressValid = (emailAddress: string): boolean => validateEmail(emailAddress);

  const { errors, formData, handleChange, handleSubmit, isSubmitting, handleResetForm, isSubmitSuccessful } =
    useForm<LoginForm>({
      onSubmit: handleLogin,
      validations: {
        email: {
          custom: {
            isValid: isEmailAddressValid,
            message: t('validations.emailNotValid'),
          },
        },
      },
    });

  return (
    <Grid container direction="column" className={clsx('login', classes.root)}>
      <EsureLayoutNavbar
        className={classes.header}
        hasNeedHelp={true}
        position={'Login'}
        hasChatBotTooltip={content.id === view.resendEmail.id && !interfaceStore.isMobile}
      />
      <Grid container className="login__bg">
        <SVGWrapper alt="" src="login-bg.jpg" className="login__bg__image" />
        <Grid
          component="form"
          onSubmit={handleSubmit}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className="login__box"
        >
          {content.icon && <SVGWrapper alt="icon" className="login__box-icon" src={`${content.icon}.svg`} />}

          <Typography variant="h2" align="center">
            {content.title}
          </Typography>

          {content.description && (
            <Typography align="center" variant="body1" className="login__box-description">
              <span
                dangerouslySetInnerHTML={{
                  __html: content.description,
                }}
              />

              {content.showResendLink && (
                <Link onClick={() => handleLogin(true)} data-testid={COMPONENT_ID.LOGIN_RESEND_LINK}>
                  Resend Link
                </Link>
              )}
              {content.showEditLink && <Link onClick={handleBack}>Edit</Link>}
            </Typography>
          )}

          {interfaceStore.isLoading && (
            <Grid container justifyContent="center">
              <CircularProgress color="secondary" size={20} />
            </Grid>
          )}
          {content.form && (
            <Grid container item>
              <InputLabel className="login__box-label" htmlFor="emailAddress">
                {content.form.label}
              </InputLabel>

              <OutlinedInput
                className="login__box-input"
                color="secondary"
                endAdornment={
                  errors.email && (
                    <InputAdornment position="end">
                      <IconSure icon="error" type="solid" color="error" />
                    </InputAdornment>
                  )
                }
                error={!!errors.email}
                id="emailAddress"
                onChange={handleChange('email')}
                fullWidth
                placeholder={content.form.placeholder}
                value={formData.email ?? ''}
              />
              <FormHelperText aria-live={errors.email ? 'polite' : 'off'} aria-label={errors.email} error>
                {errors.email}
              </FormHelperText>
            </Grid>
          )}

          <Collapse in={!!content.alert && !isSubmitting} unmountOnExit timeout={{ enter: 500, exit: 0 }}>
            <Alert variant="standard" severity={content.alert?.severity}>
              <AlertTitle>{content.alert?.title}</AlertTitle>
              <Typography variant="body2">
                <Trans
                  i18nKey={content.alert?.description ?? ''}
                  t={t}
                  components={[
                    <Link
                      data-testid="chat-link"
                      underline="none"
                      variant="body2"
                      color="secondary"
                      onClick={() =>
                        interfaceStore.chatBot.startChat({
                          initiator: 'chat with us',
                        })
                      }
                    />,
                  ]}
                />
              </Typography>
            </Alert>
          </Collapse>

          {content.button && (
            <CommonButton
              fullWidth
              variant={isSubmitSuccessful ? 'text' : 'contained'}
              color={isSubmitSuccessful ? 'secondary' : 'primary'}
              startIcon={isSubmitSuccessful ? 'arrow-left' : ''}
              size="large"
              text={content.button}
              type={isSubmitSuccessful ? 'button' : 'submit'}
              onClick={isSubmitSuccessful || !content.form ? handleBack : undefined}
              loading={isSubmitting}
              data-testid={isSubmitSuccessful ? COMPONENT_ID.LOGIN_BACK_TO_LOGIN : COMPONENT_ID.LOGIN_BUTTON_NEXT}
            />
          )}
        </Grid>
      </Grid>

      <Footer fullWidth={false} />
    </Grid>
  );
});
