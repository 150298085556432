import { Grid, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../service/analytics';
import { DATE_TYPES, ENDORSEMENT_REASONS, LOOKUP, ROUTE } from '../../../../../../service/constant';
import { IRenewalDriver } from '../../../../../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { formatDate } from '../../../../../../service/util/formatDate';
import { ReviewAllDivider, ReviewAllEditButton } from '../../makeAchange/mta/common/steps/reviewAll';

import { driverEqualString } from './yourAdditionalDriverAccordion';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  columnsContainer: {
    gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
    padding: spacing(2, 2),
  },
  root: {
    marginTop: spacing(3),
  },
}));

interface ILookupData {
  accessToOtherCars: string;
  carsInHousehold: string;
  licenseHeld: string;
  maritalStatus: string;
  numberOfChildren: string;
  occupation: string;
  residencyStatus: string;
  residentialStatus: string;
  secondaryOccupation: string;
}

export const formatLicense = (licenseType: string, licenseYears: string, licenseMonths: string): string => {
  return licenseMonths
    ? `${licenseType}, held for ${licenseYears} years, ${licenseMonths} ${licenseMonths !== 'LM' ? 'months' : ''}`
    : `${licenseType}, held for ${licenseYears} years`;
};

export const YourDetailsAccordion: React.FC<{ showEditButton?: boolean }> = observer(function YourDetailsAccordion({
  showEditButton = true,
}) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
    renewalChangeStore: {
      renewalDetails: { insured, drivers, vehicle, setSelectedDriverUUID, addEndorsementReason },
    },
  } = useStores();
  const history = useHistory();

  const classes = useStyles({ isDesktop });

  const [lookupData, setLookupData] = useState<ILookupData>({
    accessToOtherCars: '',
    carsInHousehold: '',
    licenseHeld: '',
    maritalStatus: '',
    numberOfChildren: '',
    occupation: '',
    residencyStatus: '',
    residentialStatus: '',
    secondaryOccupation: '',
  });

  const { isLookupsFetched } = useLookups({
    lookups: [
      LOOKUP.RESIDENTIAL_STATUS,
      LOOKUP.MARITAL_STATUS,
      LOOKUP.NUMBER_OF_CHILDREN,
      LOOKUP.CARS_IN_HOUSEHOLD,
      LOOKUP.OCCUPATION,
      LOOKUP.LICENSE_HELD_YEARS,
      LOOKUP.LICENSE_HELD_MONTHS,
      LOOKUP.ACCESS_TO_OTHER_CARS,
      LOOKUP.LICENCE_TYPE,
    ],
  });

  const insuredPersonDrivers: IRenewalDriver[] = drivers.filter(
    (driver) => driverEqualString(driver.person) === driverEqualString(insured.person),
  );

  const insuredPersonDriver = insuredPersonDrivers[0] ?? drivers[0];

  useEffect(() => {
    if (typeof insuredPersonDriver !== 'undefined') {
      setLookupData({
        accessToOtherCars: getLookup(LOOKUP.ACCESS_TO_OTHER_CARS, insuredPersonDriver.useOfOtherCarsCd),
        carsInHousehold: getLookup(LOOKUP.CARS_IN_HOUSEHOLD, insured.person.carsInHouseholdCd),
        licenseHeld: formatLicense(
          getLookup(LOOKUP.LICENCE_TYPE, insuredPersonDriver.licence.licenceTypeCd),
          getLookup(LOOKUP.LICENSE_HELD_YEARS, insuredPersonDriver.licence.licenseDateYearsCd),
          getLookup(LOOKUP.LICENSE_HELD_MONTHS, insuredPersonDriver.licence.licenseDateMonthsCd),
        ),
        maritalStatus: getLookup(LOOKUP.MARITAL_STATUS, insured.person.maritalStatusCd),
        numberOfChildren: insured.person.numberOfChildrenCd
          ? getLookup(LOOKUP.NUMBER_OF_CHILDREN, insured.person.numberOfChildrenCd)
          : '',
        occupation: insuredPersonDriver.person.primaryOccupationCd
          ? getLookup(LOOKUP.OCCUPATION, insuredPersonDriver.person.primaryOccupationCd)
          : '',
        residencyStatus: getLookup(LOOKUP.RESIDENTIAL_STATUS, insured.person.residencyStatusCd),
        residentialStatus: getLookup(LOOKUP.RESIDENTIAL_STATUS, insured.person.residencyStatusCd),
        secondaryOccupation: insuredPersonDriver.person.secondaryOccupationCd
          ? getLookup(LOOKUP.OCCUPATION, insuredPersonDriver.person.secondaryOccupationCd)
          : '',
      });
    }
  }, [isLookupsFetched]);

  return (
    <>
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.fullName')} </Typography>
          <Typography variant="h4">
            {insured.person.firstName} {insured.person.surname}
          </Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_NAME);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_NAME}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.dateOfBirth')} </Typography>
          <Typography variant="h4">{formatDate(insured.person.dateOfBirth?.toString(), DATE_TYPES.SHORT)}</Typography>
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.resident')} </Typography>
          <Typography variant="h4">
            {insuredPersonDriver.person.residentSinceBirth
              ? t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.residentYes')
              : `Resident since ${formatDate(
                  insuredPersonDriver.person.residentSinceMonthsCd.toString(),
                  DATE_TYPES.MONTH,
                )}, ${insuredPersonDriver.person.residentSinceYearsCd}`}
          </Typography>
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.relationshipStatus')} </Typography>
          <Typography variant="h4">{lookupData.maritalStatus}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_RELATIONSHIP_STATUS);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_RELATIONSHIP}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.homeAddress')} </Typography>
          <Typography variant="h4">
            {insured.address.houseName} <br />
            {insured.address.houseNumber} {insured.address.street} <br />
            {insured.address.postalTown} <br />
            {insured.address.postcode}
          </Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_HOME_ADDRESS);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_HOME_ADDRESS}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.residentialStatus')} </Typography>
          <Typography variant="h4">{lookupData.residentialStatus}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_RESIDENTIAL_STATUS);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_RESIDENTIAL_STATUS}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.children')} </Typography>
          <Typography variant="h4">{lookupData.numberOfChildren}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHILDREN_AT_HOME);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_CHILDREN}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.carsAtHome')} </Typography>
          <Typography variant="h4">{lookupData.carsInHousehold}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CARS_AT_HOME);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_CARS_AT_HOME}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.occupation')} </Typography>
          <Typography variant="h4">{lookupData.occupation}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                setSelectedDriverUUID(insuredPersonDriver.uuid);
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_OCCUPATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_OCCUPATION}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.secondaryOccupation')}</Typography>
          {insuredPersonDriver.person.secondaryOccupation ? (
            <Typography variant="h4">{lookupData.secondaryOccupation}</Typography>
          ) : (
            <Typography variant="h4">
              {t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.none')}{' '}
            </Typography>
          )}
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DETAILS_CHANGE);
                setSelectedDriverUUID(insuredPersonDriver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_OCCUPATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_YOUR_DETAILS_SECONDARY_OCCUPATION}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />

      {drivers.length > 0 && (
        <>
          <Grid container className={classes.columnsContainer} justifyContent="space-between">
            <Grid item>
              <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.drivingLicence')} </Typography>
              <Typography variant="h4">{lookupData.licenseHeld}</Typography>
            </Grid>
          </Grid>
          <ReviewAllDivider />
          <Grid container className={classes.columnsContainer} justifyContent="space-between">
            <Grid item>
              <Typography>
                {t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.accessOtherVehicles')}
              </Typography>
              <Typography variant="h4">{lookupData.accessToOtherCars}</Typography>
            </Grid>
          </Grid>
          <ReviewAllDivider />
        </>
      )}
      <Grid container className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.NCD')} </Typography>
          <Typography variant="h4">{t('policyDetailsSection.yearNCD', { value: vehicle.ncdYearsCd })}</Typography>
        </Grid>
      </Grid>
    </>
  );
});
