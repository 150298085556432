import { CommonContainer, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { PolicyStatus } from '../../../../component/common/policyStatus';
import { track } from '../../../../lib/optimizely';
import { COMPONENT_ID } from '../../../../service/analytics';
import { ROUTE } from '../../../../service/constant';
import { useStores } from '../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../service/util/customHooks/useSegment';
import { HomeRenewalNotification } from '../../policies/policy/renewals/components/homeRenewalNotification';
import { MotorRenewalNotification } from '../../policies/policy/renewals/components/motorRenewalNotification';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.up('sm')]: {
      paddingTop: spacing(3),
    },
    '& .MuiLink-underlineHover': {
      cursor: 'pointer',
    },
    '& .MuiTypography-overline': {
      fontWeight: 700,
    },
    '& .account-mngm': {
      '&_description': {
        marginBottom: spacing(2),
      },
      '&_email': {
        wordBreak: 'break-all',
      },
      '&_phone': {
        [breakpoints.down('xs')]: {
          paddingTop: spacing(1),
        },
        [breakpoints.up('sm')]: {
          paddingLeft: spacing(1),
        },
      },
      '&_status': {
        [breakpoints.down('sm')]: {
          marginBottom: spacing(1),
        },
        [breakpoints.up('sm')]: {
          justifyContent: 'flex-end',
        },
      },
      '&_status-wrapper': {
        [breakpoints.down('sm')]: {
          justifyContent: 'flex-start',
          marginTop: spacing(3),
        },
      },
    },
    '& .payment': {
      '&_content': {
        alignItems: 'center',
      },
      '&_title': {
        paddingBottom: spacing(1),
      },
      marginTop: spacing(3),
    },
  },
}));

export const AccountManagement: React.FC = observer(function AccountManagement() {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { autoPolicies },
        home: { homePolicies },
      },
      customer,
    },
    interfaceStore: { chatBot },
  } = useStores();
  const history = useHistory();

  const getPolicyStatus = (): string => {
    const isEmailConfirmed = customer.emailInfo.emailConfirmed;
    return isEmailConfirmed ? t('policyStatus.verified') : t('policyStatus.unverified');
  };

  const handleNeedHelp = () => {
    track('My_Account_need_help_click');
    chatBot.startChat({ initiator: 'Settings Need Help' });
  };

  const handleClick = () => {
    eventTrack('Payment Methods link clicked', {
      type: TRACK_TYPE.LINK,
    });
    history.push(ROUTE.PAYMENT_METHODS);
  };

  return (
    <>
      <Grid className={classes.root} container>
        <Grid item container spacing={2} direction="column" className="account-mngm">
          {autoPolicies
            .filter((policy) => policy.futureRenewalEffectiveDate === null)
            .map((policy) => (
              <MotorRenewalNotification policy={policy} key={policy.policyNumber} />
            ))}
          {homePolicies
            .filter((policy) => policy.futureRenewalEffectiveDate === null)
            .map((policy) => (
              <HomeRenewalNotification policy={policy} key={policy.policyNumber} />
            ))}
          <Grid item>
            <Typography variant="overline">{t('accountManagement.accountMgtSectionTitle')}</Typography>
          </Grid>
          <Grid item className="account-mngm_description">
            <Typography>
              {t('accountManagement.updateDetails')}
              <Link
                target="_blank"
                data-testid={COMPONENT_ID.ACCOUNT_MANAGEMENT_NEED_HELP_LINK}
                onClick={handleNeedHelp}
              >
                {t('appbar.needHelp')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item sm={6} xs={12}>
            <CommonContainer color="secondary" variant="outlined">
              <Grid container>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body2">{t('accountManagement.emailAddress')}</Typography>
                  </Grid>
                  <Grid item sm={8} xs={12} className="account-mngm_email">
                    <Typography variant="h4">{customer.emailInfo.email}</Typography>
                  </Grid>
                  <Grid className="account-mngm_status-wrapper" container item justifyContent="flex-end" sm={4} xs={12}>
                    <Grid className="account-mngm_status" item>
                      <PolicyStatus label={getPolicyStatus()} status={getPolicyStatus()} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CommonContainer>
          </Grid>
          <Grid className="account-mngm_phone" item sm={6} xs={12}>
            <CommonContainer color="secondary" variant="outlined">
              <Grid container>
                <Grid item>
                  <Typography variant="body2">{t('accountManagement.mobileNumber')}</Typography>
                  <Grid item>
                    <Typography variant="h4">{customer.phoneNumber}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CommonContainer>
          </Grid>
        </Grid>
        <Grid container className="payment">
          <Grid item sm={12} xs={12} className="payment_title">
            <Typography variant="overline">{t('policyManagement.title')}</Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CommonContainer color="secondary" variant="outlined">
              <Grid className="payment_content" container justifyContent="space-between">
                <Typography variant="body2">{t('policyManagement.paymentMethods.title')}</Typography>
                <Link target="_blank" data-testid="update-btn" onClick={handleClick}>
                  {t('buttons.update')}
                </Link>
              </Grid>
            </CommonContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
