import { Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { EDIT_STEPS, LOOKUP } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import SelfService from '../../../../../../../../service/util/selfService';

import { ReviewAllDivider, ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListChangeOfUse: React.FC<ReviewListType> = observer(function ReviewListChangeOfUse({ onEdit }) {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: { mainVehicle },
      },
    },
    mtaStore: {
      pendedMTA: { vehicle },
    },
    interfaceStore: { isDesktop },
  } = useStores();

  const classes = useListStyles({ isDesktop });

  const isClassOfUseChanged = vehicle.classOfUse !== mainVehicle.classOfUse;
  const isSocialMileageCodeChanged = vehicle.socialMileageCode !== mainVehicle.socialMileageCode;
  const isBusinessMileageCodeChanged = vehicle.businessMileageCode !== mainVehicle.businessMileageCode;
  const isDaytimeLocationChanged = vehicle.daytimeLocation !== mainVehicle.daytimeLocation;
  const isOvernightLocationChanged = vehicle.overnightLocation !== mainVehicle.overnightLocation;
  const businessMileageCode = vehicle.businessMileageCode;

  const showComponent =
    isClassOfUseChanged ||
    isSocialMileageCodeChanged ||
    isDaytimeLocationChanged ||
    isOvernightLocationChanged ||
    isBusinessMileageCodeChanged;

  return (
    <>
      {showComponent && (
        <BlueTableContainer title={t('MTAJourney.reviewAll.carUsage')} className={classes.root}>
          <>
            {isClassOfUseChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.CarUsage.useOfCar')}</Typography>
                    <Typography variant="h4">{getLookup(LOOKUP.USAGE, vehicle.classOfUse)}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.CLASS_OF_USE} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            {isSocialMileageCodeChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.CarUsage.estimatedAnnualSocialMileage')}</Typography>
                    <Typography variant="h4">{getLookup(LOOKUP.MILEAGE, vehicle.socialMileageCode)}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.SOCIAL_MILEAGE_CODE} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            {isBusinessMileageCodeChanged && businessMileageCode && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.CarUsage.estimatedAnnualBusinessMileage')}</Typography>
                    <Typography variant="h4">{getLookup(LOOKUP.MILEAGE, vehicle.businessMileageCode ?? '')}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.BUSINESS_MILEAGE_CODE} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            <>
              {isDaytimeLocationChanged && (
                <>
                  <Grid container className={classes.columnsContainer} justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('makeChange.carDetails.daytimeLocation')}</Typography>
                      <Typography variant="h4">
                        {getLookup(LOOKUP.DAYTIME_LOCATION, vehicle.daytimeLocation)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DAYTIME_LOCATION} />
                    </Grid>
                  </Grid>
                  <ReviewAllDivider />
                </>
              )}
              {isOvernightLocationChanged && (
                <>
                  <Grid container className={classes.columnsContainer} justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('MTAJourney.CarUsage.usageEdit.overnight')}</Typography>
                      {vehicle.sameOvernightAddress ? (
                        <Typography variant="h4">
                          {getLookup(LOOKUP.OVERNIGHT_LOCATION, vehicle.overnightLocation)}
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body1" className="address">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: SelfService.formatAddress(vehicle.overnightAddress, true).full,
                              }}
                            />
                          </Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.OVERNIGHT_LOCATION, vehicle.overnightLocation)}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.OVERNIGHT_LOCATION} />
                    </Grid>
                  </Grid>
                  <ReviewAllDivider />
                </>
              )}
            </>
          </>
        </BlueTableContainer>
      )}
    </>
  );
});
