import {
  Alert,
  AlertTitle,
  Link,
  themeEsure,
  themeFirstAlternative,
  themeSheilasWheels,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../service/state/store';

export const ExtendedCallCenterAlert: React.FC = observer(function ExtendedCallCenterAlert() {
  const { t } = useTranslation('common');
  const {
    interfaceStore: { brand, getFeatureFlagData, hasFeatureFlag },
  } = useStores();

  const [showAlert, setShowAlert] = useState(true);

  if (hasFeatureFlag('showExtendedCallCenterAlert') && showAlert) {
    return (
      <Alert
        severity="info"
        onClose={() => {
          setShowAlert(false);
        }}
        style={{ marginBottom: '8px' }}
      >
        <AlertTitle>{t('extendedCallCenterAlert.title')}</AlertTitle>
        <p>
          {t('extendedCallCenterAlert.paragraph1part1')} {getFeatureFlagData('showExtendedCallCenterAlertData') ?? ''}
          {brand === themeEsure.themeName && (
            <>
              {t('extendedCallCenterAlert.paragraph2part1')}
              <Link href={t('extendedCallCenterAlert.urlES')} target="_blank" rel="noopener">
                {t('extendedCallCenterAlert.link')}
              </Link>
              {t('extendedCallCenterAlert.paragraph2part2')}
            </>
          )}
          {brand === themeSheilasWheels.themeName && (
            <>
              {t('extendedCallCenterAlert.paragraph2part1')}
              <Link href={t('extendedCallCenterAlert.urlSW')} target="_blank" rel="noopener">
                {t('extendedCallCenterAlert.link')}
              </Link>
              {t('extendedCallCenterAlert.paragraph2part2')}
            </>
          )}
          {brand === themeFirstAlternative.themeName && <> {t('extendedCallCenterAlert.paragraph2FA')}</>}
        </p>
      </Alert>
    );
  } else {
    return <></>;
  }
});
