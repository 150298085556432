import { Grid, Typography, makeStyles, useTheme } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { sendOpenInBrowserHook } from '../../lib/caura-bridge';
import { track } from '../../lib/optimizely/';
import { COMPONENT_ID, getDeviceSession } from '../../service/analytics';
import { NEED_HELP_HELP_CENTRE_PAGES, RENEWAL_CHATBOT_PAGES, ROUTE, THEME_NAME } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';
import { NavigationCardSmall } from '../common/navigationCardSmall';
import { useChatBotAttributes } from '../common/utils/useChatBotAttributes';
import { useRenewalsChatBotAttributes } from '../common/utils/useRenewalsChatBotAttributes';

interface NeedHelpProps {
  showClaimButton?: boolean;
  showTitle?: boolean;
}

const useStyles = makeStyles((theme) => ({
  cardcontainer: {
    paddingBottom: theme.spacing(1.5),
  },
  containerGrid: {
    paddingTop: theme.spacing(3),
  },
  navigationCardContainer: {
    paddingBottom: theme.spacing(5),
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    paddingBottom: theme.spacing(2),
  },
}));

export const isHelpCentre = (pathname: string, themeName: string): boolean => {
  const isHelpCentrePage = Object.values(NEED_HELP_HELP_CENTRE_PAGES).some((element) => element === pathname);
  const isHelpCentreTheme = themeName === THEME_NAME.SHEILASWHEELS || themeName === THEME_NAME.ESURE;
  return isHelpCentrePage && isHelpCentreTheme;
};

export const NeedHelp: React.FC<NeedHelpProps> = observer(function NeedHelp({
  showClaimButton = true,
  showTitle = true,
}) {
  const { t } = useTranslation('myAccount');
  const location = useLocation();
  const dividedPath = location.pathname.split('/');
  const currentPage = dividedPath[dividedPath.length - 1];
  const {
    interfaceStore: {
      isMobile,
      chatBot: { startChat },
      isCaura,
    },
    dashboardStore: {
      insurance: { selectedPolicyType, home, isHome, auto },
    },
  } = useStores();
  const { eventTrack } = useSegment();

  const policySelected = isHome ? home : auto;

  const chatRenewalsBotAttr = useRenewalsChatBotAttributes(
    // used for motor product
    currentPage === RENEWAL_CHATBOT_PAGES.YOUR_QUOTE_RENEWAL ? 'ssRenQuoteMainChat' : 'ssRenQuoteUWDeclined',
  );

  const chatbotAttributes = useChatBotAttributes(
    policySelected.mainPolicy.autoRenewal ? 'ssRenARGoAhead' : 'ssHomeMainChat',
  );

  const classes = useStyles();
  const history = useHistory();
  const pathname = location.pathname;
  const theme = useTheme();

  const handleClick = () => {
    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.HOW_TO_MAKE_A_CLAIM_CARD,
      label: t('needHelp.claim'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });

    history.push(ROUTE.HOW_TO_MAKE_A_CLAIM_HOME);
  };

  const shouldTrackEventForHome =
    selectedPolicyType === 'home' && currentPage === RENEWAL_CHATBOT_PAGES.YOUR_QUOTE_RENEWAL;

  const onNeedHelp = () => {
    track('My_Account_chat_with_us_click');
    startChat({
      initiator: 'Chat With Us Button',
      ...(chatRenewalsBotAttr ?? {}),
      ...(chatbotAttributes ?? {}),
    });
    shouldTrackEventForHome &&
      eventTrack('Home Rnwl | Need help chat with us clicked', {
        type: TRACK_TYPE.CHAT,
      });
  };

  const onHelpCentre = () => {
    track('HelpCentre Click');

    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.HELP_CENTRE_CARD,
      label: t('needHelp.helpCentre.title'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });

    const link =
      theme.themeName === THEME_NAME.SHEILASWHEELS
        ? t('needHelp.helpCentre.swLink')
        : t('needHelp.helpCentre.esureLink');
    const suffix = currentPage === RENEWAL_CHATBOT_PAGES.YOUR_QUOTE_RENEWAL ? 'renewal' : '';

    const url = `${link}${suffix}`;
    isCaura ? sendOpenInBrowserHook(url) : window.open(url, '_blank');
  };

  return (
    <Grid className={classes.containerGrid} container>
      {showTitle && (
        <Typography className={classes.sectionTitle} variant="h2">
          {t('appbar.needHelp')}
        </Typography>
      )}
      <Grid container>
        <Grid className={classes.navigationCardContainer} container spacing={2}>
          <Grid item xs={12} md={showClaimButton ? 6 : 12} className={classes.cardcontainer}>
            {isHelpCentre(pathname, theme.themeName) ? (
              <NavigationCardSmall
                color="secondary"
                title={t('needHelp.helpCentre.title')}
                icon="help-centre"
                iconType="solid"
                showDescription={!isMobile}
                trackEvent={onHelpCentre}
              />
            ) : (
              <NavigationCardSmall
                color="secondary"
                title={t('needHelp.label')}
                icon="chat"
                iconType="solid"
                showDescription={!isMobile}
                trackEvent={onNeedHelp}
              />
            )}
          </Grid>
          {showClaimButton && (
            <Grid item xs={12} md={6} onClick={handleClick} data-testid="claims-link">
              <NavigationCardSmall
                color="secondary"
                title={t('needHelp.claim')}
                icon="shield-check-line"
                iconType="solid"
                showDescription={!isMobile}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
