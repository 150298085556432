import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { SVGWrapper } from '../../../../../../images/SVGWrapper';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .button': {
      padding: spacing(0, 1),
    },
    '& .container': {
      '&-text': {
        maxWidth: '300px',
      },
      alignItems: 'center',
      gap: spacing(4),
    },
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));

export const DDRenewalTechnicalError: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const {
    interfaceStore: {
      chatBot: { startChat },
      dialog: { closeDialog },
    },
    dashboardStore: {
      insurance: {
        home: { selectedPolicyNumber },
      },
    },
  } = useStores();

  const handleBack = () => {
    closeDialog();
  };

  const handleChat = () => {
    closeDialog();
    setTimeout(() => {
      startChat({
        contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
        customContactAttributes: {
          CustPolicyNumber: selectedPolicyNumber,
          ssRenPortalError: 'true',
        },
        initiator: 'Chat with us',
      });
    }, 500);
  };

  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      className={classes.root}
      data-testid="dd-renewal-error"
    >
      <Grid container item className="container" alignItems="center" direction="column">
        <SVGWrapper alt="technical-error" src="technical-error.svg" width="25%" />
        <Typography variant="h2" align="center" className="container-text">
          {t('DDRenewalFailed.title')}
        </Typography>
        <Typography variant="body1" className="container-text" align="center">
          {t('DDRenewalFailed.description')}
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <Grid className="button">
            <CommonButton
              text={t('buttons.goBack')}
              onClick={handleBack}
              startIcon="arrow-left"
              size="large"
              color="secondary"
              colorIcon="black"
              variant="outlined"
              data-testid="back"
            />
          </Grid>
          <Grid className="button">
            <CommonButton
              className="button"
              text={t('needHelp.label')}
              onClick={handleChat}
              variant="contained"
              color="primary"
              size="large"
              data-testid="chat"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
