import { Button, FormHelperText, TextField } from '@esure-cloud/react-components';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressesContainer } from '../../../../../../component/common/addressesContainer';
import IconSure from '../../../../../../component/common/icon';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { IInsuredAddress } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { useForm } from '../../../../../../service/util/customHooks/useForm';

interface AddressForm {
  houseNumber: string;
  postcode: string;
}

export interface FindAddressProps extends AddressForm {
  existingAddress: IInsuredAddress | string;
  setSelectedAddress: (value: IInsuredAddress | string) => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .addressForm': {
      '&-desc': {
        marginBottom: spacing(1),
      },
      '&-row': {
        marginBottom: spacing(3),
      },
      maxWidth: 343,
    },
    gap: spacing(3),
    marginBottom: spacing(3),
    marginTop: spacing(2),
  },
}));

export const FindAddress: React.FC<FindAddressProps> = ({ postcode, existingAddress, setSelectedAddress }) => {
  const { t } = useTranslation('myAccount');
  const [fetchError, setFetchError] = useState(false);
  const [addresses, setAddresses] = useState<IInsuredAddress[]>();
  const classes = useStyles();
  const {
    dashboardStore: { fetchAddressLookup },
  } = useStores();

  const { errors, formData, handleChange } = useForm<AddressForm>({
    initialValues: { postcode },
    validationOnChange: true,
    validations: {
      postcode: {
        pattern: {
          allowWhiteSpaces: true,
          message: t('validations.postcodeNotValid'),
          value: '^[A-Za-z0-9]{5,7}$',
        },
      },
    },
  });

  useEffect(() => {
    setAddresses(undefined);
    setFetchError(false);
  }, [formData.postcode, formData.houseNumber]);

  const cancelFindAddress = () => {
    setSelectedAddress(existingAddress);
  };

  const handleFindAddress = () => {
    fetchAddressLookup(formData.houseNumber ? formData.houseNumber : '', formData.postcode ? formData.postcode : '')
      .then((res) => {
        if (res !== undefined && res.length === 0) {
          setFetchError(true);
        } else {
          setAddresses(res);
        }
      })
      .catch(() => {
        setFetchError(true);
      });
  };
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className="addressForm">
        <Typography variant="body1" className="addressForm-row">
          {t('MTAJourney.payment.findAddress')}
        </Typography>
        <Grid container item direction="column" className="addressForm-row">
          <TextField
            type="text"
            value={formData.houseNumber}
            onChange={handleChange('houseNumber')}
            placeholder={t('placeholders.houseNumber')}
            label={t('MTAJourney.payment.houseNumber')}
          />
        </Grid>
        <Grid container item direction="column" className="addressForm-row">
          <TextField
            type="text"
            error={!!errors.postcode}
            value={formData.postcode}
            onChange={handleChange('postcode')}
            placeholder={t('placeholders.postcode')}
            label={t('MTAJourney.fields.postcode')}
            InputProps={{
              endAdornment: !!errors.postcode && <IconSure icon="alert" size="20" color="error" type="solid" />,
            }}
          />
          {errors.postcode && <FormHelperText error>{errors.postcode}</FormHelperText>}
          {fetchError && <FormHelperText error>{t('MTAJourney.fetchingAddressesError')}</FormHelperText>}
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="outlined"
              data-testid={COMPONENT_ID.FIND_ADDRESS_CANCEL_BUTTON}
              fullWidth
              onClick={() => cancelFindAddress()}
            >
              {t('MTAJourney.button.cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              data-testid={COMPONENT_ID.FIND_ADDRESS_BUTTON}
              fullWidth
              disabled={!!errors.postcode}
              onClick={() => handleFindAddress()}
            >
              {t('MTAJourney.buttons.findAddress')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {addresses && (
        <Grid>
          <Typography variant="h3">{t('MTAJourney.foundResults', { value: addresses.length.toString() })}</Typography>
          <Typography variant="body1" className="addressForm-desc">
            {t('MTAJourney.payment.selectList')}
          </Typography>
          <AddressesContainer addresses={addresses} setSelectedAddress={setSelectedAddress} />
        </Grid>
      )}
    </Grid>
  );
};
