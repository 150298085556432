import { Instance, ModelProperties, ModelSnapshotType, applySnapshot, cast, getSnapshot, types } from 'mobx-state-tree';

import { fireSegmentPageEvent } from '../../../analytics';
import { checkTheme } from '../../../util/checkTheme';
import { Feature } from '../../../util/interfaceModels/interfaceModels';

import { ChatBot } from './chatBot';
import { Dialog } from './dialog';
import { Notification } from './notification';
import { Panel } from './panel';

export const FeatureToggle = types.model('FeatureToggle', {
  id: types.optional(types.string, ''),
  value: types.optional(types.string, 'false'),
});

export const StepAutoCompleteData = types.model('StepAutoCompleteData', {
  id: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
});

export const InterfaceStore = types
  .model('InterfaceStore', {
    accessToken: types.optional(types.string, ''),
    alertMessage: types.optional(types.string, ''),
    brand: types.optional(types.string, ''),
    chatBot: types.optional(ChatBot, () => ChatBot.create()),
    dialog: types.optional(Dialog, () => Dialog.create()),
    expireDate: types.optional(types.number, 0),
    featureToggles: types.optional(types.array(FeatureToggle), []),
    hasIovationError: types.optional(types.boolean, false),
    hasQuoteError: types.optional(types.boolean, false),
    isCaura: types.optional(types.boolean, false),
    isDesktop: types.optional(types.boolean, false),
    isMobile: types.optional(types.boolean, false),
    isTablet: types.optional(types.boolean, false),
    notification: types.optional(Notification, () => Notification.create()),
    pagesViewed: types.optional(types.number, 0),
    panel: types.optional(Panel, () => Panel.create()),
    refreshAttempts: types.optional(types.number, 0),
    stepAutoCompleteData: types.optional(types.array(StepAutoCompleteData), []),
    totalErrors: types.optional(types.number, 0),
  })
  .volatile<{
    initialState: ModelSnapshotType<ModelProperties>;
    isHydrateAttempted: boolean;
    isLoading: boolean;
    lastVisitedUrl: string;
  }>((self) => ({
    initialState: getSnapshot(self),
    isHydrateAttempted: false,
    isLoading: false,
    lastVisitedUrl: document.referrer, // Initially, last visited page is the referrer
  }))
  .views((self) => ({
    getFeatureFlagData(flagName: string): string | undefined {
      const ft = self.featureToggles.find((item) => item.id === flagName);
      return ft?.value;
    },
    getStepAutoComplete(stepId: string): string | undefined {
      const item = self.stepAutoCompleteData.find((item) => item.id === stepId);
      return item?.value;
    },
    hasFeatureFlag(flagName: string): boolean {
      return self.featureToggles.some((item) => item.id === flagName && item.value === 'true');
    },
  }))
  .actions((self) => ({
    clearStepAutoComplete() {
      self.stepAutoCompleteData = cast([]);
    },
    clearStore() {
      // Apply the snapshot, but keep some of the existing state values
      applySnapshot(self, {
        ...self.initialState,
        brand: self.brand,
        featureToggles: self.featureToggles,
        isDesktop: self.isDesktop,
        isMobile: self.isMobile,
        isTablet: self.isTablet,
      });
      self.chatBot.closeChat();
    },
    onPathChange() {
      fireSegmentPageEvent(self.lastVisitedUrl);
      self.lastVisitedUrl = document.location.href;
      //Check if date is expired and if so, set alert message before 10 seconds to inform
      // user in advance that he will be logged out
      if (self.expireDate > 0 && self.expireDate - 1000 * 60 * 10 < Date.now()) {
        self.alertMessage = `Your session has expired. Please log in again to manage your account.`;
      }
    },
    setAccessToken(newValue: string) {
      self.accessToken = newValue;
    },
    setBrand(newValue: string) {
      self.brand = newValue;
    },
    setBreakpoints() {
      const currentWidth = window.innerWidth;
      const theme = checkTheme();
      if (currentWidth < theme.breakpoints.values.sm) {
        self.isDesktop = false;
        self.isTablet = false;
        self.isMobile = true;
      } else if (currentWidth <= theme.breakpoints.values.md) {
        self.isDesktop = false;
        self.isTablet = true;
        self.isMobile = false;
      } else {
        self.isDesktop = true;
        self.isTablet = false;
        self.isMobile = false;
      }
    },
    setExpireDate(newValue: number) {
      self.expireDate = newValue;
    },
    setFeatureToggles(featureArray: Feature[]) {
      self.featureToggles = cast(featureArray);
    },
    setHasIovationError(newValue: boolean) {
      self.hasIovationError = newValue;
    },
    setHasQuoteError(newValue: boolean) {
      self.hasQuoteError = newValue;
    },
    setIsCaura(newValue: boolean) {
      self.isCaura = newValue;
    },
    setIsHydrateAttempted(newValue: boolean) {
      self.isHydrateAttempted = newValue;
    },
    setIsLoading(newValue: boolean) {
      self.isLoading = newValue;
    },
    setIsMobile(newValue: boolean) {
      self.isMobile = newValue;
    },
    setIsTablet(newValue: boolean) {
      self.isTablet = newValue;
    },
    setLastVisitedUrl(newValue: string) {
      self.lastVisitedUrl = newValue;
    },
    setPagesViewed(newVal: number) {
      self.pagesViewed = newVal;
    },
    setRefreshAttempts() {
      self.refreshAttempts = self.refreshAttempts + 1;
    },
    setStepAutoComplete(stepId: string, value: string) {
      const foundStep = self.stepAutoCompleteData.find((item) => item.id === stepId);
      if (foundStep === undefined) {
        self.stepAutoCompleteData = cast([...self.stepAutoCompleteData, { id: stepId, value: value }]);
      } else {
        foundStep.value = value;
      }
    },
    setTotalErrors(newValue: number) {
      self.totalErrors = newValue;
    },
  }));

export type IInterfaceStore = Instance<typeof InterfaceStore>;
