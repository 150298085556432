import { Button, Grid, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { JOURNEY_TYPE, MTA_COMMON_ROUTES, ROUTE } from '../../../../../../../../service/constant';
import { ICompletedMTAJourney } from '../../../../../../../../service/state/models/mta';
import { useStores } from '../../../../../../../../service/state/store';
import { useStepperContext } from '../utils/stepperContext';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& a': {
      marginTop: spacing(1),
    },
    '& button': {
      '&.cancelButton': {
        fontSize: '1rem',
        marginTop: spacing(1.5),
        width: 'auto',
      },
      '&.fullWidthButton': {
        width: '100%',
      },
      '&.primaryButton': {
        whiteSpace: 'nowrap',
      },
      lineHeight: 1,
      marginTop: spacing(5),
      padding: spacing(0, 4),
      width: '48%',
    },
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
      width: '100%',
    },
    gap: spacing(1),
    paddingBottom: spacing(5),
    width: 'fit-content',
  },
}));

export interface HandleContinueFunctionReturnProperties {
  quoteFailure?: boolean;
}

type HandleContinueFunction = () => Promise<void | HandleContinueFunctionReturnProperties> | void;

export interface PrimaryButtonAsObject {
  blockContinue?: boolean;
  buttonId?: string;
  disabled?: boolean;
  handleContinue?: HandleContinueFunction;
  text?: string;
}
export type PrimaryButtonAsFunction = () => React.ReactElement;

export interface FooterProps {
  backButtonId?: string;
  cancelButtonId?: string;
  handleBack?: () => void;
  hidePrimaryButton?: boolean;
  isValid?: () => boolean;
  primaryButton?: PrimaryButtonAsObject | PrimaryButtonAsFunction;
}

export const redirectToLastJourney = (completedMTAJourneys: ICompletedMTAJourney[]): string => {
  const lastJourney = completedMTAJourneys.length - 1;

  switch (completedMTAJourneys[lastJourney]?.completedJourney) {
    case JOURNEY_TYPE.ADD_DRIVER:
      return ROUTE.ADD_DRIVER;
    case JOURNEY_TYPE.CAR_MILEAGE_AND_USAGE:
      return ROUTE.CAR_MILEAGE_AND_USAGE;
    case JOURNEY_TYPE.CAR_MODIFICATIONS:
      return ROUTE.CAR_MODIFICATIONS;
    case JOURNEY_TYPE.CHANGE_DRIVER:
      return ROUTE.CHANGE_DRIVER_DETAILS;
    case JOURNEY_TYPE.CHANGE_HOME_ADDRESS:
      return ROUTE.CHANGE_HOME_ADDRESS;
    case JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS:
      return ROUTE.CHANGE_PERSONAL_DETAILS;
    case JOURNEY_TYPE.CHANGE_YOUR_CAR:
      return ROUTE.CHANGE_YOUR_CAR;
    case JOURNEY_TYPE.OTHER_CHANGES:
      return ROUTE.OTHER_CHANGES;
    case JOURNEY_TYPE.REGISTRATION_NUMBER:
      return ROUTE.CHANGE_YOUR_REG_NO;
    case JOURNEY_TYPE.REMOVE_DRIVER:
      return ROUTE.REMOVE_DRIVER;
    case JOURNEY_TYPE.MORE_CHANGES:
      return ROUTE.MORE_CHANGES;
    case JOURNEY_TYPE.REVIEW_CHANGES:
      return ROUTE.MTA_REVIEW_CHANGES;
    case JOURNEY_TYPE.QUOTE_READY:
      return ROUTE.MTA_QUOTE_READY;
    default:
      return ROUTE.DASHBOARD;
  }
};

export const MTAFooter: React.FC<FooterProps> = observer(function MTAFooter({
  backButtonId,
  cancelButtonId,
  hidePrimaryButton,
  primaryButton,
  handleBack,
  isValid,
}) {
  const {
    stepsData: { steps, journeyType, lastStepOfJourney, nextRoute },
    step,
    setStep,
    nextStep: handleContinue,
    isMultiMTA,
    setIsMultiMTA,
  } = useStepperContext();
  const {
    mtaStore: { completedMTAJourneys, setCompletedJourney, removeCompletedJourney },
  } = useStores();

  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const { pathname } = useLocation();

  /* istanbul ignore next */
  const handleBackButton = () => {
    if (MTA_COMMON_ROUTES.includes(pathname) && step <= 1) {
      if (completedMTAJourneys.length) {
        Promise.resolve(history.push(redirectToLastJourney(completedMTAJourneys))).then(() => {
          setTimeout(() => {
            setStep(completedMTAJourneys[completedMTAJourneys.length - 1].lastStep);
            removeCompletedJourney();
            if (completedMTAJourneys.length === 0) {
              setIsMultiMTA(false);
            }
          }, 25);
        });
      }
    } else if (isMultiMTA && step <= 1 && !MTA_COMMON_ROUTES.includes(pathname)) {
      history.push(ROUTE.MORE_CHANGES);
    } else if (handleBack) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  const isPrimaryButtonObject = typeof primaryButton === 'object';

  const handleContinueButton = async () => {
    if (isPrimaryButtonObject && primaryButton.handleContinue) {
      return await primaryButton.handleContinue();
    } else {
      handleContinue();
    }
  };

  const continueClicked = async () => {
    if (isValid && !isValid()) {
      return;
    }
    const isLastStep = step + 1 === steps.length;

    if (isLastStep) {
      const completedMTAJourney: ICompletedMTAJourney = {
        completedJourney: journeyType,
        lastStep: lastStepOfJourney,
      };

      if (!completedMTAJourneys.find((journey) => journey.completedJourney === completedMTAJourney.completedJourney)) {
        setCompletedJourney(completedMTAJourney);
      }

      // Wait for the continue button to finish its action before redirecting, otherwise we can end up navigating to e.g the quote ready page and firing the page events to Segment before the quote is ready
      const continueButtonResult = await handleContinueButton();
      if ((isPrimaryButtonObject && !primaryButton.blockContinue) || !isPrimaryButtonObject) {
        // If the continue button returns quoteFailure, then we don't want to navigate to the next page
        if (!continueButtonResult?.quoteFailure) {
          history.push(nextRoute);
        }
      }
    } else {
      handleContinueButton();
    }
  };

  const handleCancel = () => {
    history.push(ROUTE.POLICY_DETAILS);
  };

  return (
    <Grid container className={classes.root}>
      <Button
        data-testid={backButtonId}
        color="secondary"
        variant="outlined"
        onClick={isMultiMTA ? handleBackButton : handleBack ? handleBack : handleBackButton}
        className={clsx(hidePrimaryButton && 'fullWidthButton')}
      >
        {t('MTAJourney.button.back')}
      </Button>

      {!hidePrimaryButton && (
        <>
          {typeof primaryButton === 'object' || primaryButton === undefined ? (
            <Button
              data-testid={primaryButton?.buttonId}
              color="primary"
              variant="contained"
              className="primaryButton"
              onClick={continueClicked}
              disabled={primaryButton?.disabled}
            >
              {primaryButton?.text ? primaryButton.text : t('MTAJourney.button.continue')}
            </Button>
          ) : (
            primaryButton()
          )}
        </>
      )}
      <Button
        data-testid={cancelButtonId}
        variant="text"
        color="secondary"
        className="cancelButton"
        onClick={handleCancel}
      >
        {t('MTAJourney.button.cancel')}
      </Button>
    </Grid>
  );
});
