import { FormControlLabel, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { ILookupItem } from '../../service/state/models/dashboard/lookups';
import { MtaTooltip } from '../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';

export interface ChangeCarLocationProps {
  location: string | undefined;
  options: ILookupItem[] | undefined;
  setLocation: (value: React.SetStateAction<string | undefined>) => void;
  tooltipDescription: string;
  tooltipTitle: string;
}
export const ChangeCarLocation: React.FC<ChangeCarLocationProps> = ({
  tooltipTitle,
  tooltipDescription,
  location,
  setLocation,
  options,
}) => {
  return (
    <>
      <MtaTooltip
        description={<Typography variant="body1">{tooltipDescription}</Typography>}
        tooltipTitle={tooltipTitle}
      />

      <RadioGroup value={location} onChange={(event) => setLocation(event.target.value)}>
        {options?.map((item, index) => {
          return (
            <FormControlLabel key={`${item.code}-${index}`} value={item.code} control={<Radio />} label={item.value} />
          );
        })}
      </RadioGroup>
    </>
  );
};
