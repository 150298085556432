import { Grid, RegistrationPlate, Typography, makeStyles, useTheme } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { DATE_TYPES, POLICY_STATUS } from '../../../service/constant';
import { IAutoPolicy, Vehicle } from '../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../service/state/store';
import { formatDate } from '../../../service/util/formatDate';
import SelfService from '../../../service/util/selfService';
import { capitalizeWords } from '../../../service/util/stringUtils';
import { PolicyCardRenewalQuote } from '../../../view/pages/policies/policy/renewals/components/policyCardRenewalQuote';
import { ViewDocsBtn } from '../../common/button/viewDocsBtn';
import { ViewPolicyBtn } from '../../common/button/viewPolicyBtn';

import { PolicyCardFailedLoading } from './policyCardFailedLoading';

import { AutoRenew, PolicyCardProps, useCommonStylesPolicyCard } from '.';

const useMotorStyles = makeStyles(({ spacing }) => ({
  vehicleContainer: {
    '& .info-plate': {
      color: 'black',
      margin: spacing(1, 0),
    },
    '@media (max-width:960px)': {
      display: 'block !important',
      maxWidth: '100% !important',
    },
    alignSelf: 'center',
    border: '0 !important',
    gap: 15,
    maxWidth: '25% !important',
    minWidth: 190,
    padding: '0 !important',
  },
}));

export const MotorPolicyCard: React.FC<PolicyCardProps> = (props) => {
  const { t } = useTranslation(['myAccount', 'common']);
  const classes = useCommonStylesPolicyCard();
  const motorClasses = useMotorStyles();
  const theme = useTheme();
  const history = useHistory();

  const location = useLocation();
  const dividedPath = location.pathname.split('/');
  const currentPage = dividedPath[dividedPath.length - 1];

  const {
    interfaceStore: { isMobile },
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();

  const {
    insured: {
      person: { firstName },
    },
    policyRenewalQuoteDetails: { vehicle },
  } = policySelected;

  const { effectiveDate, expirationDate, policyNumber, policyStatus, vehicles, autoRenewal, loadingFailed } =
    props.policy as IAutoPolicy;

  const isPolicyExpired = SelfService.isPolicyExpired(policyStatus);

  useEffect(() => {
    if (isPolicyExpired && currentPage === 'policy') {
      const params = new URLSearchParams();
      params.append('expired', 'true');
      history.push({ search: params.toString() });
    }
  }, [isPolicyExpired, history]);

  // If loading failed, return the failed loading component, no need to carry on this component
  if (loadingFailed) {
    return <PolicyCardFailedLoading policy={props.policy} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { make, model, vrm } = vehicles[0] ?? Vehicle.create();

  const vehicleName = `${make} ${model}`;

  return (
    <Grid
      className={clsx(
        'policy-card',
        classes.root,
        policyStatus,
        props.isPolicyHeader ? 'policy-page' : '',
        SelfService.themeClass(theme),
      )}
      container
      item
      justifyContent="space-between"
      sm={12}
      data-testid="box"
    >
      <Grid
        alignItems="flex-start"
        className={clsx(classes.root, motorClasses.vehicleContainer, props.isRenewalHeader ? 'renewalHeader' : '')}
        container
        direction="row"
        item
        justifyContent="space-between"
        sm={12}
      >
        <Grid item xs>
          {props.isRenewalHeader ? (
            <Typography variant="h2" className="renewalHeading">
              {firstName}
              {t('policyManagement.homeRenewalQuote.policyCard.title', { insuranceID: 'car' })}
            </Typography>
          ) : (
            <Typography variant="h2">{capitalizeWords(vehicleName)}</Typography>
          )}

          <Grid className="info-plate">
            <RegistrationPlate active label={props.isRenewalHeader ? vehicle.vrm : vrm} />
          </Grid>

          {props.isRenewalHeader && (
            <>
              <PolicyCardRenewalQuote autoRenewal={autoRenewal} expirationDate={expirationDate} />
              <Typography className="autorenew">
                <AutoRenew autoRenewal={autoRenewal} />
              </Typography>
            </>
          )}

          {!props.isPolicyHeader && (
            <Typography variant={!isMobile ? 'body2' : 'caption'}>
              {t('policyDetails.policyNo')}
              {policyNumber}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        className={clsx(
          'policy-details',
          policyStatus === POLICY_STATUS.EXPIRED || policyStatus === POLICY_STATUS.PENDING ? 'single-item' : '',
        )}
        container
        item
        justifyContent="space-between"
        md
        wrap="nowrap"
        xs
      >
        {!props.isPolicyHeader && (
          <>
            <Grid className="policy-details__list container" item>
              {policyStatus !== POLICY_STATUS.EXPIRED && (
                <Grid className={clsx('policy-details__item')} container direction="column" item>
                  <Typography variant="body1">{t('policyDetails.coverStarts')}</Typography>
                  <Typography variant="h4">{formatDate(effectiveDate?.toString(), DATE_TYPES.SHORT_SPACE)}</Typography>
                </Grid>
              )}
              {policyStatus !== POLICY_STATUS.PENDING && (
                <Grid className={clsx('policy-details__item')} container direction="column" item>
                  <Typography variant="body1">
                    {policyStatus === POLICY_STATUS.EXPIRED
                      ? t('policyDetails.coverEnded')
                      : t('policyDetails.coverEnds')}
                  </Typography>
                  <Typography variant="h4">
                    {formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT_SPACE)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid alignItems="flex-start" className="policy-details__buttons" container direction="row" item xs>
              <Grid item>
                {policyStatus === POLICY_STATUS.ACTIVE || isPolicyExpired ? (
                  <ViewPolicyBtn policy={props.policy} />
                ) : (
                  <ViewDocsBtn policy={props.policy} />
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Grid data-testid="NonMobileImage" className="policy-car" container item justifyContent="flex-end" sm xs={12}>
          <SVGWrapper
            alt="Policy Car"
            className={clsx('policy-car__full', isPolicyExpired ? 'policy-car__expired' : '')}
            src="vehicle-full-orange.svg"
          />
        </Grid>
      </Grid>
      {props.isRenewalHeader && (
        <Typography className="autorenewBottom">
          <AutoRenew autoRenewal={autoRenewal} />
        </Typography>
      )}
    </Grid>
  );
};
