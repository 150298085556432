import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .description': {
      paddingBottom: spacing(2),
    },
    '& .subtitle': {
      paddingBottom: spacing(1),
      paddingTop: spacing(2),
    },
  },
}));

export interface StepTemplateI {
  description?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const StepTemplate: React.FC<StepTemplateI> = ({ children, ...rest }) => {
  const classes = useStyles();
  const { subtitle } = rest;

  return (
    <Grid className={classes.root}>
      {subtitle && (
        <Typography variant="body1" className="subtitle">
          {subtitle}
        </Typography>
      )}
      {children}
    </Grid>
  );
};
