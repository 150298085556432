import { pause } from '@esure-cloud/js-utils';

import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import config from '../../../../../../../../service/config';
import {
  DATE_TYPES,
  DIALOG_TYPE,
  LOOKUP_TYPE_PAGE,
  MTA_QUOTE_FAILURE_REASON,
} from '../../../../../../../../service/constant';
import { APIResponse } from '../../../../../../../../service/network';
import {
  IDriver,
  ILicence,
  IPerson,
  IVehicle,
} from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IQuote } from '../../../../../../../../service/state/models/mta';
import { useStores } from '../../../../../../../../service/state/store';
import { IDateTime } from '../../../../../../../../service/state/types/dateTime';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../../../service/util/formatDate';
import { HandleContinueFunctionReturnProperties } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';
import { Row } from '../components/multipleColumnsEditPage';

export const filterRows = (rows: (Row | boolean)[]): Row[] => {
  return rows.filter((row) => row) as Row[];
};

export const compareVehicleObjects = (obj1: IVehicle, obj2: IVehicle): string[] => {
  const updatedKeys: string[] = [];
  Object.keys(obj1)
    .filter((key) => {
      return Object.values(LOOKUP_TYPE_PAGE).includes(key as LOOKUP_TYPE_PAGE);
    })
    .forEach((key) => {
      if (obj1[key as keyof IVehicle] !== obj2[key as keyof IVehicle]) {
        updatedKeys.push(key);
      }
    });

  //car overnight screen can include multiple changes (overnight location and overnight postcode)
  if (
    obj1['overnightLocation'] !== obj2['overnightLocation'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE);
  }
  return updatedKeys;
};

export const useDisplayDate = (date: IDateTime): string => {
  if (date) {
    return `${formatDate(date.toString(), DATE_TYPES.SHORT_SPACE)},
          ${DateTime.fromISO(date.toString()).toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  return '';
};

interface UseFetchQuoteReturn {
  handleFetchQuote: () => Promise<HandleContinueFunctionReturnProperties | void>;
}

export const useFetchQuote = (): UseFetchQuoteReturn => {
  const { eventTrack } = useSegment();
  const {
    interfaceStore: {
      setHasQuoteError,
      dialog: { openDialog, closeDialog },
    },
    mtaStore: { fetchPendedQuote, fetchQuote, resetPendedMTA, completedMTAJourneys },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber },
        },
      },
    },
  } = useStores();
  const {
    nextStep,
    stepsData: { journeyType },
  } = useStepperContext();

  const journeys: string[] = [];

  completedMTAJourneys.forEach((journey) => journeys.push(journey.completedJourney));

  const handleSuccessFetchQuote = () => {
    closeDialog();
    nextStep();
  };

  /* istanbul ignore next */
  const handleFailureFetchQuote = (
    res: AxiosResponse<APIResponse<IQuote>> | undefined,
  ): HandleContinueFunctionReturnProperties => {
    // If quote was ineligible, the axios interceptor has already set quote error and done a history push to the quote ineligible page, therefore we don't want to open any dialog
    if (res?.statusText !== MTA_QUOTE_FAILURE_REASON.QUOTE_INELIGIBLE) {
      setHasQuoteError(true);
      openDialog({ isFullScreen: true, type: DIALOG_TYPE.MTA_TECHNICAL_PROBLEMS });
    }
    resetPendedMTA();
    return { quoteFailure: true };
  };

  /* istanbul ignore next */
  const handleFetchQuote = async (): Promise<HandleContinueFunctionReturnProperties | void> => {
    openDialog({ isFullScreen: true, type: DIALOG_TYPE.FETCH_QUOTE });
    return await fetchQuote(policyNumber).then(async (res) => {
      if (res?.status === 200) {
        handleSuccessFetchQuote();
      } else if (res?.status === 504) {
        eventTrack('Get a quote almost there message viewed', {
          journeyType,
          type: TRACK_TYPE.VIEW,
        });
        openDialog({ isFullScreen: true, type: DIALOG_TYPE.FETCH_QUOTE_SECONDARY_DELAY });
        // Wait for specific number of seconds before we make the fetchPendedQuote API call
        await pause(config.app.mtaChargesApiCallWaitTimeMilliseconds);
        return await fetchPendedQuote(policyNumber).then((res) => {
          if (res?.status === 200) {
            handleSuccessFetchQuote();
          } else {
            return handleFailureFetchQuote(res);
          }
        });
      } else {
        return handleFailureFetchQuote(res);
      }
    });
  };

  return { handleFetchQuote };
};

export const hasDifferentModifications = (firstObj: IVehicle, secondObj: IVehicle): boolean => {
  if (firstObj.modifications.length === 0 || secondObj.modifications.length === 0) {
    return true;
  }
  const differences = firstObj.modifications.filter((firstItem) => {
    if (!secondObj.modifications.some((secondItem) => secondItem.modificationCode === firstItem.modificationCode)) {
      return firstItem;
    }
    return null;
  });
  return differences.length !== 0;
};

const pushAdditionalDriverChanges = (driverA: IDriver, driverB: IDriver, updatedKeys: string[]) => {
  if (driverA.person['surname'] !== driverB.person['surname'] && !updatedKeys.includes(LOOKUP_TYPE_PAGE.TITLE)) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.TITLE);
  }
  if (
    driverA.person['primaryOccupationIndustryCode'] !== driverB.person['primaryOccupationIndustryCode'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.PRIMARY_OCCUPATION)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.PRIMARY_OCCUPATION);
  }
  if (
    driverA.person['secondaryOccupationIndustryCode'] !== driverB.person['secondaryOccupationIndustryCode'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.SECONDARY_OCCUPATION)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.SECONDARY_OCCUPATION);
  }
  if (
    driverA.licence['licenseDateMonthsCode'] !== driverB.licence['licenseDateMonthsCode'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.LICENCE_TYPE)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.LICENCE_TYPE);
  }

  if (
    driverA.licence['licenseDateYearsCode'] !== driverB.licence['licenseDateYearsCode'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.LICENCE_TYPE)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.LICENCE_TYPE);
  }
  if (
    driverA.licence['licenseRestrictionCode'] !== driverB.licence['licenseRestrictionCode'] &&
    !updatedKeys.includes(LOOKUP_TYPE_PAGE.LICENCE_TYPE)
  ) {
    updatedKeys.push(LOOKUP_TYPE_PAGE.LICENCE_TYPE);
  }
};

const comparePersonObjects = (personA: IPerson, personB: IPerson, updatedKeys: string[]) => {
  Object.keys(personA)
    .filter((key) => {
      return Object.values(LOOKUP_TYPE_PAGE).includes(key as LOOKUP_TYPE_PAGE);
    })
    .forEach((key) => {
      if (personA[key as keyof IPerson] !== personB[key as keyof IPerson]) {
        updatedKeys.push(key);
      }
    });
};

const compareLicenceObjects = (licenceA: ILicence, licenceB: ILicence, updatedKeys: string[]) => {
  Object.keys(licenceA)
    .filter((key) => {
      return Object.values(LOOKUP_TYPE_PAGE).includes(key as LOOKUP_TYPE_PAGE);
    })
    .forEach((key) => {
      if (licenceA[key as keyof ILicence] !== licenceB[key as keyof ILicence]) {
        updatedKeys.push(key);
      }
    });
};

export const compareDriverObjects = (driverA: IDriver, driverB: IDriver): string[] => {
  const updatedKeys: string[] = [];

  Object.keys(driverA)
    .filter((key) => {
      return Object.values(LOOKUP_TYPE_PAGE).includes(key as LOOKUP_TYPE_PAGE);
    })
    .forEach((key) => {
      if (driverA[key as keyof IDriver] !== driverB[key as keyof IDriver]) {
        updatedKeys.push(key);
      }
    });

  /* because driver is a nested object, first, we compare the person objects as
  most of the attributes are sitting under "person" */

  comparePersonObjects(driverA.person, driverB.person, updatedKeys);

  /* we then continue with comparing the licence object */

  compareLicenceObjects(driverA.licence, driverB.licence, updatedKeys);

  /* some of the screens can contain multiple changes, so this is why we need to check them
  one by one and if there is an update, we need to push them */

  pushAdditionalDriverChanges(driverA, driverB, updatedKeys);

  return updatedKeys;
};
