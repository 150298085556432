import { Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeSecurityFeatures } from '../../../../../../../../component/common/changeSecurityFeatures';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .choice-container': {
      display: 'grid',
      gap: spacing(1),
      width: 'fit-content',
    },
    '& .description': {
      marginBottom: spacing(2),
    },
    '& .error-message': {
      color: palette.error.dark,
    },

    display: 'grid',
    gap: spacing(3),
  },
}));

export const SecurityFeatures: React.FC = observer(function SecurityFeatures() {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { setVehicle, vehicle },
    },
    interfaceStore: { getStepAutoComplete, setStepAutoComplete },
  } = useStores();
  const {
    nextStep,
    setTitle,
    setStep,
    stepsData: { journeyType, reviewStep },
    editMode,
  } = useStepperContext();

  const [trackingDeviceFitted, setTrackingDeviceFitted] = useState<boolean | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityDeviceDeclared, setSecurityDeviceDeclared] = useState('');
  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const classes = useStyles();
  const securityFeaturesTag1 = 'securityFeatures1';
  const securityFeaturesTag2 = 'securityFeatures2';

  useEffect(() => {
    setTitle(
      journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
        ? t('MTAJourney.SecurityFeatures.title')
        : t('MTAJourney.SecurityFeatures.updatedSecurityFeatures'),
    );

    if (getStepAutoComplete(securityFeaturesTag1) !== undefined) {
      setSecurityDeviceDeclared(getStepAutoComplete(securityFeaturesTag1) ?? '');
    } else {
      setSecurityDeviceDeclared('');
    }
    if (getStepAutoComplete(securityFeaturesTag2) !== undefined) {
      setTrackingDeviceFitted(getStepAutoComplete(securityFeaturesTag2) !== 'false');
    } else {
      setTrackingDeviceFitted(null);
    }
  }, []);

  const isValid = (): boolean => {
    return trackingDeviceFitted !== null && securityDeviceDeclared !== '';
  };
  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      setVehicle({ ...vehicle, securityDeviceDeclared, trackingDeviceFitted });
      setStepAutoComplete(securityFeaturesTag1, securityDeviceDeclared);
      setStepAutoComplete(securityFeaturesTag2, String(trackingDeviceFitted));
    }
  };
  const handleSave = () => {
    handlePendedMta();
    isValid() && setStep(reviewStep);
  };
  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.VEHICLE_SECURITY],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.VEHICLE_SECURITY] !== undefined) {
      setOptions(lookupsByName[LOOKUP.VEHICLE_SECURITY]);
    }
  }, [isLookupsFetched, lookupsByName]);

  return (
    <Grid container item xs direction="column" className={classes.root}>
      <ChangeSecurityFeatures
        securityDeviceDeclared={securityDeviceDeclared}
        isSubmitting={isSubmitting}
        options={options}
        trackingDeviceFitted={trackingDeviceFitted}
        setSecurityDeviceDeclared={setSecurityDeviceDeclared}
        setTrackingDeviceFitted={setTrackingDeviceFitted}
      />
      {editMode ? (
        <EditFooter
          id="securityFeatures"
          validPage={isValid()}
          handleSave={handleSave}
          handleBack={() => setStep(reviewStep)}
        />
      ) : (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_SECURITY_FEATURES}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_SECURITY_FEATURES}
          primaryButton={{
            blockContinue: !isValid(),
            buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_SECURITY_FEATURES,
            handleContinue,
          }}
        />
      )}
    </Grid>
  );
});
