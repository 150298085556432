import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationPaymentBlock } from '../../../../../../component/common/confirmationPaymentBlock';
import { COMPONENT_ID, JOURNEY } from '../../../../../../service/analytics';
import { DATE_TYPES, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';

interface ConfirmationDetails {
  button: string;
  description: string;
  emailConfirmation: string;
  subtitle: string;
  title: string;
  url: ROUTE;
}

export const PaymentConfirmation: React.FC = observer(function PaymentConfirmation() {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const [paymentInfo, setPaymentInfo] = useState<ConfirmationDetails>({
    button: t('confirmationPage.buttons.policy'),
    description: '',
    emailConfirmation: t('confirmationPage.emailConfirmation'),
    subtitle: '',
    title: '',
    url: ROUTE.POLICY_DETAILS,
  });

  const {
    mtaStore: {
      quote: { additionalPremium, administrationFee },
      pendedMTA: { endorsementDate },
    },
    dashboardStore: {
      fetchPolicy,
      insurance: {
        isHome,
        auto: {
          policySelected: { brand, paymentPlan, policyNumber },
        },
      },
    },
    paymentStore: { updatePaymentComplete },
  } = useStores();

  const isDDCustomer = paymentPlan !== 'YearlyCardPayment';
  const totalAmountAnnual = additionalPremium + administrationFee;

  const isToday = endorsementDate?.toISODate() === DateTime.local().toISODate();

  useEffect(() => {
    eventTrack('Change Policy Completed', {
      brand,
      journey: JOURNEY.SERVICE,
      policyNumber,
      product: isHome ? 'Home' : 'Motor',
      productType: isHome ? 'Core' : 'Flex',
    });
    fetchPolicy(policyNumber);
  }, []);

  useEffect(() => {
    updatePaymentComplete(false);
    getPaymentDetails();
  }, [totalAmountAnnual]);

  /* istanbul ignore next */
  const getPaymentDetails = () => {
    switch (true) {
      /* annual customers - additional payment - current date */
      case totalAmountAnnual > 0 && !isDDCustomer && isToday:
        setPaymentInfo({
          ...paymentInfo,
          subtitle: t('confirmationPage.payment.subtitle'),
          title: t('core:price.pound', { value: totalAmountAnnual }),
        });
        break;

      /* annual customers - additional payment - future date */
      case totalAmountAnnual > 0 && !isDDCustomer && !isToday:
        setPaymentInfo({
          ...paymentInfo,
          button: t('confirmationPage.buttons.docs'),
          emailConfirmation: t('confirmationPage.noCharges.futureDate.emailConfirmation', {
            value: formatDate(endorsementDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          }),
          subtitle: t('confirmationPage.payment.subtitle'),
          title: t('core:price.pound', { value: totalAmountAnnual }),
          url: ROUTE.DOCUMENTS,
        });
        break;

      /* annual customers - refund payment - current date */
      case totalAmountAnnual < 0 && !isDDCustomer && isToday:
        setPaymentInfo({
          ...paymentInfo,
          description: t('confirmationPage.refund.description'),
          subtitle: t('confirmationPage.refund.subtitle'),
          title: t('core:price.pound', { value: Math.abs(totalAmountAnnual) }),
        });
        break;

      /* annual customers - refund payment - future date */
      case totalAmountAnnual < 0 && !isDDCustomer && !isToday:
        setPaymentInfo({
          ...paymentInfo,
          button: t('confirmationPage.buttons.docs'),
          description: t('confirmationPage.refund.description'),
          emailConfirmation: t('confirmationPage.noCharges.futureDate.emailConfirmation', {
            value: formatDate(endorsementDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          }),
          subtitle: t('confirmationPage.refund.subtitle'),
          title: t('core:price.pound', { value: Math.abs(totalAmountAnnual) }),
          url: ROUTE.DOCUMENTS,
        });
        break;

      /* annual customers - no charges - current date */
      case totalAmountAnnual === 0 && isToday && !isDDCustomer:
        setPaymentInfo({
          ...paymentInfo,
          description: t('confirmationPage.noCharges.description'),
          title: t('confirmationPage.noCharges.currentDate.title'),
        });
        break;

      /* annual customers - no charges - future date */

      case totalAmountAnnual === 0 && !isToday && !isDDCustomer:
        setPaymentInfo({
          ...paymentInfo,
          button: t('confirmationPage.buttons.docs'),
          description: t('confirmationPage.noCharges.description'),
          emailConfirmation: t('confirmationPage.noCharges.futureDate.emailConfirmation', {
            value: formatDate(endorsementDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          }),
          title: t('confirmationPage.noCharges.futureDate.title'),
          url: ROUTE.DOCUMENTS,
        });
        break;

      /* DD customers - no charges - current date */

      case totalAmountAnnual === 0 && isDDCustomer && isToday:
        setPaymentInfo({
          ...paymentInfo,
          subtitle: t('confirmationPage.noChargesDD.subtitle'),
          title: t('confirmationPage.noCharges.currentDate.title'),
        });
        break;

      /* DD customers - no charges - future date */

      case totalAmountAnnual === 0 && isDDCustomer && !isToday:
        setPaymentInfo({
          ...paymentInfo,
          button: t('confirmationPage.buttons.docs'),
          emailConfirmation: t('confirmationPage.noCharges.futureDate.emailConfirmation', {
            value: formatDate(endorsementDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          }),
          subtitle: t('confirmationPage.noChargesDD.subtitle'),
          title: t('confirmationPage.noCharges.futureDate.title'),
          url: ROUTE.DOCUMENTS,
        });
        break;

      /* DD customers - additional payment or refund - current date */
      case totalAmountAnnual !== 0 && isDDCustomer && isToday:
        setPaymentInfo({
          ...paymentInfo,
          title: t('confirmationPage.paymentDD.title'),
        });
        break;

      /* DD customers - additional payment or refund - future date */

      case totalAmountAnnual !== 0 && isDDCustomer && !isToday:
        setPaymentInfo({
          ...paymentInfo,
          button: t('confirmationPage.buttons.docs'),
          emailConfirmation: t('confirmationPage.noCharges.futureDate.emailConfirmation', {
            value: formatDate(endorsementDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          }),
          title: t('confirmationPage.paymentDD.title'),
          url: ROUTE.DOCUMENTS,
        });
        break;
    }
  };

  const { button, description, emailConfirmation, subtitle, title, url } = paymentInfo;

  return (
    <ConfirmationPaymentBlock
      button={button}
      buttonId={COMPONENT_ID.MTA_POLICY_BUTTON_PAYMENT_CONFIRMATION}
      description={description}
      emailConfirmation={emailConfirmation}
      subtitle={subtitle}
      title={title}
      url={url}
      hasMultiplePoliciesBanner={true}
    />
  );
});
