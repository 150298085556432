import { Grid, Typography, makeStyles, useTheme } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../component/common/blueBanner';
import { DownloadInsuranceDocument } from '../../../../../component/common/button/downloadInsuranceDocument';
import { MakeAClaimBtn } from '../../../../../component/common/button/makeAClaimBtn';
import CommonAccordion, { CommonAccordionProps } from '../../../../../component/common/commonAccordion';
import { ContainerMultipleLines, PolicyData } from '../../../../../component/common/containerMultipleLines';
import { FancyTable, FancyTableCell } from '../../../../../component/common/fancyTable';
import { NavigationCardSmall } from '../../../../../component/common/navigationCardSmall';
import { DOCUMENT_TYPE_CODE, PRODUCT_TYPE, ROUTE, THEME_NAME } from '../../../../../service/constant';
import { ICoverage, IHomePolicy, ISubCoverage } from '../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../service/state/store';
import { HomeRenewalNotification } from '../../policy/renewals/components/homeRenewalNotification';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    '& .MuiGrid-grid-xs-12:after': {
      height: 0,
    },
    marginTop: theme.spacing(4),
  },
  accordionHeading: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  accordionItems: {
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  bulletList: {
    '& li': {
      '&:before': {
        background: theme.palette.secondary.main,
        borderRadius: '50%',
        content: `''`,
        display: 'block',
        height: '10px',
        left: theme.spacing(1),
        position: 'absolute',
        top: theme.spacing(1),
        width: '10px',
      },
      display: 'block',
      paddingLeft: theme.spacing(4),
      position: 'relative',
    },
    listStyle: 'none',
    padding: 0,
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  claimButton: {
    '& button, & a': {
      display: 'block',
      width: '100%',
    },
    margin: 0,
    width: '100%',
  },
  downloadButton: {
    '& button': {
      '& span': {
        textAlign: 'left',
        whiteSpace: 'normal',
      },
      marginLeft: `-${theme.spacing(2)}px`,
    },
    marginBottom: theme.spacing(2),
  },
  expireNoticeContainer: {
    marginTop: theme.spacing(2),
  },
  heading: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(4, 0, 2, 0),
  },
  makeAClaim: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  root: {},
}));

export interface Coverage {
  icon: string;
  name: string;
}

export interface AccordionItem {
  accordion: (value: number, accidentalDamage: number, subCoverages: ISubCoverage[]) => CommonAccordionProps;
  codes: string[];
}

export const Excesses: React.FC<{ policySelected: IHomePolicy }> = (props) => {
  const { t } = useTranslation('myAccount', {});
  const excessTypes: Record<string, string> = t('homeCoverages.excesses', { returnObjects: true });
  let additionalExcessTable: FancyTableCell[] = [];
  const tables: JSX.Element[] = [];

  const reorderTableRows = (rows: FancyTableCell[]) => {
    const sortOrder = [
      t('homeCoverages.excesses.escapeOfWater'),
      t('homeCoverages.excesses.subsidenceOrHeave'),
      t('homeCoverages.excesses.otherClaims'),
      t('homeCoverages.excesses.homeEmergency'),
      t('homeCoverages.excesses.familyLegalProtection'),
      t('homeCoverages.excesses.pest'),
    ];
    return rows.sort(function (a, b) {
      return sortOrder.indexOf(a.tableCells[0]) - sortOrder.indexOf(b.tableCells[0]);
    });
  };

  props.policySelected.coverages.map((item, index) => {
    const rows = item.excesses.map((excess) => {
      return {
        tableCells: [
          excessTypes[excess.code],
          excess.voluntaryExcess > 0
            ? t('core:price.poundNoDecimal', { value: excess.voluntaryExcess })
            : t('homeCoverages.excessText.none'),
          excess.compulsoryExcess > 0
            ? t('core:price.poundNoDecimal', { value: excess.compulsoryExcess })
            : t('homeCoverages.excessText.none'),
          excess.totalExcess > 0
            ? t('core:price.poundNoDecimal', { value: excess.totalExcess })
            : t('homeCoverages.excessText.none'),
        ],
      };
    });

    if (item.excesses.length > 0) {
      if (item.code === 'buildings' || item.code === 'contents') {
        const orderedRows = reorderTableRows(rows);
        tables.push(
          <FancyTable
            testId={`table_${item.code}`}
            key={item.code}
            tableHeadings={[
              item.code === 'buildings'
                ? t('homeCoverages.excessTypes.buildings')
                : t('homeCoverages.excessTypes.contents'),
              t('homeCoverages.excessTypes.voluntaryExcess'),
              t('homeCoverages.excessTypes.compulsoryExcess'),
              t('homeCoverages.excessTypes.totalExcess'),
            ]}
            tableRows={orderedRows}
          />,
        );
      } else {
        additionalExcessTable = [...additionalExcessTable, ...rows];
      }
    }

    if (additionalExcessTable.length > 0 && index === props.policySelected.coverages.length - 1) {
      tables.push(
        <FancyTable
          testId={`table_additional`}
          key={'optionalExtras'}
          tableHeadings={[
            t('homeCoverages.excessTypes.optionalExtras'),
            t('homeCoverages.excessTypes.voluntaryExcess'),
            t('homeCoverages.excessTypes.compulsoryExcess'),
            t('homeCoverages.excessTypes.totalExcess'),
          ]}
          tableRows={additionalExcessTable}
        />,
      );
      additionalExcessTable = reorderTableRows(additionalExcessTable);
    }
    return tables;
  });
  return <Grid key={`table`}>{tables}</Grid>;
};

export const isUnlimited = (value: number): boolean => value === 9999999;

export const getHeaderInfo = (
  value: number,
  valueFormatted: string,
  accidentalDamage: number,
  unlimited: string,
  upTo: string,
  includingAccidental: string,
): string => {
  const accidentalString = accidentalDamage ? includingAccidental : '';
  return isUnlimited(value) ? `${unlimited} ${accidentalString}` : `${upTo} ${valueFormatted} ${accidentalString}`;
};

export const getPestClaimsLink = (themeName: string, swLink: string, esLink: string): string => {
  return themeName === THEME_NAME.SHEILASWHEELS ? swLink : esLink;
};
export const WhatsIncluded: React.FC = observer(function WhatsIncluded() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation('myAccount', {});
  const [open, setOpen] = useState<number | null>(null);

  const {
    dashboardStore: {
      fetchPolicyDocuments,
      insurance: {
        home: { policySelected },
      },
    },
    interfaceStore: { isMobile },
  } = useStores();
  const { documents, policyNumber, effectiveDate, productType } = policySelected;

  useEffect(() => {
    if (!documents.length) {
      fetchPolicyDocuments(policyNumber, effectiveDate);
    }
  }, [policySelected]);

  const [policyData, setPolicyData] = useState<PolicyData>({ title: t('howMuchYouAreCovered.title') });

  const coverageTypes: Record<string, Coverage> = t('homeCoverages.coverageCodes', { returnObjects: true });

  const buildingsAndContentsExist = () => {
    const reducedArray = policyData.items?.reduce((a: string[], item) => [...a, item.code], []);
    if (reducedArray) {
      return reducedArray.includes('buildings') && reducedArray.includes('contents');
    } else {
      return false;
    }
  };

  const getItemPerProdType = (code: ICoverage['code']) => {
    if (code === 'specifiedItems' && productType === PRODUCT_TYPE.FLEX) {
      return t('homeCoverages.specifiedValuables');
    } else if (code === 'personalPossession' && productType === PRODUCT_TYPE.FLEX) {
      return t('homeCoverages.personalBelongings');
    } else {
      return coverageTypes[code].name;
    }
  };

  useEffect(() => {
    const policyItems = policySelected.coverages.flatMap((item) => {
      const coverageLine = {
        amount: isUnlimited(item.limit) ? -1 : item.limit,
        code: item.code,
        details: isUnlimited(item.limit) ? t('homeCoverages.unlimited') : t('excesses.upTo'),
        item: getItemPerProdType(item.code),
      };
      if (item.accidentalDamage) {
        return [
          coverageLine,
          {
            amount: -1,
            code: item.code,
            details: t('homeCoverages.included'),
            item: `${t('homeCoverages.accidental')} (${coverageTypes[item.code].name})`,
          },
        ];
      } else {
        return coverageLine;
      }
    });
    setPolicyData({ ...policyData, items: policyItems });
  }, []);

  const handleClick = () => {
    history.push(ROUTE.HOW_TO_MAKE_A_CLAIM_HOME);
  };

  const ClaimButtonWrapper: React.FC = (props: React.PropsWithChildren<{ children?: React.ReactNode }>) => {
    return (
      <Grid item xs={12} className={classes.claimButton}>
        {props.children}
      </Grid>
    );
  };

  const specifiedValuablesSubcoverageColumn = (subCoverages: ISubCoverage[]) => {
    return subCoverages.map((subcoverage) => {
      const list: string[] = [];
      let title = t('homeCoverages.accordions.specifiedItems.coverAtHome');
      if (!subcoverage.coverAwayFromHome) {
        list.push(`${subcoverage.description}  <0>£${subcoverage.limit}</0>`);
      } else {
        list.push(`${subcoverage.description}  <0>£${subcoverage.limit}</0>`);
        title = t('homeCoverages.accordions.specifiedItems.coverAwayFromHome');
      }
      return { list, title };
    });
  };

  const getHeaderTitle = (coverageType: string) => {
    switch (true) {
      case coverageType === 'personalPossession' && productType === PRODUCT_TYPE.FLEX:
        return t('homeCoverages.personalBelongings');
      case coverageType === 'specifiedItems' && productType === PRODUCT_TYPE.FLEX:
        return t('homeCoverages.specifiedValuablesSimple');
      default:
        return coverageTypes[coverageType].name;
    }
  };

  const accordions: AccordionItem[] = [
    {
      accordion: (value: number, accidentalDamage: number) => ({
        columns:
          productType === PRODUCT_TYPE.CORE
            ? [
                {
                  listWithOptions: accidentalDamage
                    ? t('homeCoverages.accordions.buildings.coverIncludes.itemsAccidentalCore', { returnObjects: true })
                    : t('homeCoverages.accordions.buildings.coverIncludes.itemsCore', { returnObjects: true }),
                },
                {
                  icon: 'times',
                  list: accidentalDamage
                    ? t('homeCoverages.accordions.buildings.coverNotIncludes.itemsAccidentalCore', {
                        returnObjects: true,
                      })
                    : t('homeCoverages.accordions.buildings.coverNotIncludes.itemsCore', { returnObjects: true }),
                  title: t('homeCoverages.accordions.buildings.coverNotIncludes.title'),
                },
              ]
            : [
                {
                  listWithOptions: accidentalDamage
                    ? t('homeCoverages.accordions.buildings.coverIncludes.itemsAccidentalFlex', { returnObjects: true })
                    : t('homeCoverages.accordions.buildings.coverIncludes.items', { returnObjects: true }),
                },
                {
                  icon: 'times',
                  list: accidentalDamage
                    ? t('homeCoverages.accordions.buildings.coverNotIncludes.itemsAccidentalFlex', {
                        returnObjects: true,
                      })
                    : t('homeCoverages.accordions.buildings.coverNotIncludes.itemsFlex', { returnObjects: true }),
                  title: t('homeCoverages.accordions.buildings.coverNotIncludes.title'),
                },
              ],
        downloadLink: (
          <DownloadInsuranceDocument
            documentTypeCode={
              buildingsAndContentsExist() ? DOCUMENT_TYPE_CODE.HOME_IPID_BCC : DOCUMENT_TYPE_CODE.HOME_IPID_BLC
            }
            buttonName={t('homeCoverages.downloadTerms')}
          />
        ),
        footerComponent: (
          <ClaimButtonWrapper>
            <MakeAClaimBtn />
          </ClaimButtonWrapper>
        ),
        headerIcon: 'buildings',
        headerIconType: 'solid',
        headerInfo: getHeaderInfo(
          value,
          isUnlimited(value) ? t('homeCoverages.unlimited') : t('core:price.poundNoDecimal', { value: value }),
          accidentalDamage,
          t('homeCoverages.unlimited'),
          t('excesses.upTo'),
          t('homeCoverages.includingAccidental'),
        ),
        headerTitle: coverageTypes['buildings'].name,
        summary: t('homeCoverages.accordions.buildings.summary'),
      }),
      codes: ['buildings'],
    },
    {
      accordion: (value: number, accidentalDamage: number) => ({
        columns:
          productType === PRODUCT_TYPE.CORE
            ? [
                {
                  listWithOptions: accidentalDamage
                    ? t('homeCoverages.accordions.contents.coverIncludes.itemsAccidentalCore', { returnObjects: true })
                    : t('homeCoverages.accordions.contents.coverIncludes.itemsCore', { returnObjects: true }),
                },
                {
                  icon: 'times',
                  list: accidentalDamage
                    ? t('homeCoverages.accordions.contents.coverNotIncludes.itemsAccidentalCore', {
                        returnObjects: true,
                      })
                    : t('homeCoverages.accordions.contents.coverNotIncludes.itemsCore', { returnObjects: true }),
                  title: t('homeCoverages.accordions.contents.coverNotIncludes.title'),
                },
              ]
            : [
                {
                  listWithOptions: accidentalDamage
                    ? t('homeCoverages.accordions.contents.coverIncludes.itemsAccidentalFlex', { returnObjects: true })
                    : t('homeCoverages.accordions.contents.coverIncludes.itemsFlex', { returnObjects: true }),
                },
                {
                  icon: 'times',
                  list: accidentalDamage
                    ? t('homeCoverages.accordions.contents.coverNotIncludes.itemsAccidentalFlex', {
                        returnObjects: true,
                      })
                    : t('homeCoverages.accordions.contents.coverNotIncludes.itemsFlex', { returnObjects: true }),
                  title: t('homeCoverages.accordions.contents.coverNotIncludes.title'),
                },
              ],
        downloadLink: (
          <DownloadInsuranceDocument
            documentTypeCode={
              buildingsAndContentsExist() ? DOCUMENT_TYPE_CODE.HOME_IPID_BCC : DOCUMENT_TYPE_CODE.HOME_IPID_CC
            }
            buttonName={t('homeCoverages.downloadTerms')}
          />
        ),
        footerComponent: (
          <ClaimButtonWrapper>
            <MakeAClaimBtn />
          </ClaimButtonWrapper>
        ),
        headerIcon: 'contents',
        headerIconType: 'solid',
        headerInfo: getHeaderInfo(
          value,
          isUnlimited(value) ? t('homeCoverages.unlimited') : t('core:price.poundNoDecimal', { value: value }),
          accidentalDamage,
          t('homeCoverages.unlimited'),
          t('excesses.upTo'),
          t('homeCoverages.includingAccidental'),
        ),
        headerTitle: coverageTypes['contents'].name,
        summary: t('homeCoverages.accordions.contents.summary'),
      }),
      codes: ['contents'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            listWithOptions: t('homeCoverages.accordions.homeEmergency.coverIncludes.items', { returnObjects: true }),
            title: t('homeCoverages.accordions.homeEmergency.coverIncludes.title'),
          },
          {
            icon: 'times',
            list: t('homeCoverages.accordions.homeEmergency.coverNotIncludes.items', { returnObjects: true }),
            title: t('homeCoverages.accordions.homeEmergency.coverNotIncludes.title'),
          },
        ],
        downloadLink: (
          <DownloadInsuranceDocument
            documentTypeCode={DOCUMENT_TYPE_CODE.HOME_IPID_HE}
            buttonName={t('homeCoverages.downloadTerms')}
          />
        ),
        footerComponent: (
          <ClaimButtonWrapper>
            <MakeAClaimBtn
              text={t('homeCoverages.accordions.homeEmergency.claimButton.title')}
              url={isMobile ? t('homeCoverages.accordions.homeEmergency.claimButton.link') : '#'}
              isExternalURL={isMobile}
            />
          </ClaimButtonWrapper>
        ),
        headerIcon: 'home-emergency',
        headerIconType: 'solid',
        headerInfo: `${t('excesses.upTo')} ${t('core:price.poundNoDecimal', { value: value })}`,
        headerTitle: coverageTypes['homeEmergency'].name,
        summary: t('homeCoverages.accordions.homeEmergency.summary'),
      }),
      codes: ['homeEmergency'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            listWithOptions: t('homeCoverages.accordions.familyLegalProtection.coverIncludes.items', {
              returnObjects: true,
            }),
            title: t('homeCoverages.accordions.familyLegalProtection.coverIncludes.title'),
          },
          {
            icon: 'times',
            list: t('homeCoverages.accordions.familyLegalProtection.coverNotIncludes.items', { returnObjects: true }),
            title: t('homeCoverages.accordions.familyLegalProtection.coverNotIncludes.title'),
          },
        ],
        downloadLink: (
          <DownloadInsuranceDocument
            documentTypeCode={DOCUMENT_TYPE_CODE.HOME_IPID_FLP}
            buttonName={t('homeCoverages.downloadTerms')}
          />
        ),
        footerComponent: (
          <ClaimButtonWrapper>
            <MakeAClaimBtn
              text={t('homeCoverages.accordions.familyLegalProtection.claimButton.title')}
              url={t('homeCoverages.accordions.familyLegalProtection.claimButton.link')}
              isExternalURL={true}
            />
          </ClaimButtonWrapper>
        ),
        headerIcon: 'legal',
        headerIconType: 'solid',
        headerInfo: `${t('excesses.upTo')} ${t('core:price.poundNoDecimal', { value: value })}`,
        headerTitle: coverageTypes['familyLegalProtection'].name,
        summary: t('homeCoverages.accordions.familyLegalProtection.summary'),
      }),
      codes: ['familyLegalProtection'],
    },
    {
      accordion: (value: number) => ({
        headerIcon: 'posessions',
        headerIconType: 'solid',
        headerInfo: `${t('excesses.upTo')} ${t('core:price.poundNoDecimal', { value: value })}`,
        headerTitle: getHeaderTitle('personalPossession'),
        summary: t(
          `homeCoverages.accordions.${
            productType === PRODUCT_TYPE.CORE ? 'personalPossession' : 'personalBelongings'
          }.summary`,
        ),
      }),
      codes: ['personalPossession'],
    },
    {
      accordion: (value: number, accidentalDamage: number, subCoverages: ISubCoverage[]) => ({
        columns:
          productType === PRODUCT_TYPE.CORE
            ? [
                {
                  list: subCoverages.reduce(
                    (a: string[], currentValue) => [...a, `${currentValue.description}  £${currentValue.limit}`],
                    [],
                  ),
                  title: t('homeCoverages.accordions.specifiedItems.coverAwayFromHome'),
                },
              ]
            : specifiedValuablesSubcoverageColumn(subCoverages),
        headerIcon: 'specified-items',
        headerIconType: 'solid',
        headerInfo: `${t('excesses.upTo')} ${t('core:price.poundNoDecimal', { value: value })}`,
        headerTitle: getHeaderTitle('specifiedItems'),
        summary:
          productType === PRODUCT_TYPE.CORE
            ? t('homeCoverages.accordions.specifiedItems.summaryCore')
            : t('homeCoverages.accordions.specifiedItems.summaryFlex'),
      }),
      codes: ['specifiedItems'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            listWithOptions: t('homeCoverages.accordions.pest.coverIncludes.items', { returnObjects: true }),
            title: t('homeCoverages.accordions.pest.coverIncludes.title'),
          },
          {
            icon: 'times',
            list: t('homeCoverages.accordions.pest.coverNotIncludes.items', { returnObjects: true }),
            title: t('homeCoverages.accordions.pest.coverNotIncludes.title'),
          },
        ],
        downloadLink: (
          <DownloadInsuranceDocument
            documentTypeCode={DOCUMENT_TYPE_CODE.HOME_IPID_PC}
            buttonName={t('homeCoverages.downloadTerms')}
          />
        ),
        footerComponent: (
          <ClaimButtonWrapper>
            <MakeAClaimBtn
              text={t('homeCoverages.accordions.pest.claimButton.title')}
              url={getPestClaimsLink(
                theme.themeName,
                t('homeCoverages.accordions.pest.claimButton.linkSw'),
                t('homeCoverages.accordions.pest.claimButton.linkEs'),
              )}
              isExternalURL={true}
            />
          </ClaimButtonWrapper>
        ),
        headerIcon: 'pest',
        headerIconType: 'solid',
        headerInfo: `${t('excesses.upTo')} ${t('core:price.poundNoDecimal', { value: value })}`,
        headerTitle: coverageTypes['pest'].name,
        summary: t('homeCoverages.accordions.pest.summary'),
      }),
      codes: ['pest'],
    },
  ];

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('routeConfig.whatsIncluded')} route={ROUTE.POLICY_DETAILS} />

      {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        policySelected.futureRenewalEffectiveDate === null && (
          <Grid item xs={12} className={classes.expireNoticeContainer}>
            <HomeRenewalNotification policy={policySelected} />
          </Grid>
        )
      }
      <Typography variant="h2" className={classes.heading}>
        {t('homeCoverages.yourCover')}
      </Typography>

      <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
        <ContainerMultipleLines dataPolicy={policyData} />

        <Typography className={classes.accordionHeading}>{t('homeCoverages.ifYouClaim')}</Typography>

        <Grid container direction="column" wrap="nowrap" className={classes.accordionItems}>
          {policySelected.coverages.map((item, index) => {
            const accordion = accordions.find((x) => x.codes.includes(item.code))?.accordion;

            return accordion ? (
              <CommonAccordion
                key={Symbol(index).toString()}
                onChange={() => setOpen(open === index ? null : index)}
                expanded={open === index}
                {...accordion(item.limit, item.accidentalDamage, item.subCoverages)}
                data-testid="accordion"
              />
            ) : null;
          })}
        </Grid>
      </Grid>

      <Typography variant="h2" className={classes.heading}>
        {t('homeCoverages.policyBooklet')}
      </Typography>
      <Typography>{t('homeCoverages.policyBookletSubtext')}</Typography>
      <DownloadInsuranceDocument
        documentTypeCode={
          productType === PRODUCT_TYPE.CORE ? DOCUMENT_TYPE_CODE.HOME_CORE_BKLT : DOCUMENT_TYPE_CODE.HOME_FLEX_BKLT
        }
        buttonName={t('homeCoverages.downloadTerms')}
        isButton
      />

      <Typography variant="h2" className={classes.heading}>
        {t('homeCoverages.excessText.title')}
      </Typography>

      <Typography>{t('homeCoverages.excessText.subTitle')}</Typography>
      <ul className={classes.bulletList}>
        <li>{t('homeCoverages.excessText.bullet1')}</li>
        <li>{t('homeCoverages.excessText.bullet2')}</li>
      </ul>
      {buildingsAndContentsExist() && <Typography variant="body2">{t('homeCoverages.excessText.footer')}</Typography>}

      <Excesses policySelected={policySelected} />

      <Typography variant="h2" className={classes.makeAClaim}>
        {t('appbar.makeClaim')}
      </Typography>

      <Grid item xs={12} md={6} onClick={handleClick} data-testid="claims-link">
        <NavigationCardSmall
          color="secondary"
          title={t('needHelp.claim')}
          icon="shield-check-line"
          iconType="solid"
          showDescription={!isMobile}
        />
      </Grid>
    </Grid>
  );
});
