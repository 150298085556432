import common from './en/common.json';
import core from './en/core.json';
import myAccount from './en/myAccount.json';

export const locales = {
  en: {
    common,
    core,
    myAccount,
  },
};

// DO NOT DELETE
// this placeholder file is used by the i18next locales loader during language file generation
const localePlaceholder = {
  en: {
    core: {
      date: {
        long: '{{date, long}}',
        longTime: '{{date, longTime}}',
        mini: '{{date, mini}}',
        'miniYear-line': '{{date, miniYear-line}}',
        monthYear: '{{date, monthYear}}',
        short: '{{date, short}}',
        shortTime: '{{date, shortTime}}',
      },
      dateFormat: {
        long: 'dd LLLL yyyy',
        longTime: 'dd LLLL yyyy HH:mm:ss',
        mini: 'dd LLL yy',
        'miniYear-line': 'dd-LL-yy',
        monthYear: 'LLLL yyyy',
        short: 'dd/LL/yyyy',
        shortTime: 'dd LLL yyyy HH:mm',
      },
    },
  },
};

export default localePlaceholder;
