import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { useStepperContext } from '../../common/utils/stepperContext';
import { EditFooter } from '../components/editFooter';

import { EditPageProps } from './reviewAll';

export const EditRemoveDriver: React.FC<EditPageProps> = observer(function EditRemoveDriver({ handleBack }) {
  const { setTitle } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    mtaStore: {
      pendedMTA: { setDrivers, setRemovedDrivers, removedDrivers, drivers },
    },
    dashboardStore: {
      insurance: {
        auto: {
          mainDriver,
          policySelected: { drivers: dashboardStoreDrivers },
        },
      },
    },
  } = useStores();

  const listOfRemovedD: string[] = toJS(removedDrivers);
  const [deletedDrivers, setDeletedDrivers] = useState(listOfRemovedD);

  useEffect(() => {
    setTitle(t('MTAJourney.removeDriver.title'));
  }, []);

  const handleContinue = () => {
    setIsSubmitting(true);
    setRemovedDrivers(deletedDrivers);

    const pendingDrivers: Map<string, IDriver> = new Map();
    toJS(dashboardStoreDrivers).forEach((driver) => pendingDrivers.set(driver.uuid, driver));
    toJS(drivers).forEach((driver) => pendingDrivers.set(driver.uuid, driver));

    const updatedDrivers: IDriver[] = Array.from(pendingDrivers.values()).filter(
      (d: IDriver) => !deletedDrivers.includes(d.uuid),
    );

    // check if the main driver has been changed
    const findMainDriverInDeletedDrivers = updatedDrivers.find((d) => d.mainDriver);

    // if so, add the mainDriver flag to it
    // otherwise add the flag to the main driver from the policy
    const mainD = findMainDriverInDeletedDrivers ? findMainDriverInDeletedDrivers : mainDriver;

    setDrivers(
      updatedDrivers.map((item) => {
        if (item.uuid === mainD.uuid) {
          return { ...item, mainDriver: true };
        }
        return { ...item, mainDriver: false };
      }),
    );
    handleBack();
  };

  const handleBackClick = () => {
    handleBack();
  };

  const handleCheckboxChange = (idx: string) => {
    if (deletedDrivers.includes(idx)) {
      const removeItem = deletedDrivers.filter((itm) => itm !== idx);
      setDeletedDrivers(removeItem);
    } else {
      setDeletedDrivers([...deletedDrivers, idx]);
    }
  };

  return (
    <>
      <Typography variant="body1">{t('MTAJourney.removeDriver.headline')}</Typography>
      <FormGroup>
        {drivers.map(
          (driver, idx) =>
            driver.relationshipToProposerCode !== 'P' && (
              <FormControlLabel
                onChange={() => handleCheckboxChange(driver.uuid)}
                control={<Checkbox checked={deletedDrivers.includes(driver.uuid)} />}
                key={`maplist1-${idx}`}
                label={`${driver.person.firstName} ${driver.person.surname}`}
              />
            ),
        )}
        {dashboardStoreDrivers.map(
          (driver, idx) =>
            driver.relationshipToProposerCode !== 'P' &&
            !drivers.some((d) => d.uuid === driver.uuid) && (
              <FormControlLabel
                onChange={() => handleCheckboxChange(driver.uuid)}
                control={<Checkbox checked={deletedDrivers.includes(driver.uuid)} />}
                key={`maplist2-${idx}`}
                label={`${driver.person.firstName} ${driver.person.surname}`}
              />
            ),
        )}
        {!(deletedDrivers.length > 0) && isSubmitting && (
          <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
        )}
      </FormGroup>

      <EditFooter id="editRemoveDriver" handleSave={handleContinue} handleBack={handleBackClick} />
    </>
  );
});
