import { CommonContainer, Grid, Typography } from '@esure-cloud/react-components';

import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { SVGWrapper } from '../../../../../../../../images/SVGWrapper';
import { track } from '../../../../../../../../lib/optimizely';
import { emails } from '../../../../../../../../service/config';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    '& .common-container': {
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      [breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: spacing(-4, -2, 0, -2),
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      color: palette.secondary.contrastText,
    },
    '& .container': {
      gap: 32,
      maxWidth: '345px',
      textAlign: 'center',
    },
    '& .step-content': {
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        marginTop: spacing(2),
      },
    },
    '& .title': {
      marginBottom: spacing(3),
      marginTop: spacing(5),
    },
    height: '50vh',
  },
}));

export const QuoteIneligible: React.FC = () => {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      hasIovationError,
      chatBot: { startChat },
    },
    renewalChangeStore: { setNextRoute },
  } = useStores();
  const chatBotAttr = useMTAChatBotAttributes('ssMTAVehicleNoQuoteOff');
  const {
    stepsData: { journeyType },
  } = useStepperContext();

  const handleClick = () => {
    track('My_Account_chat_with_us_click');
    eventTrack('Chat With Us Button Clicked', {
      position: `Quote eligibility failed`,
      type: TRACK_TYPE.CHAT,
    });
    startChat({
      initiator: `Quote eligibility failed`,
      ...(chatBotAttr ?? {}),
    });
  };

  useEffect(() => {
    setNextRoute('');
    eventTrack('Get a quote decline error viewed', {
      journeyType,
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  return (
    <Grid className={classes.root}>
      <CommonContainer className="common-container">
        <Typography variant="h3">{t('MTAJourney.yourQuote')}</Typography>
      </CommonContainer>
      <Grid className="step-content">
        <Grid container justifyContent="center" className={classes.root}>
          <Grid container direction="column" justifyContent="center" alignItems="center" className="container">
            <Grid item>
              <SVGWrapper alt="car group" src="technical-error.svg" width={125} />
            </Grid>
            <Typography variant="h2">{t('MTAJourney.quote.failed.title')}</Typography>
            {hasIovationError ? (
              <Typography variant="body1">{`${t('MTAJourney.quote.iovation.message')} ${
                emails.policyValidation
              }.`}</Typography>
            ) : (
              <Typography variant="body1">{t('MTAJourney.quote.failed.message')}</Typography>
            )}
            {!hasIovationError && (
              <Button onClick={handleClick} variant="contained" color="primary">
                {t('buttons.chatWithASpecialist')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
