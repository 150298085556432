import {
  Alert,
  AlertTitle,
  Checkbox,
  Grid,
  RegistrationPlate,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { useStores } from '../../../../../../../../service/state/store';
import { MTAFooter } from '../../common/components/footer';

interface CarRegistrationManualChangeProps {
  setManualRegistrationChange?: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles(({ spacing }) => ({
  alertContainer: {
    alignItems: 'flex-start',
    columnGap: spacing(1),
    display: 'flex',
  },
  checkbox: {
    '& svg': {
      backgroundColor: 'white',
    },
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${spacing(2)}px ${spacing(3)}px`,
    rowGap: spacing(2),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(4),
  },
}));

export const CarRegistrationManualChange: FC<CarRegistrationManualChangeProps> = observer(
  ({ setManualRegistrationChange }) => {
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const classes = useStyles();
    const { t } = useTranslation('myAccount');

    const {
      mtaStore: {
        pendedMTA: { vehicle, setIsCarRegistrationManuallyChanged, setVehicle, vehicleLookUp, vehicleFound },
      },
    } = useStores();

    const {
      bodyTypeCode,
      engineSize,
      fuelTypeCode,
      make,
      manufactureYear,
      model,
      numberOfDoors,
      numberOfSeats,
      transmissionTypeCode,
      vrm,
    } = vehicleLookUp;

    const isValid = useCallback((): boolean => {
      setIsError(!isConfirmed);
      return isConfirmed;
    }, [isConfirmed, setIsError]);

    const handleBack = useCallback((): void => {
      if (setManualRegistrationChange && vehicleFound) {
        setManualRegistrationChange(true);
        setVehicle({
          ...vehicle,
          bodyTypeCode,
          engineSize,
          fuelTypeCode,
          make,
          manufactureYear,
          model,
          numberOfDoors,
          numberOfSeats,
          transmissionTypeCode,
          vrm,
        });
      }
      setIsCarRegistrationManuallyChanged(false);
    }, [setManualRegistrationChange, setIsCarRegistrationManuallyChanged]);

    const alertSeverity = useMemo(() => (isError && !isConfirmed ? 'error' : 'warning'), [isError, isConfirmed]);

    return (
      <Grid>
        <Grid className={classes.wrapper}>
          <BlueTableContainer title={t('MTAJourney.CarRegistration.carRegistrationManualChange.label')}>
            <Grid className={classes.tableContent}>
              <Typography variant="h3">
                {vehicle.make} {vehicle.model}
              </Typography>
              <RegistrationPlate label={vehicle.vrm} active={true} />
            </Grid>
          </BlueTableContainer>
          <Alert
            variant="standard"
            icon={false}
            severity={alertSeverity}
            data-testid={`change-registration-alert-${alertSeverity}`}
          >
            <AlertTitle>{t('MTAJourney.CarRegistration.carRegistrationManualChange.alert.title')}</AlertTitle>
            <Grid className={classes.alertContainer}>
              <Checkbox
                checked={isConfirmed}
                color="primary"
                onChange={() => setIsConfirmed((prevState) => !prevState)}
                className={classes.checkbox}
              />
              <Grid>
                <Typography>{t('MTAJourney.CarRegistration.carRegistrationManualChange.alert.paragraph1')}</Typography>
                <Typography>{t('MTAJourney.CarRegistration.carRegistrationManualChange.alert.paragraph2')}</Typography>
              </Grid>
            </Grid>
          </Alert>
        </Grid>
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_CAR_REGISTRATION_NUMBER_MANUAL_CHANGE}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_CAR_REGISTRATION_NUMBER_MANUAL_CHANGE}
          handleBack={handleBack}
          primaryButton={{
            buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CAR_REGISTRATION_MANUAL_CHANGE,
          }}
          isValid={isValid}
        />
      </Grid>
    );
  },
);
