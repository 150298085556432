import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE } from '../../../../../../../../service/constant';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MainDriver } from '../components/mainDriver';

export const MainDriverRemove: React.FC = observer(() => {
  const {
    mtaStore: {
      pendedMTA: { removedDrivers, drivers },
    },
  } = useStores();
  const { eventTrack } = useSegment();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== ROUTE.MORE_CHANGES) eventTrack('Who will be main driver viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  const mainDriver = drivers.find((item) => item.mainDriver === true)?.uuid;
  const allDrivers = toJS(drivers).filter((d: IDriver) => !removedDrivers.includes(d.uuid));

  return <MainDriver drivers={allDrivers} defaultMainDriver={mainDriver} />;
});
