import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPolicyDocument } from '../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { downloadDocument } from '../utils/documentds';

import { ButtonRenewalDocumentsProps, CommonButton } from './commonButton';

export const RenewalsDocumentsBtn: React.FC<ButtonRenewalDocumentsProps> = observer(function RenewalsDocumentsBtn({
  fileName,
  policy,
  isDeclineButton = false,
  ...rest
}) {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const {
    interfaceStore: { notification, isCaura },
    getDocumentsHeader,
    dashboardStore: {
      insurance: { isHome },
    },
  } = useStores();

  const { policyNumber, renewalDocuments, documents } = policy;

  const handleClick = () => {
    const selectedDocument: IPolicyDocument[] = isDeclineButton
      ? documents.filter((document) => document.fileName === fileName)
      : renewalDocuments.filter((document) => document.fileName === fileName);
    const documentId = selectedDocument[0]?.documentId;
    const headerText = selectedDocument[0]?.fileName;
    downloadDocument({
      documentId: documentId,
      eventTrack: eventTrack,
      header: getDocumentsHeader(),
      headerText,
      isCaura,
      onFail: () => {
        notification.openNotification({
          message: t('downloadFailed.content'),
          severity: 'error',
        });
      },
      optimizelyTrackKey: `My_Account_${headerText}_click`,
      policyNumber: policyNumber,
    });

    eventTrack(!isHome ? `Renewal ${headerText} downloaded` : `Home Rnwl | ${headerText} downloaded`, {
      policyNumber,
      position: `Renewal ${headerText} downloaded`,
      type: TRACK_TYPE.DOWNLOAD,
    });
  };

  return (
    <CommonButton
      {...rest}
      onClick={handleClick}
      data-testid="renewalsDocumentsBtn"
      endIcon="download-2"
      variant="outlined"
      color="secondary"
      text={fileName}
    />
  );
});
