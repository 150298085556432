import { Grid, Link, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../component/common/icon';
import { BREAKDOWN_CODES, PACKS, PRODUCT_TYPE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  footerIcon: {
    display: 'flex',
    marginTop: '10px',
  },
  footerText: {
    margin: '4px',
  },
  gridItem: {
    display: 'grid',
    padding: spacing(0.5, 1.5),
  },
  iconText: {
    paddingLeft: spacing(0.5),
  },
}));

export const YourExtraCover: React.FC<{ onEdit?: () => void; showEditButton?: boolean }> = observer(
  function YourExtraCover({ onEdit, showEditButton = true }) {
    const { t } = useTranslation('myAccount');
    const {
      interfaceStore: { isDesktop },
      dashboardStore: {
        insurance: {
          auto: { policySelected },
        },
      },
      renewalChangeStore: { pendingMTAQuoteSelected },
    } = useStores();

    const { policyRenewalQuoteDetails, esrProductTypeCd } = policySelected;

    const classes = useStyles({ isDesktop });

    const selectedQuote =
      pendingMTAQuoteSelected && policyRenewalQuoteDetails.pendingMTAQuote
        ? policyRenewalQuoteDetails.pendingMTAQuote
        : policyRenewalQuoteDetails;

    const packCoverages = selectedQuote.vehicle.packCoverages;

    const motorLegalProtection = packCoverages.some((r) => r.coverageCd === PACKS.MOTOR_LEGAL_PROTECTION);
    const ncdpPack = packCoverages.some((r) => r.coverageCd === PACKS.NCDP);
    const upgradedCourtesyCar = packCoverages.some((r) => r.coverageCd === PACKS.CAR_HIRE_COVER);
    const keyCoverPack = packCoverages.some((r) => r.coverageCd === PACKS.KEY_COVER_PACK);
    const misfuellingPack = packCoverages.some((r) => r.coverageCd === PACKS.MISFUELLING_PACK);
    const epaPack = packCoverages.some((r) => r.coverageCd === PACKS.PERSONAL_ACCIDENT_PACK);
    const justInCase = packCoverages.some((r) => r.coverageCd === PACKS.JUST_IN_CASE);
    const fullProtection = packCoverages.some((r) => r.coverageCd === PACKS.FULL_PROTECTION);

    const breakdownCover = packCoverages.some((r) => BREAKDOWN_CODES.includes(r.coverageCd));

    const isCore = esrProductTypeCd === PRODUCT_TYPE.CORE;

    return (
      <Grid container item justifyContent="space-between" className={classes.gridItem}>
        <Grid container>
          {motorLegalProtection || fullProtection ? (
            <IconSure icon="check" color="success" />
          ) : (
            <IconSure icon="times" color="error" />
          )}
          <Typography className={classes.iconText} variant="h4">
            {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.mlp')}
          </Typography>
        </Grid>
        <Grid container>
          {breakdownCover ? <IconSure icon="check" color="success" /> : <IconSure icon="times" color="error" />}
          <Typography className={classes.iconText} variant="h4">
            {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.breakdown')}
          </Typography>
        </Grid>
        <Grid container>
          {upgradedCourtesyCar || fullProtection || justInCase ? (
            <IconSure icon="check" color="success" />
          ) : (
            <IconSure icon="times" color="error" />
          )}
          <Typography className={classes.iconText} variant="h4">
            {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.hirecar')}
          </Typography>
        </Grid>
        {isCore && (
          <Grid container>
            {keyCoverPack || fullProtection || justInCase ? (
              <IconSure icon="check" color="success" />
            ) : (
              <IconSure icon="times" color="error" />
            )}
            <Typography className={classes.iconText} variant="h4">
              {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.keyCover')}
            </Typography>
          </Grid>
        )}
        {isCore && (
          <Grid container>
            {misfuellingPack || fullProtection || justInCase ? (
              <IconSure icon="check" color="success" />
            ) : (
              <IconSure icon="times" color="error" />
            )}
            <Typography className={classes.iconText} variant="h4">
              {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.misfuelling')}
            </Typography>
          </Grid>
        )}
        {isCore && (
          <Grid container>
            {epaPack || fullProtection || justInCase ? (
              <IconSure icon="check" color="success" />
            ) : (
              <IconSure icon="times" color="error" />
            )}
            <Typography className={classes.iconText} variant="h4">
              {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.epa')}
            </Typography>
          </Grid>
        )}
        <Grid container>
          {ncdpPack ? <IconSure icon="check" color="success" /> : <IconSure icon="times" color="error" />}
          <Typography className={classes.iconText} variant="h4">
            {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.pncd')}
          </Typography>
        </Grid>
        {showEditButton && (
          <Grid container direction="row">
            <Link underline="none" target="_blank" onClick={onEdit} className={classes.footerIcon}>
              <IconSure color="secondary" icon="pencil" type="solid" />
              <Typography className={classes.footerText} variant="h4">
                {t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.iconText')}
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    );
  },
);
