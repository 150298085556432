import { Alert, Grid, Snackbar, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../service/state/store';
import { Timer } from '../common/timer';

export interface AlertNotificationInterface {
  onClose: () => void;
}
export const AlertNotification: React.FC<AlertNotificationInterface> = observer(function AlertNotification({
  onClose,
}) {
  const {
    interfaceStore: { alertMessage, isCaura },
  } = useStores();

  // Caura native app is responsible for providing and refreshing token
  if (isCaura) {
    return null;
  }

  return alertMessage !== '' ? (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={alertMessage !== ''}
    >
      <Alert severity="warning">
        <Grid alignItems="center" container direction="column" item>
          <Typography variant="h3">{alertMessage}</Typography>
          <Timer completedCounter={onClose} seconds={10 * 60} />
        </Grid>
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
});
