import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FailedPaymentBlock } from '../../../../../../component/common/failedPaymentBlock';
import { ROUTE } from '../../../../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

export const RenewalPaymentFailed: React.FC = () => {
  const history = useHistory();
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
    dashboardStore: {
      insurance: { home, auto, isHome },
    },
    paymentStore: { updatePaymentComplete },
  } = useStores();
  const { eventTrack } = useSegment();

  const handleClick = () => {
    startChat({
      contactFlowType: isHome ? CONTACT_FLOW_TYPE.HOME_AR : CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        ssRenCardPayFail: 'true',
      },
      initiator: 'chat with us',
    });
  };

  useEffect(() => {
    if (isHome) {
      const policyNumber = home.policySelected.policyNumber;
      eventTrack('Home Rnwl | Payment failure viewed', {
        policyNumber,
        type: TRACK_TYPE.VIEW,
      });
    } else {
      const policyNumber = auto.policySelected.policyNumber;
      eventTrack('Motor Renewal - Payment failure shown', {
        policyNumber,
        type: TRACK_TYPE.VIEW,
      });
    }
    updatePaymentComplete(false);
  }, []);

  return (
    <FailedPaymentBlock
      handleClick={handleClick}
      handleRedirect={() => history.push(ROUTE.RENEWAL_PAYMENT)}
      hasBanner
    />
  );
};
