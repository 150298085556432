import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentCard } from '../../../../../component/common/documentCard';
import { DocumentCardHeader } from '../../../../../component/common/documentCardHeader';
import { DATE_TYPES } from '../../../../../service/constant';
import { IPolicyDocument, PolicyDocument } from '../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../service/util/formatDate';

const useStyles = makeStyles((theme) => ({
  documentPadding: {
    paddingTop: theme.spacing(1),
  },
}));

export const OlderDocuments: React.FC = observer(() => {
  const { eventTrack } = useSegment();
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const { policySelected, selectedDocumentTypeCode } = isHome ? home : auto;

  const { documents, policyNumber } = policySelected;

  const selectedDoc: IPolicyDocument =
    documents.find((doc) => doc.documentTypeCode === selectedDocumentTypeCode) ?? PolicyDocument.create();

  useEffect(() => {
    eventTrack('Document List Viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  return (
    <>
      <DocumentCardHeader
        date={t('documents.date')}
        mainTitle={selectedDoc.fileName}
        sectionTitle={t('documents.currentVersion')}
      />
      <DocumentCard
        date={formatDate(selectedDoc.creationDate?.toString(), DATE_TYPES.MINI)}
        documentCode={selectedDoc.documentTypeCode}
        documentId={selectedDoc.documentId}
        headerText={selectedDoc.fileName}
        policyNumber={policyNumber}
      />

      <DocumentCardHeader date={t('documents.dateExpired')} sectionTitle={t('documents.olderVersions')} />
      <Grid item xs={12}>
        <Grid>
          {selectedDoc.history.map((item, index) => {
            const { documentId, documentTypeCode, expirationDate, fileName } = item;

            return (
              <Grid className={classes.documentPadding} key={`${index}:${fileName}`}>
                <DocumentCard
                  date={formatDate(expirationDate?.toString(), DATE_TYPES.MINI)}
                  dateLabel={t('documentCard.expiredText')}
                  documentCode={documentTypeCode}
                  documentId={documentId}
                  headerText={fileName}
                  policyNumber={policyNumber}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
});
