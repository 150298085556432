import { Grid, alpha, makeStyles } from '@esure-cloud/react-components';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarOwnershipForm from '../../../../../../../../component/common/mta/carOwnership';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .error-message': {
      color: palette.error.main,
      paddingTop: spacing(1),
    },
    '& .icon': {
      marginRight: spacing(1),
    },
    '& .info-box': {
      '& .box-body': {
        color: palette.error.dark,
      },
      '& .box-button': {
        marginLeft: -spacing(2),
        marginTop: spacing(2),
      },
      '& .box-title': {
        color: palette.error.dark,
        fontWeight: 'bold',
      },
      '&.info-box-error': {
        backgroundColor: alpha(palette.error.dark, 0.08),
        border: `1px solid ${palette.error.dark}`,
      },
      backgroundColor: alpha(palette.secondary.main, 0.08),
      border: `1px solid ${palette.secondary.main}`,
      marginTop: spacing(1),
    },
    '& .input': {
      '&__container': {
        gap: spacing(2),
        marginTop: spacing(2),
      },
      '&__item': {
        width: '100%',
      },
    },
    '& .radio-section': {
      gap: 20,
    },
    '& .secondary-title': {
      marginBottom: spacing(1),
    },
    display: 'grid',
  },
}));

export const EditCarOwnership: React.FC<EditPageProps> = observer(function EditCarOwnership({ handleBack }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { setTitle } = useStepperContext();
  const {
    interfaceStore: { isMobile },
    mtaStore: {
      pendedMTA: { vehicle, setVehicle, endorsementDate: startDate },
    },
  } = useStores();
  const [isCarOwned, setIsCarOwned] = useState<null | boolean>(null);
  const [estimatedValue, setEstimatedValue] = useState<number | null>(vehicle.estimatedValue);
  const [purchaseDate, setPurchaseDate] = useState<string | null>(vehicle.purchaseDate);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    setTitle(t('MTAJourney.CarOwnership.title'));
    if (estimatedValue !== null && purchaseDate !== null) {
      setIsCarOwned(true);
    } else if (estimatedValue !== null && purchaseDate == null) {
      setIsCarOwned(false);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'true';
    setIsCarOwned(value);
  };

  const isValid = (): boolean => {
    if (isCarOwned === null) {
      return false;
    }
    if (isCarOwned && (purchaseDate === '' || purchaseDate === null || estimatedValue === null || dateError)) {
      return false;
    }
    if (!isCarOwned && estimatedValue === null) {
      return false;
    }
    if (isCarOwned && ((startDate && purchaseDate && DateTime.fromISO(purchaseDate) > startDate) || dateError)) {
      return false;
    }
    return true;
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      if (isCarOwned) setVehicle({ ...vehicle, estimatedValue, notPurchasedYet: false, purchaseDate });
      else {
        setVehicle({ ...vehicle, estimatedValue, notPurchasedYet: true, purchaseDate: null });
      }
    }
  };

  const handleSave = () => {
    handlePendedMta();
    handleBack();
  };

  return (
    <Grid container item direction="column" xs={isMobile ? 12 : 6} className={classes.root}>
      <CarOwnershipForm
        isCarOwned={isCarOwned}
        estimatedValue={estimatedValue}
        setEstimatedValue={setEstimatedValue}
        purchaseDate={purchaseDate}
        setPurchaseDate={setPurchaseDate}
        isSubmitting={isSubmitting}
        dateError={dateError}
        setDateError={setDateError}
        startDate={startDate}
        isMobile={isMobile}
        handleChange={handleChange}
        setStep={() => {
          return null;
        }}
      />

      <EditFooter id="editCarOwnership" validPage={isValid()} handleSave={handleSave} handleBack={handleBack} />
    </Grid>
  );
});
