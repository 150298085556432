import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import ChatIcon from '@material-ui/icons/Chat';
import MenuIcon from '@material-ui/icons/Menu';
import Skeleton from '@material-ui/lab/Skeleton';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { SVGWrapper } from '../../images/SVGWrapper';
import { track } from '../../lib/optimizely';
import { COMPONENT_ID } from '../../service/analytics';
import { ROUTE } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { setWelcomeMessage } from '../../service/util/appbarUtils';
import { getDateTimeNow } from '../../service/util/dateUtils';
import { isChildRoute } from '../../service/util/routesUtils';
import { Breadcrumbs } from '../layout/breadcrumbs';

import { CauraHeaderComponent } from './caura/cauraHeader';
import { PopupTooltip } from './popupTooltip';
import { GeneralCustomAttrs, useChatBotAttributes } from './utils/useChatBotAttributes';
import { useMTAChatBotAttributes } from './utils/useMTAChatBotAttributes';

export interface ToggleFn {
  handleDrawerToggle: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingTop: 0,
      width: `calc(100% - 175px)`,
    },
    backgroundColor: theme.palette.secondary.contrastText,
    zIndex: 998,
  },
  backArrow: {
    color: theme.palette.secondary.main,
  },
  backArrowContainer: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(4),
  },
  fontColour: {
    color: theme.palette.secondary.dark,
    marginLeft: theme.spacing(6),
  },
  iconColor: {
    color: theme.palette.secondary.main,
    height: 27,
    marginRight: theme.spacing(2.5),
    width: 27,
  },
  logoMeasure: {
    maxHeight: theme.spacing(2),
  },
  marginButton: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  mobileLogo: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 'auto',
  },
  needHelp: {
    '&:hover': {
      backgroundColor: 'initial',
    },
    color: theme.palette.secondary.main,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  skeletonMargin: {
    margin: theme.spacing(4, 8),
  },
  toolbarBaseLine: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '53px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '74px',
    },
    margin: 0,
    padding: 0,
  },
}));

export const getCorrectAttributes = (autorenewal: boolean | null, isHome: boolean): GeneralCustomAttrs | undefined => {
  if (isHome) {
    return 'ssHomeMainChat';
  } else {
    return undefined;
  }
};

export const AppBarComponent: React.FC<ToggleFn> = observer(function AppBarComponent({ handleDrawerToggle }) {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      customer,
      insurance: { home, isHome, auto },
    },
    interfaceStore: { isCaura, isLoading, chatBot },
  } = useStores();

  const policySelected = isHome ? home : auto;

  const chatMTABotAttr = useMTAChatBotAttributes('ssMTAVehicleMainChat');
  const chatBotAttr = useChatBotAttributes();
  const homeChatBotAttr = useChatBotAttributes(getCorrectAttributes(policySelected.mainPolicy.autoRenewal, isHome));

  const location = useLocation();
  const classes = useStyles();

  const [hour, setHour] = useState<null | number>(null);

  const isRoute =
    !isChildRoute(location.pathname) ||
    location.pathname === ROUTE.DOCUMENTS ||
    location.pathname === ROUTE.PAYMENTS ||
    location.pathname === ROUTE.PAYMENT_METHODS;
  const isRouteClaims = location.pathname === ROUTE.CLAIMS || location.pathname === ROUTE.START_NEW_CLAIM;

  useEffect(() => {
    const date = getDateTimeNow();
    const userHour = date.hour;
    setHour(userHour);
  }, []);

  const hamburgerMenuVariantMobile = () => {
    return (
      <>
        (
        {!isCaura ? (
          <IconButton
            aria-label="open drawer"
            className={classes.menuButton}
            edge="start"
            onClick={handleDrawerToggle}
            data-testid="app-bar-menu-button"
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        )
        <Box className={classes.mobileLogo}>
          <SVGWrapper alt="logo" className={classes.logoMeasure} src="logo.svg" />
        </Box>
      </>
    );
  };

  const hamburgerMenuVariantDesktop = () => {
    if (isLoading) {
      return <Skeleton className={classes.skeletonMargin} variant="rect" width="15%" />;
    } else {
      if ([ROUTE.DASHBOARD, ROUTE.ACCOUNT, ROUTE.COMMUNICATION].includes(location.pathname as ROUTE)) {
        return (
          <Typography className={classes.fontColour} noWrap variant="h3">
            {setWelcomeMessage(customer.fullName, hour)}
          </Typography>
        );
      } else return <Breadcrumbs />;
    }
  };

  const handleNeedHelp = () => {
    track('My_Account_need_help_click');
    chatBot.startChat({
      initiator: 'AppBar Desktop Button',
      ...(chatMTABotAttr ?? {}),
      ...(chatBotAttr ?? {}),
      ...(homeChatBotAttr ?? {}),
    });
  };

  window.addEventListener('message', (event) => {
    const allowedOrigins = [
      'https://eclaim-dash.esure.com',
      'https://s-eclaim-dash.digitaltesting.co.uk',
      'https://d-eclaim-dash.digitaltesting.co.uk',
      'https://eclaim-dash-sheila.sheilaswheels.com',
      'https://s-eclaim-dash-sheila.digitaltesting.co.uk',
      'https://d-eclaim-dash-sheila.digitaltesting.co.uk',
      'https://eclaim-dash.firstalternative.com',
      'https://s-eclaim-dash-fa.digitaltesting.co.uk',
      'https://d-eclaim-dash-fa.digitaltesting.co.uk',
    ];

    if (allowedOrigins.includes(event.origin) && event.data === 'start-chat') {
      handleNeedHelp();
    }
  });

  return (
    <AppBar className={classes.appBar} position="fixed" data-testid="app-bar-component">
      <CauraHeaderComponent isCaura={isCaura} />
      <Toolbar className={classes.toolbarBaseLine}>
        <Hidden smDown>
          <Grid alignContent="center" alignItems="center" container justifyContent="space-between" wrap="nowrap">
            <Grid alignContent="center" alignItems="center" container item wrap="nowrap">
              <Hidden smDown>{hamburgerMenuVariantDesktop()}</Hidden>
              <Hidden mdUp>
                {isRoute && (
                  <Typography className={classes.fontColour} noWrap variant="h3">
                    {setWelcomeMessage(customer.fullName, hour)}
                  </Typography>
                )}
              </Hidden>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} wrap="nowrap">
                <Grid item>
                  {isRouteClaims ? (
                    <PopupTooltip
                      alignment="center"
                      anchorEl={
                        <div>
                          <Button
                            className={classes.needHelp}
                            data-testid={COMPONENT_ID.NEED_HELP_APPBAR_BUTTON_CLAIMS_PAGE}
                            onClick={handleNeedHelp}
                            startIcon={<ChatIcon />}
                          >
                            <span className={classes.noWrap}>{t('appbar.needHelp')}</span>
                          </Button>
                        </div>
                      }
                      arrow="right"
                      color="secondary"
                      hideClose
                      permanent={isRouteClaims}
                      placement="bottom-end"
                      size="small"
                      title={t('appbar.needHelpTooltip')}
                    />
                  ) : (
                    <Button
                      className={classes.needHelp}
                      data-testid={COMPONENT_ID.NEED_HELP_APPBAR_BUTTON}
                      onClick={handleNeedHelp}
                      startIcon={<ChatIcon />}
                    >
                      <span className={classes.noWrap}>{t('appbar.needHelp')}</span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container justifyContent="space-between" wrap="nowrap">
            {hamburgerMenuVariantMobile()}
            <IconButton
              edge="end"
              onClick={() =>
                chatBot.startChat({
                  initiator: 'AppBar Mobile Button',
                  ...(chatMTABotAttr ?? {}),
                  ...(chatBotAttr ?? {}),
                  ...(homeChatBotAttr ?? {}),
                })
              }
            >
              <ChatIcon className={classes.iconColor} />
            </IconButton>
          </Grid>
        </Hidden>
      </Toolbar>
      <Divider />
    </AppBar>
  );
});
