import { Button, Grid, makeStyles } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { JOURNEY_TYPE, ROUTE } from '../../../../../../../service/constant';
import {
  IAutoPolicyRenewalQuoteDetails,
  IRenewalPendingMTAQuote,
} from '../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IRenewalDriver } from '../../../../../../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../../../../../../service/state/store';
import { useRenewalStepperContext } from '../utils/renewalStepperContext';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& a': {
      marginTop: spacing(1),
    },
    '& button': {
      '&.cancelButton': {
        fontSize: '1rem',
        marginTop: spacing(1.5),
        width: 'auto',
      },
      '&.fullWidthButton': {
        width: '100%',
      },
      '&.primaryButton': {
        whiteSpace: 'nowrap',
      },
      lineHeight: 1,
      marginTop: spacing(5),
      padding: spacing(0, 4),
      width: '48%',
    },
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
      width: '100%',
    },
    gap: spacing(1),
    paddingBottom: spacing(5),
    width: 'fit-content',
  },
}));

export interface PrimaryButtonAsObject {
  buttonId?: string;
  handleContinue?: () => void;
  text?: string;
}
export type PrimaryButtonAsFunction = () => React.ReactElement;

export interface FooterProps {
  backButtonId?: string;
  cancelButtonId?: string;
  handleBack?: () => void;
  primaryButton?: PrimaryButtonAsObject | PrimaryButtonAsFunction;
}

export const removedAddedBlankDriver = (
  journeyType: string,
  drivers: IRenewalDriver[],
  selectedDriverUUID: string,
  setDrivers: (a: IRenewalDriver[]) => void,
) => {
  // remove previously added new driver if add driver journey is cancelled
  if (journeyType === JOURNEY_TYPE.ADD_DRIVER) {
    const newDrivers = drivers.filter((d) => d.uuid !== selectedDriverUUID);
    setDrivers(newDrivers);
  }
};

export const clearRemovedDrivers = (journeyType: string, setRemovedDrivers: (a: string[]) => void) => {
  // clear removed drivers if journey is cancelled
  if (journeyType === JOURNEY_TYPE.REMOVE_DRIVER) {
    setRemovedDrivers([]);
  }
};

export const revertChanges = (
  usePending: boolean,
  addRenewalQuoteDetails: (a: IRenewalPendingMTAQuote | IAutoPolicyRenewalQuoteDetails) => void,
  pendingQuote: IRenewalPendingMTAQuote | null,
  originaQuote: IAutoPolicyRenewalQuoteDetails,
) => {
  if (usePending && pendingQuote) {
    addRenewalQuoteDetails(pendingQuote);
  } else {
    addRenewalQuoteDetails(originaQuote);
  }
};

export const RenewalChangeFooter: React.FC<FooterProps> = observer(function RenewalChangeFooter({
  primaryButton,
  handleBack,
  backButtonId,
  cancelButtonId,
}) {
  const {
    stepsData: { steps, journeyType },
    step,
    setStep,
    nextStep: handleContinue,
  } = useRenewalStepperContext();

  const history = useHistory();

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyRenewalQuoteDetails },
        },
      },
    },
    renewalChangeStore: {
      pendingMTAQuoteSelected,
      renewalDetails: {
        addRenewalQuoteDetails,
        drivers,
        removedDrivers,
        selectedDriverUUID,
        setDrivers,
        setRemovedDrivers,
      },
    },
  } = useStores();

  const listOfRemovedD: string[] = toJS(removedDrivers);
  const [deletedDrivers] = useState(listOfRemovedD);

  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const hideCancelButton = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

  const handleBackButton = () => {
    if (handleBack) {
      handleBack();
    } else if (step === 0) {
      removedAddedBlankDriver(journeyType, drivers, selectedDriverUUID, setDrivers);
      clearRemovedDrivers(journeyType, setRemovedDrivers);
      revertChanges(
        pendingMTAQuoteSelected,
        addRenewalQuoteDetails,
        policyRenewalQuoteDetails.pendingMTAQuote,
        policyRenewalQuoteDetails,
      );
      history.push(ROUTE.YOUR_COVER_DETAILS);
    } else {
      setStep(step - 1);
      // scroll to top
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  };

  const continueText = step + 1 === steps.length ? t('MTAJourney.button.save') : t('MTAJourney.button.continue');

  const continueClicked = () => {
    if (journeyType === JOURNEY_TYPE.REMOVE_DRIVER && step + 1 === steps.length) {
      const updatedDrivers = toJS(drivers).filter((d: IRenewalDriver) => !deletedDrivers.includes(d.uuid));
      setDrivers(updatedDrivers);
    }

    // scroll to top
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (step + 1 === steps.length && typeof primaryButton === 'object' && primaryButton.handleContinue) {
      primaryButton.handleContinue();
    } else if (typeof primaryButton === 'object' && primaryButton.handleContinue) {
      primaryButton.handleContinue();
    } else {
      handleContinue();
    }
  };

  /* istanbul ignore next */
  const handleCancel = () => {
    removedAddedBlankDriver(journeyType, drivers, selectedDriverUUID, setDrivers);
    clearRemovedDrivers(journeyType, setRemovedDrivers);
    revertChanges(
      pendingMTAQuoteSelected,
      addRenewalQuoteDetails,
      policyRenewalQuoteDetails.pendingMTAQuote,
      policyRenewalQuoteDetails,
    );
    history.push(ROUTE.YOUR_COVER_DETAILS);
  };

  return (
    <Grid container className={classes.root}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleBack ? handleBack : handleBackButton}
        data-testid={backButtonId}
      >
        {t('MTAJourney.button.back')}
      </Button>

      {typeof primaryButton === 'object' || primaryButton === undefined ? (
        <Button color="primary" variant="contained" onClick={continueClicked} data-testid={primaryButton?.buttonId}>
          {primaryButton?.text ? primaryButton.text : continueText}
        </Button>
      ) : (
        primaryButton()
      )}
      {!hideCancelButton && (
        <Button
          data-testid={cancelButtonId}
          variant="text"
          color="secondary"
          className="cancelButton"
          onClick={handleCancel}
        >
          {t('MTAJourney.button.cancel')}
        </Button>
      )}
    </Grid>
  );
});
