import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

export const RemoveDriver: React.FC = observer(() => {
  const {
    setTitle,
    nextStep,
    editMode,
    stepsData: { reviewStep },
    step,
    setStep,
    setEditMode,
    lastStepRestoreData,
    setLastStepRestoreData,
  } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { drivers: dashboardStoreDrivers },
        },
      },
    },
    mtaStore: {
      pendedMTA: { setDrivers, setRemovedDrivers, removedDrivers, drivers },
    },
  } = useStores();
  const { eventTrack } = useSegment();

  const listOfRemovedDrivers: string[] = toJS(removedDrivers);
  const [deletedDrivers, setDeletedDrivers] = useState(listOfRemovedDrivers);

  useEffect(() => {
    setTitle(t('MTAJourney.removeDriver.title'));
    eventTrack('Which driver to remove viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  const handleContinue = () => {
    setIsSubmitting(true);
    if (deletedDrivers.length > 0) {
      setRemovedDrivers(deletedDrivers);

      const pendingDrivers: Map<string, IDriver> = new Map();
      toJS(dashboardStoreDrivers).forEach((driver) => pendingDrivers.set(driver.uuid, driver));
      toJS(drivers).forEach((driver) => pendingDrivers.set(driver.uuid, driver));

      const updatedDrivers: IDriver[] = Array.from(pendingDrivers.values()).filter(
        (d: IDriver) => !deletedDrivers.includes(d.uuid),
      );

      // check if the main driver has been changed
      const findMainDriverInDeletedDrivers = updatedDrivers.find((d) => d.mainDriver)?.uuid;
      const mainDriver = drivers.find((item) => item.mainDriver === true)?.uuid;
      // if so, add the mainDriver flag to it
      // otherwise add the flag to the main driver from the policy
      const mainDriverUuid = findMainDriverInDeletedDrivers ? findMainDriverInDeletedDrivers : mainDriver;

      setDrivers(
        updatedDrivers.map((item) => {
          if (item.uuid === mainDriverUuid) {
            return { ...item, mainDriver: true };
          }
          return { ...item, mainDriver: false };
        }),
      );

      setLastStepRestoreData(deletedDrivers);

      setEditMode(false);
      nextStep();
    }
  };

  const handleBack = () => {
    if (editMode) {
      setEditMode(false);
      setStep(reviewStep);
    } else {
      setStep(step - 1);
    }
  };

  const handleCheckboxChange = (idx: string) => {
    if (deletedDrivers.includes(idx)) {
      const removeItem = deletedDrivers.filter((itm) => itm !== idx);
      setDeletedDrivers(removeItem);
    } else {
      setDeletedDrivers([...deletedDrivers, idx]);
    }
  };

  const addDriversFromDashboardStoreToPendedMTA = (lastStepRestoreData: string[]) => {
    lastStepRestoreData.forEach((driverUuid) => {
      const driverToAdd = dashboardStoreDrivers.find((d) => d.uuid === driverUuid);
      const isDriverAlreadyInArray = drivers.some((d) => d.uuid === driverToAdd?.uuid);

      if (driverToAdd !== undefined && !isDriverAlreadyInArray) {
        setDrivers([...drivers, toJS(driverToAdd)]);
      }
    });
  };

  useEffect(() => {
    if (lastStepRestoreData !== undefined && lastStepRestoreData.length > 0) {
      addDriversFromDashboardStoreToPendedMTA(lastStepRestoreData);
      setLastStepRestoreData([]);
    }
  }, []);

  return (
    <>
      <Typography variant="body1">{t('MTAJourney.removeDriver.headline')}</Typography>
      <FormGroup>
        {drivers.map(
          (driver, idx) =>
            driver.relationshipToProposerCode !== 'P' && (
              <FormControlLabel
                onChange={() => handleCheckboxChange(driver.uuid)}
                control={<Checkbox checked={deletedDrivers.includes(driver.uuid)} />}
                key={idx}
                label={`${driver.person.firstName} ${driver.person.surname}`}
              />
            ),
        )}
        {!(deletedDrivers.length > 0) && isSubmitting && (
          <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
        )}
      </FormGroup>
      <MTAFooter
        backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_REMOVE_DRIVER}
        cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_REMOVE_DRIVER}
        primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_REMOVE_DRIVER, handleContinue }}
        handleBack={handleBack}
      />
    </>
  );
});
