import { Drawer, Grid, IconButton, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { memo } from 'react';

import { getSafeAreaBottom, getSafeAreaTop } from '../../lib/caura-bridge';
import { PANEL_TYPE } from '../../service/constant';
import { useStores } from '../../service/state/store';
import IconSure from '../common/icon';
import { AutoRenewalDrawer, CarDetailsDrawer, DriverDrawerProfile, LifestylePacksDrawer } from '../layout/drawers';
import { NCDDrawer } from '../layout/drawers/NCDDrawer';
import { YourHomeDrawer } from '../layout/drawers/yourHomeDrawer';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '100vw',
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
      width: '35vw',
    },
  },
  iconClose: {
    position: 'absolute',
    right: theme.spacing(3),
  },
}));

const PanelDrawer: React.FC = observer(function PanelDrawer() {
  const classes = useStyles();

  const {
    interfaceStore: {
      panel: { closePanel, open, packCode, title, type },
      isCaura,
    },
  } = useStores();

  const DrawerContent = () => {
    switch (type) {
      case PANEL_TYPE.AUTO_RENEWAL:
        return <AutoRenewalDrawer />;
      case PANEL_TYPE.NCD:
        return <NCDDrawer />;
      case PANEL_TYPE.CAR_DETAILS:
        return <CarDetailsDrawer />;
      case PANEL_TYPE.DRIVER:
        return <DriverDrawerProfile />;
      case PANEL_TYPE.LIFESTYLE_PACK:
        return <LifestylePacksDrawer code={packCode} />;
      case PANEL_TYPE.YOUR_HOME:
        return <YourHomeDrawer />;
      default:
        return null;
    }
  };

  let topStyle = {};
  let bottomStyle = {};
  if (isCaura) {
    bottomStyle = { paddingBottom: `${Number(getSafeAreaBottom())}px` };
    topStyle = { paddingTop: `${Number(getSafeAreaTop())}px` };
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawerPaper }}
      onClose={closePanel}
      open={open}
      variant="temporary"
    >
      <Grid style={topStyle} alignItems="center" container>
        <Typography variant="h2">{title}</Typography>
        <IconButton className={classes.iconClose} onClick={closePanel}>
          <IconSure color="secondary" icon="times" />
        </IconButton>
      </Grid>

      <div style={bottomStyle}>
        <DrawerContent />
      </div>
    </Drawer>
  );
});

export default memo(PanelDrawer);
