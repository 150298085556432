import { observer } from 'mobx-react-lite';

import { PolicyPageSkeleton } from '../../../component/layout/skeletons/policySkeleton';
import { useStores } from '../../../service/state/store';

import { PoliciesDashboardDesktop } from './policiesDashboards/desktop';

export const PoliciesApp: React.FC = observer(function PoliciesApp() {
  const {
    interfaceStore: { isLoading },
  } = useStores();

  return isLoading ? <PolicyPageSkeleton /> : <PoliciesDashboardDesktop />;
});
