/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
} from '@esure-cloud/react-components';

import { GridProps, Table as TableMui } from '@material-ui/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES, PAYMENT_METHOD } from '../../service/constant';
import { ITransaction } from '../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../service/state/store';
import { formatDate } from '../../service/util/formatDate';
import SelfService from '../../service/util/selfService';
import IconSure from '../common/icon';
import { getCreditCardImage } from '../common/utils';

const StyledTableCell = withStyles((theme) => ({
  body: {
    '& .bold': {
      '&-green': {
        color: theme.palette.success.dark,
        fontWeight: 'bold',
      },
      fontWeight: 'bold',
    },
    '&:last-child': {
      '& .card-container': {
        gap: theme.spacing(1),
      },
      textAlign: 'end',
    },
    '&:not(:last-child)': {
      [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.palette.secondary.main}50`,
      },
    },
    borderBottom: `1px solid ${theme.palette.secondary.main}50`,
    textAlign: 'start',
  },
  head: {
    '&:last-child': {
      textAlign: 'end',
    },
    '&:not(:last-child)': {
      [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.palette.secondary.main}50`,
      },
    },
    backgroundColor: `${theme.palette.secondary.main}08`,
    borderBottom: `1px solid ${theme.palette.secondary.main}50`,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:last-child td': { borderBottom: 0 },
    '&:nth-child(2n-1) td': {
      background: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles(({ palette, shape, spacing }) => ({
  root: {
    '& .amount-container': {
      verticalAlign: 'top',
    },
    '& .cell-with-icon': {
      gap: 4,
      verticalAlign: 'baseline',
    },
    '& .details-container': {
      paddingTop: spacing(1),
    },
    border: `1px solid ${palette.secondary.main}50`,
    borderRadius: shape.borderRadius,
  },
}));

export interface PaymentTableProps extends GridProps {
  headers: string[];
  transactions: PaymentTableData[];
}

export type PaymentMethod = 'ACCOUNT' | 'CARD';

export interface PaymentTableData extends ITransaction {
  details?: string;
  hasChangeInPremium?: boolean;
  method?: PaymentMethod;
  shortDescription?: string;
  subType?: string;
}

export enum PAYMENTS_ACTIVIY_HEADERS {
  AMOUNT = 'Amount',
  DETAILS = 'Details',
  PAYMENTS_ACTIVITY = 'Payment activity',
}
export const PaymentTable: React.FC<PaymentTableProps> = ({ transactions, headers, className }) => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isMobile },
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();

  const shortDescWithTooltip = ['Non-premium refund'];

  const isSameDayAsStart = (transactionDate: DateTime | null) => {
    return (
      transactionDate !== null &&
      policySelected.effectiveDate !== null &&
      transactionDate.startOf('hour') <= policySelected.effectiveDate.startOf('hour')
    );
  };

  return (
    <TableContainer className={clsx(className, classes.root)}>
      <TableMui>
        <TableHead>
          <StyledTableRow>
            {headers.map((cellTitle, index) => {
              return (
                <StyledTableCell key={`cell_header_typo_${index}`}>
                  <Typography variant="h3">{cellTitle}</Typography>
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {transactions?.map((row, rowIndex) => {
            const {
              type,
              transactionDate,
              amount,
              paymentMethod: { maskedCardNumber, creditCardType },
              details,
              shortDescription,
              method = 'CARD',
              hasChangeInPremium,
            } = row;
            const cardImg =
              method === PAYMENT_METHOD.CARD ? getCreditCardImage(creditCardType) : './assets/images/account.svg';

            return (
              <StyledTableRow key={`table_row_${rowIndex}`}>
                {headers.includes(PAYMENTS_ACTIVIY_HEADERS.PAYMENTS_ACTIVITY) && (
                  <StyledTableCell>
                    <Typography className="bold" variant="body1">
                      {amount > 0 ? t('payments.paymentDetails.refund') : type}
                    </Typography>
                    <Typography variant="body2">{formatDate(transactionDate?.toString(), DATE_TYPES.MINI)}</Typography>
                    {isMobile && (
                      <Grid className="details-container">
                        <Typography className="bold" variant="body1">
                          {details}
                        </Typography>
                        {!shortDescWithTooltip.includes(shortDescription ?? '') ? (
                          <Typography variant="body2">{shortDescription}</Typography>
                        ) : (
                          <Grid container className="cell-with-icon">
                            {shortDescription}
                            <IconSure icon="info-circle" size="20" type="solid" color="secondary"></IconSure>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </StyledTableCell>
                )}
                {headers.includes(PAYMENTS_ACTIVIY_HEADERS.DETAILS) && (
                  <StyledTableCell>
                    {typeof details !== 'undefined' ? (
                      <Typography className="bold" variant="body1">
                        {details}
                      </Typography>
                    ) : (
                      <Typography className="bold" variant="body1">
                        {isSameDayAsStart(transactionDate)
                          ? t('payments.paymentDetails.policyPurchase')
                          : t('payments.paymentDetails.changeMade')}
                      </Typography>
                    )}

                    {!shortDescWithTooltip.includes(shortDescription ?? '') ? (
                      <Typography variant="body2">{shortDescription}</Typography>
                    ) : (
                      <Grid container className="cell-with-icon">
                        {shortDescription}
                        <IconSure icon="info-circle" size="20" type="solid" color="secondary"></IconSure>
                      </Grid>
                    )}
                  </StyledTableCell>
                )}
                {headers.includes(PAYMENTS_ACTIVIY_HEADERS.AMOUNT) && (
                  <StyledTableCell className="amount-container">
                    <Typography className={`bold${amount > 0 ? '-green' : ''}`} variant="body1">
                      {hasChangeInPremium
                        ? `${SelfService.toCurrency(Math.abs(amount))} p/m`
                        : SelfService.toCurrency(Math.abs(amount))}
                    </Typography>
                    <Grid className="card-container" container justifyContent="flex-end">
                      {cardImg && <img alt="card" src={cardImg} />}
                      <Typography variant="caption">
                        {method === 'ACCOUNT' ? `acc. ${maskedCardNumber}` : `ending in ${maskedCardNumber}`}
                      </Typography>
                    </Grid>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </TableMui>
    </TableContainer>
  );
};
