import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedSelectDropDown } from '../../../../../../../../component/common/outlinedSelectDropdown';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

import { DriverEditPageProps } from './editDriver';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .license-field': {
      marginBottom: spacing(3),
    },
    '& .license-held-container': {
      maxWidth: '352px',
    },
    '& .license-label': {
      '&.error': {
        color: palette.error.dark,
      },
      marginBottom: spacing(1),
    },
  },
}));

export const DriverLicense: React.FC<DriverEditPageProps> = observer(
  ({ handleBackDriverEdit, handleSaveDriverEdit }) => {
    const {
      dashboardStore: {
        lookups: { lookupsByName },
      },
      mtaStore: {
        pendedMTA: { setSelectedDriver, selectedDriver },
      },
    } = useStores();

    const classes = useStyles();

    const [licenseTypeList, setLicenseTypeList] = useState<ILookupItem[] | undefined>();
    const [licenseRestrictionList, setLicenseRestrictionList] = useState<ILookupItem[] | undefined>();
    const [licenseHeldYearsList, setLicenseHeldYearsList] = useState<ILookupItem[] | undefined>();
    const [licenseHeldMonthsList, setLicenseHeldMonthsList] = useState<ILookupItem[] | undefined>();
    const [licenseRestrictionCode, setLicenseRestrictionsCode] = useState(
      selectedDriver.licence.licenseRestrictionCode,
    );
    const [licenceTypeCode, setLicenceTypeCode] = useState(selectedDriver.licence.licenceTypeCode);
    const [licenseDateYearsCode, setLicenceHeldYearsCode] = useState(selectedDriver.licence.licenseDateYearsCode);
    const [licenseDateMonthsCode, setLicenceHeldMonthsCode] = useState(selectedDriver.licence.licenseDateMonthsCode);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation('myAccount');

    const {
      nextStep,
      setTitle,
      setStep,
      editMode,
      stepsData: { reviewStep, journeyType },
    } = useStepperContext();
    const isChangeDriverDetailsJourney = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

    const isNotValid = () => {
      return (
        licenceTypeCode === '' ||
        licenseRestrictionCode === '' ||
        licenseDateYearsCode === '' ||
        (parseInt(licenseDateYearsCode) <= 4 && licenseDateMonthsCode === '')
      );
    };

    const handleResetOnLicenceTypeChange = () => {
      setLicenceHeldYearsCode('');
      setLicenceHeldMonthsCode('');
      setLicenseRestrictionsCode('');
    };

    const handlePendedMta = () => {
      setIsSubmitting(true);
      !isNotValid() &&
        setSelectedDriver({
          ...selectedDriver,
          licence: {
            ...selectedDriver.licence,
            licenceTypeCode,
            licenseDateMonthsCode,
            licenseDateYearsCode,
            licenseRestrictionCode,
          },
        });
    };

    const handleSave = () => {
      handlePendedMta();
      if (!isNotValid()) {
        isChangeDriverDetailsJourney ? handleSaveDriverEdit?.() : setStep(reviewStep);
      }
    };
    const handleContinue = () => {
      handlePendedMta();
      !isNotValid() && nextStep();
    };

    const handleBack = () => {
      isChangeDriverDetailsJourney ? handleBackDriverEdit?.() : setStep(reviewStep);
    };

    const { isLookupsFetched } = useLookups({
      lookups: [
        LOOKUP.LICENCE_TYPE,
        LOOKUP.LICENSE_HELD_YEARS,
        LOOKUP.LICENSE_HELD_MONTHS,
        LOOKUP.LICENSE_RESTRICTIONS,
      ],
    });

    useEffect(() => {
      setTitle(t('MTAJourney.licenseType.title'));
    }, []);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isLookupsFetched && lookupsByName[LOOKUP.LICENCE_TYPE] !== undefined) {
        setLicenseTypeList(lookupsByName[LOOKUP.LICENCE_TYPE]);
        setLicenseRestrictionList(lookupsByName[LOOKUP.LICENSE_RESTRICTIONS]);
        setLicenseHeldYearsList(lookupsByName[LOOKUP.LICENSE_HELD_YEARS]);
        setLicenseHeldMonthsList(lookupsByName[LOOKUP.LICENSE_HELD_MONTHS]);
      }
    }, [isLookupsFetched, lookupsByName]);

    const licenseLabelClasses = `license-label ${licenceTypeCode === '' && isSubmitting ? 'error' : ''}`;
    return (
      <Grid className={classes.root}>
        <Grid className="license-held-container">
          <Typography variant="body1" className={licenseLabelClasses}>
            {t('MTAJourney.licenseType.licenseTypeLabel')}
          </Typography>
          <Grid className="license-field">
            <RadioGroup
              value={licenceTypeCode}
              onChange={(event) => {
                setLicenceTypeCode(event.target.value);
                handleResetOnLicenceTypeChange();
              }}
            >
              {licenseTypeList?.map((item, index) => {
                return (
                  <FormControlLabel
                    key={`${item.code}-${index}`}
                    value={item.code}
                    control={<Radio />}
                    label={item.value}
                  />
                );
              })}
            </RadioGroup>
            {licenceTypeCode === '' && isSubmitting && (
              <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
            )}
          </Grid>

          <Grid className="license-field">
            {licenseHeldYearsList && (
              <OutlinedSelectDropDown
                label={t('MTAJourney.licenseType.licenseLengthYearsLabel')}
                errorMessage={t('validations.noSelection')}
                isError={licenseDateYearsCode === '' && isSubmitting}
                onChangeHandler={setLicenceHeldYearsCode}
                value={licenseDateYearsCode}
                values={licenseHeldYearsList}
                dataTest="licenseHeldYearsList"
              />
            )}
          </Grid>
          <Grid className="license-field">
            {licenseHeldMonthsList && parseInt(licenseDateYearsCode) <= 4 && (
              <OutlinedSelectDropDown
                errorMessage={t('validations.noSelection')}
                isError={licenseDateMonthsCode === '' && isSubmitting}
                label={t('MTAJourney.licenseType.licenseLengthMonthLabel')}
                onChangeHandler={setLicenceHeldMonthsCode}
                value={licenseDateMonthsCode}
                values={licenseHeldMonthsList}
                dataTest="licenseHeldMonthsList"
              />
            )}
          </Grid>
          <Grid className="license-field">
            {licenseRestrictionList && (
              <>
                <MtaTooltip
                  tooltipTitle={t('tooltips.licenceRestriction')}
                  description={t('MTAJourney.licenseType.licenseRestrictionLabel')}
                />
                <OutlinedSelectDropDown
                  errorMessage={t('validations.noSelectionDropdown')}
                  isError={licenseRestrictionCode === '' && isSubmitting}
                  onChangeHandler={setLicenseRestrictionsCode}
                  value={licenseRestrictionCode}
                  values={licenseRestrictionList}
                  dataTest="licenseRestrictionList"
                />
              </>
            )}
          </Grid>
        </Grid>

        {editMode ? (
          <EditFooter id="driverLicense" validPage={!isNotValid()} handleSave={handleSave} handleBack={handleBack} />
        ) : (
          <MTAFooter
            backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_DRIVER_LICENSE}
            cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_DRIVER_LICENSE}
            primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_DRIVER_LICENSE, handleContinue }}
          />
        )}
      </Grid>
    );
  },
);
