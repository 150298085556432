import { Alert, IconButton, Slide, Snackbar, Typography, makeStyles } from '@esure-cloud/react-components';

import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';

import { useStores } from '../service/state/store';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    '& .alert': {
      '&-text': {
        whiteSpace: 'pre-line',
      },
      width: '100%',
    },
    top: 108,
    [breakpoints.down('xs')]: {
      top: 80,
    },
  },
}));

export const Notification: React.FC = observer(function Notification() {
  const classes = useStyles();

  const {
    interfaceStore: {
      notification: { open, closeNotification, autoHideDuration, message, severity },
    },
  } = useStores();

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      className={classes.root}
      TransitionComponent={Slide}
      onClose={closeNotification}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      data-testid="notification-component"
    >
      <Alert
        className="alert"
        severity={severity}
        variant="standard"
        action={
          <IconButton color="inherit" aria-label="Close" size="small" onClick={closeNotification}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Typography className="alert-text" variant="body2">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
});
