import {
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  PopperPlacementType,
  Tooltip,
  makeStyles,
  withStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useStores } from '../../service/state/store';

import IconSure from './icon';

const useStyles = makeStyles((theme) => ({
  arrow: {
    '&.left': {
      borderRight: '50px solid transparent',
      left: 0,
    },
    '&.right': {
      borderLeft: '50px solid transparent',
      right: 0,
    },
    borderBottom: `50px solid ${theme.palette.secondary.main}`,
    position: 'absolute',
    top: -18,
    transition: 'all 0.3s ease-out',
  },
  card: {
    '&.center': {
      textAlign: 'center',
    },

    '&.has-arrow': {
      marginTop: 25,
    },
    '&.primary': {
      background: theme.palette.primary.main,
    },
    '&.primary, &.secondary': {
      boxShadow: 'none',
      color: theme.palette.common.white,
    },
    '&.right': {
      textAlign: 'right',
    },
    '&.secondary': {
      background: theme.palette.secondary.main,
    },
    '&.small': {
      maxWidth: 170,
    },
    position: 'relative',
  },
  cardWrapper: {
    position: 'relative',
  },
  iconButton: {
    '&:hover': {
      transform: 'scale3d(1.15, 1.15, 1)',
    },
    transformStyle: 'preserve-3d',
    transition: '.3s ease all',
    willChange: 'transform',
    zIndex: 998,
  },
}));

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 285,
    padding: 0,
  },
}))(Tooltip);

export interface PopupTooltipProps {
  alignment?: 'left' | 'center' | 'right';
  anchorEl?: React.ReactElement;
  arrow?: 'left' | 'right';
  background?: string;
  color?: 'primary' | 'secondary';
  hideClose?: boolean;
  linkText?: string;
  onClick?: () => void;
  permanent?: boolean;
  placement?: PopperPlacementType;
  size?: 'medium' | 'small' | 'auto';
  subheader?: React.ReactNode;
  title: string;
  tooltipColor?: string;
}

export const PopupTooltip: React.FC<PopupTooltipProps> = observer(function PopupTooltip(props) {
  const {
    background,
    color = 'default',
    size = 'medium',
    alignment = 'left',
    placement = 'bottom-start',
    linkText = '',
    arrow,
    title,
    subheader,
    anchorEl,
    permanent = false,
    hideClose = false,
    onClick,
    tooltipColor,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(permanent);

  const {
    interfaceStore: { isDesktop },
  } = useStores();

  useEffect(() => {
    setOpen(permanent);
  }, [permanent]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      if (!permanent) setOpen(false);
    }, 100);
  };

  return (
    <HtmlTooltip
      interactive
      disableHoverListener
      onClose={isDesktop ? handleClose : undefined}
      onClick={handleOpen}
      onOpen={handleOpen}
      open={open}
      placement={placement}
      title={
        <div className={classes.cardWrapper}>
          {arrow && <div className={clsx(classes.arrow, arrow)}></div>}
          <Card
            className={clsx(classes.card, color, size, alignment, arrow && 'has-arrow')}
            style={{ background: background }}
          >
            <CardHeader
              data-testid="close"
              action={
                !hideClose && (
                  <IconButton size="small" aria-label="close" onClick={handleClose}>
                    <IconSure icon="times" size="20" color="black" strokeWidth="1.5" />
                  </IconButton>
                )
              }
              title={title}
              subheader={subheader}
              subheaderTypographyProps={{ variant: 'caption' }}
            />
            {onClick && (
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                    onClick();
                  }}
                >
                  {linkText}
                </Button>
              </CardActions>
            )}
          </Card>
        </div>
      }
    >
      {anchorEl ?? (
        <IconButton
          aria-haspopup="true"
          aria-label="info"
          className={classes.iconButton}
          size="small"
          data-testid="open"
        >
          <IconSure icon="info-circle" size="20" type="solid" color={tooltipColor} />
        </IconButton>
      )}
    </HtmlTooltip>
  );
});
