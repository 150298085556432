import { IconButton, Theme, makeStyles } from '@esure-cloud/react-components';

import Badge from '@material-ui/core/Badge';
import ChatIcon from '@material-ui/icons/Chat';

import { track } from '../../lib/optimizely';
import { useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';

import { CHATBOT_WRAPPER_ZINDEX } from './chatBotWidget/chatBotWidget';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    '& span': {
      bottom: theme.spacing(9),
      color: theme.palette.secondary.contrastText,
      position: 'fixed',
      right: theme.spacing(3.5),
      // Badge needs to have 1 higher zindex
      zIndex: CHATBOT_WRAPPER_ZINDEX + 1,
    },
  },
  iconColor: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    bottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1.5),
    position: 'fixed',
    right: theme.spacing(1),
    zIndex: CHATBOT_WRAPPER_ZINDEX,
  },
}));

export const ChatMinimised: React.FC<{ hasNotification?: boolean }> = ({ hasNotification }) => {
  const classes = useStyles();
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
  } = useStores();
  const { eventTrack } = useSegment();

  const handleHelp = () => {
    track('My_Account_minimised_icon_click');
    eventTrack('Minimised Button Clicked');
    startChat({ initiator: 'Minimised Button Clicked' });
  };

  return (
    <IconButton edge="end" onClick={handleHelp} data-testid="chat-minimised-button" className={classes.iconColor}>
      <Badge
        overlap="circular"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent={'1'}
        color="error"
        className={classes.badge}
        invisible={!hasNotification}
      >
        <ChatIcon fontSize="large" color="secondary" />
      </Badge>
    </IconButton>
  );
};
