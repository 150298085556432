import { Checkbox, FormControlLabel, FormHelperText, Grid, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IModification, IRenewalModification } from '../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../service/state/models/dashboard/lookups';
import IconSure from '../icon';

type UnifiedModification = IModification | IRenewalModification;

interface ModificationsComponentProps {
  classes: { root: string };
  handleChangeCheckbox: (item: ILookupItem) => void;
  handleChat: () => void;
  isSubmitting: boolean;
  options: { selections: UnifiedModification[] };
  showTitle: boolean | undefined;
  updatedModifications: ILookupItem[] | undefined;
}

const isModificationSelected = (modification: UnifiedModification, code: string): boolean => {
  return 'modificationCode' in modification
    ? modification.modificationCode === code
    : modification.modificationCd === code;
};

const ModificationsComponent: React.FC<ModificationsComponentProps> = ({
  showTitle,
  updatedModifications,
  options,
  isSubmitting,
  handleChat,
  handleChangeCheckbox,
  classes,
}) => {
  const { t } = useTranslation('myAccount');

  return (
    <Grid className={classes.root}>
      {showTitle && (
        <Grid container item direction="column" className="modifications__header-container">
          <Typography variant="h3">{t('MTAJourney.CarModified.modifications.title')}</Typography>
        </Grid>
      )}
      <>
        {updatedModifications?.map((item, index) => (
          <Grid container item key={index}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.selections.findIndex((sel) => isModificationSelected(sel, item.code)) !== -1}
                    className={clsx('checkbox', 'invert')}
                    color="secondary"
                    onChange={() => handleChangeCheckbox(item)}
                  />
                }
                label={item.value}
              />
            </Grid>
          </Grid>
        ))}
      </>
      {!options.selections.length && isSubmitting && (
        <FormHelperText error>{t('validations.noSelectionCheckbox')}</FormHelperText>
      )}

      <Grid container direction="row" className="chat-section">
        <Typography>{t('MTAJourney.CarModified.cannotFind')}</Typography>
        <IconSure icon="chat" color="secondary" type="solid" />
        <Link className="chat-section__link" onClick={handleChat}>
          <Typography>{t('MTAJourney.CarModified.chat')}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ModificationsComponent;
