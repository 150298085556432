import { Instance, applySnapshot, cast, clone, getSnapshot, types } from 'mobx-state-tree';

import {
  IAutoPolicyRenewalQuoteDetails,
  IBaseCoverage,
  IRenewalModification,
  IRenewalPendingMTAQuote,
  PersonRenewal,
  RenewalDriver,
  RenewalInsured,
  RenewalVehicle,
} from '../dashboard/autoPolicyModel';
import { IDeviceCheck } from '../mta/pendedMTA';

const numberNull = types.maybeNull(types.number);

export const RenewalDetails = types
  .model('RenewalDetails', {
    // deviceCheck: types.optional(DeviceCheck, () => DeviceCheck.create()),
    changedDrivers: types.optional(types.array(types.string), []),
    drivers: types.optional(types.array(RenewalDriver), () => []),
    endorsementReasons: types.optional(types.array(types.string), []),
    insured: types.optional(RenewalInsured, () => RenewalInsured.create()),
    rateQuote: types.optional(types.boolean, false),
    removedDrivers: types.optional(types.array(types.string), []),
    selectedDriverUUID: types.optional(types.string, ''),
    vehicle: types.optional(RenewalVehicle, () => RenewalVehicle.create()),
    version: numberNull,
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
  }))
  .views((self) => ({
    driverFullName(driver: IRenewalDriver): string {
      return `${driver.person.firstName} ${driver.person.surname}`;
    },
    get selectedDriver(): IRenewalDriver {
      return self.drivers.find((driver) => driver.uuid === self.selectedDriverUUID) ?? RenewalDriver.create();
    },
    get vehicleCoverages(): IBaseCoverage[] {
      return [...self.vehicle.coverages, ...self.vehicle.subCoverages];
    },
    get vehicleDetails(): string {
      const { make, model } = self.vehicle;
      return `${make} ${model}`;
    },
  }))
  .actions((self) => ({
    setDrivers(drivers: IRenewalDriver[]) {
      self.drivers.replace(drivers);
    },
  }))
  .actions((self) => ({
    addEndorsementReason(value: string) {
      let reasons: string[] = self.endorsementReasons;
      if (reasons.indexOf(value) === -1) {
        reasons = [...self.endorsementReasons, value];
      }
      self.endorsementReasons.replace(reasons);
    },
    addRenewalQuoteDetails(policyRenewalQuoteDetails: IAutoPolicyRenewalQuoteDetails | IRenewalPendingMTAQuote) {
      const drivers: IRenewalDriver[] = [];
      policyRenewalQuoteDetails.drivers.forEach((driver) => {
        drivers.push(clone(driver));
      });
      self.setDrivers(drivers);
      self.insured = clone(policyRenewalQuoteDetails.insured);
      self.vehicle = clone(policyRenewalQuoteDetails.vehicle);
    },
    clearEndorsementReasons() {
      self.endorsementReasons.replace([]);
    },
    clearStore() {
      applySnapshot(self, self.initialState);
    },
    setChangedDrivers(value: string[]) {
      self.changedDrivers = cast(value);
    },
    setExistingCarUsage(vehicle: IRenewalVehicle) {
      const {
        classOfUseCd,
        legalOwnerCd,
        overnightLocationCd,
        registeredKeeperCd,
        daytimeLocationCd,
        overnightPostcode,
        sameOvernightAddress,
        businessMileageCd,
        socialMileageCd,
      } = vehicle;

      self.vehicle = {
        ...self.vehicle,
        businessMileageCd,
        classOfUseCd,
        daytimeLocationCd,
        legalOwnerCd,
        overnightLocationCd,
        overnightPostcode,
        registeredKeeperCd,
        sameOvernightAddress,
        socialMileageCd,
      };
    },
    setHasModifications(value: boolean) {
      if (!value) {
        self.vehicle.modifications.replace([]);
      }
      self.vehicle.carModifications = value;
    },
    setInsured(insured: IRenewalInsured) {
      self.insured = insured;
    },
    setInsuredAddress(address: IRenewalInsured['address']) {
      self.insured.address = address;
    },
    setInsuredPerson(person: IRenewalInsured['person']) {
      self.insured.person = person;
    },
    setModifications(value: IRenewalModification[]) {
      self.vehicle.modifications.replace(value);
    },
    setRemovedDrivers(value: string[]) {
      self.removedDrivers = cast(value);
    },
    setSelectedDriver(updatedDriver: IRenewalDriver) {
      const index = self.drivers.findIndex((driver) => driver.uuid === self.selectedDriverUUID);
      self.drivers[index] = updatedDriver;
    },
    setSelectedDriverUUID(uuid: string) {
      self.selectedDriverUUID = uuid;
    },
    setVehicle(vehicle: IRenewalVehicle) {
      self.vehicle = vehicle;
    },
    setVrm(vrm: string) {
      self.vehicle.vrm = vrm;
    },
  }));

export type IRenewalDetails = Instance<typeof RenewalDetails>;
export type IRenewalDriver = Instance<typeof RenewalDriver>;
export type IRenewalVehicle = Instance<typeof RenewalVehicle>;
export type IRenewalPerson = Instance<typeof PersonRenewal>;
export type IRenewalInsured = Instance<typeof RenewalInsured>;
export interface RenewalQuotePayload {
  deviceCheck?: IDeviceCheck;
  drivers: IRenewalDriver[];
  endorsementReasons: string[];
  insured: IRenewalInsured;
  rateQuote: boolean;
  usePendingMTAQuote: boolean;
  vehicle: IRenewalVehicle;
}
