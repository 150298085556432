import { Instance, types } from 'mobx-state-tree';

export const BillingAddress = types
  .model('BillingAddress', {
    country: types.maybeNull(types.string),
    county: types.maybeNull(types.string),
    houseName: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    locality: types.maybeNull(types.string),
    organisation: types.maybeNull(types.string),
    postalTown: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    subPremises: types.maybeNull(types.string),
    udprn: types.maybeNull(types.string),
    uprn: types.maybeNull(types.string),
  })
  .views((self) => ({
    get firstLineOfAddress() {
      return `${self.subPremises ?? ''} ${self.houseName ?? ''} ${self.houseNumber ?? ''}`.trim();
    },
  }));

export type IBillingAddress = Instance<typeof BillingAddress>;
