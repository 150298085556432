import { Accordion, AccordionSummary, Box, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { RenewalsDocumentsBtn } from '../../../../../../component/common/button/renewalsDocumentsBtn';
import CommonAccordion from '../../../../../../component/common/commonAccordion';
import { ConfirmationMessage } from '../../../../../../component/common/confirmationMessage';
import IconSure from '../../../../../../component/common/icon';
import { IconListSection } from '../../../../../../component/common/iconListSection';
import { EmptyState } from '../../../../../../component/layout/emptyState';
import { PolicyCard } from '../../../../../../component/layout/policyCards';
import { DATE_TYPES, DIALOG_TYPE, ROUTE } from '../../../../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';
import { useRenewalDocumentsFetch } from '../../../../../../service/util/customHooks/useRenewalDocumentsFetch';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';
import { MonthlyPaymentCard } from '../components/monthlyPaymentCard';
import { RenewalCostBreakDown } from '../components/renewalCostBreakDown';
import RenewalCostCard from '../components/renewalCostCard';
import { RenewalDocuments } from '../components/renewalDocuments';
import { RenewalQuoteQuestionItem, RenewalQuoteQuestions } from '../components/renewalQuoteQuestions';
import { WhatsNextBox } from '../components/whatsNextBox';
import { useHomeStyles } from '../home/homeRenewalQuote';

export const renewalQuoteUseStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .renewals': {
      '&__banner': {
        '&-title': {
          marginTop: spacing(5),
        },
        backgroundColor: palette.secondary.main,
        borderColor: palette.secondary.main,
        [breakpoints.down('xs')]: {
          borderRadius: 0,
          margin: spacing(-4, -2, 0, -2),
        },
        [breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        color: palette.secondary.contrastText,
      },
      '&__buttons-container': {
        '& .MuiButton-root': {
          width: '311px',
        },
        marginTop: spacing(2),
        width: '100%',
        [breakpoints.down('sm')]: {
          '& .MuiButton-root': {
            width: '100%',
          },
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '&__carmake': {
        marginTop: spacing(5),
      },
      '&__costs': {
        display: 'flex',
        flexFlow: 'nowrap',
        gap: '15px',
        justfifyContent: 'space-between',
        padding: spacing(5, 0, 2, 0),
        [breakpoints.down('sm')]: {
          flexFlow: 'wrap',
        },
      },
      '&__documents': {
        minWidth: '100%',
      },
      '&__iconMargin': {
        marginRight: spacing(2),
      },
      '&__included': {
        padding: spacing(1, 2),
      },
      '&__needhelp': {
        gap: spacing(2),
        marginTop: spacing(6),
      },
      '&__notification': {
        backgroundColor: `${palette.primary.light}20`,
        border: `1px solid ${palette.primary.light}`,
        borderRadius: spacing(1),
        marginTop: spacing(2),
        padding: spacing(2),
      },
      '&__question': {
        '&-lists': {
          marginTop: spacing(2),
        },
        paddingTop: spacing(6),
      },
      '&__subtext': {
        marginBottom: spacing(5),
        marginTop: spacing(3),
      },
      '&__subtitle': {
        marginTop: spacing(4),
      },
    },
  },
}));

export interface AccordionRow {
  hasButton: boolean;
  label: string;
  segmentStep: string;
  value: string;
}
interface HappyToGoAheadProps {
  isDDCustomer: boolean;
}
interface IncludedItems {
  content: string;
  title: string;
}

const IncludedAsStandard: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const includedItemsList: IncludedItems[] = t('policyManagement.yourRenewalQuote.includedStandardRenewal', {
    returnObjects: true,
  });
  return (
    <Grid container>
      {includedItemsList.map((item) => {
        return (
          <Grid item container className="renewals__included" key={item.title} wrap="nowrap">
            <Grid item className="renewals__iconMargin">
              <IconSure icon="check" color="success" />
            </Grid>
            <Grid item>
              <Grid item>
                <Typography variant="h5">{item.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{item.content}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const HappyToGoAhead: React.FC<HappyToGoAheadProps> = observer(({ isDDCustomer }) => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber, expirationDate, autoRenewal, autoRenewalOption },
        },
      },
    },
    interfaceStore: { isMobile, isTablet },
  } = useStores();

  const classes = useHomeStyles();
  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { eventTrack } = useSegment();

  const expiryDate = formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT_SPACE);
  const info = t(
    `policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.${
      isDDCustomer ? 'ddCustomer' : 'annualCustomer'
    }.mainInfo`,
    {
      cardInfo: autoRenewalOption.creditCardDetails.number?.slice(-4),
      renewalDate: formatDate(expiryDate, DATE_TYPES.SHORT_SPACE),
      returnObjects: true,
    },
  );

  const checks: { content: string }[] = t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOff.checks', {
    returnObjects: true,
  });

  const handlePay = () => {
    eventTrack('Motor Renewal Quote - continue button pressed', {
      policyNumber,
      type: TRACK_TYPE.VIEW,
    });

    if (!isDDCustomer) {
      setIsSubmitting(true);
      if (confirmed) {
        history.push(ROUTE.RENEWAL_PAYMENT);
      }
    } else {
      history.push(ROUTE.YOUR_RENEWAL_SUMMARY);
    }
  };

  const handleChangeAutoRenew = () => {
    history.push(ROUTE.YOUR_RENEWAL_DETAILS);
  };

  return autoRenewal ? (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      <Typography variant="body1">
        <Trans i18nKey={info} t={t} components={[<strong key={Symbol().toString()} />]} />
      </Typography>
      <Typography variant="h3">
        {t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.dontAutoRenewTitle')}
      </Typography>
      <Typography variant="body1">
        <Trans
          i18nKey={'policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.dontAutoRenewInfoP1'}
          t={t}
          components={[
            <Link
              className={classes.link}
              underline="none"
              color="secondary"
              onClick={handleChangeAutoRenew}
              data-testid="auto-renewal-link-orig-motor-renewal-quote"
            />,
          ]}
        />
      </Typography>
      <Typography variant="body1">
        {t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.dontAutoRenewInfoP2')}
      </Typography>
    </Grid>
  ) : (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      <Typography>
        {t(
          `policyManagement.homeRenewalQuote.whatsNext.happy.${
            isDDCustomer ? 'ddCustomer' : 'annualCustomer'
          }.nonAutoRenewal.mainInfo`,
        )}
      </Typography>
      {!isDDCustomer && (
        <>
          <IconListSection color="success" icon="check" list={checks} />
          <ConfirmationMessage
            hasError={!confirmed && isSubmitting}
            checked={confirmed}
            setChecked={setConfirmed}
            message={t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOff.confirmation')}
            isRenewalMessage={true}
          />
        </>
      )}
      <Grid item xs={isMobile || isTablet ? 12 : 3}>
        <CommonButton
          text={isDDCustomer ? t('MTAJourney.button.continue') : t('MTAJourney.button.continueToPay')}
          size="large"
          xl
          onClick={handlePay}
        ></CommonButton>
      </Grid>
    </Grid>
  );
});

export const OriginalMotorRenewalQuote: React.FC = observer(() => {
  const { hasFetched, hasDocsLoadingFailed } = useRenewalDocumentsFetch();
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
    interfaceStore: {
      hasFeatureFlag,
      dialog: { openDialog },
      chatBot: { startChat },
    },
  } = useStores();
  const { eventTrack } = useSegment();
  const {
    vehicles,
    policyNumber,
    policyRenewalQuoteDetails: {
      apr,
      numberOfInstallments,
      policyCostWithoutInterest,
      previousYearMonthlyCost,
      previousYearTotalCost,
      currentYearMonthlyCost,
      currentYearTotalCost,
      inceptionDate,
      installmentSchedule,
      premiumBreakdown: { interest },
    },
    paymentPlan,
  } = policySelected;
  const { t } = useTranslation('myAccount');

  const classes = renewalQuoteUseStyles();
  const homeClasses = useHomeStyles();
  const loyaltyYears = inceptionDate
    ? new Date(new Date().valueOf() - inceptionDate.valueOf()).getFullYear() - 1970
    : null;
  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment' && hasFeatureFlag('BCCJM-9000');
  const questions: RenewalQuoteQuestionItem[] = t('policyManagement.yourRenewalQuote.faqList', {
    returnObjects: true,
  });

  const getLoyaltyText = (loyaltyYears: number) => {
    switch (true) {
      case loyaltyYears < 4:
        return t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextunder4years');
      case loyaltyYears >= 4:
        return t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextover4years');
    }
  };

  useEffect(() => {
    eventTrack('Renewal-quote-page-shown', {
      policyNumber,
      position: 'Renewal-quote-page-shown',
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  return (
    <Grid className={clsx('renewals', classes.root)}>
      <BlueBanner title={t('policyManagement.yourRenewalQuote.title')} route={ROUTE.POLICY_DETAILS} />
      <Typography className={homeClasses.title} variant="h1">
        {t('policyManagement.yourRenewalQuote.subtitle')}
      </Typography>
      <PolicyCard policy={policySelected} isPolicyHeader isRenewalHeader />
      <Grid container item className="renewals__costs">
        <RenewalCostCard
          hasTooltip={false}
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleCurrentYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? currentYearMonthlyCost : currentYearTotalCost,
          })}
          showDD={isDDCustomer}
          ddValue={t('core:price.pound', {
            value: currentYearTotalCost,
          })}
        />
        <RenewalCostCard
          hasTooltip
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleLastYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? previousYearMonthlyCost : previousYearTotalCost,
          })}
          ddValue={t('core:price.pound', {
            value: previousYearTotalCost,
          })}
          showDD={isDDCustomer}
        />
      </Grid>
      {isDDCustomer && hasFeatureFlag('BCCJM-9000') && (
        <>
          <MonthlyPaymentCard
            currentYearMonthlyCost={currentYearMonthlyCost}
            installmentSchedule={installmentSchedule}
            numberOfInstallments={numberOfInstallments}
            startDate={installmentSchedule.length ? installmentSchedule[0].dueDate : ''}
          />
          <RenewalCostBreakDown
            apr={apr}
            policyCost={currentYearTotalCost}
            policyInterest={interest}
            policyCostWithoutInterest={policyCostWithoutInterest}
          />
        </>
      )}
      <Typography variant="body1EB" className="renewals__subtext">
        {loyaltyYears ? getLoyaltyText(loyaltyYears) : ''}
      </Typography>
      <CommonAccordion
        customComponent={<IncludedAsStandard />}
        expanded={false}
        headerIcon="car-renew"
        headerIconType="solid"
        headerTitle={t('policyManagement.yourRenewalQuote.standard')}
        defaultBehaviour={false}
        onChange={() => {
          eventTrack(`Renewal-what-is-covered-chevron`, {
            position: 'Renewal-what-is-covered-chevron',
            type: TRACK_TYPE.ACCORDION,
          });
        }}
      />
      <Grid container className="renewals__carmake">
        <Typography variant="h2">{t('policyManagement.yourRenewalQuote.whatNext')}</Typography>
        <Grid item xs={12} className="renewals__subtitle">
          <Typography>
            {t('policyManagement.yourRenewalQuote.carMake', {
              value: vehicles[0].make,
            })}
          </Typography>
        </Grid>
      </Grid>

      <WhatsNextBox
        number="1"
        title={t('policyManagement.homeRenewalQuote.whatsNext.check.title')}
        description={t('policyManagement.homeRenewalQuote.whatsNext.check.description')}
      >
        {!hasFetched ? (
          <></>
        ) : hasDocsLoadingFailed ? (
          <Box py={2}>
            <EmptyState title={t('docsFailedLoading.title')} content={t('docsFailedLoading.content')} />
          </Box>
        ) : (
          <Grid item xs={12} className="renewals__documents">
            <Grid className="renewals__buttons-container">
              <RenewalsDocumentsBtn
                policy={policySelected}
                fileName={t('policyManagement.yourRenewal.scheduleOfInsurance')}
                size="large"
              />
            </Grid>

            <Grid container direction="column" wrap="nowrap" className={homeClasses.accordionContainer}>
              <Accordion
                onChange={() => {
                  eventTrack(`Renewal-show-all-renewal-docs-expanded`, {
                    position: 'Renewal-show-all-renewal-docs-expanded',
                    type: TRACK_TYPE.ACCORDION,
                  });
                }}
              >
                <AccordionSummary expandIcon={<IconSure color="secondary" icon="chevron-down" type="line" size="26" />}>
                  <Typography variant="body1" color="secondary">
                    {t('policyManagement.yourRenewalQuote.showRenewalDocument')}
                  </Typography>
                </AccordionSummary>
                <RenewalDocuments />
              </Accordion>
            </Grid>

            <Grid>
              <Typography variant="h3">
                {t('policyManagement.motorRenewalQuote.checkDetails.updatePolicy.title')}
              </Typography>
              <Typography variant="body1">
                <Trans i18nKey="policyManagement.motorRenewalQuote.checkDetails.updatePolicy.description" t={t}>
                  <Link
                    underline="none"
                    variant="body1"
                    color="secondary"
                    onClick={() => {
                      eventTrack('Renewal-need-help-chat-with-us-link-clicked', {
                        policyNumber: policySelected.policyNumber,
                        position: 'Renewal-need-help-chat-with-us-link-clicked',
                        type: TRACK_TYPE.LINK,
                      });
                      openDialog({
                        title: t('fetchAdviserPopup.autoRenewalOnChatWithUs.title'),
                        type: DIALOG_TYPE.FETCH_ADVISER_RENEWAL_QUOTE,
                      });
                    }}
                  />
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        )}
      </WhatsNextBox>

      <WhatsNextBox number="2" title={t('policyManagement.motorRenewalQuote.renewalUpdate.title')}>
        <Typography variant="body1">
          <Trans
            i18nKey="policyManagement.motorRenewalQuote.renewalUpdate.description"
            t={t}
            components={[
              <strong key={Symbol().toString()} />,
              <Link
                className={homeClasses.link}
                data-testid="chat-link"
                underline="none"
                variant="body1"
                color="secondary"
                onClick={() =>
                  startChat({
                    contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
                    customContactAttributes: {
                      ssRenQuoteUpdatePol: 'true',
                    },
                    initiator: 'Chat with us',
                  })
                }
              />,
            ]}
          />
        </Typography>
        <Grid item xs={12} className="renewals__documents">
          <Grid className="renewals__buttons-container">
            <RenewalsDocumentsBtn
              policy={policySelected}
              fileName={t('policyManagement.motorRenewalQuote.renewalUpdate.buttonText')}
              size="large"
            />
          </Grid>
        </Grid>
      </WhatsNextBox>

      <WhatsNextBox
        number="3"
        title={t('policyManagement.homeRenewalQuote.whatsNext.happy.title')}
        description={t(`policyManagement.motorRenewalQuote.happyToGoAhead.description`)}
      >
        <HappyToGoAhead isDDCustomer={isDDCustomer} />
      </WhatsNextBox>

      <RenewalQuoteQuestions
        header={t('policyManagement.yourRenewalQuote.gotQuestion')}
        subTitle={t('policyManagement.yourRenewalQuote.gotQuestionSubTitle')}
        questions={questions}
      />
    </Grid>
  );
});
