import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CarModifiedQuestion } from '../../../../../../../../component/common/carModifiedQuestion';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { IRenewalModification } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

import { CarModificationsRenewal } from './carModificationsRenewal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& .error': {
      color: palette.error.dark,
    },
    '& .radio-section': {
      display: 'grid',
      gap: 20,
      width: 'fit-content',
    },
  },
}));

export const CarModifiedRenewal: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { nextStep, setTitle } = useRenewalStepperContext();

  const {
    renewalChangeStore: {
      renewalDetails: { setModifications, vehicle, setHasModifications },
    },
  } = useStores();
  const [state, setState] = useState<{ selections: IRenewalModification[] }>({
    selections: [...vehicle.modifications],
  });

  const [wasCarModified, setWasCarModified] = useState<null | boolean>(vehicle.carModifications);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { eventTrack } = useSegment();

  useEffect(() => {
    setTitle(t('MTAJourney.CarModified.title'));
    eventTrack('Modifications screen viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'true';
    setWasCarModified(value);
  };
  const isValid = (): boolean => {
    return wasCarModified === null || (wasCarModified && state.selections.length === 0) ? false : true;
  };
  const handleRenewalChange = () => {
    setIsSubmitting(true);
    if (isValid()) {
      setModifications(state.selections);
      if (wasCarModified !== null) {
        setHasModifications(wasCarModified);
      }
    }
  };

  const handleContinue = () => {
    handleRenewalChange();
    isValid() && nextStep();
  };

  return (
    <Grid container item direction="column" className={classes.root}>
      <CarModifiedQuestion
        handleChangeRadio={handleChangeRadio}
        isSubmitting={isSubmitting}
        wasCarModified={wasCarModified}
      />

      {wasCarModified && (
        <CarModificationsRenewal isSubmitting={isSubmitting} options={state} setOptions={setState} showTitle />
      )}
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_CAR_MODIFIED_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_CAR_MODIFIED_CANCEL}
        primaryButton={{
          buttonId: COMPONENT_ID.RENEWAL_CAR_MODIFIED_SAVE,
          handleContinue,
        }}
      />
    </Grid>
  );
});
