import Cookies from 'js-cookie';

import {
  MESSAGE_TYPE_BECAME_ACTIVE,
  MESSAGE_TYPE_CHECK_READY,
  MESSAGE_TYPE_TOKEN_RESULT,
  NATIVE_APP_BOTTOM_SAFE_AREA_FIELD_NAME,
  NATIVE_APP_COOKIE_NAME,
  NATIVE_APP_PLATFORM_FIELD_NAME,
  NATIVE_APP_TOP_SAFE_AREA_FIELD_NAME,
  NATIVE_APP_VERSION_FIELD_NAME,
  NATIVE_HOOK_TYPE_CLOSE,
  NATIVE_HOOK_TYPE_DOWNLOAD_FILE,
  NATIVE_HOOK_TYPE_GET_TOKEN,
  NATIVE_HOOK_TYPE_OPEN_IN_BROWSER,
  NATIVE_HOOK_TYPE_READY,
} from './constants';

/**
 * Caura JavaScript Bridge interface
 * Read CAURA-README.md for example usages
 */

/**
 * Sending Messages to Native App
 */
export interface INativeHookArg {
  [key: string]: string | Record<string, string>;
  type: string;
}

export type INativeHookResult = Promise<{
  error?: string;
  status: 'success' | 'failed';
  type: string;
}>;
export type INativeHookFunction = (arg: INativeHookArg) => INativeHookResult;

/**
 * Registering Handlers for Incoming Messages
 */
export interface IIncomingMessageResult {
  error?: string;
  status: 'success' | 'failed';
  type: string;
}
export type IIncomingMessageFunctionValueHandler = (value: string) => void;
export type IIncomingMessageFunction = (
  type: string,
  handler: IIncomingMessageFunctionValueHandler,
) => Promise<IIncomingMessageResult>;

/**
 * Unregister Handler for Incoming Messages
 */
export type IUnregisterHandlerFunction = (type: string) => void;

/**
 * Unregister all Handlers
 */
export type IUnregisterHandlerFunctions = () => void;

export interface ICauraBridge {
  nativeHook: INativeHookFunction;
  registerIncomingHandler: IIncomingMessageFunction;
  resetBridge: IUnregisterHandlerFunctions;
  unregisterIncomingHandler: IUnregisterHandlerFunction;
}

export interface CauraCookie {
  appVersion: string;
  platform: string;
  safeAreaBottom: string;
  safeAreaTop: string;
}

declare global {
  interface Window {
    CauraBridge: ICauraBridge;
  }
}

let token = '';

export const setToken = (newValue: string) => (token = newValue);
export const getToken = () => token;

/**
 * In some cases it's impossible to rely on mobx store
 */
export const isCauraRoute = () => {
  const { search } = window.location;
  return hasIsCauraSearchParam(search);
};
export const hasIsCauraSearchParam = (search: string) => search && decodeURIComponent(search).includes('isCaura=true');

export const messageCheckReadyHandler = () => {
  if (token) {
    void sendReadyHook();
  }
};

/**
 * Once native app is opened while being in background this is called to refresh/fetch data
 * Not used for now
 */
export const messageBecameActiveHandler = () => {
  if (token) {
    void sendReadyHook();
  }
};

/**
 * Called on /flex-customers entry point, handles token logic and other things like opening previously minimalized app or refreshing token
 * NATIVE_HOOK_TYPE_GET_TOKEN must be called to receive token from native mobile app
 * @param onTokenUpdate
 */
export const initializeBridge = async (onTokenUpdate: () => void) => {
  return await new Promise<void>((resolve) => {
    if (!isCauraRoute()) {
      resolve();
      return;
    }

    window.CauraBridge.registerIncomingHandler(MESSAGE_TYPE_CHECK_READY, messageCheckReadyHandler);
    window.CauraBridge.registerIncomingHandler(MESSAGE_TYPE_BECAME_ACTIVE, messageBecameActiveHandler);

    window.CauraBridge.registerIncomingHandler(MESSAGE_TYPE_TOKEN_RESULT, (cauraBridgeToken) => {
      token = cauraBridgeToken;
      onTokenUpdate();
      resolve();
    });

    window.CauraBridge.nativeHook({ type: NATIVE_HOOK_TYPE_GET_TOKEN });
  });
};

/**
 * Send ready hook once app is ready to being displayed (removes native app loader)
 */
export const sendReadyHook = () => window.CauraBridge.nativeHook({ type: NATIVE_HOOK_TYPE_READY });

/**
 * Send close WebView hook to native app
 */
export const sendCloseHook = () => window.CauraBridge.nativeHook({ type: NATIVE_HOOK_TYPE_CLOSE });

/**
 * Not needed for now
 */
export const resetBridge = () => window.CauraBridge.resetBridge();

/**
 * Opens default mobile browser as handling multiple tabs in WebView is very problematic
 * @param notValidatedUrl
 */
export const sendOpenInBrowserHook = (notValidatedUrl: string) => {
  // for some weird reason we store footers links without https: prefix, simply add https: if url starts with //
  const url = notValidatedUrl.startsWith('//') ? `https:${notValidatedUrl}` : notValidatedUrl;
  window.CauraBridge.nativeHook({ type: NATIVE_HOOK_TYPE_OPEN_IN_BROWSER, url });
};

/**
 * While being hosted by native mobile app WebView downloading files is very restricted for security reasons
 * For iOS downloading files is not even possible in WebView
 * For Android there is something called DownloaderManager, but its problematic and differs in implementation for every mobile brand and even mobile device itself
 * @param url
 * @param fileName
 * @param headers
 */
export const sendDownloadFileHook = (url: string, fileName: string, headers: Record<string, string>) => {
  window.CauraBridge.nativeHook({ fileName, headers, type: NATIVE_HOOK_TYPE_DOWNLOAD_FILE, url });
};

/**
 * Caura cookie is injected into WebView to pass nessesary informations
 */
export const getCauraCookie = (): CauraCookie => {
  const cauraCookieRaw = Cookies.get(NATIVE_APP_COOKIE_NAME) as string;
  let cauraCookie: CauraCookie | undefined;
  try {
    cauraCookie = JSON.parse(cauraCookieRaw) as CauraCookie;
  } catch (error) {
    return {
      appVersion: '',
      platform: '',
      safeAreaBottom: '',
      safeAreaTop: '',
    };
  }
  return cauraCookie;
};

/**
 * Native app version
 */
export const getAppVersion = (): string => getCauraCookie()[NATIVE_APP_VERSION_FIELD_NAME];

/**
 * Returns native app platform, iOS/Android
 */
export const getPlatform = (): string => getCauraCookie()[NATIVE_APP_PLATFORM_FIELD_NAME];

/**
 * In WebView this means top margin for app, for example we have status icons (battery, wifi etc) and that shouldn't be covered by app
 */
export const getSafeAreaTop = (): string => getCauraCookie()[NATIVE_APP_TOP_SAFE_AREA_FIELD_NAME];

/**
 * In WebView this means bottom margin for app, for example we have navigation buttons on Android that shouldn't be covered by app
 */
export const getSafeAreaBottom = (): string => getCauraCookie()[NATIVE_APP_BOTTOM_SAFE_AREA_FIELD_NAME];
