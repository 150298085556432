import { Divider, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { LOOKUP, PACKS } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../../service/util/customHooks/useDecodedLookups';
import SelfService from '../../../../../../service/util/selfService';

export interface SummaryItem {
  code: string;
  name: string;
  price: number;
}

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
  root: {
    '& .summary': {
      '&-content': {
        '&__item': {
          gap: 30,
        },
        gap: 8,
      },
      '&-divider': {
        width: '100%',
      },
      '&-footer': {
        '&__total': {
          maxWidth: 150,
          textAlign: 'right',
        },
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      backgroundColor: palette.grey[100],
      borderRadius: 8,
      gap: 25,
      maxWidth: 400,
      padding: spacing(2),
    },
    gap: 15,
  },
  tableContainer: {
    border: `1px solid ${palette.secondary.main}50`,
    borderRadius: shape.borderRadius,
  },
}));

export const PremiumDetails: React.FC<{ total?: number }> = observer(function PremiumDetails({ total }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ options: ['vehicle'] });

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected,
          policyCover: {
            basePack,
            lifeStylePacks,
            breakdownCover,
            motorLegalProtection,
            upgradedCourtesyCar,
            ncdpPack,
          },
        },
      },
      lookups: { getLookupValue },
    },
  } = useStores();

  const summaryText = (code?: string) => {
    switch (code) {
      case PACKS.RESRV11:
      case PACKS.RESRV12:
        return t('payments.summary.packs.breakdown1');
      case PACKS.RESRHV11:
      case PACKS.RESRHV12:
        return t('payments.summary.packs.breakdown2');
      case PACKS.RESROV11:
      case PACKS.RESROV13:
        return t('payments.summary.packs.breakdown3');
      case PACKS.BASE_FLEX_COMP:
      case PACKS.BASE_TIA_COMP:
        return t('payments.summary.packs.yourPolicy');
      default:
        break;
    }
  };

  const summary = [...lifeStylePacks, motorLegalProtection, breakdownCover, ncdpPack, upgradedCourtesyCar].reduce(
    (acc: SummaryItem[], item) => {
      if (item) {
        const { coverageCd, gwtAnnualPremium } = item;

        acc.push({
          code: coverageCd,
          name: summaryText(coverageCd) ?? getLookupValue(LOOKUP.COVERAGE_DESCRIPTION, coverageCd),
          price: gwtAnnualPremium ?? 0,
        });
      }
      return acc;
    },
    [
      {
        code: basePack?.coverageCd ?? '',
        name: summaryText(basePack?.coverageCd) ?? '',
        price: basePack?.gwtAnnualPremium ?? 0,
      },
    ],
  );

  return isDecodedLookupsFetched ? (
    <div className={classes.root}>
      <Grid className="summary" container item sm={12}>
        <Typography variant="h2">{t('payments.summary.title')}</Typography>

        <Grid className="summary-content" container direction="column">
          {summary.map(({ name, price, code }, index) => {
            return (
              <Grid
                alignItems="center"
                className="summary-content__item"
                container
                data-testid={code}
                item
                justifyContent="space-between"
                key={`${index}${name}`}
                wrap="nowrap"
              >
                <Typography variant="body1">{name}</Typography>
                <Typography variant="h4">{SelfService.toCurrency(price)}</Typography>
              </Grid>
            );
          })}
        </Grid>

        <Divider className="summary-divider" />

        <Grid className="summary-footer" container justifyContent="space-between">
          <Typography variant="h4">{t('payments.summary.total')}</Typography>

          <Grid className="summary-footer__total">
            <Typography variant="h2" data-testid="total-premium-amount">
              {total ? SelfService.toCurrency(total) : SelfService.toCurrency(policySelected.totalPremium)}
            </Typography>
            <Typography variant="body2">{t('payments.summary.tax')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );
});
