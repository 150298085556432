import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { DIALOG_TYPE, JOURNEY_TYPE, ROUTE } from '../../../../../../../service/constant';
import { useStores } from '../../../../../../../service/state/store';
import { CarFoundRenewal } from '../editDetails/steps/carFoundRenewal';
import { CarModifiedRenewal } from '../editDetails/steps/carModifiedRenewal';
import { CarOwnershipRenewal } from '../editDetails/steps/carOwnershipRenewal';
import { CarRegistrationRenewal } from '../editDetails/steps/carRegistrationRenewal';
import { CarsInHouseholdRenewal } from '../editDetails/steps/carsInHouseholdRenewal';
import { ChangeDriverNameRenewal } from '../editDetails/steps/changeDriverNameRenewal';
import { ChangeNameRenewal } from '../editDetails/steps/changeNameRenewal';
import { ChangeRelationshipStatusRenewal } from '../editDetails/steps/changeRelationshipStatusRenewal';
import { ChildrenInHouseholdRenewal } from '../editDetails/steps/childrenInHouseholdRenewal';
import { DateOfBirthRenewal } from '../editDetails/steps/dateOfBirthRenewal';
import { DaytimeLocationRenewal } from '../editDetails/steps/daytimeLocationRenewal';
import { DriverHistoryRenewal } from '../editDetails/steps/driverHistoryRenewal';
import { DriverLicenseRenewal } from '../editDetails/steps/driverLicenseRenewal';
import { DriverOccupationRenewal } from '../editDetails/steps/driverOccupationRenewal';
import { DriverRelationshipRenewal } from '../editDetails/steps/driverRelationshipRenewal';
import { HomeAddressRenewal } from '../editDetails/steps/homeAddressRenewal';
import { LegalOwnerRenewal } from '../editDetails/steps/legalOwnerRenewal';
import { MainDriverAddRenewal } from '../editDetails/steps/mainDriverAddRenewal';
import { MaritalStatusRenewal } from '../editDetails/steps/maritalStatusRenewal';
import { OvernightLocationRenewal } from '../editDetails/steps/overnightLocationRenewal';
import { RegisteredKeeperRenewal } from '../editDetails/steps/registeredKeeperRenewal';
import { RemoveDriverRenewal } from '../editDetails/steps/removeDriverRenewal';
import { ResidentialStatusRenewal } from '../editDetails/steps/residentialStatusRenewal';
import { SecurityFeaturesRenewal } from '../editDetails/steps/securityFeaturesRenewal';
import { UsageAndMilageRenewal } from '../editDetails/steps/usageAndMileageRenewal';
import { UseOfOtherCarsRenewal } from '../editDetails/steps/useOfOtherCarsRenewal';

interface Step {
  blueBannerTitle: Record<'default' | string, string>;
  journeyType: JOURNEY_TYPE;
  steps: React.ReactElement[];
}

interface GlobalStepsProps {
  [key: string]: Step;
}

export interface RenewalStepperContextProps {
  nextStep: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  step: number;
  stepsData: Step;
  title: string;
}

export const RenewalStepperContext = React.createContext<RenewalStepperContextProps>({
  nextStep: () => null,
  setStep: () => null,
  setTitle: () => null,
  step: 0,
  stepsData: {
    blueBannerTitle: { 0: '' },
    journeyType: JOURNEY_TYPE.NONE,
    steps: [],
  },
  title: '',
});

export const RenewalStepperProvider: React.FunctionComponent = observer(function RenewalStepperProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { t } = useTranslation('myAccount');
  const [title, setTitle] = useState('');
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    interfaceStore: {
      dialog: { openDialog, closeDialog },
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber },
        },
      },
    },
    renewalChangeStore: {
      getChangeMade,
      isLoading,
      saveChanges,
      nextRoute,
      pendingMTAQuoteSelected,
      renewalDetails: { endorsementReasons },
    },
  } = useStores();

  const defaultSteps: GlobalStepsProps = {
    [ROUTE.YOUR_COVER_DETAILS_CHANGE_REG]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeYourCar'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [<CarRegistrationRenewal />, <CarFoundRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_CHANGE_CAR]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeYourCar'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [
        <CarRegistrationRenewal />,
        <CarFoundRenewal />,
        <CarOwnershipRenewal />,
        <SecurityFeaturesRenewal />,
        <CarModifiedRenewal />,
      ],
    },
    [ROUTE.YOUR_COVER_DETAILS_CHANGE_MODIFICATIONS]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateCarModifications'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [<CarModifiedRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_CHANGE_SECURITY]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateSecurity'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [<SecurityFeaturesRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_LEGAL_OWNER]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateLegalOwner'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [<LegalOwnerRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_REGISTERED_KEEPER]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateRegisteredKeeper'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      steps: [<RegisteredKeeperRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeMileage'),
      },
      journeyType: JOURNEY_TYPE.CAR_MILEAGE_AND_USAGE,
      steps: [<UsageAndMilageRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_OVERNIGHT_LOCATION]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateParkingLocation'),
      },
      journeyType: JOURNEY_TYPE.OVERNIGHT_LOCATION,
      steps: [<OvernightLocationRenewal />],
    },
    [ROUTE.YOUR_COVER_DETAILS_DAYTIME_LOCATION]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateParkingLocation'),
      },
      journeyType: JOURNEY_TYPE.DAYTIME_LOCATION,
      steps: [<DaytimeLocationRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_NAME]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeYourName'),
      },
      journeyType: JOURNEY_TYPE.NAME,
      steps: [<ChangeNameRenewal firstNameDisabled={true} />],
    },

    [ROUTE.YOUR_COVER_DETAILS_DRIVER_NAME]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_DRIVER,
      steps: [<ChangeDriverNameRenewal firstNameDisabled={true} />],
    },

    [ROUTE.YOUR_COVER_DETAILS_RELATIONSHIP_STATUS]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeRelationshipStatus'),
      },
      journeyType: JOURNEY_TYPE.RELATIONSHIP_STATUS,
      steps: [<ChangeRelationshipStatusRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_HOME_ADDRESS]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeHomeAddress'),
      },
      journeyType: JOURNEY_TYPE.HOME_ADDRESS,
      steps: [
        <HomeAddressRenewal />,
        <ResidentialStatusRenewal />,
        <UsageAndMilageRenewal />,
        <DaytimeLocationRenewal />,
        <OvernightLocationRenewal />,
      ],
    },

    [ROUTE.YOUR_COVER_DETAILS_RESIDENTIAL_STATUS]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeResidentialStatus'),
      },
      journeyType: JOURNEY_TYPE.RESIDENTIAL_STATUS,
      steps: [<ResidentialStatusRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_CHILDREN_AT_HOME]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeChildrenInHousehold'),
      },
      journeyType: JOURNEY_TYPE.CHILDREN_AT_HOME,
      steps: [<ChildrenInHouseholdRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_CARS_AT_HOME]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeCarsInHousehold'),
      },
      journeyType: JOURNEY_TYPE.CARS_AT_HOME,
      steps: [<CarsInHouseholdRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_YOUR_LICENSE]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.DRIVER_LICENSE,
      steps: [<DriverLicenseRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_ADD_DRIVER]: {
      blueBannerTitle: {
        default: t('MTAJourney.addDriver'),
      },
      journeyType: JOURNEY_TYPE.ADD_DRIVER,
      steps: [
        <ChangeDriverNameRenewal firstNameDisabled={false} />,
        <DateOfBirthRenewal />,
        <MaritalStatusRenewal />,
        <DriverRelationshipRenewal />,
        <DriverOccupationRenewal />,
        <DriverLicenseRenewal />,
        <UseOfOtherCarsRenewal />,
        <DriverHistoryRenewal />,
        <MainDriverAddRenewal />,
      ],
    },

    [ROUTE.YOUR_COVER_DETAILS_REMOVE_DRIVER]: {
      blueBannerTitle: {
        default: t('MTAJourney.removeDriver.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.REMOVE_DRIVER,
      steps: [<RemoveDriverRenewal />, <MainDriverAddRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_MARITAL_STATUS]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.DRIVER_RELATIONSHIP,
      steps: [<MaritalStatusRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_DRIVER_RELATIONSHIP]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.DRIVER_RELATIONSHIP,
      steps: [<DriverRelationshipRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.DRIVER_OCCUPATION,
      steps: [<DriverOccupationRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_OCCUPATION]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.OCCUPATION,
      steps: [<DriverOccupationRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_DRIVER_LICENSE]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_DRIVER,
      steps: [<DriverLicenseRenewal />],
    },

    [ROUTE.YOUR_COVER_DETAILS_DRIVER_HISTORY]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeDriverDetails.blueBanner'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_DRIVER,
      steps: [<DriverHistoryRenewal />],
    },
  };
  const [currentSteps, setCurrentSteps] = useState(defaultSteps[ROUTE.YOUR_COVER_DETAILS_CHANGE_CAR]);

  useEffect(() => {
    setStep(0);
    if (Object.prototype.hasOwnProperty.call(defaultSteps, pathname)) {
      setCurrentSteps(defaultSteps[pathname]);
    }

    // if url is in defaultSteps AND endorsementReasons.length == 0 then redirect to YCD
    if (Object.keys(defaultSteps).includes(pathname) && endorsementReasons.length === 0) {
      history.push(ROUTE.YOUR_COVER_DETAILS);
    }
  }, [pathname]);

  useEffect(() => {
    if (nextRoute !== '') {
      history.push(nextRoute);
    }
  }, [nextRoute]);

  useEffect(() => {
    if (!isLoading) {
      closeDialog();
    }
  }, [isLoading]);

  const [step, setStep] = useState(0);

  const changeMade = getChangeMade(policyNumber);

  /* istanbul ignore next */
  const handleContinueButton = () => {
    if (step < currentSteps.steps.length - 1) {
      setStep(step + 1);
    } else if (step === currentSteps.steps.length - 1) {
      openDialog({ isFullScreen: true, type: DIALOG_TYPE.SAVE_CHANGES });
      saveChanges(policyNumber, false, changeMade || pendingMTAQuoteSelected);
    }
  };

  /// add journey modified flag in the store

  return (
    <RenewalStepperContext.Provider
      value={{
        nextStep: handleContinueButton,
        setStep,
        setTitle,
        step,
        stepsData: currentSteps,
        title,
      }}
    >
      {children}
    </RenewalStepperContext.Provider>
  );
});

export const useRenewalStepperContext: () => RenewalStepperContextProps = () => {
  return useContext(RenewalStepperContext);
};
