import { Autocomplete, Grid, TextField, Typography } from '@esure-cloud/react-components';

import { LOOKUP } from '../../service/constant';
import { MtaTooltip } from '../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';

export interface CarMileageProps {
  getLookup: (lookupName: LOOKUP, lookupCode: string) => string;
  helperText: string;
  mileageCode: string | null;
  mileageOptions: string[];
  placeholder: string;
  setMileageCode: (value: React.SetStateAction<string | null>) => void;
  tooltipDescription: string;
  tooltipTitle: string;
}

export const ChangeCarMileage: React.FC<CarMileageProps> = ({
  mileageOptions,
  getLookup,
  tooltipTitle,
  tooltipDescription,
  mileageCode,
  setMileageCode,
  helperText,
  placeholder,
}) => {
  return (
    <Grid item xs={12} md={4}>
      <Autocomplete
        disablePortal
        options={mileageOptions}
        getOptionLabel={(item) => getLookup(LOOKUP.MILEAGE, item)}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <MtaTooltip description={<Typography>{tooltipDescription}</Typography>} tooltipTitle={tooltipTitle} />
            }
            error={mileageCode === null}
            helperText={mileageCode === null ? helperText : ''}
            placeholder={placeholder}
          />
        )}
        onChange={(event, value: string | null) => {
          setMileageCode(value ?? null);
        }}
        value={mileageCode}
      />
    </Grid>
  );
};
