import { observer } from 'mobx-react-lite';
import Iframe from 'react-iframe';
import { useParams } from 'react-router-dom';

import { url } from '../../../../service/config';
import { useStores } from '../../../../service/state/store';
import { sendJwtToIframe } from '../../../../service/util/jwtUtils';

export const ActiveClaimSummary: React.FC = observer(() => {
  const { claimId } = useParams<{ claimId: string }>();
  const {
    interfaceStore: { accessToken },
  } = useStores();

  return (
    <>
      <Iframe
        url={`${url.claimDashboardUrl}/summary/${claimId}`}
        title="active-claim-summary-iframe"
        id="active-claim-summary-iframe"
        width="100%"
        height="100%"
        position="relative"
        frameBorder={0}
        onLoad={() => {
          sendJwtToIframe('active-claim-summary-iframe', accessToken);
        }}
      />
    </>
  );
});
