import { FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup } from '@esure-cloud/react-components';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import {
  IInsuredAddress,
  InsuredAddress,
} from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import SelfService from '../../../../../../../../service/util/selfService';
import { EditFooter } from '../../common/components/editFooter';
import { FindAddress } from '../../common/components/findAddress';
import { MTAFooter } from '../../common/components/footer';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

const castToNullIfUndefined = <T,>(variable: T): T | null => {
  if (typeof variable === 'undefined') return null;
  return variable;
};

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .overnight-address': {
      '&_title': {
        fontWeight: 'bold',
      },
      gap: spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    width: 'fit-content',
  },
}));

export const CarOvernight: React.FC = observer(function CarOvernight() {
  const {
    setTitle,
    nextStep,
    editMode,
    stepsData: { reviewStep },
    setStep,
  } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: {
        vehicle,
        setInsuredChangedOvernightAddr,
        insuredChangedOvernightAddr,
        insured,
        setVehicle,
        setInsuredChangedSameAddr,
        insuredChangedSameAddr,
      },
    },
  } = useStores();
  const { isLookupsFetched } = useLookups({ lookups: [LOOKUP.OVERNIGHT_LOCATION] });

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [sameOvernight, setSameOvernight] = useState<boolean | null>(null);
  const [sameOvernightError, setSameOvernightError] = useState(false);
  const [nightLocation, setNightLocation] = useState<string | null>(null);
  const [nightLocationError, setNightLocationError] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | undefined>();

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.CarOvernight.homeAddressOvernight'));
    if (insuredChangedOvernightAddr && insuredChangedSameAddr) {
      setSameOvernight(castToNullIfUndefined(toJS(vehicle.sameOvernightAddress)));
      setNightLocation(castToNullIfUndefined(vehicle.overnightLocation));
      setSelectedAddress(vehicle.overnightAddress);
    } else if (!insuredChangedOvernightAddr && insuredChangedSameAddr) {
      setSameOvernight(toJS(vehicle.sameOvernightAddress));
      setNightLocation(vehicle.overnightLocation);
      setSelectedAddress(undefined);
    }
  }, []);

  useEffect(() => {
    setOptions(lookupsByName[LOOKUP.OVERNIGHT_LOCATION]);
  }, [isLookupsFetched, lookupsByName]);

  const updateVehicle = () => {
    !sameOvernight && setInsuredChangedOvernightAddr(true);
    sameOvernight && setInsuredChangedOvernightAddr(false);
    !insuredChangedSameAddr && setInsuredChangedSameAddr(true);

    setVehicle({
      ...vehicle,
      overnightAddress: sameOvernight ? InsuredAddress.create() : (selectedAddress as IInsuredAddress),
      overnightLocation: nightLocation as string,
      overnightPostcode: sameOvernight ? insured.address.postcode : (selectedAddress as IInsuredAddress).postcode,
      sameOvernightAddress: sameOvernight as boolean,
    });
  };

  const handleValidation = () => {
    setNightLocationError(!nightLocation);
    setSameOvernightError(typeof sameOvernight === 'boolean' ? false : true);
  };

  const handleContinue = () => {
    handleValidation();

    if (typeof sameOvernight === 'boolean' && nightLocation) {
      updateVehicle();
      nextStep();
    }
  };

  const handleSave = () => {
    updateVehicle();
    setStep(reviewStep);
  };

  return (
    <>
      <Grid className={classes.root}>
        <InputLabel>{t('MTAJourney.CarUsage.CarOvernight.homeAddress')}</InputLabel>
        <Typography variant="body1">
          <span
            dangerouslySetInnerHTML={{
              __html: SelfService.formatAddress(insured.address, true).full,
            }}
          />
        </Typography>
        <Choice
          identifier="sameOvernight"
          onChange={(event) => {
            setSameOvernight(event.target.value === 'true');
            setNightLocationError(false);
            setSameOvernightError(false);
          }}
          left={{ label: t('labels.no') }}
          right={{ label: t('labels.yes') }}
          radioValue={sameOvernight}
        />
        {sameOvernightError && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}
        {typeof sameOvernight === 'boolean' && !sameOvernight && (
          <Grid container direction="column" className="overnight-address">
            <Typography variant="h3" className="overnight-address_title">
              {t('MTAJourney.CarUsage.CarOvernight.overnightAddress')}
            </Typography>
            <FindAddress
              hideFooter={!!selectedAddress}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              chatbotAttr="ssMTAVehicleNoAddress"
            />
          </Grid>
        )}
        {(selectedAddress || sameOvernight === true || sameOvernight === null) && (
          <>
            <MtaTooltip
              description={
                <Typography variant="body1">{t('MTAJourney.CarUsage.CarOvernight.carKeptOvernight')}</Typography>
              }
              tooltipTitle={t('tooltips.changeYourCar.overnightAddress')}
            />
            <RadioGroup
              value={nightLocation}
              onChange={(event) => {
                setNightLocation(event.target.value);
                setNightLocationError(false);
                setSameOvernightError(false);
              }}
            >
              {options?.map((item, index) => {
                return (
                  <FormControlLabel
                    key={`${item.code}-${index}`}
                    value={item.code}
                    control={<Radio required />}
                    label={item.value}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}
        {nightLocationError && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}
        {(selectedAddress || sameOvernight === true || sameOvernight === null) && (
          <>
            {editMode ? (
              <EditFooter id="carOvernight" handleBack={() => setStep(reviewStep)} handleSave={handleSave} />
            ) : (
              <MTAFooter
                backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_CAR_OVERNIGHT_LOCATION}
                cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_CAR_OVERNIGHT_LOCATION}
                primaryButton={{
                  blockContinue: typeof sameOvernight !== 'boolean' || !nightLocation,
                  buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CAR_OVERNIGHT_LOCATION,
                  handleContinue,
                }}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
});
