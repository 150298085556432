import { Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeSecurityFeatures } from '../../../../../../../../component/common/changeSecurityFeatures';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .choice-container': {
      display: 'grid',
      gap: spacing(1),
      width: 'fit-content',
    },
    '& .description': {
      marginBottom: spacing(2),
    },
    '& .error-message': {
      color: palette.error.dark,
    },

    display: 'grid',
    gap: spacing(3),
  },
}));

export const EditSecurityFeatures: React.FC<EditPageProps> = observer(function EditSecurityFeatures({ handleBack }) {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { setVehicle, vehicle },
    },
  } = useStores();
  const { setTitle } = useStepperContext();

  const [trackingDeviceFitted, setTrackingDeviceFitted] = useState<boolean | null>(vehicle.trackingDeviceFitted);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityDeviceDeclared, setSecurityDeviceDeclared] = useState(vehicle.securityDeviceDeclared);
  const [options, setOptions] = useState<ILookupItem[] | undefined>();

  const classes = useStyles();

  useEffect(() => {
    setTitle(t('MTAJourney.SecurityFeatures.updatedSecurityFeatures'));
  }, []);

  const isValid = (): boolean => {
    return trackingDeviceFitted !== null && securityDeviceDeclared !== '';
  };
  const handlePendedMta = () => {
    setIsSubmitting(true);
    isValid() && setVehicle({ ...vehicle, securityDeviceDeclared, trackingDeviceFitted });
  };

  const handleSave = () => {
    handlePendedMta();
    isValid() && handleBack();
  };
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.VEHICLE_SECURITY],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.VEHICLE_SECURITY] !== undefined) {
      setOptions(lookupsByName[LOOKUP.VEHICLE_SECURITY]);
    }
  }, [isLookupsFetched, lookupsByName]);

  return (
    <Grid container item xs direction="column" className={classes.root}>
      <ChangeSecurityFeatures
        securityDeviceDeclared={securityDeviceDeclared}
        isSubmitting={isSubmitting}
        options={options}
        trackingDeviceFitted={trackingDeviceFitted}
        setSecurityDeviceDeclared={setSecurityDeviceDeclared}
        setTrackingDeviceFitted={setTrackingDeviceFitted}
      />

      <EditFooter id="editSecurityFeatures" validPage={isValid()} handleSave={handleSave} handleBack={handleBack} />
    </Grid>
  );
});
