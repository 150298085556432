import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP, LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../components/editFooter';
import { MtaTooltip } from '../components/mtaTooltip';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps, PageValues } from './reviewAll';

export const EditRegisteredKeeper: React.FC<EditPageProps> = observer(function EditRegisteredKeeper({ handleBack }) {
  const { setTitle } = useStepperContext();
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { vehicle, setVehicle },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { editMode } = useStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [isSubmitting] = useState<boolean>(false);

  const getPageVal: () => PageValues = () => {
    return { defaultOption: vehicle[LOOKUP_TYPE_PAGE.REGISTERED_KEEPER], lookup: LOOKUP.KEEPER };
  };
  const [value, setValue] = useState(getPageVal().defaultOption);
  const { isLookupsFetched } = useLookups({
    lookups: [getPageVal().lookup],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[getPageVal().lookup]);
    }
  }, [isLookupsFetched, lookupsByName]);

  useEffect(() => {
    setTitle(t('makeChange.carDetails.registeredKeeper'));
  }, []);

  const handleContinueOrSave = () => {
    setVehicle({ ...vehicle, registeredKeeper: value });
  };

  const handleSaveChanges = () => {
    handleContinueOrSave();
    handleBack();
  };

  return (
    <>
      {isLookupsFetched && (
        <>
          <MtaTooltip
            description={<Typography variant="body1">{t('makeChange.carDetails.registeredKeeper')}</Typography>}
            tooltipTitle={t('tooltips.changeYourCar.registeredKeeper')}
          />

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      {!editMode && value === '' && isSubmitting && (
        <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
      )}

      <EditFooter id="editRegisteredKeeper" validPage={true} handleBack={handleBack} handleSave={handleSaveChanges} />
    </>
  );
});
