import { Grid, makeStyles } from '@esure-cloud/react-components';

import React from 'react';

import { BasicTooltip } from '../../../../../../../../component/common/basicTooltip';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTypography-root': {
      display: 'inline',
    },
    display: 'block',
  },
}));

export const MtaTooltip: React.FC<{ description: React.ReactNode; tooltipTitle: string }> = ({
  description,
  tooltipTitle,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" className={classes.root}>
      {description}
      {tooltipTitle && <BasicTooltip title={tooltipTitle} />}
    </Grid>
  );
};
