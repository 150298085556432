import { Grid, Typography, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonAccordion, { CommonAccordionProps } from '../../../../../../../component/common/commonAccordion';
import { Excesses } from '../../../../../../../component/layout/excesses';
import { useStores } from '../../../../../../../service/state/store';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  excessesContainer: {
    marginTop: theme.spacing(2),
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export interface AccordionItem {
  accordion: (value: number) => CommonAccordionProps;
  codes: string[];
}

interface WindscreenValues {
  repair?: number;
  replacement?: number;
}

export const PolicyExcesses: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const [open, setOpen] = useState<null | number>(null);

  const {
    dashboardStore: {
      coverages,
      insurance: {
        auto: { mainPolicy, mainVehicle },
      },
    },
    interfaceStore: { isDesktop },
  } = useStores();

  const windscreen: WindscreenValues = {};
  const windscreenCodes = ['windscreen', 'windscreen_windscreenRepair'];

  coverages.policyExtras.forEach(({ amount, code }) => {
    if (code === windscreenCodes[0]) {
      windscreen.replacement = amount;
    } else if (code === windscreenCodes[1]) {
      windscreen.repair = amount;
    }
  });

  const voluntaryAmount = mainVehicle.voluntaryExcessCode ? Number.parseInt(mainVehicle.voluntaryExcessCode) : 0;
  const compulsory = mainVehicle.coverages.find((itm) => itm.coverageCd === 'ad');
  const compulsoryAmount = compulsory?.deductibleAmount ? compulsory.deductibleAmount : 0;
  const totalAccidental = voluntaryAmount + compulsoryAmount;

  const VehicleDamageFooter: React.FC = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('vehicleDamage.drivers.title')}</Typography>
        </Grid>

        {mainPolicy.drivers.map((driver, index) => {
          const driverSpecificAmount = driver.driverSpecificExcess ? driver.driverSpecificExcess : 0;

          return (
            <Grid container direction="column" item key={Symbol(index).toString()} md={4} xs={12}>
              <Typography variant="h5">{`${driver.person.fullName()}:`}</Typography>
              <Typography variant="body2">{`${t('vehicleDamage.drivers.voluntary')} - ${t('core:price.poundNoDecimal', {
                value: voluntaryAmount,
              })}`}</Typography>
              <Typography variant="body2">{`${t('vehicleDamage.drivers.compulsory')} - ${t(
                'core:price.poundNoDecimal',
                {
                  value: compulsoryAmount,
                },
              )}`}</Typography>
              <Typography variant="body2">{`${t('vehicleDamage.drivers.driverSpecific')} - ${t(
                'core:price.poundNoDecimal',
                {
                  value: driverSpecificAmount,
                },
              )}`}</Typography>
              <Typography variant="h5">{`${t('vehicleDamage.drivers.totalAccidental')} - ${t(
                'core:price.poundNoDecimal',
                {
                  value: totalAccidental + driverSpecificAmount,
                },
              )}`}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const accordions: AccordionItem[] = [
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('vehicleDamage.coverIncludes.included', { returnObjects: true }),
            title: t('vehicleDamage.coverIncludes.title'),
          },
        ],
        footerComponent: <VehicleDamageFooter />,
        headerIcon: 'car-damage',
        headerInfo: t('vehicleDamage.summary', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('vehicleDamage.title'),
        summary: t('vehicleDamage.contentDescription'),
      }),
      codes: ['ad'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('fire.coverIncludes.included', { returnObjects: true }),
            title: t('theft.coverIncludes.title'),
          },
        ],
        headerIcon: 'fire',
        headerIconType: 'solid',
        headerInfo: t('fire.summary', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('fire.title'),
      }),
      codes: ['fire'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            list: t('theft.coverIncludes.included', { returnObjects: true }),
            title: t('theft.coverIncludes.title'),
          },
          {
            icon: 'times',
            list: t('theft.coverNotIncludes.notIncluded', { returnObjects: true }),
            title: t('theft.coverNotIncludes.title'),
          },
        ],
        headerIcon: 'theft',
        headerInfo: t('theft.summary', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('theft.title'),
      }),
      codes: ['theft'],
    },
    {
      accordion: (value: number) => ({
        columns: [
          {
            subtitle: t('nonApprovedRepairer.note.description', { value: t('core:price.poundNoDecimal', { value }) }),
            title: t('nonApprovedRepairer.note.title'),
          },
          {
            icon: 'circle',
            list: t('nonApprovedRepairer.nonApprovedRepairerItems.items', { returnObjects: true }),
            title: t('nonApprovedRepairer.nonApprovedRepairerItems.title'),
          },
        ],
        headerIcon: 'repair-work',
        headerIconType: 'solid',
        headerInfo: t('nonApprovedRepairer.summary', { value: t('core:price.poundNoDecimal', { value }) }),
        headerTitle: t('nonApprovedRepairer.title'),
      }),
      codes: ['ownRepairerExcess'],
    },
    {
      accordion: () => ({
        columns: [
          {
            list: t('windscreenCover.coverIncludes.included', { returnObjects: true }),
            title: t('windscreenCover.coverIncludes.title'),
          },
          {
            list: [
              t('windscreenCover.needToPay.excessForRepair', {
                value: t('core:price.poundNoDecimal', { value: windscreen.repair }),
              }),
              t('windscreenCover.needToPay.excessForReplacement', {
                value: t('core:price.poundNoDecimal', { value: windscreen.replacement }),
              }),
            ].filter((item) => !item.includes('NaN')),
            title: t('windscreenCover.needToPay.title'),
          },
        ],
        footerButtonText: t('windscreenCover.needToKnow.contactButton'),
        footerSubtitle: t('windscreenCover.needToKnow.description'),
        footerTitle: t('windscreenCover.needToKnow.title'),
        headerIcon: 'windscreen-cover',
        headerInfo: [
          t('windscreenCover.repairSummary', { value: t('core:price.poundNoDecimal', { value: windscreen.repair }) }),
          t('windscreenCover.replacementSummary', {
            value: t('core:price.poundNoDecimal', { value: windscreen.replacement }),
          }),
        ]
          .filter((item) => !item.includes('NaN'))
          .join(' & '),
        headerTitle: t('windscreenCover.title'),
        redirectUrl: t('windscreenCover.needToKnow.url'),
        thirdPartyLogo: './assets/images/autoglass.svg',
      }),
      codes: [windscreenCodes[1]],
    },
  ];

  const handleOnChange = (index: number) => {
    setOpen(open === index ? null : index);
  };

  return (
    <>
      <Grid className={classes.gridContainer} container>
        <Grid className={classes.pageTitle} item xs={12}>
          <Typography variant={isDesktop ? 'h3' : 'h2'}>{t('policyExcesses.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isDesktop ? 'body1' : 'body2'}>{t('policyExcesses.description')}</Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.excessesContainer} xs={12}>
        <Excesses />
      </Grid>
      <Grid className={classes.accordionContainer} container direction="column" wrap="nowrap">
        {coverages.policyExtras.map((item, index) => {
          const accordion = accordions.find((x) => x.codes.includes(item.code))?.accordion;

          return accordion ? (
            <CommonAccordion
              expanded={open === index}
              key={Symbol(index).toString()}
              onChange={() => handleOnChange(index)}
              {...accordion(item.amount)}
            />
          ) : null;
        })}
      </Grid>
    </>
  );
});
