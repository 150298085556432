import { Instance, types } from 'mobx-state-tree';

import SelfService from '../../../util/selfService';

import { AutoPolicies } from './autoPoliciesModel';
import { IAutoPolicy } from './autoPolicyModel';
import { HomePolicies } from './homePoliciesModel';
import { IHomePolicy } from './homePolicyModel';

export const Insurance = types
  .model('Insurance', {
    auto: types.optional(AutoPolicies, () => AutoPolicies.create()),
    home: types.optional(HomePolicies, () => HomePolicies.create()),
    selectedPolicyType: types.optional(types.string, ''),
  })
  .views((self) => ({
    get isHome(): boolean {
      return self.selectedPolicyType === 'home';
    },
  }))
  .actions((self) => ({
    setSelectedPolicyType(policy: IAutoPolicy | IHomePolicy) {
      self.selectedPolicyType = SelfService.isHomePolicy(policy) ? 'home' : 'auto';
    },
    setSelectedPolicyTypeByName(policyType: string) {
      self.selectedPolicyType = policyType;
    },
  }));

export type IInsurance = Instance<typeof Insurance>;
