import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES, LOOKUP } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../service/util/customHooks/useDecodedLookups';
import { formatDate } from '../../../service/util/formatDate';
import SelfService from '../../../service/util/selfService';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  paddingTop2: {
    paddingTop: theme.spacing(2),
  },
}));

export interface ProfileOptions {
  description?: string;
  title?: string;
}

export interface DrawerContainer {
  headerTitle: string;
  options: (ProfileOptions | boolean)[];
}

export const DriverDrawerProfile: React.FC = observer(function DriverDrawerProfile() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount', {});

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({
    options: ['vehicle', 'driver'],
  });

  const {
    dashboardStore: {
      fetchPolicyClaims,
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          policySelected,
          mainDriver,
          driverSelected: { uuid, claims, convictions, person, licence },
          mainVehicle: { classOfUse, ncdYears },
          policyCover: { drivingOtherCars },
        },
      },
    },
  } = useStores();

  useEffect(() => {
    fetchPolicyClaims(policySelected.policyNumber);
  }, [uuid]);

  const isMainDriver = uuid === mainDriver.uuid;
  const noneText = 'None';

  const driverProfile: DrawerContainer[] = [
    {
      headerTitle: t('driverProfileHeaders.personalDetails'),
      options: [
        {
          description: formatDate(person.dateOfBirth, DATE_TYPES.LONG),
          title: t('driverProfileHeaders.dateOfBirth'),
        },
        isMainDriver && {
          description: SelfService.formatAddress(policySelected.insured.address).full,
          title: t('driverProfileHeaders.homeAddress'),
        },
        {
          description: getLookup(LOOKUP.OCCUPATION, person.primaryOccupationCode ?? '') || noneText,
          title: t('driverProfileHeaders.occupation'),
        },
        {
          description: getLookup(LOOKUP.OCCUPATION, person.secondaryOccupationCode ?? '') || noneText,
          title: t('driverProfileHeaders.secondaryOccupation'),
        },
        isMainDriver && {
          description: person.numberOfChildren ?? noneText,
          title: t('driverProfileHeaders.numberOfChildren'),
        },
        {
          description: getLookup(LOOKUP.LICENCE_TYPE, licence.licenceTypeCode) || noneText,
          title: t('driverProfileHeaders.licenceType'),
        },
        {
          description: formatDate(licence.licenceDate, DATE_TYPES.LONG),
          title: t('driverProfileHeaders.licenceDate'),
        },
      ],
    },
    {
      headerTitle: t('driverProfileHeaders.insuranceDetails'),
      options: [
        isMainDriver && {
          description: ncdYears,
          title: t('driverProfileHeaders.ncdYears'),
        },
        isMainDriver && {
          description: drivingOtherCars ? t('labels.yes') : t('labels.no'),
          title: t('driverProfileHeaders.useOfOtherCarsCode'),
        },
        Boolean(classOfUse) && {
          description: getLookup(LOOKUP.USAGE, classOfUse),
          title: t('driverProfileHeaders.typeOfUse'),
        },
        {
          description: claims.length.toString() || noneText,
          title: t('driverProfileHeaders.claims'),
        },
        {
          description: convictions.length.toString() || noneText,
          title: t('driverProfileHeaders.convictions'),
        },
      ],
    },
    ...claims.map(({ claimDate, claimCode, claimStatus }, index) => ({
      headerTitle: `${t('driverProfileHeaders.claim')} ${index + 1}`,
      options: [
        {
          description: formatDate(claimDate, DATE_TYPES.LONG),
          title: t('driverProfileHeaders.date'),
        },
        {
          description: getLookup(LOOKUP.CLAIM_TYPE, claimCode),
          title: t('driverProfileHeaders.claimDescription'),
        },
        {
          description: claimStatus,
          title: t('driverProfileHeaders.status'),
        },
      ],
    })),
    ...convictions.map(({ convictionDate, convictionCode, licenceSuspended, banLength }, index) => ({
      headerTitle: `${t('driverProfileHeaders.conviction')} ${index + 1}`,
      options: [
        {
          description: formatDate(convictionDate, DATE_TYPES.LONG),
          title: t('driverProfileHeaders.date'),
        },
        {
          description: getLookup(LOOKUP.CONVICTION_CODE, convictionCode),
          title: t('driverProfileHeaders.convictionCode'),
        },
        {
          description: licenceSuspended ? 'Yes' : 'No',
          title: t('driverProfileHeaders.licenseSuspended'),
        },
        Boolean(licenceSuspended) && {
          description: formatDate(banLength?.toString(), DATE_TYPES.LONG),
          title: t('driverProfileHeaders.banLength'),
        },
      ],
    })),
  ];

  return (
    <>
      <Typography variant="h2">{person.fullName()}</Typography>

      {isDecodedLookupsFetched &&
        driverProfile.map(({ headerTitle, options }, index) => (
          <CommonContainer key={index} className={classes.container} color="secondary" variant="outlined">
            <Typography variant="h3">{headerTitle}</Typography>

            {options.map((option, _index) =>
              typeof option !== 'boolean' ? (
                <Grid key={_index} container direction="column">
                  <Typography variant="body2" className={classes.paddingTop2}>
                    {option.title}
                  </Typography>
                  <Typography variant="h4">{option.description}</Typography>
                </Grid>
              ) : null,
            )}
          </CommonContainer>
        ))}
    </>
  );
});
