import { Grid, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES, LOOKUP } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { formatDate } from '../../../../../../service/util/formatDate';
import { ReviewAllDivider } from '../../makeAchange/mta/common/steps/reviewAll';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  gridItem: {
    padding: spacing(1, 2),
  },
  paddingGrid: {
    paddingBottom: spacing(0.6),
  },
}));

export const ConvictionComp: React.FC = observer(function ConvictionComp() {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    renewalChangeStore: {
      renewalDetails: { drivers },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
  } = useStores();
  const classes = useStyles({ isDesktop });

  const policyHolder = drivers.find((driver) => driver.relationshipToProposerCd === 'P');

  return (
    <Grid>
      {policyHolder?.convictions.map((conviction, i) => (
        <Grid key={Symbol(i).toString()}>
          {i !== 0 && <ReviewAllDivider />}
          <Typography variant="h4" style={{ padding: '0px 16px' }}>
            {`${t('policyManagement.yourCoverDetails.drivingOffencesAccordion.subTitle')}${i + 1}`}
          </Typography>
          <Grid container direction="column" className={classes.gridItem}>
            <Grid item className={classes.paddingGrid}>
              <Typography>{t('policyManagement.yourCoverDetails.drivingOffencesAccordion.date')}</Typography>
              <Typography variant="h4">{formatDate(conviction.convictionDate, DATE_TYPES.WEEKDAY_SHORT)}</Typography>
            </Grid>
            <Grid item className={classes.paddingGrid}>
              <Typography>{t('policyManagement.yourCoverDetails.drivingOffencesAccordion.convictionCode')}</Typography>
              <Typography variant="h4">
                {getLookup(LOOKUP.CONVICTION_CODE, conviction.convictionTypeCd)}, {conviction.penaltyPoints}{' '}
                {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.penaltyPoints')}
              </Typography>
            </Grid>
            <Grid item className={classes.paddingGrid}>
              <Typography>
                {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.licenceSuspended')}
              </Typography>
              <Typography variant="h4">{conviction.licenceSuspended ? 'Yes' : 'No'}</Typography>
            </Grid>
            {conviction.licenceSuspended && (
              <Grid item className={classes.paddingGrid}>
                <Typography>
                  {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.suspensionPeriod')}
                </Typography>
                <Typography variant="h4">
                  {conviction.banLength} {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.months')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
});
