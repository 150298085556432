import { makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import ModificationsComponent from '../../../../../../../../component/common/mta/carModifications';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { track } from '../../../../../../../../lib/optimizely';
import { LOOKUP } from '../../../../../../../../service/constant';
import { IModification } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .chat-section': {
      '&__link': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
      gap: 10,
    },
    '& .checkbox': {
      marginLeft: spacing(-1),
      marginTop: spacing(-1),
    },
    '& .modifications': {
      '&__header-container': {
        gap: 20,
        paddingTop: spacing(2),
      },
    },
  },
}));

export interface CarModificationsProps {
  isSubmitting: boolean;
  options: { selections: IModification[] };
  setOptions: React.Dispatch<
    React.SetStateAction<{
      selections: IModification[];
    }>
  >;
  showTitle?: boolean;
}

export const CarModifications: React.FC<CarModificationsProps> = observer(function CarModifications({
  isSubmitting = false,
  options,
  setOptions,
  showTitle,
}) {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const {
    step,
    stepsData: { blueBannerTitle },
  } = useStepperContext();
  const chatBotAttr = useMTAChatBotAttributes('ssMTAVehicleModsChat');

  const {
    interfaceStore: {
      chatBot: { startChat },
    },
    dashboardStore: {
      lookups: { lookupsByName },
    },
  } = useStores();

  const [updatedModifications, setUpdatedModifications] = useState<ILookupItem[] | undefined>();

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.MODIFICATIONS],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.MODIFICATIONS] !== undefined) {
      const lookups = [...lookupsByName[LOOKUP.MODIFICATIONS]];
      setUpdatedModifications(lookups.sort((a, b) => a.value.localeCompare(b.value)));
    }
  }, [isLookupsFetched, lookupsByName]);

  const handleChangeCheckbox = (item: ILookupItem) => {
    const sel = options.selections;
    const find = sel.findIndex((selected) => selected.modificationCode === item.code);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push({ modificationCode: item.code, uuid: '' });
    }
    setOptions({
      selections: sel,
    });
  };

  const eventName = `${blueBannerTitle[step] ? blueBannerTitle[step] : blueBannerTitle.default} - Step ${step + 1}`;
  const handleChat = () => {
    track('My_Account_chat_with_us_click');
    eventTrack('Chat With Us Button Clicked', { position: eventName, type: TRACK_TYPE.CHAT });
    startChat({ initiator: eventName, ...(chatBotAttr ?? {}) });
  };

  return (
    <ModificationsComponent
      showTitle={showTitle}
      updatedModifications={updatedModifications}
      options={options}
      isSubmitting={isSubmitting}
      handleChat={handleChat}
      handleChangeCheckbox={handleChangeCheckbox}
      classes={classes}
    />
  );
});
