import { Button, ButtonProps, CircularProgress, Link, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { BUTTON_ORIGIN } from '../../../service/constant';
import { IAutoPolicy } from '../../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../../service/state/models/dashboard/homePolicyModel';
import IconSure, { IconType } from '../icon';

const useStyles = makeStyles((theme) => ({
  button: {
    '& .hide': {
      display: 'none',
    },
    '& .loading': {
      marginRight: theme.spacing(1),
    },
    '& .spinner': {
      position: 'absolute',
    },
    '&.contrast': {
      borderColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.contrastText,
    },
    '&[class*="sizeLarge"]': {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 1),
      width: '100%',
    },
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
  xl: {
    width: '100%',
  },
}));

export interface CommonButtonProps extends ButtonProps {
  colorIcon?: string;
  contrast?: boolean;
  endIcon?: string;
  iconType?: IconType;
  isExternalURL?: boolean;
  loading?: boolean;
  onClick?: () => void;
  origin?: BUTTON_ORIGIN;
  startIcon?: string;
  text?: string;
  url?: string;
  xl?: boolean;
}

export interface ButtonOfSelectedPolicyProps extends CommonButtonProps {
  policy: IAutoPolicy | IHomePolicy;
}
export interface ButtonRenewalDocumentsProps extends CommonButtonProps {
  fileName: string;
  isDeclineButton?: boolean;
  policy: IAutoPolicy | IHomePolicy;
}

export interface ButtonRenewalGoAheadProps extends CommonButtonProps {
  checked: boolean;
  handleChat: (value: boolean) => void;
  hasRenewalRequirements: boolean | undefined;
  policyNumber: string;
  setIsSubmitting: (value: boolean) => void;
  text: string;
}

export const CommonButton: React.FC<CommonButtonProps> = (props) => {
  const {
    color = 'primary',
    colorIcon,
    contrast,
    endIcon,
    startIcon,
    iconType = 'line',
    text,
    url,
    loading = false,
    variant = 'contained',
    xl,
    isExternalURL = false,

    ...rest
  } = props;
  const classes = useStyles();

  const iconColor = colorIcon ? colorIcon : contrast ?? variant === 'contained' ? 'white' : color;

  const ControlledButton = () => (
    <Button
      {...rest}
      className={clsx(classes.button, contrast && 'contrast', xl && classes.xl)}
      style={{ position: 'relative' }}
      color={color}
      endIcon={
        endIcon && <IconSure className={loading ? 'hide' : ''} type={iconType} icon={endIcon} color={iconColor} />
      }
      startIcon={
        startIcon && <IconSure className={loading ? 'hide' : ''} type={iconType} icon={startIcon} color={iconColor} />
      }
      variant={variant}
      aria-label={loading ? 'Please wait, loading' : text}
      aria-busy={loading}
      aria-live="polite"
    >
      <span className={loading ? 'hide' : ''}>{text}</span>
      {<CircularProgress className={loading ? 'spinner' : 'hide'} color="inherit" size={20} />}
    </Button>
  );

  let resultComponent;
  if (url) {
    if (isExternalURL) {
      resultComponent = (
        <Link className={classes.link} href={url} target="_blank">
          <ControlledButton />
        </Link>
      );
    } else {
      resultComponent = (
        <RouterLink className={classes.link} to={url}>
          <ControlledButton />
        </RouterLink>
      );
    }
  } else {
    resultComponent = <ControlledButton />;
  }

  return resultComponent;
};
