/**
 * Native App sends this message once it opens WebView and belives its loaded
 * Displays loader spinner until it receives 'ready' message
 */
export const MESSAGE_TYPE_CHECK_READY = 'checkReady';

/**
 * Native App hook responsible for triggering getting token
 */
export const NATIVE_HOOK_TYPE_GET_TOKEN = 'getToken';

/**
 * Native App expects this message once data loading is finished to remove loader spinner
 */
export const NATIVE_HOOK_TYPE_READY = 'ready';

/**
 * Native App expects this message when users click on close icon in WebView
 */
export const NATIVE_HOOK_TYPE_CLOSE = 'close';

/**
 * Native App expects this message to open url in browser
 */
export const NATIVE_HOOK_TYPE_OPEN_IN_BROWSER = 'openInBrowser';

/**
 * Downloading files is very tricky in WebView, for downloading files we need to rely on bridge
 */
export const NATIVE_HOOK_TYPE_DOWNLOAD_FILE = 'downloadFile';

/**
 * Native App sends this message once app becomes active (comes back from background)
 */
export const MESSAGE_TYPE_BECAME_ACTIVE = 'becameActive';

/**
 * Native App returns promise which resolves to access token
 */
export const MESSAGE_TYPE_TOKEN_RESULT = 'getTokenResult';

/**
 * Native App passes values for safe area, platform and build version in cookie (JSON)
 */
export const NATIVE_APP_COOKIE_NAME = 'caura-native';
export const NATIVE_APP_TOP_SAFE_AREA_FIELD_NAME = 'safeAreaTop';
export const NATIVE_APP_BOTTOM_SAFE_AREA_FIELD_NAME = 'safeAreaBottom';
export const NATIVE_APP_VERSION_FIELD_NAME = 'appVersion';
export const NATIVE_APP_PLATFORM_FIELD_NAME = 'platform';
