import { Box, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import { Footer } from '../../../component/common';
import { AppBarComponent } from '../../../component/common/appbar';
import PanelDrawer from '../../../component/common/panelDrawer';
import { Sidebar } from '../../../component/common/sidebar';
import { AlertNotification } from '../../../component/layout/alertNotification';
import { Dialog } from '../../../component/layout/dialogs/dialog';
import { Notification } from '../../../component/notification';
import { getSafeAreaTop, sendReadyHook } from '../../../lib/caura-bridge';
import { DIALOG_TYPE, ERROR_CODE, MTA_QUOTE_FAILURE_REASON, ROUTE } from '../../../service/constant';
import GetFeedbackTracker from '../../../service/getFeedbackTracker';
import { APIResponseError } from '../../../service/network';
import { RouteData, routeConfig } from '../../../service/routeConfig';
import RouteTracker from '../../../service/routeTracker';
import { useStores } from '../../../service/state/store';
import { ProtectedRoute } from '../../../service/util/customHooks/protectedRoute';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { manageLogout } from '../../../service/util/handleLogout';
import SelfService from '../../../service/util/selfService';
import { PoliciesDashboardDesktop } from '../policies/policiesDashboards/desktop';
import { StepperProvider } from '../policies/policy/makeAchange/mta/common/utils/stepperContext';
import { RenewalStepperProvider } from '../policies/policy/renewals/yourCoverDetails/utils/renewalStepperContext';
/**
 * If you want to essentially bypass the axios global response interceptor, then add the url into this array. This will check if the
 * request url ends with the url in the array. If it does, then the interceptor will reject the promise with the original error.
 * The caller ts then able to catch the error and act accordingly
 */
const urlsToThrowOriginalAxiosError = ['/auto-renew', '/java-app-customerauth/v0/token'];

/**
 * Same as urlsToThrowOriginalAxiosError except this can be used when URL is dynamic, so we compare the URL using includes rather than endsWith
 */
const urlsToThrowOriginalErrorContains = ['/java-app-motorpolicy/v0/policies/', '/java-app-homepolicy/v0/policies/'];

/**
 * Urls in this list will not add to the error count in the interface store, but instead will have their error code
 * checked, and depending on that error code, will act accordingly, i.e by navigating to an error page etc.
 */
const whiteList = [
  'policydocuments',
  'customervalidation',
  'legitimate-interest-marketing-permissions',
  'payments',
  'java-app-addresslookup',
  'java-app-vehiclelookup',
  'java-app-customermta',
  'java-app-motor-renewals',
  'auto-renew-details',
  'homerenewals',
  'java-app-home-renewals',
];

const useStyles = makeStyles((theme) => ({
  mainClaimsContent: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
    flex: '1',
    margin: theme.spacing(11, 2, 0),
  },
  mainContent: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(16, 8, 8),
      position: 'relative',
    },
    flex: '1',
    margin: theme.spacing(11, 2, 0),
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 175px)`,
    },
    float: 'right',
  },
}));

/* istanbul ignore next */
export const HomePage: React.FC = observer(function HomePage() {
  const classes = useStyles();
  const { eventTrack } = useSegment();

  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    clearAllStores,
    dashboardStore: {
      customer,
      getInitialData,
      insurance,
      fetchRenewalDetails,
      fetchMotorPolicyRenewalQuoteDetails,
      fetchHomePolicyRenewalQuoteDetails,
      updatedCustomerAccount,
    },
    interfaceStore,
    userStore: { user },
  } = useStores();

  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const fetchInitialState = async () => {
    try {
      interfaceStore.setTotalErrors(0);
      interfaceStore.setIsLoading(true);
      await getInitialData();
    } finally {
      if (interfaceStore.totalErrors === 0) {
        interfaceStore.setIsLoading(false);
        await handleActivation();
      }
    }
  };

  const fetchRenewalData = async () => {
    const combinedPolicies = [...insurance.auto.autoPolicies, ...insurance.home.homePolicies];
    if (combinedPolicies.length) {
      try {
        for (const policy of combinedPolicies) {
          if (!policy.tiaPolicy) {
            await fetchRenewalDetails(policy.policyNumber, policy.effectiveDate, SelfService.isHomePolicy(policy));
          }
        }
        for (const policy of insurance.auto.autoPolicies) {
          const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(policy.expirationDate) - 1;
          const insideRenewal = remainingDays <= 32 && remainingDays >= 0 && !policy.futureRenewalEffectiveDate;
          if (!policy.tiaPolicy && insideRenewal) {
            await fetchMotorPolicyRenewalQuoteDetails(policy.policyNumber);
          }
        }
        if (interfaceStore.hasFeatureFlag('showHomeRenewalNotification - annual')) {
          for (const policy of insurance.home.homePolicies) {
            const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(policy.expirationDate) - 1;
            const insideRenewal = remainingDays <= 32 && remainingDays >= 0;
            if (!policy.tiaPolicy && insideRenewal) {
              await fetchHomePolicyRenewalQuoteDetails(policy.policyNumber);
            }
          }
        }
      } catch (error) {
        // console.error();
      }
    }
  };

  useEffect(() => {
    if (user.userLoggedIn && user.userId) {
      fetchInitialState()
        .then(() => {
          fetchRenewalData().then();

          // Caura logic
          const searchParams = new URLSearchParams(location.search);
          const shouldRedirectToPolicyDetails = searchParams.get('redirectToPolicyDetails') === 'true';
          if (shouldRedirectToPolicyDetails) {
            interfaceStore.setIsCaura(true);
            const policyNumberParam = searchParams.get('policyNumber');
            const policyTypeParam = searchParams.get('policyType');
            if (policyNumberParam && policyTypeParam) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              insurance.setSelectedPolicyTypeByName(policyTypeParam);
              if (policyTypeParam === 'home') {
                insurance.home.setSelectedPolicyNumber(policyNumberParam);
              } else {
                insurance.auto.setSelectedPolicyNumber(policyNumberParam);
              }
            }

            history.push(ROUTE.POLICY_DETAILS);
            sendReadyHook().then();
          }
        })
        .catch(() => {
          return;
        });
    }
  }, [user.userLoggedIn, user.userId]);

  useEffect(() => {
    if (interfaceStore.totalErrors > 0) {
      interfaceStore.dialog.openDialog({ type: DIALOG_TYPE.GENERIC_TECHNICAL_PROBLEM });
    }
  }, [interfaceStore.totalErrors]);

  axios.interceptors.response.use(
    (success) => {
      return success;
    },
    (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const errors: APIResponseError[] = error.response.data['errors'];

          const url = error.response.config.url;

          eventTrack('Axios error', { error: JSON.stringify(error), item: url });

          // Some urls we want to just reject with the original error
          if (
            urlsToThrowOriginalAxiosError.some((x) => url?.endsWith(x)) ||
            urlsToThrowOriginalErrorContains.some((x) => url?.includes(x))
          ) {
            return Promise.reject(error);
          }

          if (url && !whiteList.some((x) => url.includes(x))) {
            interfaceStore.setTotalErrors(interfaceStore.totalErrors + 1);
          } else {
            if (errors.length > 0 && errors[0]['code']) {
              switch (errors[0]['code']) {
                case ERROR_CODE.FAILED_MTA_BUSINESS_003:
                case ERROR_CODE.FAILED_MTA_BUSINESS_004:
                case ERROR_CODE.FAILED_MTA_BUSINESS_005:
                  interfaceStore.setHasQuoteError(true);
                  interfaceStore.dialog.closeDialog();
                  history.push(ROUTE.QUOTE_INELIGIBLE);
                  return {
                    status: 400,
                    statusText: MTA_QUOTE_FAILURE_REASON.QUOTE_INELIGIBLE,
                  };
                case ERROR_CODE.FAILED_MTA_BUSINESS_006:
                  interfaceStore.setHasIovationError(true);
                  interfaceStore.dialog.closeDialog();
                  history.push(ROUTE.QUOTE_INELIGIBLE);
                  return {
                    status: 400,
                    statusText: MTA_QUOTE_FAILURE_REASON.QUOTE_INELIGIBLE,
                  };
                case ERROR_CODE.FAILED_MTA_TECH_000:
                case ERROR_CODE.FAILED_MTA_TECH_001:
                  interfaceStore.setHasQuoteError(true);
                  interfaceStore.dialog.openDialog({ isFullScreen: true, type: DIALOG_TYPE.MTA_TECHNICAL_PROBLEMS });
                  break;
              }
            }
          }
        }

        if (error.response === undefined) {
          const requestUrl = error.config.url;
          eventTrack('Axios error response undefined', { error: JSON.stringify(error), item: error.config.url });

          if (requestUrl?.includes('java-app-customermta')) {
            // If it's MTA and a timeout error, then return with a 504 status
            if (error.code === 'ECONNABORTED') {
              return {
                status: 504,
              };
            }

            interfaceStore.setHasQuoteError(true);
            interfaceStore.dialog.openDialog({ isFullScreen: true, type: DIALOG_TYPE.MTA_TECHNICAL_PROBLEMS });
          }
        }
      }
    },
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isClaimsPage = location.pathname.includes('/claims');
  const handleLogout = () => {
    const mtaRoutes = ['car-details', 'your-details', 'drivers'];

    if (mtaRoutes.some((x) => location.pathname.includes(x))) {
      eventTrack(`${DIALOG_TYPE.CANCEL_CONFIRMATION} -logout`, { type: TRACK_TYPE.MODAL });
      interfaceStore.dialog.openDialog({
        title: t('cancelConfirmationPopup.title'),
        type: DIALOG_TYPE.CANCEL_CONFIRMATION,
      });
    } else {
      manageLogout();
      clearAllStores();
    }
  };

  const handleActivation = async () => {
    const customerEmails = customer.customerEmails;

    if (customerEmails.length > 0) {
      const customer = customerEmails.find((item) => item.email === user.email);

      // Confirm logged user email on his first login
      if (customer && !customer.emailConfirmed) {
        await updatedCustomerAccount({ ...customer, emailConfirmed: true });
        eventTrack('Account Activated', { type: TRACK_TYPE.INFO });
      }
    }
  };

  const allRoutes: RouteData[] = [];
  const getRoutes = (routes: RouteData[]) => {
    routes.forEach((item) => {
      allRoutes.push(item);
      if (item.children) {
        return getRoutes(item.children);
      }
    });
  };
  getRoutes(routeConfig);
  const switchRoutes = (
    <Switch>
      {allRoutes.map((route) => {
        return (
          <ProtectedRoute
            component={route.component ? route.component : PoliciesDashboardDesktop}
            exact={true}
            key={route.path}
            path={route.path}
          />
        );
      })}
      <Redirect to={ROUTE.DASHBOARD} />
    </Switch>
  );

  let contentStyle = {};
  if (interfaceStore.isCaura) {
    const contentMarginTop = Number(getSafeAreaTop());
    contentStyle = { marginTop: `${contentMarginTop + 128}px` };
  }

  return (
    <Box height="100vh">
      <RouteTracker />
      <GetFeedbackTracker />
      <AlertNotification onClose={handleLogout} />
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
        open={mobileOpen}
        routes={routeConfig}
        setOpen={setMobileOpen}
      />
      <StepperProvider>
        <RenewalStepperProvider>
          <Box className={classes.mainPanel} display="flex" flexDirection="column" height="100%" width="100%">
            <AppBarComponent handleDrawerToggle={handleDrawerToggle} />
            <Box style={contentStyle} className={isClaimsPage ? classes.mainClaimsContent : classes.mainContent}>
              {switchRoutes}
            </Box>
            <Footer />
          </Box>
          <PanelDrawer />
          <Dialog />
        </RenewalStepperProvider>
      </StepperProvider>
      <Notification />
    </Box>
  );
});
