import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { LOOKUP } from '../../service/constant';
import { IVehicle } from '../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../service/state/store';
import { useDecodedLookupsMotor } from '../../service/util/customHooks/useDecodedLookups';

import { CarTooltipSkeleton } from './skeletons/carTooltipSkeleton';

export const CarTooltipDetails: React.FC<{ vehicle: IVehicle }> = observer(function CarTooltipDetails({ vehicle }) {
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
  } = useStores();

  const [vehicleDetails, setVehicleDetails] = useState('');
  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ vehicle });

  const { bodyTypeCode, engineSize, fuelTypeCode, numberOfDoors, totalDeclaredMileageCode, transmissionTypeCode } =
    vehicle;

  useEffect(() => {
    getCarDetails();
  }, [isDecodedLookupsFetched]);

  const getCarDetails = () => {
    if (isDecodedLookupsFetched) {
      const fuel = getLookup(LOOKUP.FUEL_TYPE, fuelTypeCode);
      const transmission = getLookup(LOOKUP.TRANSMISSION, transmissionTypeCode);
      const body = getLookup(LOOKUP.BODY_TYPE, bodyTypeCode);
      const mileage = getLookup(LOOKUP.MILEAGE, totalDeclaredMileageCode);

      setVehicleDetails(
        [
          ...[fuel, engineSize, transmission].filter((x) => x),
          `${numberOfDoors} door ${body} ${mileage} estimated annual mileage`,
        ].join(' | '),
      );
    }
  };

  return <>{isDecodedLookupsFetched ? vehicleDetails : <CarTooltipSkeleton />}</>;
});
