import { Dialog as DialogMUI } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DIALOG_TYPE, FETCH_ADVISOR_REASON } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { DDRenewalTechnicalError } from '../../../view/pages/policies/policy/renewals/renewalSummary/renewalError';

import { FetchQuoteDialog } from './FetchQuoteDialog';
import { GenericTechnicalErrorDialog } from './GenericTechnicalErrorDialog';
import { MTATechnicalErrorDialog } from './MTATechnicalErrorDialog';
import { WelcomeToAccountDialog } from './WelcomeToAccountDialog';
import { CancelConfirmationDialog } from './cancelConfirmationDialog';
import { ErrorDialog } from './errorDialog';
import { FetchAdviserDialog } from './fetchAdviser';
import { FrequentIssues } from './frequentIssues';
import { SpeakToSpecialistDialog } from './speakToSpecialistDialog';
import { TIAPolicyDialog } from './tiaPolicyDialog';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export const Dialog: React.FC = observer(function Dialog() {
  const {
    interfaceStore: {
      dialog: { open, type, isFullScreen, disableBackdropClick, title },
    },
  } = useStores();

  const handleClose = (reason: CloseReason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }
  };
  const { t } = useTranslation('myAccount');

  const DialogContent: React.FC | null = () => {
    switch (type) {
      case DIALOG_TYPE.ERROR_ATTEMPT_1:
      case DIALOG_TYPE.ERROR_ATTEMPT_2:
        return <ErrorDialog type={type} />;
      case DIALOG_TYPE.BROKEN_GLASS:
      case DIALOG_TYPE.LOST_KEYS:
      case DIALOG_TYPE.ACCIDENT_RECOVERY:
        return <FrequentIssues issue={type} />;
      case DIALOG_TYPE.DD_CUSTOMER_ASSISTED_PAYMENT:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.DD_CUSTOMER} />;
      case DIALOG_TYPE.FETCH_ADVISER_CHANGES:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.MAKE_CHANGES} />;
      case DIALOG_TYPE.FETCH_ADVISER_CHANGES_REN:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.MAKE_CHANGES_REN} />;
      case DIALOG_TYPE.FETCH_ADVISER_ASSISTED_PAYMENT:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.ASSISTED_PAYMENT} />;
      case DIALOG_TYPE.FETCH_ADVISER_CHANGE_FIRSTNAME:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.CHANGE_FIRSTNAME} />;
      case DIALOG_TYPE.FETCH_ADVISER_CLAIMS_CONV:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.CLAIMS_CONV} />;
      case DIALOG_TYPE.FETCH_ADVISER_EMPLOYMENT:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.CHANGE_EMPLOYMENT_DETAILS} />;
      case DIALOG_TYPE.FETCH_ADVISER_ADD_CARD:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.FETCH_ADVISER_ADD_CARD} />;
      case DIALOG_TYPE.FETCH_ADVISER_DELETE_CARD:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.FETCH_ADVISER_DELETE_CARD} />;
      case DIALOG_TYPE.FETCH_ADVISER_RENEWAL_QUOTE:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.FETCH_ADVISER_RENEWAL_QUOTE} />;
      case DIALOG_TYPE.FETCH_ADVISER_RENEWAL_QUOTE_PAY:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.FETCH_ADVISER_RENEWAL_QUOTE_PAY} />;
      case DIALOG_TYPE.FETCH_QUOTE:
        return <FetchQuoteDialog title={t('MTAJourney.fetchingQuote')} />;
      case DIALOG_TYPE.FETCH_QUOTE_SECONDARY_DELAY:
        return (
          <FetchQuoteDialog title={t('MTAJourney.fetchingQuote')} content={t('MTAJourney.pleaseWaitSecondaryDelay')} />
        );
      case DIALOG_TYPE.SAVE_CHANGES:
        return <FetchQuoteDialog title={t('MTAJourney.savingChanges')} />;
      case DIALOG_TYPE.PROCESSING_PAYMENT:
        return <FetchQuoteDialog title={t('MTAJourney.paymentProcessed')} />;
      case DIALOG_TYPE.UPDATING_DETAILS:
        return <FetchQuoteDialog title={t('MTAJourney.updatingDetails')} />;
      case DIALOG_TYPE.MTA_TECHNICAL_PROBLEMS:
        return <MTATechnicalErrorDialog />;
      case DIALOG_TYPE.CANCEL_CONFIRMATION:
        return <CancelConfirmationDialog />;
      case DIALOG_TYPE.UNABLE_TO_HELP_ONLINE:
        return <FetchAdviserDialog title={title} type={FETCH_ADVISOR_REASON.MAKE_CHANGES} />;
      case DIALOG_TYPE.SPEAK_TO_SPECIALIST:
        return <SpeakToSpecialistDialog title={title} />;
      case DIALOG_TYPE.TIA_POLICY:
        return <TIAPolicyDialog />;
      case DIALOG_TYPE.DD_RENEWAL_TECHNICAL_PROBLEM:
        return <DDRenewalTechnicalError />;
      case DIALOG_TYPE.FETCH_ADVISOR_RENEWAL_MTA_UNAVAILABLE_ONLINE:
        return (
          <FetchAdviserDialog
            continueButtonText={t('buttons.chatWithUs')}
            title={t('renewalMtaUnavailableOnlineDialog.title')}
            type={FETCH_ADVISOR_REASON.RENEWAL_MTA_UNAVAILABLE_ONLINE}
            secondaryContent={t('renewalMtaUnavailableOnlineDialog.secondaryContent')}
          />
        );
      case DIALOG_TYPE.WELCOME_TO_ACCOUNT:
        return <WelcomeToAccountDialog />;
      case DIALOG_TYPE.GENERIC_TECHNICAL_PROBLEM:
        return <GenericTechnicalErrorDialog />;
      default:
        return null;
    }
  };

  return (
    <DialogMUI open={open} onClose={(_, reason) => handleClose(reason)} fullScreen={isFullScreen}>
      <DialogContent />
    </DialogMUI>
  );
});
