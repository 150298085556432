import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CustomTabs, TabProps } from '../../../../../component/common/customTabs';
import { ROUTE } from '../../../../../service/constant';
import { ClaimsApp } from '../../../claims/ClaimsApp';
import { PoliciesDashboardDesktop } from '../desktop';

export const PoliciesDashboardMobile: React.FC = observer(function PoliciesDashboardMobile() {
  const { t } = useTranslation('myAccount');
  const tabs: TabProps[] = [
    {
      components: [{ component: <PoliciesDashboardDesktop />, path: ROUTE.DASHBOARD }],
      disabled: false,
      label: t('mobileDashboardTabs.policiesTab'),
    },
    {
      components: [{ component: <ClaimsApp />, path: ROUTE.CLAIMS_MOBILE_TAB }],
      disabled: false,
      label: t('mobileDashboardTabs.claimsTab'),
    },
  ];

  return <CustomTabs background="empty" color="secondary" orientation="horizontal" tabs={tabs} variant="fullWidth" />;
});
