import { Alert, AlertTitle, Box, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ThankYouNotification } from '../../../../component/common/thankYouNotification';
import { Insureds } from '../../../../component/layout/insureds';
import { NeedHelp } from '../../../../component/layout/needHelp';
import { PolicyCard } from '../../../../component/layout/policyCards';
import { useStores } from '../../../../service/state/store';
import SelfService from '../../../../service/util/selfService';
import { HomeRenewalNotification } from '../policy/renewals/components/homeRenewalNotification';

import { HomePolicyDetailsSection } from './components/homePolicyDetailsSection';

export const HomePolicyDetails: React.FC = observer(function HomePolicyDetails() {
  const {
    dashboardStore: {
      fetchRenewalDetails,
      insurance: {
        home: { policySelected },
      },
    },
    interfaceStore: { setIsLoading },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const isPolicyExpired = SelfService.isPolicyExpired(policySelected.policyStatus);

  useEffect(() => {
    setIsLoading(true);
    fetchRenewalDetails(policySelected.policyNumber, policySelected.effectiveDate, true);
    setIsLoading(false);
  }, []);

  return (
    <>
      {isPolicyExpired && (
        <Box sx={{ mb: 2 }}>
          <Alert className="expiry-box" severity={'error'} data-testid={'alert'}>
            <AlertTitle>
              <Typography variant="h4">{t('homePolicyDetailsPage.homePolicyHasEnded.title')}</Typography>
            </AlertTitle>
            <Typography variant="body2"> {t('homePolicyDetailsPage.homePolicyHasEnded.subtitle')}</Typography>
          </Alert>
        </Box>
      )}

      {policySelected.futureRenewalEffectiveDate === null && (
        <HomeRenewalNotification policy={policySelected} hasLabel={false} />
      )}
      {policySelected.futureRenewalEffectiveDate && (
        <ThankYouNotification
          policy={policySelected}
          insuranceDetails={policySelected.policyRenewalQuoteDetails.mainAddress().addressDetails()}
        />
      )}
      <PolicyCard policy={policySelected} isPolicyHeader={true} />
      <HomePolicyDetailsSection />
      <Insureds insureds={policySelected.insureds} />
      <NeedHelp />
    </>
  );
});
