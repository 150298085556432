import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { policyDetailUseStyles } from '../../component/layout/motorPolicyDetailsSection';
import { getDeviceSession } from '../../service/analytics';
import { useSegment } from '../../service/util/customHooks/useSegment';
import { ManageRenewalCard } from '../../view/pages/policies/policy/renewals/components/manageRenewalCardAlert';
import { ButtonOfSelectedPolicyProps } from '../common/button/commonButton';
import { useDispatchMakeAChange } from '../common/button/useDispatchMakeAChange';
import { useDispatchMakeAClaim } from '../common/button/useDispatchMakeAClaim';
import { NavigationCardSmall } from '../common/navigationCardSmall';

export const DoTodayCTASection: React.FC<ButtonOfSelectedPolicyProps> = observer(
  ({ policy }: ButtonOfSelectedPolicyProps) => {
    const { t } = useTranslation('myAccount');
    const { eventTrack } = useSegment();

    const classes = policyDetailUseStyles();

    const useStyles = makeStyles((theme) => ({
      [theme.breakpoints.only('xs')]: {
        tweakTopMargin: {
          '& p': {
            marginTop: '8px',
          },
        },
      },
    }));

    const additionalClassTweaks = useStyles();

    const CardClickedEvent = 'Card clicked';
    const history = useHistory();

    const dispatchMakeAChange = useDispatchMakeAChange({ policy });
    const dispatchMakeAClaim = useDispatchMakeAClaim();

    return (
      <div className={additionalClassTweaks.tweakTopMargin}>
        <Grid className={classes.gridContainer} container>
          <Typography variant="h2" className={classes.sectionTitle} color="secondary">
            {t('whatWouldYouLikeToDoTodayCTA.title')}
          </Typography>
          <Grid container spacing={2}>
            <ManageRenewalCard />

            <Grid item xs={6} lg={4} data-testid="doTodayCTA-CheckPolicy">
              <NavigationCardSmall
                color="secondary"
                description={t('whatWouldYouLikeToDoTodayCTA.CheckPolicyCTA.subtitle')}
                title={t('whatWouldYouLikeToDoTodayCTA.CheckPolicyCTA.title')}
                icon="checkPolicy"
                iconType="solid"
                showDescription
                trackEvent={() => {
                  const anchorId = document.getElementById('policySummaryAnchor');
                  if (anchorId) {
                    anchorId.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                  }
                  eventTrack(CardClickedEvent, {
                    componentID: 'DoTodayCheckPolicy',
                    label: 'CheckPolicy',
                    sessionId: getDeviceSession(),
                    url: window.location.href,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4} data-testid="doTodayCTA-GetDocuments">
              <NavigationCardSmall
                color="secondary"
                description={t('whatWouldYouLikeToDoTodayCTA.GetDocumentsCTA.subtitle')}
                title={t('whatWouldYouLikeToDoTodayCTA.GetDocumentsCTA.title')}
                icon="document"
                showDescription
                trackEvent={() => {
                  history.push('/policies/policy/documents');
                  eventTrack(CardClickedEvent, {
                    componentID: 'DoTodayGetDocuments',
                    label: 'GetDocuments',
                    sessionId: getDeviceSession(),
                    url: window.location.href,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4} data-testid="doTodayCTA-MakeAChange">
              <NavigationCardSmall
                color="secondary"
                description={t('whatWouldYouLikeToDoTodayCTA.MakeAChange.subtitle')}
                title={t('whatWouldYouLikeToDoTodayCTA.MakeAChange.title')}
                icon="makeChange"
                iconType="solid"
                showDescription
                trackEvent={() => {
                  eventTrack(CardClickedEvent, {
                    componentID: 'DoTodayMakeAChange',
                    label: 'MakeAChange',
                    sessionId: getDeviceSession(),
                    url: window.location.href,
                  });

                  dispatchMakeAChange.dispatch();
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4} data-testid="doTodayCTA-ManageClaim">
              <NavigationCardSmall
                color="secondary"
                description={t('whatWouldYouLikeToDoTodayCTA.ManageClaim.subtitle')}
                title={t('whatWouldYouLikeToDoTodayCTA.ManageClaim.title')}
                icon="claim"
                iconType="line"
                showDescription
                trackEvent={() => {
                  eventTrack(CardClickedEvent, {
                    componentID: 'DoTodayManageClaims',
                    label: 'ManageClaims',
                    sessionId: getDeviceSession(),
                    url: window.location.href,
                  });
                  dispatchMakeAClaim.dispatch();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  },
);
