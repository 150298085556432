import { makeStyles } from '@esure-cloud/react-components';

import Box from '@material-ui/core/Box';
import * as React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    panelPaddingTop: { paddingTop: theme.spacing(5) },
  },
}));

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={classes.panelPaddingTop}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
