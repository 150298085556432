import { Grid, makeStyles } from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeCarLocation } from '../../../../../../../../component/common/changeCarLocation';
import { ChangeOvernightAddress } from '../../../../../../../../component/common/changeOvernightAddress';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import {
  IInsuredAddress,
  InsuredAddress,
} from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { CONTACT_FLOW_TYPE } from '../../../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .home-address': {
      fontWeight: 'bold',
    },
    '& .overnight-address': {
      gap: spacing(2),
    },
    display: 'grid',
    gap: spacing(2),
    width: 'fit-content',
  },
}));

export const OvernightLocationRenewal: React.FC = observer(() => {
  const { setTitle, nextStep } = useRenewalStepperContext();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: {
        setVehicle,
        vehicle,
        insured: { address },
      },
    },
    interfaceStore: { chatBot },
  } = useStores();

  const { isLookupsFetched } = useLookups({ lookups: [LOOKUP.OVERNIGHT_LOCATION] });
  const chatAttrs = useMTAChatBotAttributes();
  const { overnightLocationCd, sameOvernightAddress, overnightAddress } = vehicle;

  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | undefined>();
  const [nightLocation, setNightLocation] = useState<string | undefined>(overnightLocationCd);
  const [sameOvernight, setSameOvernight] = useState(sameOvernightAddress);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.CarOvernight.title'));
    if (overnightAddress.postcode !== '') {
      setSelectedAddress(toJS(overnightAddress));
    }
  }, []);

  useEffect(() => {
    setOptions(lookupsByName[LOOKUP.OVERNIGHT_LOCATION]);
  }, [isLookupsFetched, lookupsByName]);

  const handleContinue = () => {
    if (attempts >= 3) {
      chatBot.startChat({
        contactFlowType: CONTACT_FLOW_TYPE.MTA,
        customContactAttributes: { ...chatAttrs?.customContactAttributes, ssMTAVehiclePostCode: 'true' },
        initiator: 'Chat with a specialist button',
      });
    } else {
      if (!sameOvernight && !selectedAddress) return;
      setVehicle({
        ...vehicle,
        overnightAddress: sameOvernight ? InsuredAddress.create() : (selectedAddress as IInsuredAddress),
        overnightLocationCd: nightLocation as string,
        overnightPostcode: sameOvernight ? address.postcode : (selectedAddress as IInsuredAddress).postcode,
        sameOvernightAddress: sameOvernight,
      });
      nextStep();
    }
  };

  return (
    <>
      <Grid container direction="column" xs item className={classes.root}>
        <ChangeOvernightAddress
          address={address}
          sameOvernight={sameOvernight}
          selectedAddress={selectedAddress}
          setAttempts={setAttempts}
          setSameOvernight={setSameOvernight}
          setSelectedAddress={setSelectedAddress}
        />
        {(selectedAddress || sameOvernight) && (
          <ChangeCarLocation
            location={nightLocation}
            options={options}
            setLocation={setNightLocation}
            tooltipDescription={t('MTAJourney.CarUsage.CarOvernight.carKeptOvernight')}
            tooltipTitle={t('tooltips.changeYourCar.overnightAddress')}
          />
        )}
      </Grid>

      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_OVERNIGHT_LOCATION_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_OVERNIGHT_LOCATION_CANCEL}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_OVERNIGHT_LOCATION_SAVE, handleContinue }}
      />
    </>
  );
});
