import { ActiveClaim } from '../../view/pages/claims/activeClaim';
import { ActiveClaimSummary } from '../../view/pages/claims/claimSummary';
import { HomeActiveClaim } from '../../view/pages/claims/homeActiveClaim';
import { HomeChecker } from '../../view/pages/claims/homeChecker';
import { HomeEmergencyCovered } from '../../view/pages/claims/homeEmergencyCovered';
import { HomeEmergencyNotCovered } from '../../view/pages/claims/homeEmergencyNotCovered';
import { LegalProtectionCovered } from '../../view/pages/claims/legalProtectionCovered';
import { LegalProtectionNotCovered } from '../../view/pages/claims/legalProtectionNotCovered';
import { PestCovered } from '../../view/pages/claims/pestCovered';
import { PestNotCovered } from '../../view/pages/claims/pestNotCovered';
import { WhatYouCanClaim } from '../../view/pages/claims/whatYouCanClaim';
import { SelectedPolicyDocuments } from '../../view/pages/documents/selectedPolicyDocuments';
import { OlderDocuments } from '../../view/pages/documents/selectedPolicyDocuments/olderDocuments';
import { SelectedPayment } from '../../view/pages/payments/selectedPayment';
import { DirectDebitPayment } from '../../view/pages/payments/selectedPayment/directDebitPayment';
import { HowToMakeAClaim } from '../../view/pages/policies/homePolicy/howToMakeAClaim';
import { WhatsIncluded } from '../../view/pages/policies/homePolicy/whatsIncluded';
import { MakeChange } from '../../view/pages/policies/policy/makeAchange';
import { MakePayment } from '../../view/pages/policies/policy/makeAchange/MakePayment';
import { PaymentConfirmation } from '../../view/pages/policies/policy/makeAchange/MakePayment/paymentConfirmation';
import { MTALayout } from '../../view/pages/policies/policy/makeAchange/mta';
import { CarDetails } from '../../view/pages/policies/policy/makeAchange/mta/carDetails';
import { MoreChanges } from '../../view/pages/policies/policy/makeAchange/mta/carDetails/components/moreChanges';
import { PaymentFailed } from '../../view/pages/policies/policy/makeAchange/mta/carDetails/components/paymentFailed';
import { Drivers } from '../../view/pages/policies/policy/makeAchange/mta/drivers';
import { YourDetails } from '../../view/pages/policies/policy/makeAchange/mta/yourDetails';
import { IfYouClaim } from '../../view/pages/policies/policy/policyDetails/ifYouClaim';
import { TypesOfUse } from '../../view/pages/policies/policy/policyDetails/typesOfUse';
import { RenewalPayment } from '../../view/pages/policies/policy/renewals/RenewalPayment';
import { RenewalPaymentFailed } from '../../view/pages/policies/policy/renewals/RenewalPayment/renewalPaymentFailed';
import { RenewalPaymentSuccess } from '../../view/pages/policies/policy/renewals/RenewalPayment/renewalPaymentSuccess';
import { MotorRenewalChangeConfirmation } from '../../view/pages/policies/policy/renewals/motor/motorRenewalChangeConfirmation';
import { RenewalChangePending } from '../../view/pages/policies/policy/renewals/motor/renewalChangePending';
import { AutoRenewal } from '../../view/pages/policies/policy/renewals/pages/autoRenewal';
import { AutoRenewalConfirmation } from '../../view/pages/policies/policy/renewals/pages/autoRenewalConfirmation';
import { RenewalSetupSuccess } from '../../view/pages/policies/policy/renewals/pages/renewalSetupSuccess';
import { RenewalsDecline } from '../../view/pages/policies/policy/renewals/pages/renewalsDecline';
import { ROUTE } from '../constant';
import { RouteData } from '../routeConfig';

export const claimsChildren: RouteData[] = [
  {
    component: WhatYouCanClaim,
    exact: false,
    path: ROUTE.START_NEW_CLAIM,
  },
  {
    component: ActiveClaim,
    exact: false,
    path: ROUTE.ACTIVE_CLAIM,
  },
  {
    component: HomeActiveClaim,
    exact: false,
    path: ROUTE.HOME_ACTIVE_CLAIM,
  },
  {
    component: HomeEmergencyCovered,
    exact: false,
    path: ROUTE.HOME_EMERGENCY_COVERED,
  },
  {
    component: HomeEmergencyNotCovered,
    exact: false,
    path: ROUTE.HOME_EMERGENCY_NOT_COVERED,
  },
  {
    component: PestCovered,
    exact: false,
    path: ROUTE.PEST_COVERED,
  },
  {
    component: PestNotCovered,
    exact: false,
    path: ROUTE.PEST_NOT_COVERED,
  },
  {
    component: LegalProtectionCovered,
    exact: false,
    path: ROUTE.LEGAL_PROTECTION_COVERED,
  },
  {
    component: LegalProtectionNotCovered,
    exact: false,
    path: ROUTE.LEGAL_PROTECTION_NOT_COVERED,
  },
  {
    component: HomeChecker,
    exact: false,
    path: ROUTE.HOME_CHECKER,
  },
  {
    component: ActiveClaimSummary,
    exact: false,
    path: ROUTE.ACTIVE_CLAIM_SUMMARY,
  },
];

const selectedPaymentChildren: RouteData[] = [
  {
    component: DirectDebitPayment,
    exact: false,
    path: ROUTE.PAYMENT_ACTIVITY,
  },
  {
    component: DirectDebitPayment,
    exact: false,
    path: ROUTE.POLICY_BREAKDOWN,
  },
  {
    component: DirectDebitPayment,
    exact: false,
    path: ROUTE.INSTALMENTS,
  },
];

const changeCarClusterChildren: RouteData[] = [
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.CHANGE_YOUR_CAR,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.CAR_MILEAGE_AND_USAGE,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.CAR_MODIFICATIONS,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.CHANGE_YOUR_REG_NO,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.MORE_CHANGES,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.MTA_REVIEW_CHANGES,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.MTA_QUOTE_READY,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.OTHER_CHANGES,
  },
];

const driversClusterChildren: RouteData[] = [
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.ADD_DRIVER,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.CHANGE_DRIVER_DETAILS,
  },
  {
    component: MTALayout,
    exact: true,
    path: ROUTE.REMOVE_DRIVER,
  },
];

const motorMakeChangeChildren: RouteData[] = [
  {
    children: [...changeCarClusterChildren],
    component: CarDetails,
    exact: true,
    path: ROUTE.CAR_DETAILS,
  },
  {
    component: MakePayment,
    exact: true,
    path: ROUTE.CHANGE_PAYMENT,
  },
  {
    component: PaymentConfirmation,
    exact: true,
    path: ROUTE.CHANGE_PAYMENT_CONFIRMATION,
  },
  {
    component: MotorRenewalChangeConfirmation,
    exact: true,
    path: ROUTE.RENEWAL_CHANGE_CONFIRMATION,
  },
  {
    component: RenewalChangePending,
    exact: true,
    path: ROUTE.RENEWAL_CHANGE_PENDING,
  },
  {
    component: RenewalPaymentSuccess,
    exact: true,
    path: ROUTE.RENEWAL_PAYMENT_SUCCESS,
  },
  {
    component: RenewalSetupSuccess,
    exact: true,
    path: ROUTE.RENEWAL_SETUP_SUCCESS,
  },
  {
    component: PaymentFailed,
    exact: true,
    path: ROUTE.CHANGE_PAYMENT_FAILED,
  },
  {
    component: RenewalPaymentFailed,
    exact: true,
    path: ROUTE.RENEWAL_PAYMENT_FAILED,
  },
  {
    children: [...driversClusterChildren],
    component: Drivers,
    exact: true,
    path: ROUTE.DRIVERS,
  },
  {
    children: [
      {
        component: MTALayout,
        exact: true,
        path: ROUTE.CHANGE_HOME_ADDRESS,
      },
      {
        component: MTALayout,
        exact: true,
        path: ROUTE.CHANGE_PERSONAL_DETAILS,
      },
    ],
    component: YourDetails,
    exact: true,
    path: ROUTE.YOUR_DETAILS,
  },
];
export const motorPolicyDetailsChildren: RouteData[] = [
  {
    component: TypesOfUse,
    exact: false,
    path: ROUTE.TYPES_OF_USE,
  },
  {
    component: IfYouClaim,
    exact: false,
    path: ROUTE.IF_YOU_CLAIM,
  },
  {
    children: [...motorMakeChangeChildren],
    component: MakeChange,
    exact: true,
    path: ROUTE.MAKE_CHANGE,
  },
  {
    component: RenewalsDecline,
    exact: true,
    path: ROUTE.YOUR_RENEWAL_DECLINE,
  },
  {
    children: [
      {
        component: AutoRenewalConfirmation,
        exact: true,
        path: ROUTE.YOUR_RENEWAL_DETAILS_AUTORENEWAL_CONFIRMATION,
      },
    ],
    component: AutoRenewal,
    exact: true,
    path: ROUTE.YOUR_RENEWAL_DETAILS,
  },
  {
    component: RenewalPayment,
    exact: false,
    path: ROUTE.RENEWAL_PAYMENT,
  },
  {
    children: [
      {
        component: OlderDocuments,
        exact: false,
        path: ROUTE.OLDER_DOCUMENTS,
      },
    ],
    component: SelectedPolicyDocuments,
    exact: false,
    path: ROUTE.DOCUMENTS,
  },
  {
    children: [...selectedPaymentChildren],
    component: SelectedPayment,
    exact: false,
    path: ROUTE.PAYMENTS,
  },
  {
    component: WhatsIncluded,
    exact: false,
    path: ROUTE.WHATS_INCLUDED,
  },
  {
    component: HowToMakeAClaim,
    exact: false,
    path: ROUTE.HOW_TO_MAKE_A_CLAIM_HOME,
  },
  {
    component: WhatsIncluded,
    exact: true,
    path: ROUTE.WHATS_INCLUDED,
  },
  {
    component: MoreChanges,
    exact: true,
    path: ROUTE.MORE_CHANGES,
  },
];
