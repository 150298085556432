import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import CommonAccordion, { CommonAccordionProps } from '../../../../../../component/common/commonAccordion';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { COVERAGE, DATE_TYPES, LOOKUP, ROUTE } from '../../../../../../service/constant';
import { IRenewalDriver } from '../../../../../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../../../../../service/state/store';
import { formatDate } from '../../../../../../service/util/formatDate';
import RenewalCostCard from '../components/renewalCostCard';
import { ClaimComp } from '../yourCoverDetails/claimItem';
import { ConvictionComp } from '../yourCoverDetails/convictionItem';
import { UsageOfCarAccordion } from '../yourCoverDetails/usageOfCarAccordion';
import { YourAdditionalDriverAccordion, driverEqualString } from '../yourCoverDetails/yourAdditionalDriverAccordion';
import { YourCarAccordion } from '../yourCoverDetails/yourCarAccordion';
import { YourDetailsAccordion } from '../yourCoverDetails/yourDetailsAccordion';
import { YourExcesses } from '../yourCoverDetails/yourExcesses';
import { YourExtraCover } from '../yourCoverDetails/yourExtraCover';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  accordionContainer: {
    gap: spacing(1),
    marginBottom: spacing(2),
  },
  boldItem: {
    fontWeight: 700,
  },
  gridSpacing: {
    marginBottom: spacing(2),
  },
  paddingTop: {
    paddingTop: spacing(1),
  },
  recordSpacing: {
    padding: spacing(0.5, 2),
  },
  root: {
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiDivider-root': {
      marginBottom: spacing(1),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    '& .accordionGrid': {
      display: 'grid',
    },
    '& .classNameBorder': { border: 'transparent' },
    '& .classNameBorder:nth-child(10)': {
      '& .accordionContent': { padding: 0 },
    },
    '& .classNameBorder:nth-child(3), .classNameBorder:nth-child(4)': {
      '& .accordionContent': { padding: 0 },
    },
    '& .columnsContainer': { gap: spacing(1), padding: spacing(2, 1.2) },
    '& .costLastYear .card__details-default': {
      backgroundColor: palette.grey[100],
      border: `2px solid ${palette.grey[500]}`,
    },
    '& .footerClass': { margin: spacing(0.9) },
    [breakpoints.up('xs')]: {
      '& .column-no-title': {
        marginTop: 0,
        padding: 0,
      },
    },
    '& .headerTitle': {
      color: palette.secondary.main,
    },
    '& .multiGridItem': {
      padding: spacing(1, 0.7),
    },
    '& .newQuote .card__details-default': {
      backgroundColor: `${palette.primary.light}20`,
      border: `2px solid ${palette.primary.light}`,
    },
    '& .originalRenewal .card__details-default': {
      backgroundColor: `${palette.secondary.light}20`,
      border: `2px solid ${palette.secondary.light}`,
    },
  },
  stepNumber: {
    alignItems: 'center',
    backgroundColor: `${palette.secondary.main}`,
    borderRadius: '50%',
    color: palette.secondary.contrastText,
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    marginRight: '10px',
    width: '32px',
  },
  title: { margin: spacing(2.5, 0) },
}));

export interface YourQuoteProps {
  route: ROUTE;
}

export const YourQuote: React.FC<YourQuoteProps> = observer(function YourQuote({ route }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<number | null>(null);
  const quoteType = route === ROUTE.YOUR_NEW_QUOTE ? 'yourNewQuote' : 'yourSavedQuote';

  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
    renewalChangeStore: {
      renewalDetails: {
        insured: { person },
        drivers,
        vehicle,
      },
      setChangeMade,
      getChangeMade,
      setPendingMTAQuoteSelected,
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: {
            policyRenewalQuoteDetails: {
              policyNumber,
              startDate,
              currentYearMonthlyCost,
              previousYearMonthlyCost,
              currentYearTotalCost,
              previousYearTotalCost,
              pendingMTAQuote,
            },
          },
        },
      },
      lookups: { getLookupValue: getLookup },
    },
  } = useStores();

  const changeMade = getChangeMade(policyNumber);

  const handleClick = () => {
    if (changeMade) {
      setChangeMade(policyNumber, false);
    }
    history.push(ROUTE.YOUR_COVER_DETAILS);
  };
  const handleSummaryClick = () => {
    setPendingMTAQuoteSelected(true);
    history.push(ROUTE.YOUR_RENEWAL_SUMMARY);
  };

  const ycdButtonID =
    route === ROUTE.YOUR_NEW_QUOTE
      ? COMPONENT_ID.RENEWAL_YOUR_NEW_QUOTE_CHECK_DETAILS
      : COMPONENT_ID.RENEWAL_YOUR_SAVED_QUOTE_CHECK_DETAILS;
  const continueButtonID =
    route === ROUTE.YOUR_NEW_QUOTE
      ? COMPONENT_ID.RENEWAL_YOUR_NEW_QUOTE_CONTINUE
      : COMPONENT_ID.RENEWAL_YOUR_SAVED_QUOTE_CONTINUE;

  const findCoverage = (coverage: COVERAGE) => vehicle.coverages.find((x) => x.coverageCd === coverage);

  const mainDriver = drivers.length > 1 ? drivers.find((driver) => driver.main === true) : drivers[0];

  const insuredPersonDrivers: IRenewalDriver[] = drivers.filter(
    (driver) => driverEqualString(driver.person) === driverEqualString(person),
  );

  const insuredPersonDriver = insuredPersonDrivers[0] ?? drivers[0];

  if (typeof insuredPersonDriver === 'undefined' || typeof insuredPersonDriver.claims === 'undefined') {
    // store hasnt updated correctly, or page loaded before store did - go back to quotes page
    history.push(ROUTE.YOUR_RENEWAL_QUOTES);
    return null;
  }

  const hasAnyClaim = insuredPersonDriver.claims.length > 0;
  const hasAnyConviction = insuredPersonDriver.convictions.length > 0;

  const accordions: CommonAccordionProps[] = [
    {
      columns: [
        {
          description: formatDate(startDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.renewalDate'),
        },
        {
          description: getLookup(LOOKUP.COVER_TYPE, vehicle.coverTypeCd),
          subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.coverType'),
        },
        {
          description: `${person.firstName} ${person.surname}`,
          subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.policyHolder'),
        },
        {
          description: mainDriver ? `${mainDriver.person.firstName} ${mainDriver.person.surname}` : '',
          subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.mainDriver'),
        },
        {
          description: findCoverage(COVERAGE.DRIVING_OTHER_CARS)
            ? t('policyManagement.yourCoverDetails.yourCoverAccordion.yes')
            : t('labels.no'),
          subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.otherCars'),
        },
      ],
      headerTitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.title'),
    },
    {
      columns: [{ subtitle: t('policyManagement.yourCoverDetails.insuranceHistory.details') }],
      headerTitle: t('policyManagement.yourCoverDetails.insuranceHistory.title'),
    },
    {
      customComponent: hasAnyClaim ? (
        <ClaimComp />
      ) : (
        <Typography variant="h4" className={classes.recordSpacing}>
          {t('policyManagement.yourCoverDetails.claimAccordion.none')}
        </Typography>
      ),
      footerSubtitle: t('policyManagement.yourCoverDetails.claimAccordion.summary'),
      headerTitle: t('policyManagement.yourCoverDetails.claimAccordion.title'),
    },
    {
      customComponent: hasAnyConviction ? (
        <ConvictionComp />
      ) : (
        <Typography variant="h4" className={classes.recordSpacing}>
          {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.none')}
        </Typography>
      ),
      footerSubtitle: t('policyManagement.yourCoverDetails.drivingOffencesAccordion.summary'),
      headerTitle: t('policyManagement.yourCoverDetails.drivingOffencesAccordion.title'),
    },
    {
      columns: [{ subtitle: t('policyManagement.yourCoverDetails.criminalConvictions.details') }],
      headerTitle: t('policyManagement.yourCoverDetails.criminalConvictions.title'),
    },
    {
      customComponent: <YourCarAccordion showEditButton={false} />,
      headerTitle: t('policyManagement.yourCoverDetails.yourCarAccordion.title'),
    },
    {
      customComponent: <UsageOfCarAccordion showEditButton={false} />,
      headerTitle: t('policyManagement.yourCoverDetails.usageCarAccordion.title'),
    },
    {
      customComponent: <YourDetailsAccordion showEditButton={false} />,
      headerTitle: t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.title'),
    },
    {
      customComponent: <YourAdditionalDriverAccordion showEditButton={false} />,
      headerTitle: t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.title'),
    },
    {
      customComponent: <YourExtraCover showEditButton={false} />,
      headerTitle: t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.title'),
    },
  ];

  const policySelected = isHome ? home.policySelected : auto.policySelected;

  const isDDCustomer = policySelected.paymentPlan !== '' && policySelected.paymentPlan !== 'YearlyCardPayment';

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.gridSpacing}>
        <BlueBanner title={t(`policyManagement.${quoteType}.title`)} route={ROUTE.YOUR_RENEWAL_QUOTES} />
        <Typography className={classes.title} variant="h1" color="secondary">
          {t(`policyManagement.${quoteType}.subTitle`)}
        </Typography>
        <Grid container className={clsx('newQuote', classes.gridSpacing)}>
          {pendingMTAQuote && (
            <RenewalCostCard
              title={t('policyManagement.yourNewQuote.newQuoteBox.title')}
              value={t('core:price.pound', {
                value: isDDCustomer ? pendingMTAQuote.currentYearMonthlyCost : pendingMTAQuote.currentYearTotalCost,
              })}
              ddValue={t('core:price.pound', {
                value: pendingMTAQuote.currentYearTotalCost,
              })}
              showDD={isDDCustomer}
              showSameInfo={false}
              subText={t('policyManagement.yourNewQuote.newQuoteBox.subText')}
            />
          )}
        </Grid>
        <Grid container className={clsx('costLastYear', classes.gridSpacing)}>
          <RenewalCostCard
            title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleLastYear')}
            value={t('core:price.pound', {
              value: isDDCustomer ? previousYearMonthlyCost : previousYearTotalCost,
            })}
            ddValue={t('core:price.pound', {
              value: previousYearTotalCost,
            })}
            showDD={isDDCustomer}
            showSameInfo={false}
            subText={t('policyManagement.yourNewQuote.updatedCost.subText')}
          />
        </Grid>
        <Grid container className={clsx('originalRenewal', classes.gridSpacing)}>
          <RenewalCostCard
            hasTooltip={false}
            title={t('policyManagement.yourSavedQuote.originalRenewalQuote.title')}
            value={t('core:price.pound', {
              value: isDDCustomer ? currentYearMonthlyCost : currentYearTotalCost,
            })}
            showDD={isDDCustomer}
            ddValue={t('core:price.pound', {
              value: currentYearTotalCost,
            })}
            showSameInfo={false}
            subText={t('policyManagement.yourNewQuote.originalRenewalQuote.subText')}
          />
        </Grid>
        <Typography paragraph variant="h2" color="secondary">
          {t(`policyManagement.${quoteType}.happyWithQuote`)}
        </Typography>
        <Typography variant="body1">
          <Trans t={t} i18nKey={`policyManagement.${quoteType}.happyWithQuoteStatement`} />
        </Typography>
      </Grid>
      <CommonContainer color="secondary" variant="outlined" className={classes.gridSpacing}>
        <Grid container wrap="nowrap">
          <Typography align="center" variant="h5" className={classes.stepNumber}>
            1
          </Typography>
          <Typography paragraph variant="h3">
            {t(`policyManagement.${quoteType}.checkYourDetails.title`)}
          </Typography>
        </Grid>
        <Typography paragraph variant="body1">
          {t(`policyManagement.${quoteType}.checkYourDetails.details`)}
        </Typography>

        <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
          {accordions.map((props, index) => (
            <CommonAccordion
              key={index}
              onChange={() => setOpen(open === index ? null : index)}
              expanded={open === index}
              {...props}
            />
          ))}
        </Grid>

        <Typography paragraph variant="h3">
          {t(`policyManagement.${quoteType}.checkYourDetails.needChanges`)}
        </Typography>
        <Grid item sm={12} md={4}>
          <CommonButton
            onClick={handleClick}
            data-testid={ycdButtonID}
            size="large"
            variant="outlined"
            color="inherit"
            text={t(`policyManagement.${quoteType}.checkYourDetails.btnText`)}
          />
        </Grid>
      </CommonContainer>
      <CommonContainer color="secondary" variant="outlined" className={classes.gridSpacing}>
        <Grid container wrap="nowrap">
          <Typography align="center" variant="h5" className={classes.stepNumber}>
            2
          </Typography>
          <Typography paragraph variant="h3">
            {t(`policyManagement.${quoteType}.checkYourExcesses`)}
          </Typography>
        </Grid>
        <Grid>
          <YourExcesses showEditButton={false} />
        </Grid>
      </CommonContainer>
      <CommonContainer color="secondary" variant="outlined" className={classes.gridSpacing}>
        <Grid container wrap="nowrap">
          <Typography align="center" variant="h5" className={classes.stepNumber}>
            3
          </Typography>
          <Typography paragraph variant="h3">
            {t(`policyManagement.${quoteType}.yourRenewalSummary.title`)}
          </Typography>
        </Grid>
        <Typography paragraph variant="body1">
          {t(`policyManagement.${quoteType}.yourRenewalSummary.details`)}
        </Typography>
        <Grid item sm={12} md={4}>
          <CommonButton
            onClick={handleSummaryClick}
            data-testid={continueButtonID}
            size="large"
            variant="contained"
            color="primary"
            text={t(`policyManagement.${quoteType}.yourRenewalSummary.btnText`)}
          />
        </Grid>
      </CommonContainer>
    </Grid>
  );
});
