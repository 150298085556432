import { CircularProgress, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .container': {
      '&-text': {
        maxWidth: '240px',
      },
      gap: spacing(4),
    },
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));

export const FetchQuoteDialog: React.FC<{ content?: string; title: string }> = ({ content, title }) => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.root}>
      <Grid container item className="container" alignItems="center" direction="column">
        <SVGWrapper alt="car" src="/fetch-quote.png" />
        <Typography variant="h2" align="center" className="container-text">
          {title}
        </Typography>
        <CircularProgress color="secondary" />
        <Typography variant="body2">{content ?? t('MTAJourney.pleaseWait')}</Typography>
      </Grid>
    </Grid>
  );
};
