import {
  Grid,
  Alert as MUIAlert,
  AlertProps as MUIAlertProps,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { GridProps } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export interface AlertProps extends GridProps {
  date?: string;
  link?: string;
  message: string;
  route?: string;
  severity: MUIAlertProps['severity'];
  title?: string;
}

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    '& .alert-container': {
      [breakpoints.down('xs')]: {
        width: '100%',
      },
      '&__link': {
        color: palette.secondary.main,
        textDecoration: 'none',
      },
      '&__message': {
        color: palette.secondary.dark,
      },
      '&__title': {
        color: palette.info.dark,
      },
    },
    padding: spacing(4, 0, 2, 0),
  },
}));

export const Alert: React.FC<AlertProps> = (props) => {
  const { link, message, severity, title, route, date, className } = props;

  const classes = useStyles();

  return (
    <Grid className={clsx(classes.root, className)}>
      <MUIAlert className="alert-container" severity={severity} variant="standard">
        {title && (
          <Typography className="alert-container__title" variant="body1">
            {title}
          </Typography>
        )}
        <Typography className="alert-container__message" variant="body2">
          {date ? message.replace('{value}', date) : message}
        </Typography>
        {route && (
          <Link to={route} className="alert-container__link">
            <Typography variant="h5">{link}</Typography>
          </Link>
        )}
      </MUIAlert>
    </Grid>
  );
};
