import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ClassOfUseForm from '../../../../../../../../component/common/mta/usage';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

export const EditClassOfUse: React.FC<EditPageProps> = observer(function EditClassOfUse({ handleBack }) {
  const {
    dashboardStore: {
      lookups: { lookupsByName, getLookupValue: getLookup },
    },
    mtaStore: {
      pendedMTA: { vehicle, setVehicle },
    },
  } = useStores();
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.USAGE, LOOKUP.MILEAGE],
  });
  const { t } = useTranslation('myAccount');
  const [usageOptions, setUsageOptions] = useState<ILookupItem[] | undefined>();
  const [mileageOptions, setMileageOptions] = useState<string[]>();
  const [socialMileageCode, setSocialMileageCode] = useState<string | null>(vehicle.socialMileageCode);
  const [businessMileageCode, setBusinessMileageCode] = useState<string | null>(vehicle.businessMileageCode);
  const [classOfUse, setClassOfUse] = useState(vehicle.classOfUse);
  const hasBusinessMileage = classOfUse !== '033' && classOfUse !== '001';

  const { setTitle } = useStepperContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.USAGE] && lookupsByName[LOOKUP.MILEAGE] !== undefined) {
      setUsageOptions(lookupsByName[LOOKUP.USAGE]);
      setMileageOptions(lookupsByName[LOOKUP.MILEAGE].map((a) => a.code));
    }
  }, [isLookupsFetched, lookupsByName]);
  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.usageEdit.title'));
  }, []);

  const isValid = () => {
    return (businessMileageCode === null && hasBusinessMileage) || socialMileageCode === null ? false : true;
  };
  const handlePendedMta = () => {
    if (isValid()) {
      setVehicle({
        ...vehicle,
        businessMileageCode: hasBusinessMileage ? businessMileageCode : null,
        classOfUse,
        socialMileageCode: socialMileageCode ?? '',
      });
    }
    handleBack();
  };

  return (
    <>
      <ClassOfUseForm
        usageOptions={usageOptions}
        mileageOptions={mileageOptions}
        classOfUse={classOfUse}
        setClassOfUse={setClassOfUse}
        socialMileageCode={socialMileageCode}
        setSocialMileageCode={setSocialMileageCode}
        businessMileageCode={businessMileageCode}
        setBusinessMileageCode={setBusinessMileageCode}
        hasBusinessMileage={hasBusinessMileage}
        getLookup={getLookup}
      />
      <EditFooter id="editClassOfUse" validPage={isValid()} handleSave={handlePendedMta} handleBack={handleBack} />
    </>
  );
});
