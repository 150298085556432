import { AutoRenewalInfo } from '../autoRenewalInfo';
import { AutoRenewalPolicy } from '../autoRenewalPolicy';
import { AutoRenewalPreference } from '../autoRenewalPreference';

export const AutoRenewalDrawer: React.FC = () => {
  return (
    <>
      <AutoRenewalInfo />
      <AutoRenewalPolicy />
      <AutoRenewalPreference />
    </>
  );
};
