import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CustomTabs, TabProps } from '../../../component/common/customTabs';
import { ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';

import { AccountManagement } from './accountManagement';
import { Communication } from './communication';

export const SettingsApp: React.FC = observer(function SettingsApp() {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isTablet, isDesktop },
  } = useStores();

  const tabs: TabProps[] = [
    {
      components: [{ component: <AccountManagement />, path: ROUTE.ACCOUNT }],
      disabled: false,
      label: t('settings.account'),
    },
    {
      components: [{ component: <Communication />, path: ROUTE.COMMUNICATION }],
      disabled: false,
      label: t('settings.communication'),
    },
  ];

  return (
    <CustomTabs
      activeTab={0}
      background="empty"
      color="secondary"
      orientation="horizontal"
      tabs={tabs}
      variant={isTablet || isDesktop ? 'standard' : 'fullWidth'}
    />
  );
});
