import { makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { CommonButton } from '../../common/button/commonButton';

import { ICard } from './index';

const useStyles = makeStyles(() => ({
  bannerCard: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    marginRight: 0,
    minWidth: '100%',
    padding: '15px',
  },
  gridContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    padding: '0 15px',
  },
  gridImage: {
    borderRadius: '5px',
    width: '100%',
  },
  gridImageContainer: {
    flex: 1,
  },
  gridLayout: {
    flexDirection: 'row',
  },
}));

export const BannerCard: React.FC<{ card: ICard }> = observer(function BannerCard({ card }) {
  const classes = useStyles();

  const onClick = () => {
    window.braze?.logContentCardClick(card);
    window.open(card.url, '_blank');
  };

  const renderButton = () => {
    if (!card.url) {
      return null;
    }
    return (
      <div>
        <CommonButton onClick={onClick} text={card.linkText ?? 'Learn More'} />
      </div>
    );
  };

  const renderStandardHtml = () => {
    return (
      <div
        className={classes.bannerCard}
        style={{ backgroundColor: card.extras?.backgroundColor ?? '#FFF' }}
        data-testid="standard-html"
      >
        <img className={classes.gridImage} src={card.imageUrl} alt={card.title} />
        <h2>{card.title}</h2>
        <p>{card.description}</p>
        {renderButton()}
      </div>
    );
  };

  const renderGridHtml = () => {
    return (
      <div
        className={clsx(classes.gridLayout, classes.bannerCard)}
        style={{ backgroundColor: card.extras?.backgroundColor ?? '#FFF' }}
      >
        <div className={classes.gridImageContainer}>
          <img className={classes.gridImage} src={card.imageUrl} alt={card.title} />
        </div>
        <div className={classes.gridContent}>
          <h2>{card.title}</h2>
          <p>{card.description}</p>
          {renderButton()}
        </div>
      </div>
    );
  };

  const maxWidth = 600;
  if (window.innerWidth <= maxWidth || card.extras?.layout !== 'grid') {
    return renderStandardHtml();
  }
  return renderGridHtml();
});
