import { Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { LOOKUP } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../service/util/customHooks/useDecodedLookups';
import IconSure from '../../common/icon';
import { availablePacks } from '../lifestylePacks';

const useStyles = makeStyles((theme) => ({
  lifeStylePacksContainer: {
    '& a': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(3),
  },
}));

export const LifestylePacksDrawer: React.FC<{ code: string }> = observer(function LifestylePacksDrawer({ code }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policyCover: { lifeStylePacks },
        },
      },
      lookups: { getLookupValue },
    },
  } = useStores();

  const packTitle = lifeStylePacks.find((x) => x.coverageCd === code);
  const pack = availablePacks[code];
  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ options: ['vehicle'] });

  return isDecodedLookupsFetched ? (
    <Grid container spacing={3} direction="column" className={classes.lifeStylePacksContainer}>
      <Grid item>
        <Typography variant="h2">{getLookupValue(LOOKUP.COVERAGE_DESCRIPTION, packTitle?.coverageCd ?? '')}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="h5">{t('lifestylePacks.drawer.included')}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          {pack.map((item) => (
            <Grid item container direction="row" wrap="nowrap" spacing={1} key={item.title}>
              <Grid item>
                <IconSure icon="check" color="success" />
              </Grid>
              <Grid item container>
                <Grid item>
                  <Typography variant="h5">{item.title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{item.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid item container spacing={2}>
            <Grid item>
              <IconSure icon="download" color="secondary" size="17" />
            </Grid>
            <Grid item>
              <Link variant="body2">{t('lifestylePacks.drawer.document')}</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
});
