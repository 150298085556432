import { Grid, Link, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IconSure from '../../../../../../component/common/icon';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { DATE_TYPES, DIALOG_TYPE, ENDORSEMENT_REASONS, LOOKUP, ROUTE } from '../../../../../../service/constant';
import { IPerson } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IRenewalDriver, IRenewalPerson } from '../../../../../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../../../../../service/state/store';
import { formatDate } from '../../../../../../service/util/formatDate';
import { ReviewAllDivider, ReviewAllEditButton } from '../../makeAchange/mta/common/steps/reviewAll';

import { formatLicense } from './yourDetailsAccordion';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  columnsContainer: {
    flexWrap: 'nowrap',
    gap: ({ isDesktop }) => (isDesktop ? spacing(1) : 0),
    padding: spacing(2, 2),
  },
  footerIcon: {
    display: 'flex',
    marginTop: '8px',
  },
  footerText: {
    margin: '4px',
  },
  root: {
    marginTop: spacing(3),
  },
  subtitle: {
    padding: spacing(0.5, 2, 0),
  },
}));

export const secondaryOccupationString = (secondaryOccupation: boolean | null, lookup: string, noneAdded: string) => {
  return secondaryOccupation !== null && secondaryOccupation ? lookup : noneAdded;
};

export const DriverItem: React.FC<{
  driver: IRenewalDriver;
  index: number;
  isDesktop: boolean;
  setRemovedDrivers: (a: string[]) => void;
  setSelectedDriverUUID: (a: string) => void;
  showEditButton?: boolean;
}> = ({ driver, setSelectedDriverUUID, isDesktop, index, showEditButton = true }) => {
  const classes = useStyles({ isDesktop });
  const { t } = useTranslation('myAccount');
  const history = useHistory();

  const {
    interfaceStore: {
      dialog: { openDialog },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
    renewalChangeStore: {
      renewalDetails: { addEndorsementReason },
    },
  } = useStores();

  const person = driver.person;

  const removeDriver = () => {
    addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
    history.push(ROUTE.YOUR_COVER_DETAILS_REMOVE_DRIVER);
  };

  return (
    <>
      <Grid container className={classes.subtitle}>
        <Typography paragraph variant="h4">
          {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.subtitle')} {index + 1}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.fullName')} </Typography>
          <Typography variant="h4">
            {person.firstName} {person.surname}
          </Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_DRIVER_NAME);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_NAME}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.dateOfBirth')} </Typography>
          <Typography variant="h4">{formatDate(person.dateOfBirth, DATE_TYPES.SHORT)}</Typography>
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.resident')} </Typography>
          <Typography variant="h4">
            {driver.person.residentSinceBirth
              ? t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.residentYes')
              : `Resident since ${formatDate(driver.person.residentSinceMonthsCd.toString(), DATE_TYPES.MONTH)}, ${
                  driver.person.residentSinceYearsCd
                }`}
          </Typography>
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.relationshipStatus')}{' '}
          </Typography>
          <Typography variant="h4">{getLookup(LOOKUP.MARITAL_STATUS, person.maritalStatusCd)}</Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_MARITAL_STATUS);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_RELATIONSHIP_STATUS}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.relationshipToPolicyHolder')}{' '}
          </Typography>
          <Typography variant="h4">{getLookup(LOOKUP.DRIVER_RELATIONSHIP, driver.relationshipToProposerCd)}</Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_DRIVER_RELATIONSHIP);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_RELATIONSHIP_TO_POLICYHOLDER}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.occupation')} </Typography>
          <Typography variant="h4">
            {getLookup(LOOKUP.OCCUPATION, person.primaryOccupationCd ? person.primaryOccupationCd : '')}
          </Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_OCCUPATION}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.secondaryOccupation')}{' '}
          </Typography>
          <Typography variant="h4">
            {secondaryOccupationString(
              person.secondaryOccupation,
              getLookup(LOOKUP.OCCUPATION, person.secondaryOccupationCd ?? ''),
              t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.noneAdded'),
            )}
          </Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_SECONDARY_OCCUPATION}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.drivingLicence')}{' '}
          </Typography>
          <Typography variant="h4">
            {formatLicense(
              getLookup(LOOKUP.LICENCE_TYPE, driver.licence.licenceTypeCd),
              getLookup(LOOKUP.LICENSE_HELD_YEARS, driver.licence.licenseDateYearsCd),
              getLookup(LOOKUP.LICENSE_HELD_MONTHS, driver.licence.licenseDateMonthsCd),
            )}
          </Typography>
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
                setSelectedDriverUUID(driver.uuid);
                history.push(ROUTE.YOUR_COVER_DETAILS_DRIVER_LICENSE);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_LICENSE}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.claims')} </Typography>
          {driver.claims.length > 0 ? (
            driver.claims.map((claim, i) => {
              return (
                <Typography key={i} variant="h4">
                  {formatDate(claim.claimDate, DATE_TYPES.WEEKDAY_SHORT)}{' '}
                  {getLookup(LOOKUP.CLAIM_TYPE, claim.claimTypeCd)}
                </Typography>
              );
            })
          ) : (
            <Typography variant="h4">{t('policyManagement.yourCoverDetails.claimAccordion.none')}</Typography>
          )}
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                openDialog({
                  title: t('fetchAdviserPopup.details.title'),
                  type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
                });
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_CLAIMS}
            />
          )}
        </Grid>
      </Grid>
      <ReviewAllDivider />
      <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.drivingOffences')}
          </Typography>
          {driver.convictions.length > 0 ? (
            driver.convictions.map((offence, i) => {
              return (
                <Typography key={i} variant="h4">
                  {formatDate(offence.convictionDate, DATE_TYPES.WEEKDAY_SHORT)}{' '}
                  {getLookup(LOOKUP.CONVICTION_CODE, offence.convictionTypeCd)} {offence.penaltyPoints}{' '}
                  {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.penaltyPoints')}
                </Typography>
              );
            })
          ) : (
            <Typography variant="h4">{t('policyManagement.yourCoverDetails.claimAccordion.none')}</Typography>
          )}
        </Grid>
        <Grid item>
          {showEditButton && (
            <ReviewAllEditButton
              onEdit={() => {
                openDialog({
                  title: t('fetchAdviserPopup.details.title'),
                  type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
                });
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_DRIVER_OFFENCES}
            />
          )}
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" className={classes.columnsContainer}>
        <Link
          underline="none"
          target="_blank"
          onClick={() => removeDriver()}
          className={classes.footerIcon}
          data-testid={COMPONENT_ID.RENEWAL_REMOVE_DRIVER}
        >
          <IconSure icon="remove-driver" color="secondary" size="26" type="solid" />
          <Typography className={classes.footerText} variant="h4">
            {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.removeDriver')}
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export const driverEqualString = (person: IPerson | IRenewalPerson) => {
  return `${person.firstName}${person.surname}${person.dateOfBirth ?? ''}`;
};

export const YourAdditionalDriverAccordion: React.FC<{ showEditButton?: boolean }> = observer(
  function YourAdditionalDriverAccordion({ showEditButton = true }) {
    const {
      interfaceStore: { isDesktop },
      renewalChangeStore: {
        renewalDetails: { drivers, insured, setRemovedDrivers, setSelectedDriverUUID },
      },
    } = useStores();
    const classes = useStyles({ isDesktop });
    const { t } = useTranslation('myAccount');

    const additionalDrivers = drivers.filter(
      (driver) => driverEqualString(driver.person) !== driverEqualString(insured.person),
    );

    return (
      <>
        {additionalDrivers.length > 0 ? (
          additionalDrivers.map((driver, index) => (
            <DriverItem
              index={index}
              key={driver.uuid}
              driver={driver}
              setSelectedDriverUUID={setSelectedDriverUUID}
              setRemovedDrivers={setRemovedDrivers}
              isDesktop={isDesktop}
              showEditButton={showEditButton}
            />
          ))
        ) : (
          <Grid container wrap="nowrap" className={classes.columnsContainer} justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">
                {t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.noneAdded')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  },
);
