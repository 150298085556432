import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import { DIALOG_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .section-typo': {
      paddingBottom: spacing(1),
    },
    gap: 23,
  },
}));

export const EditDriverHistory: React.FC<EditPageProps> = observer(function EditDriverHistory({ handleBack, uuid }) {
  const { setTitle } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      dialog: { openDialog },
    },
    mtaStore: {
      pendedMTA: { setDriver, getDriver },
    },
  } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const driver = getDriver(uuid ?? '');

  const [driverState, setDriverState] = useState(driver);
  const { declinedOnSpecialTerms, unspentConvictions, prosecutionsOnOffences, claimsFive } = driverState;
  const classes = useStyles();

  useEffect(() => {
    setTitle(t('driverHistory.title'));
  }, []);

  const handlePendedMta = () => {
    /*
    it should never save a yes answer for these as an agent journey is required
setDriver(uuid ?? '', {
      ...driver,
      claimsFive,
      declinedOnSpecialTerms,
      prosecutionsOnOffences,
      unspentConvictions,
    });
    */

    setDriver(uuid ?? '', {
      ...driver,
      declinedOnSpecialTerms,
      unspentConvictions,
    });
  };

  const isValidEditMode = () => {
    return !prosecutionsOnOffences && !claimsFive;
  };

  const handleContinue = () => {
    if (claimsFive || prosecutionsOnOffences) {
      openDialog({
        title: t('fetchAdviserPopup.details.title'),
        type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
      });
    } else {
      setIsSubmitting(true);
      handlePendedMta();
      handleBack();
    }
  };

  const handleBackClick = () => {
    handleBack();
  };

  return (
    <>
      <Grid container className={classes.root} direction="column">
        <Grid item>
          <MtaTooltip tooltipTitle={t('tooltips.deniedInsurance')} description={t('driverHistory.section1')} />
          <Choice
            identifier="driverHistorySection1"
            onChange={(event) =>
              setDriverState({ ...driverState, declinedOnSpecialTerms: event.target.value === 'true' })
            }
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={declinedOnSpecialTerms}
            error={isSubmitting && declinedOnSpecialTerms === null}
          />
        </Grid>
        <Grid item>
          <MtaTooltip tooltipTitle={t('tooltips.convictions')} description={t('driverHistory.section2')} />
          <Choice
            identifier="driverHistorySection2"
            onChange={(event) => setDriverState({ ...driverState, unspentConvictions: event.target.value === 'true' })}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={unspentConvictions}
            error={isSubmitting && unspentConvictions === null}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" className="section-typo">
            {t('driverHistory.section3')}
          </Typography>
          <Choice
            identifier="driverHistorySection3"
            onChange={(event) => {
              setDriverState({ ...driverState, claimsFive: event.target.value === 'true' });
            }}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={claimsFive}
            error={isSubmitting && claimsFive === null}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" className="section-typo">
            {t('driverHistory.section4')}
          </Typography>
          <Choice
            identifier="driverHistorySection4"
            onChange={(event) => {
              setDriverState({ ...driverState, prosecutionsOnOffences: event.target.value === 'true' });
            }}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={prosecutionsOnOffences}
            error={isSubmitting && prosecutionsOnOffences === null}
          />
        </Grid>
      </Grid>

      <EditFooter
        id="editDriverHistory"
        validPage={isValidEditMode()}
        handleSave={handleContinue}
        handleBack={handleBackClick}
      />
    </>
  );
});
