import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { formatDate } from '../../service/util/formatDate';
import SelfService from '../../service/util/selfService';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 2, 0),
      padding: theme.spacing(2, 0),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5, 0, 2, 0),
      padding: theme.spacing(3, 0, 2, 0),
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  policyAndCoverStyle: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
}));

export const AutoRenewalPolicy: React.FC = observer(function AutoRenewalPolicy() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { mainVehicle, policySelected },
      },
    },
  } = useStores();

  const endDate = policySelected.expiration?.toISO() ?? '';

  return (
    <Grid className={classes.gridContainer} container>
      <Grid item xs={6}>
        <Typography variant="h3">{mainVehicle.model}</Typography>
      </Grid>
      <Grid container item justifyContent="flex-end" xs={6}>
        <Typography variant="body1">
          {t('drawer.autoRenewalPolicy.autoRenewal')}{' '}
          {policySelected.autoRenewalAuthorizedInd
            ? t('policyDetails.autoRenewalOn')
            : t('policyDetails.autoRenewalOff')}
        </Typography>
      </Grid>
      <Grid className={classes.policyAndCoverStyle} item xs={6}>
        <Typography variant="body1">
          {t('policyDetails.policyNo')} {policySelected.policyNumber}
        </Typography>
      </Grid>
      <Grid container item justifyContent="flex-end" xs={6}>
        <Typography className={classes.policyAndCoverStyle} variant="body1">
          {t('drawer.autoRenewalPolicy.coverEnds')}{' '}
          {formatDate(SelfService.calculatePolicyEndDate(endDate).toString(), DATE_TYPES.MINI)}
        </Typography>
      </Grid>
    </Grid>
  );
});
