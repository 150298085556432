import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DIALOG_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { ChatWithUs } from '../../common/components/chatWithUs';
import { PersonName } from '../../yourDetails/components/personName';

import { DriverEditPageProps } from './editDriver';

export const DriverNameChangeDriver: React.FC<DriverEditPageProps> = observer(
  ({ handleBackDriverEdit, handleSaveDriverEdit }) => {
    const {
      mtaStore: {
        pendedMTA: {
          selectedDriver: { person },
        },
      },
    } = useStores();

    const { t } = useTranslation('myAccount');

    return (
      <PersonName
        title={t('MTAJourney.driverName.changeDriverTitle')}
        disableFirstName
        chatWithUs={
          <ChatWithUs
            description={t('MTAJourney.driverName.chat')}
            dialogType={DIALOG_TYPE.FETCH_ADVISER_CHANGE_FIRSTNAME}
          />
        }
        person={person}
        handleSaveDriverEdit={handleSaveDriverEdit}
        handleBackDriverEdit={handleBackDriverEdit}
      />
    );
  },
);
