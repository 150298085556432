import { Instance, getRoot, types } from 'mobx-state-tree';

import { IRootStore } from '..';
import { COVERAGE, PACKS } from '../../../constant';
import SelfService from '../../../util/selfService';

import {
  AutoPolicy,
  IAutoPolicy,
  IAutoPolicyRenewalQuoteDetails,
  IAutoRenewalOption,
  IClaims,
  IDriver,
  IInsured,
  IPayments,
  IPolicyDocument,
  IRenewalPendingMTAQuote,
  IVehicle,
  Vehicle,
} from './autoPolicyModel';

const string = types.optional(types.string, '');

export const AutoPolicies = types
  .model('AutoPolicies', {
    autoPolicies: types.optional(types.array(AutoPolicy), () => []),
    selectedDocumentTypeCode: string,
    selectedDriverUuid: string,
    selectedPolicyNumber: string,
  })
  .views((self) => ({
    get driverSelected(): IDriver {
      return this.policySelected.drivers.find((item) => item.uuid === self.selectedDriverUuid) ?? this.mainDriver;
    },

    getPolicyByNumber(policyNumber: string): IAutoPolicy | undefined {
      return self.autoPolicies.find((item) => item.policyNumber === policyNumber);
    },

    get insured(): IInsured {
      return this.policySelected.insured;
    },

    get mainDriver(): IDriver {
      const mainDriver = this.policySelected.vehicles[0].driverAssignments.filter((item) => item.mainDriverInd)[0];
      return this.policySelected.drivers.filter((driver) => driver.uuid === mainDriver.driverUuid)[0];
    },

    get mainPolicy(): IAutoPolicy {
      const { autoPolicies: policies } = self;
      return policies.length ? policies[0] : AutoPolicy.create();
    },

    get mainVehicle(): IVehicle {
      const { vehicles } = this.policySelected;
      return vehicles.length ? vehicles[0] : Vehicle.create();
    },

    get policyCover() {
      const packs = [...this.mainVehicle.packCoverages];
      const coverages = [...this.mainVehicle.subCoverages, ...this.mainVehicle.coverages];

      const LIFESTYLE = [PACKS.SAFETY_NET, PACKS.LONG_DISTANCE_TRAVELLER, PACKS.FOREIGN_TRAVELLER];

      const findPack = (pack: PACKS | PACKS[]) =>
        packs.find((x) => x.coverageCd === pack || pack.includes(x.coverageCd as PACKS));

      const findCoverage = (coverage: COVERAGE) => coverages.find((x) => x.coverageCd === coverage);

      return {
        accidentPerPeriod: findCoverage(COVERAGE.ACCIDENT_PER_PERIOD),
        accidentPerPerson: findCoverage(COVERAGE.ACCIDENT_PER_PERSON),
        basePack: findPack([PACKS.BASE_FLEX_COMP, PACKS.BASE_TIA_COMP]),
        breakdownCover: findPack([
          PACKS.RESRV11,
          PACKS.RESRHV11,
          PACKS.RESROV11,
          PACKS.RESRHV12,
          PACKS.RESROV13,
          PACKS.RESRV12,
        ]),
        drivingOtherCars: findCoverage(COVERAGE.DRIVING_OTHER_CARS),
        lifeStylePacks: packs.filter((x) => LIFESTYLE.includes(x.coverageCd as PACKS)),
        motorLegalProtection: findPack(PACKS.MOTOR_LEGAL_PROTECTION),
        ncdpPack: findPack(PACKS.NCDP),
        safetyNet: findPack(PACKS.SAFETY_NET),
        standardCourtesyCar: findCoverage(COVERAGE.STANDARD_CC),
        upgradedCourtesyCar: findPack(PACKS.CAR_HIRE_COVER),
      };
    },

    get policySelected(): IAutoPolicy {
      return self.autoPolicies.find((item) => item.policyNumber === self.selectedPolicyNumber) ?? this.mainPolicy;
    },

    get rootStore(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    setFetchingRenewalData(policyNumber: string, value: boolean) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].fetchingRenewalData = value;
    },
  }))
  .actions((self) => ({
    addDocuments(documents: IPolicyDocument[], policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].documents.replace(documents);
    },

    addDriverClaims(policyNumber: string, claims: IClaims[]) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);

      const drivers = self.autoPolicies[index].drivers.map((driver) => {
        driver.claims.map((claim) => {
          const status = claims.find((item) => item.claimNumber === claim.claimNumber)?.claimStatus;
          if (status) return (claim.claimStatus = status);
          return claim;
        });

        return driver;
      });
      self.autoPolicies[index].drivers.replace(drivers);
    },
    addPayments(payments: IPayments, policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      const filteredTransactions = payments.transactions.filter((item) => 'paymentMethod' in item);
      const newPayments: IPayments = {
        billablePremium: payments.billablePremium,
        paymentPlan: payments.paymentPlan,
        transactions: filteredTransactions as typeof payments.transactions,
      };
      self.autoPolicies[index].payments = newPayments;
    },
    addPendedQuoteDetails(policyNumber: string, pendedQuoteDetails: IRenewalPendingMTAQuote) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].policyRenewalQuoteDetails.pendingMTAQuote = pendedQuoteDetails;
    },
    addPolicy(policy: IAutoPolicy) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policy.policyNumber);

      policy.policyStatus = SelfService.getPolicyStatus(policy);

      if (index === -1) {
        if (!policy.tiaPolicy) {
          self.autoPolicies.push({ ...policy, drivers: SelfService.orderedDrivers(policy) });
        } else self.autoPolicies.push({ ...policy, vehicles: [policy.tiaVehicle] });
      } else {
        self.autoPolicies[index] = policy;
      }
    },
    addRenewalDocuments(renewalDocuments: IPolicyDocument[], policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].renewalDocuments.replace(renewalDocuments);
    },
    addRenewalInfo(autoRenewalOption: IAutoRenewalOption, autoRenewal: boolean | null, policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].autoRenewalOption = autoRenewalOption;
      self.autoPolicies[index].autoRenewal = autoRenewal;
    },
    addRenewalQuoteDetails(policyNumber: string, renewalQuoteDetails: IAutoPolicyRenewalQuoteDetails) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.setFetchingRenewalData(policyNumber, false);
      self.autoPolicies[index].policyRenewalQuoteDetails = renewalQuoteDetails;
    },
    getPendedQuoteDetails(policyNumber: string): IRenewalPendingMTAQuote | null {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      return self.autoPolicies[index].policyRenewalQuoteDetails.pendingMTAQuote;
    },
    setRenewalStatusRequestPending(renewalStatusRequestPending: boolean, policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      self.autoPolicies[index].renewalStatusRequestPending = renewalStatusRequestPending;
    },
    setSelectedDocumentTypeCode(documentTypeCode: string) {
      self.selectedDocumentTypeCode = documentTypeCode;
    },
    setSelectedDriverUuid(uuid: string) {
      self.selectedDriverUuid = uuid;
    },
    setSelectedPolicyNumber(policyNumber: string) {
      self.selectedPolicyNumber = policyNumber;
    },
    setShouldUpdatePolicy(value: boolean, policyNumber: string) {
      const index = self.autoPolicies.findIndex((item) => item.policyNumber === policyNumber);
      if (index > -1) self.autoPolicies[index].shouldUpdatePolicy = value;
    },
  }));

export type IMotorPolicies = Instance<typeof AutoPolicies>;
