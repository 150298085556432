import { Box, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ThankYouNotification } from '../../../../../component/common/thankYouNotification';
import { EmergencySiteAlert } from '../../../../../component/layout/emergencySiteAlert';
import { ExtendedCallCenterAlert } from '../../../../../component/layout/extendedCallCenterAlert';
import { NeedHelp } from '../../../../../component/layout/needHelp';
import { PolicyCard } from '../../../../../component/layout/policyCards';
import { ProposalBanners } from '../../../../../component/layout/proposalBanners';
import { trackSatismeter } from '../../../../../lib/satismeter';
import { IHomePolicy } from '../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../service/state/store';
import { useSegment } from '../../../../../service/util/customHooks/useSegment';
import SelfService, { IPolicies } from '../../../../../service/util/selfService';
import { HomeRenewalNotification } from '../../policy/renewals/components/homeRenewalNotification';
import { MotorRenewalNotification } from '../../policy/renewals/components/motorRenewalNotification';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    gap: 20,
    padding: spacing(2, 0),
  },
}));

/* is this used in mobile... if so needs renaming */
export const PoliciesDashboardDesktop: React.FC = observer(function PoliciesDashboardDesktop() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const {
    dashboardStore: {
      insurance: {
        auto: { autoPolicies, mainVehicle, policySelected },
        home: { homePolicies },
      },
      refetchPoliciesData,
    },
    interfaceStore: { pagesViewed, hasFeatureFlag },
  } = useStores();

  const allPolicies = SelfService.getPoliciesByStatus([...autoPolicies, ...homePolicies] as IPolicies);
  /* at this moment, sorting policies function has become quite redundant, but I kept it because
  the whole sorting policies thing is subject to change. When we'll have the final decision on how to display the policies on the portal, we might need to take some time and
  update the reduce function to give us the policies grouped as we want */

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const activeMotorPolicies = allPolicies.auto.Active ?? [];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const pendingPolicies = [...(allPolicies.auto.Pending ?? []), ...(allPolicies.home.Pending ?? [])];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const expiredPolicies = [...(allPolicies.auto.Expired ?? []), ...(allPolicies.home.Expired ?? [])];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const activeHomePolicies: IHomePolicy[] = allPolicies.home.Active ?? [];

  useEffect(() => {
    /* Satismeter Call
    ------------------
    conditions
    The customer is on the policy dashboard screen
    The customer has visited at least [2] pages
     */
    const enoughPagesVisited = pagesViewed > 2;
    if (enoughPagesVisited) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      trackSatismeter('SME-Show General Digital Ease Survey');
      eventTrack('Show General Digital Ease Survey Event Fired');
    }
    if (policySelected.shouldUpdatePolicy) {
      refetchPoliciesData();
    }
  }, []);
  const getAddress = (address: { houseNumber: string; street: string }) => {
    return `${address.houseNumber} ${address.street}`;
  };

  const renderProposalBanners = () => {
    const featureFlag = window.braze?.getFeatureFlag('bannerFeed');
    window.braze?.logFeatureFlagImpression('bannerFeed');
    if (!featureFlag?.enabled) {
      return null;
    }
    return (
      <div data-testid="proposal-banners">
        <ProposalBanners />
      </div>
    );
  };

  const renderComunicationBanners = () => {
    const featureFlag = window.braze?.getFeatureFlag('bannerFeed');
    window.braze?.logFeatureFlagImpression('bannerFeed');
    if (!featureFlag?.enabled || !hasFeatureFlag('showBrazeCommsCard')) {
      return null;
    }
    return (
      <div data-testid="proposal-banners-comms">
        <ProposalBanners commsBanner={true} />
      </div>
    );
  };

  return (
    <Box>
      <ExtendedCallCenterAlert />
      <EmergencySiteAlert />

      {autoPolicies.map((policy) =>
        policy.futureRenewalEffectiveDate === null ? (
          <MotorRenewalNotification policy={policy} key={policy.policyNumber} hasLabel={true} />
        ) : (
          <ThankYouNotification policy={policy} key={policy.policyNumber} insuranceDetails={mainVehicle.vrm} />
        ),
      )}

      {homePolicies.map((policy) =>
        policy.futureRenewalEffectiveDate === null ? (
          <HomeRenewalNotification policy={policy} key={policy.policyNumber} hasLabel={true} />
        ) : (
          <ThankYouNotification
            policy={policy}
            key={policy.policyNumber}
            insuranceDetails={getAddress(policy.homeAddress)}
          />
        ),
      )}

      {renderComunicationBanners()}

      {pendingPolicies.length > 0 && (
        <>
          <Typography variant="overline">{t('dashboard.pendingPolicies')}</Typography>
          {pendingPolicies.map((policy, index) => {
            return (
              <Grid className={classes.container} container key={index}>
                <PolicyCard data-test-id="policy-type-container" policy={policy} />
              </Grid>
            );
          })}
        </>
      )}

      {activeMotorPolicies.length > 0 && (
        <>
          <Typography variant="overline">{t('dashboard.carInsurancePolicies')}</Typography>
          {activeMotorPolicies.map((policy, index) => {
            return (
              <Grid className={classes.container} container key={index}>
                <PolicyCard data-test-id="policy-type-container" policy={policy} />
              </Grid>
            );
          })}
        </>
      )}

      {activeHomePolicies.length > 0 && (
        <>
          <Typography variant="overline">{t('dashboard.homeInsurancePolicies')}</Typography>
          {activeHomePolicies.map((policy, index) => {
            return (
              <Grid className={classes.container} container key={index}>
                <PolicyCard data-test-id="policy-type-container" policy={policy} />
              </Grid>
            );
          })}
        </>
      )}

      {renderProposalBanners()}

      {expiredPolicies.length > 0 && (
        <>
          <Typography variant="overline">{t('dashboard.expiredPolicies')}</Typography>
          {expiredPolicies.map((policy, index) => {
            return (
              <Grid className={classes.container} container key={index}>
                <PolicyCard data-test-id="policy-type-container" policy={policy} />
              </Grid>
            );
          })}
        </>
      )}

      <NeedHelp />
    </Box>
  );
});
