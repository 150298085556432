import { Grid, IconButton, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../../service/state/store';
import IconSure from '../../common/icon';

type CloseIconColorProp = 'secondary' | 'dark';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .bottom-section': {
      '&__content': {
        textAlign: 'center',
        whiteSpace: 'pre-line',
      },
      '&__image': {
        '& img': {
          paddingTop: spacing(2),
        },
      },
    },
    '& .button': {
      '&:first-child button': {
        marginRight: spacing(2),
      },
      width: 'auto',
    },
    '& .close-icon-container': {
      padding: spacing(2, 2, 0, 0),
    },

    '& .content-container': {
      display: 'grid',
      gap: spacing(2),
      padding: spacing(2, 0),
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
    '& .dialog-container': {
      padding: 0,
    },
    '& .header-typography': {
      padding: spacing(2, 0),
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
    '& .image': {
      alignSelf: 'baseline',
      padding: spacing(2, 0),
    },
    '& .padding-content-container': {
      gap: spacing(2),
      padding: spacing(0, 5, 5, 5),
    },
    '& .secondary-content': {
      paddingTop: spacing(2),
      whiteSpace: 'pre-line',
    },
    '& .title': {
      textAlign: 'center',
    },
  },
}));

interface DialogTemplateProps {
  bottomContent?: string;
  bottomImage?: React.ReactNode;
  buttons?: React.ReactNode[];
  closeIconColor?: CloseIconColorProp;
  header?: string;
  mainContent?: string;
  secondaryContent?: string;
  testid?: string;
  title: string;
  topImage?: React.ReactNode;
  visibleCloseIcon?: boolean;
}

export const DialogTemplate: React.FC<DialogTemplateProps> = observer(function DialogTemplate(props) {
  const {
    topImage,
    testid,
    title,
    mainContent,
    secondaryContent,
    buttons,
    visibleCloseIcon,
    header,
    closeIconColor = 'secondary',
    bottomImage,
    bottomContent,
  } = props;

  const classes = useStyles();

  const {
    interfaceStore: { dialog },
  } = useStores();

  const handleCloseIcon = () => {
    dialog.closeDialog();
  };
  return (
    <Grid container data-testid={testid} className={clsx(classes.root, 'dialog-container')}>
      <Grid container item justifyContent="flex-end" className="close-icon-container">
        {visibleCloseIcon && (
          <IconButton size="small" aria-label="close" onClick={handleCloseIcon} data-testid="close-button">
            <IconSure icon="times" size="20" color={closeIconColor} strokeWidth="1.5" />
          </IconButton>
        )}
      </Grid>
      <Grid container className={clsx(classes.root, 'padding-content-container')} justifyContent="center">
        <Grid container item justifyContent="center" className={clsx(classes.root, 'image')}>
          {topImage}

          {header && (
            <Grid container item justifyContent="center">
              <Typography variant="overline" className="header-typography">
                {header}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item justifyContent="center">
          <Typography variant="h2" className="title">
            {title}
          </Typography>
        </Grid>

        <Grid className="content-container">
          {mainContent && (
            <Typography variant="body1">
              <span
                dangerouslySetInnerHTML={{
                  __html: mainContent,
                }}
              />
            </Typography>
          )}

          {secondaryContent && (
            <Grid className="secondary-content" item>
              <Typography variant="body1">{secondaryContent}</Typography>
            </Grid>
          )}

          {bottomImage && (
            <Grid item className="bottom-section__image">
              {bottomImage}
            </Grid>
          )}

          {bottomContent && (
            <Grid item className="bottom-section__content">
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: bottomContent,
                  }}
                />
              </Typography>
            </Grid>
          )}
        </Grid>

        {buttons && (
          <Grid container justifyContent="center">
            {buttons.map((btn, idx) => (
              <Grid container className={clsx(classes.root, 'button')} key={idx}>
                {btn}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
