import { Grid, Typography } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COMPONENT_ID, getDeviceSession } from '../../service/analytics';
import { useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';
import { NavigationCardSmall } from '../common/navigationCardSmall';

import { ItemList, policyDetailUseStyles } from './motorPolicyDetailsSection';

export const PolicyDetailsNavCards: React.FC<{ cards: ItemList[] }> = ({ cards }) => {
  const { eventTrack } = useSegment();
  const classes = policyDetailUseStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber },
        },
      },
    },
    interfaceStore: { isMobile },
  } = useStores();

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Typography variant="h2" className={classes.sectionTitle} color="secondary">
          {t('breadcrumbs.aboutYourCover')}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <Grid
            item
            xs={6}
            xl={4}
            data-testid={`navigation-card-small-${index + 1}`}
            onClick={() =>
              eventTrack(card.event, {
                componentID: COMPONENT_ID.DRIVER_CARD,
                label: card.title,
                policyNumber: policyNumber,
                sessionId: getDeviceSession(),
                url: window.location.href,
              })
            }
            key={card.title}
          >
            <Link
              to={{
                pathname: card.route,
              }}
            >
              <NavigationCardSmall
                color="secondary"
                description={card.subtitle}
                title={card.title}
                icon={card.icon}
                iconType={card.iconType}
                showDescription={!isMobile}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
