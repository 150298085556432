import { Autocomplete, Grid, TextField, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Choice } from '../../../../../../../../component/common/choice';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .MuiAutocomplete-clearIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiAutocomplete-inputRoot': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },
    },
    '& .MuiAutocomplete-noOptions': {
      color: palette.error.dark,
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiFormLabel-root': {
      color: palette.common.black,
    },
    gap: 25,
    marginBottom: spacing(4),
  },
}));

export const DriverOccupationRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName, getLookupValue, getLookupCode },
    },
    renewalChangeStore: {
      renewalDetails: { setSelectedDriver, selectedDriver, insured, setInsured },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { nextStep, setTitle } = useRenewalStepperContext();

  const location = useLocation();

  const isDriverJourney =
    location.pathname === ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION ||
    location.pathname === ROUTE.YOUR_COVER_DETAILS_ADD_DRIVER;

  const [occupationList, setOccupationList] = useState<string[]>();
  const [industryList, setIndustryList] = useState<string[]>();
  const [employmentList, setEmploymentList] = useState<string[]>();
  const [personState, setPersonState] = useState(isDriverJourney ? selectedDriver.person : insured.person);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    secondaryOccupation,
    primaryOccupationCd,
    primaryOccupationIndustryCd,
    secondaryOccupationCd,
    secondaryOccupationIndustryCd,
    employmentStatusCd,
  } = personState;

  const isNotValid = () => {
    return (
      (employmentStatusCd === null && !isDriverJourney) ||
      primaryOccupationIndustryCd === null ||
      primaryOccupationCd === null ||
      secondaryOccupation === null ||
      (secondaryOccupation && secondaryOccupationCd === null) ||
      (secondaryOccupation && secondaryOccupationIndustryCd === null)
    );
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);

    if (isDriverJourney) {
      !isNotValid() &&
        setSelectedDriver({
          ...selectedDriver,
          person: {
            ...selectedDriver.person,
            employmentStatusCd,
            primaryOccupationCd,
            primaryOccupationIndustryCd,
            secondaryOccupation,
            ...(secondaryOccupation
              ? {
                  secondaryOccupationCd,
                  secondaryOccupationIndustryCd,
                }
              : {
                  secondaryOccupationCd: null,
                  secondaryOccupationIndustryCd: null,
                }),
          },
        });
    } else {
      !isNotValid() &&
        setInsured({
          ...insured,
          person: {
            ...insured.person,
            employmentStatusCd,
            primaryOccupationCd,
            primaryOccupationIndustryCd,
            secondaryOccupation,
            ...(secondaryOccupation
              ? {
                  secondaryOccupationCd,
                  secondaryOccupationIndustryCd,
                }
              : {
                  secondaryOccupationCd: null,
                  secondaryOccupationIndustryCd: null,
                }),
          },
        });
    }
  };

  const handleContinue = () => {
    handlePendedMta();
    !isNotValid() && nextStep();
  };

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.INDUSTRY, LOOKUP.OCCUPATION, LOOKUP.EMPLOYMENT],
  });

  useEffect(() => {
    setTitle(t('MTAJourney.driverOccupation.title'));
  }, []);

  useEffect(() => {
    if (
      isLookupsFetched &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (lookupsByName[LOOKUP.INDUSTRY] && lookupsByName[LOOKUP.EMPLOYMENT] && lookupsByName[LOOKUP.OCCUPATION]) !==
        undefined
    ) {
      setOccupationList(lookupsByName[LOOKUP.OCCUPATION].map((item) => item.value));
      setIndustryList(lookupsByName[LOOKUP.INDUSTRY].map((item) => item.value));
      setEmploymentList(lookupsByName[LOOKUP.EMPLOYMENT].map((item) => item.value));
    }
  }, [isLookupsFetched, lookupsByName]);

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        {employmentList?.length !== undefined && employmentList.length > 0 && !isDriverJourney && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={employmentList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.employmentNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.employmentStatusLabel')}
                  error={isSubmitting && employmentStatusCd === null}
                  helperText={isSubmitting && employmentStatusCd === null && t('validations.selectEmployment')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  employmentStatusCd: value ? getLookupCode(LOOKUP.EMPLOYMENT, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.EMPLOYMENT, employmentStatusCd ?? '') || null}
            />
          </Grid>
        )}

        {occupationList?.length !== undefined && occupationList.length > 0 && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={occupationList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.occupationNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.occupationLabel')}
                  error={isSubmitting && primaryOccupationCd === null}
                  helperText={isSubmitting && primaryOccupationCd === null && t('validations.selectOccupation')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  primaryOccupationCd: value ? getLookupCode(LOOKUP.OCCUPATION, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.OCCUPATION, primaryOccupationCd ?? '') || null}
            />
          </Grid>
        )}

        {industryList?.length !== undefined && industryList.length > 0 && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={industryList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.industryNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.industryLabel')}
                  error={isSubmitting && primaryOccupationIndustryCd === null}
                  helperText={isSubmitting && primaryOccupationIndustryCd === null && t('validations.selectIndustry')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  primaryOccupationIndustryCd: value ? getLookupCode(LOOKUP.INDUSTRY, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.INDUSTRY, primaryOccupationIndustryCd ?? '') || null}
            />
          </Grid>
        )}

        <Typography>{t('MTAJourney.driverOccupation.secondaryOccupation')}</Typography>

        <Choice
          identifier="secondaryOccupationSelector"
          onChange={(event) => setPersonState({ ...personState, secondaryOccupation: event.target.value === 'true' })}
          left={{ label: t('labels.no') }}
          right={{ label: t('labels.yes') }}
          radioValue={secondaryOccupation}
          error={isSubmitting && secondaryOccupation === null}
        />

        {occupationList?.length !== undefined && occupationList.length > 0 && secondaryOccupation && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={occupationList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.occupationNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('makeChange.person.secondaryOccupation')}
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  error={isSubmitting && secondaryOccupation && secondaryOccupationCd === null}
                  helperText={isSubmitting && secondaryOccupationCd === null && t('validations.selectOccupation')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  secondaryOccupationCd: value ? getLookupCode(LOOKUP.OCCUPATION, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.OCCUPATION, secondaryOccupationCd ?? '') || null}
            />
          </Grid>
        )}

        {industryList?.length !== undefined && industryList.length > 0 && secondaryOccupation && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={industryList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.industryNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('makeChange.person.secondaryIndustry')}
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  error={isSubmitting && secondaryOccupation && secondaryOccupationIndustryCd === null}
                  helperText={isSubmitting && secondaryOccupationIndustryCd === null && t('validations.selectIndustry')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  secondaryOccupationIndustryCd: value ? getLookupCode(LOOKUP.INDUSTRY, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.INDUSTRY, secondaryOccupationIndustryCd ?? '') || null}
            />
          </Grid>
        )}
      </Grid>
      <RenewalChangeFooter
        cancelButtonId={COMPONENT_ID.RENEWAL_OCCUPATION_CANCEL}
        backButtonId={COMPONENT_ID.RENEWAL_OCCUPATION_BACK}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_OCCUPATION_CONTINUE, handleContinue }}
      />
    </>
  );
});
