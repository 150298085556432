import { Box, Theme, makeStyles } from '@esure-cloud/react-components';

import React, { useEffect } from 'react';

import { getSafeAreaBottom, getSafeAreaTop, isCauraRoute } from '../../../lib/caura-bridge';
import config from '../../../service/config';
import { usePageVisibility } from '../../../service/util/customHooks/usePageVisibility';
import { ChatMinimised } from '../chatMinimised';

export interface IncomingChatItem {
  content: {
    data: string;
    type: 'application/vnd.amazonaws.connect.event.participant.joined' | 'text/plain';
  };
  displayName: string;
  participantRole: 'CUSTOM_BOT' | 'AGENT' | 'SYSTEM';
}

export interface ChatSession {
  client: {
    session: {
      controller: {
        contactId: string;
        participantId: string;
        participantToken: string;
      };
    };
  };
  contactStatus: string;
  incomingItemDecorator: (data: IncomingChatItem) => IncomingChatItem | void;
  onChatClose: (data: unknown) => void;
  onChatDisconnected: (data: unknown) => void;
}

interface StartChatConfig {
  failureCallback: (error: unknown) => void;
  initiateChatConfig: {
    apiGatewayEndpoint: string;
    // This will be set as the ParticipantDetails -> DisplayName property which is required to start the chat
    contactAttributes?: string;
    contactFlowId: string;
    featurePermissions?: {
      ATTACHMENTS?: boolean;
    };
    instanceId: string;
    name: string;
    region: string;
  };
  successCallback: (chatSession: ChatSession) => void;
}

export interface ChatBotWidgetProps {
  hasNotification: boolean;
  initiated: boolean;
  minimised: boolean;
  setIsHidden: (data: boolean) => void;
}

interface ChatSessionGlobalConfig {
  loggerConfig: {
    level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';
    useDefaultLogger: boolean;
  };
}

// The amazon connect js interface file puts a connect property on the global window, so we have to provide the typings for it in order to access the window.connect property
declare global {
  interface Window {
    // Marked as optional as this covers the scenario where the JS file didn't load, so in that case the connect property would not exist
    connect?: {
      ChatInterface: {
        // Marked as optional as this function was custom added by esure to the library, so is not guaranteed to exist as user may have cached version of the library
        // from before this function was added
        getVersion?: () => string;
        init: (initConfig: {
          containerId: string;
          footerConfig?: {
            isHTML?: boolean;
            render?: () => string;
          };
          headerConfig?: {
            isHTML?: boolean;
            render?: () => string;
          };
        }) => unknown;
        initiateChat: (
          initiateChatConfig: {
            apiGatewayEndpoint: string;
            // This will be set as the ParticipantDetails -> DisplayName property which is required to start the chat
            contactAttributes?: string;
            contactFlowId: string;
            featurePermissions?: {
              ATTACHMENTS?: boolean;
            };
            instanceId: string;
            name: string;
            region: string;
          },
          successCallback: (chatSession: ChatSession) => unknown,
          failureCallback: (error: unknown) => unknown,
        ) => unknown;
      };
      ChatSession: {
        setGlobalConfig: (config: ChatSessionGlobalConfig) => void;
      };
    };
  }
}

// footer is 999, header is 1100, full page dialogs are 1300 so needs to be higher than all of these
export const CHATBOT_WRAPPER_ZINDEX = 9999;
const isChatMode = window.location.search.includes('launchChat=true');
const safeAreaTop = `${Number(getSafeAreaTop())}px`;
const safeAreaBottom = `${Number(getSafeAreaBottom())}px`;
const useStyles = makeStyles<Theme, ChatBotWidgetProps>((theme) => ({
  chatBotWrapper: {
    [theme.breakpoints.down('xs')]: {
      bottom: safeAreaBottom,
      right: 0,
      width: '100%',
    },

    // These are classes on standard html elements, so need to be referenced using their actual class names
    '& .connect-customer-interface': {
      // hide minimalize button for standalone mode
      '& > div > div:first-child div:first-child div:first-child button': {
        display: isChatMode ? 'none' : 'block',
      },
      '@media (max-height: 545px)': {
        // Browsers that support dvh should use this, it should have higher specificity
        '&:is(&)': {
          height: isCauraRoute() ? `calc(100dvh - ${safeAreaTop})` : '100dvh',
        },
        // Fallback for browsers that do not support dvh
        height: isCauraRoute() ? `calc(100vh - ${safeAreaTop})` : '100vh',
      },
      bottom: safeAreaBottom,
      [theme.breakpoints.down('xs')]: {
        // Browsers that support dvh should use this, it should have higher specificity
        '&:is(&)': {
          height: isCauraRoute() ? `calc(100dvh - ${safeAreaTop})` : '100dvh',
        },
        // Fallback for browsers that do not support dvh
        height: isCauraRoute() ? `calc(100vh - ${safeAreaTop})` : '100vh',
        width: '100%',
      },
      height: isCauraRoute() ? `calc(85vh - ${safeAreaTop})` : '85vh',
      margin: 0,
      top: safeAreaTop,
      width: theme.spacing(50),
    },

    // Here for now to fix zindex issues
    backgroundColor: theme.palette.common.white,
    bottom: safeAreaBottom,
    display: (props) => (props.initiated && !props.minimised ? 'block' : 'none'),
    fontWeight: 400,
    position: 'fixed',
    right: theme.spacing(0.25),
    top: isCauraRoute() ? safeAreaTop : 'unset',
    zIndex: CHATBOT_WRAPPER_ZINDEX,
  },
  overlay: {
    backgroundColor: '#fff',
    height: '100dvh',
    position: 'absolute',
    top: `-${safeAreaTop}`,
    width: '100dvw',
  },
}));

/**
 * Initialises the chat widget in preparation for a chat to be started.
 */
const initialise = () => {
  // See https://github.com/amazon-connect/amazon-connect-chat-interface for repo to build the interface js file,
  // Files in that repo can be modified, e.g. text and colors can be changed and then the file built. Potentially then we
  // would not need to pass in headerConfig as it will already have been built with the correct colors and text etc
  window.connect?.ChatInterface.init({
    containerId: 'chatArea', // This is the id of the container where you want the widget to reside
    // Use the optional headerConfig and footerConfig to customize your widget
    // You can only return standard html, so you can't return a React component
    // Pass in empty footer to completely remove it
    footerConfig: {
      render: () => '',
    },
  });
};

/**
 * Initiates the chat using the provided configuration
 * @param startChatConfig configuration for the chat widget
 */
export const initiateChat = (startChatConfig: StartChatConfig): void => {
  // If no connect property on window, then just call the failure callback
  if (!window.connect) {
    return startChatConfig.failureCallback('window.connect undefined');
  }

  // Set the log level to ERROR so that we don't get too many logs in the console
  window.connect.ChatSession.setGlobalConfig({ loggerConfig: { level: 'ERROR', useDefaultLogger: true } });

  window.connect.ChatInterface.initiateChat(
    startChatConfig.initiateChatConfig,
    startChatConfig.successCallback,
    startChatConfig.failureCallback,
  );
};

/**
 * ChatBotWidget - state of the chatbot etc is passed in so that the component is free of any state management.
 */
export const ChatBotWidget: React.FC<ChatBotWidgetProps> = (props) => {
  const classes = useStyles(props);
  const { minimised, hasNotification, setIsHidden } = props;

  const isHidden = usePageVisibility();

  useEffect(() => {
    initialise();
  }, []);

  /**
   * ChatBotWidget - adds a notification in the browser tab when chat minimised and new message is received.
   */
  useEffect(() => {
    if (isHidden) {
      setIsHidden(true);
    } else {
      const favicon = document.getElementById('favicon') as HTMLAnchorElement;
      favicon.href = `./assets/images/${config.brand}/favicon.png`;
      document.title = document.title.startsWith('(1) ') ? document.title.slice(4) : document.title;
      setIsHidden(false);
    }
  }, [isHidden, setIsHidden]);

  return (
    <>
      <Box className={classes.chatBotWrapper} data-testid="chatBoxWidget">
        <div className={classes.overlay} />
        <Box id="chatArea" />
      </Box>
      {minimised && <ChatMinimised hasNotification={hasNotification} />}
    </>
  );
};
