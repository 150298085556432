import { darken, lighten, makeStyles } from '@esure-cloud/react-components';

import { Trans, useTranslation } from 'react-i18next';

import { IAutoPolicy } from '../../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../../service/state/models/dashboard/homePolicyModel';
import SelfService from '../../../service/util/selfService';

import { HomePolicyCard } from './homePolicyCard';
import { MotorPolicyCard } from './motorPolicyCard';

export interface PolicyCardProps {
  isPolicyHeader?: boolean;
  isRenewalHeader?: boolean;
  policy: IAutoPolicy | IHomePolicy;
}

export const useCommonStylesPolicyCard = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .autorenewBottom': {
      '@media (max-width:960px)': {
        display: 'block',
      },
      display: 'none',
    },
    '& .link': {
      cursor: 'pointer',
      fontSize: '0.875rem',
      textDecoration: 'underline',
    },
    '& .policy-car': {
      '& img': {
        height: '100%',
        maxWidth: 140,
        position: 'relative',
        width: '100%',
      },
      '&__expired': {
        filter: 'grayscale(100%)',
      },
      '@media (max-width:960px)': {
        display: 'none',
      },
      alignSelf: 'center',
      minWidth: 100,
    },
    '& .policy-details': {
      '&.single-item': {
        '& .policy-details__item': {
          maxWidth: '100%',
        },

        '@media (max-width:960px)': {
          flexDirection: 'row',
        },
      },
      '&__buttons': {
        alignSelf: 'center',
        justifyContent: 'center',
        minWidth: '152px',
      },
      '&__item': {
        '&:first-child': {
          borderBottom: `1px solid ${palette.background.default}80`,
        },
        '@media (max-width:960px)': {
          '&:first-child': {
            '&:after': {
              background: `${palette.background.default}80`,
              content: `' '`,
              height: '22px',
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '1px',
            },
            borderBottom: 'none',
          },
          '&:nth-child(2)': {
            textAlign: 'right',
          },
          maxWidth: '50%',
          padding: spacing(0),
        },
        maxWidth: 'max-content',
        padding: spacing(1, 0),
        position: 'relative',
      },
      '&__list': {
        '@media (max-width:960px)': {
          flexDirection: 'row',
          width: '100%',
        },
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '130px',
      },
      '@media (max-width:960px)': {
        '&__buttons': {
          '& button': {
            width: 'auto',
          },
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
        },
        flexDirection: 'column',
      },
      gap: spacing(2, 3),
    },
    '& .renewalHeading': {
      '-webkit-line-clamp': 'initial',
      fontSize: '1.5rem',
      maxHeight: '100%',
    },
    '& h2': {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      '@media (max-width:1280px)': {
        '-webkit-line-clamp': 3,
        maxHeight: '4em',
      },
      '@media (max-width:960px)': {
        '-webkit-line-clamp': 1,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        maxHeight: '1.5em',
      },
      display: '-webkit-box',
      maxHeight: '2.8em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
    '&.Active': {
      '&.firstAlternative': {
        '& .contrast': {
          '& path': {
            stroke: palette.primary.main,
          },
          borderColor: palette.primary.main,
          color: palette.primary.main,
        },
        '& .icon-button path': {
          fill: palette.primary.main,
        },
        background: lighten(palette.secondary.main, 0.92),
        color: palette.primary.main,
      },
      '&.sheilas': {
        '& .contrast': {
          '& path': {
            stroke: palette.text.primary,
          },
          borderColor: palette.text.primary,
          color: palette.text.primary,
        },
        '& .icon-button path': {
          fill: palette.text.primary,
        },
        background: lighten(palette.secondary.main, 0.92),
        color: palette.text.primary,
      },
      background: `linear-gradient(115.01deg, ${palette.secondary.main} 8.54%, ${palette.secondary.dark} 94.97%)`,
      borderColor: 'transparent',
      color: palette.secondary.contrastText,
    },
    '&.Expired, &.Lapsed, &.Cancelled': {
      background: palette.background.subtle,
      borderColor: `${palette.common.black}1F`,
    },
    '&.Inactive': {
      '&.sheilas': {
        background: lighten(palette.warning.main, 0.92),
        borderColor: darken(palette.warning.main, 0.1),
      },
    },
    '&.Pending': {
      '& .single-divider': {
        '&:before': {
          background: palette.primary.light,
        },
      },
      '&.sheilas': {
        '&:before': {
          content: 'none',
        },
        background: lighten(palette.info.main, 0.92),
        borderColor: darken(palette.info.main, 0.1),
      },
      '&:before': {
        borderTopColor: `${palette.primary.main}15`,
      },
      background: `${palette.primary.main}12`,
      borderColor: palette.primary.main,
    },
    '&.policy-page': {
      '@media (max-width:960px)': {
        '& .policy-car': {
          '&__full': {
            display: 'block',
            margin: '0 auto',
          },
          alignSelf: 'center',
          display: 'block',
        },
        '& .policy-vehicle': {
          maxWidth: '100%',
          width: '100%',
        },

        flexDirection: 'column',
      },
      gap: spacing(1),
      justifyContent: 'space-between',
    },

    '&.renewalHeader': {
      maxWidth: '80% !important',
    },

    '@media (max-width:960px)': {
      '& .autorenew': {
        display: 'none',
      },
      '&.renewalHeader': {
        maxWidth: '100% !important',
      },
      display: 'block !important',
      maxWidth: '100% !important',
    },
    border: `2px solid ${palette.secondary.main}75`,
    borderRadius: spacing(1),
    gap: spacing(2),
    padding: spacing(2),
    position: 'relative',
  },
}));

export const PolicyCard: React.FC<PolicyCardProps> = ({ policy, isPolicyHeader = false, isRenewalHeader = false }) => {
  return SelfService.isHomePolicy(policy) ? (
    <HomePolicyCard policy={policy} isPolicyHeader={isPolicyHeader} isRenewalHeader={isRenewalHeader} />
  ) : (
    <MotorPolicyCard policy={policy} isPolicyHeader={isPolicyHeader} isRenewalHeader={isRenewalHeader} />
  );
};

export const AutoRenew: React.FC<{ autoRenewal: boolean | null }> = ({ autoRenewal }) => {
  const { t } = useTranslation(['myAccount', 'common']);
  return (
    <>
      {autoRenewal ? (
        <Trans
          t={t}
          i18nKey={'policyManagement.homeRenewalQuote.policyCard.autoRenew'}
          components={[<strong key={Symbol().toString()} />]}
        />
      ) : (
        <Trans
          t={t}
          i18nKey={'policyManagement.homeRenewalQuote.policyCard.noAutoRenew'}
          components={[<strong key={Symbol().toString()} />]}
        />
      )}
    </>
  );
};
