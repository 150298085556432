import { toJS } from 'mobx';
import { useLocation } from 'react-router-dom';

import { JOURNEY_TYPE } from '../../../service/constant';
import { IDriver } from '../../../service/state/models/dashboard/autoPolicyModel';
import { CONTACT_FLOW_TYPE } from '../../../service/state/models/interface/chatBot';
import { useStores } from '../../../service/state/store';
import { useStepperContext } from '../../../view/pages/policies/policy/makeAchange/mta/common/utils/stepperContext';

import { compareDrivers, deletedDrivers } from './chatBotUtils';

interface ReturnValues {
  contactFlowType: CONTACT_FLOW_TYPE;
  customContactAttributes: Record<string, string>;
}

export type MTACustomAttrT =
  | 'ssMTAVehicleCarNotRec'
  | 'ssMTAVehicleModsChat'
  | 'ssMTAVehicleNoQuoteOff'
  | 'ssMTAVehicleNoVRN'
  | 'ssMTAVehicleOfferChar'
  | 'ssMTAVehicleOfferRef'
  | 'ssMTAVehicleDDChar'
  | 'ssMTAVehicleMainChat'
  | 'ssMTAVehicleFirstNameChange'
  | 'ssMTAVehicleEmpDet'
  | 'ssMTAVehicleADClaimsConvict'
  | 'ssMTAVehiclePaymentFailed'
  | 'ssMTAVehicleRC'
  | 'ssRenQuoteUpdatePol'
  | 'ssRenARGoAhead';

// hook that checks which is the existent journey
// and returns an object with the modified properties
// these properties are send to the chatbot when the customer clicks the 'Need help' btn
// or any of the 'Chat with us'/'Chat with a specialist' btns existent in journeys
export const useMTAChatBotAttributes: (customAttr?: MTACustomAttrT) => ReturnValues | undefined = (customAttr) => {
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected, mainVehicle, insured },
      },
      lookups: { decodeByFieldName },
    },
    mtaStore: {
      chatbotModifications,
      pendedMTA: {
        changedDrivers,
        removedDrivers,
        drivers: mtaDrivers,
        vehicle: {
          businessMileageCode,
          classOfUse,
          daytimeLocation,
          vrm,
          estimatedValue,
          trackingDeviceFitted,
          overnightPostcode,
          overnightLocation,
          purchaseDate,
          legalOwner,
          registeredKeeper,
          socialMileageCode,
          securityDeviceDeclared,
        },
        insured: {
          address: { houseName, houseNumber, postcode },
          person: { residencyStatusCode, maritalStatusCode, numberOfChildren, carsInHousehold, surname },
        },
        endorsementDate,
      },
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { drivers: policyDrivers },
        },
      },
    },
  } = useStores();

  const {
    stepsData: { journeyType },
  } = useStepperContext();

  // current page - will determine which journey we are on
  const location = useLocation();
  const dividedPath = location.pathname.split('/');
  const currentPage = dividedPath[dividedPath.length - 1];

  // gets the details of the new driver added for 'Add driver' journey
  const newDriver = mtaDrivers.find((driver) => driver.uuid.includes('emptyDriver'));

  // changedDrivers is a list of changed drivers ids
  // looks in both policy drivers and mta drivers for them
  // when found, both objects are compared to see which properties are changed
  const findDrivers = (listOfDrivers: IDriver[]) =>
    changedDrivers.reduce((acc: IDriver[], cur) => {
      const driver = listOfDrivers.find((d) => d.uuid === cur);
      if (driver) acc.push(toJS(driver));
      return acc;
    }, []);
  const policyD = findDrivers(policyDrivers);
  const mtaD = findDrivers(mtaDrivers);

  const MtaFlow = {
    contactFlowType: CONTACT_FLOW_TYPE.MTA,
  };

  // common for all journeys
  // ssMTAVehicleMainChat - this attribute is send when the customer clicks the 'Need help?' btn
  // ssMTAVehicleOfferChar, ssMTAVehicleOfferRef, ssMTAVehicleDDChar are send on quote page
  const commonattrs = {
    ...(customAttr === 'ssMTAVehicleMainChat' ? { ssMTAVehicleMainChat: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleOfferChar' ? { ssMTAVehicleOfferChar: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleOfferRef' ? { ssMTAVehicleOfferRef: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleDDChar' ? { ssMTAVehicleDDChar: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleRC' && { ssMTAVehicleRC: 'true' }),
    ssMTAVehiclePolicyNumber: policySelected.policyNumber,
    ...(endorsementDate ? { ssMTAVehicleStartDate: endorsementDate.toString() } : {}),
  };

  // 'Change your car' journey - each property from the existent policy is compared with the mta one
  // ssMTAVehicleNoVRN - send when 'Chat with a specialist' btn is clicked, step 2
  // ssMTAVehicleCarNotRec - send when 'Chat with us' btn is clicked, step 3
  // ssMTAVehicleModsChat - send when 'Chat with us' btn is clicked, step 6
  const allCarDetailsAttr = {
    ...(chatbotModifications
      ? {
          ssMTAVehicleModifications: chatbotModifications,
        }
      : {}),
    ...(businessMileageCode && mainVehicle.businessMileageCode !== businessMileageCode
      ? { ssMTAVehicleBusinessMileageCode: decodeByFieldName('businessMileageCode', businessMileageCode) }
      : {}),
    ...(mainVehicle.classOfUse !== classOfUse
      ? { ssMTAVehicleClassOfUse: decodeByFieldName('classOfUse', classOfUse) }
      : {}),
    ...(mainVehicle.daytimeLocation !== daytimeLocation
      ? { ssMTAVehicleDaytimeLocation: decodeByFieldName('daytimeLocation', daytimeLocation) }
      : {}),
    ...(mainVehicle.legalOwner !== legalOwner
      ? { ssMTAVehicleLegalOwner: decodeByFieldName('legalOwner', legalOwner) }
      : {}),
    ...(mainVehicle.estimatedValue && estimatedValue && mainVehicle.estimatedValue !== estimatedValue
      ? { ssMTAVehicleEstimatedValue: `${estimatedValue}` }
      : {}),
    ...(mainVehicle.overnightLocation !== overnightLocation
      ? { ssMTAVehicleOvernightLocation: decodeByFieldName('overnightLocation', overnightLocation) }
      : {}),
    ...(mainVehicle.overnightPostcode !== overnightPostcode
      ? { ssMTAVehicleOvernightPostcode: overnightPostcode }
      : {}),
    ...(mainVehicle.purchaseDate && purchaseDate && mainVehicle.purchaseDate !== purchaseDate
      ? { ssMTAVehiclePurchaseDate: purchaseDate }
      : {}),
    ...(mainVehicle.securityDeviceDeclared !== securityDeviceDeclared && securityDeviceDeclared !== ''
      ? { ssMTAVehicleSecurityDeviceDeclared: decodeByFieldName('securityDeviceDeclared', securityDeviceDeclared) }
      : {}),
    ...(mainVehicle.registeredKeeper !== registeredKeeper
      ? { ssMTAVehicleRegisteredKeeper: decodeByFieldName('registeredKeeper', registeredKeeper) }
      : {}),
    ...(mainVehicle.socialMileageCode !== socialMileageCode
      ? { ssMTAVehicleSocialMileageCode: decodeByFieldName('socialMileageCode', socialMileageCode) }
      : {}),
    ...(mainVehicle.trackingDeviceFitted !== null &&
    trackingDeviceFitted !== null &&
    mainVehicle.trackingDeviceFitted !== trackingDeviceFitted
      ? { ssMTAVehicleTrackingDeviceFitted: trackingDeviceFitted.toString() }
      : {}),
    ...(mainVehicle.vrm !== vrm && vrm !== '' ? { ssMTAVehicleVrm: vrm } : {}),
    ...(customAttr === 'ssMTAVehicleNoVRN' ? { ssMTAVehicleNoVRN: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleCarNotRec' ? { ssMTAVehicleCarNotRec: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleModsChat' ? { ssMTAVehicleModsChat: 'true' } : {}),
    ...commonattrs,
  };

  // attributes send for the entire 'Your details' cluster
  // ssMTAVehicleFirstNameChange - 'Chat with a specialist' btn is clicked, step 2, 'Change personal details' journey
  const allYourDetailsAttrs = {
    ...(houseName && insured.address.houseName !== houseName ? { ssMTAVehicleHouseName: houseName } : {}),
    ...(houseNumber && insured.address.houseNumber !== houseNumber ? { ssMTAVehicleHouseNumber: houseNumber } : {}),
    ...(insured.address.postcode !== postcode ? { ssMTAVehiclePostcode: postcode } : {}),
    ...(insured.person.residencyStatusCode !== residencyStatusCode
      ? { ssMTAVehicleResidentialStatus: decodeByFieldName('residencyStatusCode', residencyStatusCode) }
      : {}),
    ...(mainVehicle.socialMileageCode !== socialMileageCode
      ? { ssMTAVehicleSocialMileageCode: decodeByFieldName('socialMileageCode', socialMileageCode) }
      : {}),
    ...(businessMileageCode && mainVehicle.businessMileageCode !== businessMileageCode
      ? { ssMTAVehicleBusinessMileageCode: decodeByFieldName('businessMileageCode', businessMileageCode) }
      : {}),
    ...(mainVehicle.classOfUse !== classOfUse
      ? { ssMTAVehicleClassOfUse: decodeByFieldName('classOfUse', classOfUse) }
      : {}),
    ...(mainVehicle.daytimeLocation !== daytimeLocation
      ? { ssMTAVehicleDaytimeLocation: decodeByFieldName('daytimeLocation', daytimeLocation) }
      : {}),
    ...(mainVehicle.overnightLocation !== overnightLocation
      ? { ssMTAVehicleOvernightLocation: decodeByFieldName('overnightLocation', overnightLocation) }
      : {}),
    ...(mainVehicle.overnightPostcode !== overnightPostcode
      ? { ssMTAVehicleOvernightPostcode: overnightPostcode }
      : {}),
    ...(insured.person.surname !== surname ? { ssMTAVehicleSurname: surname } : {}),
    ...(insured.person.maritalStatusCode !== maritalStatusCode
      ? { ssMTAVehicleMaritalStatus: decodeByFieldName('maritalStatusCode', maritalStatusCode) }
      : {}),
    ...(numberOfChildren && insured.person.numberOfChildren !== numberOfChildren
      ? { ssMTAVehicleChildrenInHousehold: decodeByFieldName('numberOfChildren', numberOfChildren) }
      : {}),
    ...(insured.person.carsInHousehold !== carsInHousehold
      ? { ssMTAVehicleCarsInHousehold: decodeByFieldName('carsInHousehold', carsInHousehold) }
      : {}),
    ...(customAttr === 'ssMTAVehicleFirstNameChange' ? { ssMTAVehicleFirstNameChange: 'true' } : {}),
    ...commonattrs,
  };

  // atributes send for 'Add driver' journey
  const addDriver = newDriver
    ? {
        ...(newDriver.person.firstName && newDriver.person.surname
          ? {
              ssMTAVehicleADName1: `${newDriver.person.firstName} ${newDriver.person.surname}`,
            }
          : {}),
        ...(newDriver.person.dateOfBirth
          ? {
              ssMTAVehicleADDOB: newDriver.person.dateOfBirth,
            }
          : {}),
        ...(newDriver.person.residentSinceBirth
          ? {
              ssMTAVehicleADUKRes: newDriver.person.residentSinceBirth.toString(),
            }
          : {}),
        ...(newDriver.person.residentSinceBirth === false
          ? {
              ssMTAVehicleADUKRes: `${newDriver.person.residentSinceMonthsCode}-${newDriver.person.residentSinceYearsCode}`,
            }
          : {}),
        ...(newDriver.person.maritalStatusCode
          ? {
              ssMTAVehicleADMaritalStatus: decodeByFieldName('maritalStatusCode', newDriver.person.maritalStatusCode),
            }
          : {}),
        ...(newDriver.relationshipToProposerCode
          ? {
              ssMTAVehicleADRelation: decodeByFieldName(
                'relationshipToProposerCode',
                newDriver.relationshipToProposerCode,
              ),
            }
          : {}),
        ...(newDriver.person.employmentStatusCode
          ? {
              ssMTAVehicleADEmploymentStatus: decodeByFieldName(
                'employmentStatusCode',
                newDriver.person.employmentStatusCode,
              ),
            }
          : {}),
        ...(newDriver.person.primaryOccupationCode
          ? {
              ssMTAVehicleADPrimaryOccupation: decodeByFieldName(
                'primaryOccupationCode',
                newDriver.person.primaryOccupationCode,
              ),
            }
          : {}),
        ...(newDriver.person.primaryOccupationIndustryCode
          ? {
              ssMTAVehicleADPrimaryIndustry: decodeByFieldName(
                'primaryOccupationIndustryCode',
                newDriver.person.primaryOccupationIndustryCode,
              ),
            }
          : {}),
        ...(typeof newDriver.person.hasSecondaryOccupation === 'boolean'
          ? {
              ssMTAVehicleADHasSecondaryOccupation: newDriver.person.hasSecondaryOccupation.toString(),
            }
          : {}),
        ...(newDriver.person.hasSecondaryOccupation && newDriver.person.secondaryOccupationCode
          ? {
              ssMTAVehicleADSecondaryOccupation: decodeByFieldName(
                'secondaryOccupationCode',
                newDriver.person.secondaryOccupationCode,
              ),
            }
          : {}),
        ...(newDriver.person.hasSecondaryOccupation && newDriver.person.secondaryOccupationIndustryCode
          ? {
              ssMTAVehicleADSecondaryIndustry: decodeByFieldName(
                'secondaryOccupationIndustryCode',
                newDriver.person.secondaryOccupationIndustryCode,
              ),
            }
          : {}),
        ...(newDriver.licence.licenceTypeCode
          ? {
              ssMTAVehicleADLicenseType: decodeByFieldName('licenceTypeCode', newDriver.licence.licenceTypeCode),
            }
          : {}),
        ...(newDriver.licence.licenseDateYearsCode
          ? {
              ssMTAVehicleADLicenseHeldSince: decodeByFieldName(
                'licenseDateYearsCode',
                newDriver.licence.licenseDateYearsCode,
              ),
            }
          : {}),

        ...(newDriver.licence.licenseRestrictionCode
          ? {
              ssMTAVehicleADLicenseRestrictions: decodeByFieldName(
                'licenseRestrictionCode',
                newDriver.licence.licenseRestrictionCode,
              ),
            }
          : {}),
        ...(newDriver.useOfOtherCarsCode
          ? {
              ssMTAVehicleADAccessOtherVehicle: decodeByFieldName('useOfOtherCarsCode', newDriver.useOfOtherCarsCode),
            }
          : {}),
        ...(typeof newDriver.declinedOnSpecialTerms === 'boolean'
          ? {
              ssMTAVehicleADPolCanc: newDriver.declinedOnSpecialTerms.toString(),
            }
          : {}),
        ...(typeof newDriver.unspentConvictions === 'boolean'
          ? {
              ssMTAVehicleADNonMotorConvict: newDriver.unspentConvictions.toString(),
            }
          : {}),
        ...(typeof newDriver.claimsFive === 'boolean'
          ? {
              ssMTAVehicleADClaimsFive: newDriver.claimsFive.toString(),
            }
          : {}),
        ...(typeof newDriver.prosecutionsOnOffences === 'boolean'
          ? {
              ssMTAVehicleADConvFive: newDriver.prosecutionsOnOffences.toString(),
            }
          : {}),
        ...commonattrs,
      }
    : {};

  // atributes for 'Change driver' journey
  // ssMTAVehicleFirstNameChange - send when 'Chat with a specialist' btn is clicked, 'What's the driver's name?' step
  // ssMTAVehicleADClaimsConvict - send when 'Chat with a specialist' btn is clicked, 'Are their details correct?' step
  const changeDriver = {
    ...(changedDrivers.length > 0 ? { ssMTAVehicleADNumDriversToChange: changedDrivers.length.toString() } : {}),
    ...compareDrivers(policyD, mtaD, decodeByFieldName),
    ...(customAttr === 'ssMTAVehicleFirstNameChange' ? { ssMTAVehicleADFirstNameChange: 'true' } : {}),
    ...(customAttr === 'ssMTAVehicleADClaimsConvict' ? { ssMTAVehicleADClaimsConvict: 'true' } : {}),
    ...commonattrs,
  };

  switch (currentPage) {
    case 'quote-ineligible':
      return {
        customContactAttributes: {
          ssMTAType: journeyType,
          // no quote has been provided online,'Chat with a specialist' is clicked
          ...(customAttr === 'ssMTAVehicleNoQuoteOff' ? { ssMTAVehicleNoQuoteOff: 'true' } : {}),
        },
        ...MtaFlow,
      };
    case 'payment':
    case 'payment-confirmation':
      return {
        customContactAttributes: {
          ...(journeyType === JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS || journeyType === JOURNEY_TYPE.CHANGE_HOME_ADDRESS
            ? allYourDetailsAttrs
            : allCarDetailsAttr),
          ssMTAType: journeyType,
        },
        ...MtaFlow,
      };
    case 'payment-error':
      return {
        customContactAttributes: {
          ...(journeyType === JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS || journeyType === JOURNEY_TYPE.CHANGE_HOME_ADDRESS
            ? allYourDetailsAttrs
            : allCarDetailsAttr),
          ssMTAType: journeyType,
          // unable to process the payment, 'Chat with us' btn is clicked
          ...(customAttr === 'ssMTAVehiclePaymentFailed' ? { ssMTAVehiclePaymentFailed: 'true' } : {}),
        },
        ...MtaFlow,
      };
    case 'change-your-car':
      return {
        customContactAttributes: {
          ...allCarDetailsAttr,
          ...(mainVehicle.trackingDeviceFitted !== null && trackingDeviceFitted !== null
            ? { ssMTAVehicleTrackingDeviceFitted: trackingDeviceFitted.toString() }
            : {}),
          ssMTAType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
        },
        ...MtaFlow,
      };
    case 'change-your-registration-number':
      return {
        customContactAttributes: { ...allCarDetailsAttr, ssMTAType: JOURNEY_TYPE.REGISTRATION_NUMBER },
        ...MtaFlow,
      };
    case 'car-modifications':
      return {
        customContactAttributes: { ...allCarDetailsAttr, ssMTAType: JOURNEY_TYPE.CAR_MODIFICATIONS },
        ...MtaFlow,
      };
    case 'car-mileage-and-usage':
      return {
        customContactAttributes: {
          ...allCarDetailsAttr,
          ssMTAType: JOURNEY_TYPE.CAR_MILEAGE_AND_USAGE,
        },
        ...MtaFlow,
      };
    case 'other-changes':
      return {
        customContactAttributes: { ...allCarDetailsAttr, ssMTAType: JOURNEY_TYPE.OTHER_CHANGES },
        ...MtaFlow,
      };
    case 'home-address':
      return {
        customContactAttributes: { ...allYourDetailsAttrs, ssMTAType: JOURNEY_TYPE.CHANGE_HOME_ADDRESS },
        ...MtaFlow,
      };
    case 'personal-details':
      return {
        customContactAttributes: { ...allYourDetailsAttrs, ssMTAType: JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS },
        ...MtaFlow,
      };
    case 'your-details':
      return {
        customContactAttributes: {
          ssMTAType: JOURNEY_TYPE.CHANGE_EMPLOYMENT_DETAILS,
          // send when 'Employment details' card is clicked
          ...(customAttr === 'ssMTAVehicleEmpDet' ? { ssMTAVehicleEmpDet: 'true' } : {}),
        },
        ...MtaFlow,
      };
    case 'add-driver':
      return {
        customContactAttributes: {
          ...addDriver,
          ssMTAType: JOURNEY_TYPE.ADD_DRIVER,
        },
        ...MtaFlow,
      };
    case 'change-driver-details':
      return {
        customContactAttributes: {
          ...changeDriver,
          ssMTAType: JOURNEY_TYPE.CHANGE_DRIVER,
        },
        ...MtaFlow,
      };
    case 'remove-driver':
      return {
        customContactAttributes: {
          ...commonattrs,
          ...(removedDrivers.length > 0
            ? {
                ssMTAVehicleADDriversToRemove: deletedDrivers(policyDrivers, removedDrivers).join(', '),
              }
            : {}),
          ssMTAType: JOURNEY_TYPE.REMOVE_DRIVER,
        },
        ...MtaFlow,
      };
    case 'your-cover-details':
      return {
        customContactAttributes: commonattrs,
        ...MtaFlow,
      };
    default:
      return undefined;
  }
};
