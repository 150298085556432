import { Instance, types } from 'mobx-state-tree';

import { DateTime } from '../../types/dateTime';

const string = types.optional(types.string, '');

export const AutoRenewPolicies = types.model('AutoRenewPolicies', {
  effectiveDate: DateTime,
  policyNumber: string,
});

export const CardDetails = types.model('CardDetails', {
  cardHolderName: string,
  creditCardType: string,
  expirationDate: string,
  number: string,
});

export const PaymentMethod = types.model('PaymentMethod', {
  autoRenewPolicies: types.optional(types.array(AutoRenewPolicies), () => []),
  creditCardDetails: types.optional(CardDetails, () => CardDetails.create()),
  type: string,
});

export const PaymentMethods = types.model('PaymentMethods', {
  methods: types.optional(types.array(PaymentMethod), () => []),
});

export type IPaymentMethod = Instance<typeof PaymentMethod>;
export type IPaymentMethods = Instance<typeof PaymentMethods>;
