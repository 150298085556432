import { useTranslation } from 'react-i18next';

import { DATE_TYPES, LOOKUP, LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { formatDate } from '../../../../../../../../service/util/formatDate';
import SelfService from '../../../../../../../../service/util/selfService';
import { useStepperContext } from '../../common/utils/stepperContext';
import { Column, Row } from '../components/multipleColumnsEditPage';

import { filterRows } from '.';

interface EditPageReturn {
  getDriverRows: (driver: IDriver) => Row[];
  handleBack: () => void;
  handleSave: () => void;
  vehicleColumns: Column[];
  vehicleRows: Row[];
}

export const useEditPage = (filteredColumns?: LOOKUP_TYPE_PAGE[]): EditPageReturn => {
  const {
    interfaceStore: {
      isDesktop,
      notification: { openNotification },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
    mtaStore: {
      pendedMTA: { vehicle, driverFullName },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { setEditPage } = useStepperContext();

  const {
    classOfUse,
    legalOwner,
    registeredKeeper,
    daytimeLocation,
    overnightPostcode,
    overnightAddress,
    sameOvernightAddress,
    businessMileageCode,
    socialMileageCode,
    overnightLocation,
  } = vehicle;

  const handleOnSave = () => {
    setEditPage(undefined);
    openNotification({ message: t('validations.onSave') });
  };

  const handleBack = () => {
    setEditPage(undefined);
  };

  const vehicleColumns: Column[] = [
    {
      rows: [
        {
          id: LOOKUP_TYPE_PAGE.LEGAL_OWNER,
          label: t('makeChange.carDetails.legalOwner'),
          title: t('MTAJourney.CarUsage.legalOwner'),
          values: [getLookup(LOOKUP.OWNER, legalOwner)],
        },
        {
          id: LOOKUP_TYPE_PAGE.USAGE,
          label: t('MTAJourney.CarUsage.useOfCar'),
          title: t('MTAJourney.CarUsage.usageEdit.title'),
          values: [getLookup(LOOKUP.USAGE, classOfUse)],
        },
        {
          id: LOOKUP_TYPE_PAGE.DAYTIME_LOCATION,
          label: t('makeChange.carDetails.daytimeLocation'),
          showDivider: !isDesktop,
          title: t('MTAJourney.CarUsage.daytimeLocation'),
          values: [getLookup(LOOKUP.DAYTIME_LOCATION, daytimeLocation)],
        },
      ],
    },
    {
      rows: filterRows([
        {
          id: LOOKUP_TYPE_PAGE.REGISTERED_KEEPER,
          label: t('makeChange.carDetails.registeredKeeper'),
          title: t('MTAJourney.CarUsage.registeredKeeper'),
          values: [getLookup(LOOKUP.KEEPER, registeredKeeper)],
        },
        {
          id: LOOKUP_TYPE_PAGE.SOCIAL_MILEAGE,
          label: t('MTAJourney.CarUsage.estimatedAnnualSocialMileage'),
          title: t('MTAJourney.CarUsage.usageEdit.title'),
          values: [getLookup(LOOKUP.MILEAGE, socialMileageCode)],
        },
        classOfUse !== '033' &&
          classOfUse !== '001' && {
            id: LOOKUP_TYPE_PAGE.BUSINESS_MILEAGE,
            label: t('MTAJourney.CarUsage.estimatedAnnualBusinessMileage'),
            title: t('MTAJourney.CarUsage.usageEdit.title'),
            values: [getLookup(LOOKUP.MILEAGE, businessMileageCode ?? '')],
          },
        {
          id: LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE,
          label: t('MTAJourney.CarUsage.usageEdit.overnight'),
          title: t('MTAJourney.CarUsage.overnightLocation'),
          values: sameOvernightAddress
            ? [getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation)]
            : [
                overnightAddress.postcode !== ''
                  ? SelfService.formatAddress(overnightAddress).full
                  : overnightPostcode || '',
                getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation),
              ],
        },
      ]),
    },
  ];

  const vehicleRows: Row[] = filterRows([
    {
      id: LOOKUP_TYPE_PAGE.LEGAL_OWNER,
      label: t('makeChange.carDetails.legalOwner'),
      title: t('MTAJourney.CarUsage.legalOwner'),
      values: [getLookup(LOOKUP.OWNER, legalOwner)],
    },
    {
      id: LOOKUP_TYPE_PAGE.USAGE,
      label: t('MTAJourney.CarUsage.useOfCar'),
      title: t('MTAJourney.CarUsage.usageEdit.title'),
      values: [getLookup(LOOKUP.USAGE, classOfUse)],
    },
    {
      id: LOOKUP_TYPE_PAGE.DAYTIME_LOCATION,
      label: t('MTAJourney.CarUsage.usageEdit.daytime'),
      showDivider: false,
      title: t('MTAJourney.CarUsage.daytimeLocation'),
      values: [getLookup(LOOKUP.DAYTIME_LOCATION, daytimeLocation)],
    },
    {
      id: LOOKUP_TYPE_PAGE.REGISTERED_KEEPER,
      label: t('makeChange.carDetails.registeredKeeper'),
      title: t('MTAJourney.CarUsage.registeredKeeper'),
      values: [getLookup(LOOKUP.KEEPER, registeredKeeper)],
    },
    {
      id: LOOKUP_TYPE_PAGE.SOCIAL_MILEAGE,
      label: t('MTAJourney.CarUsage.estimatedAnnualSocialMileage'),
      title: t('MTAJourney.CarUsage.usageEdit.title'),
      values: [getLookup(LOOKUP.MILEAGE, socialMileageCode)],
    },
    classOfUse !== '033' &&
      classOfUse !== '001' && {
        id: LOOKUP_TYPE_PAGE.BUSINESS_MILEAGE,
        label: t('MTAJourney.CarUsage.estimatedAnnualBusinessMileage'),
        title: t('MTAJourney.CarUsage.usageEdit.title'),
        values: [getLookup(LOOKUP.MILEAGE, businessMileageCode ?? '')],
      },
    {
      id: LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE,
      label: t('MTAJourney.CarUsage.usageEdit.overnight'),
      title: t('MTAJourney.CarUsage.overnightLocation'),
      values: sameOvernightAddress
        ? [getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation)]
        : [
            overnightAddress.postcode !== ''
              ? SelfService.formatAddress(overnightAddress).full
              : overnightPostcode || '',
            getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation),
          ],
    },
  ]);

  const columns = filteredColumns
    ? vehicleColumns.map((itm) => {
        return {
          rows: itm.rows.filter((i) => filteredColumns.includes(i.id as LOOKUP_TYPE_PAGE)),
        };
      })
    : vehicleColumns;

  const getDriverRows = (driver: IDriver): Row[] => {
    const driverRows: Row[] = filterRows([
      {
        id: LOOKUP_TYPE_PAGE.TITLE,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.driverName'),
        values: [`${getLookup(LOOKUP.TITLE, driver.person.titleCode)} ${driverFullName(driver)}`],
      },
      {
        id: LOOKUP_TYPE_PAGE.DATE_OF_BIRTH,
        ignoreEdit: true,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.dob'),
        values: [formatDate(driver.person.dateOfBirth?.toString(), DATE_TYPES.WEEKDAY_SHORT)],
      },
      {
        id: LOOKUP_TYPE_PAGE.RESIDENT_SINCE_BIRTH,
        ignoreEdit: true,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.resident'),
        values: [driver.person.residentSinceBirth ? t('labels.yes') : t('labels.no')],
      },
      {
        id: LOOKUP_TYPE_PAGE.MARITAL_STATUS,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.maritalStatus'),
        values: [getLookup(LOOKUP.MARITAL_STATUS, driver.person.maritalStatusCode)],
      },
      {
        id: LOOKUP_TYPE_PAGE.RELATIONSHIP_TO_PROPOSER,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.relationship'),
        values: [getLookup(LOOKUP.DRIVER_RELATIONSHIP, driver.relationshipToProposerCode)],
      },
      {
        id: LOOKUP_TYPE_PAGE.EMPLOYMENT,
        label: t('MTAJourney.driverOccupation.employmentStatusLabel'),
        values: [getLookup(LOOKUP.EMPLOYMENT, driver.person.employmentStatusCode ?? '')],
      },
      {
        id: LOOKUP_TYPE_PAGE.PRIMARY_OCCUPATION,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.primaryOccupation'),
        values: [
          getLookup(LOOKUP.OCCUPATION, driver.person.primaryOccupationCode ?? ''),
          getLookup(LOOKUP.INDUSTRY, driver.person.primaryOccupationIndustryCode ?? ''),
        ],
      },
      driver.person.hasSecondaryOccupation === true && {
        id: LOOKUP_TYPE_PAGE.SECONDARY_OCCUPATION,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.secondaryOccupation'),
        values: [
          getLookup(LOOKUP.OCCUPATION, driver.person.secondaryOccupationCode ?? ''),
          getLookup(LOOKUP.INDUSTRY, driver.person.secondaryOccupationIndustryCode ?? ''),
        ],
      },
      !driver.person.hasSecondaryOccupation && {
        id: LOOKUP_TYPE_PAGE.SECONDARY_OCCUPATION,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.secondaryOccupation'),
        values: [t('labels.no')],
      },
      {
        id: LOOKUP_TYPE_PAGE.LICENCE_TYPE,
        label: t('MTAJourney.ReviewChanges.reviewAddDriver.licenceType'),
        values: [
          getLookup(LOOKUP.LICENCE_TYPE, driver.licence.licenceTypeCode),
          formatDate(driver.licence.licenceDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
          getLookup(LOOKUP.LICENSE_RESTRICTIONS, driver.licence.licenseRestrictionCode),
        ],
      },
      {
        id: LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS,
        label: t('MTAJourney.accessToOtherCars.description'),
        values: [getLookup(LOOKUP.ACCESS_TO_OTHER_CARS, driver.useOfOtherCarsCode)],
      },
    ]);
    return driverRows;
  };

  return {
    getDriverRows,
    handleBack,
    handleSave: handleOnSave,
    vehicleColumns: columns,
    vehicleRows,
  };
};
