import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .heading': {
      marginBottom: spacing(2),
    },
    '& .number': {
      background: palette.secondary.main,
      borderRadius: '50%',
      color: palette.common.white,
      height: spacing(4),
      lineHeight: `${spacing(4)}px`,
      marginRight: spacing(1),
      textAlign: 'center',
      width: spacing(4),
    },
    border: `2px solid ${palette.secondary.main}80`,
    borderRadius: '8px',
    margin: spacing(2, 0),
    padding: spacing(2),
    whiteSpace: 'pre-line',
  },
}));

interface WhatsNextBoxProps {
  description?: string;
  number: string;
  title: string;
}

export const WhatsNextBox: React.FC<WhatsNextBoxProps> = observer(({ number, description, title, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row" alignItems="center" className="heading">
        <Typography variant="h3" className="number">
          {number}
        </Typography>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Typography>{description}</Typography>
      {children}
    </Grid>
  );
});
