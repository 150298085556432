import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Accordion as MuiAccordion,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { memo, useState } from 'react';

import IconSure from '../icon';

export interface AccordionProps {
  children: React.ReactElement;
  defaultBehaviour?: boolean;
  expanded?: boolean;
  onChange?: () => void;
  title: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .content': {
      border: `2px solid ${palette.secondary.dark}50`,
      borderRadius: spacing(0, 0, 1, 1),
      borderTop: 'none',
      gap: spacing(2),
      padding: spacing(2),
      paddingTop: 0,
    },
    '& .header': {
      border: `2px solid ${palette.secondary.main}50`,
      borderRadius: spacing(1),
      padding: spacing(2),
    },
    '& .title': { whiteSpace: 'nowrap' },
    '&.open': {
      '& .header': {
        borderBottom: 'none',
        borderRadius: spacing(1, 1, 0, 0),
      },
    },
    width: '100%',
  },
}));

const BasicAccordion: React.FC<AccordionProps> = observer(function BasicAccordion(props) {
  const { expanded, onChange, title, children } = props;
  const [open, setOpen] = useState(expanded ?? false);
  const classes = useStyles();

  const handleChange = () => {
    setOpen(!open);
    onChange?.();
  };

  return (
    <MuiAccordion className={clsx(classes.root, open ? 'open' : 'closed')} expanded={open}>
      <AccordionSummary
        onClick={handleChange}
        className={'header'}
        expandIcon={<IconSure icon="chevron-down" size="26" color="secondary" />}
      >
        <Typography variant="h2">{title}</Typography>
      </AccordionSummary>
      <Grid container component={AccordionDetails} className="content">
        {children}
      </Grid>
    </MuiAccordion>
  );
});
export default memo(BasicAccordion);
