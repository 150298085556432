import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const DriverRelationshipRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
      insurance: {
        auto: {
          policySelected: { drivers },
        },
      },
    },
    renewalChangeStore: {
      renewalDetails: { selectedDriver, setSelectedDriver },
    },
  } = useStores();
  const [relationship, setRelationshipList] = useState<ILookupItem[] | undefined>();
  const [relationshipSelected, setRelationshipSelected] = useState(selectedDriver.relationshipToProposerCd);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation('myAccount');

  const { nextStep, setTitle } = useRenewalStepperContext();

  const isNotValid = () => {
    return relationshipSelected === '' || (relationshipSelected === 'S' && hasSpouse);
  };

  const handleRenewalChange = () => {
    setIsSubmitting(true);
    !isNotValid() && setSelectedDriver({ ...selectedDriver, relationshipToProposerCd: relationshipSelected });
  };

  const handleContinue = () => {
    handleRenewalChange();
    !isNotValid() && nextStep();
  };

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.DRIVER_RELATIONSHIP],
  });
  useEffect(() => {
    setTitle(t('MTAJourney.driverRelationship.title'));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.DRIVER_RELATIONSHIP] !== undefined) {
      const relationshipsNoProposer = lookupsByName[LOOKUP.DRIVER_RELATIONSHIP].filter((item) => item.code !== 'P');
      setRelationshipList(relationshipsNoProposer);
    }
  }, [isLookupsFetched, lookupsByName]);

  const hasSpouse = drivers.find((driver) => driver.relationshipToProposerCode === 'S');

  return (
    <>
      <Typography variant="body1">{t('MTAJourney.driverRelationship.description')}</Typography>
      <RadioGroup
        value={relationshipSelected}
        onChange={(event) => {
          setRelationshipSelected(event.target.value);
        }}
      >
        {relationship?.map((item, index) => {
          return (
            <FormControlLabel key={`${item.code}-${index}`} value={item.code} control={<Radio />} label={item.value} />
          );
        })}
      </RadioGroup>
      {relationshipSelected === '' && isSubmitting && (
        <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
      )}
      {relationshipSelected === 'S' && isSubmitting && hasSpouse && (
        <FormHelperText error>{t('validations.spouseError')}</FormHelperText>
      )}

      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_RELATED_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_RELATED_CANCEL}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_RELATED_CONTINUE, handleContinue }}
      />
    </>
  );
});
