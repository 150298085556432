import { FormControl, MenuItem, Select, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { useState } from 'react';

import IconSure from '../../component/common/icon';

export interface ValuePairs {
  label: string;
  value: string;
}

export interface SelectDropDownProps {
  defaultValue: string;
  extraClass?: string;
  isOpen?: boolean;
  labelId?: string;
  onChangeHandler: (value: string) => void;
  values: ValuePairs[];
  variant?: string;
}

const useStyles = makeStyles((theme) => ({
  selectBox: {
    borderStyle: 'solid',
    fontSize: '16px',
    padding: 0,
  },
  variant: {
    '&.dark': {
      borderColor: theme.palette.text.primary,
      borderWidth: `1px 1px 0 1px`,
    },
    '&.secondary': {
      borderColor: theme.palette.secondary.main,
      borderRadius: '4px',
      borderWidth: '2px',
    },
  },
}));

export const SelectDropDown: React.FC<SelectDropDownProps> = ({
  onChangeHandler,
  labelId,
  extraClass,
  defaultValue,
  isOpen,
  values,
  variant = 'dark',
}) => {
  const classes = useStyles();
  const data: ValuePairs[] = values;

  const handleChange = (value: string) => {
    setCurrent(value);
    onChangeHandler(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [current, setCurrent] = useState(defaultValue);
  const [open, setOpen] = useState(isOpen ?? false);

  return (
    <FormControl>
      <Select
        labelId={labelId}
        className={clsx(classes.selectBox, extraClass, classes.variant, variant)}
        defaultValue={defaultValue}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        data-testid="select-dropdown"
        IconComponent={() => <IconSure icon="chevron-down" type="solid" size="40" color={`${variant}`} />}
        value={current}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleChange(e.target.value as string)}
      >
        {data.map((item) => {
          return (
            <MenuItem value={item.value} key={item.label}>
              {' '}
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
