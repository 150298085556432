import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { DateTime as DateTimeLuxon } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../../../component/common/button/commonButton';
import { IconType } from '../../../../../../../../component/common/icon';
import { NavigationCardSmall } from '../../../../../../../../component/common/navigationCardSmall';
import { ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { SameDayMta } from '../../carDetails/components/sameDayMta';
import { useStepperContext } from '../utils/stepperContext';

export interface ItemList {
  disabled?: boolean;
  endorsementReason: string;
  icon: string;
  iconType: IconType;
  length?: string;
  route: string;
  subtitle?: string;
  title: string;
}

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .button': {
      minWidth: '140px',
    },
    '& .card': {
      '&__child-container': {
        [breakpoints.down('xs')]: {
          padding: spacing(1, 0),
        },
        padding: spacing(1),
      },
      '&__parent-container': {
        '&--mta': {
          padding: spacing(0, 0),
        },
        padding: spacing(3, 0),
      },
    },
    '& .subtitle': {
      '& a': {
        '&:link': {
          color: palette.secondary.main,
          textDecoration: 'none',
        },
        '&:visited': {
          color: palette.secondary.main,
          textDecoration: 'none',
        },
      },
      paddingTop: spacing(1),
    },
    '& .title': {
      '&__container': {
        padding: spacing(4, 0, 3, 0),
      },
    },
  },
}));

export interface NavigationLayoutProps {
  banner: string;
  cards: ItemList[];
  mtaType: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route?: string) => void;
  section?: string;
  subtitle?: string | ReactNode;
  title: string;
}
export const customSegmentEventName = (cardTitle: string): string | null => {
  switch (cardTitle) {
    case 'Change driver details':
      return 'Change driver details';
    case 'Other changes':
      return 'Car other changes';
    case 'Registration number':
      return 'Change of reg';
    case 'Change your car':
      return 'Change of car';
    default:
      return null;
  }
};
export const NavigationLayout: React.FC<NavigationLayoutProps> = observer(function NavigationLayout({
  cards,
  mtaType,
  title,
  subtitle,
  banner,
  onClick,
  section,
}) {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const todaysDate = DateTimeLuxon.fromISO(DateTimeLuxon.now().toISO());
  const {
    interfaceStore: { isMobile, hasFeatureFlag },
    mtaStore: {
      pendedMTA: { setEndorsementReason, endorsementReason, setEndorsementSubReason, endorsementSubReasons },
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { lastCompletedMtaDate },
        },
      },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const { isMultiMTA } = useStepperContext();

  const handleBack = () => {
    history.push(ROUTE.MAKE_CHANGE);
  };

  const setReasons = (newEndorsementReason: string) => {
    if (endorsementReason === '') {
      setEndorsementReason(newEndorsementReason);
    } else {
      if (endorsementSubReasons.includes(newEndorsementReason) || endorsementReason.includes(newEndorsementReason)) {
        return;
      } else {
        setEndorsementSubReason(newEndorsementReason);
      }
    }
  };

  const renderSubtitle = () => {
    if (isMobile || isMultiMTA || !subtitle) return;

    if (typeof subtitle === 'string') {
      return (
        <Typography variant="body1" className="subtitle">
          <span
            dangerouslySetInnerHTML={{
              __html: subtitle,
            }}
          />
        </Typography>
      );
    } else return subtitle;
  };

  return (
    <>
      {!isMultiMTA && <BlueBanner title={banner} route={ROUTE.MAKE_CHANGE} />}
      <Grid container direction="column" className={clsx(classes.root)}>
        {lastCompletedMtaDate &&
        lastCompletedMtaDate.day === todaysDate.day &&
        lastCompletedMtaDate.month === todaysDate.month &&
        !hasFeatureFlag('BDILMM-2500') ? (
          <SameDayMta />
        ) : (
          <Grid item className={isMultiMTA ? '' : 'title__container'}>
            {!isMultiMTA && <Typography variant="h2">{title}</Typography>}
            {isMultiMTA && cards.length > 0 && (
              <Typography className="card__child-container" variant="h3">
                {section}
              </Typography>
            )}
            {renderSubtitle()}

            <Grid
              container
              item
              className={isMultiMTA ? 'card__parent-container--mta' : 'card__parent-container'}
              data-testid="button-ButtonCard"
            >
              {cards.map((card, idx) => {
                const { subtitle, title, icon, route, endorsementReason, iconType, disabled } = card;
                return (
                  !disabled && (
                    <Grid item xs={isMobile ? 12 : 6} key={idx} className="card__child-container">
                      <Link
                        to={{
                          pathname: route,
                        }}
                        style={{ textDecoration: 'none' }}
                        onClick={(event) => {
                          setReasons(endorsementReason);
                          onClick?.(event, route);
                        }}
                      >
                        <NavigationCardSmall
                          title={title}
                          description={subtitle}
                          color="secondary"
                          icon={icon}
                          showDescription={true}
                          iconType={iconType}
                          trackEvent={() => {
                            eventTrack('Button clicked', {
                              componentID: customSegmentEventName(title) ?? title,
                              description: 'Specific Mta Journey started',
                              eventFiredOn: title,
                              goal: 'Mta',
                              journeyType: title,
                              label: title,
                              step: 'mta-journey-started',
                              url: window.location.href,
                            });
                          }}
                        />
                      </Link>
                    </Grid>
                  )
                );
              })}
            </Grid>
            {!isMultiMTA && (
              <CommonButton
                size="large"
                variant="outlined"
                onClick={handleBack}
                color="secondary"
                data-testid={`${mtaType}BackButton`}
                text={t('MTAJourney.button.back')}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
});
