import {
  Alert,
  AlertTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  RegistrationPlate,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP, NON_DVLA_REGISTRATION_NUMBER_CHANGE } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

interface DvlaCheckProps {
  carFoundManualUpdate?: boolean;
  handleBack: () => void;
  registrationNo?: string;
  setManualRegistrationChange?: (value: boolean) => void;
  vehicleMakeModel?: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: `${spacing(2)}px`,
  },
  label: {
    '& span': {
      fontWeight: 700,
    },
  },
}));

export const DvlaCheck: React.FC<DvlaCheckProps> = observer(function DvlaCheck({
  registrationNo,
  handleBack,
  vehicleMakeModel,
  carFoundManualUpdate = false,
  setManualRegistrationChange,
}) {
  const [isCarRegisteredWithDvla, setIsCarRegisteredWithDvla] = useState<boolean | null>(null);
  const [registrationChangeReason, setRegistrationChangeReason] = useState<NON_DVLA_REGISTRATION_NUMBER_CHANGE | null>(
    null,
  );
  const [regChangeReasonsList, setRegChangeReasonsList] = useState<ILookupItem[] | undefined>();

  const {
    dashboardStore: {
      lookups: { lookupsByName },
      insurance: {
        auto: { mainVehicle },
      },
    },
    mtaStore: {
      pendedMTA: { vehicle, setVehicle, setIsCarRegistrationManuallyChanged },
    },
    interfaceStore: { setHasQuoteError },
  } = useStores();

  const { bodyTypeCode, engineSize, fuelTypeCode, make, manufactureYear, model, numberOfDoors, numberOfSeats } =
    mainVehicle;

  const { nextStep } = useStepperContext();

  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.PORTAL_REG_CHANGE],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.PORTAL_REG_CHANGE] !== undefined) {
      setRegChangeReasonsList(lookupsByName[LOOKUP.PORTAL_REG_CHANGE]);
    }
  }, [isLookupsFetched, lookupsByName]);

  /* istanbul ignore next */
  const handlePendedMta = useCallback((): void => {
    if (!registrationNo) return;

    setVehicle({
      ...vehicle,
      bodyTypeCode,
      engineSize,
      fuelTypeCode,
      make,
      manufactureYear,
      model,
      numberOfDoors,
      numberOfSeats,
      vrm: registrationNo,
    });
    setIsCarRegistrationManuallyChanged(true);
  }, [registrationNo, setIsCarRegistrationManuallyChanged, setVehicle, vehicle]);

  /* istanbul ignore next */
  const handleContinue = useCallback((): void => {
    if (!registrationNo || !isCarRegisteredWithDvla) return;
    if (registrationChangeReason === NON_DVLA_REGISTRATION_NUMBER_CHANGE.NONE) {
      setHasQuoteError(true);
    }
    handlePendedMta();
    nextStep();
  }, [handlePendedMta, nextStep, registrationNo, isCarRegisteredWithDvla, registrationChangeReason, setHasQuoteError]);

  const isValid = useCallback((): boolean => {
    if (setManualRegistrationChange) {
      setManualRegistrationChange(false);
    }
    handleContinue();
    return false;
  }, [handleContinue, setManualRegistrationChange]);

  return (
    <>
      <Grid>
        <Grid className={classes.description}>
          {carFoundManualUpdate && vehicleMakeModel ? (
            <Typography variant="body1" className={classes.label}>
              {t('MTAJourney.CarRegistration.dvlaCheck.carDescription')}
              <span
                dangerouslySetInnerHTML={{
                  __html: `${vehicleMakeModel}.`,
                }}
              />
            </Typography>
          ) : (
            ''
          )}
          <Typography variant="body1">{t('MTAJourney.CarRegistration.dvlaCheck.checkDescription')}</Typography>
          {registrationNo && <RegistrationPlate label={registrationNo} active={true} />}
          <Typography variant="body1">{t('MTAJourney.CarRegistration.dvlaCheck.notice')}</Typography>
          <Typography variant="body1">
            {t(
              `MTAJourney.CarRegistration.${
                carFoundManualUpdate ? 'carFound.foundInDvla' : 'carNotFound.missingInDvla'
              }.question`,
            )}
          </Typography>
          <Choice
            identifier="isCarRegisteredWithDvla"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsCarRegisteredWithDvla(event.target.value === 'true');
            }}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={isCarRegisteredWithDvla}
          />
          {isCarRegisteredWithDvla === false && (
            <Alert variant="standard" severity="error">
              <AlertTitle>{t('MTAJourney.CarRegistration.dvlaCheck.alert.title')}</AlertTitle>
              <Typography>{t('MTAJourney.CarRegistration.dvlaCheck.alert.description')}</Typography>
            </Alert>
          )}
          {isCarRegisteredWithDvla && (
            <Grid>
              <Typography>{t('MTAJourney.CarRegistration.dvlaCheck.whyAreYouChanging')}</Typography>
              <RadioGroup
                onChange={(event, value) =>
                  event.target.checked && setRegistrationChangeReason(value as NON_DVLA_REGISTRATION_NUMBER_CHANGE)
                }
                value={registrationChangeReason}
              >
                {regChangeReasonsList?.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.code}
                      value={item.code}
                      control={<Radio />}
                      label={item.value}
                      className={classes.label}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      </Grid>
      <MTAFooter
        backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_REGISTRATION_NUMBER_NOT_FOUND}
        cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_REGISTRATION_NUMBER_NOT_FOUND}
        handleBack={handleBack}
        isValid={isValid}
        primaryButton={{
          buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CAR_NOT_FOUND,
          disabled: !registrationChangeReason,
          text: t(
            `MTAJourney.CarRegistration.${
              carFoundManualUpdate && registrationChangeReason !== NON_DVLA_REGISTRATION_NUMBER_CHANGE.NONE
                ? 'carFound.foundInDvla'
                : 'carNotFound.missingInDvla'
            }.continueButton`,
          ),
        }}
        hidePrimaryButton={!isCarRegisteredWithDvla || !registrationChangeReason}
      />
    </>
  );
});
