import { Grid } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP_TYPE_PAGE, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../../../../service/util/customHooks/useDecodedLookups';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MTAFooter } from '../../common/components/footer';
import { ReusableRadioPage } from '../../common/components/reusableRadioPage';
import { Usage } from '../../common/components/usage';
import { useStepperContext } from '../../common/utils/stepperContext';
import { CarOvernight } from '../components/carOvernight';
import { MultipleColumnsEditPage } from '../components/multipleColumnsEditPage';
import { useEditPage } from '../utils/useEditPage';

interface CarUsageProps {
  boxTitle?: string;
  cols?: LOOKUP_TYPE_PAGE[];
  description?: string;
  title?: string;
}

interface PagesProps {
  [key: string]: JSX.Element;
}

const CarUsage: React.FC<CarUsageProps> = observer(function CarUsage({
  boxTitle = 'MTAJourney.CarUsage.carUsage',
  title = 'MTAJourney.CarUsage.title',
  cols,
}) {
  const {
    mtaStore: {
      pendedMTA: { vehicle },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { setTitle, nextStep, editPage, setEditPage } = useStepperContext();

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ vehicle });
  const { vehicleColumns: columns, handleBack: handleBackDefault, handleSave } = useEditPage(cols);

  useEffect(() => {
    if (editPage === undefined) setTitle(t(title));
  }, [editPage]);

  const handleBack = () => {
    handleBackDefault();
    setTitle(t(title));
  };

  const handleContinue = () => {
    nextStep();
  };

  const EditComponent: PagesProps = {
    [LOOKUP_TYPE_PAGE.DAYTIME_LOCATION]: (
      <ReusableRadioPage
        id={LOOKUP_TYPE_PAGE.DAYTIME_LOCATION}
        handleBack={handleBack}
        handleSave={handleSave}
        radioTitle={t('makeChange.carDetails.daytimeLocation')}
        tooltipTitle={t('tooltips.changeYourCar.daytimeParking')}
      />
    ),
    [LOOKUP_TYPE_PAGE.REGISTERED_KEEPER]: (
      <ReusableRadioPage
        id={LOOKUP_TYPE_PAGE.REGISTERED_KEEPER}
        handleBack={handleBack}
        handleSave={handleSave}
        radioTitle={t('makeChange.carDetails.registeredKeeper')}
        tooltipTitle={t('tooltips.changeYourCar.registeredKeeper')}
      />
    ),
    [LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE]: <CarOvernight handleBack={handleBack} handleSave={handleSave} />,
    [LOOKUP_TYPE_PAGE.LEGAL_OWNER]: (
      <ReusableRadioPage
        id={LOOKUP_TYPE_PAGE.LEGAL_OWNER}
        handleBack={handleBack}
        handleSave={handleSave}
        radioTitle={t('makeChange.carDetails.legalOwner')}
        tooltipTitle={t('tooltips.changeYourCar.legalOwner')}
      />
    ),
    [LOOKUP_TYPE_PAGE.USAGE]: <Usage handleBack={handleBack} handleSave={handleSave} />,
    [LOOKUP_TYPE_PAGE.SOCIAL_MILEAGE]: <Usage handleBack={handleBack} handleSave={handleSave} />,
    [LOOKUP_TYPE_PAGE.BUSINESS_MILEAGE]: <Usage handleBack={handleBack} handleSave={handleSave} />,
  };

  const Pages: PagesProps = cols ? cols.reduce((a, c) => ({ ...a, [c]: EditComponent[c] }), {}) : EditComponent;

  return (
    <Grid container direction="column">
      {editPage ? (
        <>{Pages[editPage as LOOKUP_TYPE_PAGE]}</>
      ) : (
        isDecodedLookupsFetched && (
          <MultipleColumnsEditPage columns={columns} tableHeader={t(boxTitle)} handleEdit={setEditPage} />
        )
      )}
      {editPage === undefined && (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_CAR_USAGE}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_CAR_USAGE}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CAR_USAGE, handleContinue }}
        />
      )}
    </Grid>
  );
});

export const CarUsageChangeYourCar: React.FC = () => {
  return <CarUsage />;
};

export const CarUsageOtherChanges: React.FC = () => {
  const { eventTrack } = useSegment();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== ROUTE.MORE_CHANGES) eventTrack('Other changes screen viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  return (
    <CarUsage
      cols={[
        LOOKUP_TYPE_PAGE.LEGAL_OWNER,
        LOOKUP_TYPE_PAGE.DAYTIME_LOCATION,
        LOOKUP_TYPE_PAGE.REGISTERED_KEEPER,
        LOOKUP_TYPE_PAGE.OVERNIGHT_POSTCODE,
      ]}
      title="MTAJourney.ReviewChanges.otherChanges"
      description="MTAJourney.ReviewChanges.editYourDetails"
      boxTitle="MTAJourney.CarUsage.otherDetails"
    />
  );
};
