import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    gap: spacing(1),
  },
}));

export const EditMainDriver: React.FC<EditPageProps> = observer(function EditMainDriver({ handleBack }) {
  const {
    mtaStore: {
      pendedMTA: { drivers, setDrivers, driverFullName },
    },
  } = useStores();

  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const { setTitle } = useStepperContext();

  useEffect(() => {
    setTitle(t('MTAJourney.chooseMainDriver.title'));
  }, []);
  const defaultMainDriver = drivers.find((item) => item.mainDriver === true)?.uuid;

  const [mainDriver, setMainDriver] = useState(defaultMainDriver);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (!mainDriver) return;
    setDrivers(
      drivers.map((item) => {
        if (item.uuid === mainDriver) {
          return { ...item, mainDriver: true };
        }
        return { ...item, mainDriver: false };
      }),
    );
  };

  const handleSave = () => {
    handlePendedMta();
    handleBack();
  };

  const handleBackClick = () => {
    handleBack();
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <MtaTooltip description={t('MTAJourney.chooseMainDriver.description')} tooltipTitle={t('tooltips.mainDriver')} />
      <Grid item>
        <RadioGroup
          defaultValue={mainDriver}
          value={mainDriver}
          onChange={(event) => {
            setMainDriver(event.target.value);
          }}
        >
          {drivers.map((driver, idx) => (
            <FormControlLabel control={<Radio />} label={driverFullName(driver)} value={driver.uuid} key={idx} />
          ))}
        </RadioGroup>
        {!mainDriver && isSubmitting && <FormHelperText error>{t('validations.noDriver')}</FormHelperText>}
      </Grid>

      <EditFooter id="editMainDriver" validPage={!!mainDriver} handleSave={handleSave} handleBack={handleBackClick} />
    </Grid>
  );
});
