import { Grid, RegistrationPlate, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { DATE_TYPES, EDIT_STEPS, JOURNEY_TYPE, LOOKUP, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { formatDate } from '../../../../../../../../service/util/formatDate';
import { useStepperContext } from '../utils/stepperContext';

import { ReviewAllDivider, ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListChangeOfCar: React.FC<ReviewListType> = observer(function ReviewListChangeOfCar({ onEdit }) {
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
    mtaStore: { pendedMTA: vehicleMTA },
  } = useStores();

  const {
    vehicle: {
      vrm,
      estimatedValue,
      purchaseDate,
      trackingDeviceFitted,
      securityDeviceDeclared,
      carModificationsInd: hasModifications,
      modifications,
    },
  } = vehicleMTA;

  const isCarOwner = estimatedValue !== null && purchaseDate !== null;

  const {
    interfaceStore: { isDesktop },
    mtaStore: { isJourneyCompleted },
  } = useStores();
  const history = useHistory();

  const { setStep } = useStepperContext();
  /* istanbul ignore next */
  const onEditChangeReg = () => {
    setStep(1);
    history.push(ROUTE.CHANGE_YOUR_CAR);
  };

  const classes = useListStyles({ isDesktop });
  const isCarJourneyComplete = isJourneyCompleted(JOURNEY_TYPE.CHANGE_YOUR_CAR);
  const isModJourneyComplete = isJourneyCompleted(JOURNEY_TYPE.CAR_MODIFICATIONS);
  const showComponents = isCarJourneyComplete || isModJourneyComplete;

  if (showComponents) {
    return (
      <BlueTableContainer title={t('MTAJourney.reviewAll.carDetails')} className={classes.root}>
        <>
          {isCarJourneyComplete && (
            <>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.carDetails')}</Typography>
                  <Typography variant="h4">
                    {vehicleMTA.vehicleDetails}
                    <RegistrationPlate label={vrm} active={true} />
                  </Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEditChangeReg} onEditType={EDIT_STEPS.REGISTRATION} />
                </Grid>
              </Grid>
              <ReviewAllDivider />
            </>
          )}
          {isCarJourneyComplete && (
            <>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                {isCarOwner && (
                  <>
                    <Grid item>
                      <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.buyThisCar')}</Typography>
                      <Typography variant="h4">{formatDate(purchaseDate.toString(), DATE_TYPES.SHORT)}</Typography>
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.PURCHASE_DATE} />
                    </Grid>
                  </>
                )}
                {!isCarOwner && (
                  <>
                    <Grid item>
                      <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.ownThisCar')}</Typography>
                      <Typography variant="h4">{t('labels.no')}</Typography>
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.CAR_OWNER} />
                    </Grid>
                  </>
                )}
              </Grid>
              <ReviewAllDivider />
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.estimatedValue')}</Typography>
                  <Typography variant="h4">{t('core:price.pound', { value: estimatedValue })}</Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.ESTIMATED_VALUE} />
                </Grid>
              </Grid>
              <ReviewAllDivider />
            </>
          )}
          {(isCarJourneyComplete || isModJourneyComplete) && (
            <>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.carSecurityDevices')}</Typography>
                  <Typography variant="h4">{getLookup(LOOKUP.VEHICLE_SECURITY, securityDeviceDeclared)}</Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.SECURITY_DEVICE_DECLARED} />
                </Grid>
              </Grid>
              <ReviewAllDivider />
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.trackingDevices')}</Typography>
                  <Typography variant="h4">{trackingDeviceFitted ? t('labels.yes') : t('labels.no')}</Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.TRACKING_DEVICE_FITTED} />
                </Grid>
              </Grid>
              <ReviewAllDivider />

              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    {!hasModifications && (
                      <>
                        <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.carBeenModified')}</Typography>
                        <Typography variant="h4">{t('labels.no')}</Typography>
                      </>
                    )}
                    {hasModifications && (
                      <>
                        <Typography>{t('MTAJourney.ReviewChanges.reviewCarDetailsChanges.carBeenModified')}</Typography>
                        <Typography variant="h4">
                          {modifications.map((item) => {
                            return (
                              <Fragment key={item.modificationCode}>
                                {getLookup(LOOKUP.MODIFICATIONS, item.modificationCode)}
                                <br />
                              </Fragment>
                            );
                          })}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.MODIFICATIONS} />
                  </Grid>
                </Grid>
              </>
            </>
          )}
        </>
      </BlueTableContainer>
    );
  } else {
    return <></>;
  }
});
