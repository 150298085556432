import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .bold-item': {
      fontStyle: { ...theme.typography.body2 },
      fontWeight: 700,
    },
    marginTop: theme.spacing(1),
  },
}));

export const PaymentDisclaimer: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const providerLogos: string[] = t('MTAJourney.payment.providerLogos', { returnObjects: true });
  const classes = useStyles();

  return (
    <Grid classes={classes} container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="body2">{t('MTAJourney.payment.encryption')}</Typography>
      </Grid>
      <Grid container item justifyContent="space-between">
        {providerLogos.map((card, idx) => (
          <Grid item key={`provider_${idx}`}>
            <img alt={`${card} logo`} src={`./assets/images/${card}.svg`} />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <Trans i18nKey="MTAJourney.payment.disclaimer" t={t}>
            <span className="bold-item" />
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};
