import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicTooltip } from '../../../../../../../../component/common/basicTooltip';
import { IModification } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';
import { CarModifications } from '../components/carModifications';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .modifications-title': {
      flexWrap: 'nowrap',
      marginBottom: spacing(3),
    },
  },
}));

export const CarModifiedSelection: React.FC = observer(function CarModifiedSelection() {
  const { t } = useTranslation('myAccount');
  const {
    nextStep,
    setTitle,
    editMode,
    setStep,
    stepsData: { reviewStep },
  } = useStepperContext();
  const classes = useStyles();

  const {
    mtaStore: {
      pendedMTA: { setModifications, vehicle, setHasModifications },
    },
  } = useStores();
  const [state, setState] = useState<{ selections: IModification[] }>({ selections: [...vehicle.modifications] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { eventTrack } = useSegment();

  useEffect(() => {
    setTitle(t('MTAJourney.CarModified.modifications.title'));
    eventTrack('Modifications screen viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  const isValid = () => {
    return state.selections.length !== 0;
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      setModifications(state.selections);
      setHasModifications(true);
    }
  };
  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };

  const handleSave = () => {
    handlePendedMta();
    isValid() && setStep(reviewStep);
  };

  return (
    <Grid container item direction="column" className={classes.root}>
      <Grid item container className="modifications-title">
        <Typography variant="body1">{t('MTAJourney.CarModified.pleaseSelect')}</Typography>
        <BasicTooltip title={t('tooltips.changeYourCar.carModified')} />
      </Grid>
      <CarModifications isSubmitting={isSubmitting} options={state} setOptions={setState} />
      {editMode ? (
        <EditFooter
          id="carModifiedSelection"
          validPage={isValid()}
          handleSave={handleSave}
          handleBack={() => setStep(reviewStep)}
        />
      ) : (
        <MTAFooter primaryButton={{ handleContinue }} />
      )}
    </Grid>
  );
});
