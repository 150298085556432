import { Box, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PopupTooltip } from '../../../../../../component/common/popupTooltip';
import { useStores } from '../../../../../../service/state/store';

export interface RenewalCostCardProps {
  ddValue?: string;
  hasTooltip?: boolean;
  isPrimary?: boolean;
  showDD?: boolean;
  showSameInfo?: boolean;
  subText?: string;
  title: string;
  tooltipTitle?: string;
  value: string;
}

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .card': {
      '&__details': {
        '&-default': {
          backgroundColor: `${palette.secondary.light}20`,
          border: `2px solid ${palette.secondary.light}`,
          borderRadius: spacing(1),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: spacing(0.75, 1),
        },
        '&-primary': {
          backgroundColor: `${palette.primary.light}20`,
          border: `2px solid ${palette.primary.light}`,
          borderRadius: spacing(1),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: spacing(0.75, 1),
        },
        backgroundColor: palette.grey[100],
        border: `2px solid ${palette.grey[500]}`,
        borderRadius: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(0.5),
        justifyContent: 'space-between',
        minHeight: 115,
        opacity: '75%',
        padding: spacing(0.75, 1),
        [breakpoints.down('sm')]: {
          minHeight: 'auto',
        },
      },
      '&__monthly': {
        alignItems: 'end',
        display: 'flex',
        paddingLeft: spacing(0.5),
      },
      paddingTop: spacing(4),
    },
  },
}));

const RenewalCostCard: React.FC<RenewalCostCardProps> = observer(function RenewalCostCard({
  hasTooltip,
  isPrimary,
  title,
  value,
  ddValue,
  showDD,
  showSameInfo = true,
  tooltipTitle,
  subText,
}) {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { paymentPlan },
        },
      },
    },
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment' && hasFeatureFlag('BCCJM-9000');
  const primaryClass = isPrimary ? '-primary' : '-default';
  const cardDetailsClass = !hasTooltip ? primaryClass : '';

  return (
    <Grid container item wrap="nowrap" className={clsx('card', classes.root)} data-testid="cost-card">
      <Grid item className={`card__details${cardDetailsClass}`} xs={12}>
        <Box display="flex">
          <Typography variant="h3">{title}</Typography>
          {hasTooltip && (
            <PopupTooltip
              alignment="left"
              background="#EBF1FC"
              tooltipColor="secondary"
              placement="bottom-end"
              size="medium"
              title={
                tooltipTitle ??
                (isDDCustomer
                  ? t('policyManagement.yourRenewalQuote.previousCostValueTooltipTextDDCustomer')
                  : t('policyManagement.yourRenewalQuote.previousCostValueTooltipText'))
              }
            />
          )}
        </Box>
        <Grid container>
          <Typography variant="h1">{value}</Typography>
          {showDD && (
            <Grid className="card__monthly">
              <Typography variant="h4">{t('policyManagement.yourRenewalQuote.monthly')}</Typography>
            </Grid>
          )}
        </Grid>
        {showDD && (
          <Typography variant="h4">
            {t('policyManagement.yourRenewalQuote.totalCosts', {
              value: ddValue,
            })}
          </Typography>
        )}
        {showSameInfo ? (
          <Typography variant="body2">{t('policyManagement.yourRenewalQuote.info')}</Typography>
        ) : (
          <Typography variant="body2">{subText}</Typography>
        )}
      </Grid>
    </Grid>
  );
});
export default memo(RenewalCostCard);
