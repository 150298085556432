import { Grid, RegistrationPlate, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { EDIT_STEPS, JOURNEY_TYPE, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useStepperContext } from '../utils/stepperContext';

import { ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListChangeOfRegistration: React.FC<ReviewListType> = observer(
  function ReviewListChangeOfRegistration() {
    const { t } = useTranslation('myAccount');

    const {
      interfaceStore: { isDesktop },
      mtaStore: { pendedMTA: vehicleMTA },
      mtaStore: { isJourneyCompleted },
    } = useStores();
    const classes = useListStyles({ isDesktop });
    const history = useHistory();

    const {
      vehicle: { vrm },
    } = vehicleMTA;

    const { setStep } = useStepperContext();
    const onEdit = () => {
      setStep(1);
      history.push(ROUTE.CHANGE_YOUR_REG_NO);
    };

    return (
      <>
        {isJourneyCompleted(JOURNEY_TYPE.REGISTRATION_NUMBER) && (
          <>
            <BlueTableContainer title={t('MTAJourney.reviewAll.carDetails')} className={classes.root}>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('makeChange.carDetails.registration')}</Typography>
                  <Typography variant="h4">
                    <RegistrationPlate label={vrm} active={true} />
                  </Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.REGISTRATION} />
                </Grid>
              </Grid>
            </BlueTableContainer>
          </>
        )}
      </>
    );
  },
);
