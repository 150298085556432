import { Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeSecurityFeatures } from '../../../../../../../../component/common/changeSecurityFeatures';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .choice-container': {
      display: 'grid',
      gap: spacing(1),
      width: 'fit-content',
    },
    '& .description': {
      marginBottom: spacing(2),
    },
    '& .error-message': {
      color: palette.error.dark,
    },

    display: 'grid',
    gap: spacing(3),
  },
}));

export const SecurityFeaturesRenewal: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { setVehicle, vehicle },
    },
  } = useStores();
  const {
    nextStep,
    setTitle,
    stepsData: { journeyType },
  } = useRenewalStepperContext();

  const [trackingDeviceFitted, setTrackingDeviceFitted] = useState<boolean | null>(vehicle.trackingDeviceFitted);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityDeviceDeclaredCd, setSecurityDeviceDeclared] = useState(vehicle.securityDeviceDeclaredCd);
  const [options, setOptions] = useState<ILookupItem[] | undefined>();

  const classes = useStyles();

  useEffect(() => {
    setTitle(
      journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
        ? t('MTAJourney.SecurityFeatures.title')
        : t('MTAJourney.SecurityFeatures.updatedSecurityFeatures'),
    );
  }, []);

  const isValid = (): boolean => {
    return trackingDeviceFitted !== null && securityDeviceDeclaredCd !== '';
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    isValid() && setVehicle({ ...vehicle, securityDeviceDeclaredCd, trackingDeviceFitted });
  };

  const handleContinue = () => {
    handleSubmit();
    isValid() && nextStep();
  };

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.VEHICLE_SECURITY],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.VEHICLE_SECURITY] !== undefined) {
      setOptions(lookupsByName[LOOKUP.VEHICLE_SECURITY]);
    }
  }, [isLookupsFetched, lookupsByName]);

  return (
    <Grid container item xs direction="column" className={classes.root}>
      <ChangeSecurityFeatures
        securityDeviceDeclared={securityDeviceDeclaredCd}
        isSubmitting={isSubmitting}
        options={options}
        trackingDeviceFitted={trackingDeviceFitted}
        setSecurityDeviceDeclared={setSecurityDeviceDeclared}
        setTrackingDeviceFitted={setTrackingDeviceFitted}
      />
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_CHANGE_SECURITY_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_CHANGE_SECURITY_CANCEL}
        primaryButton={{
          buttonId: COMPONENT_ID.RENEWAL_CHANGE_SECURITY_SAVE,
          handleContinue,
        }}
      />
    </Grid>
  );
});
