import { DateTime as DateTimeLuxon } from 'luxon';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import SelfService from '../../../service/util/selfService';
import { Landing } from '../../../view/pages/landing';
import { ROUTE } from '../../constant';
import { useStores } from '../../state/store';

import { useRouteContext } from './routeContext';

interface ProtectedRouteProps {
  component: React.FC;
  exact: boolean;
  path: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, exact, path, ...rest }) => {
  const {
    userStore: {
      user: { userLoggedIn },
    },
    dashboardStore: {
      customer: { underwritingInvestigationBar },
      insurance: {
        auto: {
          policySelected: { lastCompletedMtaDate, expirationDate, paymentPlan },
        },
      },
    },
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  useRouteContext();

  const mtaRoutes = [
    ROUTE.MAKE_CHANGE,
    ROUTE.CHANGE_YOUR_CAR,
    ROUTE.CAR_DETAILS,
    ROUTE.CAR_MILEAGE_AND_USAGE,
    ROUTE.CAR_MODIFICATIONS,
    ROUTE.CHANGE_YOUR_REG_NO,
    ROUTE.OTHER_CHANGES,
    ROUTE.ADD_DRIVER,
    ROUTE.CHANGE_DRIVER_DETAILS,
    ROUTE.CHANGE_EMPLOYMENT_DETAILS,
    ROUTE.CHANGE_HOME_ADDRESS,
    ROUTE.DRIVERS,
    ROUTE.CHANGE_PERSONAL_DETAILS,
    ROUTE.REMOVE_DRIVER,
    ROUTE.YOUR_DETAILS,
  ];
  const renewalMTARoutes = [
    ROUTE.YOUR_COVER_DETAILS,
    ROUTE.YOUR_COVER_DETAILS_ADD_DRIVER,
    ROUTE.YOUR_COVER_DETAILS_CARS_AT_HOME,
    ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE,
    ROUTE.YOUR_COVER_DETAILS_CHANGE_CAR,
    ROUTE.YOUR_COVER_DETAILS_CHANGE_REG,
    ROUTE.YOUR_COVER_DETAILS_CHILDREN_AT_HOME,
    ROUTE.YOUR_COVER_DETAILS_DAYTIME_LOCATION,
    ROUTE.YOUR_COVER_DETAILS_DRIVER_LICENSE,
    ROUTE.YOUR_COVER_DETAILS_DRIVER_NAME,
    ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION,
    ROUTE.YOUR_COVER_DETAILS_DRIVER_RELATIONSHIP,
    ROUTE.YOUR_COVER_DETAILS_HOME_ADDRESS,
    ROUTE.YOUR_COVER_DETAILS_MARITAL_STATUS,
    ROUTE.YOUR_COVER_DETAILS_NAME,
    ROUTE.YOUR_COVER_DETAILS_OCCUPATION,
    ROUTE.YOUR_COVER_DETAILS_OVERNIGHT_LOCATION,
    ROUTE.YOUR_COVER_DETAILS_RELATIONSHIP_STATUS,
    ROUTE.YOUR_COVER_DETAILS_REMOVE_DRIVER,
    ROUTE.YOUR_COVER_DETAILS_RESIDENTIAL_STATUS,
    ROUTE.YOUR_COVER_DETAILS_YOUR_LICENSE,
  ];
  const Component = component;
  const todaysDate = DateTimeLuxon.fromISO(DateTimeLuxon.now().toISO());

  /* istanbul ignore next */
  const hasSameDateMta =
    lastCompletedMtaDate &&
    lastCompletedMtaDate.day === todaysDate.day &&
    lastCompletedMtaDate.month === todaysDate.month &&
    !hasFeatureFlag('BDILMM-2500');

  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={() => {
        if (userLoggedIn) {
          const dividedRoute = path.split('/');
          const isMTARoute = mtaRoutes.includes(path as ROUTE);
          const isRenewalMTARoute = renewalMTARoutes.includes(path as ROUTE);
          /* istanbul ignore next */
          switch (true) {
            case isMTARoute && underwritingInvestigationBar !== undefined:
            case isMTARoute && remainingDays <= 32 && remainingDays >= 0 && paymentPlan !== 'YearlyCardPayment':
              return <Redirect to={ROUTE.DASHBOARD} />;
            case dividedRoute[dividedRoute.length - 2] === 'car-details' && hasSameDateMta:
              return <Redirect to={ROUTE.CAR_DETAILS} />;
            case isRenewalMTARoute && !hasFeatureFlag('renewalMtaActive'):
              return <Redirect to={ROUTE.RENEWAL_QUOTE_DETAILS} />;
          }
          return <Component />;
        }
        return <Landing />;
      }}
    />
  );
};
