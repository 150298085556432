import { Instance, getSnapshot, types } from 'mobx-state-tree';

import { HEADER } from '../../constant';
import { Header } from '../../util/interfaceModels/interfaceModels';

import { DashboardStore, IDashboardStore } from './dashboard';
import { IInterfaceStore, InterfaceStore } from './interface';
import { MTAStore } from './mta';
import { IPaymentStore, PaymentStore } from './payment';
import { IRenewalChangeStore, RenewalChangeStore } from './renewalChange';
import { IUserStore, UserStore } from './user';

export const RootStore = types
  .model('RootStore', {
    dashboardStore: types.optional(DashboardStore, () => DashboardStore.create()),
    interfaceStore: types.optional(InterfaceStore, () => InterfaceStore.create()),
    mtaStore: types.optional(MTAStore, () => MTAStore.create()),
    paymentStore: types.optional(PaymentStore, () => PaymentStore.create()),
    renewalChangeStore: types.optional(RenewalChangeStore, () => RenewalChangeStore.create()),
    userStore: types.optional(UserStore, () => UserStore.create()),
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
  }))
  .views((self) => ({
    get authHeader(): Header {
      return { [HEADER.AUTH_KEY]: `Bearer ${self.interfaceStore.accessToken}` };
    },
    get brandHeaderEs(): Header {
      return { [HEADER.BRAND]: HEADER.ESBRAND };
    },
    get brandHeaderFa(): Header {
      return { [HEADER.BRAND]: HEADER.FABRAND };
    },
    get brandHeaderSw(): Header {
      return { [HEADER.BRAND]: HEADER.SWBRAND };
    },
    get customerHeader(): Header {
      return { [HEADER.CUSTOMER_KEY]: self.userStore.user.userId };
    },
  }))
  .actions((self) => ({
    clearAllStores() {
      self.dashboardStore.clearStore();
      self.userStore.clearStore();
      self.paymentStore.clearStore();
      self.interfaceStore.clearStore();
      self.mtaStore.clearStore();
      self.renewalChangeStore.clearStore();
    },
    getCustomerHeader(): Header {
      return { ...self.authHeader, ...self.customerHeader };
    },
    getDocumentsHeader(): Header {
      return {
        ...self.authHeader,
        ...self.customerHeader,
        Accept: 'application/*',
      };
    },
  }));

export interface IRootStore extends Instance<typeof RootStore> {
  dashboardStore: IDashboardStore;
  interfaceStore: IInterfaceStore;
  paymentStore: IPaymentStore;
  renewalChangeStore: IRenewalChangeStore;
  userStore: IUserStore;
}
