/* eslint-disable sort-keys-fix/sort-keys-fix */
import { CommonContainer, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import SelfService from '../../../../../../service/util/selfService';

interface PolicyBreakdownItem {
  bold?: boolean;
  name: string;
  value: number | string;
}

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    [breakpoints.up('sm')]: {
      marginTop: spacing(4),
    },
    '& .breakdown-container': {
      display: 'grid',
      gap: spacing(1),
    },
    '& .footer-container': {
      borderTop: `1px solid ${palette.divider}`,
      paddingTop: spacing(1),
      gap: spacing(1),
    },
  },
  listedItemsContainer: {
    '&:nth-child(3n)': {
      background: palette.grey[100],
    },
  },
}));
const ListedItemsContainer: React.FC<{ items: PolicyBreakdownItem[] }> = ({ items }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      {items.map(({ name, value, bold = false }) => {
        return (
          <Grid
            className={classes.listedItemsContainer}
            container
            data-testid={`${name}_id`}
            item
            justifyContent="space-between"
            key={name}
            wrap="nowrap"
          >
            <Typography variant={bold ? 'h4' : 'body1'}>{name}</Typography>
            <Typography variant={bold ? 'h4' : 'body1'}>
              {typeof value === 'string' ? value : SelfService.toCurrency(value)}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const PolicyBreakdown: React.FC = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const mainItems: PolicyBreakdownItem[] = [
    {
      name: 'Premium details & add-ons',
      value: 'Amount',
      bold: true,
    },
    {
      name: 'Your policy ',
      value: 593.84,
    },
    {
      name: 'Safety Pack ',
      value: 593.84,
    },
    {
      name: 'Breakdown cover-Level 1 Roadside',
      value: 30,
    },
    {
      name: 'Protected no claims discount',
      value: 59.34,
    },
    {
      name: 'Changes to premium',
      value: 13.1,
    },
  ];
  const footerItems: PolicyBreakdownItem[] = [
    {
      name: 'Total annual premium',
      value: 726.28,
      bold: true,
    },
    {
      name: 'Charge for credit',
      value: 113.11,
    },
  ];

  return (
    <Grid container>
      <Grid item xs className={classes.root}>
        <CommonContainer color="secondary" variant="outlined" className="breakdown-container">
          <Typography variant="h4">{t('policyBreakdown.title')}</Typography>
          <Grid>
            <ListedItemsContainer items={mainItems} />
            <Link component="button">{t('policyBreakdown.link')}</Link>
          </Grid>
          <Grid className="footer-container" container>
            <ListedItemsContainer items={footerItems} />
            <Grid container data-testid={`total-amount-id`} item justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <Typography variant="h4">{t('policyBreakdown.totalAmountPayable')}</Typography>
                <Typography variant="body2">{t('policyBreakdown.totalAmountDescription')}</Typography>
              </Grid>
              <Typography variant="h4">{SelfService.toCurrency(839.39)}</Typography>
            </Grid>
          </Grid>
        </CommonContainer>
      </Grid>
      {/*<Grid item xs={isDesktop ? 4 : 12}>*/}
      {/*  <DDPremiumDetails type={DD_PREMIUM_DETAILS_TYPE.POLICY_BREAKDOWN} />*/}
      {/*</Grid>*/}
    </Grid>
  );
};
