import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const RemoveDriverRenewal: React.FC = observer(() => {
  const { setTitle, nextStep } = useRenewalStepperContext();
  const { t } = useTranslation('myAccount');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    renewalChangeStore: {
      renewalDetails: { drivers, removedDrivers, setRemovedDrivers },
    },
  } = useStores();

  const listOfRemovedD: string[] = toJS(removedDrivers);
  const [deletedDrivers, setDeletedDrivers] = useState(listOfRemovedD);

  useEffect(() => {
    setTitle(t('MTAJourney.removeDriver.title'));
  }, []);

  /* istanbul ignore next */
  const handleContinue = () => {
    setIsSubmitting(true);
    if (deletedDrivers.length > 0) {
      setRemovedDrivers(deletedDrivers);
      nextStep();
    }
  };

  /* istanbul ignore next */
  function handleCheckboxChange(idx: string) {
    if (deletedDrivers.includes(idx)) {
      const removeItem = deletedDrivers.filter((itm) => itm !== idx);
      setDeletedDrivers(removeItem);
    } else {
      setDeletedDrivers([...deletedDrivers, idx]);
    }
  }

  return (
    <>
      <Typography variant="body1">{t('MTAJourney.removeDriver.headline')}</Typography>
      <FormGroup>
        {drivers.map(
          (driver, idx) =>
            driver.relationshipToProposerCd !== 'P' && (
              <FormControlLabel
                onChange={() => handleCheckboxChange(driver.uuid)}
                control={<Checkbox checked={deletedDrivers.includes(driver.uuid)} />}
                key={idx}
                label={`${driver.person.firstName} ${driver.person.surname}`}
              />
            ),
        )}
        {!(deletedDrivers.length > 0) && isSubmitting && (
          <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
        )}
      </FormGroup>
      <RenewalChangeFooter primaryButton={{ handleContinue }} />
    </>
  );
});
