import { Divider, Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { EDIT_STEPS, JOURNEY_TYPE, LOOKUP, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import SelfService from '../../../../../../../../service/util/selfService';
import { useStepperContext } from '../utils/stepperContext';

import { ReviewAllDivider, ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListYourDetails: React.FC<ReviewListType> = observer(function ReviewListYourDetails({ onEdit }) {
  const { t } = useTranslation('myAccount');
  const {
    mtaStore: {
      pendedMTA: { insured, vehicle },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: { insured: insuredDetails, mainVehicle },
      },
    },
    interfaceStore: { isDesktop },
    mtaStore: { isJourneyCompleted },
  } = useStores();
  const classes = useListStyles({ isDesktop });
  const history = useHistory();
  const { setStep } = useStepperContext();

  const insuredFullName = `${getLookup(LOOKUP.TITLE, insured.person.titleCode)} ${insured.person.firstName} ${
    insured.person.surname
  }`;
  const { legalOwner, registeredKeeper } = vehicle;
  const showName =
    insured.person.surname !== insuredDetails.person.surname ||
    insured.person.titleCode !== insuredDetails.person.titleCode;

  const isLegalOwnerChanged = vehicle.legalOwner !== mainVehicle.legalOwner;
  const isRegisteredKeeperChanged = vehicle.registeredKeeper !== mainVehicle.registeredKeeper;
  const isMaritalStatusCodeChanged = insured.person.maritalStatusCode !== insuredDetails.person.maritalStatusCode;
  const isNumberOfChildrenChanged = insured.person.numberOfChildren !== insuredDetails.person.numberOfChildren;
  const isCarsInHouseholdChanged = insured.person.carsInHousehold !== insuredDetails.person.carsInHousehold;

  const onEditChangeAddress = () => {
    setStep(1);
    history.push(ROUTE.CHANGE_HOME_ADDRESS);
  };

  const showOtherChangesTable = isLegalOwnerChanged || isRegisteredKeeperChanged;

  const showPersonalDetailsChangesTable =
    showName || isMaritalStatusCodeChanged || isNumberOfChildrenChanged || isCarsInHouseholdChanged;

  return (
    <>
      {isJourneyCompleted(JOURNEY_TYPE.CHANGE_HOME_ADDRESS) && (
        <BlueTableContainer title={t('MTAJourney.reviewAll.addressDetails')} className={classes.root}>
          <>
            <Grid container className={classes.columnsContainer} justifyContent="space-between">
              <Grid item>
                <Typography>{t('MTAJourney.ReviewChanges.reviewHomeAddressChanges.newAddress')}</Typography>
                <Typography variant="h4">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: SelfService.formatAddress(insured.address, true).full,
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <ReviewAllEditButton onEdit={onEditChangeAddress} onEditType={EDIT_STEPS.ADDRESS} />
              </Grid>
            </Grid>
            <ReviewAllDivider />
            <Grid container className={classes.columnsContainer} justifyContent="space-between">
              <Grid item>
                <Typography>{t('makeChange.person.residencyStatus')}</Typography>
                <Typography variant="h4">
                  {getLookup(LOOKUP.RESIDENTIAL_STATUS, insured.person.residencyStatusCode)}
                </Typography>
              </Grid>
              <Grid item>
                <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.RESIDENTIAL_STATUS} />
              </Grid>
            </Grid>
          </>
        </BlueTableContainer>
      )}
      {showPersonalDetailsChangesTable && (
        <BlueTableContainer title={t('MTAJourney.reviewAll.personalDetails')} className={classes.root}>
          <>
            {showName && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewPersonalDetails.name')}</Typography>
                    <Typography variant="h4">{insuredFullName}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.FULL_NAME} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            {isMaritalStatusCodeChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewPersonalDetails.relationshipStatus')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.MARITAL_STATUS, insured.person.maritalStatusCode)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.MARITAL_STATUS} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            {isNumberOfChildrenChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewPersonalDetails.numberOfChildren')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.NUMBER_OF_CHILDREN, insured.person.numberOfChildren ?? '')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.NUMBER_OF_CHILDREN} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
              </>
            )}
            {isCarsInHouseholdChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.carsInHousehold.radioTitle')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.CARS_IN_HOUSEHOLD, insured.person.carsInHousehold)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.CARS_IN_HOUSEHOLD} />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </BlueTableContainer>
      )}
      {showOtherChangesTable && (
        <BlueTableContainer title={t('MTAJourney.reviewAll.otherDetails')} className={classes.root}>
          <>
            {isLegalOwnerChanged && (
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('makeChange.carDetails.legalOwner')}</Typography>
                    <Typography variant="h4">{getLookup(LOOKUP.OWNER, legalOwner)}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.LEGAL_OWNER} />
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
            {isRegisteredKeeperChanged && (
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('makeChange.carDetails.registeredKeeper')}</Typography>
                  <Typography variant="h4">{getLookup(LOOKUP.KEEPER, registeredKeeper)}</Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.KEEPER} />
                </Grid>
              </Grid>
            )}
          </>
        </BlueTableContainer>
      )}
    </>
  );
});
