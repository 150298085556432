import { Grid, RegistrationPlate, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES, FITTED, LOOKUP, NON_FITTED } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../service/util/customHooks/useDecodedLookups';
import { formatDate } from '../../../service/util/formatDate';
import { CarTooltipDetails } from '../carTooltipDetails';

const useStyles = makeStyles((theme) => ({
  borderTop: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(1, 0, 0, 0),
  },
  carTooltipContainer: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  leftAuto: {
    marginLeft: 'auto',
  },
  vehicleDetailsContainer: {
    padding: theme.spacing(4, 0, 1, 0),
  },
}));

export const CarDetailsDrawer: React.FC = observer(function CarDetailsDrawer() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount', {});
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: { mainVehicle },
      },
    },
  } = useStores();

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ vehicle: mainVehicle });

  const {
    daytimeLocation,
    estimatedValue,
    modifications,
    overnightLocation,
    purchaseDate,
    registeredKeeper,
    securityDeviceDeclared,
    trackingDeviceFitted,
  } = mainVehicle;

  const getModificationsList = () =>
    modifications.map((x) => getLookup(LOOKUP.MODIFICATIONS, x.modificationCode)).join(',\n');

  const carDetails = [
    {
      data: formatDate(purchaseDate, DATE_TYPES.LONG),
      label: 'carDetails.headers.purchaseDate',
    },
    {
      data: getLookup(LOOKUP.KEEPER, registeredKeeper),
      label: 'carDetails.headers.registeredKeeper',
    },
    {
      data: estimatedValue,
      label: 'carDetails.headers.estimatedValue',
    },
    {
      data: getLookup(LOOKUP.VEHICLE_SECURITY, securityDeviceDeclared || ''),
      label: 'carDetails.headers.carSecurity',
    },
    {
      data: trackingDeviceFitted ? FITTED : NON_FITTED,
      label: 'carDetails.headers.trackingDevices',
    },
    {
      data: getModificationsList() || NON_FITTED,
      label: 'carDetails.headers.carModifications',
    },
    {
      data: getLookup(LOOKUP.DAYTIME_LOCATION, daytimeLocation),
      label: 'carDetails.headers.daytimeParking',
    },
    {
      data: getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation),
      label: 'carDetails.headers.overnightParking',
    },
  ];

  return (
    <>
      <Grid className={classes.vehicleDetailsContainer} container>
        <Grid item xs={8}>
          <Typography variant="h3">{`${mainVehicle.make} ${mainVehicle.model}`}</Typography>
        </Grid>
        <Grid alignItems="flex-start" container item justify-content="flex-end" xs={4}>
          <RegistrationPlate active={true} label={mainVehicle.vrm} />
        </Grid>

        {
          <Typography className={classes.carTooltipContainer} variant="caption">
            <CarTooltipDetails vehicle={mainVehicle} />
          </Typography>
        }
      </Grid>

      {isDecodedLookupsFetched &&
        carDetails.map(({ data, label }, index) => (
          <Grid className={classes.borderTop} key={index}>
            <Typography variant="h5">{t(label)}</Typography>
            <Typography variant="body1">{data}</Typography>
          </Grid>
        ))}
    </>
  );
});
