import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

interface DriverType {
  uuid: string;
}

export const SelectChangeDrivers: React.FC = observer(() => {
  const { setTitle, nextStep, step, setStep } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    mtaStore: {
      pendedMTA: { setChangedDrivers, changedDrivers, removedDrivers, setSelectedDriverUUID },
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { drivers },
        },
      },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const listOfChangedDriversUuid: string[] = toJS(changedDrivers);
  const [changedDriversLocalState, setChangedDriversLocalState] = useState(listOfChangedDriversUuid);

  useEffect(() => {
    setTitle(t('MTAJourney.changeDriverDetails.title'));
    eventTrack('Which driver details have changed viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  const handleContinue = () => {
    /*we need to ensure that we will always have the same order of selected drivers, otherwise switching between back and continue will break
     the next page of editing drivers */
    const sortedSelectedDrivers = drivers
      .filter((driver) => changedDriversLocalState.includes(driver.uuid))
      .map((driver) => driver.uuid);

    setIsSubmitting(true);
    if (changedDriversLocalState.length > 0) {
      setChangedDrivers(sortedSelectedDrivers);
      const updatedDrivers = toJS(drivers).filter((driver: IDriver) => changedDriversLocalState.includes(driver.uuid));
      if (updatedDrivers.length) {
        setSelectedDriverUUID(updatedDrivers[0].uuid);
      }
      nextStep();
    }
  };

  const handleCheckboxChange = (uuidFromCheckbox: string) => {
    if (changedDriversLocalState.includes(uuidFromCheckbox)) {
      //if driver uuid exists in the list, we want to uncheck it by filtering it out from the list
      const checkedDrivers = changedDriversLocalState.filter((uuid) => uuid !== uuidFromCheckbox);
      setChangedDriversLocalState(checkedDrivers);
    } else {
      setChangedDriversLocalState([...changedDriversLocalState, uuidFromCheckbox]);
    }
  };

  const isDriverRemoved = (uuid: string) => {
    const foundIndex = removedDrivers.findIndex((removedUuid: string) => uuid === removedUuid);
    return foundIndex > -1 ? true : false;
  };

  const isDrivernoMainDriver = (uuid: string) => {
    const foundIndex = drivers.findIndex((driver: DriverType) => uuid === driver.uuid);
    if (drivers[foundIndex].relationshipToProposerCode !== 'P') {
      return true;
    } else {
      return false;
    }
  };

  const driverList = drivers.filter(
    (driver: DriverType) => !isDriverRemoved(driver.uuid) && isDrivernoMainDriver(driver.uuid),
  );

  return (
    <>
      <Typography variant="body1">
        {driverList.length === 0 && <>{t('MTAJourney.changeDriverDetails.noDrivers')}</>}
        {driverList.length > 0 && <>{t('MTAJourney.removeDriver.headline')}</>}
      </Typography>
      <FormGroup>
        {driverList.map((driver, idx) => (
          <FormControlLabel
            onChange={() => handleCheckboxChange(driver.uuid)}
            control={<Checkbox checked={changedDriversLocalState.includes(driver.uuid)} />}
            key={idx}
            label={`${driver.person.firstName} ${driver.person.surname}`}
          />
        ))}
      </FormGroup>
      {driverList.length > 0 && changedDriversLocalState.length === 0 && isSubmitting && (
        <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
      )}
      <MTAFooter
        backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_DRIVER_CHANGED}
        cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_DRIVER_CHANGED}
        primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_DRIVER_CHANGED, handleContinue }}
        handleBack={() => setStep(step - 1)}
      />
    </>
  );
});
