import { types } from 'mobx-state-tree';

import { Driver } from '../dashboard/autoPolicyModel';

const numberOptional = types.optional(types.number, 0);
const stringOptional = types.optional(types.string, '');

export const DriversExcesses = types.model('DriversExcesses', {
  compulsoryExcesses: numberOptional,
  driverSpecificExcesses: numberOptional,
  uuid: stringOptional,
  voluntaryExcessCode: stringOptional,
});

export const Excess = types.model('Excess', {
  coverageCode: stringOptional,
  excess: numberOptional,
});

const PaymentPlan = types.model('PaymentPlan', {
  chargeForCredit: numberOptional,
  currentAnualPremium: numberOptional,
  currentMonthlyInstallment: numberOptional,
  newMonthlyInstallment: numberOptional,
});

const VehicleDetails = types.model('VehicleDetails', {
  make: stringOptional,
  model: stringOptional,
  vrm: stringOptional,
});

export const Quote = types.model('Quote', {
  additionalPremium: numberOptional,
  administrationFee: numberOptional,
  drivers: types.optional(types.array(Driver), () => []),
  driversExcesses: types.optional(types.array(DriversExcesses), () => []),
  otherExcesses: types.optional(types.array(Excess), () => []),
  ownRepairExcesses: numberOptional,
  paymentPlan: types.optional(PaymentPlan, () => PaymentPlan.create()),
  trackerRequiredInd: types.optional(types.boolean, false),
  vehicleDetails: types.optional(VehicleDetails, () => VehicleDetails.create()),
});
