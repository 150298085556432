import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../service/analytics';
import { DIALOG_TYPE, ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import SelfService from '../../../service/util/selfService';

import { ButtonOfSelectedPolicyProps, CommonButton } from './commonButton';

export const ViewPolicyBtn: React.FC<ButtonOfSelectedPolicyProps> = observer(function ViewPolicyBtn({
  policy,
  ...rest
}) {
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: {
        auto: { setSelectedPolicyNumber: setSelectedPolicyNumberAuto },
        home: { setSelectedPolicyNumber: setSelectedPolicyNumberHome },
        setSelectedPolicyType,
      },
    },
    interfaceStore: {
      dialog: { openDialog },
    },
  } = useStores();
  const { policyNumber, tiaPolicy } = policy;
  const { eventTrack } = useSegment();

  const onClickHandler = () => {
    switch (true) {
      case tiaPolicy:
        openDialog({ type: DIALOG_TYPE.TIA_POLICY });
        break;
      case SelfService.isHomePolicy(policy):
        setSelectedPolicyNumberHome(policyNumber);
        eventTrack('Home | View policy clicked', {
          policyNumber,
          policyStatus: policy.policyStatus,
          product: 'Home',
          productType: 'Core',
          type: TRACK_TYPE.CTA,
        });
        break;
      case !SelfService.isHomePolicy(policy):
        setSelectedPolicyNumberAuto(policyNumber);
        break;
    }
    setSelectedPolicyType(policy);
  };

  const getUrl = () => {
    switch (true) {
      case tiaPolicy:
        return undefined;
      default:
        return ROUTE.POLICY_DETAILS;
    }
  };

  return (
    <CommonButton
      // Put this before the rest spread operator, so that it can be overridden if required
      data-testid={COMPONENT_ID.VIEW_POLICY_BUTTON}
      {...rest}
      iconType="solid"
      onClick={onClickHandler}
      endIcon="arrow-right"
      text={t('dashboard.buttonTxtPolicy')}
      url={getUrl()}
    />
  );
});
