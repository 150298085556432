import { FormControlLabel, Grid, Radio, RadioGroup, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP } from '../../../service/constant';
import { ILookupItem } from '../../../service/state/models/dashboard/lookups';
import { MtaTooltip } from '../../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';
import { ChangeCarMileage } from '../changeCarMileage';

export const useUsageStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .MuiAutocomplete-clearIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiAutocomplete-inputRoot': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiFormLabel-root': {
      color: palette.common.black,
    },
    '& .mileage-container': {
      margin: spacing(3, 0),
    },
  },
}));

export interface ClassOfUseFormProps {
  businessMileageCode: string | null;
  classOfUse: string;
  getLookup: (lookupName: LOOKUP, lookupCode: string) => string;
  hasBusinessMileage: boolean;
  mileageOptions: string[] | undefined;
  setBusinessMileageCode: (value: React.SetStateAction<string | null>) => void;
  setClassOfUse: (classOfUse: string) => void;
  setSocialMileageCode: (value: React.SetStateAction<string | null>) => void;
  socialMileageCode: string | null;
  usageOptions: ILookupItem[] | undefined;
}

export const ClassOfUseForm: React.FC<ClassOfUseFormProps> = ({
  usageOptions,
  mileageOptions,
  classOfUse,
  setClassOfUse,
  socialMileageCode,
  setSocialMileageCode,
  businessMileageCode,
  setBusinessMileageCode,
  hasBusinessMileage,
  getLookup,
}) => {
  const classes = useUsageStyles();
  const { t } = useTranslation('myAccount');

  return (
    <>
      <MtaTooltip
        description={<Typography>{t('MTAJourney.CarUsage.useOfCar')}</Typography>}
        tooltipTitle={t('tooltips.changeYourCar.usage.useOfCar')}
      />
      <RadioGroup onChange={(event) => setClassOfUse(event.target.value)} value={classOfUse}>
        {usageOptions?.map((item, index) => (
          <FormControlLabel key={`${item.code}-${index}`} value={item.code} control={<Radio />} label={item.value} />
        ))}
      </RadioGroup>

      <Grid container direction="column" className={classes.root}>
        {mileageOptions !== undefined && mileageOptions.length > 0 && (
          <ChangeCarMileage
            getLookup={getLookup}
            helperText={t('validations.selectValue')}
            mileageCode={socialMileageCode}
            mileageOptions={mileageOptions}
            placeholder={t('placeholders.mileage')}
            setMileageCode={setSocialMileageCode}
            tooltipDescription={t('MTAJourney.CarUsage.estimatedAnnualSocialMileage')}
            tooltipTitle={t('tooltips.changeYourCar.usage.socialMileage')}
          />
        )}

        {hasBusinessMileage ? (
          mileageOptions !== undefined &&
          mileageOptions.length > 0 && (
            <ChangeCarMileage
              getLookup={getLookup}
              helperText={t('validations.selectValue')}
              mileageCode={businessMileageCode}
              mileageOptions={mileageOptions}
              placeholder={t('placeholders.mileage')}
              setMileageCode={setBusinessMileageCode}
              tooltipDescription={t('MTAJourney.CarUsage.estimatedAnnualBusinessMileage')}
              tooltipTitle={t('tooltips.changeYourCar.usage.businessMileage')}
            />
          )
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default ClassOfUseForm;
