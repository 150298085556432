import { useEffect, useState } from 'react';

import { LOOKUP } from '../../constant';
import { useStores } from '../../state/store';

interface LookupsProps {
  lookups?: LOOKUP[];
}
interface LookupsReturn {
  isLookupsFetched: boolean;
}

export const useLookups = ({ lookups }: LookupsProps): LookupsReturn => {
  const [isLookupsFetched, setIsLookupsFetched] = useState(false);

  const {
    dashboardStore: { fetchLookups },
  } = useStores();

  useEffect(() => {
    lookups?.map(async (lookup) => {
      await fetchLookups(lookup);
    });
    setIsLookupsFetched(true);
  }, []);

  return {
    isLookupsFetched,
  };
};
