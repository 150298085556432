import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { PolicyStatus } from '../../../../../../component/common/policyStatus';
import { NeedHelp } from '../../../../../../component/layout/needHelp';
import { SVGWrapper } from '../../../../../../images/SVGWrapper';
import { DATE_TYPES } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';
import { postCodeFormatter } from '../../../../../../service/util/stringUtils';
import { RenewalsDeclineAccordion } from '../components/renewalDeclineAccordion';
import { RenewalsCommonHeadings, useHeadingStyles } from '../components/renewalsCommonHeadings';
import { WhatsMeansList } from '../components/whatMeansList';

import { useHomeStyles } from './../../../../../../component/layout/policyCards/homePolicyCard';
import { AccordionList, useRenewalsDeclineStyles } from './../motor/motorRenewalsDecline';

export const useAddHomeStyles = makeStyles(() => ({
  newContainer: {
    alignSelf: 'flex-start',
  },
}));

export const HomeRenewalsDecline: React.FC = observer(() => {
  const classes = useRenewalsDeclineStyles();
  const titleStyle = useHeadingStyles();
  const homeStyles = useHomeStyles();
  const homeAddStyles = useAddHomeStyles();

  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        home: { policySelected },
        isHome,
      },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const { expirationDate, homeAddress, policyNumber } = policySelected;
  const expiryDate = formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT_SPACE);

  const accordionList: AccordionList[] = t('policyManagement.yourRenewal.homeAccordionsDecline', {
    returnObjects: true,
  });

  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;

  useEffect(() => {
    eventTrack('Home Rnwl | Decline page viewed', {
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  return (
    <Grid container direction="column">
      <BlueBanner title={t('policyManagement.yourRenewal.title')} />
      <Grid item xs={12} md={6}>
        <Grid container direction="column" className={clsx(classes.root)}>
          <RenewalsCommonHeadings expiryDate={expiryDate} policyNumber={policyNumber} />

          <Typography variant="h4">
            {homeAddress.houseName} {homeAddress.houseNumber} {homeAddress.street}
          </Typography>
          <Grid className={clsx(homeAddStyles.newContainer, homeStyles.homeContainer)}>
            <Grid className="info-plate">
              <Grid className="info-plate__postcode">
                <SVGWrapper alt="" src="postcodepin.svg" className="info-plate__postcode-pin" />
                <Typography variant="h5" className="info-plate__postcode-text">
                  {postCodeFormatter(homeAddress.postcode)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {remainingDays < 1 && remainingDays >= 0 && (
            <PolicyStatus
              className="policyStatus"
              label={t('policyManagement.yourRenewal.expiresToday')}
              status="expired"
            />
          )}
          {remainingDays >= 1 &&
            (remainingDays === 1 ? (
              <PolicyStatus
                className="policyStatus"
                label={t('policyManagement.yourRenewal.oneDay')}
                status="expired"
              />
            ) : (
              <PolicyStatus
                className="policyStatus"
                label={t('policyManagement.yourRenewal.moreThanOneDay', { value: remainingDays })}
                status="expired"
              />
            ))}
          <Typography className="body" variant="body1">
            {t('policyManagement.yourRenewal.unableToRenewText')}
          </Typography>

          <Typography className={titleStyle.sectionTitle} variant="h2">
            {t('policyManagement.yourRenewal.secondaryTitle')}
          </Typography>

          <WhatsMeansList expiryDate={expiryDate} isHomePolicy={isHome} />

          <Typography className="thankYouSection" variant="body1">
            {t(`policyManagement.yourRenewal.homeThankYouMessage`)}
          </Typography>

          {accordionList.map(({ value, label, hasButton, segmentStep }, index) => {
            return (
              <RenewalsDeclineAccordion
                key={label}
                expiryDate={expiryDate}
                hasButton={hasButton}
                label={label}
                segmentStep={segmentStep}
                value={value}
                index={index}
              />
            );
          })}
          <NeedHelp showClaimButton={false} />
        </Grid>
      </Grid>
    </Grid>
  );
});
