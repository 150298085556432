import { useLocation } from 'react-router-dom';

import { RENEWAL_CHATBOT_PAGES } from '../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../service/state/models/interface/chatBot';

export type CustomAttrT =
  | 'ssRenQuoteMainChat'
  | 'ssRenQuoteUWDeclined'
  | 'ssRenCardPayFail'
  | 'ssRenQuotePriceNeg'
  | 'ssRenQuoteCancel'
  | 'ssRenQuoteUpdatePol';

interface ReturnValues {
  contactFlowType: CONTACT_FLOW_TYPE;
  customContactAttributes: Record<string, string>;
}

export const useRenewalsChatBotAttributes: (customAttr: CustomAttrT) => ReturnValues | undefined = (customAttr) => {
  const location = useLocation();
  const dividedPath = location.pathname.split('/');
  const currentPage = dividedPath[dividedPath.length - 1];

  const isRenewalChatbotPage = Object.values(RENEWAL_CHATBOT_PAGES).some((element) => element === currentPage);
  if (isRenewalChatbotPage) {
    const customAttributes = {
      [customAttr]: 'true',
    };

    return {
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: customAttributes,
    };
  } else {
    return undefined;
  }
};
