import { CommonContainer, Grid, Typography, alpha, makeStyles } from '@esure-cloud/react-components';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CachedIcon from '@material-ui/icons/Cached';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { DATE_TYPES, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { formatDate } from '../../../../../../service/util/formatDate';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  accordionContainer: {
    gap: spacing(1),
    marginBottom: spacing(2),
  },
  boldItem: {
    fontWeight: 700,
  },
  date: {
    '&.issued': {
      backgroundColor: alpha(palette.success.main, 0.1),
    },
    '&.renewal': {
      backgroundColor: palette.warning.light,
    },
    borderRadius: '6px',
    marginLeft: spacing(1),
    padding: '2px 6px',
  },
  gridSpacing: {
    marginBottom: spacing(2),
  },
  monthly: {
    alignItems: 'end',
    display: 'flex',
    paddingLeft: spacing(0.5),
  },
  paddingTop: {
    paddingTop: spacing(1),
  },
  root: {
    [breakpoints.up('xs')]: {
      '& .column-no-title': {
        marginTop: 0,
        padding: 0,
      },
    },
  },
  title: { height: spacing(4), margin: spacing(2, 0, 1, 0) },
}));

export interface QuoteBoxProps {
  isDDCustomer: boolean;
  issued?: string;
  monthlyCost?: number;
  renewalDate?: string;
  route: ROUTE;
  totalCost?: number;
}

export const QuoteBox: React.FC<QuoteBoxProps> = observer(function QuoteBox({
  monthlyCost,
  isDDCustomer,
  issued,
  renewalDate,
  route,
  totalCost,
}) {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const savedType = totalCost && totalCost > 0 ? 'saved' : 'unsaved';
  const type = route === ROUTE.RENEWAL_QUOTE_DETAILS ? 'current' : savedType;
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyRenewalQuoteDetails, fetchingRenewalData },
        },
      },
    },
    renewalChangeStore: {
      renewalDetails: { addRenewalQuoteDetails },
      setChangeMade,
      setPendingMTAQuoteSelected,
    },
  } = useStores();

  const buttonId =
    route === ROUTE.YOUR_SAVED_QUOTE && policyRenewalQuoteDetails.pendingMTAQuote
      ? COMPONENT_ID.RENEWAL_YOUR_QUOTES_SAVED
      : COMPONENT_ID.RENEWAL_YOUR_QUOTES_CURRENT;

  const handleClick = (route: ROUTE) => {
    if (route === ROUTE.YOUR_SAVED_QUOTE && policyRenewalQuoteDetails.pendingMTAQuote) {
      if (policyRenewalQuoteDetails.pendingMTAQuote.currentYearTotalCost >= 1) {
        setPendingMTAQuoteSelected(true);
        setChangeMade(policyRenewalQuoteDetails.policyNumber, false);
        addRenewalQuoteDetails(policyRenewalQuoteDetails.pendingMTAQuote);
        history.push(route);
      } else {
        setPendingMTAQuoteSelected(true);
        addRenewalQuoteDetails(policyRenewalQuoteDetails.pendingMTAQuote);
        setChangeMade(policyRenewalQuoteDetails.policyNumber, true);
        history.push(ROUTE.YOUR_COVER_DETAILS);
      }
    } else {
      setChangeMade(policyRenewalQuoteDetails.policyNumber, false);
      setPendingMTAQuoteSelected(false);
      addRenewalQuoteDetails(policyRenewalQuoteDetails);
      history.push(route);
    }
  };

  return (
    <CommonContainer color="secondary" variant="outlined" className={classes.gridSpacing}>
      {fetchingRenewalData || typeof totalCost == 'undefined' || (totalCost === 0 && type === 'current') ? (
        <Grid container spacing={1}>
          <Skeleton animation="wave" width="55%" />
          <Skeleton animation="wave" width="46%" height="5rem" />
          <Skeleton animation="wave" width="60%" />
        </Grid>
      ) : (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h3">{t('policyManagement.yourRenewalQuotes.quoteBox.title')}</Typography>
          </Grid>
          <Grid item xs={6} container direction="column" alignContent="flex-end">
            <Typography variant="body2" className={clsx(classes.date, 'issued')}>
              {t('policyManagement.yourRenewalQuotes.quoteBox.issuedDate', { date: issued })}
            </Typography>
          </Grid>

          {!!totalCost && totalCost >= 1 && (
            <Grid container item xs={12}>
              <Typography variant="h1" color="secondary">
                {isDDCustomer
                  ? t('core:price.pound', {
                      value: monthlyCost,
                    })
                  : t('core:price.pound', {
                      value: totalCost,
                    })}
              </Typography>
              {isDDCustomer && (
                <Grid className={classes.monthly}>
                  <Typography variant="body2" data-testid="cost">
                    {t('policyManagement.yourRenewalQuotes.quoteBox.monthlyCost')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          {isDDCustomer && !!totalCost && totalCost >= 1 && (
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.boldItem}>
                {t('policyManagement.yourRenewalQuotes.quoteBox.totalCost', {
                  value: t('core:price.pound', {
                    value: totalCost,
                  }),
                })}
              </Typography>

              <Typography variant="body2" className={classes.paddingTop}>
                {t('policyManagement.yourRenewalQuotes.quoteBox.includesInterest')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1">{t(`policyManagement.yourRenewalQuotes.quoteBox.${type}.subText`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" display="inline">
              {t('policyManagement.yourRenewalQuotes.quoteBox.renewalDateTitle')}
            </Typography>
            <Typography className={clsx(classes.date, 'renewal')} variant="body2" display="inline">
              {t('policyManagement.yourRenewalQuotes.quoteBox.renewalDateValue', { date: renewalDate })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <CommonButton
              variant="outlined"
              data-testid={buttonId}
              color="inherit"
              text={t(`policyManagement.yourRenewalQuotes.quoteBox.${type}.button`)}
              onClick={() => handleClick(route)}
            />
          </Grid>
        </Grid>
      )}
    </CommonContainer>
  );
});

export const YourRenewalQuotes: React.FC = observer(function YourRenewalQuotes() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();

  const { paymentPlan, policyRenewalQuoteDetails } = policySelected;
  const { currentYearMonthlyCost, currentYearTotalCost, startDate, inceptionDate, pendingMTAQuote } =
    policyRenewalQuoteDetails;

  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.gridSpacing}>
        <BlueBanner title={t('policyManagement.yourRenewalQuotes.title')} route={ROUTE.POLICY_DETAILS} />

        <Grid container direction="row" spacing={1}>
          <Grid item>
            <CachedIcon className={classes.title} color="secondary" />
          </Grid>
          <Grid item xs>
            <Typography className={classes.title} variant="h2" color="secondary">
              {t('policyManagement.yourRenewalQuotes.currentQuoteTitle')}
            </Typography>
          </Grid>
        </Grid>

        <QuoteBox
          monthlyCost={currentYearMonthlyCost}
          totalCost={currentYearTotalCost}
          isDDCustomer={isDDCustomer}
          route={ROUTE.RENEWAL_QUOTE_DETAILS}
          renewalDate={startDate ? formatDate(startDate.toString(), DATE_TYPES.SHORT_SPACE) : undefined}
          issued={inceptionDate ? formatDate(inceptionDate.toString(), DATE_TYPES.SHORT_SPACE) : undefined}
        />

        <Grid container direction="row" spacing={1}>
          <Grid item>
            <BookmarkBorderIcon className={classes.title} color="secondary" />
          </Grid>
          <Grid item xs>
            <Typography className={classes.title} variant="h2" color="secondary">
              {t('policyManagement.yourRenewalQuotes.savedQuoteTitle')}
            </Typography>
          </Grid>
        </Grid>

        <QuoteBox
          monthlyCost={pendingMTAQuote?.currentYearMonthlyCost}
          totalCost={pendingMTAQuote?.currentYearTotalCost}
          isDDCustomer={isDDCustomer}
          route={ROUTE.YOUR_SAVED_QUOTE}
          renewalDate={startDate ? formatDate(startDate.toString(), DATE_TYPES.SHORT_SPACE) : undefined}
          issued={inceptionDate ? formatDate(inceptionDate.toString(), DATE_TYPES.SHORT_SPACE) : undefined}
        />
      </Grid>
    </Grid>
  );
});
