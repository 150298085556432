import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { track } from '../../../lib/optimizely';
import { DIALOG_TYPE, ROUTE } from '../../../service/constant';
import { DialogOptions } from '../../../service/state/models/interface/dialog';
import { useStores } from '../../../service/state/store';
import { useSegment } from '../../../service/util/customHooks/useSegment';

export const checkAndOpenDialog = (
  isThirdParty: boolean,
  openDialog: (options: DialogOptions) => void,
  title: string,
): void => {
  if (isThirdParty) {
    openDialog({
      title: title,
      type: DIALOG_TYPE.SPEAK_TO_SPECIALIST,
    });
  }
};

export const isTPFT = (isHome: boolean, coverType: string): boolean => {
  if (!isHome) {
    return coverType === 'TPFT';
  } else {
    return false;
  }
};

export function useDispatchMakeAClaim() {
  const { t } = useTranslation('myAccount');
  const location = useLocation();
  const { eventTrack } = useSegment();
  const isRoutePolicy = location.pathname === ROUTE.DASHBOARD;

  const history = useHistory();

  const {
    dashboardStore: {
      insurance: { selectedPolicyType, auto, isHome },
    },
    interfaceStore: {
      dialog: { openDialog },
    },
  } = useStores();

  const isThirdParty = () => isTPFT(isHome, auto.mainVehicle.coverType ?? '');

  const makeAClaim = (overrideUrl?: string) => {
    if (isRoutePolicy) {
      track('My_Account_make_a_claim_policies_page_click');
    }

    checkAndOpenDialog(isThirdParty(), openDialog, t('fetchAdviserPopup.speakToASpecialist'));

    selectedPolicyType === 'home'
      ? eventTrack('Home  | Make a claim clicked')
      : eventTrack('Make a Claim Button Clicked');

    if (!isThirdParty()) {
      history.push(overrideUrl ?? ROUTE.CLAIMS);
    }
  };

  return {
    dispatch: makeAClaim,
    isThirdParty,
  };
}
