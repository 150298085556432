import {
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { DIALOG_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { ChatWithUs } from '../../../../makeAchange/mta/common/components/chatWithUs';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .first-name': {
      marginBottom: spacing(2),
      marginTop: spacing(3),
    },
    '& .radio-container-gender': {
      '&__label': {
        minWidth: 'fit-content',
        width: '100%',
      },
      '&__typo': {
        marginBottom: spacing(1),
      },
    },
    maxWidth: 352,
  },
}));

export const ChangeDriverNameRenewal: React.FC<{ firstNameDisabled: boolean }> = observer(({ firstNameDisabled }) => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { selectedDriver, setSelectedDriver },
    },
  } = useStores();

  const { nextStep, setTitle } = useRenewalStepperContext();

  const [titleList, setTitleList] = useState<ILookupItem[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [personState, setPersonState] = useState(selectedDriver.person);

  const { firstName, surname, titleCd, genderCd } = personState;

  const classes = useStyles();
  const styleContainedSecondary = 'contained invert secondary';
  const { t } = useTranslation('myAccount');

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.TITLE],
  });

  useEffect(() => {
    setTitle(t('MTAJourney.driverName.changeDriverTitle'));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.TITLE] !== undefined) {
      setTitleList(lookupsByName[LOOKUP.TITLE]);
    }
  }, [isLookupsFetched, lookupsByName]);

  const isNotValid = (): boolean => {
    return firstName === '' || surname === '' || titleCd === '' || (titleCd === '001' && genderCd === null);
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);

    if (!isNotValid()) {
      setSelectedDriver({
        ...selectedDriver,
        person: { ...selectedDriver.person, firstName, genderCd, surname, titleCd },
      });
    }
  };

  const handleContinue = () => {
    handlePendedMta();
    if (!isNotValid()) {
      nextStep();
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography>{t('MTAJourney.driverName.titleLabel')}</Typography>
      <Select
        variant="outlined"
        value={titleList ? titleCd : ''}
        error={isSubmitting && !titleCd}
        onChange={(event) => {
          setPersonState({
            ...personState,
            titleCd: event.target.value as string,
          });
        }}
      >
        {titleList?.map((item, index) => {
          return (
            <MenuItem key={`${item.code}-${index}`} value={item.code}>
              {item.value}
            </MenuItem>
          );
        })}
      </Select>
      {isSubmitting && titleCd === '' && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}

      {titleCd === '001' && (
        <Grid container className={classes.root}>
          <Typography className="radio-container-gender__typo">{t('MTAJourney.driverName.genderSelection')}</Typography>
          <Grid item xs={12}>
            <RadioGroup
              onChange={(event) => setPersonState({ ...personState, genderCd: event.target.value })}
              value={genderCd}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={clsx('radio-container-gender__label', styleContainedSecondary, {
                      checked: genderCd === 'M',
                    })}
                    control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-male' }} />}
                    label={t('MTAJourney.driverName.genderMale')}
                    labelPlacement="end"
                    value="M"
                    classes={classes}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={clsx('radio-container-gender__label', styleContainedSecondary, {
                      checked: genderCd === 'F',
                    })}
                    control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-female' }} />}
                    label={t('MTAJourney.driverName.genderFemale')}
                    labelPlacement="end"
                    value="F"
                    classes={classes}
                  />
                </Grid>
              </Grid>
            </RadioGroup>

            {isSubmitting && genderCd === null && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}
          </Grid>
        </Grid>
      )}

      <TextField
        className="first-name"
        value={firstName}
        label={t('MTAJourney.driverName.firstName')}
        helperText={isSubmitting && firstName === '' && t('validations.missingFirstName')}
        error={isSubmitting && firstName === ''}
        onChange={(event) => setPersonState({ ...personState, firstName: event.target.value })}
        disabled={firstNameDisabled}
      />
      <TextField
        label={t('MTAJourney.driverName.lastName')}
        helperText={isSubmitting && surname === '' && t('validations.missingLastName')}
        error={isSubmitting && surname === ''}
        value={surname}
        onChange={(event) => setPersonState({ ...personState, surname: event.target.value })}
      />

      {firstNameDisabled && (
        <ChatWithUs
          description={t('changeYourDetails.personalDetails.assistance')}
          dialogType={DIALOG_TYPE.FETCH_ADVISER_CHANGE_FIRSTNAME}
          linkId={COMPONENT_ID.CHAT_WITH_US_BUTTON}
        />
      )}

      <RenewalChangeFooter
        cancelButtonId={COMPONENT_ID.RENEWAL_DRIVER_NAME_CANCEL}
        backButtonId={COMPONENT_ID.RENEWAL_DRIVER_NAME_BACK}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_DRIVER_NAME_CONTINUE, handleContinue }}
      />
    </Grid>
  );
});
