import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import CommonAccordion, { CommonAccordionProps } from '../../../../../../component/common/commonAccordion';
import IconSure from '../../../../../../component/common/icon';
import { ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { Lookups, getDetailsOfSpecificCode } from '../../../../../../service/util/lookup';
import { MotorRenewalNotification } from '../../renewals/components/motorRenewalNotification';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  drivingOtherCarsContainer: {
    '& .header': {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      '&__info': {
        '&-container': {
          paddingRight: 35,
        },
        '&-typo': {
          paddingLeft: 38,
        },
      },
    },
    '& .icon': {
      marginRight: theme.spacing(2),
    },
  },
  expireNoticeContainer: {
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-1, -2, 0),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

export const TypesOfUse: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const [open, setOpen] = useState<number | null>(null);

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected,
          mainVehicle: { classOfUse },
          policyCover: { drivingOtherCars },
        },
      },
    },
    interfaceStore: { isDesktop },
  } = useStores();
  const lookups: Lookups = t('lookups', { returnObjects: true });

  const accordions: (CommonAccordionProps | boolean)[] = [
    {
      columns: [
        {
          list: getDetailsOfSpecificCode(lookups, 'usage', classOfUse)?.included,
          subtitle: getDetailsOfSpecificCode(lookups, 'usage', classOfUse)?.subtitle,
          title: t('typesOfUse.howYouUseYourCar.thisIncludes'),
        },
        {
          icon: 'times',
          list: getDetailsOfSpecificCode(lookups, 'usage', classOfUse)?.notIncluded,
          title: t('typesOfUse.howYouUseYourCar.notIncluded'),
        },
      ],
      headerIcon: 'use-of-car',
      headerInfo: getDetailsOfSpecificCode(lookups, 'usage', classOfUse)?.title,
      headerTitle: t('typesOfUse.howYouUseYourCar.title'),
    },
    !!drivingOtherCars && {
      columns: [
        {
          list: t('typesOfUse.drivingOtherCars.apply.items', { returnObjects: true }),
          title: t('typesOfUse.drivingOtherCars.apply.title'),
        },
        {
          icon: 'times',
          list: t('typesOfUse.drivingOtherCars.notApply.items', { returnObjects: true }),
          title: t('typesOfUse.drivingOtherCars.notApply.title'),
        },
      ],
      headerIcon: 'drive-other-cars',
      headerInfo: t('typesOfUse.drivingOtherCars.yes'),
      headerTitle: t('typesOfUse.drivingOtherCars.title'),
      summary: t('typesOfUse.drivingOtherCars.summary'),
    },
    {
      columns: [
        {
          subtitle: t('typesOfUse.whereCanIDrive.summary'),
        },
        {
          icon: 'circle',
          list: t('typesOfUse.whereCanIDrive.conditionsList', { returnObjects: true }),
          title: t('typesOfUse.whereCanIDrive.conditions'),
        },
      ],
      headerIcon: 'location',
      headerInfo: t('typesOfUse.whereCanIDrive.description'),
      headerTitle: t('typesOfUse.whereCanIDrive.title'),
    },
  ];

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('breadcrumbs.typesOfUse')} route={ROUTE.POLICY_DETAILS} />
      {policySelected.futureRenewalEffectiveDate === null && (
        <Grid item xs={12} className={classes.expireNoticeContainer}>
          <MotorRenewalNotification policy={policySelected} />
        </Grid>
      )}
      <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
        {accordions
          .filter((item) => item)
          .map((props, index) => (
            <CommonAccordion
              key={Symbol(index).toString()}
              onChange={() => setOpen(open === index ? null : index)}
              expanded={open === index}
              {...(props as CommonAccordionProps)}
            />
          ))}
        {drivingOtherCars === undefined && (
          <CommonContainer color="secondary" className={classes.drivingOtherCarsContainer}>
            <Grid container direction="row" wrap="nowrap" className="header">
              <Grid item container xs={isDesktop ? 4 : 12}>
                <IconSure icon="drive-other-cars" color="secondary" className="icon" size="26"></IconSure>
                <Typography variant="h4">{t('typesOfUse.drivingOtherCars.title')} </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
                xs={isDesktop ? 8 : 12}
                className="header__info-container"
              >
                <Typography className={!isDesktop ? 'header__info-typo' : ''}>
                  {t('typesOfUse.drivingOtherCars.no')}
                </Typography>
              </Grid>
            </Grid>
          </CommonContainer>
        )}
      </Grid>
    </Grid>
  );
});
