import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../service/state/store';

import { MTAMotorRenewalQuote } from './mtaMotorRenewalQuote';
import { OriginalMotorRenewalQuote } from './originalMotorRenewalQuote';

export interface AccordionRow {
  hasButton: boolean;
  label: string;
  segmentStep: string;
  value: string;
}

export const MotorRenewalQuote: React.FC = observer(function MotorRenewalQuote() {
  const {
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  return hasFeatureFlag('renewalMtaActive') ? <MTAMotorRenewalQuote /> : <OriginalMotorRenewalQuote />;
});
