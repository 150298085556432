import { Typography, makeStyles } from '@esure-cloud/react-components';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.main}14`,
    borderRadius: '50%',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    width: '32px',
  },
}));
export interface NumberIconProps {
  number?: number;
}
export const DriverCounter: React.FC<NumberIconProps> = ({ number = 0 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5">
        {number}
      </Typography>
    </div>
  );
};
