import {
  CommonContainer,
  CommonContainerProps,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID, getDeviceSession } from '../../service/analytics';
import { DATE_TYPES, LOOKUP, PANEL_TYPE, ROUTE, THEME_NAME } from '../../service/constant';
import { IAutoPolicy } from '../../service/state/models/dashboard/autoPolicyModel';
import { ICustomerBar } from '../../service/state/models/dashboard/customer';
import { IHomePolicy } from '../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../service/state/store';
import { useDecodedLookupsMotor } from '../../service/util/customHooks/useDecodedLookups';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';
import { formatDate } from '../../service/util/formatDate';
import SelfService from '../../service/util/selfService';
import { capitalizeWords } from '../../service/util/stringUtils';
import { MakeAChangeBtn } from '../common/button/makeAChangeBtn';
import { MakeAClaimBtn } from '../common/button/makeAClaimBtn';
import IconSure, { IconType } from '../common/icon';
import { PolicyStatus } from '../common/policyStatus';

import { DoTodayCTASection } from './DoTodayCTASection';
import { useCommonStylesPolicyCard } from './policyCards';
import { PolicyDetailsNavCards } from './policyDetailsNavCards';

export const policyDetailUseStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  carInfo: {
    textAlign: 'start',
  },
  cardButton: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cards: {
    height: '100%',
  },
  expiredCard: {
    background: theme.palette.background.subtle,
    borderColor: `${theme.palette.common.black}1F !important`,
  },

  gridContainer: {
    '& a': {
      textDecoration: 'none',
    },
    paddingTop: theme.spacing(5),
  },

  paymentsContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(0.5),
  },
  policyNumber: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  renewalStatusPending: {
    '& h5': {
      fontWeight: 'normal',
    },
    background: theme.palette.warning.light,
    borderRadius: theme.spacing(1),
    color: theme.palette.warning.contrastText,
    height: 26,
    maxWidth: 'fit-content',
    padding: theme.spacing(0, 1),
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    paddingBottom: theme.spacing(2),
  },
  statusLabel: {
    backgroundColor: theme.palette.error.main,
    borderRadius: '6px',
    color: theme.palette.common.white,
    display: 'inline-block',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.25, 0.75, 0.25, 0.75),
  },
}));

export interface ItemList {
  event: string;
  icon: string;
  iconType: IconType;
  route: string;
  subtitle: string;
  title: string;
}

export const checkColour = (themeName: string): CommonContainerProps['color'] =>
  themeName === THEME_NAME.SHEILASWHEELS ? 'secondary' : 'primary';

export const PolicyInfo: React.FC<{
  policyNo: string;
  t: TFunction<'myAccount'>;
}> = ({ policyNo, t }) => {
  const classes = policyDetailUseStyles();

  return (
    <div id="policySummaryAnchor">
      <Typography className={classes.sectionTitle} variant="h2">
        {t('policyDetailsSection.title')}
      </Typography>
      <Grid container direction="row">
        <Typography variant="body1">{t('policyDetails.policyNo')}</Typography>
        <Typography className={classes.policyNumber} variant="body1">
          {policyNo}
        </Typography>
      </Grid>
    </div>
  );
};

export const CoverInfo: React.FC<{ coverType: string; endDate: string; policyStatus: string; startDate: string }> = ({
  startDate,
  endDate,
  coverType,
  policyStatus,
}) => {
  const { t } = useTranslation('myAccount');
  const policyCard = useCommonStylesPolicyCard();
  const classes = policyDetailUseStyles();
  const isPolicyExpired = SelfService.isPolicyExpired(policyStatus);

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <CommonContainer
          className={clsx(classes.cards, policyCard.root, isPolicyExpired && policyStatus)}
          color="secondary"
          variant="outlined"
        >
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={4}>
              <Typography variant="body2">{t('policyDetailsSection.coverStarts')}</Typography>
              <Typography variant="h4">{startDate}</Typography>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="flex-end">
                <Typography variant="body2">{t('policyDetailsSection.coverEnds')}</Typography>
                <Typography variant="h4">{endDate}</Typography>
              </Grid>
            </Grid>
            {/* TODO: Uncomment when need to be implemented */}
            {/* {isPolicyExpired ? (
              <Grid item xs={12}>
                <Grid item className={classes.statusLabel}>
                  <Typography variant="caption">{t('policyDetailsSection.endedOn', { value: endDate })}</Typography>
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </CommonContainer>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CommonContainer
          className={clsx(classes.cards, policyCard.root, isPolicyExpired && policyStatus)}
          color="secondary"
          variant="outlined"
        >
          <Typography variant="body2">{t('policyDetails.typeOfCover')}</Typography>
          <Typography variant="h4">{coverType}</Typography>
        </CommonContainer>
      </Grid>
    </>
  );
};

export const PolicyButtons: React.FC<{
  isCustomerBarred: ICustomerBar | undefined;
  policy: IHomePolicy | IAutoPolicy;
}> = ({ policy, isCustomerBarred }) => {
  const classes = policyDetailUseStyles();
  const isPolicyExpired = SelfService.isPolicyExpired(policy.policyStatus);

  return (
    <Grid className={classes.buttons} container spacing={2}>
      <Grid item xs={6} lg={2}>
        <MakeAClaimBtn xl size="large" variant="outlined" color="secondary" />
      </Grid>
      {isCustomerBarred === undefined && !isPolicyExpired && (
        <Grid item xs={6} lg={2}>
          <MakeAChangeBtn xl size="large" variant="contained" color="primary" contrast policy={policy} />
        </Grid>
      )}
    </Grid>
  );
};

export const PolicyRenewalInfo: React.FC<{
  autoRenewal: boolean | null;
  onClick?: () => void;
  policy: IHomePolicy | IAutoPolicy;
  t: TFunction<'myAccount'>;
}> = observer(({ onClick, autoRenewal, t, policy }) => {
  const classes = policyDetailUseStyles();
  const policyCard = useCommonStylesPolicyCard();
  const isPolicyExpired = SelfService.isPolicyExpired(policy.policyStatus);

  const {
    dashboardStore: {
      insurance: {
        selectedPolicyType,
        auto: {
          policySelected: { renewalStatusRequestPending: autoRenewalStatusRequestPending },
        },
        home: {
          policySelected: { renewalStatusRequestPending: homeRenewalStatusRequestPending },
        },
      },
    },
    interfaceStore: { isLoading },
  } = useStores();
  const { eventTrack } = useSegment();
  const { policyStatus } = policy;
  const onClickHandler = () => {
    if (isLoading) return;

    onClick?.();

    if (selectedPolicyType === 'home') {
      eventTrack('Home | Manage renewal clicked', { type: TRACK_TYPE.VIEW });
    }
  };

  const showRenewalStatusRequestPending = () => {
    if (selectedPolicyType === 'home') {
      return homeRenewalStatusRequestPending;
    }
    if (selectedPolicyType === 'auto') {
      return autoRenewalStatusRequestPending;
    }

    return false;
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <CommonContainer
        className={clsx(classes.cards, policyCard.root, isPolicyExpired ? policyStatus : '')}
        color="secondary"
        variant="outlined"
        onClick={onClickHandler}
      >
        <Grid className={classes.cardButton} data-testid="renewal-card">
          <Grid container justifyContent="space-between">
            <Typography variant="body2">{t('policyDetails.autoRenewal')}</Typography>
            <Grid item>
              <IconSure color="secondary" icon="chevron-right" />
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="h4" data-testid="ar-status">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={35}
                  style={{ fontSize: '1.5rem' }}
                  height={40}
                  data-testid="ar-is-loading"
                />
              ) : autoRenewal ? (
                t('policyDetails.autoRenewalOn')
              ) : (
                t('policyDetails.autoRenewalOff')
              )}
            </Typography>
            {showRenewalStatusRequestPending() && (
              <Grid
                alignItems="center"
                className={classes.renewalStatusPending}
                container
                item
                justifyContent="center"
                data-testid="ar-pending"
              >
                <Typography align="center" variant="h5">
                  {t('policyDetails.autoRenewalSwitchOffInProgress')}
                </Typography>
              </Grid>
            )}
            {isLoading && <Skeleton variant="text" width="50%" height={20} />}
            {!isPolicyExpired && !isLoading && (
              <Typography variant="body1" color="secondary">
                {t('policyDetails.manageRenewal')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CommonContainer>
    </Grid>
  );
});

export const cardsString = (coverType: string | null): string => {
  if (coverType === 'TPFT') {
    return 'policyDetailsSection.motorCardsTPFT';
  } else {
    return 'policyDetailsSection.motorCards';
  }
};

export const MotorPolicyDetailsSection: React.FC = observer(function MotorPolicyDetailsSection() {
  const { t } = useTranslation('myAccount');
  const classes = policyDetailUseStyles();
  const {
    dashboardStore: {
      customer: { underwritingInvestigationBar },
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          mainVehicle,
          policySelected,
          policyCover: { ncdpPack },
        },
      },
    },
    interfaceStore: {
      panel: { openPanel },
      hasFeatureFlag,
    },
  } = useStores();
  const isPolicyExpired = SelfService.isPolicyExpired(policySelected.policyStatus);
  const { autoRenewal, policyNumber, effectiveDate, expirationDate, paymentPlan, totalPremium } = policySelected;

  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';
  const history = useHistory();
  const motorPolicyCards: ItemList[] = t(cardsString(mainVehicle.coverType), { returnObjects: true });
  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ options: ['vehicle'] });

  const { eventTrack } = useSegment();

  let cards: ItemList[];
  if (isPolicyExpired) {
    cards = motorPolicyCards.filter((card) => card.title === 'Documents');
  } else {
    cards = motorPolicyCards;
  }

  const handleOpenCarDetails = () => {
    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.DRIVER_DETAILS_CARD,
      label: t('drawer.carDetails'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });

    openPanel({ title: t('drawer.carDetails'), type: PANEL_TYPE.CAR_DETAILS });
  };

  const handleNoClaimsDiscount = () => {
    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.NO_CLAIMS_DISCOUNT_CARD,
      label: t('drawer.ncd'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });

    openPanel({ title: t('drawer.ncd'), type: PANEL_TYPE.NCD });
  };

  const handlePolicyRenewal = () => {
    eventTrack('Card Clicked', {
      componentID: COMPONENT_ID.RENEWAL_CARD,
      label: t('policyDetails.autoRenewal'),
      sessionId: getDeviceSession(),
      url: window.location.href,
    });

    if (policySelected.renewalStatusRequestPending) {
      history.push(ROUTE.RENEWAL_CHANGE_PENDING);
    } else {
      history.push(ROUTE.YOUR_RENEWAL_DETAILS);
    }
  };

  return (
    <>
      {hasFeatureFlag('showDoTodayCTA') && !isPolicyExpired && <DoTodayCTASection policy={policySelected} />}
      <Grid className={classes.gridContainer} container>
        <PolicyInfo policyNo={policyNumber} t={t} />

        <Grid container spacing={2}>
          {isDecodedLookupsFetched && (
            <CoverInfo
              startDate={formatDate(effectiveDate?.toString(), DATE_TYPES.SHORT_SPACE)}
              endDate={formatDate(
                SelfService.calculatePolicyEndDate(expirationDate).toString(),
                DATE_TYPES.SHORT_SPACE,
              )}
              coverType={getLookup(LOOKUP.COVER_TYPE, mainVehicle.coverType ?? '')}
              policyStatus={policySelected.policyStatus}
            />
          )}

          <Grid item xs={12} sm={6} lg={4}>
            <CommonContainer
              className={clsx(classes.cards, isPolicyExpired && classes.expiredCard)}
              color="secondary"
              variant="outlined"
              onClick={handleOpenCarDetails}
            >
              <Grid className={classes.cardButton} data-testid="car-details-card">
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t('policyDetailsSection.car')}</Typography>
                  <Grid item>
                    <IconSure color="secondary" icon="chevron-right" />
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="flex-start">
                  <Typography variant="h4">{capitalizeWords(`${mainVehicle.make} ${mainVehicle.model}`)}</Typography>
                  <Typography variant="h4">{mainVehicle.vrm}</Typography>
                  <Typography variant="body1" color="secondary">
                    {t('policyDetailsSection.carDetails')}
                  </Typography>
                </Grid>
              </Grid>
            </CommonContainer>
          </Grid>
          {!isPolicyExpired && (
            <Grid item xs={12} sm={6} lg={4}>
              <CommonContainer
                className={classes.cards}
                color="secondary"
                variant="outlined"
                onClick={handleNoClaimsDiscount}
              >
                <Grid className={classes.cardButton} data-testid="ncd-card">
                  <Grid container justifyContent="space-between">
                    <Typography variant="body2">{t('drawer.ncd')}</Typography>
                    <Grid item>
                      <IconSure color="secondary" icon="chevron-right" />
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-start">
                    <Typography variant="h4">
                      {mainVehicle.ncdYears} {t('policyDetailsSection.years')}
                    </Typography>
                    <PolicyStatus
                      label={ncdpPack ? t('policyStatus.protected') : t('policyStatus.notProtected')}
                      status={ncdpPack ? 'protected' : 'not-protected'}
                    />
                  </Grid>
                </Grid>
              </CommonContainer>
            </Grid>
          )}
          {!isPolicyExpired && hasFeatureFlag('BDILMM-1331') && (
            <Grid item xs={12} sm={6} lg={4}>
              <CommonContainer
                className={classes.cards}
                color="secondary"
                variant="outlined"
                onClick={() => history.push(ROUTE.PAYMENTS)}
              >
                <Grid className={classes.cardButton} data-testid="payments-card">
                  <Grid container justifyContent="space-between">
                    <Typography variant="body2">{t('policyDetailsSection.payments.paymentsHeader')}</Typography>
                    <Grid item>
                      <IconSure color="secondary" icon="chevron-right" />
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-start">
                    <Typography variant="h4">
                      {isDDCustomer
                        ? t('policyDetailsSection.payments.monthly', { value: totalPremium }) //needs to be updated when the dd values are available
                        : t('policyDetailsSection.payments.yearly', { value: totalPremium })}
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {t('policyDetailsSection.payments.link')}
                    </Typography>
                  </Grid>
                </Grid>
              </CommonContainer>
            </Grid>
          )}

          {!isPolicyExpired && (
            <PolicyRenewalInfo autoRenewal={autoRenewal} onClick={handlePolicyRenewal} t={t} policy={policySelected} />
          )}

          <PolicyButtons policy={policySelected} isCustomerBarred={underwritingInvestigationBar} />
        </Grid>

        <PolicyDetailsNavCards cards={cards} />
      </Grid>
    </>
  );
});
