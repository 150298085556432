export interface Lookup {
  code: string;
  included: string[];
  notIncluded: string[];
  subtitle: string;
  title: string;
}

export interface Lookups {
  [paramName: string]: Lookup[];
}

export const getDetailsOfSpecificCode = (
  lookupsList: Lookups,
  lookupName: string,
  code: string,
): { included: string[]; notIncluded: string[]; subtitle: string; title: string } | undefined => {
  const lookups: undefined | Lookup[] = lookupsList[lookupName];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (lookups) {
    const foundLookup = lookups.find((el) => el.code === code);

    if (foundLookup) {
      return {
        included: foundLookup.included,
        notIncluded: foundLookup.notIncluded,
        subtitle: foundLookup.subtitle,
        title: foundLookup.title,
      };
    }
  }
};
