import { Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../images/SVGWrapper';

import { BlueBanner } from './blueBanner';
import { CommonButton } from './button/commonButton';
import IconSure from './icon';

const useStyles = makeStyles(() => ({
  root: {
    '& .chat-section': {
      '&__link': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
      gap: 10,
    },
    '& .xs__container': {
      gap: 32,
      margin: '64px auto 0px',
      maxWidth: '345px',
      textAlign: 'center',
    },
  },
}));

export interface FailedPaymentBlockProps {
  handleClick: () => void;
  handleRedirect: () => void;
  hasBanner?: boolean;
}

export const FailedPaymentBlock: React.FC<FailedPaymentBlockProps> = ({
  handleClick,
  handleRedirect,
  hasBanner = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  return (
    <Grid className={classes.root}>
      {hasBanner && <BlueBanner title={t('policyDetailsSection.payments.paymentsHeader')} />}
      <Grid container direction="column" justifyContent="center" alignItems="center" className="xs__container">
        <Grid item>
          <SVGWrapper width={125} alt="car group" src="payment-failed.svg" />
        </Grid>
        <Typography variant="h2">{t('MTAJourney.payment.failed.title')}</Typography>
        <Typography variant="body1">{t('MTAJourney.payment.failed.body')}</Typography>
        <Grid item xs={8}>
          <CommonButton
            variant="contained"
            color="primary"
            startIcon="arrow-left"
            text={t('MTAJourney.payment.failed.button')}
            onClick={handleRedirect}
          />
        </Grid>
        <Grid container justifyContent="center" className="chat-section">
          <Typography>{t('appbar.needHelp')}</Typography>
          <IconSure icon="chat" color="secondary" type="solid" />
          <Link onClick={handleClick} className="chat-section__link">
            <Typography>{t('MTAJourney.CarModified.chat')}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
