import { Grid, Typography } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLinksList, ButtonLinksListItem } from '../../../../../../component/common/lists/buttonLinksList';
import { NeedHelp } from '../../../../../../component/layout/needHelp';
import config from '../../../../../../service/config';

export interface RenewalQuoteQuestionItem extends Pick<ButtonLinksListItem, 'label' | 'segmentStep'> {
  helpCentreArticle: number;
}

export interface RenewalQuoteQuestionsProps {
  header: string;
  questions: RenewalQuoteQuestionItem[];
  showButton?: boolean;
  subTitle: string;
}

export const RenewalQuoteQuestions: React.FC<RenewalQuoteQuestionsProps> = ({
  questions,
  header,
  subTitle,
  showButton = false,
}) => {
  const { t } = useTranslation('myAccount');

  const generateHelpCentreUrl = (articleId: number) => {
    const helpCentreUrl = config.brand === 'es' ? t('needHelp.helpCentre.esureLink') : t('needHelp.helpCentre.swLink');
    return `${helpCentreUrl}article/${articleId}?tab=Overview`;
  };

  const links: ButtonLinksListItem[] = questions.map((row) => ({
    label: row.label,
    segmentStep: row.segmentStep,
    url: generateHelpCentreUrl(row.helpCentreArticle),
  }));
  return (
    <>
      {
        // Do not show the questions if the brand is 'fa'
        config.brand !== 'fa' && (
          <>
            <Grid container spacing={2} className="renewals__question">
              <Grid item xs={12}>
                <Typography variant="h2">{header}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{subTitle}</Typography>
              </Grid>
            </Grid>
            <ButtonLinksList links={links} />
          </>
        )
      }
      <Grid container className="renewals__needhelp">
        <Grid item xs={12}>
          <NeedHelp showClaimButton={showButton} showTitle={config.brand === 'fa'} />
        </Grid>
      </Grid>
    </>
  );
};
