import { Grid, Theme, Typography, alpha, makeStyles } from '@esure-cloud/react-components';

import { GridProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStores } from '../../service/state/store';

export interface BlueTableContainerProps extends GridProps {
  children: React.ReactElement;
  title: string;
}
const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ palette, spacing }) => ({
  root: {
    '& .table': {
      '&_header': {
        '&-title': {
          fontWeight: 700,
        },
        backgroundColor: alpha(palette.secondary.main, 0.08),
        borderBottom: `1px solid ${palette.secondary.main}50`,
        padding: spacing(2, 3),
      },
      border: `1px solid ${palette.secondary.main}50`,
      borderRadius: spacing(1),
    },
  },
}));
export const BlueTableContainer: React.FC<BlueTableContainerProps> = ({ className, children, title }) => {
  const {
    interfaceStore: { isDesktop },
  } = useStores();

  const classes = useStyles({ isDesktop });
  return (
    <Grid container direction="column" className={clsx(classes.root, className)}>
      <Grid className="table">
        <Grid className="table_header">
          <Typography className="table_header-title">{title}</Typography>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};
