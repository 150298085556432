import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    '& .label': {
      minWidth: 'fit-content',
      width: '100%',
    },
    width: 300,
  },
}));

interface ControlProps {
  disabled?: boolean;
  label: string;
  value?: boolean;
}

export interface ChoiceProps {
  error?: boolean;
  identifier: string;
  left: ControlProps;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  radioGroupRef?: ((instance: unknown) => void) | RefObject<unknown> | null | undefined;
  radioValue: boolean | null;
  right: ControlProps;
}

export const Choice: React.FC<ChoiceProps> = observer(function Choice({
  identifier,
  onChange,
  left,
  right,
  radioValue,
  error,
  radioGroupRef,
}) {
  const classes = useStyles();
  const styleContainedSecondary = 'contained invert secondary';
  const { t } = useTranslation('myAccount');

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <RadioGroup onChange={onChange} value={radioValue} ref={radioGroupRef}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                className={clsx(
                  'label',
                  styleContainedSecondary,
                  radioValue !== null && {
                    checked: !radioValue,
                  },
                )}
                control={<Radio className={styleContainedSecondary} inputProps={{ id: `${identifier}-radio-no` }} />}
                id={`${identifier}-no`}
                label={left.label}
                labelPlacement="end"
                value={left.value ?? false}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                className={clsx(
                  'label',
                  styleContainedSecondary,
                  radioValue !== null && {
                    checked: radioValue,
                  },
                )}
                control={<Radio className={styleContainedSecondary} inputProps={{ id: `${identifier}-radio-yes` }} />}
                id={`${identifier}-yes`}
                label={right.label}
                labelPlacement="end"
                value={right.value ?? true}
              />
            </Grid>
          </Grid>
        </RadioGroup>

        {error && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}
      </Grid>
    </Grid>
  );
});
