import { Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeCarLocation } from '../../../../../../../../component/common/changeCarLocation';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .home-address': {
      fontWeight: 'bold',
    },
    '& .overnight-address': {
      gap: spacing(2),
    },
    display: 'grid',
    gap: spacing(2),
    width: 'fit-content',
  },
}));

export const DaytimeLocationRenewal: React.FC = observer(() => {
  const { setTitle, nextStep } = useRenewalStepperContext();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { setVehicle, vehicle },
    },
  } = useStores();

  const { isLookupsFetched } = useLookups({ lookups: [LOOKUP.DAYTIME_LOCATION] });
  const [daytimeLocation, setDaytimeLocation] = useState<string | undefined>(vehicle.daytimeLocationCd);

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.daytimeLocation'));
  }, []);

  useEffect(() => {
    setOptions(lookupsByName[LOOKUP.DAYTIME_LOCATION]);
  }, [isLookupsFetched, lookupsByName]);

  const handleContinue = () => {
    if (daytimeLocation) {
      setVehicle({
        ...vehicle,
        daytimeLocationCd: daytimeLocation,
      });
      nextStep();
    }
  };

  return (
    <>
      <Grid container direction="column" xs item className={classes.root}>
        <ChangeCarLocation
          location={daytimeLocation}
          options={options}
          setLocation={setDaytimeLocation}
          tooltipDescription={t('MTAJourney.CarUsage.CarDaytime.carKeptDaytime')}
          tooltipTitle={t('tooltips.changeYourCar.daytimeParking')}
        />
      </Grid>

      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_DAYTIME_LOCATION_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_DAYTIME_LOCATION_CANCEL}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_DAYTIME_LOCATION_SAVE, handleContinue }}
      />
    </>
  );
});
