import { AlertTitle, Box, Alert as MuiAlert, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../../../component/common/alert';
import { ThankYouNotification } from '../../../../component/common/thankYouNotification';
import { Insureds } from '../../../../component/layout/insureds';
import { LifestylePacks } from '../../../../component/layout/lifestylePacks';
import { MotorPolicyDetailsSection } from '../../../../component/layout/motorPolicyDetailsSection';
import { NeedHelp } from '../../../../component/layout/needHelp';
import { PolicyCard } from '../../../../component/layout/policyCards';
import { PolicyPageSkeleton } from '../../../../component/layout/skeletons/policySkeleton';
import { Upgrades } from '../../../../component/layout/upgrades';
import { DATE_TYPES, ROUTE } from '../../../../service/constant';
import { useStores } from '../../../../service/state/store';
import { formatDate } from '../../../../service/util/formatDate';
import SelfService from '../../../../service/util/selfService';
import { MotorRenewalNotification } from '../policy/renewals/components/motorRenewalNotification';

export const MotorPolicyDetails: React.FC = observer(function MotorPolicyDetails() {
  const {
    dashboardStore: {
      fetchRenewalDetails,
      insurance: {
        auto: { policySelected, mainVehicle },
      },
      refetchPoliciesData,
    },
    interfaceStore: { isLoading, setIsLoading },
  } = useStores();

  const { t } = useTranslation('myAccount');
  const { futureMtaEffectiveDate, futureRenewalEffectiveDate, drivers } = policySelected;

  const isPolicyExpired = SelfService.isPolicyExpired(policySelected.policyStatus);
  const { shouldUpdatePolicy } = policySelected;

  useEffect(() => {
    setIsLoading(true);
    if (shouldUpdatePolicy) {
      refetchPoliciesData();
    } else {
      fetchRenewalDetails(policySelected.policyNumber, policySelected.effectiveDate, false);
    }
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <PolicyPageSkeleton />
  ) : (
    <>
      {isPolicyExpired && (
        <Box sx={{ mb: 2 }}>
          <MuiAlert className="expiry-box" severity={'error'} data-testid={'alert'}>
            <AlertTitle>
              <Typography variant="h4">{t('motorPolicyDetailsPage.motorPolicyHasEnded.title')}</Typography>
            </AlertTitle>
            <Typography variant="body2"> {t('motorPolicyDetailsPage.motorPolicyHasEnded.subtitle1')}</Typography>
            <Typography variant="body2"> {t('motorPolicyDetailsPage.motorPolicyHasEnded.subtitle2')}</Typography>
          </MuiAlert>
        </Box>
      )}

      {futureRenewalEffectiveDate === null && <MotorRenewalNotification policy={policySelected} hasLabel={false} />}
      {futureRenewalEffectiveDate && (
        <ThankYouNotification policy={policySelected} insuranceDetails={mainVehicle.vrm} />
      )}
      <PolicyCard policy={policySelected} isPolicyHeader={true} />

      {futureMtaEffectiveDate && (
        <Alert
          severity="info"
          message={t('MTAPolicyBanner.message')}
          title={t('MTAPolicyBanner.title')}
          link={t('MTAPolicyBanner.link')}
          route={ROUTE.DOCUMENTS}
          date={formatDate(futureMtaEffectiveDate.toString(), DATE_TYPES.WEEKDAY_LONG)}
        />
      )}

      <MotorPolicyDetailsSection />
      <Insureds drivers={drivers} />
      <LifestylePacks />
      <Upgrades />
      <NeedHelp />
    </>
  );
});
