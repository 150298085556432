import { Divider, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { MtaTooltip } from '../../common/components/mtaTooltip';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .summary': {
      '&__divider': {
        width: '100%',
      },
      '&__item': {
        gap: spacing(1),
        margin: spacing(0, 3),
        padding: spacing(2, 0),
      },
    },
    '& .typography-style': {
      '&__bold': {
        fontWeight: 700,
      },
      '&__text': {
        color: palette.grey.A700,
      },
    },
    gap: spacing(4),
  },
}));

export const YourPremiumDetails: React.FC = () => {
  const { t } = useTranslation('myAccount');

  const classes = useStyles();

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { paymentPlan },
        },
      },
    },
    mtaStore: { quote },
  } = useStores();

  const {
    additionalPremium,
    administrationFee,
    paymentPlan: { chargeForCredit, currentAnualPremium },
  } = quote;

  const isDDCustomer = paymentPlan !== 'YearlyCardPayment';

  const totalAmount = additionalPremium + +administrationFee + chargeForCredit;

  return (
    <>
      <Grid container className={clsx(classes.root, 'summary__item')}>
        <Typography variant="body1"> {t('MTAJourney.QuoteReady.yourPremium')}</Typography>

        {isDDCustomer && (
          <Grid container justifyContent="space-between">
            <Typography variant="body1" className={clsx('typography-style__bold', 'typography-style__text')}>
              {t('MTAJourney.QuoteReady.DDCustomer.annualPremium')}
            </Typography>

            <Typography variant="body1" className={clsx('typography-style__bold', 'typography-style__text')}>
              {t('core:price.pound', { value: currentAnualPremium })}
            </Typography>
          </Grid>
        )}

        <Grid container justifyContent="space-between">
          <Grid>
            {additionalPremium >= 0 ? (
              <MtaTooltip
                description={
                  <Typography variant="body1" className="typography-style__bold">
                    {t('MTAJourney.QuoteReady.additionalPremium')}
                  </Typography>
                }
                tooltipTitle={t('tooltips.quote.additionalPremium')}
              />
            ) : (
              <MtaTooltip
                description={
                  <Typography variant="body1" className="typography-style__bold">
                    {t('MTAJourney.QuoteReady.returnPremium')}
                  </Typography>
                }
                tooltipTitle={t('tooltips.quote.returnPremium')}
              />
            )}
          </Grid>

          <Typography variant="body1" className="typography-style__bold">
            {t('core:price.pound', { value: additionalPremium })}
          </Typography>
        </Grid>

        <Divider className="summary__divider" />

        {isDDCustomer && (
          <Grid container justifyContent="space-between">
            <Typography variant="body1">{t('MTAJourney.QuoteReady.DDCustomer.chargeCredit')}</Typography>

            <Typography variant="body1" className="typography-style__bold">
              {t('core:price.pound', { value: chargeForCredit })}
            </Typography>
          </Grid>
        )}

        <Grid container justifyContent="space-between">
          <Grid>
            <MtaTooltip
              description={<Typography variant="body1">{t('MTAJourney.QuoteReady.administrationFee')}</Typography>}
              tooltipTitle={t('tooltips.quote.administrationFee')}
            />
          </Grid>
          <Typography variant="body1" className="typography-style__bold">
            {t('core:price.pound', { value: administrationFee })}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" className="summary__item">
        <Typography variant="h3" className="typography-style__bold">
          {totalAmount >= 0 ? t('MTAJourney.QuoteReady.totalCost') : t('MTAJourney.QuoteReady.noCost')}
        </Typography>
        <Typography variant="body1" className="typography-style__bold">
          {t('core:price.pound', {
            value: totalAmount,
          })}
        </Typography>
      </Grid>
    </>
  );
};
