import { Component, ErrorInfo } from 'react';

import { UseSegmentReturn } from '../../service/util/customHooks/useSegment';
import { stringifyError } from '../../service/util/stringUtils';

interface ErrorHandlerProps {
  children: React.ReactNode;
  eventTrackHandler: UseSegmentReturn['eventTrack'];
}

interface ErrorHandlerState {
  hasError: boolean;
}

export class ErrorHandler extends Component<ErrorHandlerProps, ErrorHandlerState> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorHandlerState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // This safely stringifies the Error object, then parses it back to an object, since we need to refer to it as an object later
    const parsedErrorObject = JSON.parse(stringifyError(error)) as Record<string, unknown>;
    this.props.eventTrackHandler('Error boundary caught error', {
      error: JSON.stringify({ errorInfo: errorInfo, errorObject: parsedErrorObject }),
    });
  }

  renderFallback(): React.ReactNode {
    return (
      <div>
        <div>Oops, the application reached an unexpected state.</div>
      </div>
    );
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return this.renderFallback();
    }

    return children;
  }
}
