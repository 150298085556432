import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CarModifiedQuestion } from '../../../../../../../../component/common/carModifiedQuestion';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { IModification } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';
import { CarModifications } from '../components/carModifications';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& .error': {
      color: palette.error.dark,
    },
    '& .radio-section': {
      display: 'grid',
      gap: 20,
      width: 'fit-content',
    },
  },
}));

export const CarModified: React.FC = observer(function CarModified() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const {
    nextStep,
    setTitle,
    editMode,
    setStep,
    stepsData: { reviewStep },
  } = useStepperContext();

  const {
    mtaStore: {
      pendedMTA: { setModifications, setHasModifications },
    },
    interfaceStore: { getStepAutoComplete, setStepAutoComplete },
  } = useStores();
  const [state, setState] = useState<{ selections: IModification[] }>({ selections: [] });

  const [wasCarModified, setWasCarModified] = useState<null | boolean>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const carModifiedTag1 = 'carModified1';
  const carModifiedTag2 = 'carModified2';

  useEffect(() => {
    setTitle(t('MTAJourney.CarModified.title'));

    if (getStepAutoComplete(carModifiedTag1) !== undefined) {
      if (getStepAutoComplete(carModifiedTag1) === 'true') {
        setWasCarModified(true);
      } else if (getStepAutoComplete(carModifiedTag1) === 'false') {
        setWasCarModified(false);
      }
    }
    if (getStepAutoComplete(carModifiedTag2) !== undefined) {
      const modlist = getStepAutoComplete(carModifiedTag2) ?? '';
      const processedModList: IModification[] = JSON.parse(modlist) as IModification[];
      setState({ selections: processedModList });
    }
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value === 'true';
    setWasCarModified(value);
  };
  const isValid = (): boolean => {
    return wasCarModified === null || (wasCarModified && state.selections.length === 0) ? false : true;
  };
  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (isValid()) {
      setModifications(state.selections);
      setStepAutoComplete(carModifiedTag2, JSON.stringify(state.selections));
      if (wasCarModified !== null) {
        setHasModifications(wasCarModified);
        setStepAutoComplete(carModifiedTag1, String(wasCarModified));
      }
    }
  };

  const handleSave = () => {
    handlePendedMta();
    isValid() && setStep(reviewStep);
  };

  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };

  return (
    <Grid container item direction="column" className={classes.root}>
      <CarModifiedQuestion
        handleChangeRadio={handleChangeRadio}
        isSubmitting={isSubmitting}
        wasCarModified={wasCarModified}
      />
      {wasCarModified && (
        <CarModifications isSubmitting={isSubmitting} options={state} setOptions={setState} showTitle />
      )}
      {editMode ? (
        <EditFooter
          id="carModified"
          validPage={isValid()}
          handleSave={handleSave}
          handleBack={() => setStep(reviewStep)}
        />
      ) : (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_CAR_MODIFIED}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_CAR_MODIFIED}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CAR_MODIFIED, handleContinue }}
        />
      )}
    </Grid>
  );
});
