import { types } from 'mobx-state-tree';

export const PolicyExtra = types.model('PolicyExtra', {
  amount: types.number,
  code: types.string,
  details: types.optional(types.string, ''),
  item: types.string,
});

export const PolicyExtras = types.model('PolicyExtras', {
  policyExtras: types.array(PolicyExtra),
});
