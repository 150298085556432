import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { track } from '../lib/optimizely';

import { ROUTE } from './constant';
import { useStores } from './state/store';

export interface iLookup {
  eventKey: string;
  route: string;
}

const RouteTracker: React.FC = () => {
  const location = useLocation();
  const [route, setRoute] = useState('');
  const {
    interfaceStore: { setPagesViewed, pagesViewed },
  } = useStores();
  useEffect(() => {
    const lookups: iLookup[] = [
      { eventKey: 'My_Account_login_pageview', route: '/' },
      { eventKey: 'My_Account_policies_pageview', route: ROUTE.DASHBOARD },
      { eventKey: 'My_Account_policies_policy_pageview', route: ROUTE.POLICY_DETAILS },
      {
        eventKey: 'My_Account_about_your_cover_types_of_use_pageview',
        route: ROUTE.TYPES_OF_USE,
      },
      {
        eventKey: 'My_Account_about_your_cover_if_you_claim_pageview',
        route: ROUTE.IF_YOU_CLAIM,
      },
      {
        eventKey: 'My_Account_about_your_cover_if_you_claim_excesses_pageview',
        route: ROUTE.EXCESSES,
      },
      { eventKey: 'My_Account_policy_folder_documents_pageview', route: ROUTE.DOCUMENTS },
      { eventKey: 'My_Account_claims_pageview', route: ROUTE.CLAIMS },
      { eventKey: 'My_Account_settings_pageview', route: ROUTE.ACCOUNT },
    ];
    const checkLookup = (item: iLookup) => {
      return item.route === location.pathname;
    };
    const eventKey: string = lookups.find(checkLookup)?.eventKey ?? '';
    if (eventKey !== '') {
      if (route !== eventKey) {
        track(eventKey);
        setPagesViewed(pagesViewed + 1);
      }
      setRoute(eventKey);
    }
  }, [location.pathname, route]);

  return null;
};

export default RouteTracker;
