import { Grid, makeStyles } from '@esure-cloud/react-components';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../component/common/blueBanner';
import { PaymentTable, PaymentTableData } from '../../../../../component/layout/paymentTable';
import { ROUTE } from '../../../../../service/constant';
import { ITransaction } from '../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../service/state/store';
import { MotorRenewalNotification } from '../../../policies/policy/renewals/components/motorRenewalNotification';

import { PremiumDetails } from './premiumDetails';

export interface SummaryItem {
  code: string;
  name: string;
  price: number;
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& .blue-banner': {
      marginBottom: spacing(1),
    },
    '& .content': {
      gap: spacing(2),
      [breakpoints.up('sm')]: {
        margin: spacing(2, 0, 0),
        padding: spacing(0),
      },
      padding: spacing(0, 2),
    },
    '& .payments-container': {
      gap: 10,
    },
    [breakpoints.up('sm')]: {
      margin: spacing(-1, -2, 0),
    },
    margin: spacing(-4.5, -2, 0),
  },
}));

export const orderedTransactions = (transactions: PaymentTableData[]): PaymentTableData[] => {
  const largerThan = (a: DateTime, b: DateTime) => (a > b ? 1 : 0);
  const sortTransactions = (a: DateTime, b: DateTime) => (a < b ? -1 : largerThan(a, b));
  return transactions
    .slice()
    .sort((a, b) =>
      a.transactionDate && b.transactionDate ? sortTransactions(b.transactionDate, a.transactionDate) : 0,
    );
};

export const YearlyPayment: React.FC<{ total: number; transactions: ITransaction[] }> = observer(
  ({ total, transactions }) => {
    const classes = useStyles();
    const { t } = useTranslation('myAccount');
    const {
      dashboardStore: {
        insurance: {
          auto: { policySelected },
        },
      },
    } = useStores();

    return (
      <Grid className={classes.root}>
        <Grid className="blue-banner">
          <BlueBanner title={t('breadcrumbs.payments')} route={ROUTE.POLICY_DETAILS} />
        </Grid>
        <Grid className="content" container direction="column">
          {policySelected.futureRenewalEffectiveDate === null && (
            <Grid item xs>
              <MotorRenewalNotification policy={policySelected} />
            </Grid>
          )}
          <Grid container className="payments-container">
            <Grid item xs>
              <PaymentTable
                transactions={orderedTransactions(transactions)}
                headers={['Payment activity', 'Details', 'Amount']}
              />
            </Grid>
            <PremiumDetails total={total} />
          </Grid>
        </Grid>
      </Grid>
    );
  },
);
