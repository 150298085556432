import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FailedPaymentBlock } from '../../../../../../../../component/common/failedPaymentBlock';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { track } from '../../../../../../../../lib/optimizely';
import { ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { useStepperContext } from '../../common/utils/stepperContext';

export const PaymentFailed: React.FC = () => {
  const { eventTrack } = useSegment();
  const history = useHistory();
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
    paymentStore: { updatePaymentComplete },
  } = useStores();
  const {
    setTitle,
    stepsData: { journeyType },
  } = useStepperContext();
  const chatBotAttr = useMTAChatBotAttributes('ssMTAVehiclePaymentFailed');

  const handleClick = () => {
    track('My_Account_chat_with_us_click');
    eventTrack('Chat With Us Button Clicked', {
      position: `Payment Failed`,
      type: TRACK_TYPE.CHAT,
    });
    startChat({
      initiator: `Payment failed`,
      ...(chatBotAttr ?? {}),
    });
  };

  useEffect(() => {
    setTitle('');

    eventTrack('Payment failure screen viewed viewed', {
      journeyType,
      type: TRACK_TYPE.VIEW,
    });
    updatePaymentComplete(false);
  }, []);

  return <FailedPaymentBlock handleClick={handleClick} handleRedirect={() => history.push(ROUTE.CHANGE_PAYMENT)} />;
};
