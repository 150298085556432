import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../service/state/store';

import { CoverItem } from './accordions/CoverItem';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  renewalDescriptionContainer: {
    marginBottom: theme.spacing(2),
  },
  renewalHeaderContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const AutoRenewalInfo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount', {});
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const [benefits, setBenefits] = useState<undefined | string[]>([]);

  useEffect(() => {
    setBenefits(t('drawer.autoRenewalInformation.renewalBenefits', { returnObjects: true }));
  }, [t]);

  return (
    <>
      <Grid className={classes.gridContainer} container>
        <Grid className={classes.renewalHeaderContainer} item>
          <Typography variant={isDesktop ? 'h3' : 'h2'}>
            {' '}
            {t('drawer.autoRenewalInformation.renewalHeader')}{' '}
          </Typography>
        </Grid>
        <Grid className={classes.renewalDescriptionContainer} item>
          <Typography variant={isDesktop ? 'body2' : 'body1'}>
            {' '}
            {t('drawer.autoRenewalInformation.renewalDescription')}{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={isDesktop ? 'h5' : 'h3'}>
            {' '}
            {t('drawer.autoRenewalInformation.renewalbenefitsHeader')}{' '}
          </Typography>
        </Grid>
        <Grid item>
          {benefits?.map((item, index) => (
            <CoverItem key={index} text={item} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
