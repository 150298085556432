import { AlertProps } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { JOURNEY_TYPE, MTA_COMMON_ROUTES, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { AutoRenewal } from '../../../../renewals/pages/autoRenewal';
import { MoreChanges } from '../../carDetails/components/moreChanges';
import { CarLookupResult } from '../../carDetails/steps/carLookupResult';
import { CarModified } from '../../carDetails/steps/carModified';
import { CarModifiedSelection } from '../../carDetails/steps/carModifiedSelection';
import { CarOwnership } from '../../carDetails/steps/carOwnership';
import { CarRegistration } from '../../carDetails/steps/carRegistration';
import { CarUsageChangeYourCar, CarUsageOtherChanges } from '../../carDetails/steps/carUsage';
import { QuoteReady } from '../../carDetails/steps/quoteReady';
import { SecurityFeatures } from '../../carDetails/steps/securityFeatures';
import { UseOfCar } from '../../carDetails/steps/useOfCar';
import { DateOfBirth } from '../../drivers/steps/dateOfBirth';
import { DriverHistory } from '../../drivers/steps/driverHistory';
import { DriverLicense } from '../../drivers/steps/driverLicense';
import { DriverMaritalStatus } from '../../drivers/steps/driverMaritalStatus';
import { DriverNameAddDriver } from '../../drivers/steps/driverNameAddDriver';
import { DriverOccupation } from '../../drivers/steps/driverOccupation';
import { DriverRelationship } from '../../drivers/steps/driverRelationship';
import { EditDriver } from '../../drivers/steps/editDriver';
import { MainDriverAdd } from '../../drivers/steps/mainDriverAdd';
import { MainDriverRemove } from '../../drivers/steps/mainDriverRemove';
import { RemoveDriver } from '../../drivers/steps/removeDriver';
import { SelectChangeDrivers } from '../../drivers/steps/selectChangeDrivers';
import { UseOfOtherCars } from '../../drivers/steps/useOfOtherCars';
import { CarOvernight } from '../../yourDetails/steps/carOvernight';
import { CarUsage } from '../../yourDetails/steps/carUsage';
import { CarsInHousehold } from '../../yourDetails/steps/carsInHousehold';
import { ChildrenNumber } from '../../yourDetails/steps/childrenInHousehold';
import { DaytimeLocation } from '../../yourDetails/steps/daytimeLocation';
import { HomeAddress } from '../../yourDetails/steps/homeAddress';
import { RelationshipStatus } from '../../yourDetails/steps/relationshipStatus';
import { ResidentialStatus } from '../../yourDetails/steps/residentialStatus';
import { UpdateName } from '../../yourDetails/steps/updateName';
import { ReviewAll } from '../steps/reviewAll';
import { StartDate } from '../steps/startDate';

interface Step {
  blueBannerTitle: Record<'default' | string, string>;
  journeyType: JOURNEY_TYPE;
  lastStepOfJourney: number;
  nextRoute: ROUTE;
  reviewStep: number;
  steps: React.ReactElement[];
}

interface GlobalStepsProps {
  [key: string]: Step;
}

interface StepperAlertProps {
  description: string;
  severity: AlertProps['severity'];
  showAlert: boolean;
  title: string;
}

export interface StepperContextProps {
  alert?: StepperAlertProps;
  bannerTitle: string;
  editMode: boolean;
  editPage: string | undefined;
  isMultiMTA: boolean;
  lastStepRestoreData?: string[];
  nextStep: () => void;
  overrideStepCount: number;
  setAlert?: React.Dispatch<React.SetStateAction<StepperAlertProps>>;
  setBannerTitle: React.Dispatch<React.SetStateAction<string>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsMultiMTA: React.Dispatch<React.SetStateAction<boolean>>;
  setLastStepRestoreData: React.Dispatch<React.SetStateAction<string[]>>;
  setOverrideStepCount: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  step: number;
  stepsData: Step;
  title: string;
}

const StepperContext = React.createContext<StepperContextProps>({
  alert: undefined,
  bannerTitle: '',
  editMode: false,
  editPage: undefined,
  isMultiMTA: false,
  lastStepRestoreData: undefined,
  nextStep: () => null,
  overrideStepCount: 0,
  setAlert: () => null,
  setBannerTitle: () => null,
  setEditMode: () => null,
  setEditPage: () => undefined,
  setIsMultiMTA: () => undefined,
  setLastStepRestoreData: () => null,
  setOverrideStepCount: () => undefined,
  setStep: () => null,
  setTitle: () => null,
  step: 0,
  stepsData: {
    blueBannerTitle: { 0: '' },
    journeyType: JOURNEY_TYPE.NONE,
    lastStepOfJourney: 0,
    nextRoute: ROUTE.DASHBOARD,
    reviewStep: 0,
    steps: [],
  },
  title: '',
});

const StepperProvider: React.FunctionComponent = observer(function StepperProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [alert, setAlert] = useState<StepperAlertProps>({
    description: '',
    severity: undefined,
    showAlert: false,
    title: '',
  });
  const [title, setTitle] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [editPage, setEditPage] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [isMultiMTA, setIsMultiMTA] = useState(false);
  const [overrideStepCount, setOverrideStepCount] = useState(0);
  const { pathname } = useLocation();

  const { t } = useTranslation('myAccount');
  const {
    mtaStore: { completedMTAJourneys },
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  const defaultSteps: GlobalStepsProps = {
    [ROUTE.CAR_MILEAGE_AND_USAGE]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeMileage'),
      },
      journeyType: JOURNEY_TYPE.CAR_MILEAGE_AND_USAGE,
      lastStepOfJourney: 1,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 1,
      steps: [<StartDate />, <UseOfCar />],
    },
    [ROUTE.CAR_MODIFICATIONS]: {
      blueBannerTitle: {
        default: t('MTAJourney.updateCarModifications'),
      },
      journeyType: JOURNEY_TYPE.CAR_MODIFICATIONS,
      lastStepOfJourney: 2,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 2,
      steps: [<StartDate />, <CarModifiedSelection />, <SecurityFeatures />],
    },
    [ROUTE.CHANGE_YOUR_CAR]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeYourCar'),
      },
      journeyType: JOURNEY_TYPE.CHANGE_YOUR_CAR,
      lastStepOfJourney: 6,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 6,
      steps: [
        <StartDate />,
        <CarRegistration changeCarJourney />,
        <CarLookupResult changeCarJourney />,
        <CarOwnership />,
        <SecurityFeatures />,
        <CarModified />,
        <CarUsageChangeYourCar />,
      ],
    },
    [ROUTE.CHANGE_YOUR_REG_NO]: {
      blueBannerTitle: {
        default: t('MTAJourney.changeRegNo'),
      },
      journeyType: JOURNEY_TYPE.REGISTRATION_NUMBER,
      lastStepOfJourney: 2,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 2,
      steps: [
        <StartDate />,
        <CarRegistration showCarNotFoundPrompt={hasFeatureFlag('SSOP-1123')} />,
        <CarLookupResult />,
      ],
    },
    [ROUTE.OTHER_CHANGES]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.OTHER_CHANGES,
      },
      journeyType: JOURNEY_TYPE.OTHER_CHANGES,
      lastStepOfJourney: 1,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 1,
      steps: [<StartDate />, <CarUsageOtherChanges />],
    },
    [ROUTE.CHANGE_HOME_ADDRESS]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.CHANGE_HOME_ADDRESS,
      },
      journeyType: JOURNEY_TYPE.CHANGE_HOME_ADDRESS,
      lastStepOfJourney: 5,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 5,
      steps: [
        <StartDate />,
        <HomeAddress />,
        <ResidentialStatus />,
        <CarUsage />,
        <DaytimeLocation />,
        <CarOvernight />,
      ],
    },
    [ROUTE.ADD_DRIVER]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.ADD_DRIVER,
      },
      journeyType: JOURNEY_TYPE.ADD_DRIVER,
      lastStepOfJourney: 9,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 9,
      steps: [
        <StartDate />,
        <DriverNameAddDriver />,
        <DateOfBirth />,
        <DriverMaritalStatus />,
        <DriverRelationship />,
        <DriverOccupation />,
        <DriverLicense />,
        <UseOfOtherCars />,
        <DriverHistory />,
        <MainDriverAdd />,
      ],
    },
    [ROUTE.CHANGE_DRIVER_DETAILS]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.CHANGE_DRIVER,
      },
      journeyType: JOURNEY_TYPE.CHANGE_DRIVER,
      lastStepOfJourney: 3,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 3,
      steps: [<StartDate />, <SelectChangeDrivers />, <EditDriver />, <MainDriverAdd />],
    },
    [ROUTE.REMOVE_DRIVER]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.REMOVE_DRIVER,
      },
      journeyType: JOURNEY_TYPE.REMOVE_DRIVER,
      lastStepOfJourney: 2,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 2,
      steps: [<StartDate />, <RemoveDriver />, <MainDriverRemove />],
    },
    [ROUTE.CHANGE_PERSONAL_DETAILS]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS,
      },
      journeyType: JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS,
      lastStepOfJourney: 4,
      nextRoute: ROUTE.MORE_CHANGES,
      reviewStep: 4,
      steps: [<StartDate />, <UpdateName />, <RelationshipStatus />, <ChildrenNumber />, <CarsInHousehold />],
    },
    [ROUTE.MORE_CHANGES]: {
      blueBannerTitle: {
        default: t('MTAJourney.moreChanges'),
      },
      journeyType: JOURNEY_TYPE.MORE_CHANGES,
      lastStepOfJourney: 0,
      nextRoute: ROUTE.MTA_REVIEW_CHANGES,
      reviewStep: 0,
      steps: [<MoreChanges />],
    },
    [ROUTE.MTA_REVIEW_CHANGES]: {
      blueBannerTitle: {
        default: t('MTAJourney.reviewAll.reviewChanges'),
      },
      journeyType: JOURNEY_TYPE.REVIEW_CHANGES,
      lastStepOfJourney: 0,
      nextRoute: ROUTE.MTA_QUOTE_READY,
      reviewStep: 0,
      steps: [<ReviewAll />],
    },
    [ROUTE.MTA_QUOTE_READY]: {
      blueBannerTitle: {
        default: t('MTAJourney.yourQuote'),
      },
      journeyType: JOURNEY_TYPE.QUOTE_READY,
      lastStepOfJourney: 0,
      nextRoute: ROUTE.DASHBOARD, // it doesn't matter what this is set to as it's not used on the quote ready page
      reviewStep: 0,
      steps: [<QuoteReady />],
    },
    [ROUTE.YOUR_RENEWAL_DETAILS]: {
      blueBannerTitle: {
        default: JOURNEY_TYPE.CHANGE_AUTO_RENEWAL,
      },
      journeyType: JOURNEY_TYPE.CHANGE_AUTO_RENEWAL,
      lastStepOfJourney: 0,
      nextRoute: ROUTE.DASHBOARD, // it doesn't matter what this is set to as it's not used on the quote ready page
      reviewStep: 1,
      steps: [<AutoRenewal />],
    },
  };
  const [currentSteps, setCurrentSteps] = useState(defaultSteps[ROUTE.CHANGE_YOUR_CAR]);

  const [lastStepRestoreData, setLastStepRestoreData] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isMultiMTA && !MTA_COMMON_ROUTES.includes(pathname)) {
      setStep(1);
    } else {
      setStep(0);
    }
    if (Object.prototype.hasOwnProperty.call(defaultSteps, pathname)) {
      setCurrentSteps(defaultSteps[pathname]);
    }
    if (completedMTAJourneys.length > 0) {
      setIsMultiMTA(true);
    } else {
      setIsMultiMTA(false);
    }
  }, [pathname]);

  const [step, setStep] = useState(0);

  useEffect(() => {
    setBannerTitle(getTitle());
  }, [pathname, step]);

  const handleContinueButton = () => {
    if (step < currentSteps.steps.length - 1) {
      setStep(step + 1);
    }
  };

  const getTitle = () => {
    const currentStep: Step | undefined = defaultSteps[pathname];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (currentStep === undefined) {
      return '';
    } else if (currentStep.blueBannerTitle[step]) {
      return currentStep.blueBannerTitle[step];
    } else {
      return currentStep.blueBannerTitle['default'];
    }
  };

  return (
    <StepperContext.Provider
      value={{
        alert,
        bannerTitle,
        editMode,
        editPage,
        isMultiMTA,
        lastStepRestoreData,
        nextStep: handleContinueButton,
        overrideStepCount,
        setAlert,
        setBannerTitle,
        setEditMode,
        setEditPage,
        setIsMultiMTA,
        setLastStepRestoreData,
        setOverrideStepCount,
        setStep,
        setTitle,
        step,
        stepsData: currentSteps,
        title,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
});

const useStepperContext: () => StepperContextProps = () => {
  return useContext(StepperContext);
};

export { StepperContext, StepperProvider, useStepperContext };

//move the journey steps to another file
