import {
  Accordion,
  AccordionSummary,
  CommonContainer,
  Grid,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../component/common/icon';
import { TableList } from '../../../../../../component/common/tableLists';
import { DATE_TYPES } from '../../../../../../service/constant';
import { formatDate } from '../../../../../../service/util/formatDate';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .payment': {
      '&__details': {
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        padding: '0px 5px',
      },
      borderRadius: spacing(1),
      marginTop: spacing(4),
    },
    minWidth: '100%',
  },
}));

interface Instalments {
  amount: number | null;
  dueDate?: string;
}
export interface MonthlyPaymentCardProp {
  currentYearMonthlyCost: number;
  installmentSchedule: Instalments[];
  numberOfInstallments: number;
  startDate: string;
}

export const MonthlyPaymentCard: React.FC<MonthlyPaymentCardProp> = observer(
  ({ currentYearMonthlyCost, installmentSchedule, numberOfInstallments, startDate }) => {
    const classes = useStyles();
    const { t } = useTranslation('myAccount');

    const allHeaders = ['Due date', 'Amount'];

    return (
      <Grid className={clsx(classes.root)} xs={12} item data-testid="monthly-card-payment">
        <CommonContainer className="payment" color="secondary">
          <Grid container className="payment__details">
            <Typography variant="h3">
              {t('policyManagement.motorRenewalQuote.DDCustomer.monthlyPayment.title')}
            </Typography>
            <Typography variant="h4">
              {t('policyManagement.motorRenewalQuote.DDCustomer.monthlyPayment.paymentText', {
                numberOfInstallments: numberOfInstallments,
                value: t('core:price.pound', { value: currentYearMonthlyCost }),
              })}
            </Typography>
            {installmentSchedule.length > 0 && (
              <Typography variant="h4">
                {t('policyManagement.motorRenewalQuote.DDCustomer.monthlyPayment.subTitle', {
                  date: formatDate(startDate, DATE_TYPES.SHORT),
                })}
              </Typography>
            )}

            <Typography
              dangerouslySetInnerHTML={{
                __html: t('policyManagement.motorRenewalQuote.DDCustomer.monthlyPayment.content'),
              }}
            />
          </Grid>

          <Grid container direction="column" wrap="nowrap" className="payment__lists">
            <Accordion>
              <AccordionSummary expandIcon={<IconSure color="secondary" icon="chevron-down" type="line" size="26" />}>
                <Typography variant="body1" color="secondary">
                  {t('policyManagement.motorRenewalQuote.DDCustomer.monthlyPayment.showPayments')}
                </Typography>
              </AccordionSummary>
              <TableList headers={allHeaders} cells={installmentSchedule} />
            </Accordion>
          </Grid>
        </CommonContainer>
      </Grid>
    );
  },
);
