import { Box, CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PopupTooltip } from '../../../../../../component/common/popupTooltip';
import SelfService from '../../../../../../service/util/selfService';

export interface RenewalCostBreakDownProps {
  apr: number | null;
  policyCost: number;
  policyCostWithoutInterest: number;
  policyInterest: number | null;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(3),
  },
}));

export const RenewalCostBreakDown: React.FC<RenewalCostBreakDownProps> = observer(
  ({ apr, policyCostWithoutInterest, policyInterest, policyCost }) => {
    const classes = useStyles();
    const { t } = useTranslation('myAccount');

    return (
      <Grid className={clsx(classes.root)} data-testid="cost-break-down">
        <CommonContainer color="secondary" variant="outlined">
          <Typography color="textSecondary" variant="h3">
            {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.title')}
          </Typography>
          <Grid container>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography color="textSecondary" variant="body1">
                  {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.policy')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body1">
                  {SelfService.toCurrency(policyCostWithoutInterest)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.includes')}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Box display="flex">
                  <Typography color="textSecondary" variant="body1">
                    {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.interest')}
                  </Typography>
                  <PopupTooltip
                    alignment="left"
                    background="#EBF1FC"
                    tooltipColor="secondary"
                    placement="bottom-end"
                    size="medium"
                    title={t('policyManagement.yourRenewalQuote.renewalCostBreakdown.interestTooltipText')}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body1">
                  {policyInterest === null ? '£0' : SelfService.toCurrency(policyInterest)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                (APR = {apr === null ? 0 : apr}%)
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography color="textSecondary" variant="h3">
                  {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.totalAmount')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="h3">
                  {SelfService.toCurrency(policyCost)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {t('policyManagement.yourRenewalQuote.renewalCostBreakdown.includesTotal')}
              </Typography>
            </Grid>
          </Grid>
        </CommonContainer>
      </Grid>
    );
  },
);

export default memo(RenewalCostBreakDown);
