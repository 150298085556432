import { AxiosResponse } from 'axios';
import { Instance, applySnapshot, flow, getRoot, getSnapshot, toGenerator, types } from 'mobx-state-tree';

import config from '../../../config';
import { APIResponse } from '../../../network';
import { getPendedMtaQuote, getQuote } from '../../../network/api/dashboard';
import { Header } from '../../../util/interfaceModels/interfaceModels';
import { IRootStore } from '../index';

import { IPendedMTA, PendedMTA } from './pendedMTA';
import { Quote } from './quote';

export const CompletedMTAJourney = types.model('CompletedMTAJourney', {
  completedJourney: types.optional(types.string, ''),
  lastStep: types.optional(types.number, 0),
});

export const MTAStore = types
  .model('MTAStore', {
    completedMTAJourneys: types.optional(types.array(CompletedMTAJourney), () => []),
    isMTAInitialised: types.optional(types.boolean, false),
    pendedMTA: types.optional(PendedMTA, () => PendedMTA.create()),
    quote: types.optional(Quote, () => Quote.create()),
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
  }))
  .views((self) => ({
    get chatbotModifications() {
      const { vehicle } = self.pendedMTA;

      const newMods = vehicle.modifications;
      const mainMods = this.rootStore.dashboardStore.insurance.auto.mainVehicle.modifications;

      const sortNewMods = newMods.slice().sort((a, b) => a.modificationCode.localeCompare(b.modificationCode));
      const sortMainMods = mainMods.slice().sort((a, b) => a.modificationCode.localeCompare(b.modificationCode));
      if (JSON.stringify(sortMainMods) === JSON.stringify(sortNewMods)) {
        return undefined;
      }

      const modsAsString: string = newMods
        .map((mod) => this.rootStore.dashboardStore.lookups.decodeByFieldName('modifications', mod.modificationCode))
        .join(', ');

      return modsAsString;
    },
    get rootStore(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    clearStore() {
      applySnapshot(self, self.initialState);
    },
    isJourneyCompleted(journey: string) {
      const foundIndex = self.completedMTAJourneys.findIndex((item) => {
        return item.completedJourney === journey;
      });
      if (foundIndex === -1) {
        return false;
      } else {
        return true;
      }
    },
    removeCompletedJourney() {
      self.completedMTAJourneys.pop();
    },
    resetCompletedJourney() {
      self.completedMTAJourneys.clear();
    },
    resetPendedMTA() {
      self.pendedMTA = PendedMTA.create();
    },
    setCompletedJourney(completedJourney: ICompletedMTAJourney) {
      self.completedMTAJourneys.push(completedJourney);
    },
    setMtaInitialised(val: boolean) {
      self.isMTAInitialised = val;
    },
    setQuote(fetchQuoteResponse: AxiosResponse<APIResponse<IQuote>>) {
      const { make, model, vrm } = self.pendedMTA.vehicle;
      const vehicleDetails = { make, model, vrm };
      if (fetchQuoteResponse.status === 200 && fetchQuoteResponse.data.results.length) {
        self.quote = Quote.create({ ...fetchQuoteResponse.data.results[0], vehicleDetails });
        if (fetchQuoteResponse.data.infos.length > 0 && fetchQuoteResponse.data.infos[0].code === 'TDR') {
          self.quote.trackerRequiredInd = true;
        }
      }
    },
  }))
  // These are seperated into their own actions block so that we get typings when using self.setQuote
  .actions((self) => ({
    fetchPendedQuote: flow(function* (policyNumber: string) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getPendedMtaQuote({ headers }, policyNumber));
        self.setQuote(res);
        return res;
      } catch (err) {
        /* empty */
      }
    }),
    fetchQuote: flow(function* (policyNumber: string) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const endorsementDate = self.pendedMTA.endorsementDate
          ? self.pendedMTA.endorsementDate.toUTC(undefined, { keepLocalTime: true })
          : self.pendedMTA.endorsementDate;

        const data: IPendedMTA = { ...self.pendedMTA, endorsementDate };
        // Special case here, set the timeout to config.app.apiGatewayTimeoutMilliseconds, this just needs to be something slightly under the default API gateway timeout of 29 seconds
        // to ensure that we abort the request before the gateway does so that we can correctly capture the aborted error
        const res = yield* toGenerator(
          getQuote(data, { headers, timeout: config.app.apiGatewayTimeoutMilliseconds }, policyNumber),
        );
        self.setQuote(res);
        return res;
      } catch (err) {
        /* empty */
      }
    }),
  }));

export type IQuote = Instance<typeof Quote>;
export type ICompletedMTAJourney = Instance<typeof CompletedMTAJourney>;
