import { PolicyExtras } from '../../component/common/containerMultipleLines';
import { POLICY_TYPE } from '../constant';
import { IAutoPolicy } from '../state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../state/models/dashboard/homePolicyModel';

import { SortedPolicies } from './selfService';

export const sortInPredefinedOrder = (predefinedOrderList: string[], unorderedList: PolicyExtras[]): PolicyExtras[] => {
  const orderedList: PolicyExtras[] = [];
  predefinedOrderList = Array.from(new Set(predefinedOrderList));
  for (let i = 0; i < predefinedOrderList.length; i++) {
    const itemsToOrder = unorderedList.filter((item) => item.item === predefinedOrderList[i]);
    const duplicateItems = orderedList.filter((item) => item.item === predefinedOrderList[i]);
    if (itemsToOrder.length > 0 && duplicateItems.length === 0) {
      orderedList.push(itemsToOrder[0]);
    }
  }
  return orderedList;
};

/**
 * Sorts the policies by loading failed property, so that any failed loading policies are at the end of their respective arrays
 */
const sortPolicyByLoadingFailed = (policy1: IAutoPolicy | IHomePolicy, policy2: IAutoPolicy | IHomePolicy): number => {
  return Number(policy1.loadingFailed) - Number(policy2.loadingFailed);
};

export const sortPolicies = (policies: SortedPolicies): SortedPolicies => {
  const predefinedOrderList = [POLICY_TYPE.PENDING, POLICY_TYPE.ACTIVE, POLICY_TYPE.EXPIRED];
  // Loop through all the arrays and sort them so that any failed loading policies are at the end
  for (const policiesByStatusObject of Object.values(policies)) {
    for (const policiesArray of Object.values(policiesByStatusObject)) {
      policiesArray.sort(sortPolicyByLoadingFailed);
    }
  }

  return predefinedOrderList.reduce(
    (acc: SortedPolicies, key: POLICY_TYPE) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      acc.home[key] = policies.home[key];
      acc.auto[key] = policies.auto[key];

      return acc;
    },
    { auto: {}, home: {} },
  );
};
