import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';

import { locales } from './locales/';

export const i18nLoader = (() => {
  const dateFormat: { [key: string]: string } = locales.en.core.dateFormat;

  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      defaultNS: 'core',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        format: (value: string, format) => {
          if (format) {
            if (['pricePence', 'pricePound', 'pricePoundNoDecimal'].includes(format)) {
              const prepValue: number = parseFloat(value);
              switch (format) {
                case 'pricePence':
                  return new Intl.NumberFormat('en-GB', {
                    currency: 'GBP',
                    style: 'currency',
                  }).format(prepValue / 100);

                case 'pricePoundNoDecimal':
                  return new Intl.NumberFormat('en-GB', {
                    currency: 'GBP',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                  }).format(prepValue);

                case 'pricePound':
                  return new Intl.NumberFormat('en-GB', {
                    currency: 'GBP',
                    style: 'currency',
                  }).format(prepValue);
              }
            }
            if (dateFormat[format]) {
              const checkDate = DateTime.fromISO(value);
              if (checkDate.isValid) return checkDate.toFormat(dateFormat[format]);
            }
          }
          return value;
        },
      },
      lng: 'en',
      react: {
        // This is to fix issue when Google translate is used, and then interacting with the page might cause a 'removeChild' error
        // See: https://github.com/facebook/react/issues/11538#issuecomment-390386520
        // And the bottom of https://react.i18next.com/latest/trans-component#trans-props which recommends this fix
        transWrapTextNodes: 'span',
      },
      resources: locales,
    })
    .catch((error) => {
      throw error;
    });

  return i18n;
})();
