import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { ReusableRadioPage } from '../../common/components/reusableRadioPage';
import { StepTemplate } from '../../common/components/stepTemplate';
import { useStepperContext } from '../../common/utils/stepperContext';

export const ResidentialStatus: React.FC = observer(function ResidentialStatus() {
  const {
    setTitle,
    stepsData: { reviewStep },
    setStep,
  } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const backToReviewPage = () => setStep(reviewStep);

  useEffect(() => {
    setTitle(t('MTAJourney.residentialStatus.title'));
  }, []);

  return (
    <StepTemplate>
      <ReusableRadioPage
        id={LOOKUP_TYPE_PAGE.RESIDENTIAL_STATUS}
        handleBack={backToReviewPage}
        handleSave={backToReviewPage}
        radioTitle={t('MTAJourney.residentialStatus.subtitle')}
        tooltipTitle={t('tooltips.residentialStatus')}
      />
    </StepTemplate>
  );
});
