import { useLocation } from 'react-router-dom';

import { CONTACT_FLOW_TYPE } from '../../../service/state/models/interface/chatBot';
import { useStores } from '../../../service/state/store';

interface ReturnValues {
  contactFlowType: CONTACT_FLOW_TYPE;
  customContactAttributes: Record<string, string>;
}

export type GeneralCustomAttrs =
  | 'ssHomeMainChat'
  | 'ssRenHomeBreakout'
  | 'ssHomeChange'
  | 'ssHomeClaim'
  | 'ssRenARGoAhead';

export const useChatBotAttributes: (customAttr?: GeneralCustomAttrs) => ReturnValues | undefined = (customAttr) => {
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: {
            autoRenewal,
            autoRenewalOption: { type: autoRenewalType },
          },
        },
      },
    },
  } = useStores();

  const location = useLocation();
  const dividedPath = location.pathname.split('/');
  const currentPage = dividedPath[dividedPath.length - 1];
  const params = new URLSearchParams(location.search);
  const isPolicyExpired = params.get('expired');

  const getCustomAttributes = () => {
    if (customAttr) {
      return {
        [customAttr]: 'true',
      };
    }
  };

  switch (currentPage) {
    case 'your-renewal-details':
      return {
        contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
        customContactAttributes: {
          ssRenAR: autoRenewal ? 'yes' : 'no',
          ssRenARBreakOut: 'true',
          ssRenCPA: autoRenewalType.length > 0 ? 'yes' : 'no',
        },
      };
    case 'policy':
    case 'how-to-make-a-claim':
      if (isPolicyExpired) {
        return {
          contactFlowType: CONTACT_FLOW_TYPE.MAIN,
          customContactAttributes: { ...getCustomAttributes() },
        };
      }
      return {
        contactFlowType: CONTACT_FLOW_TYPE.HOME,
        customContactAttributes: { ...getCustomAttributes() },
      };
    case 'your-quote-renewal':
      return autoRenewal
        ? {
            contactFlowType: CONTACT_FLOW_TYPE.MAIN,
            customContactAttributes: { ...getCustomAttributes() },
          }
        : undefined;
    default:
      return undefined;
  }
};
