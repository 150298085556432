import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { ReusableRadioPage } from '../../common/components/reusableRadioPage';
import { StepTemplate } from '../../common/components/stepTemplate';
import { useStepperContext } from '../../common/utils/stepperContext';

export const UseOfOtherCars: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    setTitle,
    setStep,
    stepsData: { reviewStep },
  } = useStepperContext();

  useEffect(() => {
    setTitle(t('MTAJourney.accessToOtherCars.title'));
  }, []);

  return (
    <>
      <StepTemplate description={t('MTAJourney.accessToOtherCars.description')}>
        <ReusableRadioPage
          id={LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS}
          handleBack={() => setStep(reviewStep)}
          handleSave={() => setStep(reviewStep)}
        />
      </StepTemplate>
    </>
  );
});
