import { Grid, RegistrationPlate, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { RenewalsDocumentsBtn } from '../../../../../../component/common/button/renewalsDocumentsBtn';
import { PolicyStatus } from '../../../../../../component/common/policyStatus';
import { NeedHelp } from '../../../../../../component/layout/needHelp';
import { DATE_TYPES, LOOKUP } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../../service/util/customHooks/useDecodedLookups';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';
import { RenewalsDeclineAccordion } from '../components/renewalDeclineAccordion';
import { RenewalsCommonHeadings, useHeadingStyles } from '../components/renewalsCommonHeadings';
import { WhatsMeansList } from '../components/whatMeansList';

export const useRenewalsDeclineStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .MuiList-root': {
      paddingBottom: spacing(6),
    },
    '& .bannerRenewalsDecline': {
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      [breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: spacing(-4, -2, 0, -2),
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      color: palette.secondary.contrastText,
    },
    '& .bannerRenewalsDecline, .body': {
      marginBottom: spacing(3),
    },
    '& .bold': {
      fontWeight: 'bold',
      marginBottom: spacing(1),
    },
    '& .buttonWidth': {
      [breakpoints.down('xs')]: {
        flexGrow: 1,
      },
      [breakpoints.down('md')]: {
        flexGrow: 2,
      },
    },
    '& .details': {
      flexDirection: 'column',
      rowGap: 20,
    },
    '& .download-button': {
      margin: spacing(3, 0),
    },
    '& .expiry': {
      marginBottom: spacing(3),
    },
    '& .header': {
      marginTop: spacing(3),
    },
    '& .listIcon': {
      margin: 0,
      marginLeft: -4,
      minWidth: spacing(4),
    },
    '& .listText': {
      flex: 0,
      fontSize: 16,
      marginBottom: spacing(1),
    },
    '& .policyNo': {
      marginBottom: spacing(1),
    },
    '& .policyStatus': {
      marginBottom: spacing(5),
      marginTop: spacing(1),
    },
    '& .thankYouSection': {
      marginBottom: spacing(3),
    },
    '& .title': {
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
  },
}));

export interface AccordionList {
  hasButton: boolean;
  index: number;
  label: string;
  segmentStep: string;
  value: string;
}
export interface WhatMeansList {
  icon: string;
  index: number;
  listText: string;
}

export const MotorRenewalsDecline: React.FC = observer(function MotorRenewalsDecline() {
  const [rnlCompanyDeclinedReasonMessage, setRnlCompanyDeclinedReasonMessage] = useState('');

  const classes = useRenewalsDeclineStyles();
  const titleStyle = useHeadingStyles();

  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: { policySelected },
        isHome,
      },
      fetchPolicyDocuments,
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const {
    expirationDate,
    documents,
    migrated,
    paymentPlan,
    futureMtaEffectiveDate,
    policyNumber,
    vehicles,
    policyRenewalQuoteDetails: { lastActionReasonCd },
  } = policySelected;

  const expiryDate = formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT_SPACE);

  const accordionList: AccordionList[] = t('policyManagement.yourRenewal.motorAccordionsDecline', {
    returnObjects: true,
  });
  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({
    options: ['renewal'],
  });

  const getRenewalMessage = () => {
    if (isDecodedLookupsFetched) {
      const msg = getLookup(LOOKUP.MOTOR_COMPANY_DECLINED_REASON, lastActionReasonCd);
      setRnlCompanyDeclinedReasonMessage(msg);
    }
  };

  useEffect(() => {
    getRenewalMessage();
  }, [isDecodedLookupsFetched]);

  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

  useEffect(() => {
    eventTrack('Motor Renewal - decline page shown', {
      policyNumber,
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  useEffect(() => {
    if (!documents.length) {
      fetchPolicyDocuments(policyNumber, futureMtaEffectiveDate);
    }
  }, [policySelected]);

  const getDeclinedReasonMsg = () => {
    if (lastActionReasonCd === '') {
      return `${t('policyManagement.yourRenewal.unableToRenewText')}`;
    } else {
      return rnlCompanyDeclinedReasonMessage;
    }
  };

  return (
    <Grid container direction="column">
      <BlueBanner title={t('policyManagement.yourRenewal.title')} />
      <Grid item xs={12} md={6}>
        <Grid container direction="column" className={clsx(classes.root)}>
          <RenewalsCommonHeadings expiryDate={expiryDate} policyNumber={policyNumber} />

          <Typography className="bold" variant="body1">
            {`${vehicles[0].make} ${vehicles[0].model}`}
          </Typography>
          <RegistrationPlate active label={vehicles[0].vrm} />
          {remainingDays < 1 && remainingDays >= 0 && (
            <PolicyStatus
              className="policyStatus"
              label={t('policyManagement.yourRenewal.expiresToday')}
              status="expired"
            />
          )}
          {remainingDays >= 1 &&
            (remainingDays === 1 ? (
              <PolicyStatus
                className="policyStatus"
                label={t('policyManagement.yourRenewal.oneDay')}
                status="expired"
              />
            ) : (
              <PolicyStatus
                className="policyStatus"
                label={t('policyManagement.yourRenewal.moreThanOneDay', { value: remainingDays })}
                status="expired"
              />
            ))}
          <Typography className="body" variant="body1">
            {getDeclinedReasonMsg()}
          </Typography>
          {(isDDCustomer || migrated) && (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: migrated
                  ? t('policyManagement.yourRenewal.unableToRenewTextMigrated')
                  : t('policyManagement.yourRenewal.unableToRenewTextDD'),
              }}
            />
          )}
          {(migrated || isDDCustomer) && (
            <>
              <Grid className="download-button">
                <RenewalsDocumentsBtn
                  isDeclineButton={true}
                  policy={policySelected}
                  fileName={t('policyManagement.yourRenewal.scheduleOfInsurance')}
                  fullWidth
                ></RenewalsDocumentsBtn>
              </Grid>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: t('policyManagement.yourRenewal.unableToRenewTextMigratedAndDD.afterButton'),
                }}
              />
            </>
          )}
          <Typography className={titleStyle.sectionTitle} variant="h2">
            {t('policyManagement.yourRenewal.secondaryTitle')}
          </Typography>

          <WhatsMeansList expiryDate={expiryDate} isHomePolicy={isHome} />

          {(migrated ?? isDDCustomer) && (
            <Typography className="thankYouSection" variant="body1">
              {t('policyManagement.yourRenewal.thankYouMessage', { value: expiryDate })}
            </Typography>
          )}
          {!migrated &&
            !isDDCustomer &&
            accordionList.map(({ value, label, hasButton, segmentStep }, index) => {
              return (
                <RenewalsDeclineAccordion
                  key={label}
                  expiryDate={expiryDate}
                  hasButton={hasButton}
                  label={label}
                  segmentStep={segmentStep}
                  value={value}
                  index={index}
                />
              );
            })}
          {(isDDCustomer || migrated) && (
            <Typography variant="body1" data-testid="opening-hours">
              {t('policyManagement.yourRenewal.ifYouNeed')}
            </Typography>
          )}
          <NeedHelp showClaimButton={false} />
        </Grid>
      </Grid>
    </Grid>
  );
});
