import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../service/state/store';
import { removeCompletedJourneyByTitle } from '../../../../../../../service/util/multiMTAUtil';
import { ItemList, NavigationLayout } from '../common/components/navigationLayout';
import { useStepperContext } from '../common/utils/stepperContext';

export const CarDetails: React.FC = observer(function CarDetails() {
  const { t } = useTranslation('myAccount');
  const cards: ItemList[] = t('changeCarDetails.cards', { returnObjects: true });

  const { isMultiMTA } = useStepperContext();

  const {
    mtaStore: { completedMTAJourneys },
  } = useStores();

  if (isMultiMTA) removeCompletedJourneyByTitle(cards, completedMTAJourneys);

  return (
    <NavigationLayout
      mtaType="changeCarDetails"
      cards={cards}
      banner={t('changeCarDetails.banner')}
      title={t('changeCarDetails.title')}
      section={t('policyDetailsSection.carDetails')}
    />
  );
});
