import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';

import IconSure from './icon';

const useStyles = makeStyles(() => ({
  root: {
    '& .align-items': {
      alignItems: 'flex-start',
    },
    '& .justify-content': {
      justifyContent: 'flex-start',
    },
    '& .margin-icon': {
      marginTop: '5px',
      minWidth: 0,
    },
  },
}));

export interface AccordionStandardList {
  content: string;
  title?: string;
}

export interface IconListSectionProps {
  className?: string;
  color?: string;
  icon: string;
  list: AccordionStandardList[];
}

export const IconListSection: React.FC<IconListSectionProps> = ({ className, color = 'primary', icon, list }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {list.map((item, index) => (
        <ListItem key={index} className={clsx(className, 'justify-content align-items')}>
          <ListItemIcon className="margin-icon">
            <IconSure color={color} icon={icon} />
          </ListItemIcon>
          <ListItemText primary={item.title} secondary={item.content} />
        </ListItem>
      ))}
    </List>
  );
};
