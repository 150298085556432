import {
  FormHelperText,
  Grid,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TextField,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import LuxonUtils from '@date-io/luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { getDateTimeNow } from '../../../../../../../../service/util/dateUtils';
import { EditFooter } from '../components/editFooter';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .date-picker': {
      '& > div': {
        paddingRight: 0,
      },
      '& span': {
        color: palette.secondary.main,
      },
      margin: 0,
    },
    '& .time-picker': {
      width: '100%',
    },
    display: 'grid',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    gap: spacing(3),
    marginTop: spacing(3),
  },
}));

export const EditStartDate: React.FC<EditPageProps> = observer(function EditStartDate({ handleBack }) {
  const classes = useStyles();
  const {
    interfaceStore: { isMobile },
    mtaStore: {
      pendedMTA: { setEndorsementDate: setStartDate, endorsementDate: startDate },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { setTitle } = useStepperContext();

  const currentTime = getDateTimeNow().toFormat('HH:mm');
  const [time, setTime] = useState(currentTime);
  const [timeError, setTimeError] = useState(false);
  const intialDate = startDate ?? getDateTimeNow();
  const [date, setDate] = useState(intialDate);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    setTitle(t('MTAJourney.StartDate.title'));
    setTime(date.toLocaleString(DateTime.TIME_SIMPLE));
  }, []);

  const handleChangeStartDate = (value: MaterialUiPickersDate | null) => {
    if (value) {
      setDate(value.set({ hour: 0, minute: 0 }));

      if (value.day === intialDate.day && value.month === intialDate.month && value.year === intialDate.year) {
        setTime(currentTime);
      } else {
        setTime('00:00');
      }
    } else {
      setDate(getDateTimeNow());
    }
  };

  /* istanbul ignore next */
  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dates: string[] = event.target.value.split(':');
    setDate(date.set({ hour: parseInt(dates[0]), minute: parseInt(dates[1]) }));
    setTime(event.target.value);
  };
  const isValid = (): boolean => {
    return dateError || timeError ? false : true;
  };

  const handlePendedMta = () => {
    isValid() && setStartDate(date);
  };
  const handleSaveClick = () => {
    handlePendedMta();
    handleBack();
  };

  useEffect(() => {
    if (
      date.day === intialDate.day &&
      date.month === intialDate.month &&
      date.year === intialDate.year &&
      time < currentTime
    ) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  }, [date, time]);

  const handleBackClick = () => {
    handleBack();
  };

  const setMaxDate = () => {
    const now = getDateTimeNow();
    return now.plus({ days: 30 });
  };

  return (
    <Grid className={classes.root} item xs={isMobile ? 12 : 6}>
      <Typography variant="body1" className="subtitle">
        {t('MTAJourney.StartDate.description')}
      </Typography>

      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <KeyboardDatePicker
          disableToolbar
          value={date}
          minDate={getDateTimeNow()}
          className="date-picker"
          format="dd/MM/yyyy"
          onChange={handleChangeStartDate}
          variant="inline"
          autoOk
          label={t('MTAJourney.StartDate.startDate')}
          color="secondary"
          inputVariant="outlined"
          views={['date', 'month', 'year']}
          onError={(err) => ((err as string).length > 0 ? setDateError(true) : setDateError(false))}
          minDateMessage={t('validations.invalidDate')}
          maxDate={setMaxDate()}
          maxDateMessage={t('validations.beyond30days')}
        />
      </MuiPickersUtilsProvider>
      <Grid>
        <TextField
          className="time-picker"
          type="time"
          label={t('MTAJourney.StartDate.time')}
          value={time}
          onChange={handleChangeTime}
        />
        {timeError && <FormHelperText error>{t('validations.invalidTime')}</FormHelperText>}
      </Grid>

      <EditFooter id="editStartDate" validPage={isValid()} handleSave={handleSaveClick} handleBack={handleBackClick} />
    </Grid>
  );
});
