import { Drawer, Grid, Hidden, IconCross, Typography } from '@esure-cloud/react-components';

import { Box, Link, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { THEME_NAME } from '../../service/constant';
import { RouteData } from '../../service/routeConfig';
import { useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';
import { isChildOfSpecifiedParent } from '../../service/util/routesUtils';

import { Logo } from './logo';
import { OffersNavlink } from './offersNavlink';

export interface SidebarProps {
  handleDrawerToggle: () => void;
  handleLogout: () => void;
  open: boolean;
  routes: RouteData[];
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  activeCursor: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1.5),
    },
    background: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(5),
    width: theme.spacing(0.5),
  },
  activeNavLink: {
    background:
      theme.themeName === THEME_NAME.SHEILASWHEELS || theme.themeName === THEME_NAME.FIRSTALTERNATIVE
        ? theme.palette.primary.light
        : `${theme.palette.secondary.dark}50`,
  },
  closeIcon: {
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(2.5, 2, 0, 2.5),
  },
  closeMenu: {
    marginTop: theme.spacing(2.5),
  },
  drawerPaper: {
    [theme.breakpoints.down('xs')]: {
      filter: 'drop-shadow(110px 0px 100px rgba(0, 0, 0, 0.35))',
      flexShrink: 0,
      minWidth: 175,
      width: '75vw',
    },
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: 175,
    },
    background:
      theme.themeName === THEME_NAME.SHEILASWHEELS || theme.themeName === THEME_NAME.FIRSTALTERNATIVE
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    border: 'unset',
    maxWidth: 'unset',
    overflowY: 'unset',
    padding: 'unset',
    zIndex: 998,
  },
  listContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4.5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2.5),
    },
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  listItemActive: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  listItemNonactive: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  logButton: {
    textAlign: 'left',
    width: '100%',
  },
  logoContainer: { height: 75 },
  logoutDesktop: {
    marginTop: 'auto',
  },
  navLink: {
    display: 'flex',
    padding: theme.spacing(2.5, 0),
    textDecoration: 'none',
    width: '100%',
  },
  nonActiveNavLink: {
    background:
      theme.themeName === THEME_NAME.SHEILASWHEELS || theme.themeName === THEME_NAME.FIRSTALTERNATIVE
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  },
  secondaryAction: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    margin: theme.spacing(0, 4, 0),
    padding: theme.spacing(2.5, 0),
  },
  typographyFont: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    color: theme.palette.secondary.contrastText,
  },
  userInfo: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4.5, 6, 0, 2.5),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3.5, 2.5, 0, 2.5),
    },
    color: theme.palette.secondary.contrastText,
  },
}));

export const Sidebar: React.FC<SidebarProps> = observer(function Sidebar({
  handleDrawerToggle,
  open,
  routes,
  setOpen,
  handleLogout,
}) {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: { customer },
    interfaceStore: { isMobile },
  } = useStores();
  const location = useLocation();

  const menuSelectedItem = (menuItem: string): boolean => {
    return location.pathname === menuItem ? true : isChildOfSpecifiedParent(menuItem, location.pathname);
  };

  const links = (routes: RouteData[], isMobileView: boolean) => {
    return (
      <List className={classes.listContainer}>
        {routes.map((route, index) => {
          return (
            ((isMobileView && route.showInSidebar) || (!isMobileView && route.showInSidebar)) && (
              <NavLink
                data-testid="navlink"
                activeClassName="active"
                className={clsx(
                  classes.navLink,
                  menuSelectedItem(route.path) ? classes.activeNavLink : classes.nonActiveNavLink,
                )}
                key={`route-${index}`}
                to={route.path}
                onClick={() => {
                  eventTrack('Sidebar Link Clicked', { item: route.path });
                  if (isMobile) handleDrawerToggle();
                }}
              >
                {menuSelectedItem(route.path) && <Box className={classes.activeCursor} key={`cursor-${index}`} />}
                <Typography
                  className={clsx(
                    classes.typographyFont,
                    menuSelectedItem(route.path) ? classes.listItemActive : classes.listItemNonactive,
                  )}
                  key={`typography-${index}`}
                  data-testid={`sidebar-nav-${route.name ?? ''}`}
                >
                  {t(route.name ?? '')}
                </Typography>
              </NavLink>
            )
          );
        })}
        <OffersNavlink onClick={() => handleDrawerToggle()} isMobile={isMobile} />
      </List>
    );
  };

  const drawer = (isMobileView: boolean) => {
    return (
      <>
        <Grid container className={classes.logoContainer} justifyContent="center" alignItems="center">
          <Logo size={95} />
        </Grid>
        <Typography className={classes.userInfo} variant={isMobileView ? 'h2' : 'h3'}>
          {customer.fullName}
        </Typography>
        {links(routes, isMobileView)}
      </>
    );
  };

  return (
    <nav>
      <Hidden implementation="css" mdUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          variant="temporary"
          data-testid="drawer"
        >
          <Box display="flex">
            <IconCross className={classes.closeIcon} onClick={handleDrawerToggle} />
            <Typography className={clsx(classes.closeMenu, classes.typographyFont)}>
              {t('sidebar.closeMenu')}
            </Typography>
          </Box>
          {drawer(true)}
          <Box>
            <Link>
              <Typography className={clsx(classes.typographyFont, classes.listItemNonactive)} onClick={handleLogout}>
                {t('sidebar.logout')}
              </Typography>
            </Link>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden implementation="css" smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          variant="permanent"
        >
          {drawer(false)}
          <Box className={classes.logoutDesktop}>
            <Link component="button" onClick={handleLogout} className={classes.logButton}>
              <Typography className={clsx(classes.typographyFont, classes.secondaryAction)} data-testid="logout-text">
                {t('sidebar.logout')}
              </Typography>
            </Link>
          </Box>
        </Drawer>
      </Hidden>
    </nav>
  );
});
