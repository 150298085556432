import { useTheme } from '@esure-cloud/react-components';

import iconMap from './icons';

export type IconType = 'line' | 'solid';
export type IconList = Record<string, string>;
export type IconMap = { [key in IconType]: IconList };

export interface IconSureProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  color?: string;
  icon: string;
  size?: number | string;
  strokeWidth?: number | string;
  type?: IconType;
}

const IconSure: React.FC<IconSureProps> = (props) => {
  const { icon, color = 'white', size = 24, type = 'line', strokeWidth = 2, ...rest } = props;

  const theme = useTheme();

  const d = iconMap[type][icon];
  if (!d) {
    throw new Error(`Icon '${icon}' doesn't exists in IconSure`);
  }

  const getColor = () => {
    switch (color) {
      case 'primary':
        return theme.palette.primary.main;
      case 'secondary':
        return theme.palette.secondary.main;
      case 'error':
        return theme.palette.error.main;
      case 'error-dark':
        return theme.palette.error.dark;
      case 'warning':
        return theme.palette.warning.main;
      case 'success':
        return theme.palette.success.main;
      case 'dark':
        return theme.palette.common.black;
      case 'grey800':
        return theme.palette.grey[800];
      case 'grey700':
        return theme.palette.grey[700];
      default:
        return color;
    }
  };

  const Solid = () => <path d={d} fill={getColor()} />;

  const Line = () => (
    <path
      d={d}
      fill="none"
      stroke={getColor()}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
  );

  const IconPath = () => {
    switch (type) {
      case 'solid':
        return <Solid />;
      default:
        return <Line />;
    }
  };

  return (
    <svg data-testid="icon-svg" height={size} width={size} viewBox="0 0 24 24" {...rest}>
      <IconPath />
    </svg>
  );
};

export default IconSure;
