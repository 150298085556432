import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { PolicyPageSkeleton } from '../../../../component/layout/skeletons/policySkeleton';
import { trackSatismeter } from '../../../../lib/satismeter';
import { useStores } from '../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../service/util/customHooks/useSegment';
import { MotorPolicyDetails } from '../autoPolicy';
import { HomePolicyDetails } from '../homePolicy';

export const PolicyDetails: React.FC = observer(function PolicyDetails() {
  const { eventTrack } = useSegment();

  const {
    dashboardStore: {
      insurance: { isHome },
    },
    interfaceStore: { isLoading, pagesViewed },
  } = useStores();

  useEffect(() => {
    eventTrack('Policy Viewed', {
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  useEffect(() => {
    /* Satismeter Call
    ------------------
    conditions
    The customer is on the policy dashboard screen

    The customer has visited at least [2] pages
     */
    const enoughPagesVisited = pagesViewed > 2;
    /* istanbul ignore next */
    if (enoughPagesVisited) {
      trackSatismeter('SME-Show General Digital Ease Survey');
      eventTrack('Show General Digital Ease Survey Event Fired');
    }
  }, []);

  if (isLoading) {
    return <PolicyPageSkeleton />;
  } else {
    return isHome ? <HomePolicyDetails /> : <MotorPolicyDetails />;
  }
});
