import { observer } from 'mobx-react-lite';
import Iframe from 'react-iframe';

import { url } from '../../../../service/config';
import { useStores } from '../../../../service/state/store';
import { sendJwtToIframe } from '../../../../service/util/jwtUtils';

export const LegalProtectionCovered: React.FC = observer(function ActiveClaim() {
  const {
    interfaceStore: { accessToken },
  } = useStores();

  const headers = new Headers();
  headers.append('X-Frame-Options', 'SAMEORIGIN');
  headers.append(
    'Content-Security-Policy',
    "frame-ancestors 'self' https://d-eclaim-dash.digitaltesting.co.uk/ https://s-eclaim-dash.digitaltesting.co.uk/ https://eclaim-dash.esure.com/",
  );

  fetch(`${url.claimDashboardUrl}/legal-protection-covered`, {
    headers: headers,
  })
    .then((response) => {
      if (response.ok) {
        // eslint-disable-next-line no-console
        console.log('Headers added to URL');
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });

  return (
    <>
      <Iframe
        url={`${url.claimDashboardUrl}/legal-protection-covered`}
        id="legal-protection-covered-claim-iframe"
        title="legal-protection-covered-claim-iframe"
        width="100%"
        height="100%"
        position="relative"
        frameBorder={0}
        onLoad={() => {
          sendJwtToIframe('legal-protection-covered-claim-iframe', accessToken);
        }}
      />
    </>
  );
});
