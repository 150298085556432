import { DateTime as DateTimeLuxon } from 'luxon';
import { Instance, types } from 'mobx-state-tree';

export const DateTime = types.maybeNull(
  types.custom<string, DateTimeLuxon>({
    fromSnapshot(value: string) {
      return DateTimeLuxon.fromISO(value);
    },
    getValidationMessage(value: string) {
      if (DateTimeLuxon.fromISO(value).isValid) return '';
      return `expected ISO parsable date, but found '${value}'`;
    },
    isTargetType(value: unknown) {
      return DateTimeLuxon.isDateTime(value);
    },
    name: 'DateTime',
    toSnapshot(value: DateTimeLuxon) {
      return value.toISO();
    },
  }),
);
export type IDateTime = Instance<typeof DateTime>;
