import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { useStores } from '../../state/store';

interface DocumentsFetchReturn {
  hasDocsLoadingFailed: boolean;
  hasFetched: boolean;
}

export const useRenewalDocumentsFetch = (): DocumentsFetchReturn => {
  const [hasDocsLoadingFailed, setHasDocsLoadingFailed] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const {
    dashboardStore: {
      fetchPolicyRenewalDocuments,
      insurance: { auto, home, isHome },
    },
  } = useStores();
  const policySelected = isHome ? home.policySelected : auto.policySelected;

  const {
    renewalDocuments,
    policyNumber,
    policyRenewalQuoteDetails: { startDate },
  } = policySelected;

  const effectiveDate = startDate ? DateTime.fromISO(startDate.toString()) : null;

  useEffect(() => {
    const getDocuments = async () => {
      /* istanbul ignore next */
      if (!renewalDocuments.length) {
        try {
          const res = await fetchPolicyRenewalDocuments(policyNumber, effectiveDate, 'Quote');
          setHasFetched(true);
          if (res?.status !== 200) {
            setHasDocsLoadingFailed(true);
          }
        } catch (error) {
          setHasDocsLoadingFailed(true);
        }
      } else {
        setHasFetched(true);
      }
    };
    getDocuments();
  }, [policySelected]);

  return {
    hasDocsLoadingFailed,
    hasFetched,
  };
};
