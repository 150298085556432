import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DATE_TYPES, POLICY_STATUS_CODE, ROUTE } from '../../../../../../service/constant';
import { IHomePolicy } from '../../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';
import { postCodeFormatter } from '../../../../../../service/util/stringUtils';

import { RenewalAlertTemplate } from './renewalAlert';

export const HomeRenewalNotification: React.FC<{ hasLabel?: boolean; policy: IHomePolicy }> = observer(
  ({ policy, hasLabel }) => {
    const {
      autoRenewal,
      expirationDate,
      policyNumber,
      policyRenewalQuoteDetails: { renewalPolicyStatusCode },
      homeAddress: { street, houseNumber, postcode },
      paymentPlan,
    } = policy;
    const { t } = useTranslation('myAccount');
    const { eventTrack } = useSegment();

    const {
      dashboardStore: {
        insurance: {
          home: { setSelectedPolicyNumber },
          setSelectedPolicyType,
        },
      },
      interfaceStore: { hasFeatureFlag },
    } = useStores();
    const history = useHistory();
    const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;
    const address = `${houseNumber} ${street}`;
    const showNotification = remainingDays <= 32 && remainingDays >= 0;
    const companyDeclined = renewalPolicyStatusCode === POLICY_STATUS_CODE.COMPANY_DECLINED;
    const formattedDate = formatDate(
      SelfService.calculatePolicyEndDate(expirationDate).toString(),
      DATE_TYPES.SHORT_SPACE,
    );
    const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

    const handleClick = () => {
      setSelectedPolicyNumber(policyNumber);
      setSelectedPolicyType(policy);
      if (renewalPolicyStatusCode === POLICY_STATUS_CODE.PROPOSED) {
        history.push(ROUTE.RENEWAL_QUOTE_DETAILS);
      } else if (renewalPolicyStatusCode !== '') {
        history.push(ROUTE.YOUR_RENEWAL_DECLINE);
      }
      eventTrack('Home Rnwl | Important info notification clicked', {
        type: TRACK_TYPE.LINK,
      });
    };

    const shouldNotificationShow = () => {
      if (showNotification && !isDDCustomer && hasFeatureFlag('showHomeRenewalNotification - annual')) {
        return true;
      } else if (showNotification && isDDCustomer && hasFeatureFlag('showHomeRenewalNotification - DD')) {
        return true;
      } else {
        return false;
      }
    };

    const linkText = (): string => {
      if (hasFeatureFlag('newRenewalMta')) {
        return t(companyDeclined ? 'expiryNoticeNew.companyDeclined.quoteLink' : 'expiryNoticeNew.quoteLink');
      } else {
        return t(companyDeclined ? 'expiryNotice.companyDeclined.quoteLink' : 'expiryNotice.quoteLink');
      }
    };

    const getTitle = (): string => {
      let title = '';

      /* istanbul ignore next */
      if (hasFeatureFlag('newRenewalMta')) {
        switch (true) {
          case remainingDays > 10 && renewalPolicyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED:
            title = t('expiryNoticeNew.autoRenewalOff.willEndInMoreThan10daysDeclined');
            break;
          case remainingDays === 0 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.sameDay', {
              policyID: address,
            });
            break;
          case remainingDays === 1 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'day',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'days',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              policyID: address,
              value: remainingDays,
            });
            break;
          case companyDeclined:
            title = t('expiryNoticeNew.companyDeclined.title', { date: formattedDate, policyID: address });
            break;
          case remainingDays === 0 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.sameDay', {
              date: formattedDate,
              policyID: address,
            });
            break;
          case remainingDays === 1 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'day',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'days',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              policyID: address,
              value: remainingDays,
            });
            break;
        }
      } else {
        switch (true) {
          case remainingDays === 0 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.sameDay', {
              insuranceType: 'home',
              policyID: address,
            });
            break;
          case remainingDays === 1 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.oneDay', {
              date: formattedDate,
              insuranceType: 'home',
              policyID: address,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'home',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'home',
              policyID: address,
              value: remainingDays,
            });
            break;
          case companyDeclined:
            title = t('expiryNotice.companyDeclined.title', { date: formattedDate, policyID: address });
            break;
          case remainingDays === 0 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.sameDay', {
              date: formattedDate,
              insuranceType: 'home',
              policyID: address,
            });
            break;
          case remainingDays === 1 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.oneDay', {
              date: formattedDate,
              insuranceType: 'home',
              policyID: address,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'home',
              policyID: address,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'home',
              policyID: address,
              value: remainingDays,
            });
            break;
        }
      }
      return title;
    };

    return (
      <RenewalAlertTemplate
        title={getTitle()}
        linkText={linkText()}
        handleClick={handleClick}
        showNotification={shouldNotificationShow()}
        showRenewalLink={renewalPolicyStatusCode !== POLICY_STATUS_CODE.CUSTOMER_DECLINED}
        vrm={hasLabel ? postCodeFormatter(postcode) : null}
        autoRenewal={autoRenewal}
        formattedDate={formattedDate}
        remainingDays={remainingDays}
        savedQuoteLinkText={t('expiryNoticeNew.savedQuoteLink')}
        showSavedQuoteLink={false}
        policyStatusCode={renewalPolicyStatusCode}
      />
    );
  },
);
