import { Grid, GridProps, Typography, darken, lighten, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { THEME_NAME } from '../../service/constant';

export interface PolicyStatusProps extends GridProps {
  label?: string;
  status: string;
}

const useStyles = makeStyles(({ palette, spacing, themeName }) => ({
  root: {
    '&.active, &.included, &.protected, &.verified': {
      background: themeName === THEME_NAME.SHEILASWHEELS ? palette.success.main : lighten(palette.success.light, 0.6),
      color: themeName === THEME_NAME.SHEILASWHEELS ? palette.common.white : darken(palette.success.main, 0.6),
    },
    '&.expired, &.lapsed, &.cancelled, &.not-protected, &.unverified': {
      background: palette.error.main,
      color: palette.common.white,
    },
    '&.pending': {
      background: palette.warning.light,
      color: palette.warning.contrastText,
    },
    borderRadius: spacing(1),
    height: 26,
    maxWidth: 'fit-content',
    padding: spacing(0, 1),
  },
}));

export const PolicyStatus: React.FC<PolicyStatusProps> = ({ className, label, status, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={clsx(classes.root, className, status.toLowerCase())}
      container
      item
      justifyContent="center"
      {...rest}
      data-testid="box"
    >
      <Typography align="center" variant="h5">
        {label ?? status}
      </Typography>
    </Grid>
  );
};
