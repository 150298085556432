import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const ChangeRelationshipStatusRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: {
        insured: { person },
        setInsuredPerson,
      },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [value, setValue] = useState(person.maritalStatusCd);
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.MARITAL_STATUS],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[LOOKUP.MARITAL_STATUS]);
    }
  }, [isLookupsFetched, lookupsByName]);

  useEffect(() => {
    setTitle(t('MTAJourney.relationshipStatus.title'));
  }, []);

  /* istanbul ignore next */
  const handleContinue = () => {
    setIsSubmitting(true);
    setInsuredPerson({ ...person, maritalStatusCd: value });
    if (value === '') return;
    nextStep();
  };

  return (
    <>
      {isLookupsFetched && (
        // t('MTAJourney.driverMaritalStatus.description')

        <>
          <Typography variant="body1">{t('MTAJourney.relationshipStatus.radioTitle')}</Typography>

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      {value === '' && isSubmitting && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}

      <RenewalChangeFooter
        cancelButtonId={COMPONENT_ID.RENEWAL_YOUR_RELATIONSHIP_STATUS_CANCEL}
        backButtonId={COMPONENT_ID.RENEWAL_YOUR_RELATIONSHIP_STATUS_BACK}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_YOUR_RELATIONSHIP_STATUS_CONTINUE, handleContinue }}
      />
    </>
  );
});
