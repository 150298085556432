import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { DIALOG_TYPE } from '../../../service/constant';
import { useSegment } from '../../../service/util/customHooks/useSegment';
import IconSure from '../../common/icon';

import { DialogTemplate } from './dialogTemplate';

export const FrequentIssues: React.FC<{ issue: DIALOG_TYPE }> = ({ issue }) => {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const getDialogContent = () => {
    switch (issue) {
      case DIALOG_TYPE.BROKEN_GLASS:
        return (
          <DialogTemplate
            topImage={<SVGWrapper alt="icon" height="80%" width="80%" src="chipped-or-broken-glass.svg" />}
            visibleCloseIcon={true}
            title={t('frequentIssues.brokenGlass.title')}
            testid="dialog-content-frequent-issues"
            mainContent={t('frequentIssues.brokenGlass.mainContent')}
            buttons={[
              <Button
                color="primary"
                variant="contained"
                target="_blank"
                href="https://www.autoglass.co.uk/insurers/esure/"
                onClick={() => eventTrack('Accident Recovery - Call 0345 603 7872 - Button Clicked')}
              >
                {t('frequentIssues.brokenGlass.button')}
              </Button>,
            ]}
          />
        );
      case DIALOG_TYPE.LOST_KEYS:
        return (
          <DialogTemplate
            topImage={<img alt="icon" height="80%" width="80%" src="./assets/images/lost-or-stolen-keys.svg" />}
            title={t('frequentIssues.lostKeys.title')}
            visibleCloseIcon={true}
            testid="dialog-content-frequent-issues"
            mainContent={t('frequentIssues.lostKeys.mainContent')}
            buttons={[
              <Button
                color="primary"
                variant="contained"
                href={`tel: ${t('frequentIssues.lostKeys.button')}`}
                startIcon={<IconSure icon="phone" color="white" type="solid" size="20" />}
                onClick={() => eventTrack('Lost or Stolen Keys - Call  0800 085 4913 - Button Clicked')}
              >
                {t('frequentIssues.lostKeys.button')}
              </Button>,
            ]}
          />
        );
      case DIALOG_TYPE.ACCIDENT_RECOVERY:
        return (
          <DialogTemplate
            topImage={<SVGWrapper alt="icon" height="80%" width="80%" src="./assets/images/accident-recovery.svg" />}
            visibleCloseIcon={true}
            title={t('frequentIssues.accidentRecovery.title')}
            testid="dialog-content-frequent-issues"
            mainContent={t('frequentIssues.accidentRecovery.mainContent')}
            buttons={[
              <Button
                color="primary"
                variant="contained"
                href={`tel: ${t('frequentIssues.accidentRecovery.button')}`}
                startIcon={<IconSure icon="phone" color="white" type="solid" />}
                onClick={() => eventTrack('Chipped or broken glass - Got to Autoglass - Button Clicked')}
              >
                {t('frequentIssues.accidentRecovery.button')}
              </Button>,
            ]}
          />
        );
      default:
        return <></>;
    }
  };
  return getDialogContent();
};
