import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { PersonName } from '../../yourDetails/components/personName';

export const DriverNameAddDriver: React.FC = observer(() => {
  const {
    mtaStore: {
      pendedMTA: {
        selectedDriver: { person },
      },
    },
  } = useStores();

  const { t } = useTranslation('myAccount');

  return <PersonName title={t('MTAJourney.driverName.title')} disableFirstName={false} person={person} />;
});
