import { Typography } from '@esure-cloud/react-components';

import React, { useEffect, useState } from 'react';

export interface TimerProps {
  completedCounter: () => void;
  seconds: number;
}
export const Timer: React.FC<TimerProps> = ({ seconds, completedCounter }) => {
  const [timer, setTimer] = useState(seconds);

  useEffect(() => {
    if (timer > 0) {
      const time = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => {
        clearTimeout(time);
      };
    } else {
      completedCounter();
    }
  }, [timer]);

  let mins = 0;
  let secs = 0;
  let remainingTime = timer;
  while (remainingTime >= 60) {
    remainingTime -= 60;
    mins++;
  }
  secs = remainingTime;

  const minsDisplay = mins <= 9 ? `0${mins}` : `${mins}`;
  const secsDisplay = secs <= 9 ? `0${secs}` : `${secs}`;

  return <Typography variant="h3">{`${minsDisplay}:${secsDisplay}`}</Typography>;
};
