import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { camelCaseString } from '../../../service/analytics';
import { url } from '../../../service/config';
import { BUTTON_ORIGIN } from '../../../service/constant';
import { CONTACT_FLOW_TYPE, StartChatConfig } from '../../../service/state/models/interface/chatBot';
import { useStores } from '../../../service/state/store';
import { useSegment } from '../../../service/util/customHooks/useSegment';
import { useMTAChatBotAttributes } from '../utils/useMTAChatBotAttributes';

import { BtnProps } from './cancelBtn';
import { CommonButton } from './commonButton';

export const ContinueBtn: React.FC<BtnProps> = observer(function ContinueBtn({ origin, customAttr, text, ...props }) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      hasFeatureFlag,
      chatBot: { startChat },
      dialog: { closeDialog, chatbotAction },
    },
    userStore: {
      user: { email },
    },
    dashboardStore: {
      insurance: { isHome },
    },
  } = useStores();
  const { eventTrack } = useSegment();

  const chatBotAttr = useMTAChatBotAttributes(customAttr);

  const startChatWithTimeout = (config: StartChatConfig, timeout = 0) => setTimeout(() => startChat(config), timeout);

  const openMTAChatBot = () => {
    closeDialog();
    startChatWithTimeout({
      contactFlowType: CONTACT_FLOW_TYPE.MTA,
      customContactAttributes: { ssMTAType: chatbotAction },
      initiator: origin,
      ...(chatBotAttr ?? {}),
    });
  };

  const openPaymentChatBot = () => {
    eventTrack('Mta payment chat init');
    closeDialog();
    startChatWithTimeout({
      initiator: '',
      ...(chatBotAttr ?? {}),
    });
  };

  const openChatBot = (attr: Record<string, string>, contactFlowType?: CONTACT_FLOW_TYPE) => {
    closeDialog();
    startChatWithTimeout({
      contactFlowType: contactFlowType ?? CONTACT_FLOW_TYPE.MAIN,
      customContactAttributes: {
        ...attr,
      },
      initiator: origin,
    });
  };

  const handleClick = () => {
    switch (origin) {
      case BUTTON_ORIGIN.CANCEL_POLICY:
      case BUTTON_ORIGIN.FETCH_ADVISER_MTA:
        !isHome ? openMTAChatBot() : openChatBot({ ssHomeChange: 'true' }, CONTACT_FLOW_TYPE.HOME);
        break;
      case BUTTON_ORIGIN.FETCH_ADVISER_DELETE_CARD:
        openChatBot({ ssRenChaCardRem: 'true' }, CONTACT_FLOW_TYPE.CPA_AR);
        break;
      case BUTTON_ORIGIN.FETCH_ADVISER_ADD_CARD:
        openChatBot({ ssRenChaCardAdd: 'true' }, CONTACT_FLOW_TYPE.CPA_AR);
        break;
      case BUTTON_ORIGIN.FETCH_ADVISER_RENEWAL_QUOTE:
        openChatBot(
          {
            ssRenQuoteUpdatePol: 'true',
          },
          CONTACT_FLOW_TYPE.CPA_AR,
        );
        break;
      case BUTTON_ORIGIN.FETCH_ADVISER_MTA_REN:
        openChatBot(
          {
            ssRenQuoteUpdatePol: 'true',
          },
          CONTACT_FLOW_TYPE.CPA_AR,
        );
        break;
      case BUTTON_ORIGIN.FETCH_ADVISER_RENEWAL_QUOTE_PAY:
        if (customAttr === 'ssRenARGoAhead') {
          openChatBot(
            {
              ssRenARGoAhead: 'true',
            },
            CONTACT_FLOW_TYPE.MAIN,
          );
        } else {
          openChatBot(
            hasFeatureFlag('BCCJM-9000')
              ? {
                  ssRenARBreakOut: 'true',
                }
              : {
                  ssRenQuoteAccept: 'true',
                },
            CONTACT_FLOW_TYPE.CPA_AR,
          );
        }
        break;
      case BUTTON_ORIGIN.ASSISTED_PAYMENT:
        openPaymentChatBot();
        break;
      case BUTTON_ORIGIN.TIA_POLICY:
        window.open(`${url.tiaLogin}/${email}`, '_blank');
        closeDialog();
        break;
      case BUTTON_ORIGIN.NONE:
      case BUTTON_ORIGIN.CANCEL_CONFIRMATION:
        break;
      case BUTTON_ORIGIN.RENEWAL_MTA_UNAVAILABLE_ONLINE:
        openMTAChatBot();
        break;
    }
  };

  return (
    <CommonButton
      {...props}
      color="primary"
      variant="contained"
      text={text ?? t('cancelPolicyPopup.open.continueBtn')}
      onClick={handleClick}
      data-testid={`${camelCaseString(origin)}Continue`}
      size="large"
    />
  );
});
