import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../component/common/icon';
import { WhatMeansList } from '../motor/motorRenewalsDecline';

export interface WhatMeansListExpiryDateProp {
  expiryDate: string;
  isHomePolicy: boolean;
}

export const WhatsMeansList: React.FC<WhatMeansListExpiryDateProp> = ({ expiryDate, isHomePolicy }) => {
  const { t } = useTranslation('myAccount');

  const whatMeansList: WhatMeansList[] = t(
    `policyManagement.yourRenewal.${isHomePolicy ? 'homeWhatMeansList' : 'motorWhatMeansList'}`,
    {
      returnObjects: true,
    },
  );

  const getListValue = (value: string, index: number) => {
    let accValue;

    accValue = value;
    switch (index) {
      case 0:
      case 1:
        accValue = value.replace('{expiryDate}', expiryDate);
        break;
    }
    return accValue;
  };

  return (
    <List dense>
      {whatMeansList.map((item, _index) => (
        <ListItem key={item.listText} dense disableGutters alignItems="flex-start">
          <ListItemIcon className="listIcon">
            <IconSure icon={item.icon} color="blue" strokeWidth="3.5" />
          </ListItemIcon>
          <ListItemText classes={{ primary: 'listText' }} className="listText" />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: getListValue(item.listText, _index),
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};
