import {
  Instance,
  SnapshotIn,
  applySnapshot,
  detach,
  flow,
  getRoot,
  getSnapshot,
  toGenerator,
  types,
} from 'mobx-state-tree';

import { IRootStore } from '../';
import { event } from '../../../../service/analytics';
import { DIMENSION, LOOKUP } from '../../../constant';
import {
  ResponseLookup,
  getAddress,
  getClaims,
  getCustomerDetails,
  getDecodedLookups,
  getHomePolicy,
  getHomePolicyRenewalQuoteDetails,
  getLookups,
  getMotorPolicy,
  getMotorPolicyRenewalQuoteDetails,
  getPaymentMethods,
  getPolicies,
  getPolicyDocuments,
  getPolicyRenewalStatus,
  getPolicyTransactions,
  getRenewalDetails,
  getRenewalPolicyDocuments,
  getVehicleLookup,
  updateAutoRenewal,
  updatedCustomerAccount,
  updatedMarketingPermissions,
} from '../../../network/api/dashboard';
import { getDateTimeNow } from '../../../util/dateUtils';
import { Header } from '../../../util/interfaceModels/interfaceModels';
import SelfService from '../../../util/selfService';
import { stringifyError } from '../../../util/stringUtils';
import { DateTime } from '../../types/dateTime';

import { IAutoPolicy } from './autoPolicyModel';
import { Customer, ICustomerEmail, ICustomerMarketingPermissions } from './customer';
import { DriversVehicleDamage } from './driversVehicleDamage';
import { IHomePolicy } from './homePolicyModel';
import { Insurance } from './insuranceModel';
import { ILookupDecode, ILookupItem, Lookups } from './lookups';
import { PaymentMethods } from './paymentMethods';
import { PolicyExtras } from './policyExtrasModel';

const sendPolicyLoadedFailedEvent = (policy: IAutoPolicy | IHomePolicy, error: Error) => {
  event('PortalHomePagePolicyNotLoaded', {
    error: stringifyError(error),
    policyNumber: policy.policyNumber,
    policyStatus: policy.policyStatus,
    policyType: policy.policyTypeCd,
  });
};

export const DashboardStore = types
  .model('DashboardStore', {
    coverages: types.optional(PolicyExtras, () => PolicyExtras.create()),
    customer: types.optional(Customer, () => Customer.create()),
    driversVehicleDamage: types.optional(DriversVehicleDamage, () => DriversVehicleDamage.create()),
    insurance: types.optional(Insurance, () => Insurance.create()),
    lookups: types.optional(Lookups, () => Lookups.create()),
    paymentMethods: types.optional(PaymentMethods, () => PaymentMethods.create()),
    subCoverages: types.optional(PolicyExtras, () => PolicyExtras.create()),
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
  }))
  .views((self) => ({
    get rootStore(): IRootStore {
      return getRoot(self);
    },
    get userId(): string {
      return this.rootStore.userStore.user.userId;
    },
  }))
  .actions((self) => ({
    clearStore() {
      applySnapshot(self, self.initialState);
    },
    fetchAddressLookup: flow(function* (houseNumber: string, postcode: string) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        return yield* toGenerator(getAddress({ headers, params: { houseNumber, postcode } }));
      } catch (err) {
        event('Fetch address lookup failed');
      }
    }),
    fetchCustomerDetails: flow(function* (forceRefetch = false) {
      try {
        const {
          userId,
          customer: { customerNumber },
        } = self;

        if (forceRefetch || !customerNumber || customerNumber !== userId) {
          const headers: Header = self.rootStore.authHeader;
          const res = yield* toGenerator(getCustomerDetails(userId, { headers }));

          self.customer = Customer.create(res.data.results[0]);
        }
      } catch (err) {
        event('Fetch customer details failed');
      }
    }),
    fetchDecodedLookups: flow(function* (decodeLookups: ILookupDecode[]) {
      try {
        if (decodeLookups.length > 0) {
          const headers: Header = self.rootStore.authHeader;
          const channelCDValue = !self.insurance.isHome
            ? self.insurance.auto.policySelected.channelCd
            : self.insurance.home.policySelected.channelCd;
          const data = {
            decodeSets: decodeLookups,
            dimensions: [
              {
                name: DIMENSION.NAME,
                value: channelCDValue,
              },
            ],
          };

          const res = yield* toGenerator(getDecodedLookups(data, { headers }));
          self.lookups.setDecodedLookups(res.data);
        }
      } catch (err) {
        event('Fetch decoded lookups failed');
      }
    }),
    fetchHomePolicyRenewalQuoteDetails: flow(function* (policyNumber: string) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getHomePolicyRenewalQuoteDetails(policyNumber, { headers }));
        const policyRenewalQuoteDetails = res.data.results[0];

        self.insurance.home.addRenewalQuoteDetails(policyNumber, policyRenewalQuoteDetails);

        return res;
      } catch (err) {
        //event('Fetch home policy renewal quote details failed');
      }
    }),

    fetchLookups: flow(function* (lookupName: LOOKUP) {
      try {
        const headers: Header = self.rootStore.authHeader;
        const data = {
          dimensions: [
            {
              name: DIMENSION.NAME,
              value: self.insurance.auto.policySelected.channelCd,
            },
          ],
          lookupName,
        };
        const res: ResponseLookup = yield* toGenerator(getLookups(data, { headers }));
        const lookups: ILookupItem[] = res.lookupElements.map((item) => {
          return { ...item, lookupName: res.lookupName };
        });
        self.lookups.replaceDecodedLookups(lookups, lookupName);
      } catch (err) {
        event('Fetch lookups failed');
      }
    }),
    fetchMotorPolicyRenewalQuoteDetails: flow(function* (policyNumber: string) {
      self.insurance.auto.setFetchingRenewalData(policyNumber, true);
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getMotorPolicyRenewalQuoteDetails(policyNumber, { headers }));
        const policyRenewalQuoteDetails = res.data.results[0];

        self.insurance.auto.addRenewalQuoteDetails(policyNumber, policyRenewalQuoteDetails);
        return res;
      } catch (err) {
        self.insurance.auto.setFetchingRenewalData(policyNumber, false);
        //event('Fetch Motor Policy Renewal Quote Details failed');
      }
    }),
    fetchPaymentMethods: flow(function* () {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getPaymentMethods({ headers }));
        self.paymentMethods = PaymentMethods.create({ methods: res.data.results });
        return res;
      } catch (err) {
        event('Fetch payment methods failed');
      }
    }),
    fetchPolicies: flow(function* (forceRefetch = false) {
      event('Policy data requested');

      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getPolicies('', { headers }));
        if (res.data.results.length > 0) {
          event('Policy data request successful');
        }
        for (const policy of res.data.results) {
          if (
            !SelfService.isHomePolicy(policy) &&
            (forceRefetch || SelfService.policiesHaveNoDuplicate(self.insurance.auto.autoPolicies, policy.policyNumber))
          ) {
            if (!policy.tiaPolicy) {
              try {
                const res = yield* toGenerator(getMotorPolicy(policy.policyNumber, { headers }));
                self.insurance.auto.addPolicy({ ...policy, ...res.data.results[0] });
              } catch (e: unknown) {
                self.insurance.auto.addPolicy({ ...(policy as IAutoPolicy), loadingFailed: true });
                // Find the added policy before sending the event, as adding the policy populates the correct policy status
                const addedPolicy = self.insurance.auto.getPolicyByNumber(policy.policyNumber);
                sendPolicyLoadedFailedEvent(addedPolicy ?? policy, e as Error);
              }
            } else {
              self.insurance.auto.addPolicy({
                ...(policy as IAutoPolicy),
              });
            }
          } else if (
            SelfService.isHomePolicy(policy) &&
            (forceRefetch || SelfService.policiesHaveNoDuplicate(self.insurance.home.homePolicies, policy.policyNumber))
          ) {
            if (!policy.tiaPolicy) {
              try {
                const res = yield* toGenerator(getHomePolicy(policy.policyNumber, { headers }, policy.effectiveDate));
                self.insurance.home.addPolicy({ ...policy, ...res.data.results[0] });
              } catch (e: unknown) {
                self.insurance.home.addPolicy({ ...(policy as IHomePolicy), loadingFailed: true });
                // Find the added policy before sending the event, as adding the policy populates the correct policy status
                const addedPolicy = self.insurance.home.getPolicyByNumber(policy.policyNumber);
                sendPolicyLoadedFailedEvent(addedPolicy ?? policy, e as Error);
              }
            } else {
              self.insurance.home.addPolicy({
                ...(policy as IHomePolicy),
              });
            }
          }
        }
      } catch (err) {
        event('Policy data request failed');
      }
    }),
    fetchPolicy: flow(function* (policyNo?: string, isHome = false) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        let policyNumber = '';
        if (policyNo) {
          policyNumber = isHome ? self.insurance.home.selectedPolicyNumber : self.insurance.auto.selectedPolicyNumber;
        }

        const policies = yield* toGenerator(getPolicies('', { headers }));
        const policy = policies.data.results.find((p) => p.policyNumber === policyNumber);

        if (isHome) {
          const policyDetails = yield* toGenerator(
            getHomePolicy(policyNumber, { headers }, self.insurance.home.policySelected.effectiveDate),
          );
          // eslint-disable-next-line array-callback-return
          self.insurance.home.homePolicies.map((pol) => {
            if (pol.policyNumber === policyNumber) {
              detach(pol);
              self.insurance.home.homePolicies.remove(pol);
            }
          });
          self.insurance.home.addPolicy({ ...policy, ...policyDetails.data.results[0] });
        } else {
          const policyDetails = yield* toGenerator(getMotorPolicy(policyNumber, { headers }));
          // eslint-disable-next-line array-callback-return
          self.insurance.auto.autoPolicies.map((pol) => {
            if (pol.policyNumber === policyNumber) {
              detach(pol);
              self.insurance.auto.autoPolicies.remove(pol);
            }
          });
          self.insurance.auto.addPolicy({ ...policy, ...policyDetails.data.results[0] });
        }
      } catch (err) {
        event('Fetch policy failed');
      }
    }),
    fetchPolicyClaims: flow(function* (policyNumber: string) {
      try {
        const drivers = self.insurance.auto.autoPolicies.find((x) => x.policyNumber === policyNumber)?.drivers ?? [];
        const claimStatus = SelfService.searchByKey(drivers, 'claimStatus');

        if (!claimStatus) {
          const headers: Header = self.rootStore.getCustomerHeader();
          const res = yield* toGenerator(getClaims(policyNumber, { headers }));

          self.insurance.auto.addDriverClaims(policyNumber, res.data.results);
        }
      } catch (err) {
        event('Fetch policy claims request failed');
      }
    }),

    fetchPolicyDocuments: flow(function* (policyNumber: string, policyEffectiveDate: Instance<typeof DateTime> | null) {
      event('Documents data requested');
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getPolicyDocuments(policyNumber, { headers }, policyEffectiveDate));

        const { policyDocuments } = res.data.results[0];
        if (self.insurance.isHome) {
          self.insurance.home.addDocuments(policyDocuments, policyNumber);
        } else {
          self.insurance.auto.addDocuments(policyDocuments, policyNumber);
        }
        event('Documents data request successful');
        return res;
      } catch (err) {
        event('Documents data request failed');
      }
    }),

    fetchPolicyRenewalDocuments: flow(function* (
      policyNumber: string,
      policyEffectiveDate: Instance<typeof DateTime> | null,
      entityType: string,
    ) {
      event('Renewal documents data requested');
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(
          getRenewalPolicyDocuments(policyNumber, { headers }, policyEffectiveDate, entityType),
        );

        const { policyDocuments } = res.data.results[0];

        if (self.insurance.isHome) {
          self.insurance.home.addRenewalDocuments(policyDocuments, policyNumber);
        } else {
          self.insurance.auto.addRenewalDocuments(policyDocuments, policyNumber);
        }
        event('Renewal documents data request successful');
        return res;
      } catch (err) {
        event('Renewal documents data request failed');
      }
    }),

    fetchPolicyTransactions: flow(function* (policyNumber: string, policyEffectiveDate: Instance<typeof DateTime>) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getPolicyTransactions(policyNumber, { headers }, policyEffectiveDate));
        const payments = res.data.results[0];
        self.insurance.auto.addPayments(payments, policyNumber);
        return res;
      } catch (err) {
        event('Fetch policy transactions request failed');
      }
    }),

    fetchRenewalDetails: flow(function* (policyNumber, date, isHome) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        const res = yield* toGenerator(getRenewalDetails(policyNumber, { headers }, date));

        const { autoRenewalOption, autoRenewal } = res.data.results[0];

        if (autoRenewal) {
          const headers: Header = self.rootStore.getCustomerHeader();
          const res = yield* toGenerator(getPolicyRenewalStatus(policyNumber, { headers }));

          const renewalStatus = res.data.results[0];
          !isHome
            ? self.insurance.auto.setRenewalStatusRequestPending(renewalStatus === 'false' ? true : false, policyNumber)
            : self.insurance.home.setRenewalStatusRequestPending(
                renewalStatus === 'false' ? true : false,
                policyNumber,
              );
        } else {
          !isHome
            ? self.insurance.auto.setRenewalStatusRequestPending(false, policyNumber)
            : self.insurance.home.setRenewalStatusRequestPending(false, policyNumber);
        }

        !isHome
          ? self.insurance.auto.addRenewalInfo(autoRenewalOption, autoRenewal, policyNumber)
          : self.insurance.home.addRenewalInfo(autoRenewalOption, autoRenewal, policyNumber);
        return res;
      } catch (err) {
        event('Fetch renewal details failed');
      }
    }),

    fetchVehicleLookup: flow(function* (vrn: string) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();
        return yield* toGenerator(getVehicleLookup(vrn, { headers }));
      } catch (err) {
        event('Fetch vehicle lookup request failed');
      }
    }),
    async getInitialData(force = false) {
      await this.fetchCustomerDetails(force);
      await this.fetchPolicies(force);
    },
    refetchPoliciesData() {
      this.getInitialData(true).then(() => this.refetchPolicyDetails());
    },
    async refetchPolicyDetails() {
      const combinedPolicies = [...self.insurance.auto.autoPolicies, ...self.insurance.home.homePolicies];
      for (const policy of combinedPolicies) {
        if (!policy.tiaPolicy) {
          await this.fetchRenewalDetails(policy.policyNumber, policy.effectiveDate, SelfService.isHomePolicy(policy));
        }
      }
      for (const policy of self.insurance.auto.autoPolicies) {
        const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(policy.expirationDate) - 1;
        const insideRenewal = remainingDays <= 32 && remainingDays >= 0 && !policy.futureRenewalEffectiveDate;
        if (!policy.tiaPolicy && insideRenewal) {
          await this.fetchMotorPolicyRenewalQuoteDetails(policy.policyNumber);
        }
      }
      if (self.rootStore.interfaceStore.hasFeatureFlag('showHomeRenewalNotification - annual')) {
        for (const policy of self.insurance.home.homePolicies) {
          const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(policy.expirationDate) - 1;
          const insideRenewal = remainingDays <= 32 && remainingDays >= 0;
          if (!policy.tiaPolicy && insideRenewal) {
            await this.fetchHomePolicyRenewalQuoteDetails(policy.policyNumber);
          }
        }
      }
    },
    setCoverages(newValue: SnapshotIn<typeof PolicyExtras>) {
      self.coverages = PolicyExtras.create(newValue);
    },
    setDriversVehicleDamage(newValue: SnapshotIn<typeof DriversVehicleDamage>) {
      self.driversVehicleDamage = DriversVehicleDamage.create(newValue);
    },
    setSubCoverages(newValue: SnapshotIn<typeof PolicyExtras>) {
      self.subCoverages = PolicyExtras.create(newValue);
    },
    updateAutoRenewal: flow(function* (policyNumber: string) {
      const headers: Header = self.rootStore.getCustomerHeader();
      return yield* toGenerator(updateAutoRenewal(policyNumber, { headers }));
    }),
    updateCustomerMarketingPermissions: flow(function* (updatedMarketing: ICustomerMarketingPermissions[]) {
      const addFalseValue = (item: ICustomerMarketingPermissions) => {
        if (!item.canMarket) item.canMarket = false;
      };
      updatedMarketing.forEach(addFalseValue);

      try {
        const headers: Header = self.rootStore.authHeader;

        //replace updated field for each marketing permission
        updatedMarketing = updatedMarketing.map((item) => {
          return { ...item, updated: getDateTimeNow().toUTC().toISO() };
        });

        //create necessary body request
        const data = [
          {
            ...self.customer.customerLegitimateInterestMarketingPermissions[0],
            customerMarketingPermissions: updatedMarketing,
          },
        ];

        yield* toGenerator(updatedMarketingPermissions(data, self.customer.customerNumber, { headers }));

        // update marketing permissions on store
        detach(self.customer.customerLegitimateInterestMarketingPermissions[0].customerMarketingPermissions);
        self.customer.customerLegitimateInterestMarketingPermissions[0].customerMarketingPermissions.replace(
          updatedMarketing,
        );
      } catch (err) {
        // Intentioanlly left empty
      }
    }),
    updatedCustomerAccount: flow(function* (customer: ICustomerEmail) {
      try {
        if (customer.id) {
          const headers: Header = self.rootStore.authHeader;
          const data = {
            customer,
            userId: self.userId,
          };

          yield* toGenerator(updatedCustomerAccount(data, { headers }));

          //Update the customer information from dashboard store on successfully update
          if (self.customer.customerEmails.length) {
            // Find the logged user email and make it confirmed
            const updatedEmails = self.customer.customerEmails.map((item) => {
              return item.id === customer.id ? { ...item, emailConfirmed: customer.emailConfirmed } : item;
            });
            // create a copy of existing customer
            const updatedCustomer = { ...self.customer };
            // put the updated list of his emails.The spread operator doesn't always clone deep enough
            updatedCustomer.customerEmails.replace(updatedEmails);
            // replace it on dashboard store
            self.customer = Customer.create(updatedCustomer);
          }
        }
      } catch (err) {
        // Intentioanlly left empty
      }
    }),
  }));

export type IDashboardStore = Instance<typeof DashboardStore>;
