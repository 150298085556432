import { IDriver } from '../../../service/state/models/dashboard/autoPolicyModel';

import { changedKeys, objectFlatMap } from '.';

export enum DriverProps {
  ACCESS_TO_OTHER_CARS = 'useOfOtherCarsCode',
  CLAIMS_FIVE = 'claimsFive',
  DATE_OF_BIRTH = 'dateOfBirth',
  DECLINED_ON_SPECIAL_TERMS = 'declinedOnSpecialTerms',
  EMPLOYMENT = 'employmentStatusCode',
  HAS_SECONDARY_OCCUPATION = 'hasSecondaryOccupation',
  LICENCE_DATE_YEARS = 'licenseDateYearsCode',
  LICENCE_RESTRICTION = 'licenseRestrictionCode',
  LICENCE_TYPE = 'licenceTypeCode',
  MARITAL_STATUS = 'maritalStatusCode',
  PRIMARY_INDUSTRY = 'primaryOccupationIndustryCode',
  PRIMARY_OCCUPATION = 'primaryOccupationCode',
  PROSECUTIONS_ON_OFFENCES = 'prosecutionsOnOffences',
  RELATIONSHIP_TO_PROPOSER = 'relationshipToProposerCode',
  RESIDENT_SINCE_BIRTH = 'residentSinceBirth',
  RESIDENT_SINCE_MONTHS_CODE = 'residentSinceMonthsCode',
  RESIDENT_SINCE_YEARS_CODE = 'residentSinceYearsCode',
  SECONDARY_INDUSTRY = 'secondaryOccupationIndustryCode',
  SECONDARY_OCCUPATION = 'secondaryOccupationCode',
  SURNAME = 'surname',
  UNSPENT_CONVICTIONS = 'unspentConvictions',
}

// takes a the list of policy drivers, a list of mta drivers and the decode function
// and if the properties from mta drivers are changed
// the following object will be returned:
// {"ssMTAVehicleADName1": "Existing name = Elena T, maritalStatus = Married",
// "ssMTAVehicleADName2": "Existing name = Elena P, dob = 12/13/1234"}
export const compareDrivers = (
  policyDrivers: IDriver[],
  mtaD: IDriver[],
  decode: (field: string, code: string) => string,
): Record<string, unknown> => {
  const res = policyDrivers.reduce((acc: Record<string, unknown>, policyDriver, i) => {
    const initialStr = `Existing name = ${policyDriver.person.firstName} ${policyDriver.person.surname}, `;
    const flattenPolicyDObj = objectFlatMap<IDriver>(policyDriver);
    const flattenMtaDObj = objectFlatMap<IDriver>(mtaD[i]);
    const matchedKeys = changedKeys(flattenPolicyDObj, flattenMtaDObj, DriverProps);

    if (matchedKeys.length > 0) {
      const keyValuePairStrings = matchedKeys.map((key) => {
        const decodedValue = decode(key, flattenMtaDObj[key] as string);
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return decodedValue !== '' ? `${key} = ${decodedValue}` : `${key} = ${flattenMtaDObj[key]}`;
      });
      acc[`ssMTAVehicleADName${i + 1}`] = initialStr.concat(keyValuePairStrings.join(', '));
    }

    return acc;
  }, {});

  return res;
};

// used for 'Remove drivers' journey
// function which takes the list of policy drivers and a list with the ids of the removed drivers
// and returns a list with the removed drivers names
export const deletedDrivers = (policyDrivers: IDriver[], removedDrivers: string[]): string[] => {
  const res = policyDrivers.reduce((acc: string[], cur) => {
    if (removedDrivers.includes(cur.uuid)) {
      acc.push(`${cur.person.firstName} ${cur.person.surname}`);
    }
    return acc;
  }, []);

  return res;
};
