/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Dialog as DialogMUI, useMediaQuery, useTheme } from '@esure-cloud/react-components';

import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { CancelConfirmationDialog } from '../../component/layout/dialogs/cancelConfirmationDialog';
import { DIALOG_ORIGIN, ROUTE } from '../constant';
import { useStores } from '../state/store';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export interface RouterPromptProps {
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  when?: boolean | undefined;
}
const RouterPrompt: React.FC<RouterPromptProps> = ({ when, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const {
    mtaStore: { resetPendedMTA, resetCompletedJourney },
    interfaceStore: { clearStepAutoComplete },
  } = useStores();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /* istanbul ignore next */
  const handleClose = (reason: CloseReason) => {
    if (reason === 'backdropClick') {
      return false;
    }
  };

  /* istanbul ignore next */
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  /* istanbul ignore next */
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    resetCompletedJourney();
    clearStepAutoComplete();
    setTimeout(() => {
      resetPendedMTA();
    }, 0);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      /* istanbul ignore next */
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const unblock = history.listen((location, action) => {
      if (action === 'POP' && when && location.pathname === ROUTE.QUOTE_INELIGIBLE && shouldBlockNavigation(location)) {
        setModalVisible(true);
        setLastLocation(location);
      }
    });

    return () => {
      unblock();
    };
  }, [history, when, shouldBlockNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {
        <DialogMUI open={modalVisible} onClose={(_, reason) => handleClose(reason)} fullScreen={fullScreen}>
          <CancelConfirmationDialog
            onLeave={handleConfirmNavigationClick}
            onContinue={() => setModalVisible(false)}
            origin={DIALOG_ORIGIN.ROUTER_PROMPT}
          />
        </DialogMUI>
      }
    </>
  );
};
export default RouterPrompt;
