import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../../../component/common/icon';
import { useStores } from '../../../../../../../../service/state/store';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .inner-container': {
      gap: 10,
    },
    border: `1px solid ${palette.secondary.main}50`,
    borderRadius: spacing(1),
    padding: spacing(2, 3),
  },
}));

export const DrivingOtherCarsBanner: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const {
    mtaStore: {
      pendedMTA: { drivers: driversFromPendedMta },
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { drivers: driversFromPolicy },
        },
      },
    },
  } = useStores();

  const pendedMtaMainDriver = driversFromPendedMta.find((driver) => driver.mainDriver);

  const policyHolder = driversFromPolicy.find((driver) => driver.relationshipToProposerCode === 'P');

  return (
    <Grid container className={classes.root}>
      <Grid container className="inner-container">
        <IconSure icon="drive-other-cars" size="26" color="secondary" />
        <Typography variant="h3">{t('drivingOtherCars.title')}</Typography>
      </Grid>
      {policyHolder?.uuid !== pendedMtaMainDriver?.uuid && (
        <Typography>{t('drivingOtherCars.changedFromPolicyHolder')}</Typography>
      )}
      {policyHolder?.uuid === pendedMtaMainDriver?.uuid && (
        <Typography>{t('drivingOtherCars.changedToPolicyHolder')}</Typography>
      )}
    </Grid>
  );
});
