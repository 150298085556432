import { FormHelperText, Grid, MenuItem, Select, Typography, makeStyles } from '@esure-cloud/react-components';

import { ILookupItem } from '../../service/state/models/dashboard/lookups';

export interface OutlinedSelectDropDownProps {
  dataTest?: string;
  errorMessage: string;
  isError: boolean;
  label?: string;
  onChangeHandler: (value: string) => void;
  value: string;
  values: ILookupItem[];
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .outlined-select-dropdown': {
      '& .MuiSelect-select': {
        paddingBottom: spacing(0.9),
        paddingTop: spacing(0.9),
      },
      '&.error': {
        borderColor: palette.error.dark,
      },
      '&:after': {
        content: 'none',
      },
      '&:before': {
        content: 'none',
      },
      border: '1px solid',
      borderColor: palette.divider,
      borderRadius: spacing(1),
      width: '100%',
    },
    '& .outlined-select-dropdown-label': {
      marginBottom: spacing(1),
    },
  },
}));

export const OutlinedSelectDropDown: React.FC<OutlinedSelectDropDownProps> = ({
  dataTest,
  errorMessage,
  isError,
  label,
  onChangeHandler,
  value,
  values,
}) => {
  const classes = useStyles();
  const classList = `outlined-select-dropdown ${isError ? 'error' : ''}`;

  return (
    <Grid className={classes.root}>
      <Typography className="outlined-select-dropdown-label">{label}</Typography>
      <Select
        labelId="select-dropdown-component"
        className={classList}
        data-testid={dataTest ? dataTest : 'select-dropdown'}
        value={value}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => onChangeHandler(e.target.value as string)}
        inputProps={{
          'data-testid': `input-${dataTest ?? ''}`,
        }}
      >
        {values.map((item, index) => {
          return (
            <MenuItem data-testid={`item-${index}`} value={item.code} key={`${index}+${item.code}`}>
              {item.value}
            </MenuItem>
          );
        })}
      </Select>
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Grid>
  );
};
