import { Accordion, AccordionSummary, Box, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { RenewalsDocumentsBtn } from '../../../../../../component/common/button/renewalsDocumentsBtn';
import CommonAccordion from '../../../../../../component/common/commonAccordion';
import IconSure from '../../../../../../component/common/icon';
import { EmptyState } from '../../../../../../component/layout/emptyState';
import { PolicyCard } from '../../../../../../component/layout/policyCards';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { DATE_TYPES, LOOKUP, ROUTE } from '../../../../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { useRenewalDocumentsFetch } from '../../../../../../service/util/customHooks/useRenewalDocumentsFetch';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import { MonthlyPaymentCard } from '../components/monthlyPaymentCard';
import { RenewalCostBreakDown } from '../components/renewalCostBreakDown';
import RenewalCostCard from '../components/renewalCostCard';
import { RenewalDocuments } from '../components/renewalDocuments';
import { RenewalQuoteQuestionItem, RenewalQuoteQuestions } from '../components/renewalQuoteQuestions';
import { WhatsNextBox } from '../components/whatsNextBox';
import { useHomeStyles } from '../home/homeRenewalQuote';

export const mtaRenewalQuoteUseStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .renewals': {
      '&__banner': {
        '&-title': {
          marginTop: spacing(5),
        },
        backgroundColor: palette.secondary.main,
        borderColor: palette.secondary.main,
        [breakpoints.down('xs')]: {
          borderRadius: 0,
          margin: spacing(-4, -2, 0, -2),
        },
        [breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        color: palette.secondary.contrastText,
      },
      '&__buttons-container': {
        '& .MuiButton-root': {
          width: '311px',
        },
        marginTop: spacing(2),
        width: '100%',
        [breakpoints.down('sm')]: {
          '& .MuiButton-root': {
            width: '100%',
          },
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '&__carmake': {
        marginTop: spacing(5),
      },
      '&__costs': {
        display: 'flex',
        flexFlow: 'nowrap',
        gap: '15px',
        justfifyContent: 'space-between',
        padding: spacing(5, 0, 2, 0),
        [breakpoints.down('sm')]: {
          flexFlow: 'wrap',
        },
      },
      '&__documents': {
        minWidth: '100%',
      },
      '&__iconMargin': {
        marginRight: spacing(2),
      },
      '&__included': {
        padding: spacing(1, 2),
      },
      '&__needhelp': {
        gap: spacing(2),
        marginTop: spacing(3),
      },
      '&__notification': {
        backgroundColor: `${palette.primary.light}20`,
        border: `1px solid ${palette.primary.light}`,
        borderRadius: spacing(1),
        marginTop: spacing(2),
        padding: spacing(2),
      },
      '&__question': {
        paddingBottom: spacing(2),
        paddingTop: spacing(3),
      },
      '&__subtext': {
        marginBottom: spacing(5),
        marginTop: spacing(3),
      },
      '&__subtitle': {
        marginTop: spacing(4),
      },
    },
  },
}));

export interface AccordionRow {
  hasButton: boolean;
  label: string;
  segmentStep: string;
  value: string;
}
interface HappyToGoAheadProps {
  isDDCustomer: boolean;
}
interface IncludedItems {
  content: string;
  title: string;
}

const IncludedAsStandard: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const includedItemsList: IncludedItems[] = t('policyManagement.yourRenewalQuote.includedStandardRenewal', {
    returnObjects: true,
  });
  return (
    <Grid container>
      {includedItemsList.map((item) => {
        return (
          <Grid item container className="renewals__included" key={item.title} wrap="nowrap">
            <Grid item className="renewals__iconMargin">
              <IconSure icon="check" color="success" />
            </Grid>
            <Grid item>
              <Grid item>
                <Typography variant="h5">{item.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{item.content}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const HappyToGoAhead: React.FC<HappyToGoAheadProps> = observer(({ isDDCustomer }) => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber, expirationDate, autoRenewal, policyRenewalQuoteDetails, autoRenewalOption },
        },
      },
    },
    renewalChangeStore: { setChangeMade, getChangeMade },
    interfaceStore: {
      isMobile,
      isTablet,
      chatBot: { startChat },
    },
  } = useStores();

  const classes = useHomeStyles();
  const history = useHistory();
  const { eventTrack } = useSegment();
  const changeMade = getChangeMade(policyRenewalQuoteDetails.policyNumber);

  const renewalDate = formatDate(expirationDate, DATE_TYPES.SHORT_SPACE);
  const info = t(`policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.mainInfo`, {
    renewalDate: formatDate(renewalDate, DATE_TYPES.SHORT_SPACE),
    returnObjects: true,
  });

  const takePayment = t(`policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.takePayment`, {
    cardInfo: autoRenewalOption.creditCardDetails.number?.slice(-4),
    returnObjects: true,
  });

  const handleYourCoverDetails = () => {
    eventTrack('Motor Renewal Quote - continue button pressed', {
      policyNumber,
      type: TRACK_TYPE.VIEW,
    });

    if (changeMade) {
      setChangeMade(policyRenewalQuoteDetails.policyNumber, false);
    }
    history.push(ROUTE.YOUR_COVER_DETAILS);
  };
  const handleChat = () => {
    eventTrack('Chat with us', {
      label: 'Not ready to renew',
      type: TRACK_TYPE.CHAT,
    });
    startChat({
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        fcaAutoRenewalInd: 'true',
        isRenewal: 'true',
        ssRenAROff: 'true',
      },
      initiator: 'Motor Not ready to renew - Chat with us',
    });
  };
  return autoRenewal ? (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      <Typography variant="body1">
        <Trans i18nKey={info} t={t} components={[<strong key={Symbol().toString()} />]} />
      </Typography>
      {!isDDCustomer && (
        <Typography variant="body1">
          <Trans i18nKey={takePayment} t={t} components={[<strong key={Symbol().toString()} />]} />
        </Typography>
      )}
      <Typography variant="h3">
        {t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.nonReadyToRenewTitle')}
      </Typography>
      <Typography variant="body1">
        {t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.nonReadyToRenewInfoP1')}
      </Typography>
      <Grid item xs={isMobile || isTablet ? 12 : 3}>
        <CommonButton
          text={t('buttons.chatWithUs')}
          data-testid="chatButton"
          color="primary"
          className="button"
          variant="contained"
          size="large"
          xl
          onClick={handleChat}
        ></CommonButton>
      </Grid>
      <Typography variant="body1">
        {t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOn.nonReadyToRenewInfoP2')}
      </Typography>
    </Grid>
  ) : (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      <Typography>
        {t(
          `policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOff.${
            isDDCustomer ? 'ddCustomer' : 'annualCustomer'
          }.mainInfo`,
        )}
      </Typography>
      <Grid item xs={isMobile || isTablet ? 12 : 3}>
        <CommonButton
          text={t('MTAJourney.button.continue')}
          size="large"
          xl
          data-testid={COMPONENT_ID.RENEWAL_YOUR_RENEWAL_QUOTE_CONTINUE}
          onClick={handleYourCoverDetails}
        ></CommonButton>
      </Grid>
    </Grid>
  );
});

export const MTAMotorRenewalQuote: React.FC = observer(function MTAMotorRenewalQuote() {
  const { hasFetched, hasDocsLoadingFailed } = useRenewalDocumentsFetch();
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
    renewalChangeStore: { setChangeMade, getChangeMade },
    interfaceStore: {
      isMobile,
      isTablet,
      hasFeatureFlag,
      chatBot: { startChat },
    },
  } = useStores();

  const { eventTrack } = useSegment();
  const { policyNumber, paymentPlan, policyRenewalQuoteDetails } = policySelected;
  const {
    apr,
    numberOfInstallments,
    policyCostWithoutInterest,
    previousYearMonthlyCost,
    previousYearTotalCost,
    currentYearMonthlyCost,
    currentYearTotalCost,
    inceptionDate,
    installmentSchedule,
    premiumBreakdown: { interest },
    vehicle,
  } = policyRenewalQuoteDetails;
  const { t } = useTranslation('myAccount');

  const classes = mtaRenewalQuoteUseStyles();
  const homeClasses = useHomeStyles();
  const loyaltyYears = inceptionDate
    ? new Date(new Date().valueOf() - inceptionDate.valueOf()).getFullYear() - 1970
    : null;

  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment' && hasFeatureFlag('BCCJM-9000');
  const questions: RenewalQuoteQuestionItem[] = t('policyManagement.yourRenewalQuote.faqList', {
    returnObjects: true,
  });

  const getLoyaltyText = (loyaltyYears: number) => {
    switch (true) {
      case loyaltyYears < 4:
        return t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextunder4years');
      case loyaltyYears >= 4:
        return t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextover4years');
    }
  };

  const history = useHistory();
  const changeMade = getChangeMade(policyRenewalQuoteDetails.policyNumber);

  const handleYourCoverDetails = () => {
    if (changeMade) {
      setChangeMade(policyRenewalQuoteDetails.policyNumber, false);
    }
    history.push(ROUTE.YOUR_COVER_DETAILS);
  };

  const { isLookupsFetched } = useLookups({
    lookups: [
      LOOKUP.RESIDENTIAL_STATUS,
      LOOKUP.MARITAL_STATUS,
      LOOKUP.NUMBER_OF_CHILDREN,
      LOOKUP.CARS_IN_HOUSEHOLD,
      LOOKUP.OCCUPATION,
      LOOKUP.LICENSE_HELD_YEARS,
      LOOKUP.LICENSE_HELD_MONTHS,
      LOOKUP.ACCESS_TO_OTHER_CARS,
      LOOKUP.COVER_TYPE,
      LOOKUP.MODIFICATIONS,
      LOOKUP.OWNER,
      LOOKUP.KEEPER,
      LOOKUP.VEHICLE_SECURITY,
      LOOKUP.MILEAGE,
      LOOKUP.USAGE,
      LOOKUP.OVERNIGHT_LOCATION,
      LOOKUP.DAYTIME_LOCATION,
      LOOKUP.OCCUPATION,
      LOOKUP.DRIVER_RELATIONSHIP,
      LOOKUP.CLAIM_TYPE,
      LOOKUP.CONVICTION_CODE,
      LOOKUP.LICENCE_TYPE,
    ],
  });

  useEffect(() => {
    eventTrack('Renewal-quote-page-shown', {
      policyNumber,
      position: 'Renewal-quote-page-shown',
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  // return to policy details if no pending MTA quote
  const backButtonRoute = policySelected.policyRenewalQuoteDetails.pendingMTAQuote
    ? ROUTE.YOUR_RENEWAL_QUOTES
    : ROUTE.POLICY_DETAILS;

  return (
    <Grid className={clsx('renewals', classes.root)}>
      <BlueBanner title={t('policyManagement.yourRenewalQuote.title')} route={backButtonRoute} />
      <Typography className={homeClasses.title} variant="h1">
        {t('policyManagement.yourRenewalQuote.subtitle')}
      </Typography>
      <PolicyCard policy={policySelected} isPolicyHeader isRenewalHeader />
      <Grid container item className="renewals__costs">
        <RenewalCostCard
          hasTooltip={false}
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleCurrentYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? currentYearMonthlyCost : currentYearTotalCost,
          })}
          showDD={isDDCustomer}
          ddValue={t('core:price.pound', {
            value: currentYearTotalCost,
          })}
        />
        <RenewalCostCard
          hasTooltip
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleLastYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? previousYearMonthlyCost : previousYearTotalCost,
          })}
          ddValue={t('core:price.pound', {
            value: previousYearTotalCost,
          })}
          showDD={isDDCustomer}
        />
      </Grid>
      {isDDCustomer && hasFeatureFlag('BCCJM-9000') && (
        <>
          <MonthlyPaymentCard
            currentYearMonthlyCost={currentYearMonthlyCost}
            installmentSchedule={installmentSchedule}
            numberOfInstallments={numberOfInstallments}
            startDate={installmentSchedule.length ? installmentSchedule[0].dueDate : ''}
          />
          <RenewalCostBreakDown
            apr={apr}
            policyCost={currentYearTotalCost}
            policyInterest={interest}
            policyCostWithoutInterest={policyCostWithoutInterest}
          />
        </>
      )}
      <Typography variant="body1EB" className="renewals__subtext">
        {loyaltyYears !== null ? getLoyaltyText(loyaltyYears) : ''}
      </Typography>
      <CommonAccordion
        customComponent={<IncludedAsStandard />}
        expanded={false}
        headerIcon="car-renew"
        headerIconType="solid"
        headerTitle={t('policyManagement.yourRenewalQuote.standard')}
        defaultBehaviour={false}
        onChange={() => {
          eventTrack(`Renewal-what-is-covered-chevron`, {
            position: 'Renewal-what-is-covered-chevron',
            type: TRACK_TYPE.ACCORDION,
          });
        }}
      />
      <Grid container className="renewals__carmake">
        <Typography variant="h2">{t('policyManagement.yourRenewalQuote.whatNext')}</Typography>
        <Grid item xs={12} className="renewals__subtitle">
          <Typography>
            {t('policyManagement.yourRenewalQuote.carMake', {
              value: vehicle.make,
            })}
          </Typography>
        </Grid>
      </Grid>

      <WhatsNextBox number="1" title={t('policyManagement.motorRenewalQuote.check.title')}>
        <Typography variant="body1">{t('policyManagement.motorRenewalQuote.check.p1')}</Typography>
        <Typography variant="body1">{t('policyManagement.motorRenewalQuote.check.p2')}</Typography>
        {!hasFetched ? (
          <></>
        ) : hasDocsLoadingFailed ? (
          <Box py={2}>
            <EmptyState title={t('docsFailedLoading.title')} content={t('docsFailedLoading.content')} />
          </Box>
        ) : (
          <Grid item xs={12} className="renewals__documents">
            <Grid item xs={isMobile || isTablet ? 12 : 3}>
              {isLookupsFetched && (
                <CommonButton
                  text={t('policyManagement.motorRenewalQuote.check.yourCoverDetailsButton')}
                  size="large"
                  xl
                  onClick={handleYourCoverDetails}
                  data-testid={COMPONENT_ID.RENEWAL_YOUR_COVER_DETAILS}
                ></CommonButton>
              )}
            </Grid>
            <Grid container direction="column" wrap="nowrap" className={homeClasses.accordionContainer}>
              <Typography variant="body1">{t('policyManagement.motorRenewalQuote.check.p3')}</Typography>
              <Accordion
                onChange={() => {
                  eventTrack(`Renewal-show-all-renewal-docs-expanded`, {
                    position: 'Renewal-show-all-renewal-docs-expanded',
                    type: TRACK_TYPE.ACCORDION,
                  });
                }}
              >
                <AccordionSummary expandIcon={<IconSure color="secondary" icon="chevron-down" type="line" size="26" />}>
                  <Typography variant="body1" color="secondary">
                    {t('policyManagement.yourRenewalQuote.showRenewalDocument')}
                  </Typography>
                </AccordionSummary>
                <RenewalDocuments />
              </Accordion>
            </Grid>
          </Grid>
        )}
      </WhatsNextBox>

      <WhatsNextBox number="2" title={t('policyManagement.motorRenewalQuote.changes.title')}>
        <Typography variant="body1">{t('policyManagement.motorRenewalQuote.changes.p1')}</Typography>
        <Typography variant="body1">{t('policyManagement.motorRenewalQuote.changes.p2')}</Typography>
        <Grid item xs={isMobile || isTablet ? 12 : 3}>
          <CommonButton
            text={t('policyManagement.motorRenewalQuote.changes.updateYourQuoteButton')}
            size="large"
            xl
            onClick={handleYourCoverDetails}
            data-testid={COMPONENT_ID.RENEWAL_UPDATE_YOUR_QUOTE}
            variant="outlined"
            color="secondary"
          ></CommonButton>
        </Grid>
        <Typography variant="body1">{t('policyManagement.motorRenewalQuote.changes.p3')}</Typography>
        <Typography variant="body1">
          <Trans
            i18nKey="policyManagement.motorRenewalQuote.changes.p4"
            t={t}
            components={[
              <Link
                key={Symbol().toString()}
                className={homeClasses.link}
                data-testid="chat-link"
                underline="none"
                variant="body1"
                color="secondary"
                onClick={() =>
                  startChat({
                    contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
                    customContactAttributes: {
                      ssRenQuoteUpdatePol: 'true',
                    },
                    initiator: 'Chat with us',
                  })
                }
              />,
            ]}
          ></Trans>
        </Typography>
      </WhatsNextBox>

      <WhatsNextBox number="3" title={t('policyManagement.motorRenewalQuote.renewalUpdate.title')}>
        <Typography variant="body1">
          <Trans
            i18nKey="policyManagement.motorRenewalQuote.renewalUpdate.description"
            t={t}
            components={[
              <strong key={Symbol().toString()} />,
              <Link
                key={Symbol().toString()}
                className={homeClasses.link}
                data-testid="chat-link"
                underline="none"
                variant="body1"
                color="secondary"
                onClick={() =>
                  startChat({
                    contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
                    customContactAttributes: {
                      ssRenQuoteUpdatePol: 'true',
                    },
                    initiator: 'Chat with us',
                  })
                }
              />,
            ]}
          />
        </Typography>
        <Grid item xs={12} className="renewals__documents">
          <Grid className="renewals__buttons-container">
            <RenewalsDocumentsBtn
              policy={policySelected}
              fileName={t('policyManagement.motorRenewalQuote.renewalUpdate.buttonText')}
              size="large"
            />
          </Grid>
        </Grid>
      </WhatsNextBox>

      <WhatsNextBox
        number="4"
        title={t('policyManagement.motorRenewalQuote.happyToGoAhead.title')}
        description={t(`policyManagement.motorRenewalQuote.happyToGoAhead.description`)}
      >
        <HappyToGoAhead isDDCustomer={isDDCustomer} />
      </WhatsNextBox>

      <RenewalQuoteQuestions
        header={t('policyManagement.yourRenewalQuote.gotQuestion')}
        subTitle={t('policyManagement.yourRenewalQuote.gotQuestionSubTitle')}
        questions={questions}
      />
    </Grid>
  );
});
