import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP, LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useStepperContext } from '../utils/stepperContext';

import { EditFooter } from './editFooter';
import { MTAFooter } from './footer';
import { MtaTooltip } from './mtaTooltip';

export interface ReusableRadioPageProps {
  handleBack?: () => void;
  handleSave?: () => void;
  id:
    | LOOKUP_TYPE_PAGE.DAYTIME_LOCATION
    | LOOKUP_TYPE_PAGE.REGISTERED_KEEPER
    | LOOKUP_TYPE_PAGE.LEGAL_OWNER
    | LOOKUP_TYPE_PAGE.RESIDENTIAL_STATUS
    | LOOKUP_TYPE_PAGE.MARITAL_STATUS
    | LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS
    | LOOKUP_TYPE_PAGE.RELATIONSHIP_STATUS
    | LOOKUP_TYPE_PAGE.CARS_IN_HOUSEHOLD
    | LOOKUP_TYPE_PAGE.NUMBER_OF_CHILDREN;
  radioTitle?: string;
  tooltipTitle?: string;
}

export interface PageValues {
  defaultOption: string;
  lookup: LOOKUP;
}

export const ReusableRadioPage: React.FC<ReusableRadioPageProps> = observer(function ReusableRadioPage({
  id,
  handleSave,
  handleBack,
  radioTitle,
  tooltipTitle = '',
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { insured, vehicle, setVehicle, setInsuredPerson, setSelectedDriver, selectedDriver },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { editMode, nextStep } = useStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getPageVal: () => PageValues = () => {
    switch (id) {
      case LOOKUP_TYPE_PAGE.LEGAL_OWNER:
        return { defaultOption: vehicle[id], lookup: LOOKUP.OWNER };
      case LOOKUP_TYPE_PAGE.REGISTERED_KEEPER:
        return { defaultOption: vehicle[id], lookup: LOOKUP.KEEPER };
      case LOOKUP_TYPE_PAGE.DAYTIME_LOCATION:
        return { defaultOption: vehicle[id], lookup: LOOKUP.DAYTIME_LOCATION };
      case LOOKUP_TYPE_PAGE.RESIDENTIAL_STATUS:
        return { defaultOption: insured.person.residencyStatusCode, lookup: LOOKUP.RESIDENTIAL_STATUS };
      case LOOKUP_TYPE_PAGE.MARITAL_STATUS:
        return { defaultOption: selectedDriver.person.maritalStatusCode, lookup: LOOKUP.MARITAL_STATUS };
      case LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS:
        return { defaultOption: selectedDriver.useOfOtherCarsCode, lookup: LOOKUP.ACCESS_TO_OTHER_CARS };
      case LOOKUP_TYPE_PAGE.RELATIONSHIP_STATUS:
        return { defaultOption: insured.person.maritalStatusCode, lookup: LOOKUP.MARITAL_STATUS };
      case LOOKUP_TYPE_PAGE.NUMBER_OF_CHILDREN:
        return { defaultOption: insured.person.numberOfChildren ?? '', lookup: LOOKUP.NUMBER_OF_CHILDREN };
      case LOOKUP_TYPE_PAGE.CARS_IN_HOUSEHOLD:
        return { defaultOption: insured.person.carsInHousehold, lookup: LOOKUP.CARS_IN_HOUSEHOLD };
    }
  };
  const [value, setValue] = useState(getPageVal().defaultOption);
  const { isLookupsFetched } = useLookups({
    lookups: [getPageVal().lookup],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[getPageVal().lookup]);
    }
  }, [isLookupsFetched, lookupsByName]);

  const handleContinueOrSave = () => {
    switch (id) {
      case LOOKUP_TYPE_PAGE.LEGAL_OWNER:
        setVehicle({ ...vehicle, legalOwner: value });
        break;
      case LOOKUP_TYPE_PAGE.REGISTERED_KEEPER:
        setVehicle({ ...vehicle, registeredKeeper: value });
        break;
      case LOOKUP_TYPE_PAGE.DAYTIME_LOCATION:
        setVehicle({ ...vehicle, daytimeLocation: value });
        break;
      case LOOKUP_TYPE_PAGE.RESIDENTIAL_STATUS:
        setInsuredPerson({ ...insured.person, residencyStatusCode: value });
        break;
      case LOOKUP_TYPE_PAGE.MARITAL_STATUS:
        setSelectedDriver({ ...selectedDriver, person: { ...selectedDriver.person, maritalStatusCode: value } });
        break;
      case LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS:
        setSelectedDriver({ ...selectedDriver, useOfOtherCarsCode: value });
        break;
      case LOOKUP_TYPE_PAGE.RELATIONSHIP_STATUS:
        setInsuredPerson({ ...insured.person, maritalStatusCode: value });
        break;
      case LOOKUP_TYPE_PAGE.NUMBER_OF_CHILDREN:
        setInsuredPerson({ ...insured.person, numberOfChildren: value });
        break;
      case LOOKUP_TYPE_PAGE.CARS_IN_HOUSEHOLD:
        setInsuredPerson({ ...insured.person, carsInHousehold: value });
        break;
    }
  };

  const handleSaveChanges = () => {
    handleContinueOrSave();
    handleSave?.();
  };

  const handleContinue = () => {
    setIsSubmitting(true);
    handleContinueOrSave();
    if (value === '') return;
    nextStep();
  };

  return (
    <>
      {isLookupsFetched && (
        <>
          {radioTitle && (
            <MtaTooltip
              description={<Typography variant="body1">{radioTitle}</Typography>}
              tooltipTitle={tooltipTitle}
            />
          )}

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      {!editMode && value === '' && isSubmitting && (
        <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
      )}

      {editMode ? (
        <EditFooter id={id} validPage={true} handleBack={handleBack} handleSave={handleSaveChanges} />
      ) : (
        <MTAFooter
          backButtonId={`${id}Back`}
          cancelButtonId={`${id}Cancel`}
          primaryButton={{ buttonId: `${id}Continue`, handleContinue }}
        />
      )}
    </>
  );
});
