import { AlertProps } from '@esure-cloud/react-components';

import type { Color } from '@material-ui/lab/Alert';
import { Instance, types } from 'mobx-state-tree';

export const Notification = types
  .model('Notification', {
    autoHideDuration: types.optional(types.number, 0),
    message: types.optional(types.string, ''),
    open: types.optional(types.boolean, false),
    severity: types.optional(types.enumeration<Color>(['success', 'info', 'warning', 'error']), 'success'),
  })
  .actions((self) => ({
    closeNotification() {
      self.open = false;
    },
    openNotification(options: NotificationOptions) {
      self.open = true;
      self.autoHideDuration = options.autoHideDuration ?? 1500;
      self.message = options.message ?? '';
      self.severity = options.severity ?? 'success';
    },
  }));

export type INotification = Instance<typeof Notification>;

export interface NotificationOptions {
  autoHideDuration?: number;
  message?: string;
  severity?: AlertProps['severity'];
}
