import {
  Box,
  Button,
  CommonContainer,
  Grid,
  InputAdornment,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TextField,
  Typography,
} from '@esure-cloud/react-components';

import LuxonUtils from '@date-io/luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES } from '../../../service/constant';
import { getDateTimeNow } from '../../../service/util/dateUtils';
import { formatDate } from '../../../service/util/formatDate';
import SelfService from '../../../service/util/selfService';
import { MtaTooltip } from '../../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';
import { Choice } from '../choice';
import IconSure from '../icon';

export interface CarOwnershipFormProps {
  dateError: boolean;
  estimatedValue: number | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCarOwned: boolean | null;
  isMobile: boolean;
  isSubmitting: boolean;
  purchaseDate: string | null;
  setDateError: (error: boolean) => void;
  setEstimatedValue: (value: number | null) => void;
  setPurchaseDate: (date: string | null) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  startDate: DateTime | null;
}

export const CarOwnershipForm: React.FC<CarOwnershipFormProps> = ({
  isCarOwned,
  estimatedValue,
  setEstimatedValue,
  purchaseDate,
  setPurchaseDate,
  isSubmitting,
  dateError,
  setDateError,
  startDate,
  isMobile,
  handleChange,
  setStep,
}) => {
  const { t } = useTranslation('myAccount');

  /* istanbul ignore next */
  const handleEstimatedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && parseInt(event.target.value) > -1) {
      setEstimatedValue(parseInt(event.target.value));
    } else {
      setEstimatedValue(null);
    }
  };

  /* istanbul ignore next */
  const handleChangePurchaseDate = (date: MaterialUiPickersDate | null) => {
    if (date?.isValid) {
      setPurchaseDate(SelfService.formatDate(date.toString()));
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  return (
    <Grid>
      <Grid item className="radio-section" xs={isMobile ? 12 : 8}>
        <Typography className="secondary-title" variant="body1">
          {t('MTAJourney.CarOwnership.ownedCar')}
        </Typography>
        <Choice
          identifier="carOwner"
          onChange={handleChange}
          left={{ label: t('labels.no') }}
          right={{ label: t('labels.yes') }}
          radioValue={isCarOwned}
          error={isSubmitting && isCarOwned === null}
        />
      </Grid>

      {isCarOwned !== null && (
        <Grid item>
          {!isCarOwned && (
            <CommonContainer variant="filled" className="info-box">
              <Grid container direction="row" wrap="nowrap">
                <IconSure className="icon" icon="info-circle" color="secondary" type="solid" />
                <Typography variant="body1">{t('MTAJourney.CarOwnership.futurePurchase')}</Typography>
              </Grid>
            </CommonContainer>
          )}
          <Grid item container direction="column" className="input__container" xs={12} sm={6}>
            {isCarOwned && (
              <>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    value={purchaseDate}
                    maxDate={getDateTimeNow()}
                    className="customized-picker input__item"
                    format="dd/MM/yyyy"
                    onChange={handleChangePurchaseDate}
                    variant="inline"
                    label={
                      <MtaTooltip
                        description={<Typography>{t('MTAJourney.CarOwnership.purchaseDate')}</Typography>}
                        tooltipTitle={t('tooltips.changeYourCar.carOwnership')}
                      />
                    }
                    color="secondary"
                    inputVariant="outlined"
                    emptyLabel="Select a date"
                    views={['date', 'month', 'year']}
                    error={(isSubmitting && purchaseDate === '') || dateError}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
                {isSubmitting && purchaseDate === null && (
                  <Typography className="error-message" variant="body1">
                    {t('MTAJourney.CarOwnership.dateError')}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          {startDate && purchaseDate && DateTime.fromISO(purchaseDate) > startDate && (
            <CommonContainer variant="filled" className="info-box info-box-error">
              <Grid container direction="row" wrap="nowrap">
                <IconSure className="icon" icon="info-circle" color="error-dark" type="solid" />
                <Box>
                  <Typography variant="body1" className="box-title">
                    {t('MTAJourney.CarOwnership.startDateError.title')}{' '}
                    {formatDate(startDate.toString(), DATE_TYPES.SHORT)}
                  </Typography>
                  <Typography variant="body2" className="box-body">
                    {t('MTAJourney.CarOwnership.startDateError.body')}
                  </Typography>
                  <Button className="box-button" color="secondary" variant="text" onClick={() => setStep(0)}>
                    {t('MTAJourney.CarOwnership.startDateError.link')}
                  </Button>
                </Box>
              </Grid>
            </CommonContainer>
          )}
          <Grid item container direction="column" className="input__container" xs={12} sm={6}>
            <TextField
              className="input__item"
              type="number"
              inputMode="numeric"
              placeholder="Enter amount"
              onChange={handleEstimatedValue}
              value={estimatedValue ?? ''}
              label={
                <MtaTooltip
                  description={<Typography>{t('MTAJourney.CarOwnership.estimatedValue')}</Typography>}
                  tooltipTitle={t('tooltips.changeYourCar.carOwnership')}
                />
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
              }}
              error={isSubmitting && estimatedValue === null}
            />

            {isSubmitting && estimatedValue === null && (
              <Typography className="error-message" variant="body1">
                {t('MTAJourney.CarOwnership.estimatedValueError')}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CarOwnershipForm;
