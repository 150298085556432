import { CommonContainer, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Accordion, { AccordionProps, ItemAccordion } from '../../../../../component/common/accordion/accordion';
import { BlueBanner } from '../../../../../component/common/blueBanner';
import IconSure from '../../../../../component/common/icon';
import { IconListSection } from '../../../../../component/common/iconListSection';
import { sendOpenInBrowserHook } from '../../../../../lib/caura-bridge';
import {
  CHATBOT_ACTION,
  DATE_TYPES,
  DIALOG_TYPE,
  FITTED,
  LOOKUP,
  MTA_TYPE,
  NONE,
  ROUTE,
} from '../../../../../service/constant';
import { useStores } from '../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../service/util/customHooks/useDecodedLookups';
import { useSegment } from '../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../service/util/formatDate';
import SelfService from '../../../../../service/util/selfService';
import { MotorRenewalNotification } from '../renewals/components/motorRenewalNotification';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .container': {
      gap: 10,
      padding: spacing(3, 0),
    },
    '& .icon': {
      marginRight: spacing(2),
      marginTop: spacing(1),
    },
    '& .link': {
      cursor: 'pointer',
      display: 'flex',
    },
    '& .title': {
      marginBottom: spacing(2),
    },
  },
}));

export const MakeChange: React.FC = observer(function MakeChange() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const history = useHistory();

  const [open, setOpen] = useState<null | number>(null);
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          mainDriver: { uuid: mainDriverId },
          mainVehicle: {
            classOfUse,
            fuelTypeCode,
            legalOwner,
            make,
            model,
            modifications,
            overnightLocation,
            registeredKeeper,
            totalDeclaredMileageCode,
            trackingDeviceFitted,
            securityDeviceDeclared,
            transmissionTypeCode,
            vrm,
          },
          policySelected,
          insured: {
            person: {
              fullName,
              firstName,
              surname,
              maritalStatusCode,
              residencyStatusCode,
              numberOfChildren,
              employmentStatusCode,
              primaryOccupationCode,
              primaryOccupationIndustryCode,
              hasSecondaryOccupation,
              secondaryOccupationCode,
              secondaryOccupationIndustryCode,
              carsInHousehold,
            },
          },
        },
      },
    },
    interfaceStore: {
      chatBot: { startChat },
      dialog: { openDialog },
      hasFeatureFlag,
      isCaura,
    },
  } = useStores();

  const { effectiveDate, drivers, vehicles, insured, policyNumber, expiration, brand, cancellationFee, adminFee } =
    policySelected;

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({
    options: ['vehicle', 'driver', 'insured'],
  });

  const getModificationsList = () =>
    modifications.map((item) => getLookup(LOOKUP.MODIFICATIONS, item.modificationCode)).join(',\n');

  const handleClick = (mtaType: MTA_TYPE) => {
    switch (true) {
      case mtaType === MTA_TYPE.CANCEL_POLICY:
        eventTrack('Cancel policy - chat with us', {
          eventFiredOn: 'Chat with us',
          journeyType: 'Cancel policy',
          label: 'Clicked chat with us',
        });
        openDialog({
          chatbotAction: mtaType,
          title: t('cancelPolicyPopup.open.title'),
          type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
        });
        break;
      case mtaType === MTA_TYPE.DRIVERS:
        history.push(ROUTE.DRIVERS);
        break;
      case mtaType === MTA_TYPE.YOUR_DETAILS:
        history.push(ROUTE.YOUR_DETAILS);
        break;
      case mtaType === MTA_TYPE.CAR_DETAILS:
        history.push(ROUTE.CAR_DETAILS);
        break;
    }
  };

  const driverInfo = drivers.reduce((acc: Record<string, string[]>, { claims, convictions, person, uuid }, idx) => {
    if (idx === 0) {
      acc['drivers'] = [];
      acc['claims'] = [];
      acc['convictions'] = [];
    }

    const name = person.fullName();
    const totalClaims = claims.length;
    const totalConvictions = convictions.length;

    if (uuid !== mainDriverId) acc.drivers.push(name);
    if (totalClaims) acc.claims.push(`${name} (${totalClaims} claim)`);
    if (totalConvictions) acc.convictions.push(`${name} (${totalConvictions} offence)`);

    return acc;
  }, {});

  const getDrivers = (drivers: string[]): ItemAccordion[] => {
    return drivers.map((item: string) => ({
      value: item,
    }));
  };

  const whiteAccordions: AccordionProps[] = [
    {
      columns: [
        {
          values: [
            {
              label: t('makeChange.carDetails.make'),
              value: `${make}`,
            },
            {
              label: t('makeChange.carDetails.model'),
              value: `${model}`,
            },
            {
              label: t('makeChange.carDetails.registration'),
              value: vrm,
            },
            {
              label: `${t('makeChange.carDetails.fuelType')} / ${t('makeChange.carDetails.gearBox')}`,
              value: `${getLookup(LOOKUP.FUEL_TYPE, fuelTypeCode)}, ${getLookup(
                LOOKUP.TRANSMISSION,
                transmissionTypeCode,
              )}`,
            },
            {
              label: t('makeChange.carDetails.legalOwner'),
              value: getLookup(LOOKUP.OWNER, legalOwner),
            },
            {
              label: t('makeChange.carDetails.registeredKeeper'),
              value: getLookup(LOOKUP.KEEPER, registeredKeeper),
            },
            {
              label: t('carDetails.headers.carSecurity'),
              value: getLookup(LOOKUP.VEHICLE_SECURITY, securityDeviceDeclared),
            },
          ],
        },
        {
          values: [
            {
              label: t('makeChange.carDetails.useOfCar'),
              value: getLookup(LOOKUP.USAGE, classOfUse),
            },
            {
              label: t('makeChange.carDetails.estimatedAnnualMileage'),
              value: getLookup(LOOKUP.MILEAGE, totalDeclaredMileageCode),
            },
            {
              label: t('makeChange.carDetails.trackingDevice'),
              value: trackingDeviceFitted ? FITTED : NONE,
            },
            {
              label: t('makeChange.carDetails.modifications'),
              value: getModificationsList() || NONE,
            },
            {
              label: t('makeChange.carDetails.overnightLocation'),
              value: getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocation),
            },
          ],
        },
      ],
      footer: {
        additionalText: t('makeChange.carDetails.administrationFee', { value: adminFee }),
      },
      header: {
        headerButtonText: t('makeChange.changeButton'),
      },
      icon: 'car',
      subtitle: t('makeChange.carDetails.description'),
      title: t('makeChange.carDetails.header'),
    },
    {
      columns: [
        {
          values: [
            {
              label: t('makeChange.person.firstName'),
              value: firstName,
            },
            {
              label: t('makeChange.person.surname'),
              value: surname,
            },
            {
              label: t('makeChange.person.homeAddress'),
              value: SelfService.formatAddress(insured.address).full,
            },
            {
              label: t('makeChange.person.relationship'),
              value: getLookup(LOOKUP.MARITAL_STATUS, maritalStatusCode),
            },
            {
              label: t('makeChange.person.residencyStatus'),
              value: getLookup(LOOKUP.RESIDENTIAL_STATUS, residencyStatusCode),
            },
            {
              label: t('makeChange.person.children'),
              value: numberOfChildren,
            },
          ],
        },
        {
          values: [
            {
              label: t('makeChange.person.carsInHousehold'),
              value: carsInHousehold,
            },
            {
              label: t('makeChange.person.employmentStatus'),
              value: getLookup(LOOKUP.EMPLOYMENT, employmentStatusCode ?? ''),
            },
            {
              label: t('makeChange.person.primaryOccupation'),
              value: getLookup(LOOKUP.OCCUPATION, primaryOccupationCode ?? ''),
            },
            {
              label: t('makeChange.person.industry'),
              value: getLookup(LOOKUP.INDUSTRY, primaryOccupationIndustryCode ?? ''),
            },
            {
              label: t('makeChange.person.secondaryOccupation'),
              value: hasSecondaryOccupation
                ? getLookup(LOOKUP.OCCUPATION, secondaryOccupationCode ?? '')
                : t('labels.no'),
            },
            {
              label: t('makeChange.person.secondaryIndustry'),
              value: hasSecondaryOccupation ? getLookup(LOOKUP.INDUSTRY, secondaryOccupationIndustryCode ?? '') : null,
            },
          ],
        },
      ],

      footer: {
        additionalText: t('makeChange.carDetails.administrationFee', { value: adminFee }),
      },
      header: {
        headerButtonText: t('makeChange.changeButton'),
      },
      icon: 'driver',
      subtitle: t('makeChange.person.description'),
      title: t('makeChange.person.header'),
    },
    {
      columns: [
        {
          values: [
            {
              value: `${fullName()} ${t('makeChange.driver.mainDriverIndicator')}`,
            },
            ...getDrivers(driverInfo.drivers),
          ],
        },
      ],
      footer: {
        additionalText: t('makeChange.carDetails.administrationFee', { value: adminFee }),
      },
      header: {
        headerButtonText: t('makeChange.changeAddDriversButton'),
      },
      icon: 'drive-other-cars',
      subtitle: t('makeChange.driver.descriptionNew'),
      title: t('makeChange.driver.header'),
    },
    {
      columns: [
        {
          values: [
            {
              label: t('makeChange.cancelPolicy.policyNumber'),
              value: policyNumber,
            },
            {
              label: t('makeChange.cancelPolicy.car'),
              value: make && model ? `${make} ${model}` : null,
            },
            {
              label: t('makeChange.cancelPolicy.registration'),
              value: vrm,
            },
          ],
        },
        {
          values: [
            {
              label: t('makeChange.cancelPolicy.coverStartDate'),
              value: formatDate(effectiveDate?.toString(), DATE_TYPES.SHORT_SPACE),
            },
            {
              label: t('makeChange.cancelPolicy.coverEndDate'),
              value: formatDate(
                SelfService.calculatePolicyEndDate(expiration?.toISO()).toString(),
                DATE_TYPES.SHORT_SPACE,
              ),
            },
            {
              label: t('makeChange.cancelPolicy.typeOfCover'),
              value: getLookup(LOOKUP.COVER_TYPE, vehicles[0].coverType ?? ''),
            },
          ],
        },
      ],
      footer: {
        additionalText: t('makeChange.carDetails.cancellationFee', { value: cancellationFee }),
      },
      header: {
        headerButtonText: t('makeChange.cancelPolicy.button'),
      },
      icon: 'cancel',
      iconType: 'solid',
      subtitle: t('makeChange.cancelPolicy.description'),
      title: t('makeChange.cancelPolicy.header'),
    },
  ];

  const bullets: { content: string }[] = t('makeChange.temporaryCover.bullets', { returnObjects: true });
  const [affiliate, setAffiliate] = useState('');

  useEffect(() => {
    switch (brand) {
      case 'esure':
        setAffiliate('esure');
        break;
      case 'sheilasWheels':
        setAffiliate('sheilaswheels');
        break;
      case 'firstAlt':
        setAffiliate('firstalternative');
        break;
      default:
        break;
    }
  });

  const renderZixtyLink = () => {
    const url = `https://quote.zixty.com/partner/${affiliate}`;
    const target = isCaura ? '' : '_blank';
    // eslint-disable-next-line no-script-url
    const href = isCaura ? 'javascript:void(0);' : url;
    const onClick = isCaura ? () => sendOpenInBrowserHook(url) : () => void 0;
    return (
      <Link target={target} href={href} className="link" data-testid="affiliate-link" onClick={onClick}>
        {t('makeChange.temporaryCover.link')}
        <IconSure color="secondary" icon="arrow-right" type="solid" size="26" />
      </Link>
    );
  };

  return (
    <>
      {policySelected.futureRenewalEffectiveDate === null && (
        <MotorRenewalNotification isAboveBlueBanner policy={policySelected} />
      )}
      <BlueBanner title={t('appbar.makeChange')} route={ROUTE.POLICY_DETAILS} />
      <div className={classes.root}>
        <Grid className="container" container direction="column">
          <Typography className="" variant="h2">
            {t('makeChange.heading')}
          </Typography>
          <Typography className="" variant="body1">
            {t('makeChange.subheading')}
          </Typography>

          {isDecodedLookupsFetched &&
            whiteAccordions.map((item, index) => {
              return (
                <Accordion
                  {...item}
                  key={`${item.title}_${index}`}
                  expanded={open === index}
                  onChange={() => setOpen(open === index ? null : index)}
                  onClick={() => handleClick(item.title as MTA_TYPE)}
                  header={{ headerButtonText: item.header?.headerButtonText, showToggleText: open === index }}
                  iconType={item.iconType}
                  footer={{ additionalText: item.footer?.additionalText, buttonText: item.header?.headerButtonText }}
                />
              );
            })}
          {hasFeatureFlag('SSOP-1159') && (
            <CommonContainer color="secondary">
              <Grid container item alignItems="center" xs className="title">
                <IconSure color="secondary" icon={'car'} type={'solid'} size="26" className="icon" />
                <Typography variant="h3">{t('makeChange.temporaryCover.title')}</Typography>
              </Grid>
              <Typography variant="body1">{t('makeChange.temporaryCover.description')}</Typography>

              <IconListSection color="secondary" icon="circle" list={bullets} />

              {renderZixtyLink()}
            </CommonContainer>
          )}
          <Link
            className="link"
            onClick={() =>
              startChat({
                customContactAttributes: { action: CHATBOT_ACTION.MAKE_CHANGE_OTHER },
                initiator: 'Make a change page',
              })
            }
            target="_blank"
            underline="none"
          >
            {t('makeChange.link')}
          </Link>
        </Grid>
      </div>
    </>
  );
});
