import { CommonContainer, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { LOOKUP, PANEL_TYPE } from '../../service/constant';
import { IVehiclePackCoverages } from '../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../service/state/store';
import { useDecodedLookupsMotor } from '../../service/util/customHooks/useDecodedLookups';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';

import IconSure from './icon';
import { PolicyStatus } from './policyStatus';

const useStyles = makeStyles(() => ({
  height: {
    height: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
  width: {
    width: '100%',
  },
}));

export interface LifestylePacksCardProps {
  pack: IVehiclePackCoverages;
  subCoverages: string[]; // in future remove this line to have dynamic texts.
}

export const LifestylePacksCard: React.FC<LifestylePacksCardProps> = observer(function LifestylePacksCard({
  pack,
  subCoverages = [],
}) {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();
  const classes = useStyles();

  const {
    interfaceStore: { panel },
    dashboardStore: {
      lookups: { getLookupValue },
    },
  } = useStores();

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({ options: ['vehicle'] });

  const packName = getLookupValue(LOOKUP.COVERAGE_DESCRIPTION, pack.coverageCd);

  const handleOpenPanel = () => {
    eventTrack('Lifestyle Pack Opened', { item: packName, type: TRACK_TYPE.DRAWER });

    panel.openPanel({
      packCode: pack.coverageCd,
      title: t('lifestylePacks.drawer.title'),
      type: PANEL_TYPE.LIFESTYLE_PACK,
    });
  };
  // for now, looping hard-coded packs texts. Uncomment line below to have dynamic texts in future
  // const subCoverages = pack.coverages?.map((x) => x.subCoverages).flat();

  return isDecodedLookupsFetched ? (
    <CommonContainer className={classes.height}>
      <Grid container alignContent="space-between" className={classes.height} direction="column">
        <Grid item className={classes.width}>
          <Grid container spacing={1}>
            <Grid item container justifyContent="space-between">
              <Typography variant="h3">{packName}</Typography>
              <PolicyStatus label={t('policyStatus.included')} status={t('policyStatus.active')} />
            </Grid>
            <Grid container item direction="column">
              {subCoverages.map((item, idx) => {
                return (
                  <Grid item container key={idx}>
                    <Grid item>
                      <IconSure icon="check" color="success" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">{item}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Link className={classes.pointer} onClick={handleOpenPanel} variant="body2" data-testid="lifestyle-card-link">
            {t('lifestylePacks.link')}
          </Link>
        </Grid>
      </Grid>
    </CommonContainer>
  ) : (
    <></>
  );
});
