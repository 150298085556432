import config from '../../service/config';

declare global {
  interface Window {
    satismeter: (
      event: { traits?: { contactId: string }; userId: string; writeKey: string } | string,
      opt?: { event: string },
    ) => void;
  }
}

let localCustomerId: string;

const initSatismeter = (customerId: string): void => {
  localCustomerId = customerId;
  window.satismeter({
    userId: customerId,
    writeKey: config.satismeterKey,
  });
};

export const updateSatismeterContactId = (contactId: string): void => {
  window.satismeter({
    traits: {
      contactId: contactId,
    },
    userId: localCustomerId,
    writeKey: config.satismeterKey,
  });
};

export const trackSatismeter = (event: string): void => {
  window.satismeter('track', { event: event });
};

export default initSatismeter;
