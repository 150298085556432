import Core from '@adyen/adyen-web/dist/types/core';
import axios, { AxiosRequestConfig } from 'axios';

import { apiURL } from '../../config';
import { ADYEN_RESULT_CODE, ROUTE } from '../../constant';
import { Header } from '../../util/interfaceModels/interfaceModels';
import { APIResponse } from '../types';

interface ActionInterface {
  instance: Record<string, unknown>;
}

export interface APIPayloadPaymentSubmit {
  action?: ActionInterface;
  pspReference?: string;
  refusalReason?: string;
  resultCode: string;
}

export type APIResponsePaymentMethodLoad = APIResponse<Core['options']['paymentMethodsResponse']>;

export type APIResponsePaymentSubmit = APIResponse<APIPayloadPaymentSubmit | ResponseData | null>;

interface ResponseData {
  data: Record<string, unknown>;
}

const generateAPiPrefix = (pathName: string, isHome: boolean, isAdditionalDetails: boolean): string => {
  const renewalPaymentUrl = isHome
    ? isAdditionalDetails
      ? apiURL.renewalPaymentPrefixHomeAdditonalDetails
      : apiURL.renewalPaymentPrefixHome
    : apiURL.renewalPaymentPrefix;

  const prefixes: Record<string, string> = {
    [ROUTE.CHANGE_PAYMENT]: apiURL.paymentPrefix,
    [ROUTE.RENEWAL_PAYMENT]: renewalPaymentUrl,
    [ROUTE.YOUR_RENEWAL_SUMMARY]: renewalPaymentUrl,
  };
  const result = Object.keys(prefixes).find((element) => pathName.includes(element));
  return result ? prefixes[result] : apiURL.paymentPrefix;
};

export const mqsPaymentAdditionalSubmit = (
  data: unknown,
  policyNumber: string | null,
  headers: Header,
  pathName: string,
  isHome = false,
): Promise<APIResponsePaymentSubmit['results'][number]> =>
  axios
    .post<APIResponsePaymentSubmit>(
      `${generateAPiPrefix(pathName, isHome, true)}${policyNumber ? policyNumber : ''}${
        isHome ? apiURL.paymentAdditionalHome : apiURL.paymentAdditional
      }`,
      data as APIResponse<APIPayloadPaymentSubmit>,
      { headers: headers },
    )
    .then((res) => {
      if (res.data.results.length > 0) {
        return res.data.results[0];
      } else {
        return {
          resultCode: ADYEN_RESULT_CODE.AUTHORISED,
        };
      }
    });

export const mqsPaymentMethodLoad = (
  data: AxiosRequestConfig,
  policyNumber: string,
  pathName: string,
  isHome = false,
): Promise<APIResponsePaymentMethodLoad['results'][number]> =>
  axios
    .get<APIResponsePaymentMethodLoad>(
      `${generateAPiPrefix(pathName, isHome, false)}${policyNumber}${
        isHome ? apiURL.paymentMethodHome : apiURL.paymentMethod
      }`,
      data,
    )
    .then((res) => res.data.results[0]);

export const mqsPaymentSubmit = (
  data: unknown,
  policyNumber: string | null,
  headers: Header,
  pathName: string,
  isHome = false,
): Promise<APIResponsePaymentSubmit['results'][number]> =>
  axios
    .post<APIResponsePaymentSubmit>(
      `${generateAPiPrefix(pathName, isHome, false)}${policyNumber ? policyNumber : ''}${
        isHome ? apiURL.paymentSubmitHome : apiURL.paymentSubmit
      }`,
      data as APIResponse<APIPayloadPaymentSubmit>,
      { headers: headers },
    )
    .then((res) => {
      if (res.data.results.length > 0) {
        return res.data.results[0];
      } else {
        return {
          resultCode: ADYEN_RESULT_CODE.AUTHORISED,
        };
      }
    });
