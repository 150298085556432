import { event } from '../analytics';

export interface BlackBoxResponse {
  blackbox: string;
  finished: boolean;
}

declare global {
  interface Window {
    IOVATION: {
      getBlackbox: () => BlackBoxResponse;
    };
  }
}

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
export const getBlackBox = async (ms = 1000): Promise<string> => {
  let data;
  let tries = 10;

  while (!data?.finished && tries) {
    try {
      data = window.IOVATION.getBlackbox();
      // eslint-disable-next-line no-empty
    } catch {
      // Do not log the error event here
    }
    if (data?.finished) {
      return data.blackbox;
    }
    await delay(ms);
    tries -= 1;
  }
  event('Iovation or API failure');
  return 'Get blackbox maximum tries reached';
};
