import { ReactNode } from 'react';

import { getDeviceSession } from '../../analytics';
import { useStores } from '../../state/store';

export enum TRACK_TYPE {
  ACCORDION = 'Accordion',
  APPLY_CHANGE = 'Apply Change',
  CHAT = 'ChatBot',
  CTA = 'CTA',
  DOWNLOAD = 'Download',
  DRAWER = 'Drawer',
  INFO = 'Info',
  LINK = 'Link',
  MODAL = 'Modal',
  TAB = 'Tab',
  VIEW = 'View',
}

export enum PAYMENT_TYPE {
  ANNUAL_CUSTOMER = 'Annual - CC',
  DD_CUSTOMER = 'Monthly - DD',
  NO_CARD = 'No card on file',
}

export enum AUTO_RENEWAL_STATUS {
  OFF = 'Auto Renew Off',
  ON = 'Auto Renew On',
}

export interface EventProps {
  app_user?: boolean;
  brand?: string;
  componentID?: string;
  customerId?: string;
  customerNumber?: string;
  description?: string;
  error?: string;
  eventFiredOn?: string;
  eventSource?: string;
  goal?: string;
  item?: string;
  journey?: string;
  journeyType?: string;
  journeysCompleted?: string[];
  // When Typography is used as a label, it will come in as a ReactNode object
  label?: string | ReactNode;
  linkType?: string;
  pathName?: string;
  policyNumber?: string;
  policyStatus?: string;
  policyType?: string;
  position?: string;
  product?: string;
  productType?: string;
  referrer?: string;
  sessionId?: string;
  step?: string;
  transactionId?: string;
  transactionType?: string;
  type?: TRACK_TYPE;
  url?: string;
}

export interface UseSegmentReturn {
  eventTrack: (event: string, data?: EventProps) => void;
  eventTrackInteraction: (event: string, data?: EventProps) => void;
}

/**
 * Handles labels which are Typography react components, just gets the children from it which should be just plain string.
 * Without this, Segment event will fail due to circular JSON error when it tries to stringify the event
 */
const handleLabelObject = (data?: EventProps): void => {
  if (data?.label instanceof Object) {
    data.label = (data.label as { props?: { children?: string } }).props?.children ?? '';
  }
};

export const useSegment = (): UseSegmentReturn => {
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: {
            // esrProductTypeCd: productTypeAuto,
            policyNumber: policyNumberAuto,
            policyStatus: policyStatusAuto,
          },
        },
        home: {
          policySelected: {
            // productType: productTypeHome,
            policyNumber: policyNumberHome,
            policyStatus: policyStatusHome,
          },
        },
        isHome,
      },
    },
    interfaceStore: { brand, lastVisitedUrl, isCaura },
    userStore: {
      user: { userId: customerNumber },
    },
  } = useStores();

  const eventTrack = (event: string, data?: EventProps): void => {
    const type = data?.type ?? TRACK_TYPE.CTA;
    const product = !isHome ? 'Motor' : 'Home';
    // Change to use productTypeAuto and productTypeHome once given go ahead
    // const productType = !isHome ? productTypeAuto : productTypeHome;
    const productType = !isHome ? 'Flex' : 'Core';
    const journey = 'service';
    const brandName = brand.substring(5);

    const selectedPolicyNo = !isHome ? policyNumberAuto : policyNumberHome;
    const selectedPolicyStatus = !isHome ? policyStatusAuto : policyStatusHome;

    handleLabelObject(data);

    const trackData: EventProps = {
      // TODO: At some point, all of these properties, (apart from referrer and sessionId), should be removed from being sent on every event
      brand: brandName,
      customerNumber,
      journey,
      pathName: window.location.pathname,
      policyNumber: selectedPolicyNo,
      policyStatus: selectedPolicyStatus,
      product,
      productType,
      // referrer is needed on all events
      referrer: lastVisitedUrl,
      // sessionId is needed on all events
      sessionId: getDeviceSession(),
      type,
      ...data,
    };

    if (isCaura) {
      trackData.app_user = true;
    }

    window.analytics.track(event, trackData);
  };

  // for auto interaction events
  const eventTrackInteraction = (event: string, data?: EventProps): void => {
    handleLabelObject(data);

    const selectedPolicyNo = !isHome ? policyNumberAuto : policyNumberHome;

    const trackData: EventProps = {
      // We've been asked to also send policyNumber on the interaction events, if it's empty string, we send undefined to stop the property being sent
      policyNumber: selectedPolicyNo || undefined,
      referrer: lastVisitedUrl,
      ...data,
    };

    if (isCaura) {
      trackData.app_user = true;
    }

    window.analytics.track(event, trackData);
  };

  return { eventTrack, eventTrackInteraction };
};
