import { Instance, applySnapshot, getSnapshot, types } from 'mobx-state-tree';

import { User } from './userModel';

export const UserStore = types
  .model('UserStore', {
    user: types.optional(User, () => User.create()),
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
    isHydrateAttempted: false,
  }))

  .actions((self) => ({
    clearStore() {
      applySnapshot(self, self.initialState);
    },
  }));

export type IUserStore = Instance<typeof UserStore>;
