import { Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { DATE_TYPES, EDIT_STEPS } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
//import { LOCAL_TIMEZONE } from '../../../../../../../../service/util/dateUtils';
import { formatDate } from '../../../../../../../../service/util/formatDate';

import { ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListStartDate: React.FC<ReviewListType> = observer(function ReviewListStartDate({ onEdit }) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    mtaStore: {
      pendedMTA: { endorsementDate: startDate },
    },
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();
  const classes = useListStyles({ isDesktop });

  const isStartDateChanged = () => {
    return startDate !== policySelected.effectiveDate;
  };

  //const localizedStartDate = startDate?.setZone(LOCAL_TIMEZONE);
  const adjustedDate = startDate; //?.minus({ hours: 1 });

  return (
    <>
      {isStartDateChanged() && (
        <BlueTableContainer title={t('MTAJourney.reviewAll.startDate')} className={classes.root}>
          <>
            <Grid container className={classes.columnsContainer} justifyContent="space-between">
              <Grid item>
                <Typography>{t('MTAJourney.ReviewChanges.startDate')}</Typography>
                <Typography variant="h4">
                  {formatDate(adjustedDate?.toString(), DATE_TYPES.SHORT_TIME_WITH_COMMA)}
                </Typography>
              </Grid>
              <Grid item>
                <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.START_DATE} />
              </Grid>
            </Grid>
          </>
        </BlueTableContainer>
      )}
    </>
  );
});
