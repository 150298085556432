import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DATE_TYPES, POLICY_STATUS_CODE, ROUTE } from '../../../../../../service/constant';
import { IAutoPolicy } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';

import { RenewalAlertTemplate } from './renewalAlert';

interface MotorRenewalNotificationProps {
  hasLabel?: boolean;
  isAboveBlueBanner?: boolean;
  policy: IAutoPolicy;
}

const policyStatusesForShowingNotification: string[] = [
  POLICY_STATUS_CODE.PROPOSED,
  POLICY_STATUS_CODE.COMPANY_DECLINED,
  POLICY_STATUS_CODE.CUSTOMER_DECLINED,
];

export const MotorRenewalNotification: React.FC<MotorRenewalNotificationProps> = observer(
  function MotorRenewalNotification({ isAboveBlueBanner, policy, hasLabel }) {
    const { autoRenewal, expirationDate, migrated, vehicles, paymentPlan, policyNumber, policyRenewalQuoteDetails } =
      policy;
    const { t } = useTranslation('myAccount');

    const {
      dashboardStore: {
        insurance: {
          auto: { setSelectedPolicyNumber },
          setSelectedPolicyType,
        },
      },
      renewalChangeStore: {
        renewalDetails: { addRenewalQuoteDetails, clearEndorsementReasons },
        setPendingMTAQuoteSelected,
      },
      interfaceStore: { hasFeatureFlag },
    } = useStores();

    const history = useHistory();

    const { eventTrack } = useSegment();
    const registration = vehicles.length > 0 ? vehicles[0].vrm : null;
    const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;
    const formattedDate = formatDate(
      SelfService.calculatePolicyEndDate(expirationDate).toString(),
      DATE_TYPES.SHORT_SPACE,
    );

    /* istanbul ignore next */
    const handleClick = () => {
      setSelectedPolicyNumber(policyNumber);
      setSelectedPolicyType(policy);
      setPendingMTAQuoteSelected(false);
      if (policyRenewalQuoteDetails.policyStatusCd === POLICY_STATUS_CODE.PROPOSED && hasFeatureFlag('BCCJM-9000')) {
        clearEndorsementReasons();
        if (policyRenewalQuoteDetails.pendingMTAQuote) {
          history.push(ROUTE.YOUR_RENEWAL_QUOTES);
        } else {
          addRenewalQuoteDetails(policyRenewalQuoteDetails);
          history.push(ROUTE.RENEWAL_QUOTE_DETAILS);
        }
      } else if (policyRenewalQuoteDetails.policyStatusCd !== '') {
        history.push(ROUTE.YOUR_RENEWAL_DECLINE);
      }
      eventTrack('Renewal-important-info-clicked', {
        policyNumber,
        position: 'Auto Renewal Expiry Notice',
        type: TRACK_TYPE.LINK,
      });
    };
    const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

    const showNotification =
      (policyStatusesForShowingNotification.includes(policyRenewalQuoteDetails.policyStatusCd) || !autoRenewal) &&
      remainingDays <= 32 &&
      remainingDays >= 0;

    const getTitle = (): string => {
      let title = '';

      if (hasFeatureFlag('newRenewalMta')) {
        switch (true) {
          case policyRenewalQuoteDetails.policyStatusCd === POLICY_STATUS_CODE.COMPANY_DECLINED:
          case remainingDays === 0 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
          case remainingDays === 1 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
          case remainingDays >= 2 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
            title = t('expiryNoticeNew.companyDeclined.title');
            break;
          case remainingDays > 10 && policyRenewalQuoteDetails.policyStatusCd === POLICY_STATUS_CODE.CUSTOMER_DECLINED:
            title = t('expiryNoticeNew.autoRenewalOff.willEndInMoreThan10daysDeclined');
            break;
          case remainingDays === 0 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.sameDay', {
              policyID: registration,
            });
            break;
          case remainingDays === 1 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'day',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'days',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOn.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays === 0 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.sameDay', {
              date: formattedDate,
              policyID: registration,
            });
            break;
          case remainingDays === 1 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'day',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'days',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && !autoRenewal:
            title = t('expiryNoticeNew.autoRenewalOff.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              policyID: registration,
              value: remainingDays,
            });
            break;
        }
      } else {
        switch (true) {
          case remainingDays === 0 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
            title = t('expiryNotice.migrated.sameDay', {
              registration,
            });
            break;
          case remainingDays === 1 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
            title = t('expiryNotice.migrated.oneDay', {
              date: formattedDate,
              registration,
            });
            break;
          case remainingDays >= 2 && (migrated || isDDCustomer) && !hasFeatureFlag('BCCJM-9000'):
            title = t('expiryNotice.migrated.willEnd', {
              date: formattedDate,
              days: 'days',
              registration,
              value: remainingDays,
            });
            break;
          case remainingDays === 0 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.sameDay', {
              insuranceType: 'car',
              policyID: registration,
            });
            break;
          case remainingDays === 1 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.oneDay', {
              date: formattedDate,
              insuranceType: 'car',
              policyID: registration,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.willEnd', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'car',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && autoRenewal:
            title = t('expiryNotice.autoRenewalOn.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'car',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays === 0 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.sameDay', {
              date: formattedDate,
              insuranceType: 'car',
              policyID: registration,
            });
            break;
          case remainingDays === 1 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.oneDay', {
              date: formattedDate,
              insuranceType: 'car',
              policyID: registration,
            });
            break;
          case remainingDays >= 2 && remainingDays <= 10 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.willEnd', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'car',
              policyID: registration,
              value: remainingDays,
            });
            break;
          case remainingDays > 10 && !autoRenewal:
            title = t('expiryNotice.autoRenewalOff.willEndInMoreThan10days', {
              date: formattedDate,
              days: 'days',
              insuranceType: 'car',
              policyID: registration,
              value: remainingDays,
            });
            break;
        }
      }
      return title;
    };

    const getLinkText = (): string => {
      let text: string;

      if (hasFeatureFlag('newRenewalMta')) {
        if (policyRenewalQuoteDetails.policyStatusCd === POLICY_STATUS_CODE.COMPANY_DECLINED) {
          text = t('expiryNoticeNew.companyDeclined.quoteLink');
        } else if (policyRenewalQuoteDetails.pendingMTAQuote) {
          text = t('expiryNoticeNew.quoteLinkMultiple');
        } else {
          text = t('expiryNoticeNew.quoteLink');
        }
      } else {
        text = t('expiryNotice.quoteLink');
      }

      return text;
    };

    return (
      <RenewalAlertTemplate
        isAboveBlueBanner={isAboveBlueBanner}
        showNotification={showNotification}
        showRenewalLink={policyRenewalQuoteDetails.policyStatusCd !== POLICY_STATUS_CODE.CUSTOMER_DECLINED}
        title={getTitle()}
        linkText={getLinkText()}
        handleClick={handleClick}
        vrm={hasLabel ? registration : null}
        autoRenewal={autoRenewal}
        formattedDate={formattedDate}
        remainingDays={remainingDays}
        savedQuoteLinkText={t('expiryNoticeNew.savedQuoteLink')}
        showSavedQuoteLink={!!policyRenewalQuoteDetails.pendingMTAQuote}
        policyStatusCode={policyRenewalQuoteDetails.policyStatusCd}
      />
    );
  },
);
