import { Grid, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../service/analytics';
import { ENDORSEMENT_REASONS, LOOKUP, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { ReviewAllDivider, ReviewAllEditButton } from '../../makeAchange/mta/common/steps/reviewAll';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  columnsContainer: {
    gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
    padding: spacing(2, 2),
  },
  root: {
    marginTop: spacing(3),
  },
}));

interface ILookupData {
  businessMileage: string;
  daytimeLocation: string;
  mileage: string;
  overnightLocation: string;
  usage: string;
}

export const UsageOfCarAccordion: React.FC<{ showEditButton?: boolean }> = observer(function UsageOfCarAccordion({
  showEditButton = true,
}) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    renewalChangeStore: {
      renewalDetails: {
        addEndorsementReason,
        vehicle: { socialMileageCd, businessMileageCd, classOfUseCd, overnightLocationCd, daytimeLocationCd },
      },
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
  } = useStores();
  const history = useHistory();
  const classes = useStyles({ isDesktop });

  const [lookupData, setLookupData] = useState<ILookupData>({
    businessMileage: '',
    daytimeLocation: '',
    mileage: '',
    overnightLocation: '',
    usage: '',
  });

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.MILEAGE, LOOKUP.USAGE, LOOKUP.OVERNIGHT_LOCATION, LOOKUP.DAYTIME_LOCATION],
  });

  useEffect(() => {
    setLookupData({
      businessMileage: getLookup(LOOKUP.MILEAGE, businessMileageCd ?? ''),
      daytimeLocation: getLookup(LOOKUP.DAYTIME_LOCATION, daytimeLocationCd),
      mileage: getLookup(LOOKUP.MILEAGE, socialMileageCd),
      overnightLocation: getLookup(LOOKUP.OVERNIGHT_LOCATION, overnightLocationCd),
      usage: getLookup(LOOKUP.USAGE, classOfUseCd),
    });
  }, [isLookupsFetched]);

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.usageCarAccordion.estimatedSocial')} </Typography>
          <Typography variant="h4">{lookupData.mileage}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_SOCIAL_MILEAGE}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      {lookupData.businessMileage && (
        <>
          <Grid
            container
            wrap="nowrap"
            className={clsx(classes.columnsContainer, 'columnsContainer')}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography>{t('policyManagement.yourCoverDetails.usageCarAccordion.estimatedBusiness')} </Typography>
              <Typography variant="h4">{lookupData.businessMileage}</Typography>
            </Grid>
            {showEditButton && (
              <Grid item>
                <ReviewAllEditButton
                  onEdit={() => {
                    addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                    history.push(ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE);
                  }}
                  compId={COMPONENT_ID.RENEWAL_EDIT_LINK_BUSINESS_MILEAGE}
                />
              </Grid>
            )}
          </Grid>
          <ReviewAllDivider />
        </>
      )}

      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.usageCarAccordion.usedFor')} </Typography>
          <Typography variant="h4">{lookupData.usage}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_USAGE}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.usageCarAccordion.overnightParkingLocation')} </Typography>
          <Typography variant="h4">{lookupData.overnightLocation}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_OVERNIGHT_LOCATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_OVERNIGHT_LOCATION}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.usageCarAccordion.daytimeParkingLocation')} </Typography>
          <Typography variant="h4">{lookupData.daytimeLocation}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.MILEAGE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_DAYTIME_LOCATION);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_DAYTIME_LOCATION}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
});
