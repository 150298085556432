import { Alert, AlertProps, AlertTitle, Collapse, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import React from 'react';

import { JOURNEY_TYPE } from '../../../service/constant';
import { UseFormReturn } from '../../../service/util/customHooks/useForm';
import { CarRegistrationForm } from '../../../view/pages/policies/policy/makeAchange/mta/carDetails/steps/carRegistration';
import { RegChangeInput } from '../regChangeInput';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    '& .navbar': {
      height: 80,
      position: 'relative',
    },
  },
  root: {
    '& .registration': {
      '&-box': {
        '& a': {
          cursor: 'pointer',
        },
        '&__description': {
          '& br': {
            content: `''`,
            display: 'block',
            margin: spacing(2, 0),
          },
          lineHeight: 1.5,
        },
        gap: 30,
        maxWidth: 430,
        width: '100%',
      },
    },
    '& .spinner-hide': {
      opacity: 0,
    },
  },
}));

export interface CarRegistrationFormProps {
  additionalText?: string;
  alert?: {
    description: string;
    severity?: AlertProps['severity'];
    title?: string;
  };
  button: string;
  description?: string;
  findCar: () => void;
  form?: {
    label: string;
    placeholder: string;
  };
  handleChange: UseFormReturn<CarRegistrationForm>['handleChange'];
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  journeyType: JOURNEY_TYPE;
  regError?: string;
  regNumber: string | undefined;
  showTooltip?: boolean;
}

export const CarRegistrationFormComponent: React.FC<CarRegistrationFormProps> = ({
  description,
  findCar,
  handleSubmit,
  alert,
  additionalText,
  regNumber,
  regError,
  handleChange,
  isSubmitting,
  journeyType,
  form,
  showTooltip,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={clsx('registration', classes.root)}>
      <Grid
        id="step2-form"
        component="form"
        container
        direction="column"
        className="registration-box"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          findCar();
          handleSubmit(e);
        }}
      >
        {description && (
          <Typography align="center" variant="body1" className="registration-box__description">
            {description}
          </Typography>
        )}

        {form && (
          <RegChangeInput
            label={form.label}
            regNumber={regNumber}
            regError={regError}
            placeholder={form.placeholder}
            disabled={isSubmitting}
            handleChange={handleChange}
            journeyType={journeyType}
            showTooltip={showTooltip}
          />
        )}

        {additionalText && (
          <Grid container item>
            <Typography variant="body1">{additionalText}</Typography>
          </Grid>
        )}

        {alert && (
          <Collapse in={!isSubmitting} unmountOnExit timeout={{ enter: 500, exit: 0 }}>
            <Alert variant="standard" severity={alert.severity}>
              <AlertTitle>{alert.title}</AlertTitle>
              {alert.description}
            </Alert>
          </Collapse>
        )}
      </Grid>
    </Grid>
  );
};

export default CarRegistrationFormComponent;
