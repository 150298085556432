import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ROUTE } from '../../../../../../../../service/constant';
import { TRACK_TYPE, useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { Usage } from '../../common/components/usage';
import { useStepperContext } from '../../common/utils/stepperContext';

export const UseOfCar: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('myAccount');
  const {
    setTitle,
    nextStep,
    setStep,
    stepsData: { reviewStep },
  } = useStepperContext();
  const { eventTrack } = useSegment();

  const handleContinue = () => {
    nextStep();
  };

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.usageEdit.title'));
    if (pathname !== ROUTE.MORE_CHANGES) eventTrack('What do you use the car for viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  return <Usage handleSave={handleContinue} handleBack={() => setStep(reviewStep)} />;
};
