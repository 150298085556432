import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { PolicyPageSkeleton } from '../../../../component/layout/skeletons/policySkeleton';
import { useStores } from '../../../../service/state/store';

import { DirectDebitPayment } from './directDebitPayment';
import { YearlyPayment } from './yearlyPayment';

export const SelectedPayment: React.FC = observer(() => {
  const [hasFetched, setHasFetched] = useState(false);

  const {
    dashboardStore: {
      fetchPolicyTransactions,
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();

  const {
    policyNumber,
    payments: { transactions, paymentPlan, billablePremium },
  } = policySelected;

  useEffect(() => {
    if (!transactions.length) {
      fetchPolicyTransactions(policyNumber, policySelected.effectiveDate).then(() => setHasFetched(true));
    } else {
      setHasFetched(true);
    }
  }, []);

  return !hasFetched || paymentPlan === '' ? (
    <PolicyPageSkeleton />
  ) : paymentPlan !== 'YearlyCardPayment' ? (
    <DirectDebitPayment />
  ) : (
    <YearlyPayment transactions={transactions} total={billablePremium} />
  );
});
