import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BasicAccordion from '../../../../../../../../component/common/accordion/basicAccordion';
import { PolicyExtras } from '../../../../../../../../component/common/containerMultipleLines';
import { useStores } from '../../../../../../../../service/state/store';
import { MtaTooltip } from '../../common/components/mtaTooltip';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .accordion-container': {
      gap: spacing(2),
    },
    '& .bold-item': {
      '&__driver': {
        fontWeight: 700,
        marginTop: spacing(2),
      },
      fontWeight: 700,
    },
    '& .damage-container': {
      '&-description': {
        marginBottom: spacing(1),
      },
      borderBottom: `1px solid ${palette.secondary.light}50`,
      gap: spacing(1),
      padding: spacing(2, 0),
    },
    '& .drivers-container': {
      borderBottom: `1px solid ${palette.divider}`,
      gap: spacing(1),
      paddingBottom: spacing(2),
    },
    '& .font-size': {
      fontSize: 16,
    },
    '& .other-container': {
      gap: spacing(2),
    },

    gap: spacing(4),
  },
}));

interface QuoteItem {
  cost?: number;
  driverName?: string;
  label?: React.ReactNode;
  value?: (string | React.ReactElement)[];
}

interface ExcessesPerDriver {
  driverName: string;
  excesses: QuoteItem[];
  total: number;
}

export const QuoteExcesses: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const [expandedExcesses, setExpandedExcesses] = useState(true);

  const classes = useStyles();

  const {
    mtaStore: { quote },
  } = useStores();

  const filterValue = (values: (QuoteItem | boolean)[]): QuoteItem[] => {
    return values.filter((x) => x) as QuoteItem[];
  };

  const { drivers, otherExcesses, driversExcesses, ownRepairExcesses } = quote;
  const otherExcessesTypes: Record<string, string> = t('MTAJourney.QuoteReady.otherExcesses', { returnObjects: true });
  const [allOtherExcesses, setAllOtherExcesses] = useState<PolicyExtras[]>([]);

  const [sameExcesses, setSameExcesses] = useState<QuoteItem[]>([]);
  const [differentExcesses, setDifferentExcesses] = useState<ExcessesPerDriver[][]>([]);

  const hasDifferentExcesses = driversExcesses.length > 1;

  const getOtherExcesses = () => {
    const allExcesses: PolicyExtras[] = [];
    toJS(otherExcesses).map((item) => {
      if (item.coverageCode) {
        const ExcessItem = {
          amount: item.excess,
          code: item.coverageCode,
          item: otherExcessesTypes[item.coverageCode],
        };
        allExcesses.push(ExcessItem);
      }
      return allExcesses;
    });

    const ownRepairerEx = ownRepairExcesses;
    if (ownRepairerEx) {
      allExcesses.push({
        amount: ownRepairerEx,
        code: 'ownRepairExcesses',
        item: otherExcessesTypes['ownRepairExcesses'],
      });
    }

    setAllOtherExcesses(allExcesses);
  };

  const getSameExcesses = () => {
    let equalExcesses: QuoteItem[] = [];
    if (!hasDifferentExcesses) {
      driversExcesses.map(({ compulsoryExcesses, voluntaryExcessCode, driverSpecificExcesses }) => {
        equalExcesses = filterValue([
          {
            cost: Number.parseInt(voluntaryExcessCode),
            label: (
              <MtaTooltip
                description={t('vehicleDamage.drivers.voluntary')}
                tooltipTitle={t('tooltips.quote.voluntaryExcesses')}
              />
            ),
          },
          {
            cost: compulsoryExcesses,
            label: (
              <MtaTooltip
                description={t('vehicleDamage.drivers.compulsory')}
                tooltipTitle={t('tooltips.quote.compulsoryExcess')}
              />
            ),
          },
          driverSpecificExcesses > 0 && {
            cost: driverSpecificExcesses,
            label: t('vehicleDamage.drivers.specificDriver'),
          },
        ]);
        return equalExcesses;
      });
    }
    setSameExcesses(equalExcesses);
    return equalExcesses;
  };

  const getDifferentExcesses = () => {
    let excessesPerDriver: ExcessesPerDriver[] = [];

    const excesses = drivers.map((driver) => {
      driversExcesses.map((excessDriver) => {
        if (driver.uuid === excessDriver.uuid) {
          const quoteDriver = driver;
          excessesPerDriver = [
            {
              driverName: quoteDriver.person.fullName(),
              excesses: filterValue([
                {
                  cost: Number.parseInt(excessDriver.voluntaryExcessCode),
                  label: (
                    <MtaTooltip
                      description={t('vehicleDamage.drivers.voluntary')}
                      tooltipTitle={t('tooltips.quote.voluntaryExcesses')}
                    />
                  ),
                },
                {
                  cost: excessDriver.compulsoryExcesses,
                  label: (
                    <MtaTooltip
                      description={t('vehicleDamage.drivers.compulsory')}
                      tooltipTitle={t('tooltips.quote.compulsoryExcess')}
                    />
                  ),
                },
                excessDriver.driverSpecificExcesses > 0 && {
                  cost: excessDriver.driverSpecificExcesses,
                  label: t('vehicleDamage.drivers.specificDriver'),
                },
              ]),
              total:
                Number.parseInt(excessDriver.voluntaryExcessCode) +
                excessDriver.compulsoryExcesses +
                excessDriver.driverSpecificExcesses,
            },
          ];
        }

        return excessesPerDriver;
      });

      return excessesPerDriver;
    });

    setDifferentExcesses(excesses);

    return excesses;
  };

  useEffect(() => {
    getOtherExcesses();
    getSameExcesses();
    getDifferentExcesses();
  }, [quote]);

  const SameDriversExcesses: React.FC = () => {
    const totalExcess = driversExcesses.map((driver) => {
      const voluntaryExcess = driver.voluntaryExcessCode ? Number.parseInt(driver.voluntaryExcessCode) : 0;
      const total = voluntaryExcess + driver.compulsoryExcesses + driver.driverSpecificExcesses;
      return total;
    });

    return (
      <>
        {sameExcesses.map(({ cost, label }, index) => {
          return (
            <Grid container justifyContent="space-between" key={index}>
              <div className="font-size">{label}</div>

              <Typography variant="body1" className="bold-item">
                {t('core:price.pound', { value: cost })}
              </Typography>
            </Grid>
          );
        })}
        <Grid container justifyContent="space-between">
          <Typography variant="body1" className="bold-item">
            {t('vehicleDamage.drivers.totalExcess')}
          </Typography>
          <Typography variant="body1" className="bold-item">
            {t('core:price.pound', { value: totalExcess })}
          </Typography>
        </Grid>
      </>
    );
  };

  const DifferentDriversExcesses: React.FC = () => {
    return (
      <Grid container className="damage-container" direction="column">
        <Typography variant="h3">{t('MTAJourney.QuoteReady.accidentalDamageExcesses')}</Typography>
        <MtaTooltip
          description={t('MTAJourney.QuoteReady.accidentalDamageDescription')}
          tooltipTitle={t('tooltips.quote.accidentalDamageExcesses')}
        />
        {differentExcesses.map((drivers) => {
          return drivers.map(({ driverName, total, excesses }, index) => {
            return (
              <React.Fragment key={index}>
                <Typography variant="body1" className="bold-item__driver">
                  {driverName}
                </Typography>
                {excesses.map((item, index) => {
                  return (
                    <Grid container justifyContent="space-between" key={index}>
                      <div className="font-size">{item.label}</div>
                      <Typography variant="body1" className="bold-item">
                        {t('core:price.pound', { value: item.cost })}
                      </Typography>
                    </Grid>
                  );
                })}
                <Grid container justifyContent="space-between">
                  <Typography variant="body1" className="bold-item">
                    {t('vehicleDamage.drivers.totalExcess')}
                  </Typography>
                  <Typography variant="body1" className="bold-item">
                    {t('core:price.pound', { value: total })}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          });
        })}
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <BasicAccordion
        title={t('MTAJourney.QuoteReady.yourExcesses')}
        expanded={expandedExcesses}
        onChange={() => setExpandedExcesses(!expandedExcesses)}
      >
        <Grid container direction="column" className="accordion-container">
          {!hasDifferentExcesses ? (
            <>
              {drivers.length > 1 && (
                <Grid container className="drivers-container" direction="column">
                  <Typography variant="body1">{t('MTAJourney.QuoteReady.drivers')}</Typography>
                  {drivers.map((driver, idx) => (
                    <Typography variant="h4" key={idx}>
                      {driver.person.fullName()}
                    </Typography>
                  ))}
                </Grid>
              )}
              <Grid container className="damage-container" direction="column">
                <Typography variant="h3">{t('MTAJourney.QuoteReady.accidentalDamageExcesses')}</Typography>
                <MtaTooltip
                  description={t('MTAJourney.QuoteReady.accidentalDamageDescription')}
                  tooltipTitle={t('tooltips.quote.accidentalDamageExcesses')}
                />
                <Grid container>
                  <SameDriversExcesses />
                </Grid>
              </Grid>
            </>
          ) : (
            <DifferentDriversExcesses />
          )}

          <Grid container className="other-container" direction="column">
            <Typography variant="h3">{t('MTAJourney.QuoteReady.otherExcessesTitle')}</Typography>
            <Grid container>
              {allOtherExcesses.map((item, idx) => (
                <Grid container justifyContent="space-between" key={idx}>
                  <Typography variant="body1">{item.item}</Typography>
                  <Typography variant="body1" className="bold-item">
                    {t('core:price.pound', { value: item.amount })}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Typography variant="body2">{t('MTAJourney.QuoteReady.otherExcessesDescription')}</Typography>
          </Grid>
        </Grid>
      </BasicAccordion>
    </div>
  );
};
