import { Button, Grid, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEditPage } from '../../carDetails/utils/useEditPage';
import { useStepperContext } from '../utils/stepperContext';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& button': {
      lineHeight: 1,
      marginTop: spacing(5),
      padding: spacing(0, 4),
      width: '48%',
    },
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    gap: spacing(1),
    [breakpoints.up('sm')]: {
      width: 'fit-content',
    },
  },
}));

export interface EditFooterProps {
  handleBack?: () => void;
  handleSave?: () => void;
  id: string;
  primaryButtonText?: React.ReactNode;
  validPage?: boolean;
}

export const EditFooter: React.FC<EditFooterProps> = ({
  id,
  validPage = false,
  handleBack,
  handleSave,
  primaryButtonText,
}) => {
  const classes = useStyles();
  const { setEditMode } = useStepperContext();
  const { t } = useTranslation('myAccount');

  const { handleSave: onSaveDefault } = useEditPage();

  const onSave = () => {
    if (validPage) {
      setEditMode(false);
      onSaveDefault();
    }
    handleSave?.();
  };

  const onBack = () => {
    setEditMode(false);
    handleBack?.();
  };

  return (
    <Grid container className={classes.root}>
      <Button data-testid={`${id}MtaEditBack`} color="secondary" variant="outlined" onClick={onBack}>
        {t('MTAJourney.button.back')}
      </Button>
      <Button data-testid={`${id}MtaEditSave`} color="primary" variant="contained" onClick={onSave}>
        {primaryButtonText ? primaryButtonText : t('MTAJourney.button.save')}
      </Button>
    </Grid>
  );
};
