import { RouteData, routeConfig } from '../routeConfig';

export const getChildrenOfSpecifiedParent = (parentRoute: string): RouteData[] | undefined => {
  const foundParent = searchRoute(routeConfig, parentRoute);
  return foundParent ? foundParent.children : undefined;
};

export const isChildOfSpecifiedParent = (parentRoute: string, childRoute: string): boolean => {
  const foundChildren: undefined | RouteData[] = getChildrenOfSpecifiedParent(parentRoute);
  if (foundChildren) {
    const foundSpecifiedChildRoute = searchRoute(foundChildren, childRoute);
    return !!foundSpecifiedChildRoute;
  }
  return false;
};

export const isChildRoute = (route: string): boolean => {
  return !routeConfig.filter((item) => item.path === route)[0];
};

export const getParentRouteOfSpecificChild = (
  routes: undefined | RouteData[],
  child: string,
): RouteData | undefined => {
  if (routes) {
    let foundParent = routes.find((route) => {
      if (route.children) {
        const foundChildren = route.children.filter((childRoute) => childRoute.path === child);
        if (foundChildren[0]) {
          return route;
        }
      }
      return null;
    });
    let index = 0;
    while (!foundParent && index < routes.length) {
      if (routes[index].children !== undefined) {
        foundParent = getParentRouteOfSpecificChild(routes[index].children, child);
      }
      index++;
    }
    return foundParent;
  }
};

export const searchRoute = (routes: undefined | RouteData[], route: string): RouteData | undefined => {
  if (routes) {
    let found = routes.find((item) => item.path.includes(route));
    let index = 0;
    while (!found && index < routes.length) {
      if (routes[index].path.includes(route)) {
        break;
      } else {
        if (routes[index].children) {
          found = searchRoute(routes[index].children, route);
        }
      }
      index++;
    }
    return found;
  }
};

export const isValidRoute = (route: string): boolean => {
  return !!searchRoute(routeConfig, route);
};
