import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../component/common/blueBanner';
import { ROUTE } from '../../../../service/constant';
import { useStores } from '../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../service/util/customHooks/useSegment';
import { HomeRenewalNotification } from '../../policies/policy/renewals/components/homeRenewalNotification';
import { MotorRenewalNotification } from '../../policies/policy/renewals/components/motorRenewalNotification';
import { PolicyDocuments } from '../selectedPolicyDocumentsContent';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    margin: spacing(2, 0, 0),
    [breakpoints.down('xs')]: {
      marginBottom: spacing(3),
    },
  },
}));

export const SelectedPolicyDocuments: React.FC = observer(function SelectedPolicyDocuments() {
  const { eventTrack } = useSegment();
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected: autoPoliciySelected },
        home: { policySelected: homePolicySelected },
        isHome,
      },
    },
  } = useStores();

  useEffect(() => {
    eventTrack('Document List Viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  return (
    <Grid container direction="column">
      <BlueBanner title={t('breadcrumbs.documents')} route={ROUTE.POLICY_DETAILS} />
      <Grid item xs={12} className={classes.content}>
        {isHome && homePolicySelected.futureRenewalEffectiveDate === null && (
          <HomeRenewalNotification policy={homePolicySelected} />
        )}
        {!isHome && autoPoliciySelected.futureRenewalEffectiveDate === null && (
          <MotorRenewalNotification policy={autoPoliciySelected} />
        )}
        <PolicyDocuments />
      </Grid>
    </Grid>
  );
});
