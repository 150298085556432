import { useLocation } from 'react-router-dom';

import { ROUTE } from '../../constant';
import { useStores } from '../../state/store';

const useGlobalContext = (searchParams: URLSearchParams): boolean => {
  const {
    interfaceStore: { isCaura, setIsCaura },
  } = useStores();

  const isCauraParam = searchParams.get('isCaura');
  if (isCauraParam !== null) {
    const value = isCauraParam === 'true';
    setIsCaura(value);
    return false;
  }

  if (isCaura) {
    searchParams.set('isCaura', String(isCaura));
    return true;
  }

  return false;
};

const usePolicyContext = (searchParams: URLSearchParams): boolean => {
  const location = useLocation();
  const {
    dashboardStore: {
      insurance: {
        auto: { selectedPolicyNumber: selectedPolicyNumberAuto, setSelectedPolicyNumber: setSelectedPolicyNumberAuto },
        home: { selectedPolicyNumber: selectedPolicyNumberHome, setSelectedPolicyNumber: setSelectedPolicyNumberHome },
        isHome,
        setSelectedPolicyTypeByName,
      },
    },
  } = useStores();

  if (!location.pathname.startsWith(ROUTE.POLICY_DETAILS)) {
    return false;
  }

  const policyNumberParam = searchParams.get('policyNumber');
  const policyTypeParam = searchParams.get('policyType');

  if (policyNumberParam && policyTypeParam) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setSelectedPolicyTypeByName(policyTypeParam);
    if (policyTypeParam === 'home') {
      setSelectedPolicyNumberHome(policyNumberParam);
    } else {
      setSelectedPolicyNumberAuto(policyNumberParam);
    }
    return false;
  }

  searchParams.set('policyType', isHome ? 'home' : 'auto');
  searchParams.set('policyNumber', isHome ? selectedPolicyNumberHome : selectedPolicyNumberAuto);

  return true;
};

export const useRouteContext = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(decodeURIComponent(location.search));
  const contexts: ((searchParams: URLSearchParams) => boolean)[] = [useGlobalContext, usePolicyContext];
  const shouldReplaceUrl = contexts.map((context) => context(searchParams)).includes(true);
  if (shouldReplaceUrl) {
    const url = new URL(window.location.href);
    searchParams.forEach((value, key) => {
      url.searchParams.set(key, value);
    });
    window.history.replaceState(null, '', url);
  }
};
