import { FormControlLabel, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const CarsInHouseholdRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { insured, setInsuredPerson },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();

  const [value, setValue] = useState(insured.person.carsInHouseholdCd);
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.CARS_IN_HOUSEHOLD],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[LOOKUP.CARS_IN_HOUSEHOLD]);
    }
  }, [isLookupsFetched, lookupsByName]);

  /* istanbul ignore next */
  const handleContinue = () => {
    setInsuredPerson({ ...insured.person, carsInHouseholdCd: value });
    if (value === '') return;
    nextStep();
  };

  useEffect(() => {
    setTitle(t('MTAJourney.carsInHousehold.title'));
  }, []);

  return (
    <>
      {isLookupsFetched && (
        <>
          <Typography variant="body1">{t('MTAJourney.carsInHousehold.radioTitle')}</Typography>

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_CARS_IN_HOUSEHOLD_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_CARS_IN_HOUSEHOLD_CANCEL}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_CARS_IN_HOUSEHOLD_CONTINUE, handleContinue }}
      />
    </>
  );
});
