import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PaymentTemplate } from '../../../../../../component/common/paymentTemplate';
import { DIALOG_TYPE, ROUTE } from '../../../../../../service/constant';
import { IInsuredAddress } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { useForm } from '../../../../../../service/util/customHooks/useForm';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { useStepperContext } from '../mta/common/utils/stepperContext';

interface FindAddressForm {
  houseNameNumber: string;
  postcode: string;
}

export const MakePayment: React.FC = observer(function MakePayment() {
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
        isHome,
      },
    },
    interfaceStore: {
      dialog: { openDialog, closeDialog },
    },
    paymentStore: {
      paymentComplete,
      paymentError,
      isSubmitting,
      updateBillingAddress,
      updatePolicyNumber,
      setCardOwnerSelected,
      updatePaymentError,
      refundPayment,
    },
    mtaStore: {
      quote: { additionalPremium, administrationFee },
    },
  } = useStores();
  const { eventTrack } = useSegment();
  const {
    stepsData: { journeyType },
  } = useStepperContext();

  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | string>(policySelected.insured.address);
  const history = useHistory();

  const [cardholder, setCardholder] = useState<null | boolean>(null);

  const { handleSubmit } = useForm<FindAddressForm>({});

  const totalAmount = additionalPremium + administrationFee;
  const isDDCustomer = policySelected.paymentPlan !== 'YearlyCardPayment';

  useEffect(() => {
    if (typeof selectedAddress !== 'string') {
      updateBillingAddress(selectedAddress);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (cardholder !== null) {
      setCardOwnerSelected(cardholder);
    }
  }, [cardholder]);

  useEffect(() => {
    updatePolicyNumber(policySelected.policyNumber);
  }, [policySelected]);

  useEffect(() => {
    if (isSubmitting) {
      if (totalAmount > 0 && !isDDCustomer) {
        openDialog({ isFullScreen: true, type: DIALOG_TYPE.PROCESSING_PAYMENT });
      } else {
        openDialog({ isFullScreen: true, type: DIALOG_TYPE.UPDATING_DETAILS });
      }
    } else {
      closeDialog();
    }
    if (paymentComplete) {
      history.push(ROUTE.CHANGE_PAYMENT_CONFIRMATION);
    }
    if (paymentError) {
      eventTrack('Tech error message viewed', { journeyType, type: TRACK_TYPE.VIEW });
      history.push(ROUTE.CHANGE_PAYMENT_FAILED);
      updatePaymentError(false);
    }
  }, [paymentComplete, paymentError, isSubmitting]);

  useEffect(() => {
    if (totalAmount <= 0 || isDDCustomer) {
      // do empty payment
      refundPayment();
    }
  }, [totalAmount]);

  return (
    <PaymentTemplate
      isHome={isHome}
      policy={policySelected}
      showAdyen={totalAmount > 0}
      handleSubmit={handleSubmit}
      hasCardholder={cardholder}
      setHasCardholder={setCardholder}
      setSelectedAddress={setSelectedAddress}
      selectedAddress={selectedAddress}
      type="payment"
    />
  );
});
