import { Grid } from '@esure-cloud/react-components';

import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export const CarTooltipSkeleton: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Skeleton animation="wave" width="50%" />
      <Skeleton animation="wave" width="80%" />
    </Grid>
  );
};
