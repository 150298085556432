import { FormControlLabel, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { MtaTooltip } from '../../../../makeAchange/mta/common/components/mtaTooltip';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export const MaritalStatusRenewal: React.FC = observer(() => {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    renewalChangeStore: {
      renewalDetails: { selectedDriver, setSelectedDriver },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [personState, setPersonState] = useState(selectedDriver.person);

  const [value, setValue] = useState(personState.maritalStatusCd);
  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.MARITAL_STATUS],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[LOOKUP.MARITAL_STATUS]);
    }
  }, [isLookupsFetched, lookupsByName]);

  /* istanbul ignore next */
  const handleContinue = () => {
    setPersonState({ ...personState, maritalStatusCd: value });
    setSelectedDriver({ ...selectedDriver, person: { ...personState, maritalStatusCd: value } });
    if (value === '') return;
    nextStep();
  };

  useEffect(() => {
    setTitle(t('MTAJourney.driverMaritalStatus.title'));
  }, []);

  return (
    <>
      {isLookupsFetched && (
        <>
          <MtaTooltip
            description={<Typography variant="body1">{t('MTAJourney.relationshipStatus.radioTitle')}</Typography>}
            tooltipTitle={''}
          />

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_RELATIONSHIP_STATUS_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_RELATIONSHIP_STATUS_CANCEL}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_RELATIONSHIP_STATUS_CONTINUE, handleContinue }}
      />
    </>
  );
});
