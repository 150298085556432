import { CommonContainer, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../service/analytics';
import { IAutoPolicy, IInsuredAddress } from '../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../service/state/models/dashboard/homePolicyModel';
import { FormSubmit } from '../../service/util/customHooks/useForm';
import SelfService from '../../service/util/selfService';
import { AdyenControl } from '../../view/pages/policies/policy/makeAchange/MakePayment/adyenControl';
import { FindAddress } from '../../view/pages/policies/policy/makeAchange/MakePayment/findAddress';
import { PaymentDisclaimer } from '../../view/pages/policies/policy/makeAchange/MakePayment/paymentDisclaimer';

import { AmountSummary, ContainerType } from './amountSummary';
import { Choice } from './choice';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .address__box': {
      '&-formField': {
        marginBottom: spacing(2),
      },
      '&-heading': {
        margin: spacing(2, 0, 1, 0),
      },
      '&-label': {
        marginBottom: spacing(1),
      },
      '&-summary': {
        marginBottom: spacing(2),
      },
      maxWidth: 589,
    },
    '& .cardholder': {
      margin: spacing(3, 0),
    },
    '& .common-container': {
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      [breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: spacing(-4, -2, 0, -2),
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      color: palette.secondary.contrastText,
    },
    '& .title': {
      margin: spacing(5, 0),
    },
  },
}));

export interface PaymentTemplateProps {
  handleSubmit: (e: FormSubmit) => Promise<void>;
  hasCardholder: boolean | null;
  isHome: boolean;
  policy: IAutoPolicy | IHomePolicy;
  selectedAddress: IInsuredAddress | string;
  setHasCardholder: React.Dispatch<React.SetStateAction<null | boolean>> | ((isCardOwnerSelected: boolean) => void);
  setSelectedAddress: (value: IInsuredAddress | string) => void;
  showAdyen?: boolean;
  type: ContainerType;
}

export const getExistingAddress = (isHome: boolean, policy: IAutoPolicy | IHomePolicy): IInsuredAddress => {
  return isHome ? policy.homeAddress : policy.insured.address;
};
export const PaymentTemplate: React.FC<PaymentTemplateProps> = ({
  handleSubmit,
  isHome,
  policy,
  setSelectedAddress,
  selectedAddress,
  showAdyen = true,
  setHasCardholder,
  hasCardholder,
  type,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const addressButtonId =
    type === 'renewalPayment' ? COMPONENT_ID.RENEWAL_PAYMENT_EDIT_ADDRESS : COMPONENT_ID.MTA_PAYMENT_EDIT_ADDRESS;

  return (
    <Grid className={classes.root}>
      <CommonContainer className="common-container">
        <Typography variant="h3">{t('payment.banner')}</Typography>
      </CommonContainer>
      <Typography className="title" variant="h2">
        {t('payment.title')}
      </Typography>

      <AmountSummary type={type} />

      <Grid component="form" onSubmit={handleSubmit} container direction="column" className="address__box">
        <Typography className="address__box-heading" variant="h3">
          {t('MTAJourney.payment.findAddressTitle')}
        </Typography>

        {selectedAddress ? (
          <Grid item>
            <Typography variant="body1" className="home-address">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    typeof selectedAddress === 'string'
                      ? selectedAddress
                      : SelfService.formatAddress(selectedAddress, true).full,
                }}
              />
            </Typography>
            <Link
              target="_blank"
              className="change-link"
              data-testid={addressButtonId}
              onClick={() => setSelectedAddress('')}
            >
              {t('MTAJourney.payment.editAddress')}
            </Link>
          </Grid>
        ) : (
          <FindAddress
            existingAddress={getExistingAddress(isHome, policy)}
            houseNumber=""
            postcode=""
            setSelectedAddress={setSelectedAddress}
          />
        )}

        <Grid container direction="column" className="cardholder">
          <Typography className="address__box-heading" variant="h3">
            {t('MTAJourney.payment.cardholder')}
          </Typography>
          <Choice
            identifier="areYouTheCardHolder"
            onChange={(event) => setHasCardholder(event.target.value === 'true')}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            error={hasCardholder === null}
            radioValue={hasCardholder}
          />
        </Grid>

        {showAdyen && <AdyenControl />}

        <PaymentDisclaimer />
      </Grid>
    </Grid>
  );
};
