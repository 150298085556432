import {
  FormHelperText,
  Grid,
  InputAdornment,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  OutlinedInput,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import LuxonUtils from '@date-io/luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Info } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import IconSure from '../../../../../../../../component/common/icon';
import { SelectDropDown } from '../../../../../../../../component/common/selectDropDown';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { useStores } from '../../../../../../../../service/state/store';
import { getDateTimeNow } from '../../../../../../../../service/util/dateUtils';
import SelfService from '../../../../../../../../service/util/selfService';
import { MtaTooltip } from '../../../../makeAchange/mta/common/components/mtaTooltip';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .date-of-birth': {
      '&__date-picker': {
        '& > div': {
          paddingRight: 0,
        },
        '& span': {
          color: palette.secondary.main,
        },
        margin: 0,
      },
      '&__label': {
        marginBottom: spacing(1),
      },
    },
    '& .resident__month': {
      '& > div': {
        paddingRight: spacing(1),
        width: '100%',
      },
      '&__field': {
        '& .MuiSelect-select': {
          paddingBottom: spacing(0.9),
          paddingTop: spacing(0.9),
        },
        '&:after': {
          content: 'none',
        },
        '&:before': {
          content: 'none',
        },
        border: '1px solid',
        borderColor: palette.divider,
        borderRadius: spacing(1),
        width: '100%',
      },
      flexBasis: '50%',
      maxWidth: '50%',
    },
    '& .resident__year': {
      '&__field': {
        width: '100%',
      },
      flexBasis: '50%',
      maxWidth: '50%',
    },
    display: 'grid',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    gap: spacing(3),
    marginTop: spacing(3),
    maxWidth: '352px',
  },
}));

export const DateOfBirthRenewal: React.FC = observer(() => {
  const classes = useStyles();
  const {
    interfaceStore: { isMobile },
    renewalChangeStore: {
      renewalDetails: { selectedDriver, setSelectedDriver },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();
  const [personState, setPersonState] = useState(selectedDriver.person);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dateOfBirth, residentSinceBirth, residentSinceMonthsCd, residentSinceYearsCd } = personState;

  const [monthError, setMonthError] = useState('');
  const [yearError, setYearError] = useState('');
  const [dateErr, setDateErr] = useState(false);

  useEffect(() => {
    setTitle(t('MTAJourney.Driver.dateOfBirth'));
  }, []);

  const handleChangeDob = (value: MaterialUiPickersDate | null) => {
    if (value?.isValid) {
      const dob = SelfService.formatDate(value.set({ hour: 0, minute: 0 }).toString());
      setPersonState({
        ...personState,
        dateOfBirth: dob,
      });
      checkDate(residentSinceMonthsCd, residentSinceYearsCd, dob);
    } else {
      setPersonState({
        ...personState,
        dateOfBirth: null,
      });
    }
  };

  const isValid = (): boolean => {
    let residentValid = true;
    if (!residentSinceBirth) {
      residentValid = residentSinceMonthsCd === '0' || residentSinceYearsCd === '' ? false : true;
    }

    return dateErr ||
      (monthError !== '' && !residentSinceBirth) ||
      (yearError !== '' && !residentSinceBirth) ||
      !residentValid ||
      residentSinceBirth === null ||
      dateOfBirth === null
      ? false
      : true;
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);
    isValid() &&
      setSelectedDriver({
        ...selectedDriver,
        person: {
          ...selectedDriver.person,
          dateOfBirth,
          residentSinceBirth,
          ...(residentSinceBirth
            ? { residentSinceMonthsCd: '', residentSinceYearsCd: '' }
            : { residentSinceMonthsCd, residentSinceYearsCd }),
        },
      });
  };

  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };

  const selectMonthValues = () => {
    const values = [
      {
        label: 'Month',
        value: '0',
      },
    ];
    return values.concat(Info.months().map((month, index) => ({ label: month, value: `${index + 1}` })));
  };

  const isNumeric = (str: string) => {
    return /^\d+$/.test(str);
  };

  const handleMonthChange = (value: string) => {
    setPersonState({ ...personState, residentSinceMonthsCd: value });
    checkDate(value, residentSinceYearsCd, dateOfBirth);
  };

  const checkDate = (month: string, year: string, dob: string | null) => {
    // if dob year is same as resident year then check the month
    if (dob !== null && !residentSinceBirth) {
      const dobObject = new Date(dob || '');
      if (parseInt(year) === dobObject.getFullYear()) {
        if (parseInt(month) < dobObject.getMonth() + 1) {
          setMonthError(t('validations.monthOfMove'));
        } else {
          setMonthError('');
        }
      } else {
        setMonthError('');
      }

      // check resident year is not before dob year
      if (!isNumeric(year)) {
        setYearError(t('validations.numericValueError'));
      } else if (year.length < 3) {
        setYearError(t('validations.yearEmpty'));
      } else {
        if (parseInt(year) < dobObject.getFullYear()) {
          setYearError(t('validations.yearOfMove'));
        } else if (parseInt(year) > getDateTimeNow().year) {
          setYearError(t('validations.futureyear'));
        } else {
          setYearError('');
        }
      }
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonState({ ...personState, residentSinceYearsCd: e.target.value });
    checkDate(residentSinceMonthsCd, e.target.value, dateOfBirth);
  };

  const handleDateError = (err: React.ReactNode) => {
    if (err) {
      (err as string).length > 0 ? setDateErr(true) : setDateErr(false);
    } else {
      setDateErr(false);
    }
  };

  useEffect(() => {
    if (!personState.residentSinceBirth) {
      checkDate(residentSinceMonthsCd, residentSinceYearsCd, dateOfBirth);
    }
  }, [personState.residentSinceBirth]);

  return (
    <Grid className={classes.root} item xs={isMobile ? 12 : 6}>
      <Grid>
        <MtaTooltip tooltipTitle={t('tooltips.dateOfBirth')} description={t('makeChange.person.dob')} />
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <KeyboardDatePicker
            disableToolbar
            value={dateOfBirth}
            maxDate={getDateTimeNow()}
            className="date-of-birth__date-picker"
            format="dd/MM/yyyy"
            onChange={handleChangeDob}
            variant="inline"
            autoOk
            color="secondary"
            inputVariant="outlined"
            views={['date', 'month', 'year']}
            onError={(err) => handleDateError(err)}
            maxDateMessage={t('validations.futuredob')}
          />
        </MuiPickersUtilsProvider>

        {dateOfBirth === null && isSubmitting && <FormHelperText error>{t('validations.dobEmpty')}</FormHelperText>}
      </Grid>
      <Grid container direction="column" className="resident">
        <Typography className="date-of-birth__label">{t('MTAJourney.Driver.residentSinceBirth')}</Typography>
        <Choice
          identifier="areYouTheCardHolder"
          onChange={(event) => setPersonState({ ...personState, residentSinceBirth: event.target.value === 'true' })}
          left={{ label: t('labels.no') }}
          right={{ label: t('labels.yes') }}
          radioValue={residentSinceBirth}
          error={residentSinceBirth === null && isSubmitting}
        />
      </Grid>
      {!residentSinceBirth && residentSinceBirth !== null && (
        <Grid container direction="row">
          <Typography className="date-of-birth__label">{t('MTAJourney.Driver.whenDidTheyMove')}</Typography>
          <Grid className="resident__month">
            <SelectDropDown
              labelId="select-dropdown"
              extraClass="resident__month__field"
              defaultValue={residentSinceMonthsCd || '0'}
              onChangeHandler={handleMonthChange}
              values={selectMonthValues()}
            />
            <FormHelperText data-testid="month-error" error>
              {monthError}
            </FormHelperText>
            {(residentSinceMonthsCd === '0' || residentSinceMonthsCd === '') && monthError === '' && isSubmitting && (
              <FormHelperText error>{t('validations.monthEmpty')}</FormHelperText>
            )}
          </Grid>
          <Grid className="resident__year">
            <OutlinedInput
              className="resident__year__field"
              color="secondary"
              endAdornment={
                yearError !== '' &&
                isSubmitting && (
                  <InputAdornment position="end">
                    <IconSure icon="error" type="solid" color="error" />
                  </InputAdornment>
                )
              }
              error={yearError !== '' && isSubmitting}
              id="text"
              inputProps={{ maxLength: 4 }}
              onChange={handleYearChange}
              fullWidth
              type="text"
              placeholder="YYYY"
              value={residentSinceYearsCd}
            />
            <FormHelperText error>{yearError}</FormHelperText>
            {residentSinceYearsCd === '' && yearError === '' && isSubmitting && (
              <FormHelperText error>{t('validations.yearEmpty')}</FormHelperText>
            )}
          </Grid>
        </Grid>
      )}
      <RenewalChangeFooter
        cancelButtonId={COMPONENT_ID.RENEWAL_DOB_CANCEL}
        backButtonId={COMPONENT_ID.RENEWAL_DOB_BACK}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_DOB_CONTINUE, handleContinue }}
      />
    </Grid>
  );
});
