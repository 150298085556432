import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PaymentTemplate, getExistingAddress } from '../../../../../../component/common/paymentTemplate';
import { DIALOG_TYPE, ROUTE } from '../../../../../../service/constant';
import {
  IAutoPolicy,
  IAutoPolicyRenewalQuoteDetails,
  IInsuredAddress,
  IRenewalPendingMTAQuote,
} from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import {
  IHomePolicy,
  IHomePolicyRenewalQuoteDetails,
} from '../../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { useForm } from '../../../../../../service/util/customHooks/useForm';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

interface FindAddressForm {
  houseNameNumber: string;
  postcode: string;
}

export const RenewalPayment = observer(() => {
  const {
    dashboardStore: {
      insurance: { auto, home, isHome, selectedPolicyType },
    },
    interfaceStore: {
      dialog: { openDialog, closeDialog },
    },
    paymentStore: {
      additionalDetailsShown,
      paymentComplete,
      paymentError,
      isCardOwnerSelected,
      isSubmitting,
      updateBillingAddress,
      updatePolicyNumber,
      setCardOwnerSelected,
      setIsHome,
      updatePaymentError,
    },
    renewalChangeStore: { pendingMTAQuoteSelected },
  } = useStores();

  const policySelected: IAutoPolicy | IHomePolicy = isHome ? home.policySelected : auto.policySelected;

  const address = getExistingAddress(isHome, policySelected);

  let renewalDetails: IAutoPolicyRenewalQuoteDetails | IHomePolicyRenewalQuoteDetails | IRenewalPendingMTAQuote;

  if (isHome) {
    renewalDetails = home.policySelected.policyRenewalQuoteDetails;
  } else if (pendingMTAQuoteSelected && auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote) {
    renewalDetails = auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote;
  } else {
    renewalDetails = auto.policySelected.policyRenewalQuoteDetails;
  }

  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | string>(address);
  const history = useHistory();
  const { eventTrack } = useSegment();

  const { handleSubmit } = useForm<FindAddressForm>({});

  useEffect(() => {
    if (typeof selectedAddress !== 'string') {
      updateBillingAddress(selectedAddress);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (isCardOwnerSelected !== null) {
      setCardOwnerSelected(isCardOwnerSelected);
    }
    return () => {
      setCardOwnerSelected(null);
    };
  }, [isCardOwnerSelected]);

  useEffect(() => {
    updatePolicyNumber(policySelected.policyNumber);
  }, [policySelected]);

  useEffect(() => {
    setIsHome(isHome);
  }, [selectedPolicyType]);

  useEffect(() => {
    const policyNumber = policySelected.policyNumber;
    isHome
      ? eventTrack('Home Renewal - payment page shown', {
          policyNumber,
          type: TRACK_TYPE.VIEW,
        })
      : eventTrack('Motor Renewal - payment page shown', {
          policyNumber,
          type: TRACK_TYPE.VIEW,
        });
  }, []);

  useEffect(() => {
    const policyNumber = policySelected.policyNumber;
    if (additionalDetailsShown) {
      isHome
        ? eventTrack('Home Renewal - additional details requested', {
            policyNumber,
            type: TRACK_TYPE.VIEW,
          })
        : eventTrack('Motor Renewal - additional details requested', {
            policyNumber,
            type: TRACK_TYPE.VIEW,
          });
    }
  }, [additionalDetailsShown]);

  useEffect(() => {
    if (isSubmitting) {
      if (renewalDetails.currentYearTotalCost) {
        openDialog({ isFullScreen: true, type: DIALOG_TYPE.PROCESSING_PAYMENT });
      }
    } else {
      closeDialog();
    }
    if (paymentComplete) {
      history.push(ROUTE.RENEWAL_PAYMENT_SUCCESS);
    }
    if (paymentError) {
      history.push(ROUTE.RENEWAL_PAYMENT_FAILED);
      updatePaymentError(false);
    }
  }, [paymentComplete, paymentError, isSubmitting]);

  return (
    <PaymentTemplate
      isHome={isHome}
      policy={policySelected}
      handleSubmit={handleSubmit}
      hasCardholder={isCardOwnerSelected}
      setHasCardholder={setCardOwnerSelected}
      setSelectedAddress={setSelectedAddress}
      selectedAddress={selectedAddress}
      type="renewalPayment"
    />
  );
});
