import { Button, Grid, Link } from '@esure-cloud/react-components';

import { Typography, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Alert } from '../../../../../../../../component/common/alert';
import { AmountSummary } from '../../../../../../../../component/common/amountSummary';
import { ConfirmationMessage } from '../../../../../../../../component/common/confirmationMessage';
import IconSure from '../../../../../../../../component/common/icon';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { DIALOG_TYPE, JOURNEY_TYPE, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { getDateTimeNow } from '../../../../../../../../service/util/dateUtils';
import SelfService from '../../../../../../../../service/util/selfService';
import { useStepperContext } from '../../common/utils/stepperContext';
import { DrivingOtherCarsBanner } from '../../drivers/components/drivingOtherCarsBanner';
import { QuoteExcesses } from '../components/quoteExcesses';
import { QuoteSummaryChanges } from '../components/quoteSummaryChanges';
import { UpdatedRenewalQuote } from '../components/updatedRenewalQuote';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    '& .banner-info': {
      padding: 0,
    },
    '& .buttons-container': {
      '& #cancel-link': {
        fontSize: '1rem',
        marginRight: spacing(2),
        order: 1,
      },
      '& #pay-button': {
        order: 2,
      },
      marginTop: spacing(2),
      [breakpoints.down('xs')]: {
        '& #cancel-link': {
          marginBottom: spacing(2),
          order: 2,
        },
        '& #pay-button': {
          order: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(3),
        margin: 'auto',
        width: 'fit-content',
      },
    },
    '& .chat-section': {
      '&__link': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
      gap: 10,
    },
    '& .tracker-section': {
      '&__bold-item': {
        fontWeight: 700,
      },
      border: `2px solid ${palette.secondary.dark}50`,
      borderRadius: spacing(1),
      gap: 10,
      padding: spacing(2),
    },
    gap: spacing(4),
  },
}));

export const QuoteReady: React.FC = observer(function QuoteReady() {
  const {
    stepsData: { journeyType },
    setTitle,
    setOverrideStepCount,
    overrideStepCount,
  } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const classes = useStyles();

  const {
    interfaceStore: {
      dialog: { openDialog },
      hasFeatureFlag,
      clearStepAutoComplete,
    },
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { paymentPlan, migrated, expirationDate },
          mainDriver: policyMainDriver,
        },
      },
    },
    mtaStore: {
      setMtaInitialised,
      pendedMTA: {
        vehicle: { purchaseDate },
        drivers,
      },
      quote,
    },
  } = useStores();
  const {
    additionalPremium,
    administrationFee,
    paymentPlan: { chargeForCredit },
    trackerRequiredInd,
  } = quote;

  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isCarOwned =
    journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
      ? purchaseDate && DateTime.fromISO(purchaseDate) <= getDateTimeNow()
      : true;

  const isTrackerRequired = journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR && trackerRequiredInd;

  const isDDCustomer = paymentPlan !== 'YearlyCardPayment';

  const totalAmount = additionalPremium + administrationFee + chargeForCredit;

  const pendedMtaMainDriver = drivers.find((driver) => driver.mainDriver);

  const hasMainDriverChanged = pendedMtaMainDriver?.uuid !== policyMainDriver.uuid;

  const isDriverCluster =
    journeyType === JOURNEY_TYPE.ADD_DRIVER ||
    journeyType === JOURNEY_TYPE.REMOVE_DRIVER ||
    journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

  const totalAmountAnnual = additionalPremium + administrationFee;

  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;
  const insideRenewal = remainingDays <= 32 && remainingDays >= 0;

  const requiresPayment = !isDDCustomer && totalAmount > 0;

  useEffect(() => {
    setTitle(t('MTAJourney.QuoteReady.title'));
    if (journeyType === JOURNEY_TYPE.CHANGE_DRIVER) {
      setOverrideStepCount(overrideStepCount + 1);
    }

    //reset the mta data next time a multi mta is started
    setMtaInitialised(false);
    clearStepAutoComplete();
    window.scrollTo(0, 0);
  }, []);

  const handlePayByLink = () => {
    setIsSubmitting(true);

    if (confirmed) {
      if (migrated && !isDDCustomer && totalAmount < 0) {
        openDialog({
          title: t('assistedPayment.refund'),
          type: DIALOG_TYPE.FETCH_ADVISER_ASSISTED_PAYMENT,
        });
      } else {
        history.push(ROUTE.CHANGE_PAYMENT);
      }
    }
  };

  return (
    <Grid container item className={classes.root} xs={12} md={9}>
      {!isCarOwned && <Alert className="banner-info" severity="info" message={t('MTAJourney.QuoteReady.ownCarInfo')} />}

      <AmountSummary type={isDDCustomer ? 'ddCustomer' : 'annualCustomer'} />

      <QuoteSummaryChanges />

      {isDDCustomer && totalAmount > 0 && (
        <Grid container direction="row" className="chat-section">
          <Typography>
            <Trans
              i18nKey="MTAJourney.QuoteReady.DDCustomerPay"
              t={t}
              values={{
                value: Math.abs(totalAmountAnnual),
              }}
              components={[<span className="tracker-section__bold-item" />]}
            />
          </Typography>
          <IconSure icon="chat" color="secondary" type="solid" />
          <Link
            onClick={() =>
              openDialog({
                title: t('fetchAdviserPopup.connectToSpecialist'),
                type: DIALOG_TYPE.DD_CUSTOMER_ASSISTED_PAYMENT,
              })
            }
            className="chat-section__link"
          >
            <Typography>{t('buttons.chatWithASpecialist')}</Typography>
          </Link>
        </Grid>
      )}

      {isDriverCluster && hasMainDriverChanged && <DrivingOtherCarsBanner />}

      {insideRenewal && <UpdatedRenewalQuote />}

      <QuoteExcesses />

      {isTrackerRequired && hasFeatureFlag('BDILMM-2629') && (
        <Grid container className="tracker-section" direction="column">
          <Typography variant="h2">{t('MTAJourney.QuoteReady.trackerEndorsement.title')}</Typography>
          <Typography variant="body1" className="tracker-section__bold-item">
            {t('MTAJourney.QuoteReady.trackerEndorsement.subtitle')}
          </Typography>
          <Typography variant="body1">{t('MTAJourney.QuoteReady.trackerEndorsement.body')}</Typography>
        </Grid>
      )}

      {isCarOwned && (
        <ConfirmationMessage
          hasError={!confirmed && isSubmitting}
          message={t('MTAJourney.QuoteReady.confirmationMessage')}
          checked={confirmed}
          setChecked={setConfirmed}
        />
      )}
      <Grid item xs={12}>
        <Typography variant="body1">{t('MTAJourney.QuoteReady.remainTheSame')}</Typography>
      </Grid>
      {isCarOwned && (
        <Grid container className="buttons-container">
          <Button
            data-testid={COMPONENT_ID.MTA_CANCEL_BUTTON_QUOTE_READY}
            id="cancel-link"
            color="secondary"
            onClick={() => history.push(ROUTE.POLICY_DETAILS)}
            variant="text"
          >
            {t('MTAJourney.button.cancel')}
          </Button>
          <Button
            data-testid={
              requiresPayment ? COMPONENT_ID.MTA_PAY_BUTTON_QUOTE_READY : COMPONENT_ID.MTA_CONFIRM_BUTTON_QUOTE_READY
            }
            color="primary"
            variant="contained"
            id="pay-button"
            onClick={handlePayByLink}
          >
            {requiresPayment ? t('MTAJourney.button.continueToPay') : t('MTAJourney.button.confirm')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
});
