import { types } from 'mobx-state-tree';

export const User = types
  .model('User', {
    email: types.optional(types.string, ''),
    userId: types.optional(types.string, ''),
    userLoggedIn: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setEmail(newValue: string) {
      self.email = newValue;
    },
    setUserId(newValue: string) {
      self.userId = newValue;
    },
    setUserLoggedIn(newValue: boolean) {
      self.userLoggedIn = newValue;
    },
  }));
