import { Grid, alpha, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CarFoundDetails from '../../../../../../../../component/common/mta/carFoundDetails';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ palette, spacing }) => ({
  box: {
    '& .car-properties': {
      '&__item': {
        '&:first-child': {
          paddingRight: spacing(2),
        },
        '&:not(:first-child)': {
          '&:before': {
            background: alpha(palette.common.black, 0.26),
            content: `''`,
            height: 15,
            left: -1,
            position: 'absolute',
            top: 6,
            width: 2,
          },
          padding: spacing(0, 2),
        },
        position: 'relative',
      },
      backgroundColor: palette.grey[100],
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    '& .content': {
      '&__grid': {
        gap: 18,
        width: 'fit-content',
      },
      '&__subtitle': {
        marginBottom: spacing(1),
      },
      borderColor: alpha(palette.secondary.main, 0.5),
      borderTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .title-box': {
      backgroundColor: alpha(palette.secondary.main, 0.08),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: alpha(palette.secondary.main, 0.5),
    },
  },
}));

export const CarFoundRenewal: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    setTitle,
    stepsData: { journeyType },
  } = useRenewalStepperContext();

  const {
    renewalChangeStore: {
      renewalDetails: { vehicle },
    },
    interfaceStore: {
      dialog: { openDialog },
      isMobile,
    },
  } = useStores();

  useEffect(() => {
    setTitle(
      journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
        ? t('MTAJourney.CarFound.checkDetails.title')
        : t('MTAJourney.CarFound.checkDetails.regFoundTitle'),
    );
  }, []);

  return (
    <Grid item className={classes.box} xs={isMobile ? 12 : 6}>
      <CarFoundDetails vehicle={vehicle} openDialog={openDialog} />
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_CAR_FOUND_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_CAR_FOUND_CANCEL}
        primaryButton={{
          buttonId: COMPONENT_ID.RENEWAL_CAR_FOUND_SAVE,
        }}
      />
    </Grid>
  );
});
