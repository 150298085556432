import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationPaymentBlock } from '../../../../../../component/common/confirmationPaymentBlock';
import { ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

export const MotorRenewalChangeConfirmation: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyNumber },
        },
      },
      fetchPolicy,
    },
  } = useStores();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPolicy(policyNumber).then(() => setIsLoading(false));
  }, []);

  const { eventTrack } = useSegment();

  useEffect(() => {
    eventTrack('Motor Renewal - Change confirmation page shown', {
      policyNumber,
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  return (
    <ConfirmationPaymentBlock
      loading={isLoading}
      button={t('policyManagement.motorRenewalChangeConfirmation.button')}
      subtitle={t('policyManagement.motorRenewalChangeConfirmation.subtitle')}
      thankYouText={t('policyManagement.motorRenewalChangeConfirmation.thankYouText')}
      emailConfirmation={t('policyManagement.motorRenewalChangeConfirmation.emailConfirmation')}
      url={ROUTE.POLICY_DETAILS}
      hasMultiplePoliciesBanner={true}
    />
  );
};
