import { Alert, AlertTitle, Box, Button, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../service/analytics';
import { IAutoPolicy } from '../../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../service/state/store';

interface PolicyCardFailedLoadingProps {
  policy: IAutoPolicy | IHomePolicy;
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: '100%',
  },
  content: {
    marginLeft: spacing(-4.5),
  },
  paragraph: {
    marginBottom: spacing(1),
    marginTop: spacing(1.5),
  },
  paragraphLast: {
    marginBottom: spacing(1.5),
  },
}));

export const PolicyCardFailedLoading = observer(function PolicyCardFailedLoading({
  policy,
}: PolicyCardFailedLoadingProps) {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
  } = useStores();

  const openChat = () => {
    startChat({
      customContactAttributes: {
        // Specify the policy number, otherwise the start chat code will just take the first policy number on the account
        policyNo: policy.policyNumber,
        // Contact flows will use this attribute for intent/routing
        portalHomePagePolicyNotLoaded: 'true',
      },
      initiator: 'Policy Card Failed Loading',
    });
  };

  return (
    <Alert severity="error" className={classes.container}>
      <AlertTitle>{t('policyFailedLoading.title', { policyNumber: policy.policyNumber })}</AlertTitle>
      <Box className={classes.content}>
        <Typography variant="body2" className={classes.paragraph}>
          {t('policyFailedLoading.p1')}
        </Typography>
        <Typography variant="body2" className={classes.paragraphLast}>
          {t('policyFailedLoading.p2')}
        </Typography>
        <Button
          // specify overrides for the policy number, otherwise the track event function will just take the first policy number on the account
          trackOverrides={{
            properties: {
              policyNumber: policy.policyNumber,
              policyStatus: policy.policyStatus,
              policyType: policy.policyTypeCd,
            },
          }}
          data-testid={COMPONENT_ID.CHAT_POLICY_FAILED_LOAD_BUTTON}
          variant="outlined"
          color="secondary"
          onClick={openChat}
        >
          {t('buttons.chatWithUs')}
        </Button>
      </Box>
    </Alert>
  );
});
