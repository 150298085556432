import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import Skeleton from '@material-ui/lab/Skeleton';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Success } from '../../animations/success';
import { ROUTE } from '../../service/constant';
import { MultiplePoliciesBanner } from '../../view/pages/policies/policy/makeAchange/mta/yourDetails/components/multiplePoliciesBanner';
import { CommonButton } from '../common/button/commonButton';

import { BlueBanner } from './blueBanner';

const useStyles = makeStyles(() => ({
  root: {
    '& .content-container': {
      gap: 25,
      marginTop: 30,
    },
  },
}));

export interface ConfirmationDetailsProps {
  amount?: number;
  button: string;
  buttonId?: string;
  buttonOnClick?: () => void;
  description?: string;
  emailConfirmation: string;
  hasMultiplePoliciesBanner?: boolean;
  loading?: boolean;
  subtitle: string;
  thankYouText?: string;
  title?: string;
  url: ROUTE;
}

export const ConfirmationPaymentBlock: React.FC<ConfirmationDetailsProps> = observer(function ConfirmationPaymentBlock({
  amount,
  button,
  buttonId,
  buttonOnClick,
  description,
  emailConfirmation,
  subtitle,
  title,
  url,
  hasMultiplePoliciesBanner,
  thankYouText,
  loading = false,
}) {
  const classes = useStyles();

  const { t } = useTranslation('myAccount');

  return (
    <Grid className={classes.root}>
      <BlueBanner title={t('confirmationPage.title')} />

      <Grid container direction="column" alignContent="center" alignItems="center" className="content-container">
        <Success />
        <>
          <Typography variant="h1" align="center">
            {loading ? <Skeleton width={120} /> : amount ? t('core:price.pound', { value: amount }) : title}
          </Typography>
          {subtitle !== '' && (
            <Typography variant="h3" align="center">
              {loading ? <Skeleton width={160} /> : subtitle}
            </Typography>
          )}
        </>
        {description && (
          <Typography variant="h3" align="center">
            {loading ? <Skeleton width={430} /> : description}
          </Typography>
        )}
        <Typography variant="body1" align="center">
          {loading ? <Skeleton width={430} /> : thankYouText}
        </Typography>

        <Typography variant="body1" align="center">
          {loading ? <Skeleton width={430} /> : emailConfirmation}
        </Typography>
        {!loading && (
          <>
            {hasMultiplePoliciesBanner && <MultiplePoliciesBanner />}
            <CommonButton data-testid={buttonId} text={button} size="large" url={url} onClick={buttonOnClick} />
          </>
        )}
      </Grid>
    </Grid>
  );
});
