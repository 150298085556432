import { Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../../../service/state/store';
import { useStepperContext } from '../../common/utils/stepperContext';

import { SingleColumnEditPageProps } from './singleColumnEditPage';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .divider': {
      borderBottom: `1px solid ${palette.divider}`,
      marginTop: spacing(1),
      width: '100%',
    },
    '& .item': {
      '&-action': {
        cursor: 'pointer',
        fontWeight: 'normal',
        marginLeft: spacing(2),
        textDecoration: 'none',
      },

      '&-value': {
        fontWeight: 700,
      },
      '&:not(:last-child)': {
        '&::after': {
          background: palette.divider,
          bottom: 0,
          content: '""',
          height: '1px',
          position: 'absolute',
          width: '100%',
        },
      },
      padding: spacing(1.5, 0),
      position: 'relative',
    },
  },
}));

export const EditPageRows: React.FC<SingleColumnEditPageProps> = observer(function EditPageRows({
  handleEdit,
  rows,
  driverUuid,
}) {
  const { t } = useTranslation('myAccount');
  const { setTitle, setEditMode } = useStepperContext();
  const classes = useStyles();
  const {
    mtaStore: {
      pendedMTA: { setSelectedDriverUUID },
    },
  } = useStores();

  return (
    <Grid container item direction="column" md wrap="nowrap" className={classes.root}>
      {rows.map(({ label, values, id, title, showDivider = false, ignoreEdit }) => (
        <Grid key={id} className="item">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid>
              <Typography variant="body1">{label}</Typography>
              {values.map((v, index) =>
                typeof v === 'string' ? (
                  <Typography variant="body1" className="item-value" key={index}>
                    {v}
                  </Typography>
                ) : (
                  <Grid key={index}>{v}</Grid>
                ),
              )}
            </Grid>
            {!ignoreEdit && (
              <Link
                data-testid={`${id}Edit`}
                className="item-action"
                onClick={() => {
                  if (driverUuid) {
                    setSelectedDriverUUID(driverUuid);
                  }
                  handleEdit(id);
                  setEditMode(true);
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  setTitle(title || label);
                }}
              >
                {t('MTAJourney.CarUsage.edit')}
              </Link>
            )}
          </Grid>
          {showDivider && <Grid className="divider" />}
        </Grid>
      ))}
    </Grid>
  );
});
