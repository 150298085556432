import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { isToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import IconSure from '../../../../../../component/common/icon';
import { SVGWrapper } from '../../../../../../images/SVGWrapper';
import { DATE_TYPES, DIALOG_TYPE, ROUTE } from '../../../../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';
import { HomeRenewalNotification } from '../components/homeRenewalNotification';
import { MotorRenewalNotification } from '../components/motorRenewalNotification';

export const useAutoRenewalStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  bulletList: {
    '& li': {
      '&:before': {
        background: palette.secondary.main,
        borderRadius: '50%',
        content: `''`,
        display: 'block',
        height: '8px',
        left: spacing(1),
        position: 'absolute',
        top: spacing(1),
        width: '8px',
      },
      display: 'block',
      marginBottom: spacing(1),
      paddingLeft: spacing(4),
      position: 'relative',
    },
    listStyle: 'none',
    marginBottom: spacing(2),
    marginTop: spacing(2),
    padding: 0,
  },
  radioButton: { padding: spacing(1, 1, 1, 0) },
  root: {
    '& .alert': {
      marginTop: spacing(2.5),
    },
    '& .button': {
      marginRight: spacing(2),
      minWidth: '160px',
      [breakpoints.down('xs')]: {
        marginRight: spacing(1),
        minWidth: 'auto',
        width: '48%',
      },
    },
    '& .button:last-child': {
      marginRight: 0,
    },
    '& .buttons': {
      marginTop: spacing(2),
    },
    '& .ctaText, & .optionContainer, & .heading': {
      marginTop: spacing(2),
      width: '100%',
    },
    '& .icon': {
      marginRight: spacing(1),
      position: 'relative',
      top: spacing(2),
    },
    '& .subHeading': {
      marginTop: spacing(4),
    },
    [breakpoints.down('xs')]: {
      marginBottom: spacing(6),
    },
  },
}));

export const AutoRenewal: React.FC = observer(function AutoRenewal() {
  const {
    dashboardStore: {
      updateAutoRenewal,
      insurance: { auto, home, isHome },
    },
    interfaceStore: {
      chatBot: { startChat },
      dialog: { openDialog },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const classes = useAutoRenewalStyles();
  const { eventTrack } = useSegment();
  const history = useHistory();

  const renewalOnRadio = useRef<HTMLButtonElement | null>(null);
  const renewalOffRadio = useRef<HTMLButtonElement | null>(null);
  const ctaAreaRef = useRef<HTMLSpanElement | null>(null);

  const { autoRenewal, autoRenewalOption, expirationDate, futureRenewalEffectiveDate } = isHome
    ? home.policySelected
    : auto.policySelected;
  const initialAutoRenewal = autoRenewal ?? false;
  const [chosenAutoRenewal, setChosenAutoRenewal] = useState(initialAutoRenewal);
  const [submitting, setSubmitting] = useState(false);
  const [hasInteractedWithForm, setHasInteractedWithForm] = useState(false);

  const isPolicyDueToday = isToday(SelfService.calculatePolicyEndDate(expirationDate).toJSDate());

  const handleChat = () => {
    eventTrack(`${isHome ? 'Home' : 'Motor'} | Chat with a specialist clicked`, { type: TRACK_TYPE.CHAT });

    startChat({
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        ssRenAR: autoRenewal ? 'yes' : 'no',
        ssRenAROn: 'true',
        ssRenCPA: autoRenewalOption.type.length > 0 ? 'yes' : 'no',
      },
      initiator: `${isHome ? 'Home' : 'Motor'} Auto renewal - Chat with us`,
    });
  };

  const handleChatOnPolicyDueDate = () => {
    eventTrack(`${isHome ? 'Home' : 'Motor'} | Chat with a specialist policy due date clicked`, {
      type: TRACK_TYPE.CHAT,
    });

    startChat({
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        ssRenAR: autoRenewal ? 'yes' : 'no',
        ssRenAROffDateDue: 'true',
        ssRenCPA: autoRenewalOption.type.length > 0 ? 'yes' : 'no',
      },
      initiator: `${isHome ? 'Home' : 'Motor'} Auto renewal - Chat with us policy due date`,
    });
  };

  const handleSave = () => {
    setSubmitting(true);
    updateAutoRenewal(isHome ? home.selectedPolicyNumber : auto.selectedPolicyNumber)
      .then(() => history.push(ROUTE.YOUR_RENEWAL_DETAILS_AUTORENEWAL_CONFIRMATION))
      .catch(() => openDialog({ isFullScreen: true, type: DIALOG_TYPE.MTA_TECHNICAL_PROBLEMS }));
  };

  const handleBack = () => {
    history.push(ROUTE.POLICY_DETAILS);
  };

  const renewalOnContainer = () => (
    <>
      <FormControlLabel
        key="autoRenewalTrue"
        value={true}
        className="radioButton"
        control={<Radio className={classes.radioButton} ref={renewalOnRadio} />}
        label={<Typography variant="h3">{t('policyManagement.autoRenewalDetails.optionOnControl.label')}</Typography>}
        onClick={(event) => {
          event.stopPropagation();
          renewalOnRadio.current?.click();
        }}
      />
      <Typography variant="body2" gutterBottom>
        {t('policyManagement.autoRenewalDetails.optionOnControl.labelP1', {
          policyEndDate: formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT),
        })}
      </Typography>
    </>
  );

  const renewalOffContainer = () => (
    <>
      <FormControlLabel
        key="autoRenewalFalse"
        value={false}
        className="radioButton"
        control={<Radio className={classes.radioButton} ref={renewalOffRadio} />}
        label={<Typography variant="h3">{t('policyManagement.autoRenewalDetails.optionOffControl.label')}</Typography>}
        onClick={(event) => {
          event.stopPropagation();
          renewalOffRadio.current?.click();
        }}
      />
      <Typography variant="body2" gutterBottom>
        {t('policyManagement.autoRenewalDetails.optionOffControl.labelP1', {
          policyEndDate: formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT),
        })}
      </Typography>
    </>
  );

  const renewalOptionsArray = [renewalOnContainer, renewalOffContainer];

  const whatItMean: string[] = t('policyManagement.autoRenewalDetails.autoRenewalPreferences.endOfPolicy.bullets', {
    returnObjects: true,
  });

  const whyAutoRenewBullets: string[] = t(
    'policyManagement.autoRenewalDetails.autoRenewalPreferences.whyAutoRenew.bullets',
    {
      returnObjects: true,
    },
  );

  return (
    <Grid container direction="column" className={classes.root}>
      <BlueBanner title={t('policyManagement.autoRenewalDetails.heading')} route={ROUTE.POLICY_DETAILS} />
      {!isHome && futureRenewalEffectiveDate === null && <MotorRenewalNotification policy={auto.policySelected} />}
      {isHome && <HomeRenewalNotification policy={home.policySelected} />}

      {hasInteractedWithForm && initialAutoRenewal === chosenAutoRenewal && (
        <Alert severity="info" className="alert">
          {t('policyManagement.autoRenewalDetails.alreadySwitchedOnOff', {
            autoRenewalValue: initialAutoRenewal ? 'on' : 'off',
          })}
        </Alert>
      )}

      <Grid item xs={12}>
        <Typography variant="h2" color="secondary" gutterBottom className="heading">
          {t('policyManagement.autoRenewalDetails.title')}
        </Typography>
        <Grid container direction="row" alignItems="baseline">
          <Typography
            gutterBottom
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: t('policyManagement.autoRenewalDetails.renewalDateText'),
            }}
          />
          <Typography gutterBottom variant="body1">
            {t('policyManagement.autoRenewalDetails.renewalDate', {
              renewalDate: formatDate(expirationDate, DATE_TYPES.SHORT_SPACE),
            })}
          </Typography>
        </Grid>
        <Typography variant="h3" style={{ marginTop: '6px' }}>
          {t('policyManagement.autoRenewalDetails.autoRenewalPreferences.endOfPolicy.title')}
        </Typography>
        <ul className={classes.bulletList}>
          {whatItMean.map((bullet, _index) => (
            <li key={_index}>
              <Typography variant="body1">{bullet}</Typography>
            </li>
          ))}
        </ul>
        <Typography variant="h3" className="subHeading">
          {t('policyManagement.autoRenewalDetails.autoRenewalPreferences.whyAutoRenew.title')}
        </Typography>
        <ul className={classes.bulletList}>
          {whyAutoRenewBullets.map((bullet, _index) => (
            <li key={_index}>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: bullet,
                }}
              />
            </li>
          ))}
        </ul>
        <Typography variant="h3" className="subHeading">
          {t('policyManagement.autoRenewalDetails.yourAutoRenewalPolicyPreferencesTitle')}
        </Typography>
        <RadioGroup
          value={chosenAutoRenewal}
          onChange={(event) => {
            setHasInteractedWithForm(true);
            // All form values are strings even though we've set up the radios to have boolean values
            const newAutoRenewal = event.target.value === 'true';
            setChosenAutoRenewal(newAutoRenewal);
            // If value has changed, then scroll down to the CTA area
            if (initialAutoRenewal !== newAutoRenewal) {
              ctaAreaRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
          }}
        >
          {renewalOptionsArray.map((Component) => (
            <Component key={Component.name} />
          ))}
        </RadioGroup>

        {/* Empty span that is always in the dom, used so that we can safely scroll it into view */}
        <span ref={ctaAreaRef}></span>

        {/* Only if the chosen option has changed do we show any text/buttons */}
        {initialAutoRenewal !== chosenAutoRenewal && (
          <>
            <Grid container wrap="nowrap">
              <IconSure className="icon" icon="info-circle" size="24" type="solid" color="secondary" />
              {chosenAutoRenewal ? (
                <Typography variant="body2" gutterBottom className="ctaText">
                  {t('policyManagement.autoRenewalDetails.ctaParagraphTurnOn')}
                </Typography>
              ) : (
                <Typography variant="body2" gutterBottom className="ctaText">
                  {isPolicyDueToday
                    ? t('policyManagement.autoRenewalDetails.ctaParagraphTurnOffDueDate')
                    : t('policyManagement.autoRenewalDetails.ctaParagraphTurnOff')}
                </Typography>
              )}
            </Grid>

            <Grid container spacing={0} className="buttons">
              <Grid item xs={12}>
                {/* Back button always shown no matter which option selected */}
                <Button
                  data-testid="backButton"
                  color="secondary"
                  className="button"
                  variant="outlined"
                  size="large"
                  onClick={handleBack}
                >
                  {t('buttons.back')}
                </Button>
                {/* If chosen option is on, then show a Chat with us button */}
                {chosenAutoRenewal && (
                  <Button
                    data-testid="chatButton"
                    color="primary"
                    className="button"
                    variant="contained"
                    size="large"
                    startIcon={<SVGWrapper alt="chat-logo" src="chat_bubble.svg" />}
                    onClick={handleChat}
                  >
                    {t('buttons.chatWithUs')}
                  </Button>
                )}
                {/* If chosen option is off and policy is not due today, then show a Save button */}
                {!chosenAutoRenewal && !isPolicyDueToday && (
                  <Button
                    data-testid="saveButton"
                    color="primary"
                    className="button"
                    variant="contained"
                    size="large"
                    onClick={handleSave}
                    disabled={submitting}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
                {/* If chosen option is off, but it's the policy due date, then show a Chat with us instead of Save */}
                {!chosenAutoRenewal && isPolicyDueToday && (
                  <Button
                    data-testid="chatDueDateButton"
                    color="primary"
                    className="button"
                    variant="contained"
                    size="large"
                    startIcon={<SVGWrapper alt="chat-logo" src="chat_bubble.svg" />}
                    onClick={handleChatOnPolicyDueDate}
                  >
                    {t('buttons.chatWithUs')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
});
