import Cookie from 'js-cookie';
import { useState } from 'react';

import { isCauraRoute } from '../../lib/caura-bridge';

import { CookieBanner } from './CookieBanner';
import { BANNER_COOKIES, CookiesOptions, MODAL_COOKIES, REQUIRED_COOKIES } from './cookieContants';

interface Props {
  children: React.ReactNode;
}

export const getPerformanceAndAnalyticsCookie = (): boolean => {
  const { prefix } = { prefix: '' }; //config.provider.cookie;
  const performanceAndAnalytics = Cookie.get(`${prefix}_Performance_and_Analytics`);
  return performanceAndAnalytics === '1' || false;
};

const prefix = 'ESURE_ENSIGHTEN_PRIVACY';
const expirationDays = 365;
const sameSiteCookie = 'strict';

export const retrieveCookieSettingsFromCookies = (): CookiesOptions =>
  REQUIRED_COOKIES.reduce((prev, val) => {
    const cookie = Cookie.get(`${prefix}_${val}`);
    if (cookie === undefined) {
      return prev;
    }
    return { ...prev, [val]: cookie === '1' };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as Record<string, boolean>);

export const CookieWrapper = ({ children }: Props) => {
  const [cookies, setCookies] = useState<CookiesOptions>(retrieveCookieSettingsFromCookies);

  const [openCookieModal, setOpenCookieModal] = useState(
    () => cookies.Marketing === undefined || cookies.Performance_and_Analytics === undefined,
  );

  const onCookieChange = (cookies: CookiesOptions) => {
    if (cookies.Performance_and_Analytics) {
      // custom js event to trigger the same analytics.js as in the main index.html
      document.dispatchEvent(new Event('loadAnalytics'));
    }
  };

  const updateCookies = (updatedCookies: CookiesOptions, seenManageModal?: boolean) => {
    setCookies(updatedCookies);
    Object.entries({
      ...updatedCookies,
      ...[...BANNER_COOKIES, ...(seenManageModal ? MODAL_COOKIES : [])].reduce((prev, val) => {
        return { ...prev, [val]: true };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      }, {} as Record<string, boolean>),
    }).forEach(([key, value]) => {
      Cookie.set(`${prefix}_${key}`, value ? '1' : '0', {
        expires: expirationDays,
        sameSite: sameSiteCookie,
      });
    });
    onCookieChange(updatedCookies);
  };

  const handleAcceptAll = () => {
    updateCookies({
      Marketing: true,
      Performance_and_Analytics: true,
    });
    setOpenCookieModal(false);
  };

  const handleRejectAll = () => {
    updateCookies({
      Marketing: false,
      Performance_and_Analytics: false,
    });
    setOpenCookieModal(false);
  };

  const handleManage = (cookies: CookiesOptions) => {
    updateCookies(cookies, true);
    setOpenCookieModal(false);
  };

  // Native app takes care of cookies - we don't need to show banner so simply reject all
  // This is to avoid showing banner in other routes if entry point was called from native app
  if (isCauraRoute()) {
    return <>{children}</>;
  }

  return (
    <>
      <CookieBanner
        openCookieModal={openCookieModal}
        handleAcceptAll={handleAcceptAll}
        handleRejectAll={handleRejectAll}
        handleManage={handleManage}
      />
      {children}
    </>
  );
};
