import { Grid, Theme, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { useStores } from '../../../../../../../../service/state/store';

import { EditPageRows } from './editPageRows';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  root: {
    '& .columns-container': {
      gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
      padding: spacing(3),
    },
    marginTop: spacing(3),
  },
}));

export interface Row {
  id: string;
  ignoreEdit?: boolean;
  label: string;
  showDivider?: boolean;
  title?: string;
  values: (string | React.ReactElement | React.ReactElement[])[];
}

export interface Column {
  rows: Row[];
}

export interface EditPageProps {
  columns: Column[];
  handleEdit: (id: string) => void;
  tableHeader: string;
}

export const MultipleColumnsEditPage: React.FC<EditPageProps> = observer(function MultipleColumnsEditPage({
  tableHeader,
  columns,
  handleEdit,
}) {
  const {
    interfaceStore: { isDesktop },
  } = useStores();

  const classes = useStyles({ isDesktop });

  return (
    <BlueTableContainer title={tableHeader} className={classes.root}>
      <Grid container className="columns-container">
        {columns.map(({ rows }, index) => {
          return (
            <Grid container item direction="column" md key={index}>
              <EditPageRows handleEdit={handleEdit} rows={rows} tableHeader={tableHeader} />
            </Grid>
          );
        })}
      </Grid>
    </BlueTableContainer>
  );
});
