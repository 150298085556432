import { Button, Grid, Link, Menu, MenuItem, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { applySnapshot } from 'mobx-state-tree';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SVGWrapper } from '../../images/SVGWrapper';
import { getSafeAreaBottom, sendOpenInBrowserHook } from '../../lib/caura-bridge';
import config from '../../service/config';
import { LINK_TYPE, ROUTE } from '../../service/constant';
import { IInterfaceStore } from '../../service/state/models/interface';
import { PERSISTENCE_NAME_INTERFACE_STORE, useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';
import { replaceLinksDomain } from '../../service/util/stringUtils';
import { ItemList } from '../../view/pages/landing';

import { InternalLink } from './internalLink';

export interface FooterProps {
  fullWidth?: boolean;
  hasBG?: boolean;
  isCore?: boolean;
  theme?: 'dark' | 'light';
}

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    '& .footer': {
      '& .wrapper': {
        margin: '0 auto',
        maxWidth: 1264,
        padding: spacing(2),

        [breakpoints.up('sm')]: {
          padding: '0 64px',
        },
      },
      '&.dark': {
        '& .footer__links, a': {
          color: palette.common.white,
        },
        backgroundColor: palette.secondary.dark,
      },
      '&.full-width': {
        '& .wrapper': {
          maxWidth: '100%',
        },
      },
      '&.light': {
        '& .footer__links': {
          '& a': {
            color: palette.secondary.main,
          },
          [breakpoints.up('md')]: {
            '& a:not(:first-child)': {
              '&:before': {
                background: palette.grey[300],
                content: `''`,
                height: 15,
                left: -20,
                position: 'absolute',
                top: 6,
                width: 2,
              },
            },
            gap: 40,
          },
        },
        backgroundColor: palette.grey[50],
      },
      '&__bg': {
        '& img': {
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center 90%',
          width: '100%',
        },
        backgroundColor: palette.background.default,
        bottom: 0,
        content: '""',
        height: 270,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: 0,
      },
      '&__links': {
        '& a': {
          '&:hover': {
            textDecoration: 'none',
          },
          position: 'relative',
        },
        gap: 25,
        padding: spacing(6, 0),
      },
    },
  },
}));

export const Footer: React.FC<FooterProps> = observer(function Footer({
  fullWidth = true,
  hasBG,
  theme = 'light',
  isCore = false,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const { eventTrack } = useSegment();
  const { interfaceStore, userStore } = useStores();
  const [devMenuAnchorEl, setDevMenuAnchorEl] = useState<HTMLElement | null>(null);

  let footerLinks: ItemList[];
  if (isCore) {
    footerLinks = t('landing.footerCore', { returnObjects: true });
  } else {
    footerLinks = t('landing.footer', { returnObjects: true });
  }

  /**
   * Handles showing the dev menu
   * @param event the click event
   */
  const handleDevMenu = (event: MouseEvent<HTMLElement>) => {
    setDevMenuAnchorEl(event.currentTarget);
  };

  /**
   * Handles copying the EIS customer ID to the clipboard
   */
  const handleCopyCustomerId = async () => {
    const customerId = userStore.user.userId;
    if (customerId) {
      await navigator.clipboard.writeText(customerId);
    }
    handleCloseDevMenu();
  };

  /**
   * Handles copying the interface store to the clipboard
   */
  const handleCopyInterfaceStore = async () => {
    const storageInterfaceStore = localStorage.getItem(PERSISTENCE_NAME_INTERFACE_STORE);
    if (storageInterfaceStore) {
      await navigator.clipboard.writeText(storageInterfaceStore);
    }
    handleCloseDevMenu();
  };

  /**
   * Handles pasting the interface store from the clipboard
   */
  const handlePasteInterfaceStore = async () => {
    const clipboardData = await navigator.clipboard.readText();
    if (clipboardData) {
      try {
        // Parse clipboard text to ensure it is an interface store, don't bother doing anything if there is no accessToken
        const parsed = JSON.parse(clipboardData) as IInterfaceStore;
        if (parsed.accessToken) {
          // Update the interface store, then navigate to the dashboard page
          applySnapshot(interfaceStore, parsed);
          handleCloseDevMenu();
          // Use return as don't want the handleCloseDevMenu running again after the navigation
          return history.push(ROUTE.DASHBOARD);
        }
      } catch (e) {
        // Nothing to do
      }
    }
    handleCloseDevMenu();
  };

  /**
   * Handles closing the dev menu
   */
  const handleCloseDevMenu = () => {
    setDevMenuAnchorEl(null);
  };

  let footerStyle = {};
  if (interfaceStore.isCaura) {
    const safeAreaBottom = getSafeAreaBottom();
    footerStyle = { paddingBottom: `${Number(safeAreaBottom)}px` };
  }

  const renderLink = (item: ItemList) => {
    const { link, title, type } = item;
    // eslint-disable-next-line no-script-url
    const href = interfaceStore.isCaura ? 'javascript:void(0);' : replaceLinksDomain(link);
    const target = interfaceStore.isCaura ? '' : '_blank';
    const onClick = () => {
      if (type === LINK_TYPE.EXTERNAL) eventTrack('Footer Link Clicked', { item: title });
      void sendOpenInBrowserHook(replaceLinksDomain(link));
    };

    if (type === LINK_TYPE.EXTERNAL) {
      return (
        <Link data-testid="footer-link" href={href} key={title} target={target} onClick={onClick}>
          {title}
        </Link>
      );
    }

    return <InternalLink key={title} title={title} url={href} onClick={onClick} />;
  };

  return (
    <Grid className={classes.root}>
      <footer style={footerStyle} className={clsx('footer', theme, fullWidth && 'full-width')}>
        {hasBG && (
          <Grid className="footer__bg">
            <SVGWrapper src="home-footer-bg.svg" />
          </Grid>
        )}

        <Grid className="wrapper">
          <Grid className="footer__links" container>
            {footerLinks.map(renderLink)}
          </Grid>
        </Grid>
        {interfaceStore.hasFeatureFlag('showVersionNumber') && (
          <Grid>
            <Button data-testid="versionButton" variant="text" color="secondary" onClick={handleDevMenu}>
              v{config.app.version}
            </Button>
            <Menu
              id="devMenu"
              anchorEl={devMenuAnchorEl}
              keepMounted={false}
              open={Boolean(devMenuAnchorEl)}
              onClose={handleCloseDevMenu}
            >
              <MenuItem data-testid="copyItem" onClick={handleCopyInterfaceStore}>
                Copy interface store
              </MenuItem>
              <MenuItem data-testid="pasteItem" onClick={handlePasteInterfaceStore}>
                Paste interface store
              </MenuItem>
              <MenuItem data-testid="copyCustomerIdItem" onClick={handleCopyCustomerId}>
                Copy user id: {userStore.user.userId || 'Unauthenticated'}
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </footer>
    </Grid>
  );
});
