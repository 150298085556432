import { Box, Grid, makeStyles } from '@esure-cloud/react-components';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentCard } from '../../../../component/common/documentCard';
import { DocumentCardHeader } from '../../../../component/common/documentCardHeader';
import { EmptyState } from '../../../../component/layout/emptyState';
import { DATE_TYPES, POLICY_STATUS } from '../../../../service/constant';
import { IPolicyDocument } from '../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../service/state/store';
import { formatDate } from '../../../../service/util/formatDate';
import SelfService from '../../../../service/util/selfService';

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    paddingTop: theme.spacing(2),
  },
  documentPadding: {
    paddingTop: theme.spacing(1),
  },
}));

export interface PolicyDocumentsProps {
  documents: IPolicyDocument[];
  sectionTitle: string;
}

export const futureDate = (
  futureRenewalEffectiveDate: string | DateTime | null,
  futureMtaEffectiveDate: string | DateTime | null,
): DateTime | null => {
  if (typeof futureRenewalEffectiveDate !== 'string' && typeof futureMtaEffectiveDate !== 'string') {
    if (futureRenewalEffectiveDate && futureMtaEffectiveDate) {
      return futureRenewalEffectiveDate >= futureMtaEffectiveDate ? futureRenewalEffectiveDate : futureMtaEffectiveDate;
    } else {
      return futureRenewalEffectiveDate ?? futureMtaEffectiveDate;
    }
  } else {
    return null;
  }
};

export const PolicyDocuments: React.FC = observer(function PolicyDocuments() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const [hasDocsLoadingFailed, setHasDocsLoadingFailed] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const {
    dashboardStore: {
      fetchPolicyDocuments,
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const policySelected = isHome ? home.policySelected : auto.policySelected;

  const { documents, policyNumber, futureMtaEffectiveDate, futureRenewalEffectiveDate } = policySelected;

  const upgradeDocuments: IPolicyDocument[] = documents.filter((doc) => doc.upgradeDoc);
  const keyDocuments: IPolicyDocument[] = documents.filter((doc) => !doc.upgradeDoc);

  const getSearchDateByStatus = () => {
    if (policySelected.policyStatus === POLICY_STATUS.ACTIVE) {
      return null;
    } else if (SelfService.isPolicyExpired(policySelected.policyStatus)) {
      return DateTime.fromISO(policySelected.expirationDate);
    } else {
      return policySelected.effectiveDate;
    }
  };

  useEffect(() => {
    if (!documents.length) {
      fetchPolicyDocuments(
        policyNumber,
        futureDate(futureRenewalEffectiveDate, futureMtaEffectiveDate) ?? getSearchDateByStatus(),
      ).then((res) => {
        setHasFetched(true);

        if (res?.status !== 200) {
          setHasDocsLoadingFailed(true);
        }
      });
    } else {
      setHasFetched(true);
    }
  }, [policySelected]);

  const DocumentsContent: React.FC<PolicyDocumentsProps> = observer(function DocumentsContent({
    documents,
    sectionTitle,
  }) {
    const dateNowString = new Date().getFullYear().toString();
    const getYear = () => {
      if (!policySelected.futureMtaEffectiveDate || typeof policySelected.futureMtaEffectiveDate === 'string') {
        return dateNowString;
      } else {
        return policySelected.futureMtaEffectiveDate.year.toString();
      }
    };

    const [year, setYear] = useState(getYear());

    return (
      <Grid className={classes.containerGrid} container data-testid="documents-container">
        <DocumentCardHeader
          sectionTitle={sectionTitle}
          visibleDropdown={documents !== upgradeDocuments}
          date={t('documents.date')}
          year={year}
          setYear={setYear}
        />
        <Grid item xs={12}>
          <Grid>
            {documents.map((item, index) => {
              const { documentId, documentTypeCode, history, fileName, creationDate } = item;

              if (creationDate?.year.toString() === year || documents === upgradeDocuments) {
                return (
                  <Grid className={classes.documentPadding} key={`${index}:${fileName}`}>
                    <DocumentCard
                      documentId={documentId}
                      documentCode={documentTypeCode}
                      headerText={fileName}
                      policyNumber={policyNumber}
                      date={formatDate(creationDate?.toString(), DATE_TYPES.MINI)}
                      hasOlderDocuments={history.length > 0}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const RenderDocumentsContent = () => {
    return (
      <>
        <DocumentsContent documents={keyDocuments} sectionTitle={t('documents.keyDocsTitle')} />
        {upgradeDocuments.length > 0 && (
          <DocumentsContent documents={upgradeDocuments} sectionTitle={t('documents.upgradesDocsTitle')} />
        )}
      </>
    );
  };

  return !hasFetched ? (
    <></>
  ) : hasDocsLoadingFailed ? (
    <Box py={8}>
      <EmptyState title={t('docsFailedLoading.title')} content={t('docsFailedLoading.content')} />
    </Box>
  ) : (
    <RenderDocumentsContent />
  );
});
