import { Grid, Typography, makeStyles, useTheme } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { DATE_TYPES, POLICY_STATUS } from '../../../service/constant';
import { IHomePolicy } from '../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../service/state/store';
import { formatDate } from '../../../service/util/formatDate';
import SelfService from '../../../service/util/selfService';
import { postCodeFormatter } from '../../../service/util/stringUtils';
import { PolicyCardRenewalQuote } from '../../../view/pages/policies/policy/renewals/components/policyCardRenewalQuote';
import { ViewDocsBtn } from '../../common/button/viewDocsBtn';
import { ViewPolicyBtn } from '../../common/button/viewPolicyBtn';

import { PolicyCardFailedLoading } from './policyCardFailedLoading';

import { AutoRenew, PolicyCardProps, useCommonStylesPolicyCard } from '.';

export const useHomeStyles = makeStyles(({ palette, spacing }) => ({
  homeContainer: {
    '& .info-plate': {
      '&__postcode': {
        '&-pin': {
          left: spacing(1.2),
          position: 'absolute',
          top: spacing(0.25),
        },
        '&-text': {
          marginLeft: spacing(0.5),
        },
      },
      background: palette.background.default,
      border: `1px solid ${palette.text.primary}`,
      borderRadius: '2px',
      color: palette.text.primary,
      margin: spacing(1, 0),
      maxWidth: 'fit-content',
      overflow: 'hidden',
      padding: spacing(0.2, 1.5, 0, 3.4),
      position: 'relative',
      whiteSpace: 'nowrap',
    },
    '& .renewalHeading': {
      '-webkit-line-clamp': 'initial',
      fontSize: '1.5rem',
      maxHeight: '100%',
    },
    '&.renewalHeader': {
      maxWidth: '80% !important',
    },
    '@media (max-width:960px)': {
      '& .autorenew': {
        display: 'none',
      },
      '&.renewalHeader': {
        maxWidth: '100% !important',
      },
      display: 'block !important',
      maxWidth: '100% !important',
    },
    alignSelf: 'center',
    gap: 15,
    maxWidth: '25% !important',
    minWidth: 190,
  },
}));

export const HomePolicyCard: React.FC<PolicyCardProps> = observer((props) => {
  const { t } = useTranslation(['myAccount', 'common']);
  const classes = useCommonStylesPolicyCard();
  const homeClasses = useHomeStyles();
  const theme = useTheme();
  const {
    dashboardStore: {
      insurance: {
        home: {
          policySelected: { policyRenewalQuoteDetails },
        },
      },
    },
  } = useStores();

  const { autoRenewal, effectiveDate, expirationDate, homeAddress, loadingFailed, policyNumber, policyStatus } =
    props.policy as IHomePolicy;

  // If loading failed, return the failed loading component, no need to carry on this component
  if (loadingFailed) {
    return <PolicyCardFailedLoading policy={props.policy} />;
  }

  const isPolicyExpired = SelfService.isPolicyExpired(policyStatus);

  return (
    <Grid
      className={clsx(
        'policy-card',
        classes.root,
        policyStatus,
        props.isPolicyHeader ? 'policy-page' : '',
        SelfService.themeClass(theme),
      )}
      container
      item
      justifyContent="space-between"
      sm={12}
      data-testid="box"
    >
      <Grid
        alignItems="flex-start"
        className={clsx(homeClasses.homeContainer, props.isRenewalHeader ? 'renewalHeader' : '')}
        container
        direction="row"
        item
        justifyContent="space-between"
        sm={12}
      >
        <Grid item xs>
          {props.isRenewalHeader ? (
            <Typography variant="h2" className="renewalHeading">
              {policyRenewalQuoteDetails.customerIndividualDetail.firstName}
              {t('policyManagement.homeRenewalQuote.policyCard.title', { insuranceID: 'home' })}
            </Typography>
          ) : (
            <Typography variant="h2">
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {homeAddress?.houseName} {homeAddress?.houseNumber} {homeAddress?.street} <br />
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {homeAddress?.postalTown}
            </Typography>
          )}

          <Grid className="info-plate">
            <Grid className="info-plate-postcode">
              <SVGWrapper alt="" src="postcodepin.svg" className="info-plate__postcode-pin" />
              <Typography variant="h5" className="info-plate__postcode-text">
                {props.isRenewalHeader
                  ? policyRenewalQuoteDetails.mainAddress().addressDetails()
                  : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    postCodeFormatter(homeAddress?.postcode)}
              </Typography>
            </Grid>
          </Grid>

          {props.isRenewalHeader && (
            <>
              <PolicyCardRenewalQuote autoRenewal={autoRenewal} expirationDate={expirationDate} />
              <Typography className="autorenew">
                <AutoRenew autoRenewal={autoRenewal} />
              </Typography>
            </>
          )}

          {!props.isPolicyHeader && (
            <Typography variant="body2">
              {t('policyDetails.policyNo')}
              {policyNumber}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        className={clsx('policy-details', policyStatus === POLICY_STATUS.PENDING ? 'single-item' : '')}
        container
        item
        justifyContent="space-between"
        md
        wrap="nowrap"
        xs
      >
        {!props.isPolicyHeader && (
          <>
            <Grid className="policy-details__list container" item>
              {!isPolicyExpired && expirationDate && (
                <Grid className={clsx('policy-details__item')} container direction="column" item>
                  <Typography variant="body1">{t('policyDetails.coverStarts')}</Typography>
                  <Typography variant="h4">{formatDate(effectiveDate?.toString(), DATE_TYPES.SHORT_SPACE)}</Typography>
                </Grid>
              )}
              {policyStatus !== POLICY_STATUS.PENDING && (
                <Grid className={clsx('policy-details__item')} container direction="column" item>
                  <Typography variant="body1">
                    {isPolicyExpired ? t('policyDetails.coverEnded') : t('policyDetails.coverEnds')}
                  </Typography>
                  <Typography variant="h4">
                    {formatDate(SelfService.calculatePolicyEndDate(expirationDate).toString(), DATE_TYPES.SHORT_SPACE)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid alignItems="flex-start" className="policy-details__buttons" container direction="row" item xs>
              <Grid item>
                {policyStatus === POLICY_STATUS.ACTIVE || isPolicyExpired ? (
                  <ViewPolicyBtn policy={props.policy} />
                ) : (
                  <ViewDocsBtn policy={props.policy} />
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Grid data-testid="NonMobileImage" className="policy-car" container item justifyContent="flex-end" sm xs={12}>
          <SVGWrapper
            alt="Policy Home"
            className="policy-car__full home"
            src={`${
              isPolicyExpired ? 'policycard-image-home-policies-expired.svg' : 'policycard-image-home-policies.svg'
            }`}
          />
        </Grid>

        {props.isRenewalHeader && (
          <Typography className="autorenewBottom">
            <AutoRenew autoRenewal={autoRenewal} />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
});
