import { Button, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { DATE_TYPES, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { formatDate } from '../../../../../../service/util/formatDate';

const useStyles = makeStyles((theme) => ({
  bulletList: {
    '& li': {
      '&:before': {
        background: theme.palette.secondary.main,
        borderRadius: '50%',
        content: `''`,
        display: 'block',
        height: '10px',
        left: theme.spacing(1),
        position: 'absolute',
        top: theme.spacing(1),
        width: '10px',
      },
      display: 'block',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      position: 'relative',
    },
    listStyle: 'none',
    padding: 0,
  },
  container: {
    margin: theme.spacing(2),
  },
  root: {},
  subtitle: {
    fontWeight: 'bold',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export const RenewalChangePending: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const bulletPoints: string[] = t('policyManagement.autoRenewalDetails.pending.bullets', { returnObjects: true });
  const classes = useStyles();
  const {
    dashboardStore: {
      insurance: {
        selectedPolicyType,
        auto: {
          policySelected: { expirationDate: autoExpirationDate },
        },
        home: {
          policySelected: { expirationDate: homeExpirationDate },
        },
      },
    },
  } = useStores();

  const handleGoToPolicy = () => {
    history.push(ROUTE.POLICY_DETAILS);
  };

  const renderRenewalEffectiveDate = () => {
    if (selectedPolicyType === 'auto') {
      return formatDate(autoExpirationDate.toString(), DATE_TYPES.SHORT_SPACE);
    }
    if (selectedPolicyType === 'home') {
      return formatDate(homeExpirationDate.toString(), DATE_TYPES.SHORT_SPACE);
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <BlueBanner title={t('policyManagement.autoRenewalDetails.pending.heading')} route={ROUTE.POLICY_DETAILS} />
      <Grid className={`container ${classes.container}`} container direction="column">
        <Typography variant="h2" className={classes.title}>
          {t('policyManagement.autoRenewalDetails.pending.title')}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {t('policyManagement.autoRenewalDetails.pending.subtitle')}
          {renderRenewalEffectiveDate()}
        </Typography>
        <ul className={classes.bulletList}>
          {bulletPoints.map((bullet) => {
            return <li key={bullet}>{bullet}</li>;
          })}
        </ul>
      </Grid>
      <Button
        data-testid="backToPolicy"
        color="primary"
        className="button"
        variant="contained"
        size="large"
        onClick={handleGoToPolicy}
      >
        {t('buttons.goToMyPolicy')}
      </Button>
    </div>
  );
};
