import {
  Box,
  ButtonBase,
  CommonContainer,
  CommonContainerProps,
  Grid,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import React, { ReactNode } from 'react';

import IconSure, { IconType } from '../common/icon';

const useStyles = makeStyles((theme) => ({
  fullHeigth: {
    '& > div': {
      height: '100%',
    },
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  iconPadding: {
    paddingRight: theme.spacing(2),
  },
  textWrap: {
    marginRight:
      '2px' /* Added to stop short titles 'clashing with the chevron' on mobile, for example 'Check policy' */,
    overflowWrap: 'break-word',
  },
}));

export interface NavigationCardSmallProps {
  color?: CommonContainerProps['color'];
  description?: string | ReactNode;
  icon: string;
  iconType?: IconType;
  showDescription?: boolean;
  title: string;
  trackEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const NavigationCardSmall: React.FC<NavigationCardSmallProps> = ({
  color,
  description,
  title,
  icon,
  trackEvent,
  showDescription = false,
  iconType = 'line',
}) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.fullWidth, classes.fullHeigth)}
      onClick={trackEvent}
      data-testid="button-ButtonBase"
    >
      <Box className={clsx(classes.fullWidth, classes.fullHeigth)}>
        <CommonContainer color={color} variant={'outlined'}>
          <Grid alignItems="baseline" className={classes.fullHeigth} container>
            <Grid alignItems="center" container item xs={11}>
              <Grid alignItems="center" container item>
                <Grid className={classes.iconPadding} item>
                  <IconSure icon={icon} color={color} type={iconType} />
                </Grid>
                <Grid item>
                  <Typography align="left" className={classes.textWrap} variant="h4">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              {showDescription && (
                <Grid alignItems="center" container item>
                  <Typography align="left" className={classes.textWrap} variant="body2">
                    {description}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid alignItems="baseline" container item direction="row-reverse" xs={1}>
              <Grid item>
                <IconSure icon="chevron-right" color={color} />
              </Grid>
            </Grid>
          </Grid>
        </CommonContainer>
      </Box>
    </ButtonBase>
  );
};
