import { Button, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../../../../../../images/SVGWrapper';
import { useStores } from '../../../../../../../../service/state/store';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& .wrapper': {
      '& button': {
        marginTop: spacing(5),
      },
      '& img': {
        maxWidth: spacing(25),
      },
      '& p, h2': {
        marginTop: spacing(4),
        maxWidth: spacing(44),
        textAlign: 'center',
      },
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '120px',
      maxWidth: '550px',
      [breakpoints.down('xs')]: {
        marginTop: spacing(5),
      },
    },
  },
}));

export const SameDayMta: React.FC = observer(function SameDayMta() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
  } = useStores();

  return (
    <Grid justifyContent="center" alignContent="center" container className={clsx(classes.root)}>
      <Grid container direction="column" className="wrapper">
        <SVGWrapper alt="car" src="fetch-quote.png" />
        <Typography variant="h2">{t('changeCarDetails.additionalChanges')}</Typography>
        <Typography variant="body1">{t('changeCarDetails.specialistsHelp')}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => startChat({ initiator: 'Speak to a specialist button - Change car details' })}
        >
          {t('buttons.speakToASpecialist')}
        </Button>
      </Grid>
    </Grid>
  );
});
