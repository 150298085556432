import {
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Switch,
  SwitchProps,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { ListPurpose, ListPurposeObject } from './ListPurpose';

type Props = {
  description: string;
  listPurpose?: ListPurposeObject[];
  title: string;
  url: string;
} & SwitchProps;

const useStyles = makeStyles(({ palette, spacing }) => ({
  checked: {},
  container: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
  },
  focusVisible: {},
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
  },
  root: {
    transform: 'scale(50%)',
  },
  switchBase: {
    '& $thumb': {
      // border: '6px solid #fff',
      color: palette.primary.main,
    },
    '&$checked': {
      '& $thumb': {
        border: '6px solid #fff',
        color: palette.common.white,
      },
      '& + $track': {
        backgroundColor: palette.primary.main,
        border: 'none',
        opacity: 1,
      },
    },
    padding: 1,
  },
  thumb: {
    // backgroundColor: palette.primary.main,
    height: 24,
    width: 24,
  },
  track: {
    backgroundColor: palette.grey[50],
    border: `1px solid ${palette.grey[400]}`,
    borderRadius: '26 / 2',
    opacity: 1,
  },
}));

export const CookiePurpose = ({ title, description, listPurpose, url, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Grid>
        <FormGroup>
          <FormControlLabel
            slot="typography"
            className={classes.formControl}
            control={
              <Switch
                {...rest}
                size="small"
                classes={{
                  checked: classes.checked,
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                }}
              />
            }
            label={<Typography variant="h3">{title}</Typography>}
            labelPlacement="start"
          />
        </FormGroup>
        <Typography>{description}</Typography>
        {listPurpose && <ListPurpose listPurpose={listPurpose} />}
        <Link href={url} rel="noopener" target="_blank">
          Cookie Details
        </Link>
      </Grid>
    </Grid>
  );
};
