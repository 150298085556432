import { Grid, Hidden, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../service/state/store';

import { SelectDropDown } from './selectDropDown';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .main-title': {
      padding: spacing(3, 0),
    },
    '& .section': {
      ' &__title': {
        color: palette.text.primary,
        padding: spacing(2, 2, 0, 0),
      },
      paddingBottom: spacing(1),
    },
  },
}));

export interface DocumentCardHeaderProps {
  date: string;
  mainTitle?: string;
  sectionTitle: string;
  setYear?: (value: string) => void;
  visibleDropdown?: boolean;
  year?: string;
}

export const DocumentCardHeader: React.FC<DocumentCardHeaderProps> = observer(function DocumentCardHeader({
  date,
  mainTitle,
  sectionTitle,
  setYear,
  visibleDropdown = false,
  year,
}) {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const [docYears, setDocYears] = useState<number[]>([]);

  const policySelected = isHome ? home.policySelected : auto.policySelected;
  const { documents } = policySelected;

  const sortDropdownValues = [...docYears].sort((a, b) => a - b);
  const vals = sortDropdownValues.reduce((acc: { label: string; value: string }[], itm) => {
    acc.push({
      label: `${itm}/${(itm + 1).toString().slice(-2)}
    `,
      value: itm.toString(),
    });
    return acc;
  }, []);

  useEffect(() => {
    const docs: number[] = [];
    documents.forEach(({ creationDate }) => {
      if (creationDate) {
        docs.push(creationDate.year);
      }
    });
    setDocYears([...new Set(docs)]);
  }, []);

  useEffect(() => {
    // Need to set the year if it's not been passed in, or it has but it doesn't exist in the list of years
    if ((!year && docYears.length > 0) || (year && docYears.length > 0 && !docYears.includes(+year))) {
      setYear?.(docYears[docYears.length - 1].toString());
    }
  }, [docYears.length]);

  return (
    <Grid container className={classes.root}>
      {mainTitle && (
        <Typography className="main-title" variant="h2">
          {mainTitle}
        </Typography>
      )}
      <Grid className="section" container direction="row" item xs={12}>
        <Grid className="section__title" container item justifyContent="flex-start" md={4} sm={6} xs={6}>
          <Typography variant="overline">{sectionTitle}</Typography>
        </Grid>
        <Hidden smDown>
          <Grid className="section__title" container item justifyContent="center" md={4} sm={5}>
            <Typography variant="overline">{date}</Typography>
          </Grid>
        </Hidden>

        {visibleDropdown && (
          <Grid container item justifyContent="flex-end" md={4} sm={6} xs={6}>
            <Hidden smDown>
              <Typography className="section__title" variant="overline">
                {t('documents.year')}
              </Typography>
            </Hidden>
            {vals.length > 0 && typeof year === 'string' && (
              <SelectDropDown
                variant="secondary"
                labelId="select-dropdown-component"
                defaultValue={vals.slice(-1)[0].value}
                values={vals}
                onChangeHandler={(value: string) => setYear?.(value)}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
