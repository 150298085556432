import {
  Alert,
  ButtonBase,
  Grid,
  IconNotificationImportant,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { AlertProps } from '@material-ui/lab/Alert';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IconSure from '../../../../../../component/common/icon';
import { POLICY_STATUS_CODE, ROUTE } from '../../../../../../service/constant';
import { IAutoPolicy } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../../service/state/store';
import { useSegment } from '../../../../../../service/util/customHooks/useSegment';
import SelfService from '../../../../../../service/util/selfService';

const useStyles = makeStyles((theme) => ({
  fillContainer: {
    '& .MuiAlert-message': {
      width: '100%',
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2),
    },
    height: '100%',
    width: '100%',
  },
  textLeft: {
    textAlign: 'left',
  },
  textWrap: {
    overflowWrap: 'break-word',
  },
}));

export const ManageRenewalCard: React.FC = observer(function ManageRenewalCard() {
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected: autoPolicySelected },
        home: { policySelected: homePolicySelected },
        selectedPolicyType,
      },
    },
    renewalChangeStore: {
      renewalDetails: { addRenewalQuoteDetails, clearEndorsementReasons },
      setPendingMTAQuoteSelected,
    },
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  const classes = useStyles();

  const policy = selectedPolicyType === 'auto' ? autoPolicySelected : homePolicySelected;

  const { policyNumber } = policy;

  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(policy.expirationDate) - 1;
  const autoRenewal = policy.autoRenewal;

  const getSeverity = (remainingDays: number) => {
    let severity: AlertProps['severity'] = 'info';

    if (remainingDays <= 3) severity = 'error';
    else if (remainingDays <= 32 && remainingDays >= 4) severity = 'warning';
    return severity;
  };

  const { t } = useTranslation('myAccount');

  const history = useHistory();

  const { eventTrack } = useSegment();

  const handleClick = () => {
    if (selectedPolicyType === 'auto') {
      handleMotorPolicyClick();
    } else {
      handleHomePolicyClick();
    }
  };

  const handleHomePolicyClick = () => {
    const homePolicy = policy as IHomePolicy;

    if (homePolicy.policyRenewalQuoteDetails.renewalPolicyStatusCode === POLICY_STATUS_CODE.PROPOSED) {
      history.push(ROUTE.RENEWAL_QUOTE_DETAILS);
    } else if (homePolicy.policyRenewalQuoteDetails.renewalPolicyStatusCode !== '') {
      history.push(ROUTE.YOUR_RENEWAL_DECLINE);
    }

    eventTrack('Card clicked', {
      componentID: 'DoTodayViewRenewal',
      label: 'ViewRenewal',
      policyNumber,
    });
  };

  const handleMotorPolicyClick = () => {
    const autoPolicy = policy as IAutoPolicy;

    setPendingMTAQuoteSelected(false);
    if (
      autoPolicy.policyRenewalQuoteDetails.policyStatusCd === POLICY_STATUS_CODE.PROPOSED &&
      hasFeatureFlag('BCCJM-9000')
    ) {
      clearEndorsementReasons();
      if (autoPolicy.policyRenewalQuoteDetails.pendingMTAQuote) {
        history.push(ROUTE.YOUR_RENEWAL_QUOTES);
      } else {
        addRenewalQuoteDetails(autoPolicy.policyRenewalQuoteDetails);
        history.push(ROUTE.RENEWAL_QUOTE_DETAILS);
      }
    } else if (autoPolicy.policyRenewalQuoteDetails.policyStatusCd !== '') {
      history.push(ROUTE.YOUR_RENEWAL_DECLINE);
    }

    eventTrack('Card clicked', {
      componentID: 'DoTodayViewRenewal',
      label: 'ViewRenewal',
      policyNumber,
    });
  };

  const getMessage = (): string => {
    let message = '';

    switch (true) {
      // Renewal Quote Issued, Non Auto-Renewal (32 - 1 day(s) remaining)
      case remainingDays >= 1 && remainingDays <= 32 && !autoRenewal:
        message = t('expiryNoticeNew.autoRenewalOff.willEnd', {
          days: remainingDays > 1 ? 'days' : 'day',
          value: remainingDays,
        });
        break;

      // Renewal Quote Issued, Auto-Renewal (32 - 1 day(s) remaining)
      case remainingDays >= 1 && remainingDays <= 32 && autoRenewal:
        message = t('expiryNoticeNew.autoRenewalOn.willRenew', {
          days: remainingDays > 1 ? 'days' : 'day',
          value: remainingDays,
        });
        break;

      // Ends Tonight, Non Auto-Renewal
      case remainingDays === 0 && !autoRenewal:
        message = t('expiryNoticeNew.autoRenewalOff.sameDay');
        break;

      // Ends Tonight, Auto-Renewal
      case remainingDays === 0 && autoRenewal:
        message = t('expiryNoticeNew.autoRenewalOn.sameRenewDay');
        break;
    }

    return message;
  };

  const iconColor = (severity: string) => {
    let color = '';
    switch (severity) {
      case 'success':
        color = '#15ae65';
        break;
      case 'info':
        color = '#0c41a7';
        break;
      case 'warning':
        color = 'rgb(100, 86, 38)';
        break;
      case 'error':
        color = '#aa221a';
        break;
    }
    return color;
  };

  const shouldMotorNotificationShow = () => {
    const withinNotificationWindow = remainingDays <= 32 && remainingDays >= 0;
    const futureRenewalEffectiveDate = autoPolicySelected.futureRenewalEffectiveDate;
    const policyStatusesForShowingNotification: string[] = [
      POLICY_STATUS_CODE.PROPOSED,
      POLICY_STATUS_CODE.CUSTOMER_DECLINED,
    ];
    const policyStatus = autoPolicySelected.policyRenewalQuoteDetails.policyStatusCd;

    return (
      withinNotificationWindow &&
      futureRenewalEffectiveDate === null &&
      policyStatusesForShowingNotification.includes(policyStatus)
    );
  };

  const shouldHomeNotificationShow = () => {
    const withinNotificationWindow = remainingDays <= 32 && remainingDays >= 0;
    const futureRenewalEffectiveDate = homePolicySelected.futureRenewalEffectiveDate;
    const isDDCustomer =
      homePolicySelected.paymentPlan !== '' && homePolicySelected.paymentPlan !== 'YearlyCardPayment';
    const policyRenewalStatus = homePolicySelected.policyRenewalQuoteDetails.renewalPolicyStatusCode;

    // If UW Declined renewal, shortcut to not show the notification
    if (policyRenewalStatus === POLICY_STATUS_CODE.COMPANY_DECLINED) return false;

    if (
      withinNotificationWindow &&
      futureRenewalEffectiveDate === null &&
      !isDDCustomer &&
      hasFeatureFlag('showHomeRenewalNotification - annual')
    ) {
      return true;
    } else if (
      withinNotificationWindow &&
      futureRenewalEffectiveDate === null &&
      isDDCustomer &&
      hasFeatureFlag('showHomeRenewalNotification - DD')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showNotification = selectedPolicyType === 'auto' ? shouldMotorNotificationShow() : shouldHomeNotificationShow();
  const severity = getSeverity(remainingDays);

  return showNotification ? (
    <Grid item xs={6} lg={4} data-testid="doTodayCTA-manageRenewals">
      <ButtonBase className={classes.fillContainer} onClick={handleClick} data-testid="button-ButtonBase">
        <Alert className={classes.fillContainer} severity={severity} icon={false} data-testid="alert">
          <Grid alignItems="baseline" wrap="nowrap" container item>
            <Grid alignItems="center" container item>
              <Grid item>
                <IconNotificationImportant htmlColor={iconColor(severity)} />
              </Grid>
              <Grid item>
                <Typography align="left" className={classes.textWrap} variant="h4" color="secondary">
                  {t('whatWouldYouLikeToDoTodayCTA.ManageRenewalsCTA.title')}
                </Typography>
              </Grid>
              <Grid container item>
                <Typography align="left" className={classes.textWrap} variant="body2">
                  {getMessage()}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <IconSure icon="chevron-right" color="secondary" />
              </Grid>
            </Grid>
          </Grid>
        </Alert>
      </ButtonBase>
    </Grid>
  ) : null;
});
