import { Grid, IconArrowBack, IconButton, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID, getDeviceSession } from '../../service/analytics';
import { ROUTE } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { useSegment } from '../../service/util/customHooks/useSegment';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .banner': {
      '&__icon': {
        color: palette.secondary.contrastText,
      },
      '&__title': {
        padding: spacing(2),
      },
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      [breakpoints.up('sm')]: {
        borderRadius: spacing(1),
      },
    },
    [breakpoints.down('xs')]: {
      margin: spacing(-4.5, -2, 0, -2),
    },
  },
}));

export interface BlueBannerProps {
  route?: ROUTE;
  title: string;
}

export const BlueBanner: React.FC<BlueBannerProps> = observer(function BlueBanner({ title, route }) {
  const classes = useStyles();
  const {
    interfaceStore: { isMobile },
  } = useStores();
  const history = useHistory();
  const { eventTrackInteraction } = useSegment();

  const onClick = () => {
    if (isMobile && route) {
      // Mimics the same event that button clicks have
      eventTrackInteraction('Button Clicked', {
        componentID: COMPONENT_ID.BLUE_BANNER_MOBILE_BACK_BUTTON,
        label: 'Back',
        sessionId: getDeviceSession(),
        url: window.location.href,
      });
      history.push(route);
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid className="banner" container>
        {isMobile && route && (
          <IconButton
            title={`Back to: ${route}`}
            disableRipple
            onClick={() => onClick()}
            data-testid={COMPONENT_ID.BLUE_BANNER_MOBILE_BACK_BUTTON}
          >
            <IconArrowBack className="banner__icon" />
          </IconButton>
        )}
        <Typography className="banner__title" variant="h3">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
});
