import { useEffect, useState } from 'react';

import { LOOKUP } from '../../constant';
import { IDriver, IVehicle } from '../../state/models/dashboard/autoPolicyModel';
import { useStores } from '../../state/store';

type LookupsOption = 'driver' | 'vehicle' | 'insured' | 'renewal';

interface LookupsProps {
  driver?: IDriver;

  options?: LookupsOption[];
  vehicle?: IVehicle;
}

interface DecodedLookupsReturn {
  isDecodedLookupsFetched: boolean;
}

export const useDecodedLookupsMotor = ({
  options = ['vehicle'],
  vehicle,
  driver,
}: LookupsProps): DecodedLookupsReturn => {
  const [isDecodedLookupsFetched, setIsDecodedLookupFetched] = useState(false);

  const {
    dashboardStore: {
      fetchDecodedLookups,
      lookups: { genLookupPayload },
      insurance: {
        auto: {
          driverSelected,
          mainVehicle,
          insured: {
            person: {
              maritalStatusCode: maritalStatusCdI,
              residencyStatusCode: residencyStatusI,
              employmentStatusCode: employmentStatusI,
              primaryOccupationCode: primaryOccupationCodeI,
              primaryOccupationIndustryCode: primaryOccupationIndustryCodeI,
              secondaryOccupationCode: secondaryOccupationCodeI,
              secondaryOccupationIndustryCode: secondaryOccupationIndustryCodeI,
            },
          },
          policySelected: {
            policyRenewalQuoteDetails: { lastActionReasonCd },
          },
        },
      },
    },
  } = useStores();

  const lookupsPayload = () => {
    const {
      classOfUse,
      fuelTypeCode,
      legalOwner,
      bodyTypeCode,
      daytimeLocation,
      securityDeviceDeclared,
      modifications,
      overnightLocation,
      registeredKeeper,
      totalDeclaredMileageCode,
      transmissionTypeCode,
      socialMileageCode,
      businessMileageCode,
      residentialStatus,
      packCoverages,
      coverType,
    } = vehicle ?? mainVehicle;

    const {
      claims,
      convictions,
      person: {
        maritalStatusCode,
        primaryOccupationCode,
        secondaryOccupationCode,
        titleCode,
        primaryOccupationIndustryCode,
        secondaryOccupationIndustryCode,
        employmentStatusCode,
      },
      licence: { licenceTypeCode, licenseDateYearsCode, licenseDateMonthsCode, licenseRestrictionCode },
      useOfOtherCarsCode,
      relationshipToProposerCode,
    } = driver ?? driverSelected;

    const lookups = {
      driver: [
        { name: LOOKUP.CLAIM_TYPE, value: claims.map((item) => item.claimCode) },
        { name: LOOKUP.CONVICTION_CODE, value: convictions.map((item) => item.convictionCode) },
        { name: LOOKUP.LICENSE_HELD_MONTHS, value: [licenseDateMonthsCode] },
        { name: LOOKUP.LICENSE_HELD_YEARS, value: [licenseDateYearsCode] },
        { name: LOOKUP.LICENSE_RESTRICTIONS, value: [licenseRestrictionCode] },
        { name: LOOKUP.LICENCE_TYPE, value: [licenceTypeCode] },
        { name: LOOKUP.TITLE, value: [titleCode] },
        { name: LOOKUP.MARITAL_STATUS, value: [maritalStatusCode] },
        { name: LOOKUP.INDUSTRY, value: [primaryOccupationIndustryCode, secondaryOccupationIndustryCode] },
        { name: LOOKUP.OCCUPATION, value: [primaryOccupationCode, secondaryOccupationCode] },
        { name: LOOKUP.DRIVER_RELATIONSHIP, value: [relationshipToProposerCode] },
        { name: LOOKUP.ACCESS_TO_OTHER_CARS, value: [useOfOtherCarsCode] },
        { name: LOOKUP.EMPLOYMENT, value: [employmentStatusCode] },
      ],
      insured: [
        { name: LOOKUP.MARITAL_STATUS, value: [maritalStatusCdI] },
        { name: LOOKUP.RESIDENTIAL_STATUS, value: [residencyStatusI] },
        { name: LOOKUP.EMPLOYMENT, value: [employmentStatusI] },
        { name: LOOKUP.OCCUPATION, value: [primaryOccupationCodeI, secondaryOccupationCodeI] },
        { name: LOOKUP.INDUSTRY, value: [primaryOccupationIndustryCodeI, secondaryOccupationIndustryCodeI] },
      ],
      renewal: [{ name: LOOKUP.MOTOR_COMPANY_DECLINED_REASON, value: [lastActionReasonCd] }],
      vehicle: [
        { name: LOOKUP.MODIFICATIONS, value: modifications.map((item) => item.modificationCode) },
        { name: LOOKUP.USAGE, value: [classOfUse] },
        { name: LOOKUP.FUEL_TYPE, value: [fuelTypeCode] },
        { name: LOOKUP.OWNER, value: [legalOwner] },
        { name: LOOKUP.BODY_TYPE, value: [bodyTypeCode] },
        { name: LOOKUP.MILEAGE, value: [totalDeclaredMileageCode] },
        { name: LOOKUP.TRANSMISSION, value: [transmissionTypeCode] },
        { name: LOOKUP.DAYTIME_LOCATION, value: [daytimeLocation] },
        { name: LOOKUP.OVERNIGHT_LOCATION, value: [overnightLocation] },
        { name: LOOKUP.VEHICLE_SECURITY, value: [securityDeviceDeclared] },
        { name: LOOKUP.KEEPER, value: [registeredKeeper] },
        { name: LOOKUP.MILEAGE, value: [businessMileageCode] },
        { name: LOOKUP.MILEAGE, value: [socialMileageCode] },
        { name: LOOKUP.RESIDENTIAL_STATUS, value: [residentialStatus] },
        { name: LOOKUP.COVERAGE_DESCRIPTION, value: packCoverages.map((item) => item.coverageCd) },
        { name: LOOKUP.COVER_TYPE, value: [coverType] },
      ],
    };
    return options
      .map((option) => lookups[option])
      .flat()
      .map((item) => genLookupPayload(item.value, item.name))
      .flat();
  };

  useEffect(() => {
    let unmounted = false;
    fetchDecodedLookups(lookupsPayload()).then(() => {
      if (!unmounted) {
        setIsDecodedLookupFetched(true);
      }
    });
    return () => {
      unmounted = true;
    };
  }, [driver?.uuid]);

  return {
    isDecodedLookupsFetched,
  };
};

export const useDecodedLookupsHome = (): DecodedLookupsReturn => {
  const [isDecodedLookupsFetched, setIsDecodedLookupFetched] = useState(false);

  const {
    dashboardStore: {
      fetchDecodedLookups,
      lookups: { genLookupPayload },
      insurance: {
        home: { policySelected },
      },
    },
  } = useStores();

  const lookupsPayload = () => {
    const {
      homePropertyUse: { occupantsCd, businessUseTypeCd },
      homeConstructionInfo: {
        ownershipTypeCd,
        propertyTypeCd,
        listedBuildingCd,
        roofBuildMaterialCd,
        wallBuildMaterialCd,
        percentageFlatRoofCd,
      },
      homeProtection: { doorsLockTypeCd, windowsLockTypeCd },
      claims: homeClaims,
      coverTypeCd,
    } = policySelected;

    return [
      { name: LOOKUP.OWNERSHIP, value: [ownershipTypeCd] },
      { name: LOOKUP.PROPERTY_TYPE, value: [propertyTypeCd] },
      { name: LOOKUP.LISTED_BUILDINGS, value: [listedBuildingCd] },
      { name: LOOKUP.ROOF_TYPE, value: [roofBuildMaterialCd] },
      { name: LOOKUP.WALL_TYPE, value: [wallBuildMaterialCd] },
      { name: LOOKUP.HOME_CLAIM_TYPE, value: homeClaims.map((item) => item.claimCd) },
      { name: LOOKUP.CLAIM_BUILDOR, value: homeClaims.map((item) => item.claimTypeCd) },
      { name: LOOKUP.ROOF_FLAT, value: [percentageFlatRoofCd] },
      { name: LOOKUP.RESIDENTS, value: [occupantsCd] },
      { name: LOOKUP.BUSINESS_TYPE, value: [businessUseTypeCd] },
      { name: LOOKUP.WINDOW_LOCK, value: [windowsLockTypeCd] },
      { name: LOOKUP.MAIN_ENTRANCE_LOCK, value: [doorsLockTypeCd] },
      { name: LOOKUP.HOME_COVER_TYPE, value: [coverTypeCd] },
    ]
      .flat()
      .map((item) => genLookupPayload(item.value, item.name))
      .flat();
  };

  useEffect(() => {
    let unmounted = false;

    fetchDecodedLookups(lookupsPayload()).then(() => {
      if (!unmounted) setIsDecodedLookupFetched(true);
    });
    return () => {
      unmounted = true;
    };
  }, []);

  return {
    isDecodedLookupsFetched,
  };
};
