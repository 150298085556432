import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { ILookupItem } from '../../service/state/models/dashboard/lookups';
import { MtaTooltip } from '../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';

import { Choice } from './choice';

export interface ChangeSecurityFeaturesProps {
  isSubmitting: boolean;
  options: ILookupItem[] | undefined;
  securityDeviceDeclared: string;
  setSecurityDeviceDeclared: (a: string) => void;
  setTrackingDeviceFitted: (a: boolean | null) => void;
  trackingDeviceFitted: boolean | null;
}

export const ChangeSecurityFeatures: React.FC<ChangeSecurityFeaturesProps> = ({
  securityDeviceDeclared,
  isSubmitting,
  options,
  trackingDeviceFitted,
  setSecurityDeviceDeclared,
  setTrackingDeviceFitted,
}) => {
  const { t } = useTranslation('myAccount');

  return (
    <>
      <Grid>
        <MtaTooltip
          description={
            <Typography variant="body1" className={!securityDeviceDeclared && isSubmitting ? 'error-message' : ''}>
              {t('MTAJourney.SecurityFeatures.otherSecurityDevices')}
            </Typography>
          }
          tooltipTitle={t('tooltips.changeYourCar.securityFeatures.top')}
        />
        <RadioGroup onChange={(event) => setSecurityDeviceDeclared(event.target.value)} value={securityDeviceDeclared}>
          {options?.map((item, index) => {
            return (
              <FormControlLabel
                key={`${item.code}-${index}`}
                value={item.code}
                control={<Radio />}
                label={item.value}
              />
            );
          })}
        </RadioGroup>
        {securityDeviceDeclared === '' && isSubmitting && (
          <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
        )}
      </Grid>
      <Grid className="choice-container">
        <MtaTooltip
          description={
            <Typography
              variant="body1"
              className={trackingDeviceFitted === null && isSubmitting ? 'error-message' : ''}
            >
              {t('MTAJourney.SecurityFeatures.isYourCarFitted')}
            </Typography>
          }
          tooltipTitle={t('tooltips.changeYourCar.securityFeatures.bottom')}
        />

        <Choice
          identifier="harTrackingDevices"
          onChange={(event) => {
            setTrackingDeviceFitted(event.target.value === 'true');
          }}
          left={{ label: 'No' }}
          right={{ label: 'Yes' }}
          radioValue={trackingDeviceFitted}
          error={trackingDeviceFitted === null && isSubmitting}
        />
      </Grid>
    </>
  );
};
