import { CommonContainer, Grid, Link, Typography } from '@esure-cloud/react-components';

import { Instance } from 'mobx-state-tree';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../service/analytics';
import { RenewalVehicle, Vehicle } from '../../../service/state/models/dashboard/autoPolicyModel';
import { DialogOptions } from '../../../service/state/models/interface/dialog';
import { DvlaCheck } from '../../../view/pages/policies/policy/makeAchange/mta/carDetails/steps/dvlaCheck';
import { ChatWithUs } from '../../../view/pages/policies/policy/makeAchange/mta/common/components/chatWithUs';
import { DisplayFoundCar } from '../displayFoundCar';

export interface Car {
  boxTitle: string;
  button: string;
  carModel: string;
  description?: string;
  notMyCar: string;
  notMyCarLink: string;
  notTheRightVehicle: string;
  pageName: string;
  pageNumber: string;
  registrationNumber: string;
  title: string;
  updateRegistration: string;
}

export interface CarFoundDetailsProps {
  changeCarJourney?: boolean;
  handleChat?: () => void;
  manualRegistrationChange?: boolean;
  openDialog?: (dialogConfig: DialogOptions) => void;
  setManualRegistrationChange?: Dispatch<SetStateAction<boolean>>;
  vehicle: Instance<typeof Vehicle | typeof RenewalVehicle>;
}

export const CarFoundDetails: React.FC<CarFoundDetailsProps> = ({
  vehicle,
  openDialog,
  manualRegistrationChange,
  setManualRegistrationChange,
  changeCarJourney,
  handleChat,
}) => {
  const { t } = useTranslation('myAccount');
  const content: Car = t('MTAJourney.CarFound.checkDetails', { returnObjects: true });

  const handleClick = () => {
    if (!setManualRegistrationChange) return;

    setManualRegistrationChange((prevState) => !prevState);
  };

  if (manualRegistrationChange)
    return (
      <DvlaCheck
        registrationNo={vehicle.vrm}
        vehicleMakeModel={`${vehicle.make} ${vehicle.model}`}
        carFoundManualUpdate={true}
        handleBack={handleClick}
        setManualRegistrationChange={setManualRegistrationChange}
      />
    );

  return (
    <Grid>
      <Typography className="content__subtitle">{content.description}</Typography>
      <CommonContainer className="title-box">
        <Typography variant="h3">{content.boxTitle}</Typography>
      </CommonContainer>
      <DisplayFoundCar vehicleObject={vehicle} simpleVariant={!openDialog}>
        {openDialog || changeCarJourney ? (
          <ChatWithUs
            description={t('MTAJourney.CarFound.checkDetails.notMyCar')}
            customHandleClick={handleChat}
            linkId={COMPONENT_ID.NOT_YOUR_CAR_CHAT_LINK}
          />
        ) : (
          <>
            <Typography>{content.notTheRightVehicle}</Typography>
            <Link
              data-testid={COMPONENT_ID.MTA_CHANGE_REGISTRATION_MANUALLY_BUTTON}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
            >
              {content.updateRegistration}
            </Link>
          </>
        )}
      </DisplayFoundCar>
    </Grid>
  );
};

export default CarFoundDetails;
