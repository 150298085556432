import { Callback, EventProperties, SegmentEvent } from '@segment/analytics-next';
import { DateTime } from 'luxon';
import { UAParser } from 'ua-parser-js';

import config from './config';
import { EventProps } from './util/customHooks/useSegment';
import { getDateTimeNow } from './util/dateUtils';

declare global {
  interface Window {
    segmentEventsHistory?: { [K in SegmentEvent['type']]: SegmentEvent[] };
  }
}

export const storeSegmentEventInHistory = (event: SegmentEvent): void => {
  // Create Segment events history object on the window, if it doesn't already exist
  window.segmentEventsHistory = window.segmentEventsHistory ?? {
    alias: [],
    group: [],
    identify: [],
    page: [],
    screen: [],
    track: [],
  };

  // Directly access the event type array, then push the new event
  const historyArray = window.segmentEventsHistory[event.type];
  historyArray.push(event);

  // Keep only the most recent 10 items
  if (historyArray.length > 10) {
    window.segmentEventsHistory[event.type] = historyArray.slice(-10);
  }
};

export enum COMPONENT_ID {
  ACCOUNT_MANAGEMENT_NEED_HELP_LINK = 'accountManagementNeedHelp',
  ADYEN_DROPIN_BUTTON_PAY = 'adyenDropinPay',
  BLUE_BANNER_MOBILE_BACK_BUTTON = 'blueBannerMobileBack',
  CHAT_POLICY_FAILED_LOAD_BUTTON = 'chatPolicyFailedLoad',
  CHAT_WITH_US_BUTTON = 'chatWithUsButton',
  DRIVERS_PAGE_BACK_A_STEP_LINK = 'driversPageBackAStepLink',
  DRIVER_CARD = 'driverCard',
  DRIVER_DETAILS_CARD = 'driverDetailsCard',
  FIND_ADDRESS_BUTTON = 'findAddressButton',
  FIND_ADDRESS_CANCEL_BUTTON = 'findAddressCancelButton',
  GENERIC_TECHNICAL_ERROR_DIALOG_CHAT_LINK = 'genericTechnicalErrorDialogChat',
  HELP_CENTRE_CARD = 'helpCentreCard',
  HOW_TO_MAKE_A_CLAIM_CARD = 'howToMakeAClaimCard',
  LOGIN_BACK_TO_LOGIN = 'loginBackToLogin',
  LOGIN_BUTTON_LANDING_PAGE_MAIN_CONTENT = 'loginButtonLandingPageMainContent',
  LOGIN_BUTTON_NAV_BAR = 'loginButtonNavBar',
  LOGIN_BUTTON_NEXT = 'loginNextButton',
  LOGIN_RESEND_LINK = 'loginResendLink',
  MTA_BACK_BUTTON_CAR_MODIFIED = 'mtaCarModifiedBack',
  MTA_BACK_BUTTON_CAR_OVERNIGHT_LOCATION = 'mtaCarOvernightLocationBack',
  MTA_BACK_BUTTON_CAR_REGISTRATION_NUMBER_MANUAL_CHANGE = 'mtaRegistrationNumberManualChangeBack',
  MTA_BACK_BUTTON_CAR_USAGE = 'mtaCarUsageBack',
  MTA_BACK_BUTTON_CHANGE_HOME_ADDRESS = 'mtaChangeHomeAddressBack',
  MTA_BACK_BUTTON_CHANGE_NAME = 'mtaChangeNameBack',
  MTA_BACK_BUTTON_DATE_OF_BIRTH = 'mtaDateOfBirthBack',
  MTA_BACK_BUTTON_DRIVER_CHANGED = 'mtaDriverChangedBack',
  MTA_BACK_BUTTON_DRIVER_HISTORY = 'mtaDriverHistoryBack',
  MTA_BACK_BUTTON_DRIVER_LICENSE = 'driverLicenseBack',
  MTA_BACK_BUTTON_DRIVER_OCCUPATION = 'driverOccupationBack',
  MTA_BACK_BUTTON_DRIVER_RELATIONSHIP = 'driverRelationshipBack',
  MTA_BACK_BUTTON_EDIT_DRIVER = 'editDriverBack',
  MTA_BACK_BUTTON_FOUND_CAR = 'mtaFoundCarBack',
  MTA_BACK_BUTTON_MAIN_DRIVER_ADD = 'mtaMainDriverAddBack',
  MTA_BACK_BUTTON_MAIN_DRIVER_REMOVE = 'mtaMainDriverRemoveBack',
  MTA_BACK_BUTTON_MAIN_DRIVER_UPDATE = 'mtaMainDriverUpdateBack',
  MTA_BACK_BUTTON_MORE_CHANGES = 'mtaMoreChangesBack',
  MTA_BACK_BUTTON_OWNERSHIP = 'mtaOwnershipBack',
  MTA_BACK_BUTTON_REGISTRATION_NUMBER = 'mtaRegistrationNumberBack',
  MTA_BACK_BUTTON_REGISTRATION_NUMBER_NOT_FOUND = 'mtaRegistrationNumberNotFoundBack',
  MTA_BACK_BUTTON_REMOVE_DRIVER = 'mtaRemoveDriverBack',
  MTA_BACK_BUTTON_REVIEW_CHANGES = 'mtaReviewChangesBack',
  MTA_BACK_BUTTON_SECURITY_FEATURES = 'mtaSecurityFeaturesBack',
  MTA_BACK_BUTTON_START_DATE = 'mtaStartDateBack',
  MTA_CANCEL_BUTTON_CAR_MODIFIED = 'mtaCarModifiedCancel',
  MTA_CANCEL_BUTTON_CAR_OVERNIGHT_LOCATION = 'mtaCarOvernightLocationCancel',
  MTA_CANCEL_BUTTON_CAR_REGISTRATION_NUMBER_MANUAL_CHANGE = 'mtaRegistrationNumberManualChangeCancel',
  MTA_CANCEL_BUTTON_CAR_USAGE = 'mtaCarUsageCancel',
  MTA_CANCEL_BUTTON_CHANGE_HOME_ADDRESS = 'mtaChangeHomeAddressCancel',
  MTA_CANCEL_BUTTON_CHANGE_NAME = 'mtaChangeNameCancel',
  MTA_CANCEL_BUTTON_DATE_OF_BIRTH = 'mtaDateOfBirthCancel',
  MTA_CANCEL_BUTTON_DRIVER_CHANGED = 'mtaDriverChangedCancel',
  MTA_CANCEL_BUTTON_DRIVER_HISTORY = 'mtaDriverHistoryCancel',
  MTA_CANCEL_BUTTON_DRIVER_LICENSE = 'driverLicenseCancel',
  MTA_CANCEL_BUTTON_DRIVER_OCCUPATION = 'driverOccupationCancel',
  MTA_CANCEL_BUTTON_DRIVER_RELATIONSHIP = 'driverRelationshipCancel',
  MTA_CANCEL_BUTTON_EDIT_DRIVER = 'editDriverCancel',
  MTA_CANCEL_BUTTON_FOUND_CAR = 'mtaFoundCarCancel',
  MTA_CANCEL_BUTTON_MAIN_DRIVER_ADD = 'mtaMainDriverAddCancel',
  MTA_CANCEL_BUTTON_MAIN_DRIVER_REMOVE = 'mtaMainDriverRemoveCancel',
  MTA_CANCEL_BUTTON_MAIN_DRIVER_UPDATE = 'mtaMainDriverUpdateCancel',
  MTA_CANCEL_BUTTON_MORE_CHANGES = 'mtaMoreChangesCancel',
  MTA_CANCEL_BUTTON_OWNERSHIP = 'mtaOwnershipCancel',
  MTA_CANCEL_BUTTON_QUOTE_READY = 'mtaQuoteReadyCancel',
  MTA_CANCEL_BUTTON_REGISTRATION_NUMBER = 'mtaRegistrationNumberCancel',
  MTA_CANCEL_BUTTON_REGISTRATION_NUMBER_NOT_FOUND = 'mtaRegistrationNumberNotFoundCancel',
  MTA_CANCEL_BUTTON_REMOVE_DRIVER = 'mtaRemoveDriverCancel',
  MTA_CANCEL_BUTTON_REVIEW_CHANGES = 'mtaReviewChangesCancel',
  MTA_CANCEL_BUTTON_SECURITY_FEATURES = 'mtaSecurityFeaturesCancel',
  MTA_CANCEL_BUTTON_START_DATE = 'mtaStartDateCancel',
  MTA_CANCEL_DIALOG_CONTINUE_BUTTON = 'mtaCancelDialogContinue',
  MTA_CANCEL_DIALOG_LEAVE_BUTTON = 'mtaCancelDialogLeave',
  MTA_CHANGE_REGISTRATION_MANUALLY_BUTTON = 'mtaChangeRegistrationManually',
  MTA_CHAT_WITH_US_LINK_CHANGE_NAME = 'mtaChangeNameChatWithUs',
  MTA_CONFIRM_BUTTON_QUOTE_READY = 'mtaQuoteReadyConfirm',
  MTA_CONTINUE_BUTTON_CAR_MODIFIED = 'mtaCarModifiedContinue',
  MTA_CONTINUE_BUTTON_CAR_NOT_FOUND = 'mtaCarNotFoundContinue',
  MTA_CONTINUE_BUTTON_CAR_OVERNIGHT_LOCATION = 'mtaCarOvernightLocationContinue',
  MTA_CONTINUE_BUTTON_CAR_REGISTRATION_MANUAL_CHANGE = 'mtaCarRegistrationManualChangeContinue',
  MTA_CONTINUE_BUTTON_CAR_USAGE = 'mtaCarUsageContinue',
  MTA_CONTINUE_BUTTON_CHANGE_HOME_ADDRESS = 'mtaChangeHomeAddressContinue',
  MTA_CONTINUE_BUTTON_CHANGE_NAME = 'mtaChangeNameContinue',
  MTA_CONTINUE_BUTTON_DATE_OF_BIRTH = 'mtaDateOfBirthContinue',
  MTA_CONTINUE_BUTTON_DRIVER_CHANGED = 'mtaDriverChangedContinue',
  MTA_CONTINUE_BUTTON_DRIVER_HISTORY = 'mtaDriverHistoryContinue',
  MTA_CONTINUE_BUTTON_DRIVER_LICENSE = 'driverLicenseContinue',
  MTA_CONTINUE_BUTTON_DRIVER_OCCUPATION = 'driverOccupationContinue',
  MTA_CONTINUE_BUTTON_DRIVER_RELATIONSHIP = 'driverRelationshipContinue',
  MTA_CONTINUE_BUTTON_EDIT_DRIVER = 'editDriverContinue',
  MTA_CONTINUE_BUTTON_FOUND_CAR = 'mtaFoundCarContinue',
  MTA_CONTINUE_BUTTON_MAIN_DRIVER_ADD = 'mtaMainDriverAddContinue',
  MTA_CONTINUE_BUTTON_MAIN_DRIVER_REMOVE = 'mtaMainDriverRemoveContinue',
  MTA_CONTINUE_BUTTON_MAIN_DRIVER_UPDATE = 'mtaMainDriverUpdateContinue',
  MTA_CONTINUE_BUTTON_MORE_CHANGES = 'mtaMoreChangesContinue',
  MTA_CONTINUE_BUTTON_OWNERSHIP = 'mtaOwnershipContinue',
  MTA_CONTINUE_BUTTON_REMOVE_DRIVER = 'mtaRemoveDriverContinue',
  MTA_CONTINUE_BUTTON_REVIEW_CHANGES = 'mtaReviewChangesContinue',
  MTA_CONTINUE_BUTTON_SECURITY_FEATURES = 'mtaSecurityFeaturesContinue',
  MTA_CONTINUE_BUTTON_START_DATE = 'mtaStartDateContinue',
  MTA_FIND_ADDRESS_BUTTON = 'mtaFindAddress',
  MTA_FIND_CAR_BUTTON = 'mtaFindCar',
  MTA_MAKE_A_CHANGE_BUTTON = 'mtaMakeAChange',
  MTA_PAYMENT_EDIT_ADDRESS = 'mtaPaymentEditAddress',
  MTA_PAY_BUTTON_QUOTE_READY = 'mtaQuoteReadyPay',
  MTA_POLICY_BUTTON_PAYMENT_CONFIRMATION = 'mtaPaymentConfirmationGoToPolicy',
  NEED_HELP_APPBAR_BUTTON = 'appbarNeedHelp',
  NEED_HELP_APPBAR_BUTTON_CLAIMS_PAGE = 'claimsPageAppbarNeedHelp',
  NOT_YOUR_CAR_CHAT_LINK = 'notYourCarChat',
  NO_CLAIMS_DISCOUNT_CARD = 'noClaimsDiscount',
  RENEWAL_ADDITIONAL_DRIVER_BUTTON = 'renewalAdditionalDriverButton',
  RENEWAL_ADD_CLAIM_BUTTON = 'renewalAddClaimButton',
  RENEWAL_ADD_CRIMINAL_CONVICTION_BUTTON = 'renewalAddCriminalConvictionButton',
  RENEWAL_ADD_MOTORING_CONVICTION_BUTTON = 'renewalAddMotoringConvictionButton',
  RENEWAL_BACK_BUTTON_MAIN_DRIVER = 'renewalMainDriverBackButton',
  RENEWAL_BACK_BUTTON_REGISTERED_KEEPER = 'renewalRegisteredKeeperBackButton',
  RENEWAL_CANCEL_BUTTON_MAIN_DRIVER = 'renewalMainDriverCancelButton',
  RENEWAL_CANCEL_BUTTON_REGISTERED_KEEPER = 'renewalRegisteredKeeperCancelButton',
  RENEWAL_CARD = 'renewalCard',
  RENEWAL_CARS_IN_HOUSEHOLD_BACK = 'renewalCarsInHouseholdBackButton',
  RENEWAL_CARS_IN_HOUSEHOLD_CANCEL = 'renewalCarsInHouseholdCancelButton',
  RENEWAL_CARS_IN_HOUSEHOLD_CONTINUE = 'renewalCarsInHouseholdContinueButton',
  RENEWAL_CAR_FOUND_BACK = 'renewalCarFoundBackButton',
  RENEWAL_CAR_FOUND_CANCEL = 'renewalCarFoundCancelButton',
  RENEWAL_CAR_FOUND_SAVE = 'renewalCarFoundSaveButton',
  RENEWAL_CAR_MODIFIED_BACK = 'renewalCarModifiedBackButton',
  RENEWAL_CAR_MODIFIED_CANCEL = 'renewalCarModifiedCancelButton',
  RENEWAL_CAR_MODIFIED_SAVE = 'renewalCarModifiedSaveButton',
  RENEWAL_CAR_OWNERSHIP_BACK = 'renewalCarOwnershipBackButton',
  RENEWAL_CAR_OWNERSHIP_CANCEL = 'renewalCarOwnershipCancelButton',
  RENEWAL_CAR_OWNERSHIP_CONTINUE = 'renewalCarOwnershipContinueButton',
  RENEWAL_CAR_REG_BACK = 'renewalCarRegBackButton',
  RENEWAL_CAR_REG_CANCEL = 'renewalCarRegCancelButton',
  RENEWAL_CAR_REG_SAVE = 'renewalCarRegSaveButton',
  RENEWAL_CHANGE_SECURITY_BACK = 'renewalChangeSecurityBackButton',
  RENEWAL_CHANGE_SECURITY_CANCEL = 'renewalChangeSecurityCancelButton',
  RENEWAL_CHANGE_SECURITY_SAVE = 'renewalChangeSecuritySaveButton',
  RENEWAL_CONTINUE_BUTTON_MAIN_DRIVER = 'renewalMainDriverContinueButton',
  RENEWAL_CONTINUE_BUTTON_REGISTERED_KEEPER = 'renewalRegisteredKeeperContinueButton',
  RENEWAL_DAYTIME_LOCATION_BACK = 'renewalDaytimeLocationBack',
  RENEWAL_DAYTIME_LOCATION_CANCEL = 'renewalDaytimeLocationCancel',
  RENEWAL_DAYTIME_LOCATION_SAVE = 'renewalDaytimeLocationSave',
  RENEWAL_DOB_BACK = 'renewalDOBBackButton',
  RENEWAL_DOB_CANCEL = 'renewalDOBCancelButton',
  RENEWAL_DOB_CONTINUE = 'renewalDOBContinueButton',
  RENEWAL_DRIVER_NAME_BACK = 'renewalDriverNameBackButton',
  RENEWAL_DRIVER_NAME_CANCEL = 'renewalDriverNameCancelButton',
  RENEWAL_DRIVER_NAME_CONTINUE = 'renewalDriverNameContinueButton',
  RENEWAL_DRIVE_ANOTHER_VEHICLE_BACK = 'renewalDriveAnotherVehicleBackButton',
  RENEWAL_DRIVE_ANOTHER_VEHICLE_CANCEL = 'renewalDriveAnotherVehicleCancelButton',
  RENEWAL_DRIVE_ANOTHER_VEHICLE_CONTINUE = 'renewalDriveAnotherVehicleContinueButton',
  RENEWAL_DRIVING_LICENCE_BACK = 'renewalDrivingLicenceBackButton',
  RENEWAL_DRIVING_LICENCE_CANCEL = 'renewalDrivingLicenceCancelButton',
  RENEWAL_DRIVING_LICENCE_CONTINUE = 'renewalDrivingLicenceContinueButton',
  RENEWAL_EDIT_LINK_BUSINESS_MILEAGE = 'renewalEditLinkBusinessMileage',
  RENEWAL_EDIT_LINK_CHANGE_CAR = 'renewalEditLinkChangeCar',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_CLAIMS = 'renewalEditLinkChangeDriverClaims',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_LICENSE = 'renewalEditLinkChangeDriverLicense',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_NAME = 'renewalEditLinkChangeDriverName',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_OCCUPATION = 'renewalEditLinkChangeDriverOccupation',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_OFFENCES = 'renewalEditLinkChangeDriverOffences',
  RENEWAL_EDIT_LINK_CHANGE_DRIVER_SECONDARY_OCCUPATION = 'renewalEditLinkChangeDriverSecondaryOccupation',
  RENEWAL_EDIT_LINK_CHANGE_LEGAL_OWNER = 'renewalEditLinkChangeLegalOwner',
  RENEWAL_EDIT_LINK_CHANGE_MODIFICATIONS = 'renewalEditLinkChangeModifications',
  RENEWAL_EDIT_LINK_CHANGE_REG = 'renewalEditLinkChangeReg',
  RENEWAL_EDIT_LINK_CHANGE_REGISTERED_KEEPER = 'renewalEditLinkChangeRegisteredKeeper',
  RENEWAL_EDIT_LINK_CHANGE_RELATIONSHIP_STATUS = 'renewalEditLinkChangeRelationshipStatus',
  RENEWAL_EDIT_LINK_CHANGE_RELATIONSHIP_TO_POLICYHOLDER = 'renewalEditLinkChangeRelationshipToPolicyHolder',
  RENEWAL_EDIT_LINK_CHANGE_SECURITY = 'renewalEditLinkChangeSecurity',
  RENEWAL_EDIT_LINK_CHANGE_TRACKING_DEVICE = 'renewalEditLinkChangeTrackingDevice',
  RENEWAL_EDIT_LINK_DAYTIME_LOCATION = 'renewalEditLinkDaytimeLocation',
  RENEWAL_EDIT_LINK_OVERNIGHT_LOCATION = 'renewalEditLinkOvernightLocation',
  RENEWAL_EDIT_LINK_SOCIAL_MILEAGE = 'renewalEditLinkSocialMileage',
  RENEWAL_EDIT_LINK_USAGE = 'renewalEditLinkUsage',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_CARS_AT_HOME = 'renewalEditLinkYourDetailsCarsAtHome',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_CHILDREN = 'renewalEditLinkYourDetailsChildren',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_HOME_ADDRESS = 'renewalEditLinkYourDetailsHomeAddress',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_NAME = 'renewalEditLinkYourDetailsName',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_OCCUPATION = 'renewalEditLinkYourDetailsOccupation',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_RELATIONSHIP = 'renewalEditLinkYourDetailsRelationship',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_RESIDENTIAL_STATUS = 'renewalEditLinkYourDetailsResidentialStatus',
  RENEWAL_EDIT_LINK_YOUR_DETAILS_SECONDARY_OCCUPATION = 'renewalEditLinkYourDetailsSecondaryOccupation',
  RENEWAL_HISTORY_CLAIMS_CONVICTIONS_BACK = 'renewalHistoryClaimsConvictionsBackButton',
  RENEWAL_HISTORY_CLAIMS_CONVICTIONS_CANCEL = 'renewalHistoryClaimsConvictionsCancelButton',
  RENEWAL_HISTORY_CLAIMS_CONVICTIONS_CONTINUE = 'renewalHistoryClaimsConvictionsContinueButton',
  RENEWAL_HOME_ADDRESS_BACK = 'renewalHomeAddressBackButton',
  RENEWAL_HOME_ADDRESS_CANCEL = 'renewalHomeAddressCancelButton',
  RENEWAL_HOME_ADDRESS_CONTINUE = 'renewalHomeAddressContinueButton',
  RENEWAL_HOW_MANY_CHILDREN_BACK = 'renewalHowManyChildrenBackButton',
  RENEWAL_HOW_MANY_CHILDREN_CANCEL = 'renewalHowManyChildrenCancelButton',
  RENEWAL_HOW_MANY_CHILDREN_CONTINUE = 'renewalHowManyChildrenContinueButton',
  RENEWAL_LEGAL_OWNER_BACK = 'renewalLegalOwnerBackButton',
  RENEWAL_LEGAL_OWNER_CANCEL = 'renewalLegalOwnerCancelButton',
  RENEWAL_LEGAL_OWNER_SAVE = 'renewalLegalOwnerSaveButton',
  RENEWAL_OCCUPATION_BACK = 'renewalOccupationBackButton',
  RENEWAL_OCCUPATION_CANCEL = 'renewalOccupationCancelButton',
  RENEWAL_OCCUPATION_CONTINUE = 'renewalOccupationContinueButton',
  RENEWAL_OVERNIGHT_LOCATION_BACK = 'renewalOvernightLocationBack',
  RENEWAL_OVERNIGHT_LOCATION_CANCEL = 'renewalOvernightLocationCancel',
  RENEWAL_OVERNIGHT_LOCATION_SAVE = 'renewalOvernightLocationSave',
  RENEWAL_PAYMENT_EDIT_ADDRESS = 'renewalPaymentEditAddress',
  RENEWAL_RELATED_BACK = 'renewalRelatedBackButton',
  RENEWAL_RELATED_CANCEL = 'renewalRelatedCancelButton',
  RENEWAL_RELATED_CONTINUE = 'renewalRelatedContinueButton',
  RENEWAL_RELATIONSHIP_STATUS_BACK = 'renewalRelationshipStatusBackButton',
  RENEWAL_RELATIONSHIP_STATUS_CANCEL = 'renewalRelationshipStatusCancelButton',
  RENEWAL_RELATIONSHIP_STATUS_CONTINUE = 'renewalRelationshipStatusContinueButton',
  RENEWAL_REMOVE_DRIVER = 'renewalRemoveDriverLink',
  RENEWAL_RESIDENTIAL_STATUS_BACK = 'renewalResidentialStatusBackButton',
  RENEWAL_RESIDENTIAL_STATUS_CANCEL = 'renewalResidentialStatusCancelButton',
  RENEWAL_RESIDENTIAL_STATUS_CONTINUE = 'renewalResidentialStatusContinueButton',
  RENEWAL_SUMMARY_BACK = 'renewalSummaryBack',
  RENEWAL_SUMMARY_CONTINUE_ANNUAL = 'renewalSummaryContinueAnnual',
  RENEWAL_SUMMARY_CONTINUE_DD_AUTORENEW = 'renewalSummaryContinueDdAutoRenewal',
  RENEWAL_UPDATE_INSURANCE_HISTORY = 'renewalUpdateInsuranceHistoryLink',
  RENEWAL_UPDATE_YOUR_QUOTE = 'renewalQuoteUpdateYourQuoteButton',
  RENEWAL_USAGE_MILEAGE_BACK = 'renewalUsageMileageBack',
  RENEWAL_USAGE_MILEAGE_CANCEL = 'renewalUsageMileageCancel',
  RENEWAL_USAGE_MILEAGE_SAVE = 'renewalUsageMileageSave',
  RENEWAL_YOUR_COVER_DETAILS = 'renewalQuoteYourCoverDetailsButton',
  RENEWAL_YOUR_COVER_DETAILS_CONTINUE = 'renewalYourCoverDetailsContinueButton',
  RENEWAL_YOUR_NAME_BACK = 'renewalYourNameBackButton',
  RENEWAL_YOUR_NAME_CANCEL = 'renewalYourNameCancelButton',
  RENEWAL_YOUR_NAME_CONTINUE = 'renewalYourNameContinueButton',
  RENEWAL_YOUR_NEW_QUOTE_CHECK_DETAILS = 'renewalNewQuoteCheckDetailsButton',
  RENEWAL_YOUR_NEW_QUOTE_CONTINUE = 'renewalNewQuoteContinueButton',
  RENEWAL_YOUR_QUOTES_CURRENT = 'renewalCurrentRenewalQuoteButton',
  RENEWAL_YOUR_QUOTES_SAVED = 'renewalSavedRenewalQuoteButton',
  RENEWAL_YOUR_RELATIONSHIP_STATUS_BACK = 'renewalYourRelationshipStatusBackButton',
  RENEWAL_YOUR_RELATIONSHIP_STATUS_CANCEL = 'renewalYourRelationshipStatusCancelButton',
  RENEWAL_YOUR_RELATIONSHIP_STATUS_CONTINUE = 'renewalYourRelationshipStatusContinueButton',
  RENEWAL_YOUR_RENEWAL_QUOTE_CONTINUE = 'renewalQuoteContinue',
  RENEWAL_YOUR_SAVED_QUOTE_CHECK_DETAILS = 'renewalSavedQuoteCheckDetailsButton',
  RENEWAL_YOUR_SAVED_QUOTE_CONTINUE = 'renewalSavedQuoteContinueButton',
  VIEW_POLICY_BUTTON = 'viewPolicyButton',
  WELCOME_TO_ACCOUNT_MODAL_CLOSE = 'welcomeToAccountModalClose',
}
// Create new instance of the UAParser
export const uaParser = new UAParser();

/* istanbul ignore next */
export const event = (name: string, data?: EventProps): void => {
  const trackData: EventProps = {
    sessionId: getDeviceSession(),
    url: window.location.href,
    ...data,
  };
  window.analytics.track(name, trackData);
};

export enum JOURNEY {
  SERVICE = 'service',
}

// Name of the property holding the session hash in local storage
export const STORAGE_SESSION_HASH_NAME = 'sessionHash';

/**
 * Determines the device type if the ua-parser-js library has set it as undefined
 * @returns desktop if the screen width is greater than or equal to 1024, otherwise undefined
 */
export const determineDeviceType = (): string | undefined => {
  return window.screen.width >= 1024 ? 'desktop' : undefined;
};

const generateId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDeviceSession = (): string => {
  const hash = localStorage.getItem(STORAGE_SESSION_HASH_NAME);
  const current: DateTime = getDateTimeNow();
  if (hash === null) {
    //generate new hash
    const newHash = generateId(32);
    const dateString = JSON.stringify(current);
    localStorage.setItem(STORAGE_SESSION_HASH_NAME, `${newHash},${dateString}`);
    return newHash;
  } else {
    //hash exists
    if (hash.indexOf(',') > -1) {
      const serialized = hash.split(',')[1];
      const deserialized: Date = new Date(JSON.parse(serialized));
      const diffTime = current.toMillis() - deserialized.getTime();
      const mins = diffTime / (1000 * 60);
      if (mins >= 30) {
        //generate new hash
        const newHash = generateId(32);
        const dateString = JSON.stringify(current);
        localStorage.setItem(STORAGE_SESSION_HASH_NAME, `${newHash},${dateString}`);
        return newHash;
      } else {
        //update timestamp
        const dateString = JSON.stringify(current);
        const session = hash.split(',')[0];
        localStorage.setItem(STORAGE_SESSION_HASH_NAME, `${session},${dateString}`);
        return session;
      }
    } else {
      //broken value generate new hash
      const newHash = generateId(32);
      const dateString = JSON.stringify(current);
      localStorage.setItem(STORAGE_SESSION_HASH_NAME, `${newHash},${dateString}`);
      return newHash;
    }
  }
};

/**
 * Middleware to add extra context to all Segment events
 */
export const applySegmentMiddleware = (): void => {
  window.analytics.addSourceMiddleware(({ payload, next }) => {
    // Get the context object from the payload, if for some reason it's undefined create a new one
    const context = payload.obj.context ?? {};
    const uaParserResult = uaParser.getResult();
    // Populate the operating system details
    context.os = {
      name: uaParserResult.os.name,
      version: uaParserResult.os.version,
    };
    // Populate the device details
    context.device = {
      manufacturer: uaParserResult.device.vendor,
      model: uaParserResult.device.model,
      type: uaParserResult.device.type ?? determineDeviceType(),
    };
    // Populate the screen details
    context.screen = {
      // Using screen height and width, this is the physical device screen size (resolution), so wont change with browser resizing
      height: window.screen.height,
      width: window.screen.width,
    };
    // Populate the browser details
    context.browser = {
      name: uaParserResult.browser.name,
      version: uaParserResult.browser.version,
    };
    // Populate the app details
    context.app = {
      name: config.app.name,
      version: config.app.version,
    };
    // Populate our own referrerInfo object
    context.referrerInfo = {
      referrer: context.page?.referrer,
      referrerPath: context.page?.referrer ? new URL(context.page.referrer).pathname : undefined,
    };
    storeSegmentEventInHistory(payload.obj);
    next(payload);
  });
};

/**
 * Fire a Segment page event.
 * @param lastVisitedUrl The last url visited.
 * @param category The category of the page.
 * @param name The name of the page.
 * @param properties Any additional properties to add to the event.
 */
export const fireSegmentPageEvent = (
  lastVisitedUrl?: string,
  category?: string | object,
  name?: string | object | Callback,
  properties?: EventProperties,
): void => {
  window.analytics.page(category, name, {
    // As this is a SPA, we need to do our own tracking of the referrer. The referrer property is a built in property name in Segment, and so by specifiying it here,
    // it will be updated in both the properties object and the context.page object. We take the last visited page from our state as the referrer, or if that is not available, the document referrer.
    referrer: lastVisitedUrl ?? document.referrer,
    sessionId: getDeviceSession(),
    // Add on any extra custom properties
    ...properties,
  });
};

/**
 * Camel cases a string
 * @param str the string to camel case
 * @returns the string camel cased, with any non alphanumeric characters also removed
 */
export const camelCaseString = (str: string) => {
  return str.toLowerCase().replace(/[^a-z0-9]+(.)/gi, (_m: string, chr: string) => chr.toUpperCase());
};
