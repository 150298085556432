import {
  Grid,
  IconChevronRight,
  Link,
  Breadcrumbs as MUIBreadcrumbs,
  Theme,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useStores } from '../../service/state/store';
import { useBreadcrumbs } from '../../service/util/customHooks/useBreadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .last-crumb': {
      color: theme.palette.secondary.dark,
      fontSize: 14,
      fontWeight: 700,
    },
    '& .text-style': {
      '&:hover': {
        textDecoration: 'underline',
      },
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    marginLeft: theme.spacing(6),
  },
}));

export const Breadcrumbs: React.FC = observer(function Breadcrumbs() {
  const classes = useStyles();
  const breadcrumbsRouteMap = useBreadcrumbs();
  const location = useLocation();
  const {
    interfaceStore: { isDesktop },
  } = useStores();

  const userLocation = breadcrumbsRouteMap.find((route) => route.path === location.pathname);

  return userLocation && isDesktop ? (
    <Grid container className={clsx(classes.root)}>
      <MUIBreadcrumbs aria-label="breadcrumbs" separator={<IconChevronRight color="disabled" fontSize="small" />}>
        {userLocation.breadcrumbs.map((route) => {
          const lastCrumb = location.pathname === route.path;
          return lastCrumb ? (
            <Typography className="last-crumb" noWrap key={route.path}>
              {route.label}
            </Typography>
          ) : (
            <Link className="text-style" key={route.path} component={RouterLink} to={route.path}>
              <Typography variant="body2">{route.label}</Typography>
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </Grid>
  ) : (
    <></>
  );
});
