/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, FormHelperText, Grid, Link, TextField, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressesContainer } from '../../../../../../../../component/common/addressesContainer';
import IconSure from '../../../../../../../../component/common/icon';
import { IInsuredAddress } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import SelfService from '../../../../../../../../service/util/selfService';
import { EditFooter } from '../../common/components/editFooter';
import { useStepperContext } from '../../common/utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .addresses': {
      gap: spacing(2),
    },
    '& .find:disabled': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
    '& .form': {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(3),
    },
    '& .link': {
      cursor: 'pointer',
      marginTop: spacing(2),
    },
    gap: spacing(3),
    width: 'fit-content',
  },
}));

interface AddressForm {
  houseNumber: string;
  postcode: string;
}

export const EditHomeAddress: React.FC<EditPageProps> = observer(function EditHomeAddress({ handleBack }) {
  const { setTitle } = useStepperContext();
  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | undefined>();
  const {
    mtaStore: {
      pendedMTA: { setInsuredChangedAddress, setInsuredAddress },
    },
  } = useStores();

  const { t } = useTranslation('myAccount');

  const [addresses, setAddresses] = useState<IInsuredAddress[]>();
  const [attempts, setAttempts] = useState(0);

  const classes = useStyles();
  const {
    dashboardStore: { fetchAddressLookup },
  } = useStores();

  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<AddressForm>({
    defaultValues: {
      houseNumber: '',
      postcode: '',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (attempts >= 3 || selectedAddress || !isValid) {
      setAddresses(undefined);
    }
  }, [selectedAddress, attempts, isValid]);

  useEffect(() => {
    setTitle(t('MTAJourney.homeAddress.title'));
  }, []);

  /* istanbul ignore next */
  const handleFindAddress = (data: FieldValues) => {
    fetchAddressLookup(data.houseNumber, data.postcode).then((res) => {
      if (res !== undefined) {
        setAddresses(res);
        if (attempts >= 3) {
          setAttempts(0);
          //setNoOfAttempts?.(0);
        }
      } else {
        setAttempts(attempts + 1);
        // setNoOfAttempts?.(attempts + 1);
        setError('postcode', { message: `${t('MTAJourney.fetchingAddressesError')}`, type: 'noMatchingAddresses' });
      }
    });
  };

  const handleContinue = () => {
    if (selectedAddress !== undefined) {
      setInsuredChangedAddress(true);
      setInsuredAddress(selectedAddress);
      handleBack();
    }
  };

  const handleChangeAddress = () => {
    setSelectedAddress(undefined);
  };
  return (
    <>
      <Grid container direction="column" xs item className={classes.root}>
        {selectedAddress ? (
          <Grid item>
            <Typography variant="body1">
              <span
                dangerouslySetInnerHTML={{
                  __html: SelfService.formatAddress(selectedAddress, true).full,
                }}
              />
            </Typography>
            <Link component="button" underline="none" className="link" onClick={handleChangeAddress}>
              {t('MTAJourney.buttons.changeAddress')}
            </Link>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit(handleFindAddress)} className="form">
            <Controller<AddressForm, 'houseNumber'>
              name="houseNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('MTAJourney.fields.houseNumber')}
                  placeholder={t('placeholders.houseNumber')}
                />
              )}
            />
            <Grid item>
              <Controller<AddressForm, 'postcode'>
                name="postcode"
                control={control}
                rules={{
                  pattern: {
                    message: `${t('validations.validPostcode')}`,
                    value: /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
                  },
                  required: {
                    message: `${t('validations.validPostcode')}`,
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('MTAJourney.fields.postcode')}
                    placeholder={t('placeholders.postcode')}
                    error={!!errors.postcode}
                    fullWidth
                    InputProps={{
                      endAdornment: errors.postcode && <IconSure icon="alert" size="20" color="error" type="solid" />,
                    }}
                  />
                )}
              />
              {errors.postcode && <FormHelperText error>{errors.postcode.message}</FormHelperText>}
            </Grid>
            <Button color="primary" type="submit" variant="contained" className="find" disabled={isSubmitting}>
              {t('MTAJourney.buttons.findAddress')}
            </Button>
          </form>
        )}
        {addresses && (
          <Grid container className="addresses" direction="column">
            <Typography variant="h3">{t('MTAJourney.foundResults', { value: addresses.length.toString() })}</Typography>
            <Typography variant="body1">{t('MTAJourney.selectAddressInfo')}</Typography>
            <AddressesContainer addresses={addresses} setSelectedAddress={setSelectedAddress} />
          </Grid>
        )}
        {attempts >= 3 && <Typography variant="body1">{t('MTAJourney.homeAddress.quoteHelp')}</Typography>}
      </Grid>
      <EditFooter id="editHomeAddress" validPage={true} handleBack={handleBack} handleSave={handleContinue} />
    </>
  );
});
