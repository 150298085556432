import { CommonContainer, List, ListItem, ListItemText } from '@esure-cloud/react-components';

import { makeStyles } from '@material-ui/core';
import React from 'react';

import { IInsuredAddress } from '../../service/state/models/dashboard/autoPolicyModel';
import SelfService from '../../service/util/selfService';

export interface AddressesContainerProps {
  addresses: IInsuredAddress[];
  setSelectedAddress: (value: IInsuredAddress) => void;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    maxHeight: spacing(44),
    overflowX: 'auto',
    paddingLeft: 0,
  },
}));

export const AddressesContainer: React.FC<AddressesContainerProps> = ({ addresses, setSelectedAddress }) => {
  const classes = useStyles();
  return (
    <CommonContainer className={classes.root}>
      <List dense disablePadding>
        {addresses.map((item, index) => {
          return (
            <ListItem
              data-testid="address-item"
              button
              key={index}
              onClick={() => {
                setSelectedAddress(item);
              }}
            >
              <ListItemText primary={SelfService.formatAddress(item).full} />
            </ListItem>
          );
        })}
      </List>
    </CommonContainer>
  );
};
