import { Alert, AlertTitle, CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { clone } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MTA_COMMON_ROUTES, ROUTE } from '../../../../../../service/constant';
import { Driver, IDriver, IDriverAssignment } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../service/state/store';
import RouterPrompt from '../../../../../../service/util/routerPrompt';

import { useStepperContext } from './common/utils/stepperContext';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  alert: {
    marginTop: spacing(2.5),
  },
  root: {
    '& .cancel-link': {
      padding: '0 36px',
    },
    '& .common-container': {
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      [breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: spacing(-4, -2, 0, -2),
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      color: palette.secondary.contrastText,
    },
    '& .step-content': {
      alignItems: 'center',
      [breakpoints.down('xs')]: {
        marginTop: spacing(2),
      },
    },
    '& .title': {
      marginBottom: spacing(3),
      marginTop: spacing(5),
    },
  },
}));

export const exclude = (isMultiMTA: boolean, pathname: string, isCarDetails: boolean, step: number): boolean => {
  if (
    pathname.includes('policies/policy/make-a-change/car-details/') ||
    pathname.includes('/policies/policy/make-a-change/your-details') ||
    pathname.includes('/policies/policy/make-a-change/drivers') ||
    MTA_COMMON_ROUTES.includes(pathname)
  )
    return false;
  if (!isMultiMTA && step <= 1) return true;
  if (pathname.includes('payment')) return false;

  if (!pathname.includes('car-details') || isCarDetails) return true;
  return true;
};

export const isInCheckoutJourney = (pathname: string): boolean => {
  return MTA_COMMON_ROUTES.includes(pathname);
};

export const MTALayout: React.FC = observer(function MTALayout() {
  const classes = useStyles();
  const {
    alert,
    step,
    stepsData: { steps },
    title,
    isMultiMTA,
    setEditMode,
    setOverrideStepCount,
    bannerTitle,
  } = useStepperContext();
  const {
    dashboardStore: {
      insurance: {
        auto: {
          mainVehicle,
          policySelected: { drivers, insured },
        },
      },
    },
    mtaStore: {
      isMTAInitialised,
      setMtaInitialised,
      pendedMTA: { setSelectedDriverUUID, setVehicle, setDrivers, setInsured, drivers: pendedMtaDrivers },
    },
    interfaceStore: { hasQuoteError, setHasQuoteError, hasIovationError, setHasIovationError },
  } = useStores();
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);

  const driverNotAlreadyAdded = () => {
    const newDriverIndex = pendedMtaDrivers.findIndex((driver) => driver.uuid.includes('emptyDriver'));
    return newDriverIndex === -1;
  };

  const addDrivers = () => {
    const updatedDrivers: IDriver[] = [];
    pendedMtaDrivers.map((driver) => {
      const foundMainDriver: IDriverAssignment = mainVehicle.driverAssignments.filter(
        ({ driverUuid }) => driverUuid === driver.uuid,
      )[0];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (foundMainDriver !== undefined) {
        updatedDrivers.push({ ...toJS(driver), mainDriver: foundMainDriver.mainDriverInd });
        return { ...driver, mainDriver: foundMainDriver.mainDriverInd };
      } else {
        return null;
      }
    });
    const newUuid = `emptyDriver${drivers.length}`;
    const emptyDriver = Driver.create({ uuid: newUuid });
    setSelectedDriverUUID(newUuid);
    if (driverNotAlreadyAdded()) {
      setDrivers([...updatedDrivers, emptyDriver]);
    }
  };

  const updateDrivers = () => {
    const updatedDrivers: IDriver[] = [];
    drivers.map((driver) => {
      const foundMainDriver: IDriverAssignment = mainVehicle.driverAssignments.filter(
        ({ driverUuid }) => driverUuid === driver.uuid,
      )[0];
      updatedDrivers.push({ ...toJS(driver), mainDriver: foundMainDriver.mainDriverInd });
      return { ...driver, mainDriver: foundMainDriver.mainDriverInd };
    });
    setDrivers([...updatedDrivers]);
  };

  useEffect(() => {
    if (!isMTAInitialised) {
      setMtaInitialised(true);
      setVehicle(clone(mainVehicle));
      setInsured(clone(insured));
      updateDrivers();
      // Reset these errors when first initialising an MTA
      setHasQuoteError(false);
      setHasIovationError(false);
    }
  }, []);

  useEffect(() => {
    if (pathname === ROUTE.ADD_DRIVER) {
      addDrivers();
    }
    setEditMode(false);
  }, [pathname]);

  useEffect(() => {
    setOverrideStepCount(0);
    setLoaded(true);
  }, []);

  const history = useHistory();

  return (
    <>
      {!hasQuoteError && !hasIovationError && (
        <RouterPrompt
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={(location) => {
            return exclude(isMultiMTA, location.pathname, history.location.pathname.includes('car-details'), step);
          }}
        />
      )}
      <Grid className={classes.root}>
        <CommonContainer className="common-container">
          <Typography variant="h3">{bannerTitle}</Typography>
          {!isInCheckoutJourney(pathname) && (
            <Typography variant="h4">
              {`${isMultiMTA && !MTA_COMMON_ROUTES.includes(pathname) ? step : step + 1} OF ${
                isMultiMTA ? steps.length - 1 : steps.length
              }`}
            </Typography>
          )}
        </CommonContainer>
        {alert?.showAlert && (
          <Alert className={classes.alert} severity={alert.severity}>
            <AlertTitle>{alert.title}</AlertTitle>
            <Typography variant="body2">{alert.description}</Typography>
          </Alert>
        )}
        <Typography className="title" variant="h2">
          {title}
        </Typography>
        <Grid className="step-content">{loaded && steps[step]}</Grid>
      </Grid>
    </>
  );
});
