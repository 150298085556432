import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { useStores } from '../../../../../../../../service/state/store';
import { MtaTooltip } from '../../../../makeAchange/mta/common/components/mtaTooltip';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    gap: spacing(1),
  },
}));

export const MainDriverAddRenewal: React.FC = observer(() => {
  const {
    renewalChangeStore: {
      renewalDetails: { setDrivers, drivers, driverFullName, removedDrivers },
    },
  } = useStores();

  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const { nextStep, setTitle } = useRenewalStepperContext();

  const nonRemovedDrivers = drivers.filter((item) => !removedDrivers.includes(item.uuid));
  const findMainDriver = nonRemovedDrivers.find((item) => item.main === true)?.uuid;
  const findInsuredDriver = nonRemovedDrivers.find((item) => item.relationshipToProposerCd === 'P')?.uuid;

  const [mainDriver, setMainDriver] = useState(findMainDriver);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setTitle(t('MTAJourney.chooseMainDriver.title'));
    if (typeof findMainDriver === 'undefined') {
      setMainDriver(findInsuredDriver);
    }
  }, []);

  const handlePendedMta = () => {
    setIsSubmitting(true);
    if (!mainDriver) return;
    setDrivers(
      drivers.map((item) => {
        if (item.uuid === mainDriver) {
          return { ...item, main: true };
        }
        return { ...item, main: false };
      }),
    );
  };

  const handleContinue = () => {
    handlePendedMta();
    nextStep();
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <MtaTooltip description={t('MTAJourney.chooseMainDriver.description')} tooltipTitle={t('tooltips.mainDriver')} />
      <Grid item>
        <RadioGroup
          defaultValue={typeof mainDriver === 'undefined' ? findInsuredDriver : mainDriver}
          value={typeof mainDriver === 'undefined' ? findInsuredDriver : mainDriver}
          onChange={(event) => {
            setMainDriver(event.target.value);
          }}
        >
          {nonRemovedDrivers.map((driver, idx) => (
            <FormControlLabel control={<Radio />} label={driverFullName(driver)} value={driver.uuid} key={idx} />
          ))}
        </RadioGroup>
        {!mainDriver && isSubmitting && <FormHelperText error>{t('validations.noDriver')}</FormHelperText>}
      </Grid>
      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_BACK_BUTTON_MAIN_DRIVER}
        cancelButtonId={COMPONENT_ID.RENEWAL_CANCEL_BUTTON_MAIN_DRIVER}
        primaryButton={{ buttonId: COMPONENT_ID.RENEWAL_CONTINUE_BUTTON_MAIN_DRIVER, handleContinue }}
      />
    </Grid>
  );
});
