import { Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { DATE_TYPES, EDIT_STEPS, JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { formatDate } from '../../../../../../../../service/util/formatDate';

import { ReviewAllDivider, ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

type DotNestedKeys<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

/* istanbul ignore next */
const getProperty = <T, K extends keyof T>(object: T, propertyPath: string): T[K] | undefined => {
  const nestedProperties = propertyPath.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let currentObject: any = object;

  for (const property of nestedProperties) {
    if (!currentObject || typeof currentObject !== 'object' || !(property in currentObject)) {
      return undefined;
    }
    if (property in currentObject) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      currentObject = currentObject[property];
    }
  }

  return currentObject as T[K] | undefined;
};

export const ReviewListUpdateDriver: React.FC<ReviewListType> = observer(function ReviewListUpdateDriver({ onEdit }) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    mtaStore: { isJourneyCompleted },
  } = useStores();
  const classes = useListStyles({ isDesktop });
  const {
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          mainDriver,
          policySelected: { drivers: driversBeforeChange },
        },
      },
    },
    mtaStore: {
      pendedMTA: { changedDrivers, drivers, driverFullName },
    },
  } = useStores();

  const updatedDriversArray = drivers.filter((driver) => {
    return changedDrivers.includes(driver.uuid);
  });

  const newMainDriver = drivers.find((driver) => driver.mainDriver === true);
  const hasNewMainDriver = mainDriver.uuid !== newMainDriver?.uuid;

  if (isJourneyCompleted(JOURNEY_TYPE.CHANGE_DRIVER))
    return (
      <>
        {updatedDriversArray.map((driver) => {
          const isValueChanged = (value: DotNestedKeys<typeof driver>) => {
            const driverBeforeChange = driversBeforeChange.find(
              (driverBeforeChange) => driverBeforeChange.uuid === driver.uuid,
            );
            if (driverBeforeChange) {
              return getProperty(driverBeforeChange, value) !== getProperty(driver, value);
            }
            return true;
          };

          return (
            <Fragment key={driver.uuid}>
              <BlueTableContainer title={driverFullName(driver)} className={classes.root}>
                <>
                  {(isValueChanged('person.titleCode') || isValueChanged('person.surname')) && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.driverName')}</Typography>
                          <Typography variant="h4">
                            {`${getLookup(LOOKUP.TITLE, driver.person.titleCode)} ${driverFullName(driver)}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_NAME} uuid={driver.uuid} />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('person.dateOfBirth') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.dob')}</Typography>
                          <Typography variant="h4">
                            {formatDate(driver.person.dateOfBirth, DATE_TYPES.WEEKDAY_SHORT)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DATE_OF_BIRTH}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('person.residentSinceBirth') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.resident')}</Typography>
                          <Typography variant="h4">
                            {driver.person.residentSinceBirth ? t('labels.yes') : t('labels.no')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DATE_OF_BIRTH}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('person.maritalStatusCode') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.maritalStatus')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.MARITAL_STATUS, driver.person.maritalStatusCode)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_MARITAL_STATUS}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('relationshipToProposerCode') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.relationship')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.DRIVER_RELATIONSHIP, driver.relationshipToProposerCode)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_RELATIONSHIP}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('person.employmentStatusCode') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.driverOccupation.employmentStatusLabel')}</Typography>
                          <Typography>
                            {getLookup(LOOKUP.EMPLOYMENT, driver.person.employmentStatusCode ?? '')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_OCCUPATION}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {(isValueChanged('person.primaryOccupationCode') ||
                    isValueChanged('person.primaryOccupationIndustryCode')) && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.primaryOccupation')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.OCCUPATION, driver.person.primaryOccupationCode ?? '')}
                            <br />
                            {getLookup(LOOKUP.INDUSTRY, driver.person.primaryOccupationIndustryCode ?? '')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_OCCUPATION}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {(isValueChanged('person.secondaryOccupationCode') ||
                    isValueChanged('person.secondaryOccupationIndustryCode')) && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.secondaryOccupation')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.OCCUPATION, driver.person.secondaryOccupationCode ?? '')}
                            <br />
                            {getLookup(LOOKUP.INDUSTRY, driver.person.secondaryOccupationIndustryCode ?? '')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_OCCUPATION}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {(isValueChanged('licence.licenceDate') ||
                    isValueChanged('licence.licenceTypeCode') ||
                    isValueChanged('licence.licenseRestrictionCode')) && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.licenceType')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.LICENCE_TYPE, driver.licence.licenceTypeCode)}
                            <br />
                            {formatDate(driver.licence.licenceDate, DATE_TYPES.WEEKDAY_SHORT)}
                            <br />
                            {getLookup(LOOKUP.LICENSE_RESTRICTIONS, driver.licence.licenseRestrictionCode)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_LICENCE_TYPE}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                      <ReviewAllDivider />
                    </>
                  )}
                  {isValueChanged('useOfOtherCarsCode') && (
                    <>
                      <Grid container className={classes.columnsContainer} justifyContent="space-between">
                        <Grid item>
                          <Typography>{t('MTAJourney.accessToOtherCars.description')}</Typography>
                          <Typography variant="h4">
                            {getLookup(LOOKUP.ACCESS_TO_OTHER_CARS, driver.useOfOtherCarsCode)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ReviewAllEditButton
                            onEdit={onEdit}
                            onEditType={EDIT_STEPS.DRIVER_OTHER_CARS}
                            uuid={driver.uuid}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              </BlueTableContainer>
              {isValueChanged('declinedOnSpecialTerms') && (
                <BlueTableContainer title={'Insurance history'} className={classes.root}>
                  <Grid container className={classes.columnsContainer} justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('driverHistory.section1')}</Typography>
                      <Typography variant="h4">
                        {driver.declinedOnSpecialTerms ? t('labels.yes') : t('labels.no')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                    </Grid>
                  </Grid>
                </BlueTableContainer>
              )}
              {isValueChanged('claimsFive') && (
                <BlueTableContainer title={'Claims'} className={classes.root}>
                  <Grid container className={classes.columnsContainer} justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('driverHistory.section3')}</Typography>
                      <Typography variant="h4">{driver.claimsFive ? t('labels.yes') : t('labels.no')}</Typography>
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                    </Grid>
                  </Grid>
                </BlueTableContainer>
              )}
              {(isValueChanged('unspentConvictions') || isValueChanged('prosecutionsOnOffences')) && (
                <BlueTableContainer title={'Convictions'} className={classes.root}>
                  <>
                    <Grid container className={classes.columnsContainer} justifyContent="space-between">
                      <Grid item>
                        <Typography>{t('driverHistory.section2')}</Typography>
                        <Typography variant="h4">
                          {driver.unspentConvictions ? t('labels.yes') : t('labels.no')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ReviewAllEditButton
                          onEdit={onEdit}
                          onEditType={EDIT_STEPS.DRIVER_HISTORY}
                          uuid={driver.uuid}
                        />
                      </Grid>
                    </Grid>
                    <ReviewAllDivider />
                    <Grid container className={classes.columnsContainer} justifyContent="space-between">
                      <Grid item>
                        <Typography>{t('driverHistory.section4')}</Typography>
                        <Typography variant="h4">
                          {driver.prosecutionsOnOffences ? t('labels.yes') : t('labels.no')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ReviewAllEditButton
                          onEdit={onEdit}
                          onEditType={EDIT_STEPS.DRIVER_HISTORY}
                          uuid={driver.uuid}
                        />
                      </Grid>
                    </Grid>
                  </>
                </BlueTableContainer>
              )}

              {hasNewMainDriver && (
                <BlueTableContainer
                  title={t('MTAJourney.ReviewChanges.reviewRemoveDriver.title')}
                  className={classes.root}
                >
                  <Grid container className={classes.columnsContainer} justifyContent="space-between">
                    <Grid item>
                      <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.mainDriver')}</Typography>
                      {newMainDriver && (
                        <Typography variant="h4">{`${getLookup(
                          LOOKUP.TITLE,
                          newMainDriver.person.titleCode,
                        )} ${driverFullName(newMainDriver)}`}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <ReviewAllEditButton
                        onEdit={onEdit}
                        onEditType={EDIT_STEPS.DRIVER_MAIN_CHANGED}
                        uuid={driver.uuid}
                      />
                    </Grid>
                  </Grid>
                </BlueTableContainer>
              )}
            </Fragment>
          );
        })}
      </>
    );

  return null;
});
