import { ButtonBase, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import React, { useCallback } from 'react';

import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import IconSure from '../icon';

export interface ButtonLinksListItem {
  label: string;
  segmentStep?: string;
  url: string;
}

interface ButtonLinksListProps {
  links: ButtonLinksListItem[];
}

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right',
  },
  fullWidth: {
    width: '100%',
  },
}));

export const ButtonLinksList: React.FC<ButtonLinksListProps> = ({ links }) => {
  const { eventTrack } = useSegment();
  const classes = useStyles();

  const handleClick = useCallback((item: ButtonLinksListItem) => {
    if (item.segmentStep) {
      eventTrack(item.segmentStep, {
        position: item.segmentStep,
        type: TRACK_TYPE.LINK,
      });
    }
    window.open(item.url, '_blank');
  }, []);

  return (
    <Grid container spacing={3} data-testid="buttonLinksListContainer">
      {links.map((linkItem, index) => {
        return (
          <Grid item xs={12} key={index}>
            <ButtonBase onClick={() => handleClick(linkItem)} className={classes.fullWidth}>
              <Grid alignItems="center" container>
                <Grid item xs={11}>
                  <Typography variant="body1" align="left" color="secondary">
                    {linkItem.label}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.alignRight}>
                  <IconSure color="secondary" icon="chevron-right" />
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );
};
