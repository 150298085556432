import { Grid, Link, List, ListItem, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../component/common/blueBanner';
import { useChatBotAttributes } from '../../../../../component/common/utils/useChatBotAttributes';
import { ROUTE } from '../../../../../service/constant';
import { useStores } from '../../../../../service/state/store';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .chat-link': {
      cursor: 'pointer',
    },
    '& .container': {
      marginTop: theme.spacing(3),
    },
    '& .list': {
      '&_item': {
        padding: '0 6px',
      },
      paddingTop: 0,
    },
    '& .list-container': {
      paddingTop: theme.spacing(1),
    },
  },
}));

export const HowToMakeAClaim: React.FC = observer(function HowToMakeAClaim() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount', {});
  const {
    interfaceStore: {
      chatBot: { startChat },
    },
  } = useStores();

  const makeAClaimSteps: string[] = t('homeClaims.howToMakeAClaim.steps', { returnObjects: true });
  const viewOpenClaimsSteps: string[] = t('homeClaims.viewYourClaims.steps', { returnObjects: true });
  const homeChatBotAttr = useChatBotAttributes('ssHomeClaim');

  const orderedList = (items: string[]) => {
    return (
      <List component="ol" className="list">
        {items.map((item, index) => (
          <ListItem key={index} className="list_item">
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${index + 1}. ${item}`,
                }}
              />
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <BlueBanner route={ROUTE.POLICY_DETAILS} title={t('breadcrumbs.claims')} />
      <Grid container direction="column" spacing={2} className="container">
        <Grid item xs={12}>
          <Typography className="title" variant="h2">
            {t('homeClaims.howToMakeAClaim.title')}
          </Typography>
          <Grid className="list-container">{orderedList(makeAClaimSteps)}</Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className="title" variant="h2">
            {t('homeClaims.viewYourClaims.title')}
          </Typography>
          <Grid className="list-container">
            <Typography>{t('homeClaims.viewYourClaims.subtitle')}</Typography>
            {orderedList(viewOpenClaimsSteps)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans i18nKey="homeClaims.chatLink" t={t}>
              <Link
                underline="none"
                variant="body1"
                color="secondary"
                className="chat-link"
                onClick={() => {
                  startChat({
                    initiator: 'Chat With Us Button',
                    ...(homeChatBotAttr ?? {}),
                  });
                }}
              />
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});
