import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { DIALOG_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .section-typo': {
      paddingBottom: spacing(1),
    },
    gap: 23,
  },
}));

export const DriverHistory: React.FC = observer(() => {
  const {
    nextStep,
    setTitle,
    editMode,
    setStep,
    stepsData: { reviewStep },
  } = useStepperContext();
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      dialog: { openDialog },
    },
    mtaStore: {
      pendedMTA: { selectedDriver, setSelectedDriver },
    },
  } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [driverState, setDriverState] = useState(selectedDriver);
  const { declinedOnSpecialTerms, unspentConvictions, prosecutionsOnOffences, claimsFive } = driverState;
  const classes = useStyles();

  useEffect(() => {
    setTitle(t('driverHistory.title'));
  }, []);

  const isValid = () => {
    return (
      declinedOnSpecialTerms !== null &&
      unspentConvictions !== null &&
      prosecutionsOnOffences !== null &&
      claimsFive !== null
    );
  };

  /* istanbul ignore next */
  const handlePendedMta = () => {
    setSelectedDriver({
      ...selectedDriver,
      claimsFive,
      declinedOnSpecialTerms,
      prosecutionsOnOffences,
      unspentConvictions,
    });
  };

  /* istanbul ignore next */
  const isValidEditMode = () => {
    return !prosecutionsOnOffences && !claimsFive;
  };

  /* istanbul ignore next */
  const handleContinue = () => {
    setIsSubmitting(true);

    // I want to update the pended mta values
    // before the user opens the chat or goes on the next step
    handlePendedMta();

    if (claimsFive || prosecutionsOnOffences) {
      openDialog({
        title: t('fetchAdviserPopup.details.title'),
        type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
      });
    } else {
      if (editMode) {
        isValidEditMode() && setStep(reviewStep);
      } else {
        isValid() && nextStep();
      }
    }
  };

  return (
    <>
      <Grid container className={classes.root} direction="column">
        <Grid item>
          <MtaTooltip tooltipTitle={t('tooltips.deniedInsurance')} description={t('driverHistory.section1')} />
          <Choice
            identifier="driverHistorySection1"
            onChange={(event) =>
              setDriverState({ ...driverState, declinedOnSpecialTerms: event.target.value === 'true' })
            }
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={declinedOnSpecialTerms}
            error={isSubmitting && declinedOnSpecialTerms === null}
          />
        </Grid>
        <Grid item>
          <MtaTooltip tooltipTitle={t('tooltips.convictions')} description={t('driverHistory.section2')} />
          <Choice
            identifier="driverHistorySection2"
            onChange={(event) => setDriverState({ ...driverState, unspentConvictions: event.target.value === 'true' })}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={unspentConvictions}
            error={isSubmitting && unspentConvictions === null}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" className="section-typo">
            {t('driverHistory.section3')}
          </Typography>
          <Choice
            identifier="driverHistorySection3"
            onChange={(event) => {
              setDriverState({ ...driverState, claimsFive: event.target.value === 'true' });
            }}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={claimsFive}
            error={isSubmitting && claimsFive === null}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1" className="section-typo">
            {t('driverHistory.section4')}
          </Typography>
          <Choice
            identifier="driverHistorySection4"
            onChange={(event) => {
              setDriverState({ ...driverState, prosecutionsOnOffences: event.target.value === 'true' });
            }}
            left={{ label: t('labels.no') }}
            right={{ label: t('labels.yes') }}
            radioValue={prosecutionsOnOffences}
            error={isSubmitting && prosecutionsOnOffences === null}
          />
        </Grid>
      </Grid>
      {editMode ? (
        <EditFooter
          id="driverHistory"
          validPage={isValidEditMode()}
          handleSave={handleContinue}
          handleBack={() => setStep(reviewStep)}
        />
      ) : (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_DRIVER_HISTORY}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_DRIVER_HISTORY}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_DRIVER_HISTORY, handleContinue }}
        />
      )}
    </>
  );
});
