import { STORAGE_SESSION_HASH_NAME } from '../analytics';
import config, { url } from '../config';

/**
 * Clear out any custom app storage, this should just cover things manually put in storage, not any storage used by Mobx.
 * Resetting of the state stores is handled by the clearStore action in each store
 */
const clearAppStorage = () => {
  localStorage.removeItem(STORAGE_SESSION_HASH_NAME);
};

export const manageLogout = (): void => {
  clearAppStorage();

  if (config.brand === 'sw') {
    // this fixes a visual glitch while transitioning by hiding the body
    document.getElementsByTagName('body')[0].style.display = 'none';
    window.location.href = url.logoutRedirectUrlSW;
  }
};
