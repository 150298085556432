import { Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { DATE_TYPES, EDIT_STEPS, LOOKUP } from '../../../../../../../../service/constant';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { formatDate } from '../../../../../../../../service/util/formatDate';

import { ReviewAllDivider, ReviewAllEditButton, ReviewListType, useListStyles } from './reviewAll';

export const ReviewListAddDriver: React.FC<ReviewListType> = observer(function ReviewListAddDriver({ onEdit }) {
  const { t } = useTranslation('myAccount');

  const {
    interfaceStore: { isDesktop },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: { mainDriver },
      },
    },
    mtaStore: {
      pendedMTA: { drivers, driverFullName },
    },
  } = useStores();
  const classes = useListStyles({ isDesktop });

  const newDriverArray = drivers.filter((driver) => driver.uuid.includes('emptyDriver'));
  const newMainDriver = drivers.find((driver) => driver.mainDriver === true);
  const hasNewMainDriver = mainDriver.uuid !== newMainDriver?.uuid;

  const hasDriverSecondaryOccupation = (driver: IDriver) => {
    if (driver.person.secondaryOccupationCode || driver.person.secondaryOccupationIndustryCode) {
      return !!(
        getLookup(LOOKUP.OCCUPATION, driver.person.secondaryOccupationCode ?? '') ||
        getLookup(LOOKUP.INDUSTRY, driver.person.secondaryOccupationIndustryCode ?? '')
      );
    }
    return false;
  };

  return (
    <>
      {newDriverArray.map((driver) => {
        const hasSecondaryOccupation = hasDriverSecondaryOccupation(driver);

        return (
          <Fragment key={driver.uuid}>
            <BlueTableContainer title={t('MTAJourney.reviewAll.addDriverDetails')} className={classes.root}>
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.driverName')}</Typography>
                    <Typography variant="h4">
                      {`${getLookup(LOOKUP.TITLE, driver.person.titleCode)} ${driverFullName(driver)}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_NAME} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.dob')}</Typography>
                    <Typography variant="h4">
                      {formatDate(driver.person.dateOfBirth, DATE_TYPES.WEEKDAY_SHORT)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DATE_OF_BIRTH} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.resident')}</Typography>
                    <Typography variant="h4">
                      {driver.person.residentSinceBirth ? t('labels.yes') : t('labels.no')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DATE_OF_BIRTH} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.maritalStatus')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.MARITAL_STATUS, driver.person.maritalStatusCode)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton
                      onEdit={onEdit}
                      onEditType={EDIT_STEPS.DRIVER_MARITAL_STATUS}
                      uuid={driver.uuid}
                    />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.relationship')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.DRIVER_RELATIONSHIP, driver.relationshipToProposerCode)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton
                      onEdit={onEdit}
                      onEditType={EDIT_STEPS.DRIVER_RELATIONSHIP}
                      uuid={driver.uuid}
                    />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.driverOccupation.employmentStatusLabel')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.EMPLOYMENT, driver.person.employmentStatusCode ?? '')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_OCCUPATION} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.primaryOccupation')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.OCCUPATION, driver.person.primaryOccupationCode ?? '')}
                      <br />
                      {getLookup(LOOKUP.INDUSTRY, driver.person.primaryOccupationIndustryCode ?? '')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_OCCUPATION} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.secondaryOccupation')}</Typography>
                    <Typography variant="h4">
                      {hasSecondaryOccupation ? (
                        <>
                          {getLookup(LOOKUP.OCCUPATION, driver.person.secondaryOccupationCode ?? '')}
                          <br />
                          {getLookup(LOOKUP.INDUSTRY, driver.person.secondaryOccupationIndustryCode ?? '')}
                        </>
                      ) : (
                        t('labels.no')
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_OCCUPATION} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.licenceType')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.LICENCE_TYPE, driver.licence.licenceTypeCode)}{' '}
                      {formatDate(driver.licence.licenceDate, DATE_TYPES.WEEKDAY_SHORT)}
                    </Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.LICENSE_RESTRICTIONS, driver.licence.licenseRestrictionCode)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton
                      onEdit={onEdit}
                      onEditType={EDIT_STEPS.DRIVER_LICENCE_TYPE}
                      uuid={driver.uuid}
                    />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.accessToOtherCars.description')}</Typography>
                    <Typography variant="h4">
                      {getLookup(LOOKUP.ACCESS_TO_OTHER_CARS, driver.useOfOtherCarsCode)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_OTHER_CARS} uuid={driver.uuid} />
                  </Grid>
                </Grid>
              </>
            </BlueTableContainer>
            <BlueTableContainer title={t('MTAJourney.reviewAll.InsuranceHistory')} className={classes.root}>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('driverHistory.section1')}</Typography>
                  <Typography variant="h4">
                    {driver.declinedOnSpecialTerms ? t('labels.yes') : t('labels.no')}
                  </Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                </Grid>
              </Grid>
            </BlueTableContainer>
            <BlueTableContainer title={t('MTAJourney.reviewAll.claims')} className={classes.root}>
              <Grid container className={classes.columnsContainer} justifyContent="space-between">
                <Grid item>
                  <Typography>{t('driverHistory.section3')}</Typography>
                  <Typography variant="h4">{driver.claimsFive ? t('labels.yes') : t('labels.no')}</Typography>
                </Grid>
                <Grid item>
                  <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                </Grid>
              </Grid>
            </BlueTableContainer>
            <BlueTableContainer title={t('MTAJourney.reviewAll.convictions')} className={classes.root}>
              <>
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('driverHistory.section2')}</Typography>
                    <Typography variant="h4">{driver.unspentConvictions ? t('labels.yes') : t('labels.no')}</Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                  </Grid>
                </Grid>
                <ReviewAllDivider />
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('driverHistory.section4')}</Typography>
                    <Typography variant="h4">
                      {driver.prosecutionsOnOffences ? t('labels.yes') : t('labels.no')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton onEdit={onEdit} onEditType={EDIT_STEPS.DRIVER_HISTORY} uuid={driver.uuid} />
                  </Grid>
                </Grid>
              </>
            </BlueTableContainer>
            {hasNewMainDriver && (
              <BlueTableContainer
                title={t('MTAJourney.ReviewChanges.reviewRemoveDriver.title')}
                className={classes.root}
              >
                <Grid container className={classes.columnsContainer} justifyContent="space-between">
                  <Grid item>
                    <Typography>{t('MTAJourney.ReviewChanges.reviewAddDriver.mainDriver')}</Typography>
                    {newMainDriver && (
                      <Typography variant="h4">{`${getLookup(
                        LOOKUP.TITLE,

                        newMainDriver.person.titleCode,
                      )} ${driverFullName(newMainDriver)}`}</Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <ReviewAllEditButton
                      onEdit={onEdit}
                      onEditType={EDIT_STEPS.DRIVER_MAIN_CHANGED}
                      uuid={driver.uuid}
                    />
                  </Grid>
                </Grid>
              </BlueTableContainer>
            )}
          </Fragment>
        );
      })}
    </>
  );
});
