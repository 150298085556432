import { Divider, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../component/common/icon';
import { useStores } from '../../../../../../service/state/store';

const useStyles = makeStyles(({ spacing }) => ({
  boldItem: {
    fontWeight: 700,
  },
  divider: {
    paddingTop: spacing(2),
  },
  footerIcon: {
    display: 'flex',
    marginTop: '10px',
  },
  footerText: {
    margin: '4px',
  },
  paddingTop: {
    paddingTop: spacing(1),
  },
  root: {
    minWidth: '100%',
    padding: spacing(0, 2),
  },
}));

interface OtherExcessItem {
  label?: string;
  value?: string;
}

export const YourExcesses: React.FC<{ onEdit?: () => void; showEditButton?: boolean }> = observer(
  function YourExcesses({ onEdit, showEditButton = true }) {
    const classes = useStyles();
    const { t } = useTranslation('myAccount');
    const {
      dashboardStore: {
        insurance: {
          auto: { mainVehicle },
        },
      },
      renewalChangeStore: {
        renewalDetails: { drivers, vehicle },
      },
    } = useStores();

    const listOfDrivers = drivers.map((driver) => driver.person.fullName()).join(', ');
    const listOfDriversWithNoSpecificExcess = drivers
      .filter((driver) => driver.driverSpecificExcess === null || driver.driverSpecificExcess <= 0)
      .map((driver) => driver.person.fullName())
      .join(', ');
    const hasDriversWithSpecificExcess = drivers.some(
      (driver) => driver.driverSpecificExcess && driver.driverSpecificExcess > 0,
    );

    const getCoverageByCode = useCallback(
      (coverageCd: string) => vehicle.coverages.find((c) => c.coverageCd === coverageCd),
      [vehicle.coverages],
    );

    const getPayAmount = (coverageCode: string) => {
      const coverage = getCoverageByCode(coverageCode);
      const value = coverage?.deductibleAmount ?? 0;
      return value;
    };

    const voluntaryAmount = vehicle.voluntaryExcessCd ? Number.parseInt(vehicle.voluntaryExcessCd) : 0;
    const compulsoryAmount = getPayAmount('ad');
    const ownRepairerExcess = vehicle.ownRepairerExcess ?? 0;
    const windscreenReplacement = mainVehicle.subCoverages.filter(
      (item) => item.coverageCd === 'windscreen_windscreenRepair',
    );
    const totalAccidental = voluntaryAmount + compulsoryAmount;

    const allOtherExcessess: OtherExcessItem[] = [
      {
        label: t('policyManagement.yourCoverDetails.yourExcesses.otherExcesses.fire'),
        value: t('core:price.poundNoDecimal', { value: getPayAmount('fire') }),
      },
      {
        label: t('policyManagement.yourCoverDetails.yourExcesses.otherExcesses.theft'),
        value: t('core:price.poundNoDecimal', { value: getPayAmount('theft') }),
      },
      {
        label: t('policyManagement.yourCoverDetails.yourExcesses.otherExcesses.windscreenWindscreenRepair'),
        value: t('core:price.poundNoDecimal', { value: windscreenReplacement[0].deductibleAmount ?? 0 }),
      },
      {
        label: t('policyManagement.yourCoverDetails.yourExcesses.otherExcesses.windscreenWindscreenReplacement'),
        value: t('core:price.poundNoDecimal', { value: getPayAmount('windscreen') }),
      },
      {
        label: t('policyManagement.yourCoverDetails.yourExcesses.otherExcesses.ownRepairExcesses'),
        value: t('core:price.poundNoDecimal', { value: ownRepairerExcess }),
      },
    ];

    return (
      <Grid className={clsx(classes.root)} container>
        {/* only show when multiple drivers */}
        {drivers.length > 1 && (
          <Grid container>
            <Grid container className="drivers-container" direction="column">
              <Typography paragraph variant="body1">
                {listOfDrivers}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container direction="column">
          <Typography paragraph variant="h4">
            {t('policyManagement.yourCoverDetails.yourExcesses.accidentalDamageExcesses')}
          </Typography>
          {/* hide when no drivers without specific excess */}
          {listOfDriversWithNoSpecificExcess && (
            <>
              {hasDriversWithSpecificExcess && (
                <Typography paragraph variant="h4">
                  {listOfDriversWithNoSpecificExcess}:
                </Typography>
              )}
              <Grid container justifyContent="space-between">
                <Typography variant="body2">
                  {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.voluntary')}
                </Typography>
                <Typography variant="body2" className={classes.boldItem}>
                  {t('core:price.poundNoDecimal', { value: voluntaryAmount })}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography variant="body2">
                  {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.compulsory')}
                </Typography>
                <Typography variant="body2" className={classes.boldItem}>
                  {t('core:price.poundNoDecimal', { value: compulsoryAmount })}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography variant="body2" className={classes.boldItem}>
                  {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.totalExcess')}
                </Typography>
                <Typography paragraph variant="body2" className={classes.boldItem}>
                  {t('core:price.poundNoDecimal', { value: totalAccidental })}
                </Typography>
              </Grid>
            </>
          )}

          {drivers.map((driver) => {
            return driver.driverSpecificExcess && driver.driverSpecificExcess > 0 ? (
              <Grid container direction="column" className={classes.divider} key={driver.uuid}>
                {/* only show name if there are multiple drivers */}
                {drivers.length > 1 && (
                  <Typography paragraph variant="h4">
                    {driver.person.firstName} {driver.person.surname}:
                  </Typography>
                )}
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">
                    {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.voluntary')}
                  </Typography>
                  <Typography variant="body2" className={classes.boldItem}>
                    {t('core:price.poundNoDecimal', { value: voluntaryAmount })}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">
                    {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.compulsory')}
                  </Typography>
                  <Typography variant="body2" className={classes.boldItem}>
                    {t('core:price.poundNoDecimal', { value: compulsoryAmount })}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">
                    {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.driverSpecific')}
                  </Typography>
                  <Typography variant="body2" className={classes.boldItem}>
                    {t('core:price.poundNoDecimal', { value: driver.driverSpecificExcess })}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography variant="body2" className={classes.boldItem}>
                    {t('policyManagement.yourCoverDetails.yourExcesses.sameExcess.totalExcess')}
                  </Typography>
                  <Typography variant="body2" className={classes.boldItem}>
                    {t('core:price.poundNoDecimal', {
                      value: driver.driverSpecificExcess + totalAccidental,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            );
          })}

          <Divider orientation="horizontal" />
          <Grid container direction="column" className={classes.divider}>
            <Typography paragraph variant="h4">
              {t('MTAJourney.QuoteReady.otherExcessesTitle')}
            </Typography>
            <Grid container>
              {allOtherExcessess.map(({ value, label }, idx) => {
                return (
                  <Grid container justifyContent="space-between" key={`allOtherExcessess_${idx}`}>
                    <Typography variant="body1">{label}</Typography>
                    <Typography variant="body2" className={classes.boldItem}>
                      {value}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Typography variant="body2" className={classes.paddingTop}>
              {t('MTAJourney.QuoteReady.otherExcessesDescription')}
            </Typography>
          </Grid>
        </Grid>
        {showEditButton ? (
          <Grid container direction="row">
            <Link underline="none" target="_blank" onClick={onEdit} className={classes.footerIcon}>
              <IconSure color="secondary" icon="pencil" type="solid" />
              <Typography className={classes.footerText} variant="h4">
                {t('policyManagement.yourCoverDetails.yourExcesses.iconText')}
              </Typography>
            </Link>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    );
  },
);
