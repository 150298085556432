import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationPaymentBlock } from '../../../../../../component/common/confirmationPaymentBlock';
import { ROUTE } from '../../../../../../service/constant';
import {
  IAutoPolicyRenewalQuoteDetails,
  IRenewalPendingMTAQuote,
} from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicyRenewalQuoteDetails } from '../../../../../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../../../../../service/state/store';

export const RenewalPaymentSuccess: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
      fetchPolicy,
    },
    renewalChangeStore: { pendingMTAQuoteSelected },
    paymentStore: { updatePaymentComplete },
  } = useStores();

  const policySelected = isHome ? home.policySelected : auto.policySelected;

  let renewalDetails: IAutoPolicyRenewalQuoteDetails | IHomePolicyRenewalQuoteDetails | IRenewalPendingMTAQuote;

  if (isHome) {
    renewalDetails = home.policySelected.policyRenewalQuoteDetails;
  } else if (pendingMTAQuoteSelected && auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote) {
    renewalDetails = auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote;
  } else {
    renewalDetails = auto.policySelected.policyRenewalQuoteDetails;
  }

  // Presuming that this uses useRef because the call to fetchPolicy will update the state and wipes the value of currentYearTotalCost?
  const totalCost = useRef(renewalDetails.currentYearTotalCost);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPolicy(policySelected.policyNumber, isHome).then(() => setIsLoading(false));
    updatePaymentComplete(false);
  }, []);

  const emailConfirmation = pendingMTAQuoteSelected
    ? t('policyManagement.motorRenewalSetup.setupSuccess.emailConfirmationChanges')
    : t('policyManagement.motorRenewalSetup.setupSuccess.emailConfirmation');

  return (
    <ConfirmationPaymentBlock
      loading={isLoading}
      button={t('policyManagement.yourRenewalPayment.paymentSuccess.button')}
      amount={totalCost.current}
      subtitle={t('policyManagement.yourRenewalPayment.paymentSuccess.subtitle')}
      thankYouText={
        isHome
          ? t('policyManagement.yourRenewalPayment.paymentSuccess.thankYouTextHome')
          : t('policyManagement.yourRenewalPayment.paymentSuccess.thankYouText')
      }
      emailConfirmation={emailConfirmation}
      url={ROUTE.DOCUMENTS}
      hasMultiplePoliciesBanner={false}
    />
  );
};
