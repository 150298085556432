import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Link,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { useState } from 'react';

import { url } from '../../service/config';
import { CommonButton } from '../common/button/commonButton';
import { Logo } from '../common/logo';

import { CookiePurpose } from './CookiePurpose';
import { CookieConsentCategory, CookiesOptions, categories } from './cookieContants';

interface Props {
  handleAcceptAll: () => void;
  handleManage: (selected: CookiesOptions) => void;
  handleRejectAll: () => void;
  openCookieModal: boolean;
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  buttons: {
    '& button': {
      height: 'auto',
      margin: spacing(1),
    },
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dialogContent: {
    padding: 0,
  },
  manage: {
    '& svg': {
      margin: spacing(1),
    },
    padding: 0,
    zIndex: 1310,
  },
  manageHeader: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
  },
  manageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
  },
  root: {},
  select: {
    '& p': {
      margin: spacing(1),
    },

    padding: spacing(4),

    [breakpoints.down('xs')]: {
      paddingInline: spacing(0),
    },
  },
}));

export const CookieBanner = ({ openCookieModal, handleAcceptAll, handleRejectAll, handleManage }: Props) => {
  const [manageCookie, setManageCookie] = useState(false);
  const classes = useStyles();

  const [consent, setConsent] = useState<Record<CookieConsentCategory, boolean>>({
    ANALYTICS: false,
    MARKETING: false,
    NECESSARY: true,
  });

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsent({ ...consent, [event.target.name]: event.target.checked });
  };

  if (manageCookie) {
    return (
      <Modal
        open
        disableScrollLock
        disableEnforceFocus
        aria-labelledby="modal-cookie-title"
        aria-describedby="modal-cookie-description"
        className={classes.root}
      >
        <Dialog open data-testid="cookie_management_manage" className={classes.manage}>
          <Paper className={classes.manageWrapper}>
            <Typography className={classes.manageHeader} variant="h2">
              <Grid container>
                <Grid xs={12} item sm={3} container justifyContent="space-evenly" alignItems="flex-start">
                  <Logo color="secondary" />
                </Grid>
                <Grid container item direction="column" xs={12} sm={9}>
                  <Grid>Customise Cookie Settings</Grid>
                  <Grid>
                    <Link href={`https://www.${url.domain}/cookies-policy`} rel="noreferrer" target="_blank">
                      Cookie Notice
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
            <DialogContent className={classes.dialogContent} id="modal-cookie-description">
              <Grid container justifyContent="center" direction="column">
                <Divider />
                <CookiePurpose
                  {...categories.NECESSARY}
                  onChange={handleUserChange}
                  checked={consent.NECESSARY}
                  name="NECESSARY"
                  id="cookie-purpose-necessary"
                />
                <Divider />
                <CookiePurpose
                  {...categories.ANALYTICS}
                  onChange={handleUserChange}
                  checked={consent.ANALYTICS}
                  name="ANALYTICS"
                  id="cookie-purpose-analytics"
                />
                <Divider />
                <CookiePurpose
                  {...categories.MARKETING}
                  onChange={handleUserChange}
                  checked={consent.MARKETING}
                  name="MARKETING"
                  id="cookie-purpose-marketing"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setManageCookie((prev) => !prev)}
                variant="outlined"
                fullWidth
                color="inherit"
                data-testid="manage-cookie-cancel"
              >
                Cancel
              </Button>
              <CommonButton
                onClick={() => {
                  handleManage({ Marketing: consent.MARKETING, Performance_and_Analytics: consent.ANALYTICS });
                  setManageCookie((prev) => !prev);
                }}
                variant="contained"
                fullWidth
                data-testid="manage-cookie-save"
                text="Save"
              />
            </DialogActions>
          </Paper>
        </Dialog>
      </Modal>
    );
  }

  return (
    <Modal
      open={openCookieModal}
      disableScrollLock
      disableEnforceFocus
      aria-labelledby="cookie-title"
      aria-describedby="cookie-description"
      className={classes.root}
    >
      <Dialog
        open={!manageCookie}
        data-testid="cookie_management"
        /* sx={{ zIndex: 1310 }}*/
      >
        <Box className={classes.select}>
          <Typography variant="h2" align="center">
            Cookies
          </Typography>
          <DialogContent id="cookie-description">
            <Grid container direction="column">
              <Typography align="center" id="modal-cookie-description">
                We value your privacy
              </Typography>
              <Typography align="center" id="modal-cookie-description">
                For {url.domain} we use essential cookies to enable basic features on our website. We'd also like to use
                non-essential cookies to provide certain functions by our website that include (i) performance and
                analytics cookies (to understand how you use our website and to help deliver a better site performance)
                and (ii) marketing cookies (to provide customised adverts to you). However, these will only be set if
                you accept them. For more details, please visit the{' '}
                <Box component="span" /*sx={{ textWrap: 'nowrap' }}*/>
                  <Link href={`https://www.${url.domain}/cookies-policy`} rel="noreferrer" target="_blank">
                    {url.domain} Cookie Notice.
                  </Link>
                </Box>
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box width="100%" alignSelf="center" alignItems="center" alignContent="center" textAlign="center" px={5}>
              <Grid className={classes.buttons} container direction="row" alignItems="center">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAcceptAll}
                  data-testid="accept-all-cookies"
                >
                  Accept all cookies
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleRejectAll}
                  data-testid="reject-all-cookies"
                >
                  Reject all non-essential cookies
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setManageCookie((prev) => !prev)}
                  data-testid="manage-cookies"
                >
                  Manage cookie settings
                </Button>
              </Grid>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Modal>
  );
};
