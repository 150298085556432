import {
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { IPerson } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';
import { DriverEditPageProps } from '../../drivers/steps/editDriver';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .first-name': {
      marginBottom: spacing(2),
      marginTop: spacing(3),
    },
    '& .radio-container-gender': {
      '&__label': {
        minWidth: 'fit-content',
        width: '100%',
      },
      '&__typo': {
        marginBottom: spacing(1),
      },
    },
    maxWidth: 352,
  },
}));

interface PersonNameProps extends DriverEditPageProps {
  chatWithUs?: React.ReactNode;
  disableFirstName: boolean;
  person: IPerson;
  title: string;
}

export const PersonName: React.FC<PersonNameProps> = observer(function PersonName({
  handleBackDriverEdit,
  handleSaveDriverEdit,
  person,
  title,
  disableFirstName,
  chatWithUs,
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { selectedDriver, setSelectedDriver, setInsuredPerson, insured },
    },
  } = useStores();

  const [titleList, setTitleList] = useState<ILookupItem[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [personState, setPersonState] = useState(person);

  const { firstName, surname, titleCode, genderCode } = personState;

  const classes = useStyles();
  const styleContainedSecondary = 'contained invert secondary';
  const { t } = useTranslation('myAccount');

  const {
    nextStep,
    setTitle,
    setStep,
    stepsData: { reviewStep, journeyType },
    editMode,
  } = useStepperContext();

  const isChangeDriverDetailsJourney = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

  const isDriverCluster = journeyType === JOURNEY_TYPE.CHANGE_DRIVER || journeyType === JOURNEY_TYPE.ADD_DRIVER;

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.TITLE],
  });

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.TITLE] !== undefined) {
      setTitleList(lookupsByName[LOOKUP.TITLE]);
    }
  }, [isLookupsFetched, lookupsByName]);

  const isNotValid = (): boolean => {
    return firstName === '' || surname === '' || titleCode === '' || (titleCode === '001' && genderCode === null);
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);

    if (!isNotValid()) {
      if (isDriverCluster) {
        setSelectedDriver({
          ...selectedDriver,
          person: { ...selectedDriver.person, firstName, genderCode, surname, titleCode },
        });
      } else {
        setInsuredPerson({ ...insured.person, firstName, genderCode, surname, titleCode });
      }
    }
  };

  const handleSave = () => {
    handlePendedMta();
    if (!isNotValid() && !isChangeDriverDetailsJourney) {
      setStep(reviewStep);
    } else {
      !isNotValid() && handleSaveDriverEdit?.();
    }
  };
  const handleContinue = () => {
    handlePendedMta();
    !isNotValid() && nextStep();
  };

  const handleBack = () => {
    isChangeDriverDetailsJourney ? handleBackDriverEdit?.() : setStep(reviewStep);
  };

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Typography>{t('MTAJourney.driverName.titleLabel')}</Typography>
        <Select
          variant="outlined"
          value={titleList ? titleCode : ''}
          error={isSubmitting && !titleCode}
          onChange={(event) => {
            setPersonState({
              ...personState,
              titleCode: event.target.value as string,
            });
          }}
        >
          {titleList?.map((item, index) => {
            return (
              <MenuItem key={`${item.code}-${index}`} value={item.code}>
                {item.value}
              </MenuItem>
            );
          })}
        </Select>
        {isSubmitting && titleCode === '' && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}

        {titleCode === '001' && (
          <Grid container className={classes.root}>
            <Typography className="radio-container-gender__typo">
              {t('MTAJourney.driverName.genderSelection')}
            </Typography>
            <Grid item xs={12}>
              <RadioGroup
                onChange={(event) => setPersonState({ ...personState, genderCode: event.target.value })}
                value={genderCode}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={clsx('radio-container-gender__label', styleContainedSecondary, {
                        checked: genderCode === 'M',
                      })}
                      control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-male' }} />}
                      label={t('MTAJourney.driverName.genderMale')}
                      labelPlacement="end"
                      value="M"
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={clsx('radio-container-gender__label', styleContainedSecondary, {
                        checked: genderCode === 'F',
                      })}
                      control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-female' }} />}
                      label={t('MTAJourney.driverName.genderFemale')}
                      labelPlacement="end"
                      value="F"
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>

              {isSubmitting && genderCode === null && (
                <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )}

        <TextField
          className="first-name"
          value={firstName}
          label={t('MTAJourney.driverName.firstName')}
          helperText={isSubmitting && firstName === '' && t('validations.missingFirstName')}
          error={isSubmitting && firstName === ''}
          onChange={(event) => setPersonState({ ...personState, firstName: event.target.value })}
          disabled={disableFirstName}
        />
        <TextField
          label={t('MTAJourney.driverName.lastName')}
          helperText={isSubmitting && surname === '' && t('validations.missingLastName')}
          error={isSubmitting && surname === ''}
          value={surname}
          onChange={(event) => setPersonState({ ...personState, surname: event.target.value })}
        />

        {chatWithUs}

        {editMode ? (
          <EditFooter id="personName" validPage={!isNotValid()} handleSave={handleSave} handleBack={handleBack} />
        ) : (
          <MTAFooter
            backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_CHANGE_NAME}
            cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_CHANGE_NAME}
            primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_CHANGE_NAME, handleContinue }}
          />
        )}
      </Grid>
    </>
  );
});
