/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Grid, Link, Typography, alpha, darken, makeStyles, themeEsure } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Footer } from '../../../component/common';
import { CommonButton } from '../../../component/common/button/commonButton';
import { EsureLayoutNavbar } from '../../../component/common/esureLayoutNavbar';
import IconSure from '../../../component/common/icon';
import { Logo } from '../../../component/common/logo';
import { SVGWrapper } from '../../../images/SVGWrapper';
import { COMPONENT_ID } from '../../../service/analytics';
import { LINK_TYPE, ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { replaceLinksDomain } from '../../../service/util/stringUtils';

const useAnimationStyles = makeStyles(({ breakpoints }) => ({
  '@keyframes turnWheel': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(10deg)',
    },
    '75%': {
      transform: 'rotate(-10deg)',
    },
  },
  bottom: { left: '12%', position: 'absolute', width: '60%' },
  container: {
    aspectRatio: '1 / 1',
    display: 'inline-block',
    height: '100%',
    maxWidth: 390,
    position: 'relative',
    width: '100%',
    [breakpoints.down('xs')]: {
      maxWidth: 220,
    },
  },
  middle: {
    height: '15%',
    left: '20%',
    paddingTop: '10%',
    position: 'absolute',
    width: '15%',
  },
  rotator: {
    animation: '$turnWheel 10s ease-in-out',
    animationDirection: 'forward',
    animationIterationCount: 'infinite',
    transformOrigin: 'center center',
  },
  top: { left: '0px', position: 'absolute', top: '0px', width: '85%' },
}));

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    '& .header': {
      '&__hero': {
        '&-car': {
          '& img': {
            width: '100%',
          },
          [breakpoints.down('xs')]: {
            '& img': {
              marginTop: 20,
            },
            justifyContent: 'flex-end',
          },
        },
        '&-logo': {
          padding: spacing(2, 0, 5),
        },
        '&-scroll': {
          marginTop: spacing(4),
          width: 'fit-content',
        },
        '&-trustpilot': {
          maxWidth: 415,
        },
        [breakpoints.down('xs')]: {
          '&-logo': {
            padding: spacing(2, 0, 2),
          },
          '&-trustpilot, &-scroll': {
            display: 'none',
          },
        },
      },
      background: 'url("assets/images/home-hero-mask.png") no-repeat bottom center / cover',
      backgroundColor: alpha(palette.secondary.main, 0.08),
      minHeight: 500,
      paddingTop: 130,
      position: 'relative',
      [breakpoints.down('xs')]: {
        backgroundSize: 'inherit',
      },
    },
    '& .wrapper': {
      margin: '0 auto',
      maxWidth: 1200,
      padding: spacing(0, 2),
    },
    '& a': {
      color: palette.secondary.main,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    '& section': {
      '&.cover': {
        '& .cover__list': {
          '&-label': {
            background: alpha(palette.success.main, 0.1),
            borderRadius: 5,
            color: darken(palette.success.main, 0.5),
            padding: spacing(0.3, 1),
            width: 'fit-content',
          },
          display: 'grid',
          gap: 25,
          gridTemplateColumns: 'repeat(2, 1fr)',
          [breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
          },
        },
        '& .cover__title': {
          color: palette.secondary.dark,
          padding: spacing(0, 3),
          [breakpoints.down('xs')]: {
            marginBottom: spacing(4),
            textAlign: 'center',
          },
        },
      },
      '&.cta': {
        gap: 10,
        margin: spacing(8, 0),
      },
      '&.facts': {
        '& img': {
          marginBottom: 20,
          width: '100%',
        },
        color: palette.secondary.dark,
        display: 'grid',
        gap: 50,
        gridTemplateColumns: 'repeat(3, 1fr)',
        marginTop: spacing(6),
        [breakpoints.down('xs')]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
          marginTop: spacing(2),
        },
      },
      '&.quote': {
        '& h2': {
          marginTop: spacing(1),
        },
        marginTop: spacing(9),
        textAlign: 'center',
      },
    },
  },
}));

export interface ItemList {
  img: string;
  link: string;
  title: string;
  type: LINK_TYPE;
}

export interface NavList {
  icon: string;
  onClick?: () => void;
  title: string;
}

export const Landing: React.FC = observer(function Landing() {
  const classes = useStyles();
  const animation = useAnimationStyles();
  const { t } = useTranslation('common');
  const {
    interfaceStore: { brand },
  } = useStores();

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLogin = () => {
    setLoading(!loading);
    history.push(ROUTE.LOGIN);
  };

  // if the brand is themeEsure then add flex to the logo
  const isFlex = brand === themeEsure.themeName ? true : false;

  const coverList: string[] | string = t('landing.cover.list', { returnObjects: true });
  const factList: ItemList[] | string = t('landing.facts', { returnObjects: true });
  const quoteList: ItemList[] | string = t('landing.quote.links', { returnObjects: true });

  return (
    <Grid className={classes.root}>
      <EsureLayoutNavbar hasNeedHelp={true} handleLogin={handleLogin} position={'Landing'} />

      <header className="header">
        <Grid container alignItems="center" justifyContent="space-between" className="header__hero wrapper">
          <Grid item container direction="column" xs>
            <Typography variant="h2">{t('landing.hero.title')}</Typography>

            <Logo flex={isFlex} size="185" color="secondary" className="header__hero-logo" />

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              className="header__hero-scroll"
            >
              <Typography variant="body2" color="secondary">
                <strong>{t('landing.hero.scroll')}</strong>
              </Typography>
              <IconSure icon="arrow-down" color="secondary" size="30" strokeWidth="1.7" />
            </Grid>
          </Grid>

          <Grid item container alignItems="flex-start" className="header__hero-car" xs={12} sm={5}>
            <div className={animation.container}>
              <div className={animation.bottom}>
                <SVGWrapper alt="car" src="home-hero-car-animation-bottom.svg" />
              </div>
              <div className={animation.middle}>
                <div className={animation.rotator}>
                  <SVGWrapper alt="car" src="home-hero-car-animation-middle.svg" />
                </div>
              </div>
              <div className={animation.top}>
                <SVGWrapper alt="car" src="home-hero-car-animation-top.svg" />
              </div>
            </div>
          </Grid>
        </Grid>
      </header>

      <main className="wrapper">
        <Grid component="section" className="facts">
          {typeof factList !== 'string' &&
            factList.map(({ img, title }, i) => (
              <Grid key={i}>
                <SVGWrapper alt="fact" src={img} />

                <Typography key={i} variant="h2" align="center">
                  {title}
                </Typography>
              </Grid>
            ))}
        </Grid>

        <Grid container direction="column" alignItems="center" component="section" className="cta">
          <Typography variant="h3">{t('landing.cta.title')}</Typography>
          <CommonButton
            data-testid={COMPONENT_ID.LOGIN_BUTTON_LANDING_PAGE_MAIN_CONTENT}
            loading={loading}
            endIcon="arrow-right"
            size="large"
            text="Log in to your account"
            onClick={handleLogin}
          />
        </Grid>

        <Grid container component="section" className="cover">
          <Grid item xs={12} sm={4}>
            <Typography variant="h1" className="cover__title">
              {t('landing.cover.title')}
            </Typography>
          </Grid>

          <Grid item container xs={12} sm={8} className="cover__list">
            {typeof coverList !== 'string' &&
              coverList.map((item, i) => (
                <Grid key={i} container spacing={2}>
                  <Grid item>
                    <IconSure icon="check" color="success" size="24" />
                  </Grid>

                  <Grid container item xs direction="column" spacing={1}>
                    <Typography variant="body2">{item}</Typography>
                    <Typography variant="body2" className="cover__list-label">
                      {t('landing.cover.included')}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="center" component="section" className="quote">
          <Typography variant="h1">{t('landing.quote.title')}</Typography>
          <Typography variant="h3">
            {typeof quoteList !== 'string' &&
              quoteList.map(({ link, title }, i) => (
                <Fragment key={i}>
                  {(i + 1 === quoteList.length && ' and ') || (i > 0 && ', ')}
                  <Link href={link} underline="none" target="_blank">
                    {title}
                  </Link>
                </Fragment>
              ))}
          </Typography>
          <Typography
            variant="h2"
            dangerouslySetInnerHTML={{
              __html: replaceLinksDomain(t('landing.quote.subtitle')),
            }}
          />
        </Grid>
      </main>

      <Footer fullWidth={false} hasBG theme="dark" />
    </Grid>
  );
});
