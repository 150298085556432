/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import AdyenCheckout from '@adyen/adyen-web';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import Core from '@adyen/adyen-web/dist/types/core';
import { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import { Instance, applySnapshot, flow, getRoot, getSnapshot, types } from 'mobx-state-tree';

import { IRootStore } from '../';
import config from '../../../config';
import { ADYEN_RESULT_CODE, ROUTE } from '../../../constant';
import {
  APIPayloadPaymentSubmit,
  mqsPaymentAdditionalSubmit,
  mqsPaymentMethodLoad,
  mqsPaymentSubmit,
} from '../../../network/api/payment';
import { Header } from '../../../util/interfaceModels/interfaceModels';
import { IInsuredAddress } from '../dashboard/autoPolicyModel';

import { BillingAddress } from './billingAddress';

interface TempState {
  data: Record<string, unknown>;
}

export const PaymentStore = types
  .model('PaymentStore', {
    additionalDetailsShown: types.maybeNull(types.boolean),
    billingAddress: types.maybeNull(BillingAddress),
    isCardOwnerSelected: types.maybeNull(types.boolean),
    isHomePolicy: types.maybeNull(types.boolean),
    policyNumber: types.maybeNull(types.string),
  })
  .volatile<{
    addressError: boolean;
    adyenAgent: null | Core;
    adyenDropin: null | DropinElement;
    initialState: unknown;
    isReady: boolean;
    isSubmitting: boolean;
    lastError: null | Error;
    paymentComplete: boolean;
    paymentError: boolean;
  }>((self) => ({
    addressError: false,
    adyenAgent: null,
    adyenDropin: null,
    initialState: getSnapshot(self),
    isReady: false,
    isSubmitting: false,
    lastError: null,
    paymentComplete: false,
    paymentError: false,
  }))
  .views((self) => ({
    get rootStore(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    setAdditionalDetailsShown(value: boolean) {
      self.additionalDetailsShown = value;
    },
    setIsHome(isHome: boolean) {
      self.isHomePolicy = isHome;
    },
    updateBillingAddress(address: IInsuredAddress | null) {
      if (address !== null) {
        self.billingAddress = BillingAddress.create({
          country: address.country,
          county: address.county,
          houseName: address.houseName,
          houseNumber: address.houseNumber,
          locality: '',
          organisation: '',
          postalTown: address.postalTown,
          postcode: address.postcode,
          street: address.street,
          subPremises: address.subPremises,
          udprn: '',
          uprn: '',
        });
      }
    },
    updatePaymentComplete(complete: boolean) {
      self.paymentComplete = complete;
    },
    updatePaymentError(error: boolean) {
      self.paymentError = error;
    },
    updatePolicyNumber(policyNumber: string) {
      self.policyNumber = policyNumber;
    },
  }))
  .actions((self) => ({
    clearStore() {
      applySnapshot(self, self.initialState);
    },

    processResponse(
      response: APIPayloadPaymentSubmit,
      dropin?: {
        handleAction: (action: Record<string, unknown>) => void;
        setStatus: (status: string, data?: { message: string }) => void;
      },
    ) {
      if (dropin && response.action) {
        // return additional actions to dropin
        dropin.handleAction(response.action.instance);
      } else {
        const resultCode = response.resultCode.toUpperCase();
        switch (resultCode) {
          case ADYEN_RESULT_CODE.AUTHORISED:
            dropin?.setStatus('success', { message: 'payment successful' });
            self.updatePaymentComplete(true);
            this.clearStore();
            break;
          case ADYEN_RESULT_CODE.ERROR:
          case ADYEN_RESULT_CODE.REFUSED:
            dropin?.setStatus('error', { message: 'unable to process payment' });
            self.updatePaymentError(true);
            break;
        }
      }
    },
  }))
  .actions((self) => ({
    onSubmit: flow(function* onSubmit(state: TempState, dropin) {
      if (self.billingAddress === null || self.isCardOwnerSelected === null) {
        self.addressError = true;
        self.adyenDropin?.setStatus('ready');
      } else {
        try {
          self.isSubmitting = true;

          const dashboardStore = self.rootStore.dashboardStore;

          const headers: Header = self.rootStore.getCustomerHeader();

          // compose the payment data bundle
          const data = {
            // state from the dropin will handle browserInfo & paymentMethod
            ...state.data,
            customerNumber: dashboardStore.customer.customerNumber,
            environment: config.provider.paymentEnv,
            origin: window.location.origin,
            paymentBillingAddress: self.billingAddress,
            policyNumber: self.policyNumber,

            // add the redirectUrl in case a redirect verification is required
            returnUrl: `${window.location.origin}`,

            // add storePaymentMethod used for CPA
            storePaymentMethod: false,
          };

          const paymentData =
            self.isHomePolicy || !self.rootStore.interfaceStore.hasFeatureFlag('renewalMtaActive')
              ? data
              : { ...data, usePendingMTAQuote: self.rootStore.renewalChangeStore.pendingMTAQuoteSelected };

          const paymentReq = mqsPaymentSubmit(
            paymentData,
            self.policyNumber,
            headers,
            window.location.pathname,
            self.isHomePolicy ? self.isHomePolicy : undefined,
          );

          // yield currently loses typing, force retype to the output results matching above
          const paymentRes = (yield paymentReq) as APIPayloadPaymentSubmit;

          if (paymentRes.resultCode) {
            return self.processResponse(paymentRes, dropin);
          }

          throw new Error('payment failed (invalid response from service)');
        } catch (err) {
          // if renewal payment fails
          if (window.location.pathname.includes(ROUTE.RENEWAL_PAYMENT)) {
            self.rootStore.dashboardStore.insurance.auto.setShouldUpdatePolicy(true, self.policyNumber ?? '');
          }

          self.lastError = err as Error;
          self.updatePaymentError(true);
        } finally {
          self.isSubmitting = false;
        }
      }
    }),
    refundPayment: flow(function* refundPayment() {
      try {
        self.isSubmitting = true;

        const headers: Header = self.rootStore.getCustomerHeader();

        headers['Content-Type'] = 'application/json';

        const paymentReq = mqsPaymentSubmit(
          null,
          self.policyNumber,
          headers,
          window.location.pathname,
          self.isHomePolicy ? self.isHomePolicy : undefined,
        );

        // yield currently loses typing, force retype to the output results matching above
        const paymentRes = (yield paymentReq) as APIPayloadPaymentSubmit;

        if (paymentRes.resultCode) {
          return self.processResponse(paymentRes);
        }

        throw new Error('payment failed (invalid response from service)');
      } catch (err) {
        self.lastError = err as Error;
        self.updatePaymentError(true);
      } finally {
        self.isSubmitting = false;
      }
    }),
    renewalPayment: flow(function* renewalPayment() {
      try {
        self.isSubmitting = true;

        const headers: Header = self.rootStore.getCustomerHeader();

        const data =
          self.isHomePolicy || !self.rootStore.interfaceStore.hasFeatureFlag('renewalMtaActive')
            ? null
            : { usePendingMTAQuote: self.rootStore.renewalChangeStore.pendingMTAQuoteSelected };

        headers['Content-Type'] = 'application/json';

        const paymentReq = mqsPaymentSubmit(
          data,
          self.policyNumber,
          headers,
          window.location.pathname,
          self.isHomePolicy ? self.isHomePolicy : undefined,
        );

        // yield currently loses typing, force retype to the output results matching above
        const paymentRes = (yield paymentReq) as APIPayloadPaymentSubmit;

        if (paymentRes.resultCode) {
          return self.processResponse(paymentRes);
        }

        throw new Error('payment failed (invalid response from service)');
      } catch (err) {
        self.lastError = err as Error;
        self.updatePaymentError(true);
      } finally {
        self.isSubmitting = false;
      }
    }),
    submitAdditionalDetail: flow(function* submitAdditionalDetail(data: Record<string, unknown>, dropin?) {
      try {
        self.isSubmitting = true;
        self.setAdditionalDetailsShown(true);

        const usePendingQuote =
          self.isHomePolicy || !self.rootStore.interfaceStore.hasFeatureFlag('renewalMtaActive')
            ? null
            : { usePendingMTAQuote: self.rootStore.renewalChangeStore.pendingMTAQuoteSelected };

        const dashboardStore = self.rootStore.dashboardStore;
        const headers: Header = self.rootStore.getCustomerHeader();

        const additionalDetailReq = mqsPaymentAdditionalSubmit(
          {
            ...data,
            customerNumber: dashboardStore.customer.customerNumber,
            paymentBillingAddress: self.billingAddress,
            ...usePendingQuote,
          },
          self.policyNumber,
          headers,
          window.location.pathname,
          self.isHomePolicy ? self.isHomePolicy : undefined,
        );

        // yield loses typing, force retype to the output results matching above
        const additionalDetailRes = (yield additionalDetailReq) as APIPayloadPaymentSubmit;

        self.processResponse(additionalDetailRes, dropin);
      } catch (err) {
        self.lastError = err as Error;
        self.updatePaymentError(true);
      } finally {
        self.isSubmitting = false;
      }
    }),
  }))
  .actions((self) => ({
    onAdditionalDetails: flow(function* onAdditionalDetails(state: TempState, dropin) {
      yield self.submitAdditionalDetail(state.data, dropin).catch((err) => {
        self.lastError = err as Error;
      });
    }),
  }))
  .actions((self) => ({
    initialiseAdyenAgent: flow(function* initialiseAdyenAgent(policyNumber: string, isHome: boolean) {
      try {
        const headers: Header = self.rootStore.getCustomerHeader();

        // get payment methods
        const paymentMethodsReq = mqsPaymentMethodLoad(
          {
            headers,
          },
          policyNumber,
          window.location.pathname,
          isHome,
        );

        // yield currently loses typing, force retype to the output results matching above
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const paymentMethodsResponse = (yield paymentMethodsReq) as PaymentMethodsResponse;

        const configuration = {
          analytics: {
            enabled: true, // Set to false to not send analytics data to Adyen.
          },
          clientKey: config.provider.paymentKey,
          environment: config.provider.paymentEnv,
          locale: 'en_GB',
          onAdditionalDetails: self.onAdditionalDetails,
          onSubmit: self.onSubmit,
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              positionHolderNameOnTop: true,
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          paymentMethodsResponse,
        };
        self.adyenAgent = (yield AdyenCheckout(configuration)) as Core;
      } catch (err) {
        self.lastError = err as Error;
      }
    }),

    resetAdyenDropinHandle() {
      this.setIsReady(false);
      this.setAdyenDropinHandle(null);
      this.setAdyenAgent(null);
    },

    setAdyenAgent(adyenAgent: typeof self.adyenAgent) {
      self.adyenAgent = adyenAgent;
    },

    setAdyenDropinHandle(adyenDropin: typeof self.adyenDropin) {
      self.adyenDropin = adyenDropin;
    },

    setCardOwnerSelected(isCardOwnerSelected: boolean | null) {
      self.isCardOwnerSelected = isCardOwnerSelected;
    },

    setIsReady(newValue: boolean) {
      self.isReady = newValue;
    },
  }));

export type IPaymentStore = Instance<typeof PaymentStore>;
