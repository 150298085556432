import { Grid, Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { Choice } from './choice';

export const CarModifiedQuestion: React.FC<{
  handleChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSubmitting: boolean;
  wasCarModified: null | boolean;
}> = ({ wasCarModified, isSubmitting, handleChangeRadio }) => {
  const { t } = useTranslation('myAccount');

  return (
    <Grid container item className="radio-section">
      <Typography variant="body1" className={wasCarModified === null && isSubmitting ? 'error' : ''}>
        {t('MTAJourney.CarModified.description')}
      </Typography>
      <Choice
        identifier="cardOwner"
        onChange={handleChangeRadio}
        left={{ label: 'No' }}
        right={{ label: 'Yes' }}
        radioValue={wasCarModified}
        error={wasCarModified === null && isSubmitting}
      />
    </Grid>
  );
};
