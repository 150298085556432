import { Grid } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { IInsuredAddress } from '../../../service/state/models/dashboard/autoPolicyModel';
import { ILookupItem } from '../../../service/state/models/dashboard/lookups';
import { EditFooter } from '../../../view/pages/policies/policy/makeAchange/mta/common/components/editFooter';
import { ChangeCarLocation } from '../changeCarLocation';
import { ChangeOvernightAddress } from '../changeOvernightAddress';

export interface CarOvernightFormProps {
  address: IInsuredAddress;
  attempts: number;
  classes: Record<'root', string>;
  handleBack: () => void;
  handleSave: () => void;
  nightLocation: string | undefined;
  options: ILookupItem[] | undefined;
  sameOvernight: boolean;
  selectedAddress: IInsuredAddress | undefined;
  setAttempts: Dispatch<SetStateAction<number>>;
  setNightLocation: Dispatch<SetStateAction<string | undefined>>;
  setSameOvernight: Dispatch<SetStateAction<boolean>>;
  setSelectedAddress: Dispatch<SetStateAction<IInsuredAddress | undefined>>;
}

export const CarOvernightForm: React.FC<CarOvernightFormProps> = ({
  address,
  handleBack,
  handleSave,
  nightLocation,
  options,
  sameOvernight,
  selectedAddress,
  setAttempts,
  setNightLocation,
  setSameOvernight,
  setSelectedAddress,
  attempts,
  classes,
}) => {
  const { t } = useTranslation('myAccount');

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <ChangeOvernightAddress
          address={address}
          sameOvernight={sameOvernight}
          selectedAddress={selectedAddress}
          setAttempts={setAttempts}
          setSameOvernight={setSameOvernight}
          setSelectedAddress={setSelectedAddress}
        />
        {(selectedAddress || sameOvernight) && (
          <ChangeCarLocation
            location={nightLocation}
            options={options}
            setLocation={setNightLocation}
            tooltipDescription={t('MTAJourney.CarUsage.CarOvernight.carKeptOvernight')}
            tooltipTitle={t('tooltips.changeYourCar.overnightAddress')}
          />
        )}
      </Grid>
      <EditFooter
        id="carOvernight"
        handleBack={handleBack}
        handleSave={handleSave}
        primaryButtonText={attempts >= 3 ? t('buttons.chatWithASpecialist') : undefined}
      />
    </>
  );
};

export default CarOvernightForm;
