import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { RenewalsDocumentsBtn } from '../../../../../../component/common/button/renewalsDocumentsBtn';
import IconSure from '../../../../../../component/common/icon';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

interface AccordionProps {
  expiryDate: string;
  hasButton: boolean;
  index: number;
  label: string;
  segmentStep: string;
  value: string;
}

export const RenewalsDeclineAccordion: React.FC<AccordionProps> = ({
  expiryDate,
  hasButton,
  label,
  segmentStep,
  value,
  index,
}) => {
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected: autoPolicySelected },
        home: { policySelected: homePolicySelected },
        isHome,
      },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const getListValue = (value: string, index: number) => {
    let accValue;

    accValue = value;
    switch (index) {
      case 0:
      case 1:
        accValue = value.replace('{expiryDate}', expiryDate);
        break;
    }
    return accValue;
  };

  return (
    <Accordion
      onChange={() => {
        eventTrack(segmentStep, {
          position: segmentStep,
          type: TRACK_TYPE.ACCORDION,
        });
      }}
    >
      <AccordionSummary
        style={{ padding: 0 }}
        expandIcon={
          <IconSure color="secondary" icon="chevron-down" type="line" size="26" className="header_container-icon" />
        }
        data-testid="accordion-summary"
      >
        <Typography variant="body1" color="secondary">
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="details">
        <Typography>{getListValue(value, index)}</Typography>
        {hasButton && (
          <Grid>
            <RenewalsDocumentsBtn
              isDeclineButton={true}
              policy={isHome ? homePolicySelected : autoPolicySelected}
              fileName={t('policyManagement.yourRenewal.scheduleOfInsurance')}
            ></RenewalsDocumentsBtn>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
