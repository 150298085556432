import {
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { JOURNEY_TYPE } from '../../service/constant';
import { UseFormReturn } from '../../service/util/customHooks/useForm';
import { CarRegistrationForm } from '../../view/pages/policies/policy/makeAchange/mta/carDetails/steps/carRegistration';
import { MtaTooltip } from '../../view/pages/policies/policy/makeAchange/mta/common/components/mtaTooltip';

import IconSure from './icon';

interface RegInputProps {
  disabled?: boolean;
  handleChange: UseFormReturn<CarRegistrationForm>['handleChange'];
  journeyType: string;
  label?: string;
  placeholder: string;
  regError: string | undefined;
  regNumber: string | undefined;
  showTooltip?: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  additionalText: {
    marginBottom: spacing(3),
  },
  input: {
    '&.Mui-error': {
      '&>input::placeholder': {
        color: palette.error.dark,
        fontSize: 20,
        fontWeight: 700,
        opacity: 1,
      },
      backgroundColor: palette.warning.main,
    },
    backgroundColor: palette.warning.main,
    borderRadius: 4,
  },
  label: {
    fontSize: 16,
    marginBottom: spacing(1),
  },
}));

export const RegChangeInput: React.FC<RegInputProps> = ({
  journeyType,
  label,
  regError,
  handleChange,
  regNumber,
  placeholder,
  disabled,
  showTooltip,
}) => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  return (
    <Grid container item>
      {journeyType === JOURNEY_TYPE.REGISTRATION_NUMBER && (
        <Typography variant="body1" className={classes.additionalText}>
          {t('MTAJourney.CarRegistration.enterDetails.newRegDescription')}
        </Typography>
      )}

      {showTooltip ? (
        <MtaTooltip
          description={
            <InputLabel className={classes.label} htmlFor="text">
              {label}
            </InputLabel>
          }
          tooltipTitle={t('tooltips.changeYourCar.registration')}
        />
      ) : (
        <InputLabel className={classes.label} htmlFor="text">
          {label}
        </InputLabel>
      )}

      <OutlinedInput
        inputProps={{ maxLength: 8 }}
        className={classes.input}
        color="secondary"
        endAdornment={
          regError && (
            <InputAdornment position="end">
              <IconSure icon="error" type="solid" color="error" />
            </InputAdornment>
          )
        }
        error={!!regError}
        id="registrationNo"
        onChange={handleChange('registrationNo', (val) => val.replace(/[^0-9a-z]/gi, ''))}
        fullWidth
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        value={regNumber?.toUpperCase()}
      />
      <FormHelperText error>{regError}</FormHelperText>
    </Grid>
  );
};
