import { Button, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { track } from '../../../../lib/optimizely';
import { useSegment } from '../../../../service/util/customHooks/useSegment';

const useStyles = makeStyles(() => ({
  root: {
    '& .content': {
      ' & img': {
        width: '100%',
      },
      ' &__link': {
        paddingBottom: 80,
      },
      gap: 25,
      textAlign: 'center',
    },
  },
}));

const redirectUrl = (url: string) => (window.location.href = url);

export const WhatYouCanClaim: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const { eventTrack } = useSegment();

  return (
    <Grid container className={classes.root}>
      <Grid container item justifyContent="center" alignItems="center" className="content" direction="column">
        <Grid container item justifyContent="center" alignItems="center" direction="column">
          <Grid item>
            <img alt="car group" src="./assets/images/error_modal.svg" />
          </Grid>
        </Grid>

        <Typography variant="h1" align="center">
          {t('whatYouCanClaim.title')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('whatYouCanClaim.contentParagraph')}
        </Typography>
        <Link
          onClick={() => {
            track('My_Account_what_you_can_claim_claims_page_click');
            eventTrack('What You Can Claim For Online - Continue Button Clicked');
            redirectUrl(process.env.REACT_APP_CLAIM_URL ?? '');
          }}
          target="_blank"
          underline="none"
        >
          <Button color="primary" variant="contained" size="large">
            {t('whatYouCanClaim.btnText')}
          </Button>
        </Link>
        <Typography variant="body1" align="center">
          {t('whatYouCanClaim.secondContentParagraph')}
        </Typography>

        <Link href={`tel:${t('claims.old.phone')}`} underline="none" className="content__link">
          {t('whatYouCanClaim.call')}
        </Link>
      </Grid>
    </Grid>
  );
});
