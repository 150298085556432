import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import IconSure from '../../common/icon';

const useStyles = makeStyles((theme) => ({
  type1: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(12),
    },
    '& svg': {
      minWidth: 24,
    },
    color: '',
  },
  type2: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(12),
    },
    '& svg': {
      minWidth: 35,
    },
    color: theme.palette.grey[800],
  },
}));

export interface CoverItemProps {
  color?: 'success' | 'error' | 'secondary' | 'grey800';
  customUsage?: boolean;
  icon?: 'check' | 'times' | 'circle';
  key?: number;
  text: string;
}

export const CoverItem: React.FC<CoverItemProps> = ({ text, color = 'success', icon = 'check', customUsage }) => {
  const classes = useStyles({ customUsage });

  return (
    <Grid container className={customUsage ? classes.type1 : classes.type2}>
      <Grid xs={1} item>
        <IconSure icon={icon} color={color} strokeWidth="2.5" />
      </Grid>
      <Grid xs={11} item>
        <Typography variant={customUsage ? 'body1' : 'body2'}>{text}</Typography>
      </Grid>
    </Grid>
  );
};
