import { Box, Divider, Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PopupTooltip } from '../../../../../../component/common/popupTooltip';
import { DATE_TYPES, LOOKUP } from '../../../../../../service/constant';
import {
  IAutoPolicyRenewalQuoteDetails,
  IRenewalPendingMTAQuote,
} from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import {
  IHomePolicyPaymentPlan,
  IHomePolicyRenewalQuoteDetails,
} from '../../../../../../service/state/models/dashboard/homePolicyModel';
import { ILookupItem } from '../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { formatDate } from '../../../../../../service/util/formatDate';

interface CostSummaryItemProps {
  leftIcon?: boolean;
  leftValue: string;
  leftValueHighlight?: boolean;
  rightValue?: string;
  rightValueHighlight?: boolean;
}

const CostSummaryItem: React.FC<CostSummaryItemProps> = ({
  leftIcon = false,
  leftValue,
  leftValueHighlight = false,
  rightValue,
  rightValueHighlight = false,
}) => {
  const { t } = useTranslation('myAccount');

  return (
    <Grid container justifyContent="space-between">
      {!leftIcon ? (
        <Typography gutterBottom variant={leftValueHighlight ? 'h3' : 'body1'}>
          {leftValue}
        </Typography>
      ) : (
        <Box display="flex">
          <Typography variant="h3">Interest</Typography>
          <PopupTooltip
            alignment="left"
            background="#EBF1FC"
            tooltipColor="secondary"
            placement="bottom-end"
            size="medium"
            title={t('policyManagement.yourRenewalQuote.renewalCostBreakdown.interestTooltipText')}
          />
        </Box>
      )}
      {rightValue && (
        <Typography color="textSecondary" variant={rightValueHighlight ? 'h3' : 'body1'}>
          {rightValue}
        </Typography>
      )}
    </Grid>
  );
};

const MotorPremiumBeakdownCost: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyRenewalQuoteDetails },
        },
      },
      lookups: { getLookupValue, lookupsByName },
    },
    renewalChangeStore: { pendingMTAQuoteSelected },
  } = useStores();

  const [coverageDescriptions, setCoverageDescriptions] = useState<ILookupItem[] | undefined>();

  const { isLookupsFetched } = useLookups({ lookups: [LOOKUP.COVERAGE_DESCRIPTION] });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.COVERAGE_DESCRIPTION] !== undefined) {
      const lookups = [...lookupsByName[LOOKUP.COVERAGE_DESCRIPTION]];
      setCoverageDescriptions(lookups);
    }
  }, [isLookupsFetched, lookupsByName]);

  const selectedQuote =
    pendingMTAQuoteSelected && policyRenewalQuoteDetails.pendingMTAQuote
      ? policyRenewalQuoteDetails.pendingMTAQuote
      : policyRenewalQuoteDetails;

  const yourPolicy = selectedQuote.vehicle.packCoverages.find((item) => item.packType === 'BASE');
  const addOns = selectedQuote.vehicle.packCoverages.filter((item) => item.packType !== 'BASE');

  return coverageDescriptions ? (
    <>
      <Divider />
      <Grid item container>
        <CostSummaryItem
          leftValue={t('policyManagement.yourRenewalSummary.policyBreakdown.title')}
          leftValueHighlight
        />
        <CostSummaryItem
          leftValue={t('policyManagement.yourRenewalSummary.policyBreakdown.yourPolicy')}
          rightValue={t('core:price.pound', { value: yourPolicy?.gwtAnnualPremium })}
        />
        {addOns.map((element, index) => {
          return (
            <CostSummaryItem
              key={index}
              leftValue={getLookupValue(LOOKUP.COVERAGE_DESCRIPTION, element.coverageCd)}
              rightValue={t('core:price.pound', { value: element.gwtAnnualPremium })}
            />
          );
        })}
      </Grid>
    </>
  ) : (
    <></>
  );
});

const CostSummary: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
    renewalChangeStore: { pendingMTAQuoteSelected },
  } = useStores();

  const policySelected = isHome ? home.policySelected : auto.policySelected;
  let renewalQuoteDetails;

  if (isHome) {
    renewalQuoteDetails = home.policySelected.policyRenewalQuoteDetails;
  } else if (pendingMTAQuoteSelected && auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote) {
    renewalQuoteDetails = auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote;
  } else {
    renewalQuoteDetails = auto.policySelected.policyRenewalQuoteDetails;
  }

  const isDDCustomer = policySelected.paymentPlan !== 'YearlyCardPayment';

  const getPolicyRenewalQuoteDetails = (
    policyRenewalQuoteDetails:
      | IHomePolicyRenewalQuoteDetails
      | IAutoPolicyRenewalQuoteDetails
      | IRenewalPendingMTAQuote,
  ): IHomePolicyPaymentPlan | IAutoPolicyRenewalQuoteDetails | IRenewalPendingMTAQuote => {
    if ('policyPaymentPlan' in policyRenewalQuoteDetails) {
      return policyRenewalQuoteDetails.policyPaymentPlan;
    }
    return policyRenewalQuoteDetails;
  };

  const { installmentSchedule, numberOfInstallments, policyCostWithoutInterest, premiumBreakdown, apr } =
    getPolicyRenewalQuoteDetails(renewalQuoteDetails);

  const startDate = renewalQuoteDetails.startDate ?? '';

  return (
    <Grid className="monthly-payment-section">
      <Grid className="header">
        <Typography variant="h3">{t('policyManagement.yourRenewalSummary.title')}</Typography>
      </Grid>
      <Grid container className="content">
        <Grid item>
          <Typography gutterBottom variant="body1">
            {t('policyManagement.yourRenewalSummary.startDateTitle')}
          </Typography>
          <Typography variant="h4">
            {startDate !== ''
              ? t('policyManagement.yourRenewalSummary.startDateValue', {
                  date: formatDate(startDate.toString(), DATE_TYPES.LONG),
                })
              : ''}
          </Typography>
        </Grid>

        {isDDCustomer && (
          <>
            <Divider />
            <Grid item>
              <Typography gutterBottom variant="body1">
                {t('policyManagement.yourRenewalSummary.monthlyPayment.title')}
              </Typography>
              <Typography gutterBottom variant="h4">
                {t('policyManagement.yourRenewalSummary.monthlyPayment.numberOfPayments', {
                  amount: t('core:price.pound', {
                    value: installmentSchedule.length ? installmentSchedule[0].amount : 0,
                  }),
                  value: numberOfInstallments,
                })}
              </Typography>

              <Typography gutterBottom variant="h4">
                {t('policyManagement.yourRenewalSummary.monthlyPayment.firstPayment', {
                  date: formatDate(installmentSchedule.length ? installmentSchedule[0].dueDate : '', DATE_TYPES.LONG),
                })}
              </Typography>
              <Typography variant="body1">
                <Trans
                  i18nKey="policyManagement.yourRenewalSummary.monthlyPayment.note"
                  t={t}
                  components={[<strong key={Symbol().toString()} />]}
                />
              </Typography>
            </Grid>
          </>
        )}

        {!isHome ? <MotorPremiumBeakdownCost /> : null}

        {isDDCustomer && (
          <>
            <Divider />
            <Grid item container>
              <CostSummaryItem
                leftValue={t('policyManagement.yourRenewalSummary.totalPremium.title')}
                leftValueHighlight
                rightValue={t('core:price.pound', { value: policyCostWithoutInterest })}
              />
              <CostSummaryItem
                leftValue={t('policyManagement.yourRenewalSummary.totalPremium.interest')}
                leftValueHighlight
                leftIcon
                rightValue={t('core:price.pound', { value: premiumBreakdown.interest })}
              />
              <Typography variant="body1">
                {t('policyManagement.yourRenewalSummary.totalPremium.apr', {
                  value: apr,
                })}
              </Typography>
            </Grid>
          </>
        )}

        <Divider />

        <Grid item container>
          <CostSummaryItem
            leftValue={t('policyManagement.yourRenewalSummary.total.title')}
            leftValueHighlight
            rightValue={t('core:price.pound', { value: renewalQuoteDetails.currentYearTotalCost })}
            rightValueHighlight
          />
          <CostSummaryItem
            leftValue={
              isDDCustomer
                ? t('policyManagement.yourRenewalSummary.total.included')
                : t('policyManagement.yourRenewalSummary.total.includesipt')
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default CostSummary;
