/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Instance, detach, types } from 'mobx-state-tree';

import { LOOKUP } from '../../../constant';

export const LookupItem = types.model('LookupItem', {
  code: types.string,
  lookupName: types.maybeNull(types.string),
  value: types.string,
});

export const Lookups = types
  .model('Lookups', {
    decoded: types.array(LookupItem),
  })
  .views((self) => ({
    get lookupsByCode() {
      return self.decoded.reduce((acc: Record<string, string>, item) => {
        acc[item.code] = item.value;
        return acc;
      }, {});
    },
    get lookupsByName() {
      return self.decoded.reduce((acc: Record<string, ILookupItem[]>, item) => {
        const { lookupName } = item;
        if (lookupName) {
          acc[lookupName] = acc[lookupName] || [];
          acc[lookupName].push(item);
          return acc;
        }
        return acc;
      }, {});
    },
    get lookupsByNameAndCode() {
      return Object.entries(this.lookupsByName).reduce(
        (acc: Record<string, Record<string, string>>, [lookupName, lookup]) => {
          acc[lookupName] = acc[lookupName] || [];
          lookup.forEach((item: ILookupItem) => {
            const { code, value } = item;
            return (acc[lookupName][code] = value);
          });
          return acc;
        },
        {},
      );
    },
  }))
  .actions((self) => ({
    decodeByFieldName(field: string, code: string): string {
      switch (field) {
        case 'modifications':
          return this.getLookupValue(LOOKUP.MODIFICATIONS, code);
        case 'securityDeviceDeclared':
          return this.getLookupValue(LOOKUP.VEHICLE_SECURITY, code);
        case 'legalOwner':
          return this.getLookupValue(LOOKUP.OWNER, code);
        case 'classOfUse':
          return this.getLookupValue(LOOKUP.USAGE, code);
        case 'daytimeLocation':
          return this.getLookupValue(LOOKUP.DAYTIME_LOCATION, code);
        case 'registeredKeeper':
          return this.getLookupValue(LOOKUP.KEEPER, code);
        case 'socialMileageCode':
        case 'businessMileageCode':
          return this.getLookupValue(LOOKUP.MILEAGE, code);
        case 'overnightLocation':
          return this.getLookupValue(LOOKUP.OVERNIGHT_LOCATION, code);
        case 'residencyStatusCode':
          return this.getLookupValue(LOOKUP.RESIDENTIAL_STATUS, code);
        case 'maritalStatusCode':
          return this.getLookupValue(LOOKUP.MARITAL_STATUS, code);
        case 'numberOfChildren':
          return this.getLookupValue(LOOKUP.NUMBER_OF_CHILDREN, code);
        case 'carsInHousehold':
          return this.getLookupValue(LOOKUP.CARS_IN_HOUSEHOLD, code);
        case 'relationshipToProposerCode':
          return this.getLookupValue(LOOKUP.DRIVER_RELATIONSHIP, code);
        case 'employmentStatusCode':
          return this.getLookupValue(LOOKUP.EMPLOYMENT, code);
        case 'primaryOccupationCode':
        case 'secondaryOccupationCode':
          return this.getLookupValue(LOOKUP.OCCUPATION, code);
        case 'primaryOccupationIndustryCode':
        case 'secondaryOccupationIndustryCode':
          return this.getLookupValue(LOOKUP.INDUSTRY, code);
        case 'licenceTypeCode':
          return this.getLookupValue(LOOKUP.LICENCE_TYPE, code);
        case 'licenseDateYearsCode':
          return this.getLookupValue(LOOKUP.LICENSE_HELD_YEARS, code);
        case 'licenseRestrictionCode':
          return this.getLookupValue(LOOKUP.LICENSE_RESTRICTIONS, code);
        case 'useOfOtherCarsCode':
          return this.getLookupValue(LOOKUP.ACCESS_TO_OTHER_CARS, code);
        default:
          return '';
      }
    },
    genLookupPayload(codes: (string | null)[], lookupName: LOOKUP): ILookupDecode[] {
      return codes.reduce((acc: ILookupDecode[], code) => {
        if (code && !this.getLookupValue(lookupName, code)) acc.push({ code, lookupName });
        return acc;
      }, []);
    },
    getLookupCode(lookupName: LOOKUP, lookupValue: string): string {
      const item = self.decoded.find((item) => item.lookupName === lookupName && item.value === lookupValue);
      return item?.code ?? '';
    },
    getLookupValue(lookupName: LOOKUP, lookupCode: string): string {
      const lookups = self.lookupsByNameAndCode[lookupName];
      return lookups?.[lookupCode] || '';
    },
    replaceDecodedLookups(data: ILookupItem[], lookup: LOOKUP) {
      const verifiedLookups = data.filter((item) => item.value);
      if (self.decoded.length) {
        self.decoded.forEach((item) => {
          if (item.lookupName === lookup) {
            detach(item);
            self.decoded.remove(item);
          }
        });
        self.decoded.replace([...self.decoded, ...verifiedLookups]);
      } else {
        self.decoded.replace(verifiedLookups);
      }
    },
    setDecodedLookups(data: ILookupItem[]) {
      // allow just payload with values and prevent model issues
      const lookups = data.filter((x) => x.value);

      if (self.decoded.length) {
        self.decoded.replace([...lookups, ...self.decoded]);
      } else {
        self.decoded.replace(lookups);
      }
    },
  }));

export type ILookupItem = Instance<typeof LookupItem>;
export type ILookups = Instance<typeof Lookups>;

export interface ILookupDecode {
  code: string;
  lookupName: LOOKUP;
}
