import { Grid, Typography, alpha, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { DATE_TYPES, LOOKUP } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { useDecodedLookupsHome } from '../../../service/util/customHooks/useDecodedLookups';
import { formatDate } from '../../../service/util/formatDate';
import SelfService from '../../../service/util/selfService';
import { BlueTableContainer } from '../../common/blueTableContainer';

export interface RowOptions {
  description: string[];
  title?: string;
}

export interface DrawerContainer {
  headerTitle: string;
  options: (RowOptions | boolean)[];
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .bold-item': {
      fontWeight: 700,
    },
    '& .home-address': {
      paddingTop: spacing(2),
    },
    '& .postcode': {
      '&-pin': {
        paddingLeft: 5,
        paddingRight: 5,
      },
      backgroundColor: alpha(palette.secondary.main, 0.08),
      border: `1px solid ${palette.text.primary}`,
      borderRadius: spacing(0.5),
      margin: spacing(1, 0),
      maxWidth: 'fit-content',
      overflow: 'hidden',
      paddingRight: 5,
    },
    '& .property-info': {
      '&__item': {
        '&:first-child': {
          paddingRight: spacing(2),
        },
        '&:not(:first-child)': {
          '&:before': {
            background: alpha(palette.common.black, 0.26),
            content: `''`,
            height: 11,
            left: -1,
            position: 'absolute',
            top: 4,
            width: 2,
          },
          padding: spacing(0, 2),
        },
        position: 'relative',
      },
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingBottom: spacing(2),
    },
    '& .summary': {
      '&__item': {
        '&:not(:nth-last-child(1))': {
          borderBottom: `1px solid ${palette.divider}`,
        },
        gap: spacing(1),
        margin: spacing(0, 3),
        padding: spacing(2, 0),
      },
    },
    '& .wrapper-container': {
      marginBottom: 20,
    },
    gap: spacing(4),
  },
}));

export const YourHomeDrawer: React.FC = observer(function YourHomeDrawer() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { isDecodedLookupsFetched } = useDecodedLookupsHome();

  const {
    dashboardStore: {
      lookups: { getLookupValue },
      insurance: {
        home: {
          policySelected: {
            homeConstructionInfo: {
              ownershipTypeCd,
              builtYear,
              listedBuildingCd,
              numberOfBathrooms,
              numberOfBedrooms,
              propertyTypeCd,
              roofBuildMaterialCd,
              wallBuildMaterialCd,
              rebuildCost,
              percentageFlatRoofCd,
            },
            homePropertyUse: {
              numberOfAdults,
              numberOfChildren,
              unoccupiedForLongPeriod,
              businessUseTypeCd,
              occupantsCd,
            },
            channelCd,
            homeProtection: { locksOnWindowsAndDoors, burglarAlarm, prevFlooded, doorsLockTypeCd, windowsLockTypeCd },
            homeBuildingDeclarationQuestion: { buildingInProgress, prevDamage, repairState },
            claims,
            homeAddress,
            prevConvictions,
          },
        },
      },
    },
  } = useStores();

  const getChildrenValue = () => {
    if (numberOfChildren < 1) {
      return t('yourHomeDrawer.homeDetails.child.noChild');
    } else if (numberOfChildren === 1) {
      return t('yourHomeDrawer.homeDetails.child.childSg');
    } else {
      return `${numberOfChildren} ${t('yourHomeDrawer.homeDetails.child.childPl')}`;
    }
  };

  const hasWindowsAndLocks = channelCd === 'DIRECT' || channelCd === 'MIGRATION' || channelCd === 'PHONE';

  const drawerContent: DrawerContainer[] = [
    {
      headerTitle: t('yourHomeDrawer.homeDetails.title'),
      options: [
        {
          description: [getLookupValue(LOOKUP.OWNERSHIP, ownershipTypeCd)],
          title: t('yourHomeDrawer.homeDetails.labels.ownership'),
        },
        {
          description: [
            getLookupValue(LOOKUP.RESIDENTS, occupantsCd),
            `${numberOfAdults} ${
              numberOfAdults === 1
                ? t('yourHomeDrawer.homeDetails.adult.adultSg')
                : t('yourHomeDrawer.homeDetails.adult.adultPl')
            }`,
            getChildrenValue(),
          ],
          title: t('yourHomeDrawer.homeDetails.labels.residents'),
        },
        {
          description: [prevConvictions ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.convictions'),
        },
        !hasWindowsAndLocks && {
          description: [getLookupValue(LOOKUP.MAIN_ENTRANCE_LOCK, doorsLockTypeCd)],
          title: t('yourHomeDrawer.homeDetails.labels.mainEntrance'),
        },
        !hasWindowsAndLocks && {
          description: [getLookupValue(LOOKUP.WINDOW_LOCK, windowsLockTypeCd)],
          title: t('yourHomeDrawer.homeDetails.labels.windowLocks'),
        },
        hasWindowsAndLocks && {
          description: [locksOnWindowsAndDoors ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.doorAndWindow'),
        },
        {
          description: [burglarAlarm ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.alarm'),
        },
        {
          description: [buildingInProgress ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.buildingInProgress'),
        },
        percentageFlatRoofCd !== '' && {
          description: [getLookupValue(LOOKUP.ROOF_FLAT, percentageFlatRoofCd)],
          title: t('yourHomeDrawer.homeDetails.labels.flatRoof'),
        },
        businessUseTypeCd !== '' && {
          description: [getLookupValue(LOOKUP.BUSINESS_TYPE, businessUseTypeCd)],
          title: t('yourHomeDrawer.homeDetails.labels.businessUse'),
        },
        {
          description: [
            t('core:price.pound', {
              value: rebuildCost,
            }),
          ],
          title: t('yourHomeDrawer.homeDetails.labels.rebuildCost'),
        },
        {
          description: [unoccupiedForLongPeriod ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.propertyUnoccupied'),
        },
        {
          description: [prevFlooded ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.flooding'),
        },
        {
          description: [getLookupValue(LOOKUP.WALL_TYPE, wallBuildMaterialCd)],
          title: t('yourHomeDrawer.homeDetails.labels.externalWall'),
        },
        {
          description: [getLookupValue(LOOKUP.ROOF_TYPE, roofBuildMaterialCd)],
          title: t('yourHomeDrawer.homeDetails.labels.roofMaterial'),
        },
        {
          description: [repairState ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.repairState'),
        },

        {
          description: [prevDamage ? t('labels.yes') : t('labels.no')],
          title: t('yourHomeDrawer.homeDetails.labels.subsidence'),
        },
      ],
    },
    ...claims.map(({ claimAmount, claimTypeCd, claimDate, claimCd }) => ({
      headerTitle: `${t('yourHomeDrawer.previousClaims.title')}`,
      options: [
        {
          description: [
            `${getLookupValue(LOOKUP.CLAIM_BUILDOR, claimTypeCd)}, ${getLookupValue(
              LOOKUP.HOME_CLAIM_TYPE,
              claimCd,
            )}, £${claimAmount}`,
          ],
          title: formatDate(claimDate, DATE_TYPES.SHORT_SPACE),
        },
      ],
    })),
  ];

  const getPropertyInfo = () => {
    const values = [
      getLookupValue(LOOKUP.PROPERTY_TYPE, propertyTypeCd),
      `${t('yourHomeDrawer.homeDetails.labels.builtIn')} ${builtYear}`,
      `${
        listedBuildingCd === 'N'
          ? t('yourHomeDrawer.homeDetails.labels.notListed')
          : t('yourHomeDrawer.homeDetails.labels.listed')
      }`,
      `${numberOfBedrooms} ${
        numberOfBedrooms > 1
          ? t('yourHomeDrawer.homeDetails.bedroom.bedroomPl')
          : t('yourHomeDrawer.homeDetails.bedroom.bedroomSg')
      }, ${numberOfBathrooms} ${
        numberOfBathrooms > 1
          ? t('yourHomeDrawer.homeDetails.bathroom.bathroomPl')
          : t('yourHomeDrawer.homeDetails.bathroom.bathroomSg')
      }`,
    ];
    return isDecodedLookupsFetched ? (
      <Grid container className="property-info">
        {values.map((item, index) => (
          <Typography key={index} variant="caption" className="property-info__item">
            {item}
          </Typography>
        ))}
      </Grid>
    ) : (
      <></>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={clsx('bold-item', 'home-address')}>
        {SelfService.formatAddress(homeAddress).home}
      </Typography>

      <Grid container item className="postcode" alignItems="baseline" xs={4}>
        <SVGWrapper alt="" src="postcodepin.svg" className="postcode-pin" height={12} />
        <Typography variant="h5">{homeAddress.postcode}</Typography>
      </Grid>

      {getPropertyInfo()}

      {isDecodedLookupsFetched &&
        drawerContent.map(({ headerTitle, options }, index) => (
          <Grid container className="wrapper-container" key={index}>
            <BlueTableContainer title={headerTitle}>
              <Grid container>
                {options.map((option, _index) =>
                  typeof option !== 'boolean' ? (
                    <Grid key={_index} container className="summary__item">
                      <Typography variant="body2">{option.title}</Typography>
                      <Grid container justifyContent="space-between">
                        <Grid>
                          {option.description.map((value, index) => (
                            <Typography variant="body1" className="bold-item" key={index}>
                              {value}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null,
                )}
              </Grid>
            </BlueTableContainer>
          </Grid>
        ))}
    </div>
  );
});
