import { Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlueBanner } from '../../../../../component/common/blueBanner';
import { CustomTabs, TabProps } from '../../../../../component/common/customTabs';
import { ROUTE } from '../../../../../service/constant';
import { useStores } from '../../../../../service/state/store';
import { MotorRenewalNotification } from '../../../policies/policy/renewals/components/motorRenewalNotification';

import { Instalments } from './payments';
import { PolicyBreakdown } from './policyBreakdown';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    '& .blue-banner': {
      marginBottom: spacing(1),
    },
    '& .content': {
      '& .main-card': {
        [breakpoints.up('sm')]: {
          margin: spacing(4, 0),
        },
        margin: spacing(0, 2),
      },
      gap: spacing(2),
      [breakpoints.up('sm')]: {
        margin: spacing(0),
      },
      margin: spacing(3, 0, 0, 0),
    },
    '& .tabs': {
      '& #tabs-container': {
        marginTop: 0,
        position: 'unset',
      },
      [breakpoints.up('md')]: {
        marginTop: spacing(3),
      },
    },
    margin: spacing(-4.5, -2, 0),
  },
}));
export const DirectDebitPayment: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
    interfaceStore: { isTablet, isDesktop },
  } = useStores();

  const classes = useStyles();

  const tabs: TabProps[] = [
    // {
    //   components: [{ component: <PaymentActivity />, path: ROUTE.PAYMENT_ACTIVITY }],
    //   disabled: false,
    //   label: t('directDebitPayment.paymentActivity'),
    // },
    {
      components: [{ component: <PolicyBreakdown />, path: ROUTE.POLICY_BREAKDOWN }],
      disabled: false,
      label: t('directDebitPayment.policyBreakdown'),
    },
    {
      components: [{ component: <Instalments />, path: ROUTE.INSTALMENTS }],
      disabled: false,
      label: t('directDebitPayment.instalments'),
    },
  ];

  return (
    <Grid className={classes.root}>
      <BlueBanner route={ROUTE.POLICY_DETAILS} title={t('breadcrumbs.payments')} />
      <Grid container className="content">
        <Grid className="main-card" container alignItems="center" justifyContent="center">
          {policySelected.futureRenewalEffectiveDate === null && <MotorRenewalNotification policy={policySelected} />}
          <CustomTabs
            className="tabs"
            item
            xs={12}
            activeTab={0}
            color="secondary"
            orientation="horizontal"
            tabs={tabs}
            variant={isTablet || isDesktop ? 'standard' : 'fullWidth'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
