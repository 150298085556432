import { getRoot, types } from 'mobx-state-tree';

import { IRootStore } from '..';
import { ChatSession, IncomingChatItem, initiateChat } from '../../../../component/common/chatBotWidget/chatBotWidget';
import { sendCloseHook } from '../../../../lib/caura-bridge';
import { trackSatismeter, updateSatismeterContactId } from '../../../../lib/satismeter';
import { event, getDeviceSession } from '../../../analytics';
import config from '../../../config';
import { RENEWAL_CHATBOT_PAGES } from '../../../constant';
import { stringifyError } from '../../../util/stringUtils';

/**
 * The types of contact flow to start.
 */
export enum CONTACT_FLOW_TYPE {
  MAIN,
  MTA,
  CPA_AR,
  HOME,
  HOME_AR,
}

export interface StartChatConfig {
  // contact flow type, optional, will default to the main chat flow if not set
  contactFlowType?: CONTACT_FLOW_TYPE;
  // additional contact attributes that you'd like to get set as contact attributes within Amazon connect.
  customContactAttributes?: Record<string, string>;
  // the initiator of the chat, e.g. Appbar button
  initiator: string;
}

const ContactFlowTypesMap: Record<CONTACT_FLOW_TYPE, string> = {
  [CONTACT_FLOW_TYPE.MAIN]: config.amazonConnect.contactFlowIDs.main,
  [CONTACT_FLOW_TYPE.MTA]: config.amazonConnect.contactFlowIDs.mta,
  [CONTACT_FLOW_TYPE.CPA_AR]: config.amazonConnect.contactFlowIDs.cpa_ar,
  [CONTACT_FLOW_TYPE.HOME]: config.amazonConnect.contactFlowIDs.home,
  [CONTACT_FLOW_TYPE.HOME_AR]: config.amazonConnect.contactFlowIDs.home_ar,
};

const isRenewal = (location: string): boolean => {
  if (typeof location !== 'undefined' && location.includes('/')) {
    const dividedPath = location.split('/');
    const currentPage = dividedPath[dividedPath.length - 1];
    return currentPage === RENEWAL_CHATBOT_PAGES.YOUR_QUOTE_RENEWAL;
  } else {
    return false;
  }
};

export const ChatBot = types
  .model('ChatBot', {
    contactId: types.optional(types.string, ''),
    participantId: types.optional(types.string, ''),
    participantToken: types.optional(types.string, ''),
  })
  .volatile<{
    hasNotification: boolean;
    initiated: boolean;
    isAgentConnected: boolean;
    isHidden: boolean;
    minimised: boolean;
  }>(() => ({
    hasNotification: false,
    initiated: false,
    isAgentConnected: false,
    isHidden: false,
    minimised: false,
  }))
  .views((self) => ({
    get rootStore(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    closeChat() {
      const { userStore, dashboardStore } = self.rootStore;

      this.setInitiated(false);
      this.setMinimised(false);
      this.setNotification(false);
      this.setAgentConnected(false);
      // eslint-disable-next-line
      if (userStore?.user.userLoggedIn && !isRenewal(window.location.href)) {
        dashboardStore.refetchPoliciesData();
      }
      // Ensure any existing chat details is wiped
      this.setCurrentChatDetails('', '', '');
    },

    setAgentConnected(connected: boolean) {
      self.isAgentConnected = connected;
    },

    setCurrentChatDetails(contactId: string, participantId: string, participantToken: string) {
      self.contactId = contactId;
      self.participantId = participantId;
      self.participantToken = participantToken;
    },

    setInitiated(initiated: boolean) {
      self.initiated = initiated;
    },

    setIsHidden(hidden: boolean) {
      self.isHidden = hidden;
    },

    setMinimised(minimised: boolean) {
      self.minimised = minimised;
    },

    setNotification(hasNotification: boolean) {
      self.hasNotification = hasNotification;
    },
    /**
     * Starts the chat.
     * @param startChatConfig configuration object for launching the chat
     * @returns void
     */
    /* istanbul ignore next */
    startChat(startChatConfig: StartChatConfig) {
      const failureHandler = (error: unknown) => {
        this.closeChat();
        // eslint-disable-next-line no-console
        console.error('CHATBOT: Error initiating chat', error);
      };

      const successHandler = (chatSession: ChatSession) => {
        this.setCurrentChatDetails(
          chatSession.client.session.controller.contactId,
          chatSession.client.session.controller.participantId,
          chatSession.client.session.controller.participantToken,
        );

        updateSatismeterContactId(chatSession.client.session.controller.contactId);

        // Here we can intercept the incoming item from the transcript and modify the display name
        chatSession.incomingItemDecorator = (item: IncomingChatItem) => {
          // Using a try catch to ensure that we always return the item, even if there is an error
          // This is to ensure that the chat doesn't break if there is an error in the incomingItemDecorator
          // since the library we are using will silently fail during rendering the transcript if we have not returned the item
          try {
            // Once agent has joined, then update the state
            if (
              item.participantRole === 'AGENT' &&
              item.content.type === 'application/vnd.amazonaws.connect.event.participant.joined' &&
              !self.isAgentConnected
            ) {
              this.setAgentConnected(true);
            }
            const favicon = document.getElementById('favicon') as HTMLAnchorElement;
            const title = document.title.startsWith('(1)') ? document.title.slice(4) : document.title;

            // Set notification to true if tab is not visible, or the chat is minimised
            this.setNotification(self.isHidden || self.minimised);

            if (self.hasNotification) {
              favicon.href = `./assets/images/${config.brand}/faviconNotification.png`;
              document.title = `(1) ${title}`;
            } else {
              favicon.href = `./assets/images/${config.brand}/favicon.png`;
            }

            if ('SYSTEM_MESSAGE' === item.displayName) {
              item.displayName = '';
            } else if ('BOT' === item.displayName) {
              item.displayName = 'Virtual Assistant';
            }
          } catch (e: unknown) {
            event('ChatWidgetIncomingItemDecoratorError', { error: stringifyError(e as Error) });
            // eslint-disable-next-line no-console
            console.error('Error in incomingItemDecorator', e);
          }
          return item;
        };

        chatSession.onChatDisconnected((_data: unknown) => {
          // Caura logic to close WebView on chat close for standalone mode
          if (window.location.search.includes('launchChat=true')) void sendCloseHook();
          // On disconnected, just clear out the current chat details
          this.setCurrentChatDetails('', '', '');
        });

        chatSession.onChatClose((_data: unknown) => {
          // If disconnected or ended, then we close the chat down completely, otherwise just minimise it
          if (chatSession.contactStatus === 'disconnected' || chatSession.contactStatus === 'ended') {
            // Keep a new reference for this boolean, as the closeChat function will reset it, and we need it's value afterwards
            const hasBeenConnectedToAgent = self.isAgentConnected;
            this.closeChat();
            // Only pop the survey if the chat had been connected to an agent
            if (hasBeenConnectedToAgent) {
              trackSatismeter('SME-Live-Chat Ended');
            }
          } else {
            this.setMinimised(true);
          }
        });
      };

      // If chat already initiated, then maximise the widget if it's currently minimised
      if (self.initiated) {
        if (self.minimised) {
          this.setMinimised(false);
          this.setNotification(false);
        }
        return;
      }

      // Set this to true, this will turn the display to block and get the spinner loading whilst the chat loads
      this.setMinimised(false);
      this.setInitiated(true);

      const { rootStore } = self;
      // get a reference to the dashboard store
      const dashboardStore = rootStore.dashboardStore;
      const interfaceStore = rootStore.interfaceStore;

      // Assign sone of the values we'll use for the contactAttributes
      const customerName = dashboardStore.customer.fullName.length > 1 ? dashboardStore.customer.fullName : 'Anonymous';
      const accessToken = interfaceStore.accessToken;
      const brand = interfaceStore.brand;
      const policyNo = !dashboardStore.insurance.isHome
        ? dashboardStore.insurance.auto.policySelected.policyNumber
        : dashboardStore.insurance.home.policySelected.policyNumber;

      // Make API call to initiate chat
      initiateChat({
        failureCallback: failureHandler,
        initiateChatConfig: {
          apiGatewayEndpoint: config.amazonConnect.apiGatewayUrl,
          // contactAttributes, this needs to be a string, so the object passed goes through JSON.stringify. The object should be a key/value pair of strings.
          // The exception to this is customContactAttributes which should itself be an object of which it is a key/value pair of all strings.
          // These key/value pairs will be set as contact attributes in the Amazon connect contact flow.
          contactAttributes: JSON.stringify({
            accessToken,
            customContactAttributes: {
              // Policy number listed first, as we want to allow that to be overridden in the passed in customContactAttributes
              policyNo,
              ...startChatConfig.customContactAttributes,
              // These are after the passed in customContactAttributes, so they can't be overridden
              appVersion: config.app.version,
              brand,
              chatWidgetVersion: window.connect?.ChatInterface.getVersion?.() ?? 'unknown',
              deviceSession: getDeviceSession(),
            },
            customerName,
            existingChatInfo: {
              contactId: self.contactId,
              participantId: self.participantId,
              participantToken: self.participantToken,
            },
            host: `${window.location.protocol}//${window.location.host}`,
            initiator: startChatConfig.initiator,
            urlPath: window.location.pathname,
          }),
          // If passed in contact flow type use that, otherwise use the MAIN one
          contactFlowId: ContactFlowTypesMap[startChatConfig.contactFlowType ?? CONTACT_FLOW_TYPE.MAIN],
          featurePermissions: {
            ATTACHMENTS: false, // this is the override flag from user for attachments
          },
          // Here we can pass in any valid connect instance ID
          instanceId: config.amazonConnect.instanceID,

          // This is required, this will display in the chat e.g. John has joined the chat
          // It will be passed in as the ParticipantDetails DisplayName property. See docs below
          // https://docs.aws.amazon.com/connect/latest/APIReference/API_StartChatContact.html
          name: customerName,
          region: config.amazonConnect.region,
        },
        successCallback: successHandler,
      });
    },
  }));
