import { CircularProgress, Grid, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';

import { COMPONENT_ID, getDeviceSession } from '../../../../../../service/analytics';
import { useStores } from '../../../../../../service/state/store';
import { useSegment } from '../../../../../../service/util/customHooks/useSegment';

const adyenClassTarget = (t: string) => `& .adyen-checkout__${t}`;

const useAdyenStyles = makeStyles((theme) => ({
  root: {
    [adyenClassTarget('button--pay')]: {
      /* Pay button */
      ...theme.overrides?.MuiButton?.root,
      ...theme.overrides?.MuiButton?.contained,
      ...theme.overrides?.MuiButton?.containedPrimary,
      ...theme.overrides?.MuiButton?.sizeLarge,
      ...theme.overrides?.MuiButton?.containedSizeLarge,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    [adyenClassTarget('input')]: {
      /* Input fields */
      ...theme.typography.body1,
    },
    [adyenClassTarget('label__text')]: {
      /* Text element inside the form label container */
      ...theme.typography.body1,
    },
    [adyenClassTarget('payment-method__name')]: {
      /* Payment method name in the payment method header */
      ...theme.typography.h3,
    },
  },
}));

export const AdyenControl: React.FC = observer(function AdyenControl() {
  const { paymentStore } = useStores();
  const integrationContainer = useRef<HTMLElement>(null);
  const adyenClasses = useAdyenStyles();
  const { eventTrackInteraction } = useSegment();
  const {
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const policyNumber = isHome ? home.policySelected.policyNumber : auto.policySelected.policyNumber;

  useEffect(() => {
    if (!paymentStore.adyenAgent) {
      paymentStore.initialiseAdyenAgent(policyNumber, isHome);
    }
  }, [paymentStore]);

  useEffect(() => {
    /* istanbul ignore next */
    if (paymentStore.adyenAgent && integrationContainer.current) {
      // create a checkout instance
      const adyenDropin = paymentStore.adyenAgent.create('dropin', {
        // Called when the shopper selects the Pay button and payment details are valid.
        // This function has already been configured in the adyenAgent, so we must ensure we make a call to the original onSubmit
        onSubmit: (state, _dropin) => {
          // Using try catch to ensure the original onSubmit will always get called
          try {
            // Send custom event track, this should mimic what button clicks do from the shared component library, so have the same event name and properties
            eventTrackInteraction('Button Clicked', {
              componentID: COMPONENT_ID.ADYEN_DROPIN_BUTTON_PAY,
              label: 'Pay',
              sessionId: getDeviceSession(),
              url: window.location.href,
            });
          } catch (e) {
            // Do nothing
          }
          // Call the already configured onSubmit
          if (paymentStore.adyenAgent?.options.onSubmit) {
            paymentStore.adyenAgent.options.onSubmit(state, _dropin);
          }
        },
      });

      // mount the checkout instance to container
      adyenDropin.mount(integrationContainer.current);

      paymentStore.setAdyenDropinHandle(adyenDropin);

      // return a cleanup routine
      return () => {
        // unmount this checkout instance on cleanup
        adyenDropin.unmount();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        paymentStore.resetAdyenDropinHandle();
      };
    }
  }, [paymentStore, paymentStore.adyenAgent, integrationContainer]);

  return (
    <Grid container data-testid="step-pay-adyen-dropin" direction="column">
      <Grid classes={adyenClasses} innerRef={integrationContainer} item>
        {!paymentStore.adyenAgent && <CircularProgress color="secondary" />}
      </Grid>
    </Grid>
  );
});
