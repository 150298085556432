import { Alert, AlertTitle, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import CheckCircle from '@material-ui/icons/CheckCircle';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DATE_TYPES, POLICY_TYPE_CODE, ROUTE } from '../../service/constant';
import { IAutoPolicy } from '../../service/state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../../service/state/models/dashboard/homePolicyModel';
import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';
import { formatDate } from '../../service/util/formatDate';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .link': {
      cursor: 'pointer',
    },
    '& .notification-box': {
      marginBottom: spacing(2),
    },
  },
}));

export interface ThankYouNotificationProp {
  insuranceDetails: string;
  policy: IAutoPolicy | IHomePolicy;
}

const ThankYouNotificationContent: React.FC<ThankYouNotificationProp> = observer(function ThankYouNotificationContent({
  policy,
  insuranceDetails,
}) {
  const {
    dashboardStore: {
      fetchPolicyDocuments,
      insurance: {
        auto: { setSelectedPolicyNumber },
        setSelectedPolicyType,
      },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const history = useHistory();
  const { eventTrack } = useSegment();
  const { policyNumber, futureRenewalEffectiveDate } = policy;

  const handleClick = () => {
    setSelectedPolicyNumber(policyNumber);
    setSelectedPolicyType(policy);
    fetchPolicyDocuments(policyNumber, futureRenewalEffectiveDate).then((res) => {
      if (res?.status === 200) {
        history.push(ROUTE.DOCUMENTS);
        eventTrack('Go to documents link clicked', {
          position: 'Thank you notification',
          type: TRACK_TYPE.LINK,
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Alert className="notification-box" severity="success" iconMapping={{ success: <CheckCircle /> }}>
        <AlertTitle>
          {t('expiryNotice.renewalSuccess', {
            date: formatDate(futureRenewalEffectiveDate?.toString(), DATE_TYPES.MINI),
            insuranceType: insuranceDetails,
          })}
        </AlertTitle>

        <Typography variant="body2">
          <Link className="link" onClick={handleClick}>
            {t('expiryNotice.documentsLink')}
          </Link>
        </Typography>
      </Alert>
    </div>
  );
});

export const ThankYouNotification: React.FC<ThankYouNotificationProp> = observer(function ThankYouNotification({
  policy,
  insuranceDetails,
}) {
  const {
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  const { futureRenewalEffectiveDate, policyTypeCd, paymentPlan } = policy;

  const isHomePolicy = policyTypeCd === POLICY_TYPE_CODE.EIS_HOME || policyTypeCd === POLICY_TYPE_CODE.TIA_HOME;
  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

  /*when we'll get rid of the FFs, it is advisable that we include ThankYouNotificationContent in the parent component.
  For now, I have separated them to be easier to understand the flow*/

  const renderContent = () => {
    switch (true) {
      case !isHomePolicy && futureRenewalEffectiveDate !== null: //all motor customers can see it
      case isHomePolicy &&
        futureRenewalEffectiveDate !== null &&
        hasFeatureFlag('showHomeRenewalNotification - annual') &&
        !isDDCustomer: //annual customers see this in dev
      case isHomePolicy &&
        futureRenewalEffectiveDate !== null &&
        hasFeatureFlag('showHomeRenewalNotification - DD') &&
        isDDCustomer: //DD customers see this in dev
        return <ThankYouNotificationContent insuranceDetails={insuranceDetails} policy={policy} />;
      default:
        return null;
    }
  };

  return renderContent();
});
