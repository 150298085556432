import { Button, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Success } from '../../../animations/success';
import { COMPONENT_ID } from '../../../service/analytics';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .container': {
      '&-text': {
        maxWidth: '300px',
      },
      alignItems: 'center',
      gap: spacing(4),
      padding: spacing(5),
    },
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));

export const WelcomeToAccountDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const {
    interfaceStore: {
      dialog: { closeDialog },
    },
  } = useStores();

  useEffect(() => {
    eventTrack('Welcome To Account Viewed', { type: TRACK_TYPE.VIEW });
  }, []);

  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.root}>
      <Grid container item className="container" alignItems="center" direction="column">
        <Success />
        <Typography variant="h2" align="center" className="container-text">
          {t('welcomeToYourAccountDialog.title')}
        </Typography>
        <Button
          data-testid={COMPONENT_ID.WELCOME_TO_ACCOUNT_MODAL_CLOSE}
          color="primary"
          variant="contained"
          onClick={closeDialog}
          size="large"
        >
          {t('buttons.close')}
        </Button>
      </Grid>
    </Grid>
  );
};
