import { Typography, makeStyles } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

export interface CommonHeadingsProps {
  expiryDate: string;
  policyNumber: string;
}

export const useHeadingStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.secondary.dark,
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(4),
  },
}));

export const RenewalsCommonHeadings: React.FC<CommonHeadingsProps> = ({ policyNumber, expiryDate }) => {
  const { t } = useTranslation('myAccount');
  const classes = useHeadingStyles();

  return (
    <>
      <Typography className={classes.sectionTitle} variant="h2">
        {t('policyManagement.yourRenewal.unableToRenew')}
      </Typography>
      <Typography className="title" variant="h3">
        {t('policyManagement.yourRenewal.willEnd', { value: expiryDate })}
      </Typography>
      <Typography className="policyNo" variant="h4">
        {t('policyManagement.yourRenewal.policyNo', { value: policyNumber })}
      </Typography>
    </>
  );
};
