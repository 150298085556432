import { makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../service/state/store';
import { HomeRenewalQuote } from '../home/homeRenewalQuote';
import { MotorRenewalQuote } from '../motor/motorRenewalQuote';

export const renewalQuoteUseStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .renewals': {
      '&__banner': {
        '&-title': {
          marginTop: spacing(5),
        },
        backgroundColor: palette.secondary.main,
        borderColor: palette.secondary.main,
        [breakpoints.down('xs')]: {
          borderRadius: 0,
          margin: spacing(-4, -2, 0, -2),
        },
        [breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        color: palette.secondary.contrastText,
      },
      '&__buttons-container': {
        '& .MuiButton-root': {
          width: '311px',
        },
        marginTop: spacing(2),
        width: '100%',
        [breakpoints.down('sm')]: {
          '& .MuiButton-root': {
            width: '100%',
          },
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '&__carmake': {
        marginTop: spacing(5),
      },
      '&__costs': {
        display: 'flex',
        flexFlow: 'nowrap',
        gap: '15px',
        justfifyContent: 'space-between',
        padding: spacing(5, 0, 2, 0),
        [breakpoints.down('sm')]: {
          flexFlow: 'wrap',
        },
      },
      '&__documents': {
        minWidth: '100%',
      },
      '&__iconMargin': {
        marginRight: spacing(2),
      },
      '&__included': {
        padding: spacing(1, 2),
      },
      '&__needhelp': {
        gap: spacing(2),
        marginTop: spacing(3),
      },
      '&__notification': {
        backgroundColor: `${palette.primary.light}20`,
        border: `1px solid ${palette.primary.light}`,
        borderRadius: spacing(1),
        marginTop: spacing(2),
        padding: spacing(2),
      },
      '&__question': {
        paddingBottom: spacing(2),
        paddingTop: spacing(3),
      },
      '&__subtext': {
        marginBottom: spacing(5),
        marginTop: spacing(3),
      },
      '&__subtitle': {
        marginTop: spacing(4),
      },
    },
  },
}));

export const RenewalQuote: React.FC = observer(function RenewalQuote() {
  const {
    dashboardStore: {
      insurance: { isHome },
    },
  } = useStores();

  return isHome ? <HomeRenewalQuote /> : <MotorRenewalQuote />;
});
