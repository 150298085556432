import { Accordion, AccordionSummary, Box, Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { DateTime, Interval } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { RenewalsDocumentsBtn } from '../../../../../../component/common/button/renewalsDocumentsBtn';
import CommonAccordion from '../../../../../../component/common/commonAccordion';
import { ConfirmationMessage } from '../../../../../../component/common/confirmationMessage';
import IconSure from '../../../../../../component/common/icon';
import { AccordionStandardList, IconListSection } from '../../../../../../component/common/iconListSection';
import { EmptyState } from '../../../../../../component/layout/emptyState';
import { PolicyCard } from '../../../../../../component/layout/policyCards';
import { DATE_TYPES, PRODUCT_TYPE, ROUTE } from '../../../../../../service/constant';
import { CONTACT_FLOW_TYPE } from '../../../../../../service/state/models/interface/chatBot';
import { useStores } from '../../../../../../service/state/store';
import { useRenewalDocumentsFetch } from '../../../../../../service/util/customHooks/useRenewalDocumentsFetch';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';
import { formatDate } from '../../../../../../service/util/formatDate';
import { MonthlyPaymentCard } from '../components/monthlyPaymentCard';
import { RenewalCostBreakDown } from '../components/renewalCostBreakDown';
import RenewalCostCard from '../components/renewalCostCard';
import { RenewalDocuments } from '../components/renewalDocuments';
import { RenewalQuoteQuestionItem, RenewalQuoteQuestions } from '../components/renewalQuoteQuestions';
import { WhatsNextBox } from '../components/whatsNextBox';
import { renewalQuoteUseStyles } from '../motor/originalMotorRenewalQuote';

export const useHomeStyles = makeStyles(({ palette, spacing }) => ({
  accordionContainer: {
    '& .MuiAccordionSummary-root': {
      padding: spacing(0),
    },
    marginTop: spacing(0.5),
  },
  buttonMargin: {
    marginTop: spacing(2),
  },
  happyToGoAheadGrid: {
    gap: 10,
  },
  link: {
    cursor: 'pointer',
  },
  subTitle: {
    marginTop: spacing(4),
  },
  title: {
    color: palette.secondary.main,
    margin: spacing(3, 0, 2, 0),
  },
}));

const HappyToGoAhead: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        home: {
          policySelected: { expirationDate, autoRenewal, paymentPlan, autoRenewalOption },
        },
      },
    },
    interfaceStore: {
      isMobile,
      isTablet,
      chatBot: { startChat },
      hasFeatureFlag,
    },
  } = useStores();
  const classes = useHomeStyles();
  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { eventTrack } = useSegment();

  const isDDCustomer = paymentPlan !== 'YearlyCardPayment' && hasFeatureFlag('isHomeDD');
  const renewalDate = formatDate(expirationDate, DATE_TYPES.SHORT_SPACE);
  const info = t(`policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.autoRenewal.mainInfo`, {
    renewalDate: formatDate(renewalDate, DATE_TYPES.SHORT_SPACE),
    returnObjects: true,
  });

  const takePayment = t(`policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.autoRenewal.takePayment`, {
    cardInfo: autoRenewalOption.creditCardDetails.number?.slice(-4),
    returnObjects: true,
  });

  const checks: { content: string }[] = t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOff.checks', {
    returnObjects: true,
  });

  const handlePay = () => {
    if (isDDCustomer && hasFeatureFlag('homeRenewalDDSummaryPage')) {
      history.push(ROUTE.YOUR_RENEWAL_SUMMARY);
    } else {
      setIsSubmitting(true);
      if (confirmed) {
        history.push(ROUTE.RENEWAL_PAYMENT);
      }
      eventTrack('Home Rnwl | Continue to pay clicked');
    }
  };

  const handleChat = () => {
    eventTrack('Chat with us', {
      label: 'Not ready to renew',
      type: TRACK_TYPE.CHAT,
    });
    startChat({
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        fcaAutoRenewalInd: 'true',
        isRenewal: 'true',
        ssRenAROff: 'true',
      },
      initiator: 'Home Not ready to renew - Chat with us',
    });
  };

  return autoRenewal ? (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      <Typography variant="body1">
        <Trans i18nKey={info} t={t} components={[<strong key={Symbol().toString()} />]} />
      </Typography>
      {!isDDCustomer && (
        <Typography variant="body1">
          <Trans i18nKey={takePayment} t={t} components={[<strong key={Symbol().toString()} />]} />
        </Typography>
      )}
      <Typography variant="h3">
        {t('policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.autoRenewal.nonReadyToRenewTitle')}
      </Typography>
      <Typography variant="body1">
        {t('policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.autoRenewal.nonReadyToRenewInfoP1')}
      </Typography>
      <Grid item xs={isMobile || isTablet ? 12 : 3}>
        <CommonButton
          text={t('buttons.chatWithUs')}
          data-testid="chatButton"
          color="primary"
          className="button"
          variant="contained"
          size="large"
          xl
          onClick={handleChat}
        ></CommonButton>
      </Grid>
      <Typography variant="body1">
        {t('policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.autoRenewal.nonReadyToRenewInfoP2')}
      </Typography>
    </Grid>
  ) : (
    <Grid container className={classes.happyToGoAheadGrid} direction="column">
      {!isDDCustomer && (
        <>
          <Typography>
            {t('policyManagement.homeRenewalQuote.whatsNext.happy.annualCustomer.nonAutoRenewal.mainInfo')}
          </Typography>
          <IconListSection color="success" icon="check" list={checks} />
          <ConfirmationMessage
            hasError={!confirmed && isSubmitting}
            checked={confirmed}
            setChecked={setConfirmed}
            message={t('policyManagement.motorRenewalQuote.happyToGoAhead.autoRenewalOff.confirmation')}
            isRenewalMessage={true}
          />
        </>
      )}
      <Grid item xs={isMobile || isTablet ? 12 : 3} className={isDDCustomer ? classes.buttonMargin : ''}>
        <CommonButton
          text={isDDCustomer ? t('MTAJourney.button.continue') : t('MTAJourney.button.continueToPay')}
          size="large"
          xl
          onClick={handlePay}
        ></CommonButton>
      </Grid>
    </Grid>
  );
});

export const getLoyaltyText = (loyaltyYears: number, under4: string, over4: string): string => {
  if (loyaltyYears < 4) return under4;
  return over4;
};

export const HomeRenewalQuote: React.FC = observer(() => {
  const { hasFetched, hasDocsLoadingFailed } = useRenewalDocumentsFetch();

  const {
    dashboardStore: {
      insurance: {
        home: { policySelected },
      },
    },
    interfaceStore: {
      chatBot: { startChat },
      hasFeatureFlag,
    },
  } = useStores();
  const classes = renewalQuoteUseStyles();
  const homeClasses = useHomeStyles();
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();

  const {
    autoRenewal,
    inceptionDate,
    policyRenewalQuoteDetails: {
      previousYearTotalCost,
      previousYearMonthlyCost,
      currentYearMonthlyCost,
      currentYearTotalCost,
      coverType,
      policyPaymentPlan: {
        apr,
        policyCostWithoutInterest,
        premiumBreakdown: { interest },
        numberOfInstallments,
        installmentSchedule,
      },
    },
    paymentPlan,
  } = policySelected;

  const isDDCustomer = paymentPlan !== 'YearlyCardPayment' && hasFeatureFlag('isHomeDD');
  const questions: RenewalQuoteQuestionItem[] = t('policyManagement.yourRenewalQuote.faqList', {
    returnObjects: true,
  });

  const columnLists: AccordionStandardList[] = t(
    policySelected.productType === PRODUCT_TYPE.CORE
      ? `policyManagement.yourRenewalQuote.includedStandardRenewalHome.${coverType}`
      : `policyManagement.yourRenewalQuote.includedStandardRenewalHomeFlex.${coverType}`,
    {
      returnObjects: true,
    },
  );

  useEffect(() => {
    eventTrack('Home Rnwl | Quote page viewed');
  }, []);

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(!open);
    eventTrack("Home Rnwl | What's included in as standard expanded");
  };

  const onChatWithUs = () => {
    startChat({
      contactFlowType: CONTACT_FLOW_TYPE.CPA_AR,
      customContactAttributes: {
        ssRenQuoteUpdatePol: 'true',
      },
      initiator: 'Chat with us',
    });

    eventTrack('Home Rnwl | Need to update your policy chat clicked', {
      type: TRACK_TYPE.CHAT,
    });
  };
  const policyAgeYears = inceptionDate
    ? Math.floor(Interval.fromDateTimes(inceptionDate, DateTime.now()).length('years'))
    : 0;

  return (
    <Grid className={clsx('renewals', classes.root)}>
      <BlueBanner title={t('policyManagement.yourRenewalQuote.title')} route={ROUTE.RENEWAL_QUOTE_DETAILS} />
      <Typography className={homeClasses.title} variant="h1">
        {t('policyManagement.yourRenewalQuote.subtitle')}
      </Typography>
      <PolicyCard policy={policySelected} isPolicyHeader isRenewalHeader />
      <Grid container item className="renewals__costs">
        <RenewalCostCard
          hasTooltip={false}
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleCurrentYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? currentYearMonthlyCost : currentYearTotalCost,
          })}
          showDD={isDDCustomer}
          ddValue={t('core:price.pound', {
            value: currentYearTotalCost,
          })}
        />
        <RenewalCostCard
          hasTooltip
          title={t('policyManagement.yourRenewalQuote.renewalCostCard.titleLastYear')}
          value={t('core:price.pound', {
            value: isDDCustomer ? previousYearMonthlyCost : previousYearTotalCost,
          })}
          ddValue={t('core:price.pound', {
            value: previousYearTotalCost,
          })}
          showDD={isDDCustomer}
        />
      </Grid>

      {isDDCustomer && hasFeatureFlag('showHomeRenewalNotification - DD') && (
        <>
          <MonthlyPaymentCard
            currentYearMonthlyCost={currentYearMonthlyCost}
            installmentSchedule={installmentSchedule}
            numberOfInstallments={numberOfInstallments}
            startDate={installmentSchedule.length ? installmentSchedule[0].dueDate : ''}
          />
          <RenewalCostBreakDown
            apr={apr}
            policyCost={currentYearTotalCost}
            policyInterest={interest}
            policyCostWithoutInterest={policyCostWithoutInterest}
          />
        </>
      )}

      <Typography variant="body1EB" className="renewals__subtext">
        {getLoyaltyText(
          policyAgeYears,
          t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextunder4years'),
          t('policyManagement.yourRenewalQuote.renewalCostBreakdown.loyaltyTextover4years'),
        )}
      </Typography>

      <CommonAccordion
        customComponent={<IconListSection color="success" icon="check" list={columnLists} />}
        headerTitle={t('policyManagement.yourRenewalQuote.standard')}
        headerIcon="home"
        headerIconType="solid"
        defaultBehaviour
        expanded={open}
        onChange={onOpen}
      />
      <Typography variant="h2" className={homeClasses.subTitle}>
        {t('policyManagement.homeRenewalQuote.whatsNext.title')}
      </Typography>
      <Typography>{t('policyManagement.homeRenewalQuote.whatsNext.description')}</Typography>
      <WhatsNextBox
        number="1"
        title={t('policyManagement.homeRenewalQuote.whatsNext.check.title')}
        description={t('policyManagement.homeRenewalQuote.whatsNext.check.description')}
      >
        {!hasFetched ? (
          <></>
        ) : hasDocsLoadingFailed ? (
          <Box py={2}>
            <EmptyState title={t('docsFailedLoading.title')} content={t('docsFailedLoading.content')} />
          </Box>
        ) : (
          <Grid item xs={12} className="renewals__documents">
            <Grid className="renewals__buttons-container">
              <RenewalsDocumentsBtn
                policy={policySelected}
                fileName={t('policyManagement.yourRenewal.scheduleOfInsurance')}
                size="large"
              />
            </Grid>

            <Grid container direction="column" className={homeClasses.accordionContainer}>
              <Accordion>
                <AccordionSummary expandIcon={<IconSure color="secondary" icon="chevron-down" type="line" size={24} />}>
                  <Typography variant="body1" color="secondary">
                    {t('policyManagement.yourRenewalQuote.showRenewalDocument')}
                  </Typography>
                </AccordionSummary>
                <RenewalDocuments />
              </Accordion>
            </Grid>
          </Grid>
        )}
      </WhatsNextBox>
      <WhatsNextBox number="2" title={t('policyManagement.homeRenewalQuote.whatsNext.need.title')}>
        <Typography variant="body1">
          <Trans
            i18nKey="policyManagement.homeRenewalQuote.whatsNext.need.description"
            t={t}
            components={[
              <strong />,
              <Link
                className={homeClasses.link}
                data-testid="chat-link"
                underline="none"
                variant="body1"
                color="secondary"
                onClick={onChatWithUs}
              />,
            ]}
          />
        </Typography>
      </WhatsNextBox>
      <WhatsNextBox
        number="3"
        title={t('policyManagement.homeRenewalQuote.whatsNext.happy.title')}
        description={t(
          `policyManagement.homeRenewalQuote.whatsNext.happy.${isDDCustomer ? 'ddCustomer' : 'annualCustomer'}.${
            !autoRenewal ? 'nonAutoRenewal' : 'autoRenewal'
          }.description`,
        )}
      >
        <HappyToGoAhead />
      </WhatsNextBox>
      <RenewalQuoteQuestions
        header={t('policyManagement.yourRenewalQuote.gotQuestion')}
        subTitle={t('policyManagement.yourRenewalQuote.gotQuestionSubTitle')}
        questions={questions}
      />
    </Grid>
  );
});
