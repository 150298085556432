import {
  FormHelperText,
  Grid,
  KeyboardDatePicker,
  Link,
  MuiPickersUtilsProvider,
  TextField,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import LuxonUtils from '@date-io/luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, ROUTE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { getDateTimeNow } from '../../../../../../../../service/util/dateUtils';
import { DriverEditPageProps } from '../../drivers/steps/editDriver';
import { EditFooter } from '../components/editFooter';
import { MTAFooter } from '../components/footer';
import { useStepperContext } from '../utils/stepperContext';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    '& .date-picker': {
      '& > div': {
        paddingRight: 0,
      },
      '& span': {
        color: palette.secondary.main,
      },
      margin: 0,
    },
    '& .link': {
      cursor: 'pointer',
    },
    '& .time-picker': {
      width: '100%',
    },
    display: 'grid',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
    gap: spacing(3),
    marginTop: spacing(3),
  },
}));

export const StartDate: React.FC<DriverEditPageProps> = observer(({ handleBackDriverEdit, handleSaveDriverEdit }) => {
  const classes = useStyles();
  const {
    dashboardStore: {
      insurance: {
        auto: {
          policySelected: { policyRenewalQuoteDetails },
        },
      },
    },
    interfaceStore: { isMobile },
    mtaStore: {
      pendedMTA: { setEndorsementDate: setStartDate, endorsementDate: startDate },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const {
    setAlert,
    setTitle,
    nextStep,
    setEditMode,
    editMode,
    setStep,
    stepsData: { reviewStep, journeyType },
  } = useStepperContext();

  const currentTime = getDateTimeNow().toFormat('HH:mm');
  const [time, setTime] = useState(currentTime);
  const [timeError, setTimeError] = useState(false);
  const intialDate = startDate ?? getDateTimeNow();
  const [date, setDate] = useState(intialDate);
  const [dateError, setDateError] = useState(false);
  const history = useHistory();
  const isChangeDriverDetailsJourney = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;
  const inRenewalCycle = ['proposed', 'companyDeclined', 'customerDeclined'].includes(
    policyRenewalQuoteDetails.policyStatusCd,
  );

  const handleAlert = (renewalDate: string, changeDate: string) => {
    if (setAlert) {
      if (renewalDate === changeDate) {
        setAlert({
          description: t('MTAJourney.StartDate.alert.sameDateRenewal.description'),
          severity: 'error',
          showAlert: true,
          title: t('MTAJourney.StartDate.alert.sameDateRenewal.title'),
        });
      } else if (renewalDate < changeDate) {
        setAlert({
          description: t('MTAJourney.StartDate.alert.afterRenewal.description'),
          severity: 'error',
          showAlert: true,
          title: t('MTAJourney.StartDate.alert.afterRenewal.title'),
        });
      } else {
        setAlert({
          description: t('MTAJourney.StartDate.alert.renewalPrice.description'),
          severity: 'info',
          showAlert: true,
          title: t('MTAJourney.StartDate.alert.renewalPrice.title'),
        });
      }
    }
  };

  useEffect(() => {
    setTitle(t('MTAJourney.StartDate.title'));

    if (policyRenewalQuoteDetails.startDate && inRenewalCycle) {
      handleAlert(policyRenewalQuoteDetails.startDate.toISODate(), date.toISODate());
    } else if (setAlert) {
      setAlert({ description: '', severity: undefined, showAlert: false, title: '' });
    }
    window.scrollTo(0, 0);
  }, []);

  const handleChangeStartDate = (value: MaterialUiPickersDate | null) => {
    if (policyRenewalQuoteDetails.startDate && value) {
      handleAlert(policyRenewalQuoteDetails.startDate.toISODate(), value.toISODate());
    }
    if (value) {
      setDate(value.set({ hour: 0, minute: 0 }));

      if (value.day === intialDate.day && value.month === intialDate.month && value.year === intialDate.year) {
        setTime(currentTime);
      } else {
        setTime('00:00');
      }
    } else {
      setDate(getDateTimeNow());
    }
  };

  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dates: string[] = event.target.value.split(':');
    setDate(date.set({ hour: parseInt(dates[0]), minute: parseInt(dates[1]) }));
    setTime(event.target.value);
  };
  const isValid = (): boolean => {
    return dateError || timeError ? false : true;
  };

  const handlePendedMta = () => {
    isValid() && setStartDate(date);
  };
  const handleSave = () => {
    handlePendedMta();
    if (isValid()) {
      isChangeDriverDetailsJourney ? handleSaveDriverEdit?.() : setStep(reviewStep);
    }
  };
  const handleContinue = () => {
    handlePendedMta();
    isValid() && nextStep();
  };

  useEffect(() => {
    if (
      date.day === intialDate.day &&
      date.month === intialDate.month &&
      date.year === intialDate.year &&
      time < currentTime
    ) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  }, [date, time]);

  const handleBack = () => {
    if (editMode) {
      if (isChangeDriverDetailsJourney) {
        handleBackDriverEdit?.();
      } else {
        setEditMode(false);
        setStep(reviewStep);
      }
    } else {
      history.push(ROUTE.MAKE_CHANGE);
    }
  };

  const setMaxDate = () => {
    if (inRenewalCycle) {
      return policyRenewalQuoteDetails.startDate?.minus({ days: 1 });
    }
    const now = getDateTimeNow();
    return now.plus({ days: 30 });
  };

  const handleRenewalQuote = () => {
    history.push(ROUTE.YOUR_RENEWAL_SUMMARY);
  };

  return (
    <Grid className={classes.root} item xs={isMobile ? 12 : 6}>
      <Typography variant="body1" className="subtitle">
        {inRenewalCycle ? t('MTAJourney.StartDate.renewalCycleDescription') : t('MTAJourney.StartDate.description')}
      </Typography>
      {inRenewalCycle && (
        <Grid item>
          <Typography variant="h3">{t('MTAJourney.StartDate.startChangeFromRenewal.heading')}</Typography>
          <Typography variant="body2">
            <Link className="link" onClick={handleRenewalQuote}>
              {t('MTAJourney.StartDate.startChangeFromRenewal.link')}
            </Link>
          </Typography>
        </Grid>
      )}

      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <KeyboardDatePicker
          disableToolbar
          value={date}
          minDate={getDateTimeNow()}
          className="date-picker"
          format="dd/MM/yyyy"
          onChange={handleChangeStartDate}
          variant="inline"
          autoOk
          label={t('MTAJourney.StartDate.startDate')}
          color="secondary"
          inputVariant="outlined"
          views={['date', 'month', 'year']}
          onError={(err) => ((err as string).length > 0 ? setDateError(true) : setDateError(false))}
          minDateMessage={t('validations.invalidDate')}
          maxDate={setMaxDate()}
          maxDateMessage={inRenewalCycle ? t('validations.beforeRenewal') : t('validations.beyond30days')}
        />
      </MuiPickersUtilsProvider>
      <Grid>
        <TextField
          className="time-picker"
          type="time"
          label={t('MTAJourney.StartDate.time')}
          value={time}
          onChange={handleChangeTime}
        />
        {timeError && <FormHelperText error>{t('validations.invalidTime')}</FormHelperText>}
      </Grid>
      {editMode ? (
        <EditFooter id="startDate" validPage={isValid()} handleSave={handleSave} handleBack={handleBack} />
      ) : (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_START_DATE}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_START_DATE}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_START_DATE, handleContinue }}
          handleBack={handleBack}
        />
      )}
    </Grid>
  );
});
