import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../../../../../../../component/common/alert';
import { ENDORSEMENT_REASONS } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';

export const MultiplePoliciesBanner: React.FC = observer(function MultiplePoliciesBanner() {
  const {
    dashboardStore: {
      insurance: {
        auto: { autoPolicies: policies },
      },
    },
    mtaStore: {
      pendedMTA: { endorsementReason, endorsementSubReasons },
    },
  } = useStores();

  const { t } = useTranslation('myAccount');

  const isYourDetailsJourney =
    endorsementReason.includes(ENDORSEMENT_REASONS.DETAILS_CHANGE) ||
    endorsementSubReasons.includes(ENDORSEMENT_REASONS.DETAILS_CHANGE);

  return policies.length > 1 && isYourDetailsJourney ? (
    <Alert severity="info" message={t('changeYourDetails.alert')} />
  ) : (
    <></>
  );
});
