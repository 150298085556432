import { AlertProps } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarRegistrationFormComponent from '../../../../../../../../component/common/mta/carRegistration';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE } from '../../../../../../../../service/constant';
import { ResponseVehicleLookup } from '../../../../../../../../service/network/api/dashboard';
import { useStores } from '../../../../../../../../service/state/store';
import { useForm } from '../../../../../../../../service/util/customHooks/useForm';
import { CarRegistrationForm } from '../../../../makeAchange/mta/carDetails/steps/carRegistration';
import { useRenewalStepperContext } from '../../utils/renewalStepperContext';
import { RenewalChangeFooter } from '../footer';

export interface CarRegistrationProps {
  additionalText?: string;
  alert?: {
    description: string;
    severity?: AlertProps['severity'];
    title?: string;
  };
  button: string;
  description?: string;
  form?: {
    label: string;
    placeholder: string;
  };
  title: string;
}

export const CarRegistrationRenewal: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: { fetchVehicleLookup },
    renewalChangeStore: {
      renewalDetails: { setVehicle, vehicle },
    },
    interfaceStore: {
      chatBot: { startChat },
    },
  } = useStores();

  const {
    nextStep,
    setTitle,
    stepsData: { journeyType },
  } = useRenewalStepperContext();

  const view: CarRegistrationProps = t('MTAJourney.CarRegistration.enterDetails', { returnObjects: true });
  const [content, setContent] = useState<CarRegistrationProps>(view);
  const [attempts, setAttempts] = useState(0);
  const chatBotAttr = useMTAChatBotAttributes('ssMTAVehicleNoVRN');

  const { errors, formData, handleChange, handleSubmit, doSubmit, isSubmitting, handleResetForm, handleValidation } =
    useForm<CarRegistrationForm>({
      initialValues: { registrationNo: vehicle.vrm },
      validations: {
        registrationNo: {
          pattern: {
            allowWhiteSpaces: true,
            message: t('validations.regNoNotValid'),
            value: '^[A-Za-z0-9]{1,8}$',
          },
          required: {
            message: t('validations.regNoNotValid'),
            value: true,
          },
        },
      },
    });

  const findCar = () => {
    const { isValid } = handleValidation();
    if (formData.registrationNo !== undefined && formData.registrationNo !== '' && isValid) {
      const stringNoSpace = formData.registrationNo.replace(/\s/g, '');
      fetchVehicleLookup(stringNoSpace).then((res) => {
        if (res === undefined) {
          setAttempts(attempts + 1);
        } else {
          const vehicleResponse: ResponseVehicleLookup = res.data.results[0];

          const { make, model, vrm } = vehicleResponse;
          setVehicle({
            ...vehicle,
            make,
            model,
            vrm,
          });
          nextStep();
        }
      });
    }
  };

  useEffect(() => {
    setTitle(
      journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
        ? t('MTAJourney.CarRegistration.enterDetails.title')
        : t('MTAJourney.CarRegistration.enterDetails.newRegTitle'),
    );
  }, []);

  useEffect(() => {
    if (attempts < 3 && attempts !== 0) {
      setContent({
        ...view,
        alert: t('MTAJourney.CarRegistration.carNotFound.alert', { returnObjects: true }),
      });
    } else {
      if (attempts === 3) {
        handleResetForm();
        setContent({
          ...view,
          additionalText: t('MTAJourney.CarRegistration.registrationNotFound.additionalText'),
          alert: undefined,
          button: t('buttons.speakToASpecialist'),
        });
      }
    }
  }, [attempts]);

  const buttonClick = () => {
    if (attempts < 3) {
      findCar();
      doSubmit();
    } else {
      startChat({
        initiator: 'Finding car registration number failed - Step 2',
        ...(chatBotAttr ?? {}),
      });
    }
  };

  return (
    <>
      <CarRegistrationFormComponent
        description={content.description}
        alert={content.alert}
        form={content.form}
        additionalText={content.additionalText}
        button={content.button}
        regNumber={formData.registrationNo}
        regError={errors.registrationNo}
        handleChange={handleChange}
        findCar={findCar}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        journeyType={journeyType}
        showTooltip={false}
      />

      <RenewalChangeFooter
        backButtonId={COMPONENT_ID.RENEWAL_CAR_REG_BACK}
        cancelButtonId={COMPONENT_ID.RENEWAL_CAR_REG_CANCEL}
        primaryButton={{
          buttonId: COMPONENT_ID.RENEWAL_CAR_REG_SAVE,
          handleContinue: buttonClick,
          text: content.button,
        }}
      />
    </>
  );
});
