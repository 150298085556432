import { List, ListItem, ListItemText, makeStyles } from '@esure-cloud/react-components';

interface Props {
  listPurpose: ListPurposeObject[];
}

export interface ListPurposeObject {
  description: string;
  id: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  item: {
    display: 'list-item',
    padding: 0,
  },
  root: {
    listStyleType: 'disc',
    padding: 0,
    paddingLeft: spacing(2),
  },
}));

export const ListPurpose = ({ listPurpose }: Props) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {listPurpose.map(({ id, description }) => (
        <ListItem key={id} className={classes.item}>
          <ListItemText primary={description} />
        </ListItem>
      ))}
    </List>
  );
};
