import { CommonContainer, Grid, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { insuredUseStyles } from '../../../../../component/common/driverCard';
import IconSure from '../../../../../component/common/icon';
import { IHomeInsured } from '../../../../../service/state/models/dashboard/homePolicyModel';

export const HomeInsuredCard: React.FC<{ homeInsured: IHomeInsured }> = observer(({ homeInsured }) => {
  const { t } = useTranslation('myAccount');

  const classes = insuredUseStyles();

  const insuredType =
    homeInsured.relationshipToProposerCd === 'P'
      ? t('homePolicyDetailsPage.policyholder')
      : t('homePolicyDetailsPage.jointPolicyH');

  return (
    <CommonContainer color="secondary" variant="outlined">
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconSure color="secondary" icon="driver" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.profileFontWeight} variant="body2">
            {insuredType}
          </Typography>
        </Grid>
        <Grid className={classes.namesOnMobile} container item>
          <Typography className={classes.nameFontWeight} variant="h4">
            {homeInsured.person.fullName()}
          </Typography>
        </Grid>
      </Grid>
    </CommonContainer>
  );
});
