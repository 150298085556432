import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE } from '../../../../../../../../service/constant';
import { IDriver } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { EditFooter } from '../../common/components/editFooter';
import { MTAFooter } from '../../common/components/footer';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';
import { DriverEditPageProps } from '../steps/editDriver';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    gap: spacing(1),
  },
}));

export interface MainDriverProps extends DriverEditPageProps {
  defaultMainDriver: string | undefined;
  driverUpdateType?: string;
  drivers: IDriver[];
}

export const MainDriver: React.FC<MainDriverProps> = observer(
  ({ defaultMainDriver, drivers, handleBackDriverEdit, handleSaveDriverEdit }) => {
    const {
      mtaStore: {
        pendedMTA: { setDrivers, driverFullName },
      },
    } = useStores();
    const classes = useStyles();
    const { t } = useTranslation('myAccount');
    const {
      nextStep,
      setTitle,
      stepsData: { reviewStep, journeyType },
      editMode,
      setStep,
    } = useStepperContext();

    useEffect(() => {
      setTitle(t('MTAJourney.chooseMainDriver.title'));
    }, []);

    const [mainDriver, setMainDriver] = useState(defaultMainDriver);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isChangeDriverDetailsJourney = journeyType === JOURNEY_TYPE.CHANGE_DRIVER;

    const [continueEvent, setContinueEvent] = useState('');
    const [backEvent, setBackEvent] = useState('');
    const [cancelEvent, setCancelEvent] = useState('');

    const handlePendedMta = () => {
      setIsSubmitting(true);
      if (!mainDriver) return;
      setDrivers(
        drivers.map((item) => {
          if (item.uuid === mainDriver) {
            return { ...item, mainDriver: true };
          }
          return { ...item, mainDriver: false };
        }),
      );
    };

    const handleContinue = () => {
      if (!mainDriver) return;
      handlePendedMta();
      nextStep();
    };

    const handleSave = () => {
      handlePendedMta();
      isChangeDriverDetailsJourney ? handleSaveDriverEdit?.() : setStep(reviewStep);
    };

    const handleBack = () => {
      isChangeDriverDetailsJourney ? handleBackDriverEdit?.() : setStep(reviewStep);
    };

    useEffect(() => {
      switch (journeyType) {
        case JOURNEY_TYPE.ADD_DRIVER:
          setContinueEvent(COMPONENT_ID.MTA_CONTINUE_BUTTON_MAIN_DRIVER_ADD);
          setBackEvent(COMPONENT_ID.MTA_BACK_BUTTON_MAIN_DRIVER_ADD);
          setCancelEvent(COMPONENT_ID.MTA_CANCEL_BUTTON_MAIN_DRIVER_ADD);
          return;
        case JOURNEY_TYPE.REMOVE_DRIVER:
          setContinueEvent(COMPONENT_ID.MTA_CONTINUE_BUTTON_MAIN_DRIVER_REMOVE);
          setBackEvent(COMPONENT_ID.MTA_BACK_BUTTON_MAIN_DRIVER_REMOVE);
          setCancelEvent(COMPONENT_ID.MTA_CANCEL_BUTTON_MAIN_DRIVER_REMOVE);
          return;
        case JOURNEY_TYPE.CHANGE_DRIVER:
          setContinueEvent(COMPONENT_ID.MTA_CONTINUE_BUTTON_MAIN_DRIVER_UPDATE);
          setBackEvent(COMPONENT_ID.MTA_BACK_BUTTON_MAIN_DRIVER_UPDATE);
          setCancelEvent(COMPONENT_ID.MTA_CANCEL_BUTTON_MAIN_DRIVER_UPDATE);
          return;
        default:
          return;
      }
    }, []);

    return (
      <Grid container direction="column" className={classes.root}>
        <MtaTooltip
          description={t('MTAJourney.chooseMainDriver.description')}
          tooltipTitle={t('tooltips.mainDriver')}
        />
        <Grid item>
          <RadioGroup
            defaultValue={mainDriver}
            value={mainDriver}
            onChange={(event) => {
              setMainDriver(event.target.value);
            }}
          >
            {drivers.map((driver, idx) => {
              if (!driver.person.firstName || !driver.person.surname) return null;

              return (
                <FormControlLabel control={<Radio />} label={driverFullName(driver)} value={driver.uuid} key={idx} />
              );
            })}
          </RadioGroup>
          {!mainDriver && isSubmitting && <FormHelperText error>{t('validations.noDriver')}</FormHelperText>}
        </Grid>
        {editMode ? (
          <EditFooter id="mainDriver" validPage={!!mainDriver} handleSave={handleSave} handleBack={handleBack} />
        ) : (
          <MTAFooter
            backButtonId={backEvent}
            cancelButtonId={cancelEvent}
            primaryButton={{
              buttonId: continueEvent,
              handleContinue,
            }}
          />
        )}
      </Grid>
    );
  },
);
