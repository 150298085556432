import { Grid, Theme, Typography, makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../service/analytics';
import { ENDORSEMENT_REASONS, FITTED, LOOKUP, NON_FITTED, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { ReviewAllDivider, ReviewAllEditButton } from '../../makeAchange/mta/common/steps/reviewAll';

const useStyles = makeStyles<Theme, { isDesktop: boolean }>(({ spacing }) => ({
  columnsContainer: {
    gap: ({ isDesktop }) => (isDesktop ? spacing(5) : 0),
    padding: spacing(2, 2),
  },
  root: {
    marginTop: spacing(3),
  },
}));

interface ILookupData {
  owner: string;
  registeredKeeper: string;
  security: string;
}

export const YourCarAccordion: React.FC<{ showEditButton?: boolean }> = observer(function YourCarAccordion({
  showEditButton = true,
}) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
    },
    renewalChangeStore: {
      renewalDetails: { addEndorsementReason, vehicle },
    },
  } = useStores();
  const history = useHistory();

  const [lookupData, setLookupData] = useState<ILookupData>({
    owner: '',
    registeredKeeper: '',
    security: '',
  });

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.MODIFICATIONS, LOOKUP.OWNER, LOOKUP.KEEPER, LOOKUP.VEHICLE_SECURITY],
  });

  useEffect(() => {
    setLookupData({
      owner: getLookup(LOOKUP.OWNER, vehicle.legalOwnerCd),
      registeredKeeper: getLookup(LOOKUP.KEEPER, vehicle.registeredKeeperCd),
      security: getLookup(LOOKUP.VEHICLE_SECURITY, vehicle.securityDeviceDeclaredCd || ''),
    });
  }, [isLookupsFetched]);

  const classes = useStyles({ isDesktop });

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.carRegistration')} </Typography>
          <Typography variant="h4">{vehicle.vrm}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHANGE_REG);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_REG}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.makemodel')} </Typography>
          <Typography variant="h4">{`${vehicle.make} ${vehicle.model}`}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHANGE_CAR);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_CAR}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.securityDevice')} </Typography>
          <Typography variant="h4">{lookupData.security}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHANGE_SECURITY);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_SECURITY}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.trackingDevice')} </Typography>
          <Typography variant="h4">{vehicle.trackingDeviceFitted ? FITTED : NON_FITTED}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHANGE_SECURITY);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_TRACKING_DEVICE}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.modifications')} </Typography>
          {vehicle.carModifications === true
            ? vehicle.modifications.map((item, index) => (
                <Typography variant="h4" key={Symbol(index).toString()}>
                  {getLookup(LOOKUP.MODIFICATIONS, item.modificationCd)}
                </Typography>
              ))
            : 'None'}
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_CHANGE_MODIFICATIONS);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_MODIFICATIONS}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.legalOwner')} </Typography>
          <Typography variant="h4">{lookupData.owner}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_LEGAL_OWNER);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_LEGAL_OWNER}
            />
          </Grid>
        )}
      </Grid>
      <ReviewAllDivider />
      <Grid
        container
        wrap="nowrap"
        className={clsx(classes.columnsContainer, 'columnsContainer')}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{t('policyManagement.yourCoverDetails.yourCarAccordion.registeredKeeper')} </Typography>
          <Typography variant="h4">{lookupData.registeredKeeper}</Typography>
        </Grid>
        {showEditButton && (
          <Grid item>
            <ReviewAllEditButton
              onEdit={() => {
                addEndorsementReason(ENDORSEMENT_REASONS.VEHICLE_CHANGE);
                history.push(ROUTE.YOUR_COVER_DETAILS_REGISTERED_KEEPER);
              }}
              compId={COMPONENT_ID.RENEWAL_EDIT_LINK_CHANGE_REGISTERED_KEEPER}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
});
