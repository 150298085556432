import {
  Checkbox,
  CommonContainer,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CommonButton } from '../../../../component/common/button/commonButton';
import { CHANNEL_TYPE } from '../../../../service/constant';
import { ICustomerMarketingPermissions } from '../../../../service/state/models/dashboard/customer';
import { useStores } from '../../../../service/state/store';
import { useForm } from '../../../../service/util/customHooks/useForm';
import { TRACK_TYPE, useSegment } from '../../../../service/util/customHooks/useSegment';
import { HomeRenewalNotification } from '../../policies/policy/renewals/components/homeRenewalNotification';
import { MotorRenewalNotification } from '../../policies/policy/renewals/components/motorRenewalNotification';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  gridContainer: {
    [breakpoints.up('sm')]: {
      paddingTop: spacing(3),
    },
  },
  root: {
    '& .button': {
      width: 'auto',
    },
    '& .card': {
      '&-title': {
        padding: spacing(1, 0.8, 2),
      },
      marginBottom: 15,
      padding: spacing(3),
      width: '100%',
    },
    '& .header': {
      '&-title': {
        flex: 1,
      },
      gap: 10,
      minHeight: 40,
    },

    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingTop: spacing(2.5),
  },
}));

type MarketingForm = Record<CHANNEL_TYPE, ICustomerMarketingPermissions>;

export const Communication: React.FC = observer(function Communication() {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');
  const comSubtitles: string[] = t('communication.communicationSectionTitles', { returnObjects: true });

  const {
    dashboardStore: {
      customer: { marketingPreference },
      insurance: {
        auto: { autoPolicies },
        home: { homePolicies },
      },
      updateCustomerMarketingPermissions,
    },
    interfaceStore: { notification },
  } = useStores();

  const updatePreferences = (): Promise<boolean> =>
    updateCustomerMarketingPermissions(Object.values(formData))
      .then(() => {
        eventTrack('Apply Changes Clicked', { type: TRACK_TYPE.APPLY_CHANGE });
        notification.openNotification({
          message: t('communication.successfullyUpdate'),
          severity: 'success',
        });
        return true;
      })
      .catch(() => {
        notification.openNotification({
          message: t('communication.failedUpdate'),
          severity: 'error',
        });
        return false;
      });

  const { handleSubmit, handleChange, formData, isSubmitting, isDirty } = useForm<MarketingForm>({
    initialValues: marketingPreference.reduce((acc, item) => {
      acc[item.channel] = item;
      return acc;

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as MarketingForm),
    objKey: 'canMarket',
    onSubmit: updatePreferences,
  });

  const SubmitButton = () =>
    isDirty ? <CommonButton text="Apply changes" type="submit" loading={isSubmitting} /> : null;

  return (
    <>
      <Grid className={classes.gridContainer} container spacing={2}>
        <Grid item container spacing={2} direction="column">
          {autoPolicies
            .filter((policy) => policy.futureRenewalEffectiveDate === null)
            .map((policy) => (
              <MotorRenewalNotification policy={policy} key={policy.policyNumber} />
            ))}
          {homePolicies
            .filter((policy) => policy.futureRenewalEffectiveDate === null)
            .map((policy) => (
              <HomeRenewalNotification policy={policy} key={policy.policyNumber} />
            ))}
        </Grid>
      </Grid>
      <form className={classes.root} onSubmit={handleSubmit} data-testid="communication_tab">
        <Grid container direction="row" className="header">
          {comSubtitles.map((item, index) => {
            return <Typography key={index}>{item}</Typography>;
          })}
        </Grid>

        <Grid container item xs md={6}>
          <CommonContainer className="card" color="secondary" variant="outlined">
            <Typography className="card-title" variant="h4">
              {t('communication.marketingSectionTitle')}
            </Typography>

            <Grid container item justifyContent="space-between">
              {Object.entries(formData).map(([key, { canMarket, channel }]) => (
                <Grid item key={key} md={3} xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={canMarket} onChange={handleChange(channel)} />}
                    label={t(`communication.${channel}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </CommonContainer>
          <Grid className="button">
            <SubmitButton />
          </Grid>
        </Grid>
      </form>
    </>
  );
});
