import { Theme, makeStyles } from '@esure-cloud/react-components';

import { LinkProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { getDeviceSession } from '../../service/analytics';
import { useSegment } from '../../service/util/customHooks/useSegment';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  link: {
    color: ({ color }) => (color === 'secondary' ? theme.palette.secondary.main : color),
    fontFamily: 'Nunito, sans-serif',
    fontSize: '1rem',
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '1.75rem',
    textDecoration: 'none',
    textRendering: 'optimizeLegibility',
    textTransform: 'none',
  },
}));

export interface InternalLinkProps {
  className?: LinkProps['className'];
  color?: string;
  id?: string;
  newTab?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  title: string;
  url: string;
}

export const InternalLink: React.FC<InternalLinkProps> = ({
  url,
  id,
  title,
  color = 'white',
  className,
  newTab = false,
  onClick,
}) => {
  const { eventTrack } = useSegment();

  const classes = useStyles({ color });

  const handleClick = () => {
    eventTrack('Link Clicked', {
      componentID: id,
      label: title,
      sessionId: getDeviceSession(),
      url: window.location.href,
    });
  };
  return (
    <Link
      id={id}
      to={{ pathname: url }}
      target={newTab ? '_target' : '_self'}
      className={clsx(className, classes.link)}
      onClick={(e) => {
        if (onClick !== undefined) onClick(e);
        handleClick();
      }}
    >
      {title}
    </Link>
  );
};
