import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { DateTime as DateTimeLuxon } from 'luxon';
import { Instance } from 'mobx-state-tree';

import { apiURL } from '../../config';
import { LOGIN_TYPE } from '../../constant';
import {
  IAutoPolicy,
  IAutoPolicyRenewalQuoteDetails,
  IClaims,
  IInsuredAddress,
  IPayments,
  IPolicyDocument,
} from '../../state/models/dashboard/autoPolicyModel';
import {
  ICustomer,
  ICustomerEmail,
  ICustomerLegitimateInterestMarketingPermissions,
} from '../../state/models/dashboard/customer';
import { IHomePolicy, IHomePolicyRenewalQuoteDetails } from '../../state/models/dashboard/homePolicyModel';
import { ILookupItem } from '../../state/models/dashboard/lookups';
import { IPaymentMethods } from '../../state/models/dashboard/paymentMethods';
import { IQuote } from '../../state/models/mta';
import { IPendedMTA } from '../../state/models/mta/pendedMTA';
import { RenewalQuotePayload } from '../../state/models/renewalChange/renewalDetails';
import { DateTime } from '../../state/types/dateTime';
import { getBlackBox } from '../../util/iovation';
import { APIResponse } from '../types';

export interface UpdateCustomer {
  customer: ICustomerEmail;
  userId: string;
}

export interface ResDocuments {
  policyDocuments: IPolicyDocument[];
}
export interface ResRenewalDocuments extends ResDocuments {
  entityType?: string;
}

export interface ResAccessToken {
  accessToken: string;
  expiresIn: number;
}

export interface AccessToken {
  blackbox?: string;
  code: string;
  loginType: LOGIN_TYPE;
  uid: string;
}

export interface ResponseLookup {
  lookupElements: ILookupItem[];
  lookupName: string;
}

export interface ResponseVehicleLookup {
  bodyStyle: string;
  bodyStyleCode: string;
  engineCapacity: number;
  fuelType: string;
  fuelTypeCode: string;
  make: string;
  manufactureYear: string;
  model: string;
  numberOfDoors: number;
  numberOfSeats: number;
  transmission: string;
  transmissionCode: string;
  vrm: string;
}

export interface UpdateAutoRenewalResponse {
  success: boolean;
}

const {
  address,
  policies,
  customerByNumber,
  documentsByPolicy,
  lookupDecode,
  vehicleLookup,
  lookup,
  customerAuth,
  payments,
  motorPolicyRenewal,
  homePolicyRenewal,
  customerMta,
  homePolicy,
  motorPolicy,
} = apiURL;

type APIRes<T> = AxiosPromise<APIResponse<T>>;
type ApiFn<T = never> = (
  param: string,
  config?: AxiosRequestConfig,
  fromDate?: Instance<typeof DateTime> | null,
  entityType?: string,
) => APIRes<T>;

export const getPolicies: ApiFn<IAutoPolicy | IHomePolicy> = (_, config) => axios.get(policies, config);

export const getMotorPolicy: ApiFn<IAutoPolicy> = (policyNumber, config) => {
  return axios.get(`${motorPolicy}/${policyNumber}`, config);
};

export const getHomePolicy: ApiFn<IHomePolicy> = (policyNumber, config, policyEffectiveDate) => {
  let params = '';
  if (policyEffectiveDate !== undefined && policyEffectiveDate !== null) {
    params = `?effectiveDate=${policyEffectiveDate.toString()}`;
  }
  return axios.get(`${homePolicy}/${policyNumber}${params}`, config);
};

export const getPolicyDocuments: ApiFn<ResDocuments> = (policyNumber, config, policyEffectiveDate) => {
  let params = '';
  if (policyEffectiveDate !== undefined && policyEffectiveDate !== null) {
    const utcDate = new Date(
      Date.UTC(policyEffectiveDate.year, policyEffectiveDate.month - 1, policyEffectiveDate.day),
    );
    const isoDateString = utcDate.toISOString();

    params = `?policyEffectiveDate=${isoDateString}`;
  }
  return axios.get(`${documentsByPolicy}/${policyNumber}/documents${params}`, config);
};

export const getRenewalPolicyDocuments: ApiFn<ResRenewalDocuments> = (
  policyNumber,
  config,
  policyEffectiveDate,
  entityType = 'Quote',
) => {
  let params = '';
  if (policyEffectiveDate !== undefined && policyEffectiveDate !== null) {
    const utcDate = new Date(
      Date.UTC(policyEffectiveDate.year, policyEffectiveDate.month - 1, policyEffectiveDate.day),
    );
    const isoDateString = utcDate.toISOString();

    params = `?policyEffectiveDate=${isoDateString}&entityType=${entityType}`;
  }
  return axios.get(`${documentsByPolicy}/${policyNumber}/documents${params}`, config);
};

export const getRenewalDetails: ApiFn<IAutoPolicy> = (policyNumber, config, date) => {
  let dateParam: DateTimeLuxon;
  if (date) {
    dateParam = DateTimeLuxon.utc(date.year, date.month, date.day);
  } else {
    dateParam = DateTimeLuxon.now().toUTC();
  }

  const isoDateString = dateParam.toISO();
  return axios.get(`${policies}/${policyNumber}${apiURL.renewalSuffix}?effectiveDate=${isoDateString}`, config);
};

export const getMotorPolicyRenewalQuoteDetails: ApiFn<IAutoPolicyRenewalQuoteDetails> = (policyNumber, config) => {
  return axios.get(`${motorPolicyRenewal}/${policyNumber}`, config);
};

export const postMotorRenewalQuoteDetails = (
  policyNumber: string,
  config: AxiosRequestConfig,
  data: RenewalQuotePayload,
): AxiosPromise<APIResponse<IAutoPolicyRenewalQuoteDetails>> => {
  return getBlackBox().then((blackbox) =>
    axios.post(
      `${motorPolicyRenewal}/${policyNumber}`,
      { ...data, deviceCheck: { ...data.deviceCheck, blackbox: blackbox } },
      config,
    ),
  );
};

export const getHomePolicyRenewalQuoteDetails: ApiFn<IHomePolicyRenewalQuoteDetails> = (policyNumber, config) => {
  return axios.get(`${homePolicyRenewal}/${policyNumber}`, config);
};

export const getPolicyRenewalStatus = (
  policyNumber: string,
  config: AxiosRequestConfig<unknown> | undefined,
): AxiosPromise<APIResponse<string>> => {
  return axios.get(`${apiURL.policies}/${policyNumber}/auto-renew-status`, config);
};

export const getCustomerDetails: ApiFn<ICustomer> = (customerNumber, config) => {
  return axios.get(`${customerByNumber}/${customerNumber}`, config);
};

export const authenticateCustomer = (email: string): AxiosPromise => {
  const data = { email, loginType: LOGIN_TYPE.LOGIN };
  return axios.post(`${customerAuth}/login`, data);
};

export const updatedCustomerAccount = (data: UpdateCustomer, config: AxiosRequestConfig): AxiosPromise => {
  const { userId, customer } = data;
  return axios.put(`${customerByNumber}/${userId}/emails/${customer.id}`, customer, config);
};

export const getDecodedLookups = (data: unknown, config: AxiosRequestConfig): AxiosPromise<ILookupItem[]> => {
  return axios.post(lookupDecode, data, config);
};

export const getLookups = (data: unknown, config: AxiosRequestConfig): Promise<ResponseLookup> => {
  return axios.post(lookup, data, config).then((res) => res.data as ResponseLookup);
};

export const getClaims: ApiFn<IClaims> = (policyNumber, config) => {
  return axios.get(`${motorPolicy}/${policyNumber}/claims`, config);
};

export const updatedMarketingPermissions = (
  data: unknown,
  customerId: string,
  config: AxiosRequestConfig,
): Promise<ICustomerLegitimateInterestMarketingPermissions[]> => {
  return axios
    .put(`${customerByNumber}/${customerId}/legitimate-interest-marketing-permissions`, data, config)
    .then((res) => res.data as ICustomerLegitimateInterestMarketingPermissions[]);
};

export const getAccessToken = (data: AccessToken): APIRes<ResAccessToken> => {
  return getBlackBox().then((blackbox) => axios.post(`${customerAuth}/token`, { ...data, blackbox }));
};

export const getPolicyTransactions: ApiFn<IPayments> = (policyNumber, config, effectiveDate) => {
  let params = '';
  if (effectiveDate) {
    const utcDate = new Date(Date.UTC(effectiveDate.year, effectiveDate.month - 1, effectiveDate.day));
    const isoDateString = utcDate.toISOString();

    params = `?effectiveDate=${isoDateString}`;
  }

  return axios.get(`${payments}/${policyNumber}/payments${params}`, config);
};

export const getPaymentMethods = (config: AxiosRequestConfig): AxiosPromise<APIResponse<IPaymentMethods>> => {
  return axios.get(`${payments}/payments-methods`, config);
};

export const getAddress = (config: AxiosRequestConfig): Promise<APIResponse<IInsuredAddress>['results']> => {
  return axios.get<APIResponse<IInsuredAddress>>(address, config).then((res) => res.data.results);
};

export const getVehicleLookup: ApiFn<ResponseVehicleLookup> = (vrn, config) => {
  return axios.get(`${vehicleLookup}?vehicleRegistration=${vrn}`, config);
};

export const updateAutoRenewal = (
  policyNumber: string,
  config: AxiosRequestConfig,
): AxiosPromise<APIResponse<UpdateAutoRenewalResponse>> => {
  return axios.post(`${policies}/${policyNumber}/auto-renew`, {}, config);
};

export const getQuote = (
  data: IPendedMTA,
  config: AxiosRequestConfig,
  policyNumber: string,
): AxiosPromise<APIResponse<IQuote>> => {
  return getBlackBox().then((blackbox) =>
    axios.post(
      `${customerMta}/${policyNumber}`,
      { ...data, deviceCheck: { ...data.deviceCheck, blackbox: blackbox } },
      config,
    ),
  );
};

export const getPendedMtaQuote = (
  config: AxiosRequestConfig,
  policyNumber: string,
): AxiosPromise<APIResponse<IQuote>> => {
  return axios.get(`${customerMta}/${policyNumber}/charges`, config);
};
