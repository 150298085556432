import { Divider, Grid, makeStyles } from '@esure-cloud/react-components';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../service/state/store';
import IconSure from '../../common/icon';
import { PolicyStatus } from '../../common/policyStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ncd': {
      '&__divider': {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
      },
      '&__heading ': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(5),
      },
      '&__icon': {
        marginRight: theme.spacing(0.5),
      },
      '&__list': {
        marginLeft: theme.spacing(-0.5),
      },
      '&__number-of-years': {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
      },
      '&__title': {
        marginTop: theme.spacing(3),
      },
    },
    '& h5': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const NCDDrawer: React.FC = () => {
  const classes = useStyles();
  const {
    dashboardStore: {
      insurance: {
        auto: {
          mainVehicle,
          policyCover: { ncdpPack },
        },
      },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');

  const combinedColumns = [
    ...t('ifYouClaim.ncdProtection.notProtected.firstColumn', { returnObjects: true }),
    ...t('ifYouClaim.ncdProtection.notProtected.secondColumn', { returnObjects: true }),
  ];

  return (
    <Grid container className={classes.root}>
      <Grid>
        <Typography className="ncd__heading" variant="h3">
          {t('ifYouClaim.ncdProtection.protected.title')}
        </Typography>
        <PolicyStatus
          label={ncdpPack ? t('policyStatus.protected') : t('policyStatus.notProtected')}
          status={ncdpPack ? 'protected' : 'not-protected'}
        />
        <Typography className="ncd__number-of-years">
          {t('policyDetailsSection.yearNCD', { value: mainVehicle.ncdYears })}
        </Typography>
      </Grid>

      <Grid className="ncd__list">
        {ncdpPack ? (
          <Grid>
            <Typography variant="h5">{t('ifYouClaim.ncdProtection.protected.title')}</Typography>
            <Grid container>
              <Grid item xs={1}>
                <IconSure className="ncd__icon" icon="check" color="success" />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2">
                  {t('ifYouClaim.ncdProtection.protected.protectedList', { returnObjects: true })}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="ncd__divider" variant="middle" />
            <Typography variant="h5">{t('common.notIncluded')}</Typography>
            <Grid container>
              <Grid item xs={1}>
                <IconSure className="ncd__icon" icon="times" color="error" />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">
                  {t('ifYouClaim.ncdProtection.protected.notIncluded.notIncludedList', { returnObjects: true })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography variant="h5">{t('ifYouClaim.ncdProtection.notProtected.title')}</Typography>
            {combinedColumns.map((exception, i) => (
              <Grid container key={i}>
                <IconSure className="ncd__icon" icon="check" color="success" />
                <Typography variant="body1">{exception}</Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
