import { Grid, Link } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PRODUCT_TYPE } from '../../../service/constant';
import { IPolicyDocument } from '../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../service/state/store';
import { useSegment } from '../../../service/util/customHooks/useSegment';
import IconSure from '../icon';
import { downloadDocument } from '../utils/documentds';

import { CommonButton } from './commonButton';

export interface DownloadButtonProps {
  buttonName: string;
  documentTypeCode: string;
  isButton?: boolean;
}
export const DownloadInsuranceDocument: React.FC<DownloadButtonProps> = observer(function DownloadInsuranceDocument({
  documentTypeCode,
  buttonName,
  isButton,
}) {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
        home: { policySelected: homePolicySelected },
        selectedPolicyType,
      },
      fetchPolicyDocuments,
    },
    interfaceStore: { notification, isCaura },
    getDocumentsHeader,
  } = useStores();
  const isHome = selectedPolicyType === 'home';
  const [documents, setDocuments] = useState<IPolicyDocument[]>(
    isHome ? homePolicySelected.documents : policySelected.documents,
  );
  const { eventTrack } = useSegment();

  const { futureMtaEffectiveDate } = policySelected;

  const findSpecificDocument = () => {
    const result: IPolicyDocument | undefined = documents.find((item) => item.documentTypeCode === documentTypeCode);
    if (result !== undefined) {
      const { documentId, fileName } = result;

      downloadDocument({
        documentId: documentId,
        eventTrack: eventTrack,
        header: getDocumentsHeader(),
        headerText: fileName,
        isCaura,
        onFail: () => {
          notification.openNotification({
            message: t('downloadFailed.content'),
            severity: 'error',
          });
        },
        policyNumber: isHome ? homePolicySelected.policyNumber : policySelected.policyNumber,
      });
    } else {
      notification.openNotification({
        message: t('documentNotFound.content'),
        severity: 'error',
      });
    }
  };

  const handleDownloadDocument = () => {
    if (!policySelected.documents.length) {
      fetchPolicyDocuments(
        isHome ? homePolicySelected.policyNumber : policySelected.policyNumber,
        futureMtaEffectiveDate,
      ).then((res) => {
        if (res?.status !== 200) {
          notification.openNotification({
            message: t('docsFailedLoading.title'),
            severity: 'error',
          });
        } else {
          setDocuments(policySelected.documents);
          findSpecificDocument();
        }
      });
    } else {
      findSpecificDocument();
    }
  };
  return (
    <>
      {isButton ? (
        <CommonButton
          colorIcon="black"
          variant="outlined"
          color="secondary"
          startIcon="download"
          size="large"
          text={
            homePolicySelected.productType === PRODUCT_TYPE.CORE
              ? t('homeCoverages.bookletButton')
              : t('homeCoverages.bookletButtonFlex')
          }
          type="button"
          onClick={handleDownloadDocument}
        />
      ) : (
        <Grid
          container
          item
          onClick={handleDownloadDocument}
          alignItems="center"
          data-testid="download-document-container"
        >
          <IconSure icon="download" color="secondary" size="20" />
          <Link variant="body2">{buttonName}</Link>
        </Grid>
      )}
    </>
  );
});
