import { DateTime as DateTimeLuxon } from 'luxon';
import { Instance, types } from 'mobx-state-tree';

import { CHANNEL_TYPE } from '../../../constant';
import { DateTime } from '../../types/dateTime';

const string = types.optional(types.string, '');
const channelList = Object.values(CHANNEL_TYPE);

export const CustomerDetail = types.model('CustomerDetail', {
  dateOfBirth: string,
  firstName: string,
  lastName: string,
});

export const CustomerEmail = types.model('CustomerEmail', {
  email: string,
  emailConfirmed: types.optional(types.boolean, false),
  id: string,
});

export const CustomerPhone = types.model('CustomerPhone', {
  countryCode: string,
  phoneConfirmed: types.optional(types.boolean, false),
  phoneNumber: string,
  phoneType: string,
});

export const CustomerAddress = types.model('CustomerAddress', {
  country: string,
  houseName: string,
  houseNumber: string,
  postalTown: string,
  postcode: string,
});

export const CustomerMarketingPermissions = types.model('CustomerMarketingPermissions', {
  canMarket: types.boolean,
  channel: types.enumeration<CHANNEL_TYPE>(channelList),
  source: types.maybeNull(string),
  updated: types.maybeNull(string),
});

export const CustomerLegitimateInterestMarketingPermissions = types.model(
  'CustomerLegitimateInterestMarketingPermissions',
  {
    brand: string,
    customerMarketingPermissions: types.array(CustomerMarketingPermissions),
    id: string,
  },
);

export const CustomerBar = types.model('CustomerBar', {
  barType: types.string,
  endDate: DateTime,
});
export const Customer = types
  .model('Customer', {
    customerAddress: types.optional(CustomerAddress, () => CustomerAddress.create()),
    customerBars: types.array(CustomerBar),
    customerEmails: types.optional(types.array(CustomerEmail), () => []),
    customerIndividualDetail: types.optional(CustomerDetail, () => CustomerDetail.create()),
    customerLegitimateInterestMarketingPermissions: types.array(CustomerLegitimateInterestMarketingPermissions),
    customerNumber: string,
    customerPhones: types.optional(types.array(CustomerPhone), () => []),
  })
  .views((self) => ({
    get emailInfo(): ICustomerEmail {
      return self.customerEmails.length ? self.customerEmails[0] : CustomerEmail.create();
    },
    get fullName(): string {
      const {
        customerIndividualDetail: { firstName, lastName },
      } = self;

      return `${firstName} ${lastName}`;
    },
    get marketingPreference(): ICustomerMarketingPermissions[] {
      const { customerLegitimateInterestMarketingPermissions: preferences } = self;

      return preferences.length
        ? preferences[0].customerMarketingPermissions
        : channelList.map((channel) => ({
            canMarket: false,
            channel,
            source: 'CONTACT_CENTRE',
            updated: null,
          }));
    },
    get phoneNumber(): string {
      return self.customerPhones.length ? self.customerPhones[0].phoneNumber : '';
    },
    get underwritingInvestigationBar(): ICustomerBar | undefined {
      return self.customerBars.find(
        (item) => item.barType === 'UW_INV' && item.endDate && item.endDate > DateTimeLuxon.now(),
      );
    },
  }));

export type ICustomer = Instance<typeof Customer>;
export type ICustomerDetail = Instance<typeof CustomerDetail>;
export type ICustomerEmail = Instance<typeof CustomerEmail>;
export type ICustomerPhone = Instance<typeof CustomerPhone>;
export type ICustomerMarketingPermissions = Instance<typeof CustomerMarketingPermissions>;
export type ICustomerLegitimateInterestMarketingPermissions = Instance<
  typeof CustomerLegitimateInterestMarketingPermissions
>;
export type ICustomerBar = Instance<typeof CustomerBar>;
