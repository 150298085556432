import { Paper, PaperProps, Typography, makeStyles } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

export interface PlaceholderBlockProps {
  children?: PaperProps['children'];
  name?: string;
  style?: PaperProps['style'];
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ph': {
      backgroundColor: '#0971b0',
      borderRadius: theme.shape.borderRadius,
      color: '#ffffff',
      fontSize: '0.6rem',
      fontWeight: 'bold',
      padding: '0px 4px',
      position: 'absolute',
      top: -2,
    },
    '& .phleft': {
      left: -2,
    },
    '& .phright': {
      right: -2,
    },
    border: 'solid 2px #0971b0',
    borderRadius: 0,
    minHeight: '100%',
    padding: theme.spacing(3),
    position: 'relative',
  },
}));

export const PlaceholderBlock: React.FC<PlaceholderBlockProps> = ({ children, name, style }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <Paper classes={classes} style={style}>
      <Typography className="ph phleft" variant="caption">
        {t('placeholder')}
      </Typography>
      {name && (
        <Typography className="ph phright" variant="caption">
          {name}
        </Typography>
      )}
      {children}
    </Paper>
  );
};
