import { persist } from 'mst-persist';
import { createContext, useContext } from 'react';

import config from '../../config';
import { RootStore } from '../models';

// create the root store
const rootStore = RootStore.create();

// create a context to allow store access via a custom hook
const storeContext = createContext(rootStore);

export const PERSISTENCE_NAME_INTERFACE_STORE = `${config.localStateKey}_if`;

// tell sessionStorage to persist our store data
Promise.all([
  // persist customer store
  persist(`${config.localStateKey}_data`, rootStore.dashboardStore),
  // persist interface store
  persist(PERSISTENCE_NAME_INTERFACE_STORE, rootStore.interfaceStore),
  // persist mta store
  persist(`${config.localStateKey}_mta`, rootStore.mtaStore),
  // persist renewal change store
  persist(`${config.localStateKey}_renewalchange`, rootStore.renewalChangeStore),
]).then(() => {
  // all stores have been registered for persist and recovered
  // update interface hydrate attempt indicator
  rootStore.interfaceStore.setIsHydrateAttempted(true);
});

// export our custom hook which will grant access to our stores
export const useStores = (): typeof rootStore => useContext(storeContext);
