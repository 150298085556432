import { Link, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { COMPONENT_ID } from '../../../../../../../service/analytics';
import { ROUTE } from '../../../../../../../service/constant';
import { useStores } from '../../../../../../../service/state/store';
import { removeCompletedJourneyByTitle } from '../../../../../../../service/util/multiMTAUtil';
import { ItemList, NavigationLayout } from '../common/components/navigationLayout';
import { useStepperContext } from '../common/utils/stepperContext';

export const Drivers: React.FC = observer(function Drivers() {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
    mtaStore: { completedMTAJourneys },
  } = useStores();
  const history = useHistory();

  const cards: ItemList[] = t('driverDetails.cards', { returnObjects: true });

  const filteredCards: ItemList[] = cards.filter((card) => Number(card.length) !== policySelected.drivers.length);

  const { isMultiMTA } = useStepperContext();

  if (isMultiMTA) removeCompletedJourneyByTitle(filteredCards, completedMTAJourneys);

  const handleClick = () => {
    history.push(ROUTE.MAKE_CHANGE);
  };

  const DriversSubtitle = () => {
    return (
      <Typography variant="body1" className="subtitle">
        <span>{t('driverDetails.subtitle1')}</span>
        <Link data-testid={COMPONENT_ID.DRIVERS_PAGE_BACK_A_STEP_LINK} onClick={handleClick}>
          {t('driverDetails.link')}
        </Link>
        <span
          dangerouslySetInnerHTML={{
            __html: t('driverDetails.subtitle2'),
          }}
        />
      </Typography>
    );
  };

  return (
    <NavigationLayout
      mtaType="changeDrivers"
      cards={filteredCards}
      banner={t('driverDetails.banner')}
      title={t('driverDetails.title')}
      subtitle={<DriversSubtitle />}
      section={t('policyDetailsSection.drivers')}
    />
  );
});
