import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { BUTTON_ORIGIN } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { CloseBtn } from '../../common/button/closeBtn';
import { ContinueBtn } from '../../common/button/continueBtn';

import { DialogTemplate } from './dialogTemplate';

export const TIAPolicyDialog: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: { isDesktop },
  } = useStores();

  return (
    <DialogTemplate
      topImage={<SVGWrapper alt="icon" height="100%" width={isDesktop ? '50%' : '100%'} src="multi-car.svg" />}
      title={t('makingImprovementsDialog.title')}
      mainContent={t('makingImprovementsDialog.body')}
      buttons={[<CloseBtn />, <ContinueBtn origin={BUTTON_ORIGIN.TIA_POLICY} />]}
      testid={BUTTON_ORIGIN.TIA_POLICY}
    />
  );
});
