import { Badge, Grid, GridProps, Link, makeStyles } from '@esure-cloud/react-components/lib/cjs/component';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../service/analytics';
import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';

import { CommonButton } from './button/commonButton';
import IconSure from './icon';
import { Logo } from './logo';
import { PopupTooltip } from './popupTooltip';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    '& .navbar': {
      '& nav, &-link': {
        '& .navbar-link': {
          gap: 5,
          whiteSpace: 'nowrap',
        },
        alignItems: 'center',
        display: 'flex',
        [breakpoints.down('xs')]: {
          gap: 8,
        },
        [breakpoints.up('sm')]: {
          gap: 25,
        },
      },
      '&.active': {
        background: palette.common.white,
        boxShadow: '0 4px 40px -15px rgba(0,0,0, 0.15)',
        height: 70,
      },
      background: 'transparent',
      height: 130,
      position: 'fixed',
      transition: 'all .4s ease-in-out',
      zIndex: 99,
    },
    '& .noWrap': {
      whiteSpace: 'nowrap',
    },
    '& .wrapper': {
      margin: '0 auto',
      maxWidth: 1200,
      padding: spacing(0, 2),
    },
    '& a': {
      color: palette.secondary.main,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

export interface NavList {
  hasTooltip?: boolean;
  icon: string;
  onClick?: () => void;
  title: string;
  tooltipText?: string;
}

export interface EsureLayoutNavbarProps extends GridProps {
  handleLogin?: () => void;
  hasChatBotTooltip?: boolean;
  hasNeedHelp: boolean;
  loading?: boolean;
  position: string;
}

export const EsureLayoutNavbar: React.FC<EsureLayoutNavbarProps> = observer(function EsureLayoutNavbar({
  loading,
  className,
  handleLogin,
  hasNeedHelp,
  position,
  hasChatBotTooltip = false,
}) {
  const classes = useStyles();
  const {
    interfaceStore: { isMobile, chatBot },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();
  const [scrollY, setScrollY] = useState(0);

  const updateScrollValue = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollValue);
    return () => window.removeEventListener('scroll', updateScrollValue);
  }, []);

  const handleHelp = () => {
    eventTrack('Need Help Button Clicked', { position: `${position} Page`, type: TRACK_TYPE.CHAT });
    chatBot.startChat({ initiator: `${position} Help Link` });
  };

  const navigation: NavList[] = [
    {
      hasTooltip: hasChatBotTooltip,
      icon: 'chat',
      onClick: handleHelp,
      title: isMobile ? 'Help' : 'Need help?',
      tooltipText: t('appbar.needHelpTooltip'),
    },
  ];

  return (
    <Grid className={clsx(classes.root, className)}>
      <Grid container className={clsx(scrollY >= 70 && 'active', 'navbar')}>
        <Grid container alignItems="center" justifyContent="space-between" className="wrapper">
          <Logo size={isMobile ? '60' : '90'} color="secondary" />

          <nav>
            {hasNeedHelp &&
              navigation.map(({ onClick, title, icon, hasTooltip, tooltipText }, i) =>
                hasTooltip ? (
                  <PopupTooltip
                    key={i}
                    alignment="center"
                    anchorEl={
                      <span>
                        <Link key={i} underline="none" onClick={onClick} className="navbar-link">
                          <Badge color="error" variant="dot">
                            <IconSure icon={icon} type="solid" color="secondary" />
                          </Badge>
                          {title}
                        </Link>
                      </span>
                    }
                    arrow="right"
                    color="secondary"
                    hideClose
                    permanent={true}
                    placement="bottom-end"
                    size="small"
                    title={tooltipText ?? ''}
                  />
                ) : (
                  <Link key={i} underline="none" onClick={onClick} className="navbar-link">
                    <IconSure icon={icon} type="solid" color="secondary" />
                    {title}
                  </Link>
                ),
              )}

            {handleLogin && (
              <CommonButton
                data-testid={COMPONENT_ID.LOGIN_BUTTON_NAV_BAR}
                loading={loading}
                iconType="solid"
                endIcon="account-circle"
                size="large"
                text="Log in"
                onClick={handleLogin}
              />
            )}
          </nav>
        </Grid>
      </Grid>
    </Grid>
  );
});
