import { CommonContainer, Grid, Typography } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IconSure from '../../../../../component/common/icon';
import { DoTodayCTASection } from '../../../../../component/layout/DoTodayCTASection';
import {
  CoverInfo,
  ItemList,
  PolicyButtons,
  PolicyInfo,
  PolicyRenewalInfo,
  policyDetailUseStyles,
} from '../../../../../component/layout/motorPolicyDetailsSection';
import { useCommonStylesPolicyCard } from '../../../../../component/layout/policyCards';
import { PolicyDetailsNavCards } from '../../../../../component/layout/policyDetailsNavCards';
import { DATE_TYPES, LOOKUP, PANEL_TYPE, ROUTE } from '../../../../../service/constant';
import { useStores } from '../../../../../service/state/store';
import { useDecodedLookupsHome } from '../../../../../service/util/customHooks/useDecodedLookups';
import { formatDate } from '../../../../../service/util/formatDate';
import SelfService from '../../../../../service/util/selfService';

export const HomePolicyDetailsSection: React.FC = observer(function HomePolicyDetailsSection() {
  const { t } = useTranslation('myAccount');
  const classes = policyDetailUseStyles();
  const policyCard = useCommonStylesPolicyCard();

  const {
    dashboardStore: {
      customer: { underwritingInvestigationBar },
      insurance: {
        home: { policySelected },
      },
      lookups: { getLookupValue },
    },
    interfaceStore: {
      hasFeatureFlag,
      panel: { openPanel },
    },
  } = useStores();

  const history = useHistory();

  const { policyNumber, effectiveDate, expirationDate, coverTypeCd, autoRenewal, homeAddress, policyStatus } =
    policySelected;

  const cards: ItemList[] = t('policyDetailsSection.homeCards', { returnObjects: true });

  const filteredExpiredPolicyCards: ItemList[] = cards.filter((card) => card.title !== "What's included");

  const { isDecodedLookupsFetched } = useDecodedLookupsHome();

  const isPolicyExpired = SelfService.isPolicyExpired(policyStatus);

  return (
    <>
      {hasFeatureFlag('showDoTodayCTA') && !isPolicyExpired && <DoTodayCTASection policy={policySelected} />}
      <Grid className={classes.gridContainer} container>
        <PolicyInfo policyNo={policyNumber} t={t} />

        <Grid container spacing={2}>
          {isDecodedLookupsFetched && (
            <CoverInfo
              startDate={formatDate(effectiveDate?.toString(), DATE_TYPES.SHORT_SPACE)}
              endDate={formatDate(
                SelfService.calculatePolicyEndDate(expirationDate).toString(),
                DATE_TYPES.SHORT_SPACE,
              )}
              coverType={getLookupValue(LOOKUP.HOME_COVER_TYPE, coverTypeCd)}
              policyStatus={policyStatus}
            />
          )}

          <Grid item xs={12} sm={6} lg={4}>
            <CommonContainer
              className={clsx(classes.cards, policyCard.root, isPolicyExpired ? policyStatus : '')}
              color="secondary"
              variant="outlined"
              onClick={() => openPanel({ title: 'Your home', type: PANEL_TYPE.YOUR_HOME })}
            >
              <Grid className={classes.cardButton} data-testid="your-home-card">
                <Grid container justifyContent="space-between">
                  <Typography variant="body2">{t('homeAddress.cardTitle')}</Typography>
                  <Grid item>
                    <IconSure color="secondary" icon="chevron-right" />
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="flex-start">
                  <Typography variant="h4">{SelfService.formatAddress(homeAddress).full}</Typography>
                </Grid>
                <Grid container direction="column" alignItems="flex-start">
                  <Typography variant="body1" color="secondary">
                    {t('homeAddress.details')}
                  </Typography>
                </Grid>
              </Grid>
            </CommonContainer>
          </Grid>

          <PolicyRenewalInfo
            autoRenewal={autoRenewal}
            t={t}
            onClick={() => {
              if (isPolicyExpired) return;

              if (policySelected.renewalStatusRequestPending) {
                history.push(ROUTE.RENEWAL_CHANGE_PENDING);
              } else {
                history.push(ROUTE.YOUR_RENEWAL_DETAILS);
              }
            }}
            policy={policySelected}
          />

          <PolicyButtons policy={policySelected} isCustomerBarred={underwritingInvestigationBar} />
        </Grid>

        <PolicyDetailsNavCards cards={isPolicyExpired ? filteredExpiredPolicyCards : cards} />
      </Grid>
    </>
  );
});
