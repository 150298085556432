import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../service/state/store';
import { sortInPredefinedOrder } from '../../service/util/customSort';
import { PolicyLimits } from '../../view/pages/policies/policy/policyDetails/ifYouClaim/policyLimits';
import { ContainerMultipleLines, PolicyData, PolicyExtras } from '../common/containerMultipleLines';

interface CoveredItem {
  [paramName: string]: string;
}

export const HowMuchYouAreCovered: React.FC = observer(function HowMuchYouAreCovered() {
  const { t } = useTranslation('myAccount');
  const coveredElements: CoveredItem = t('howMuchYouAreCovered.subCoverages', { returnObjects: true });
  const [policyData, setPolicyData] = useState<PolicyData>({ title: t('howMuchYouAreCovered.title') });

  const {
    dashboardStore: {
      setSubCoverages,
      insurance: {
        auto: { mainPolicy, mainVehicle },
      },
    },
  } = useStores();

  useEffect(() => {
    const subCoverages = toJS(mainVehicle.subCoverages);

    const detailsList = ['ad_personalAccident', 'ad_personalAccidentPerPeriod'];
    const policyExtras = subCoverages.reduce((extras: PolicyExtras[], curr) => {
      const { coverageCd, deductibleAmount, limitAmount, limitUnit } = curr;

      if (coverageCd) {
        const details = detailsList.includes(coverageCd) ? t('howMuchYouAreCovered.upTo') : '';
        const coveredItem = coveredElements[coverageCd];

        const extraItem = {
          amount: 0,
          code: coverageCd,
          details,
          item: coveredItem,
        };

        if (coverageCd === 'windscreen_replacement' && deductibleAmount) {
          extraItem.amount = deductibleAmount;
          extras.push(extraItem);
        }

        if (limitAmount && coveredItem) {
          extraItem.amount = limitAmount;
          extras.push(extraItem);
        } else if (limitUnit && mainPolicy.migrated && coveredItem) {
          extraItem.amount = parseInt(limitUnit);
          extras.push(extraItem);
        }
      }
      return extras;
    }, []);

    const sortedPolicyExtras = sortInPredefinedOrder(Object.values(coveredElements), policyExtras);
    setPolicyData({ ...policyData, items: sortedPolicyExtras });
    setSubCoverages({ policyExtras: sortedPolicyExtras });
  }, [mainPolicy]);

  return (
    <>
      <ContainerMultipleLines dataPolicy={policyData} />
      <PolicyLimits />
    </>
  );
});
