import { Alert, AlertTitle, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import Skeleton from '@material-ui/lab/Skeleton';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BlueBanner } from '../../../../../../component/common/blueBanner';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import CommonAccordion, { CommonAccordionProps } from '../../../../../../component/common/commonAccordion';
import { IconType } from '../../../../../../component/common/icon';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import {
  COVERAGE,
  DATE_TYPES,
  DIALOG_TYPE,
  ENDORSEMENT_REASONS,
  LOOKUP,
  ROUTE,
} from '../../../../../../service/constant';
import { RenewalDriver } from '../../../../../../service/state/models/dashboard/autoPolicyModel';
import { IRenewalDriver } from '../../../../../../service/state/models/renewalChange/renewalDetails';
import { useStores } from '../../../../../../service/state/store';
import { useLookups } from '../../../../../../service/util/customHooks/useLookups';
import { formatDate } from '../../../../../../service/util/formatDate';

import { ClaimComp } from './claimItem';
import { ConvictionComp } from './convictionItem';
import { UsageOfCarAccordion } from './usageOfCarAccordion';
import { YourAdditionalDriverAccordion } from './yourAdditionalDriverAccordion';
import { YourCarAccordion } from './yourCarAccordion';
import { YourDetailsAccordion } from './yourDetailsAccordion';
import { YourExcesses } from './yourExcesses';
import { YourExtraCover } from './yourExtraCover';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  accordionContainer: {
    gap: spacing(1),
    marginTop: spacing(1.5),
  },
  alert: {
    marginTop: spacing(2.5),
  },
  btnSpace: { marginTop: spacing(1.5) },
  gridSpacing: {
    marginBottom: spacing(2),
  },
  recordSpacing: {
    padding: spacing(0.5, 2),
  },
  root: {
    '& .MuiDivider-root': {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    '& .accordionGrid': {
      display: 'grid',
    },
    '& .borderStyle': { margin: spacing(0, 0.7), padding: 0 },
    '& .classNameBorder:nth-child(3), .classNameBorder:nth-child(4)': {
      '& .MuiDivider-root': { margin: spacing(2, 2) },
      '& .accordionContent': { padding: 0 },
    },
    '& .footerClass': { borderTop: 0, margin: spacing(0, 2) },
    '& .multiGridItem': {
      padding: spacing(1, 1.7),
    },
    [breakpoints.up('xs')]: {
      '& .column-no-title': {
        marginTop: 0,
        padding: 0,
      },
    },
  },
  skeletonMargin: {
    margin: spacing(2, 0),
  },
  title: { margin: spacing(2.5, 0) },
  yourDetailContainer: {
    margin: '8px 2px',
  },
}));

export interface ClaimOptions {
  description?: string;
  title?: string;
}

export const addDriverString = (length: number, string: string | IconType): string | undefined => {
  return length < 4 ? string : undefined;
};
export const addDriverIconType = (length: number, string: IconType): IconType | undefined => {
  return length < 4 ? string : undefined;
};

export const YourCoverDetails: React.FC = observer(function YourCoverDetails() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<number | null>(0);

  const {
    interfaceStore: {
      dialog: { openDialog },
    },
    renewalChangeStore: {
      getChangeMade,
      pendingMTAQuoteSelected,
      renewalDetails: {
        insured: { person },
        drivers,
        setSelectedDriverUUID,
        setDrivers,
        vehicle,
        addEndorsementReason,
        vehicleCoverages,
      },
      saveChanges,
      setIsLoading,
      setNextRoute,
    },
    dashboardStore: {
      lookups: { getLookupValue: getLookup },
      insurance: {
        auto: {
          policySelected: {
            policyRenewalQuoteDetails: { startDate },
            policyNumber,
            autoRenewal,
            expirationDate,
          },
        },
      },
    },
  } = useStores();

  const changeMade = getChangeMade(policyNumber);

  useEffect(() => {
    setIsLoading(false);
    setNextRoute('');
  }, []);

  const pageDisplay = changeMade ? ROUTE.YOUR_NEW_QUOTE : ROUTE.YOUR_RENEWAL_SUMMARY;

  const handleClick = () => {
    if (changeMade) {
      saveChanges(policyNumber, true, true);
      openDialog({ isFullScreen: true, type: DIALOG_TYPE.FETCH_QUOTE });
    }
    history.push(pageDisplay);
  };

  const initiateChat = () => {
    openDialog({ type: DIALOG_TYPE.FETCH_ADVISOR_RENEWAL_MTA_UNAVAILABLE_ONLINE });
  };
  const mainDriver = drivers.length > 1 ? drivers.find((driver) => driver.main === true) : drivers[0];
  const policyHolder = drivers.find((driver) => driver.relationshipToProposerCd === 'P');

  if (typeof policyHolder === 'undefined' || typeof policyHolder.claims === 'undefined') {
    // store hasnt updated correctly, or page loaded before store did - go back to quotes page
    history.push(ROUTE.YOUR_RENEWAL_QUOTES);
  }

  const hasAnyClaim = policyHolder && policyHolder.claims.length > 0;
  const hasAnyConviction = policyHolder && policyHolder.convictions.length > 0;
  const findCoverage = (coverage: COVERAGE) => vehicleCoverages.find((x) => x.coverageCd === coverage);

  const makeSelectDriver = () => {
    const updatedDrivers: IRenewalDriver[] = drivers;

    const emptyDriver = RenewalDriver.create({ uuid: `emptyDriver${drivers.length}` });

    setSelectedDriverUUID(emptyDriver.uuid);

    addEndorsementReason(ENDORSEMENT_REASONS.DRIVER_CHANGE);
    setDrivers([...updatedDrivers, emptyDriver]);

    history.push(ROUTE.YOUR_COVER_DETAILS_ADD_DRIVER);
  };

  const [coverType, setCoverType] = useState('');

  const [accordions, setAccordions] = useState<CommonAccordionProps[] | null>(null);

  const { isLookupsFetched } = useLookups({
    lookups: [
      LOOKUP.RESIDENTIAL_STATUS,
      LOOKUP.MARITAL_STATUS,
      LOOKUP.NUMBER_OF_CHILDREN,
      LOOKUP.CARS_IN_HOUSEHOLD,
      LOOKUP.OCCUPATION,
      LOOKUP.LICENSE_HELD_YEARS,
      LOOKUP.LICENSE_HELD_MONTHS,
      LOOKUP.ACCESS_TO_OTHER_CARS,
      LOOKUP.MODIFICATIONS,
      LOOKUP.OWNER,
      LOOKUP.KEEPER,
      LOOKUP.VEHICLE_SECURITY,
      LOOKUP.MILEAGE,
      LOOKUP.USAGE,
      LOOKUP.OVERNIGHT_LOCATION,
      LOOKUP.DAYTIME_LOCATION,
      LOOKUP.OCCUPATION,
      LOOKUP.DRIVER_RELATIONSHIP,
      LOOKUP.CLAIM_TYPE,
      LOOKUP.CONVICTION_CODE,
    ],
  });

  const [lookupsLoading, setLookupsLoading] = useState(true);

  useEffect(() => {
    setLookupsLoading(false);
    setCoverType(getLookup(LOOKUP.COVER_TYPE, vehicle.coverTypeCd));
    setAccordions([
      {
        columns: [
          {
            description: formatDate(startDate?.toString(), DATE_TYPES.WEEKDAY_SHORT),
            subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.date'),
          },
          {
            description: coverType,
            subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.coverType'),
          },
          {
            description: `${person.firstName} ${person.surname}`,
            subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.policyHolder'),
          },
          {
            description: mainDriver ? `${mainDriver.person.firstName} ${mainDriver.person.surname}` : '',
            subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.mainDriver'),
          },
          {
            description: findCoverage(COVERAGE.DRIVING_OTHER_CARS)
              ? t('policyManagement.yourCoverDetails.yourCoverAccordion.yes')
              : t('labels.no'),
            subtitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.otherCars'),
          },
        ],
        headerTitle: t('policyManagement.yourCoverDetails.yourCoverAccordion.title'),
      },
      {
        columns: [{ subtitle: t('policyManagement.yourCoverDetails.insuranceHistory.details') }],
        eventId: COMPONENT_ID.RENEWAL_UPDATE_INSURANCE_HISTORY,
        footerIcon: 'add',
        footerIconText: t('policyManagement.yourCoverDetails.insuranceHistory.iconText'),
        footerIconType: 'solid',
        headerTitle: t('policyManagement.yourCoverDetails.insuranceHistory.title'),
        onClick: initiateChat,
      },
      {
        customComponent: hasAnyClaim ? (
          <ClaimComp />
        ) : (
          <Typography variant="h4" className={classes.recordSpacing}>
            {t('policyManagement.yourCoverDetails.claimAccordion.none')}
          </Typography>
        ),
        eventId: COMPONENT_ID.RENEWAL_ADD_CLAIM_BUTTON,
        footerIcon: 'add',
        footerIconText: t('policyManagement.yourCoverDetails.claimAccordion.iconText'),
        footerIconType: 'solid',
        footerSubtitle: t('policyManagement.yourCoverDetails.claimAccordion.summary'),
        headerTitle: t('policyManagement.yourCoverDetails.claimAccordion.title'),
        onClick: initiateChat,
        showIcon: false,
      },
      {
        customComponent: hasAnyConviction ? (
          <ConvictionComp />
        ) : (
          <Typography variant="h4" className={classes.recordSpacing}>
            {t('policyManagement.yourCoverDetails.drivingOffencesAccordion.none')}
          </Typography>
        ),
        eventId: COMPONENT_ID.RENEWAL_ADD_MOTORING_CONVICTION_BUTTON,
        footerIcon: 'add',
        footerIconText: t('policyManagement.yourCoverDetails.drivingOffencesAccordion.iconText'),
        footerIconType: 'solid',
        footerSubtitle: t('policyManagement.yourCoverDetails.drivingOffencesAccordion.summary'),
        headerTitle: t('policyManagement.yourCoverDetails.drivingOffencesAccordion.title'),
        onClick: initiateChat,
        showIcon: false,
      },
      {
        columns: [{ subtitle: t('policyManagement.yourCoverDetails.criminalConvictions.details') }],
        eventId: COMPONENT_ID.RENEWAL_ADD_CRIMINAL_CONVICTION_BUTTON,
        footerIcon: 'add',
        footerIconText: t('policyManagement.yourCoverDetails.criminalConvictions.iconText'),
        footerIconType: 'solid',
        headerTitle: t('policyManagement.yourCoverDetails.criminalConvictions.title'),
        onClick: initiateChat,
      },
      {
        customComponent: <YourCarAccordion />,
        headerTitle: t('policyManagement.yourCoverDetails.yourCarAccordion.title'),
      },
      {
        customComponent: <UsageOfCarAccordion />,
        headerTitle: t('policyManagement.yourCoverDetails.usageCarAccordion.title'),
      },
      {
        customComponent: <YourDetailsAccordion />,
        headerTitle: t('policyManagement.yourCoverDetails.yourDetailsEditAccordion.title'),
      },
      {
        customComponent: <YourAdditionalDriverAccordion />,
        eventId: COMPONENT_ID.RENEWAL_ADDITIONAL_DRIVER_BUTTON,
        footerIcon: addDriverString(drivers.length, 'add-driver'),
        footerIconText: addDriverString(
          drivers.length,
          t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.addDriver'),
        ),
        footerIconType: addDriverIconType(drivers.length, 'solid'),
        headerTitle: t('policyManagement.yourCoverDetails.yourAdditionalDriverAccordion.title'),
        onClick: makeSelectDriver,
      },
      {
        customComponent: <YourExcesses onEdit={initiateChat} />,
        headerTitle: t('policyManagement.yourCoverDetails.yourExcesses.title'),
      },
      {
        customComponent: <YourExtraCover onEdit={initiateChat} />,
        headerTitle: t('policyManagement.yourCoverDetails.yourExtraCoverAccordion.title'),
      },
    ]);
  }, [isLookupsFetched]);

  const autoRenewMessage = t(`policyManagement.yourCoverDetails.autoRenew`, {
    renewalDate: formatDate(expirationDate, DATE_TYPES.SHORT_SPACE),
    returnObjects: true,
  });

  const backButtonRoute = pendingMTAQuoteSelected ? ROUTE.YOUR_RENEWAL_QUOTES : ROUTE.RENEWAL_QUOTE_DETAILS;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.gridSpacing}>
        <BlueBanner title={t('policyManagement.yourRenewalQuote.title')} route={backButtonRoute} />
        {changeMade && (
          <Alert className={classes.alert} severity="success">
            <AlertTitle> {t('policyManagement.yourCoverDetails.madeChangesAlert.title')}</AlertTitle>

            <Typography variant="body2">{t('policyManagement.yourCoverDetails.madeChangesAlert.body')}</Typography>
          </Alert>
        )}
        <Typography className={classes.title} variant="h1" color="secondary">
          {t('policyManagement.yourCoverDetails.subTitle')}
        </Typography>
        <Typography paragraph>
          <Trans t={t} i18nKey={'policyManagement.yourCoverDetails.coverDescription'} />
        </Typography>
        {lookupsLoading ? (
          <>
            <Skeleton className={classes.skeletonMargin} variant="rect" />
            <Skeleton className={classes.skeletonMargin} variant="rect" />
            <Skeleton className={classes.skeletonMargin} variant="rect" />
          </>
        ) : (
          <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
            {accordions?.map((props, index) => (
              <CommonAccordion
                key={Symbol(index).toString()}
                onChange={() => setOpen(open === index ? null : index)}
                expanded={open === index}
                {...props}
              />
            ))}
          </Grid>
        )}
        <Typography className={classes.title} variant="h2" color="secondary">
          {t('policyManagement.yourCoverDetails.confirmationTitle')}
        </Typography>
        <Typography paragraph>
          <Trans t={t} i18nKey={'policyManagement.yourCoverDetails.confirmationDescription'} />
        </Typography>
        {autoRenewal && !changeMade ? (
          <Typography>
            <Trans i18nKey={autoRenewMessage} t={t} components={[<strong key={Symbol().toString()} />]} />
          </Typography>
        ) : (
          <Typography>
            <Trans t={t} i18nKey={'policyManagement.yourCoverDetails.nonAutoRenew'} />
          </Typography>
        )}
      </Grid>

      {(!autoRenewal || changeMade) && (
        <Grid item className={classes.btnSpace}>
          <CommonButton
            onClick={handleClick}
            data-testid={COMPONENT_ID.RENEWAL_YOUR_COVER_DETAILS_CONTINUE}
            size="large"
            variant="contained"
            color="primary"
            text={t('policyManagement.yourCoverDetails.button')}
          />
        </Grid>
      )}
    </Grid>
  );
});
