import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import SelfService from '../../../service/util/selfService';

import { ButtonOfSelectedPolicyProps, CommonButton } from './commonButton';

export const ViewDocsBtn: React.FC<ButtonOfSelectedPolicyProps> = observer(function ViewDocsBtn({ policy, ...rest }) {
  const { t } = useTranslation('myAccount');

  const policyNumber = policy.policyNumber;
  const {
    dashboardStore: {
      insurance: {
        auto: { setSelectedPolicyNumber: setSelectedPolicyNumberAuto },
        home: { setSelectedPolicyNumber: setSelectedPolicyNumberHome },
        setSelectedPolicyType,
      },
    },
  } = useStores();

  const { eventTrack } = useSegment();

  const onClickHandler = () => {
    switch (true) {
      case SelfService.isHomePolicy(policy):
        setSelectedPolicyNumberHome(policyNumber);
        if (policy.policyStatus === 'Pending') {
          eventTrack('Home | Pending policy | View documents clicked', {
            policyNumber,
            policyStatus: policy.policyStatus,
            product: 'Home',
            productType: 'Core',
            type: TRACK_TYPE.CTA,
          });
        }
        break;
      case !SelfService.isHomePolicy(policy):
        setSelectedPolicyNumberAuto(policyNumber);
        break;
    }
    setSelectedPolicyType(policy);
  };

  return (
    <CommonButton
      {...rest}
      color="secondary"
      iconType="solid"
      colorIcon="black"
      onClick={onClickHandler}
      text={t('dashboard.buttonTxtDocuments')}
      url={ROUTE.DOCUMENTS}
      variant="outlined"
    />
  );
});
