import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { useStepperContext } from '../../common/utils/stepperContext';

import { EditPageProps } from './reviewAll';

export const EditDriverRelationship: React.FC<EditPageProps> = observer(function EditDriverRelationship({
  handleBack,
  uuid,
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
      insurance: {
        auto: {
          policySelected: { drivers },
        },
      },
    },
    mtaStore: {
      pendedMTA: { setDriver, getDriver },
    },
  } = useStores();

  const driver = getDriver(uuid ?? '');
  const [relationship, setRelationshipList] = useState<ILookupItem[] | undefined>();
  const [relationshipSelected, setRelationshipSelected] = useState(driver.relationshipToProposerCode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation('myAccount');

  const { setTitle } = useStepperContext();

  const isNotValid = () => {
    return relationshipSelected === '' || (relationshipSelected === 'S' && hasSpouse);
  };

  /* istanbul ignore next */
  const handlePendedMta = () => {
    setIsSubmitting(true);
    !isNotValid() && setDriver(uuid ?? '', { ...driver, relationshipToProposerCode: relationshipSelected });
  };

  const handleSave = () => {
    handlePendedMta();
    handleBack();
  };

  const handleBackClick = () => {
    handleBack();
  };

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.DRIVER_RELATIONSHIP],
  });
  useEffect(() => {
    setTitle(t('MTAJourney.driverRelationship.title'));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.DRIVER_RELATIONSHIP] !== undefined) {
      setRelationshipList(lookupsByName[LOOKUP.DRIVER_RELATIONSHIP]);
    }
  }, [isLookupsFetched, lookupsByName]);

  const hasSpouse = drivers.find((driver) => driver.relationshipToProposerCode === 'S');

  return (
    <>
      <Typography variant="body1">{t('MTAJourney.driverRelationship.description')}</Typography>
      <RadioGroup
        value={relationshipSelected}
        onChange={
          /* istanbul ignore next */ (event) => {
            setRelationshipSelected(event.target.value);
          }
        }
      >
        {relationship?.map((item, index) => {
          return (
            <FormControlLabel key={`${item.code}-${index}`} value={item.code} control={<Radio />} label={item.value} />
          );
        })}
      </RadioGroup>
      {relationshipSelected === '' && isSubmitting && (
        <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
      )}
      {relationshipSelected === 'S' && isSubmitting && hasSpouse && (
        <FormHelperText error>{t('validations.spouseError')}</FormHelperText>
      )}

      <EditFooter
        id="editDriverRelationship"
        validPage={!isNotValid()}
        handleSave={handleSave}
        handleBack={handleBackClick}
      />
    </>
  );
});
