const config = {
  amazonConnect: {
    apiGatewayUrl: process.env.REACT_APP_AMAZON_CONNECT_API_GATEWAY_URL ?? '',
    contactFlowIDs: {
      cpa_ar: process.env.REACT_APP_AMAZON_CONNECT_CPA_AR_CONTACT_FLOW_ID ?? '',
      home: process.env.REACT_APP_AMAZON_CONNECT_HOME_CONTACT_FLOW_ID ?? '',
      home_ar: process.env.REACT_APP_AMAZON_CONNECT_HOME_AR_FLOW_ID ?? '',
      main: process.env.REACT_APP_AMAZON_CONNECT_CONTACT_FLOW_ID ?? '',
      mta: process.env.REACT_APP_AMAZON_CONNECT_MTA_CONTACT_FLOW_ID ?? '',
    },
    instanceID: process.env.REACT_APP_AMAZON_CONNECT_INSTANCE_ID ?? '',
    region: 'eu-west-2',
  },
  app: {
    apiGatewayTimeoutMilliseconds: parseInt(process.env.REACT_APP_API_GATEWAY_TIMEOUT_MILLISECONDS ?? '28500', 10),
    mtaChargesApiCallWaitTimeMilliseconds: parseInt(
      process.env.REACT_APP_MTA_CHARGES_API_CALL_WAIT_TIME_MILLISECONDS ?? '15000',
      10,
    ),
    name: 'self-service',
    version: process.env.REACT_APP_VERSION ?? 'x.y.z',
  },
  brand: process.env.REACT_APP_BRAND ?? '',
  localStateKey: process.env.REACT_APP_STATE_KEY ?? 'es_ss_state',
  optimizely: { sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY ?? '' },
  provider: {
    analyticsKey: process.env.REACT_APP_PROVIDER_ANALYTICS_KEY ?? '',
    optimizelyKey: process.env.REACT_APP_PROVIDER_OPTIMIZELY_KEY ?? '',
    optimizelyScriptSrc: process.env.REACT_APP_PROVIDER_OPTIMIZELY_SCRIPT_SRC ?? '',
    paymentEnv: process.env.REACT_APP_PROVIDER_PAYMENT_ENV ?? '',
    paymentKey: process.env.REACT_APP_PROVIDER_PAYMENT_KEY ?? '',
  },
  satismeterKey: process.env.REACT_APP_SATISMETER_KEY ?? '',
  trustPilot: {
    apiKey: process.env.REACT_APP_TRUSTPILOT_APIKEY ?? '',
    businessUnitID: process.env.REACT_APP_TRUSTPILOT_BUSINESSUNITID ?? '',
  },
};

export const apiURL = {
  address: process.env.REACT_APP_API_ADDRESS ?? '',
  customerAuth: process.env.REACT_APP_API_CUSTOMER_AUTH ?? '',
  customerByNumber: process.env.REACT_APP_API_CUSTOMERDETAILS_GET_BY_CUSTOMERNUMBER ?? '',
  customerMta: process.env.REACT_APP_API_QUOTE_POST ?? '',
  customerValidation: process.env.REACT_APP_API_IOVATION_CUSTOMER_VALIDATION ?? '',
  documentById: process.env.REACT_APP_API_DOCUMENT_GET_BY_DOCUMENTID ?? '',
  documentsByPolicy: process.env.REACT_APP_API_DOCUMENTS_GET_BY_POLICYNUMBER ?? '',
  homePolicy: process.env.REACT_APP_API_HOME_POLICY ?? '',
  homePolicyRenewal: process.env.REACT_APP_API_HOME_POLICY_RENEWAL ?? '',
  lookup: process.env.REACT_APP_API_LOOKUP_POST ?? '',
  lookupDecode: process.env.REACT_APP_API_LOOKUP_DECODE ?? '',
  motorPolicy: process.env.REACT_APP_API_MOTOR_POLICY ?? '',
  motorPolicyRenewal: process.env.REACT_APP_API_MOTOR_POLICY_RENEWAL ?? '',
  paymentAdditional: '/submitAdditionalDetails',
  paymentAdditionalHome: '/additional-details',
  paymentMethod: '/paymentMethods',
  paymentMethodHome: '/payment-methods',
  paymentPrefix: process.env.REACT_APP_API_PAYMENT_PREFIX ?? '',
  paymentSubmit: '/payment',
  paymentSubmitHome: '/purchase',
  payments: process.env.REACT_APP_API_PAYMENTS ?? '',
  policies: process.env.REACT_APP_API_POLICIES ?? '',
  renewalPaymentPrefix: process.env.REACT_APP_API_RENEWAL_PAYMENT_PREFIX ?? '',
  renewalPaymentPrefixHome: process.env.REACT_APP_API_RENEWAL_PAYMENT_PREFIX_HOME ?? '',
  renewalPaymentPrefixHomeAdditonalDetails:
    process.env.REACT_APP_API_RENEWAL_PAYMENT_PREFIX_HOME_ADDITIONAL_DETAILS ?? '',
  renewalSuffix: '/auto-renew-details',
  vehicleLookup: process.env.REACT_APP_VEHICLE_LOOKUP_URL ?? '',
};

export const trustPilotURL = {
  totalReviews: process.env.REACT_APP_API_TRUSTPILOT_REVIEWS_GET ?? '',
  totalStars: process.env.REACT_APP_API_TRUSTPILOT_STARS_GET ?? '',
};

export const featureFlagURL = {
  server: process.env.REACT_APP_FEATURE_FLAG_SERVER ?? '',
};

export const emails = {
  customerServices: process.env.REACT_APP_EMAIL_CUSTOMER_SERVICES ?? '',
  dpo: process.env.REACT_APP_EMAIL_DPO ?? '',
  policyValidation: process.env.REACT_APP_EMAIL_POLICY_VALIDATION ?? '',
};

export const url = {
  breakdownAssistance: process.env.REACT_APP_BREAKDOWN_ASSISTANCE ?? '',
  claimDashboardUrl: process.env.REACT_APP_CLAIM_DASHBOARD_URL ?? '',
  domain: process.env.REACT_APP_DOMAIN ?? '',
  logoutRedirectUrlSW: process.env.REACT_APP_SW_LOGOUT_REDIRECT_URL ?? '',
  publicUrl: process.env.PUBLIC_URL,
  tiaLogin: process.env.REACT_APP_TIA_LOGIN ?? '',
  trustPilotReview: process.env.REACT_APP_TRUSTPILOT_REVIEW ?? '',
};

export default config;
