import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

type ObjUnknown = Record<string, unknown>;
type ErrorRecord<T> = Partial<Record<keyof T, string>>;
type Validations<T extends ObjUnknown> = Partial<Record<keyof T, Validation>>;
export type ChangeInput = ChangeEvent<HTMLInputElement>;
export type FormSubmit = FormEvent<HTMLFormElement>;

interface Validation {
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
  pattern?: {
    allowWhiteSpaces: boolean;
    message: string;
    value: string;
  };
  required?: {
    message: string;
    value: boolean;
  };
}

interface UseFormState<T> {
  errors: ErrorRecord<T>;
  initialValues: Partial<T>;
  isDirty: boolean;
  isSubmitSuccessful: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  validationOnChange: boolean;
}

export interface UseFormReturn<T> extends UseFormState<T> {
  doSubmit: () => void;
  formData: Partial<T>;
  handleChange: <S>(key: keyof T, sanitizeFn?: ((value: string) => S) | undefined) => ({ target }: ChangeInput) => void;
  handleResetData: () => void;
  handleResetForm: () => void;
  handleSubmit: (e: FormSubmit) => Promise<void>;
  handleValidation: () => {
    errors: Partial<Record<keyof T, string>>;
    isValid: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useForm = <T extends Record<keyof T, any> = ObjUnknown>(props?: {
  initialValues?: Partial<T>;
  objKey?: string;
  onSubmit?: () => Promise<boolean>;
  validationOnChange?: boolean;
  validations?: Validations<T>;
}): UseFormReturn<T> => {
  const defaultFormState = {
    errors: {},
    initialValues: (props?.initialValues ?? {}) as T,
    isDirty: false,
    isSubmitSuccessful: false,
    isSubmitted: false,
    isSubmitting: false,
    isValid: false,
    validationOnChange: props?.validationOnChange ?? false,
  };

  const [data, setData] = useState<T>((props?.initialValues ?? {}) as T);
  const [form, setForm] = useState<UseFormState<T>>(defaultFormState);

  const handleDirty = (obj: ObjUnknown, obj2: ObjUnknown) => {
    return JSON.stringify(obj) !== JSON.stringify({ ...obj, ...obj2 });
  };

  const handleResetForm = () => {
    setForm(defaultFormState);
  };

  const handleResetData = () => {
    setData(form.initialValues as T);
  };

  const handleValidation = () => {
    const validations = props?.validations;
    const errors: ErrorRecord<T> = {};
    let isValid = true;

    if (validations) {
      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];

        if (validation?.required?.value && !value) {
          isValid = false;
          errors[key] = validation.required.message;
        }

        // Check for pattern validation
        const pattern = validation?.pattern;
        const customizedValue =
          pattern?.allowWhiteSpaces && typeof value === 'string' ? (value as string).replace(/\s/g, '') : value;

        if (pattern?.value && !RegExp(pattern.value).test(customizedValue)) {
          isValid = false;
          errors[key] = pattern.message;
        }

        // Custom validation
        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          isValid = false;
          errors[key] = custom.message;
        }
      }
    }
    return {
      errors,
      isValid,
    };
  };

  const handleChange =
    <S,>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    ({ target }: ChangeInput) => {
      const value = target.type !== 'checkbox' ? target.value : target.checked;
      const newVal = sanitizeFn ? sanitizeFn(value.toString()) : value || undefined;

      const newData = {
        ...data,
        [key]: typeof data[key] === 'object' ? { ...data[key], [props?.objKey ?? '']: newVal } : newVal,
      };
      setData(newData);
    };

  useEffect(() => {
    const isDirty = handleDirty(data, form.initialValues);
    if (form.validationOnChange) {
      const validation = handleValidation();

      setForm({ ...form, isDirty, ...validation });
    } else {
      setForm({ ...form, isDirty });
    }
  }, [data]);

  const handleSubmit = async (e: FormSubmit) => {
    e.preventDefault();
    await doSubmit();
  };

  const doSubmit = async () => {
    const validation = handleValidation();

    if (props?.onSubmit && validation.isValid) {
      setForm({ ...form, ...validation, isSubmitting: true });

      const isSubmitted = await props.onSubmit();
      setForm({
        ...(isSubmitted
          ? {
              ...defaultFormState,
              initialValues: data,
            }
          : {
              ...form,
              ...validation,
            }),
        isSubmitSuccessful: isSubmitted,
        isSubmitted,
        isSubmitting: false,
      });
    } else {
      setForm({ ...form, ...validation });
    }
  };

  return {
    ...form,
    doSubmit,
    formData: data,
    handleChange,
    handleResetData,
    handleResetForm,
    handleSubmit,
    handleValidation,
  };
};
