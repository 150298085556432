import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    usabilla_live: (event: string) => void;
  }
}

const GetFeedbackTracker: React.FC = () => {
  const location = useLocation();
  const [route, setRoute] = useState('');
  useEffect(() => {
    if (route !== location.pathname) {
      setRoute(location.pathname);
      try {
        window.usabilla_live('virtualPageView');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [location.pathname, route]);

  return null;
};

export default GetFeedbackTracker;
