import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { BUTTON_ORIGIN, FETCH_ADVISOR_REASON } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { CloseBtn } from '../../common/button/closeBtn';
import { ContinueBtn } from '../../common/button/continueBtn';
import { MTACustomAttrT } from '../../common/utils/useMTAChatBotAttributes';

import { DialogTemplate } from './dialogTemplate';

export const FetchAdviserDialog: React.FC<{
  continueButtonText?: string;
  secondaryContent?: string;
  title: string;
  type: FETCH_ADVISOR_REASON;
}> = observer(function FetchAdviserDialog({ continueButtonText, secondaryContent, type, title }) {
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: { home, isHome, auto },
    },
    mtaStore: {
      quote: { additionalPremium },
    },
  } = useStores();
  const [originButton, setOriginButton] = useState<BUTTON_ORIGIN>(BUTTON_ORIGIN.NONE);
  const [customAttr, setCustomAttr] = useState<MTACustomAttrT>();

  const autoRenewal = isHome ? home.policySelected.autoRenewal : auto.policySelected.autoRenewal;

  useEffect(() => {
    switch (type) {
      case FETCH_ADVISOR_REASON.CLAIMS_CONV:
        setCustomAttr('ssMTAVehicleADClaimsConvict');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA);
        break;
      case FETCH_ADVISOR_REASON.MAKE_CHANGES:
        setCustomAttr('ssMTAVehicleCarNotRec');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA);
        break;
      case FETCH_ADVISOR_REASON.MAKE_CHANGES_REN:
        setCustomAttr('ssRenQuoteUpdatePol');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA_REN);
        break;
      case FETCH_ADVISOR_REASON.DD_CUSTOMER:
        setCustomAttr('ssMTAVehicleDDChar');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA);
        break;
      case FETCH_ADVISOR_REASON.ASSISTED_PAYMENT:
        additionalPremium >= 0 && setCustomAttr('ssMTAVehicleOfferChar');
        additionalPremium < 0 && setCustomAttr('ssMTAVehicleOfferRef');
        setOriginButton(BUTTON_ORIGIN.ASSISTED_PAYMENT);
        break;
      case FETCH_ADVISOR_REASON.CHANGE_FIRSTNAME:
        setCustomAttr('ssMTAVehicleFirstNameChange');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA);
        break;
      case FETCH_ADVISOR_REASON.CHANGE_EMPLOYMENT_DETAILS:
        setCustomAttr('ssMTAVehicleEmpDet');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_MTA);
        break;
      case FETCH_ADVISOR_REASON.FETCH_ADVISER_DELETE_CARD:
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_DELETE_CARD);
        break;
      case FETCH_ADVISOR_REASON.FETCH_ADVISER_ADD_CARD:
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_ADD_CARD);
        break;
      case FETCH_ADVISOR_REASON.FETCH_ADVISER_RENEWAL_QUOTE:
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_RENEWAL_QUOTE);
        break;
      case FETCH_ADVISOR_REASON.FETCH_ADVISER_RENEWAL_QUOTE_PAY:
        if (autoRenewal) setCustomAttr('ssRenARGoAhead');
        setOriginButton(BUTTON_ORIGIN.FETCH_ADVISER_RENEWAL_QUOTE_PAY);
        break;
      case FETCH_ADVISOR_REASON.RENEWAL_MTA_UNAVAILABLE_ONLINE:
        setOriginButton(BUTTON_ORIGIN.RENEWAL_MTA_UNAVAILABLE_ONLINE);
        setCustomAttr('ssMTAVehicleRC');
        break;
    }
  }, []);

  return (
    <DialogTemplate
      topImage={<SVGWrapper alt="chat-logo" src="chat-logo.svg" height="25%" width="25%" />}
      title={title}
      bottomContent={t('cancelPolicyPopup.closed.bottomContent')}
      buttons={[
        <CloseBtn key="close" origin={originButton} />,
        <ContinueBtn key="continue" origin={originButton} customAttr={customAttr} text={continueButtonText} />,
      ]}
      secondaryContent={secondaryContent}
    />
  );
});
