import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { DIALOG_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { ChatWithUs } from '../../common/components/chatWithUs';
import { PersonName } from '../components/personName';

export const UpdateName: React.FC = observer(function UpdateName() {
  const { t } = useTranslation('myAccount');

  const {
    mtaStore: {
      pendedMTA: {
        insured: { person },
      },
    },
  } = useStores();

  return (
    <PersonName
      title={t('changeYourDetails.personalDetails.title')}
      person={person}
      disableFirstName
      chatWithUs={
        <ChatWithUs
          description={t('changeYourDetails.personalDetails.assistance')}
          dialogType={DIALOG_TYPE.FETCH_ADVISER_CHANGE_FIRSTNAME}
          linkId={COMPONENT_ID.MTA_CHAT_WITH_US_LINK_CHANGE_NAME}
        />
      }
    />
  );
});
