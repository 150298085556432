import {
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DIALOG_TYPE, LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { ChatWithUs } from '../components/chatWithUs';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .first-name': {
      marginBottom: spacing(2),
      marginTop: spacing(3),
    },
    '& .radio-container-gender': {
      '&__label': {
        minWidth: 'fit-content',
        width: '100%',
      },
      '&__typo': {
        marginBottom: spacing(1),
      },
    },
    maxWidth: 352,
  },
}));

export const EditDriverName: React.FC<EditPageProps> = observer(function EditDriverName({ handleBack, uuid }) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { setDriver, getDriver },
    },
  } = useStores();
  const { setTitle } = useStepperContext();
  const [titleList, setTitleList] = useState<ILookupItem[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const driver = getDriver(uuid ?? '');

  const [personState, setPersonState] = useState(driver.person);

  const { firstName, surname, titleCode, genderCode } = personState;

  const classes = useStyles();
  const styleContainedSecondary = 'contained invert secondary';
  const { t } = useTranslation('myAccount');

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.TITLE],
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLookupsFetched && lookupsByName[LOOKUP.TITLE] !== undefined) {
      setTitleList(lookupsByName[LOOKUP.TITLE]);
    }
  }, [isLookupsFetched, lookupsByName]);
  useEffect(() => {
    setTitle(t('MTAJourney.driverName.changeDriverTitle'));
  }, []);
  const isNotValid = (): boolean => {
    return firstName === '' || surname === '' || titleCode === '' || (titleCode === '001' && genderCode === null);
  };

  const handlePendedMta = () => {
    setIsSubmitting(true);

    if (!isNotValid()) {
      setDriver(uuid ?? '', {
        ...driver,
        person: { ...driver.person, firstName, genderCode, surname, titleCode },
      });
    }
  };

  const handleSave = () => {
    handlePendedMta();
    handleBack();
  };

  const handleBackClick = () => {
    handleBack();
  };

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Typography>{t('MTAJourney.driverName.titleLabel')}</Typography>
        <Select
          variant="outlined"
          value={titleList ? titleCode : ''}
          error={isSubmitting && !titleCode}
          onChange={(event) => {
            setPersonState({
              ...personState,
              titleCode: event.target.value as string,
            });
          }}
        >
          {titleList?.map((item, index) => {
            return (
              <MenuItem key={`${item.code}-${index}`} value={item.code}>
                {item.value}
              </MenuItem>
            );
          })}
        </Select>
        {isSubmitting && titleCode === '' && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}

        {titleCode === '001' && (
          <Grid container className={classes.root}>
            <Typography className="radio-container-gender__typo">
              {t('MTAJourney.driverName.genderSelection')}
            </Typography>
            <Grid item xs={12}>
              <RadioGroup
                onChange={(event) => setPersonState({ ...personState, genderCode: event.target.value })}
                value={genderCode}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={clsx('radio-container-gender__label', styleContainedSecondary, {
                        checked: genderCode === 'M',
                      })}
                      control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-male' }} />}
                      label={t('MTAJourney.driverName.genderMale')}
                      labelPlacement="end"
                      value="M"
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      className={clsx('radio-container-gender__label', styleContainedSecondary, {
                        checked: genderCode === 'F',
                      })}
                      control={<Radio className={styleContainedSecondary} inputProps={{ id: 'gender-radio-female' }} />}
                      label={t('MTAJourney.driverName.genderFemale')}
                      labelPlacement="end"
                      value="F"
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>

              {isSubmitting && genderCode === null && (
                <FormHelperText error>{t('validations.noSelection')}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )}

        <TextField
          className="first-name"
          value={firstName}
          label={t('MTAJourney.driverName.firstName')}
          helperText={isSubmitting && firstName === '' && t('validations.missingFirstName')}
          error={isSubmitting && firstName === ''}
          onChange={(event) => setPersonState({ ...personState, firstName: event.target.value })}
          disabled={true}
        />
        <TextField
          label={t('MTAJourney.driverName.lastName')}
          helperText={isSubmitting && surname === '' && t('validations.missingLastName')}
          error={isSubmitting && surname === ''}
          value={surname}
          onChange={(event) => setPersonState({ ...personState, surname: event.target.value })}
        />

        <ChatWithUs
          description={t('MTAJourney.driverName.chat')}
          dialogType={DIALOG_TYPE.FETCH_ADVISER_CHANGE_FIRSTNAME}
        />

        <EditFooter
          id="editDriverName"
          validPage={!isNotValid()}
          handleSave={handleSave}
          handleBack={handleBackClick}
        />
      </Grid>
    </>
  );
});
