import { makeStyles } from '@esure-cloud/react-components';

const useStyles = makeStyles((theme) => ({
  fancyTable: {
    '& tbody': {
      '& td': {
        borderLeft: `1px solid ${theme.palette.secondary.main}80`,
        borderTop: `1px solid ${theme.palette.secondary.main}80`,
        fontSize: 16,
        fontWeight: 700,
        padding: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
      '& td:first-child': {
        background: `${theme.palette.secondary.main}14`,
        fontSize: 14,
      },
      '& td:last-child': {
        borderRight: `1px solid ${theme.palette.secondary.main}80`,
      },
      '& tr:first-child td:first-child': {
        borderRadius: '8px 0px 0px 0px',
      },
      '& tr:last-child td': {
        borderBottom: `1px solid ${theme.palette.secondary.main}80`,
      },
      '& tr:last-child td:first-child': {
        borderBottomLeftRadius: '8px',
      },
      '& tr:last-child td:last-child': {
        borderBottomRightRadius: '8px',
      },
    },
    '& td:first-child, & th:first-child': {
      fontWeight: 500,
      padding: theme.spacing(1),
      textAlign: 'left',
    },
    '& th': {
      '&:last-child': {
        borderRadius: '0px 8px 0px 0px',
        borderRight: `1px solid ${theme.palette.secondary.main}80`,
      },
      '&:not(:first-child)': {
        background: `${theme.palette.secondary.main}14`,
        borderLeft: `1px solid ${theme.palette.secondary.main}80`,
        borderTop: `1px solid ${theme.palette.secondary.main}80`,
        fontSize: 16,
        fontWeight: 700,
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
      '&:nth-child(2)': {
        borderRadius: '8px 0px 0px 0px',
      },
    },
    borderCollapse: 'separate',
    borderSpacing: 0,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));
export interface FancyTableCell {
  tableCells: string[];
}
export interface FancyTableProps {
  tableHeadings: string[];
  tableRows: FancyTableCell[];
  testId?: string;
}

export const FancyTable: React.FC<FancyTableProps> = (props) => {
  const classes = useStyles();
  const { tableHeadings, tableRows } = props;
  return (
    <table className={classes.fancyTable} data-testid={props.testId ? props.testId : ''}>
      <thead>
        <tr>
          {tableHeadings.map((heading, i) => (
            <th key={heading + i.toString()}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row, i) => (
          <tr key={'row' + i.toString()}>
            {row.tableCells.map((cell, i) => (
              <td key={cell + i.toString()}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
