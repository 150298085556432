import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonAccordion, { CommonAccordionProps } from '../../component/common/commonAccordion';
import { url } from '../../service/config';
import { DOCUMENT_TYPE_CODE, PACKS } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { DownloadInsuranceDocument } from '../common/button/downloadInsuranceDocument';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(5, 0),
    position: 'relative',
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
}));

export const Upgrades: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const [open, setOpen] = useState<number | null>(null);

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policyCover: { lifeStylePacks, breakdownCover, motorLegalProtection },
        },
      },
    },
  } = useStores();

  const hasLifeStyle = lifeStylePacks.length > 0;

  const breakdownContent = (): CommonAccordionProps | void => {
    const title = t('breakdownCover.subtitle');
    const code = breakdownCover?.coverageCd ?? '';

    switch (code) {
      case PACKS.RESRV11:
      case PACKS.RESRV12:
        return {
          columns: [
            {
              list: t('breakdownCover.roadside.includedListCol1', { returnObjects: true }),
              title,
            },
            { list: t('breakdownCover.roadside.includedListCol2', { returnObjects: true }) },
          ],
          headerLabel: t('breakdownCover.roadside.label'),
        };
      case PACKS.RESRHV11:
      case PACKS.RESRHV12:
        return {
          columns: [
            {
              list: t('breakdownCover.roadsideHome.includedListCol1', { returnObjects: true }),
              title,
            },
            { list: t('breakdownCover.roadsideHome.includedListCol2', { returnObjects: true }) },
          ],
          headerLabel: t('breakdownCover.roadsideHome.label'),
        };
      case PACKS.RESROV11:
      case PACKS.RESROV13:
        return {
          columns: [
            {
              list: t('breakdownCover.roadsideHomeRecovery.includedListCol1', { returnObjects: true }),
              title,
            },
            { list: t('breakdownCover.roadsideHomeRecovery.includedListCol2', { returnObjects: true }) },
          ],
          headerLabel: t('breakdownCover.roadsideHomeRecovery.label'),
        };
    }
  };

  const accordions: (CommonAccordionProps | boolean)[] = [
    !!motorLegalProtection && {
      color: 'default',
      columns: [
        {
          list: t('motorLegal.includedItems1', { returnObjects: true }),
          title: t('motorLegal.thisIncludes'),
        },
        {
          icon: 'check',
          list: t('motorLegal.includedItems2', { returnObjects: true }),
        },
      ],
      downloadLink: (
        <DownloadInsuranceDocument
          documentTypeCode={DOCUMENT_TYPE_CODE.MOTOR_IPID_MLP}
          buttonName={t('motorLegal.downloadLink')}
        />
      ),
      headerLabel: t('policyStatus.included'),
      headerLabelColour: 'success',
      headerTitle: t('motorLegal.title'),
      summary: t('motorLegal.description'),
    },
    !!breakdownCover && {
      ...breakdownContent(),
      color: 'default',
      downloadLink: (
        <DownloadInsuranceDocument
          documentTypeCode={DOCUMENT_TYPE_CODE.MOTOR_IPID_BKDN}
          buttonName={t('breakdownCover.downloadLink')}
        />
      ),
      footerButtonText: t('breakdownCover.buttonTxt'),
      headerLabelColour: 'success',
      headerTitle: t('breakdownCover.title'),
      redirectUrl: url.breakdownAssistance,
      thirdPartyLogo: './assets/images/rac.svg',
    },
  ];

  return (
    <>
      {accordions.filter((x) => x).length > 0 && (
        <Grid container direction="column" className={classes.root}>
          <Grid
            container
            item
            justifyContent={!hasLifeStyle ? 'center' : 'flex-start'}
            className={!hasLifeStyle ? classes.sectionTitle : ''}
          >
            <Typography variant={hasLifeStyle ? 'overline' : 'h1'}>{t('upgrades.title')}</Typography>
          </Grid>
          <Grid container direction="column" wrap="nowrap" className={classes.accordionContainer}>
            {accordions
              .filter((x) => x)
              .map((props, index) => (
                <CommonAccordion
                  key={index}
                  onChange={() => setOpen(open === index ? null : index)}
                  expanded={open === index}
                  {...(props as CommonAccordionProps)}
                />
              ))}
          </Grid>
        </Grid>
      )}
    </>
  );
});
