import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';

import { useRenewalStepperContext } from '../utils/renewalStepperContext';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  commonContainer: {
    backgroundColor: palette.secondary.main,
    borderColor: palette.secondary.main,
    [breakpoints.down('xs')]: {
      borderRadius: 0,
      margin: spacing(-4, -2, 0, -2),
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    color: palette.secondary.contrastText,
  },
  stepContent: {
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      marginTop: spacing(2),
    },
  },
  title: {
    marginBottom: spacing(3),
    marginTop: spacing(5),
  },
}));

export const RenewalMakeChange: React.FC = observer(() => {
  const classes = useStyles();
  const {
    step,
    stepsData: { steps, blueBannerTitle },
    title,
  } = useRenewalStepperContext();

  const getTitle = () => {
    if (blueBannerTitle[step]) {
      return blueBannerTitle[step];
    } else {
      return blueBannerTitle['default'];
    }
  };

  return (
    <Grid>
      <CommonContainer className={classes.commonContainer}>
        <Typography variant="h3">{getTitle()}</Typography>
      </CommonContainer>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      <Grid className="step-content">{steps[step]}</Grid>
    </Grid>
  );
});
