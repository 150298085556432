import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../service/state/store';
import { HomeRenewalSetupSuccess } from '../home/homeRenewalSetupSuccess';
import { MotorRenewalSetupSuccess } from '../motor/motorRenewalSetupSuccess';

export const RenewalSetupSuccess: React.FC = observer(() => {
  const {
    dashboardStore: {
      insurance: { isHome },
    },
  } = useStores();

  return isHome ? <HomeRenewalSetupSuccess /> : <MotorRenewalSetupSuccess />;
});
