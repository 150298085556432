import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMui,
  TableRow,
  makeStyles,
  withStyles,
} from '@esure-cloud/react-components';

import React from 'react';

import IconSure, { IconSureProps, IconType } from '../../../../../component/common/icon';

const StyledTableCell = withStyles(({ spacing, breakpoints }) => ({
  body: {
    '&:last-child': {
      textAlign: 'end',
    },
    fontSize: 16,
    overflow: 'auto',
    [breakpoints.down('xs')]: {
      padding: spacing(0, 2),
    },
    [breakpoints.up('sm')]: {
      padding: 0,
    },
    textAlign: 'start',
  },
  head: {
    '&:last-child': {
      textAlign: 'end',
    },
    [breakpoints.down('xs')]: {
      padding: spacing(0, 2),
    },
    [breakpoints.up('sm')]: {
      padding: 0,
    },
    textAlign: 'start',
  },
}))(TableCell);

const StyledTableRow = withStyles(({ palette }) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: palette.grey[100],
      textAlign: 'start',
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    '& .cell-with-icon': {
      gap: 4,
      verticalAlign: 'baseline',
    },
  },
}));

export interface DDTableData {
  Amount?: string;
  Date?: string;
  'Date/status'?: string;
  Detail?: string;
  'Due date'?: string;
  'Due date/status'?: string;
  'Instalment no.'?: number;
  Month?: number;
  Status?: string;
}

export interface DDTableIcon {
  color?: IconSureProps['color'];
  iconName?: string;
  iconType?: IconType;
}

export interface DDTableProps {
  data: DDTableData[];
  headers: string[];
  icon?: DDTableIcon;
  tableFooter?: React.ReactElement;
}

const tooltipHeaders = ['Due date', 'Due date/status'];
// const statusCells = ['Status', 'Due date/status', 'Date/status'];

export const DirectDebitTable: React.FC<DDTableProps> = ({ headers, data, tableFooter }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.root}>
      <TableMui>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return !tooltipHeaders.includes(header) ? (
                <StyledTableCell key={index} width={headers.length === 3 && index === 0 ? '59%' : ''}>
                  {header}
                </StyledTableCell>
              ) : (
                <StyledTableCell key={index}>
                  <Grid container className="cell-with-icon">
                    {header} <IconSure icon="info-circle" size="20" type="solid" color="secondary" />
                  </Grid>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              {headers.map((header) => {
                return (
                  <StyledTableCell key={`${index}_${header}`}>{item[header as keyof DDTableData]}</StyledTableCell>
                );
              })}
              {/*{headers.map((header) => {*/}
              {/*  return statusCells.includes(header) ? (*/}
              {/*    <StyledTableCell key={index}>*/}
              {/*      <Grid container className="cell-with-icon">*/}
              {/*        {item[header as keyof DDTableData]}*/}
              {/*        {icon?.iconName && (*/}
              {/*          <IconSure icon={icon.iconName} size="20" type={icon.iconType} color={icon.color} />*/}
              {/*        )}*/}
              {/*      </Grid>*/}
              {/*    </StyledTableCell>*/}
              {/*  ) : (*/}
              {/*    <StyledTableCell key={`${index}_${header}`}>{item[header as keyof DDTableData]}</StyledTableCell>*/}
              {/*  );*/}
              {/*})}*/}
            </StyledTableRow>
          ))}
        </TableBody>

        {tableFooter && tableFooter}
      </TableMui>
    </TableContainer>
  );
};
