import { types } from 'mobx-state-tree';

export const DriverVehicleDamage = types.model('DriverVehicleDamage', {
  compulsoryExcess: types.number,
  name: types.string,
  totalAccidental: types.number,
  voluntaryExcess: types.number,
});

export const DriversVehicleDamage = types.model('DriversVehicleDamage', {
  drivers: types.array(DriverVehicleDamage),
});
