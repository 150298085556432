import { Box, Tab, Tabs, makeStyles } from '@esure-cloud/react-components';

import { Grid, GridProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';

import { TabPanel } from './tabPanel';

type OrientationProp = 'horizontal' | 'vertical';
type VariantProp = 'fullWidth' | 'scrollable' | 'standard';
type ColorProp = 'primary' | 'secondary';
type BackgroundProp = 'empty' | 'filled';

export interface TabProps {
  components: TabPanelComponent[];
  disabled: boolean;
  handleClickEvent?: () => void;
  icon?: React.ReactElement;
  label?: string;
}

export interface TabPanelComponent {
  component: React.ReactNode;
  path: string;
}

export interface TabsProps extends GridProps {
  activeTab?: number;
  background?: BackgroundProp;
  color?: ColorProp;
  orientation?: OrientationProp;
  tabs?: TabProps[];
  textcolor?: string;
  variant?: VariantProp;
}

export interface TabPropsInterface {
  [paramName: string]: TabProps[];
}

const useStyles = makeStyles((theme) => ({
  colorPrimary: {},
  colorSecondary: {},

  empty: {
    '&$colorPrimary': {
      backgroundColor: `${theme.palette.background.paper}`,
    },
    '&$colorSecondary': {
      backgroundColor: `${theme.palette.background.paper}`,
    },
  },
  filled: {
    '&$colorPrimary': {
      backgroundColor: `${theme.palette.primary.main}08`,
    },
    '&$colorSecondary': {
      backgroundColor: `${theme.palette.secondary.main}08`,
    },
  },

  indicator: {
    '& > span': {
      borderRadius: theme.spacing(0.25),
      maxWidth: '50%',
      width: '100%',
    },
    backgroundColor: 'transparent',
    display: 'flex',
    height: theme.spacing(0.5),
    justifyContent: 'center',
  },
  indicatorSpan: {
    '&$colorPrimary': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
    '&$colorSecondary': {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
  labelStyle: {
    '& > span': {
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  notice: {
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginTop: theme.spacing(-4),
      position: 'fixed',
      width: '100%',
      zIndex: '998',
    },
    borderBottom: `1px solid ${theme.palette.common.black}23`,
  },
}));

export const CustomTabs: React.FC<TabsProps> = ({
  activeTab,
  background = 'empty',
  color = 'primary',
  orientation,
  tabs,
  variant,
  className,
  ...rest
}) => {
  const { eventTrack } = useSegment();
  const classes = useStyles();
  const colorClass = `color${color.charAt(0).toUpperCase()}${color.slice(1)}` as keyof typeof classes;
  const { pathname } = useLocation();
  const history = useHistory();

  const getActiveTab = (pathname: string): number => {
    if (tabs) {
      let index = -1;
      tabs.forEach((tab, tabIndex) => {
        const foundIndex = tab.components.findIndex((panel) => pathname === panel.path);
        if (foundIndex > -1) {
          index = tabIndex;
        }
      });
      if (index > -1) {
        return index;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const [selectedTab, setSelectedTab] = useState(activeTab ? activeTab : getActiveTab(pathname));
  useEffect(() => {
    if (tabs) {
      history.push(tabs[selectedTab].components[0].path);
      return tabs[selectedTab].handleClickEvent?.call(this);
    }
  }, [tabs]);

  const handleChange = (index: number) => {
    setSelectedTab(index);

    if (tabs) {
      const tab = tabs[index];
      eventTrack('Tab Clicked', { item: tab.label, type: TRACK_TYPE.TAB });

      history.push(tabs[index].components[0].path);
      return tabs[index].handleClickEvent?.call(this);
    }
  };

  const getTabPanelContent = (selectedTab: number) => {
    if (tabs) {
      const tabPanel = tabs[selectedTab].components.find((tab) => pathname === tab.path);
      if (tabPanel) {
        return tabPanel.component;
      }
    }
  };
  return (
    <Grid className={className} {...rest}>
      <Tabs
        TabIndicatorProps={{
          children: <span className={clsx(classes[colorClass], classes.indicatorSpan)} />,
          className: classes.indicator,
        }}
        className={clsx(classes.root, classes[colorClass], {
          [classes['filled']]: ['contained', 'filled'].includes(background),
          [classes['empty']]: ['contained', 'empty'].includes(background),
        })}
        id="tabs-container"
        orientation={orientation}
        scrollButtons="on"
        value={selectedTab}
        variant={variant}
      >
        {tabs?.map((tab, index) => {
          return (
            <Tab
              className={classes.labelStyle}
              key={index}
              label={tab.label}
              icon={tab.icon}
              disabled={tab.disabled}
              onClick={() => handleChange(index)}
              data-testid="tab"
            />
          );
        })}
      </Tabs>

      <Box>
        {tabs?.map((_tabPanel, index) => {
          return (
            <TabPanel index={index} value={selectedTab} key={index}>
              {getTabPanelContent(selectedTab)}
            </TabPanel>
          );
        })}
      </Box>
    </Grid>
  );
};
