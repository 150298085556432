import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMPONENT_ID } from '../../../service/analytics';
import { useDispatchMakeAChange } from '../../common/button/useDispatchMakeAChange';

import { ButtonOfSelectedPolicyProps, CommonButton } from './commonButton';

export const MakeAChangeBtn: React.FC<ButtonOfSelectedPolicyProps> = observer(function MakeAChangeBtn({
  policy,
  ...rest
}) {
  const { t } = useTranslation('myAccount');

  const dispatchMakeAChange = useDispatchMakeAChange({ policy });

  const handleClick = dispatchMakeAChange.dispatch;

  return (
    <CommonButton
      {...rest}
      data-testid={COMPONENT_ID.MTA_MAKE_A_CHANGE_BUTTON}
      onClick={handleClick}
      text={t('buttons.makeAChange')}
    />
  );
});
