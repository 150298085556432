import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconArrowForward,
  IconNotificationImportant,
  IconWarning,
  Link,
  RegistrationPlate,
  Theme,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { useTranslation } from 'react-i18next';

import { POLICY_STATUS_CODE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';

const useStyles = makeStyles<Theme, Partial<RenewalAlertTemplateProps>>(({ breakpoints, spacing }) => ({
  root: (props) => ({
    '& .expiry-box': {
      marginBottom: spacing(3),
    },
    '& .expiry-box-old': {
      marginBottom: spacing(1),
    },
    '& .info-plate': {
      color: 'black',
      margin: spacing(1, 0),
    },
    '& .link': {
      cursor: 'pointer',
    },
    '& .noMargin': {
      marginBottom: 0,
    },
    '& .title': {
      marginBottom: spacing(1),
    },
    '& .viewQuote': {
      display: 'flex',
      marginTop: spacing(2),
    },
    '& .viewQuoteLink': {
      display: 'flex',
    },
    marginTop: spacing(2),
    // The blue banner has -4.5 margintop on small screens to push it up to the top of the screen, but this then goes ontop of the alert,
    // therefore we need to add padding to the bottom of the alert to push it down
    ...(props.isAboveBlueBanner && {
      [breakpoints.down('xs')]: {
        marginTop: 0,
        paddingBottom: spacing(4.5),
      },
    }),
  }),
}));

interface RenewalAlertTemplateProps {
  autoRenewal: boolean | null;
  formattedDate: string;
  handleClick: () => void;
  isAboveBlueBanner?: boolean;
  linkText: string;
  policyStatusCode: string;
  remainingDays: number;
  savedQuoteLinkText: string;
  showNotification: boolean;
  showRenewalLink: boolean;
  showSavedQuoteLink: boolean;
  title: string;
  vrm: string | null;
}

export const RenewalAlertTemplate: React.FC<RenewalAlertTemplateProps> = ({
  isAboveBlueBanner,
  title,
  linkText,
  handleClick,
  savedQuoteLinkText,
  showNotification,
  showRenewalLink,
  showSavedQuoteLink,
  vrm,
  remainingDays,
  autoRenewal,
  formattedDate,
  policyStatusCode,
}) => {
  const classes = useStyles({ isAboveBlueBanner });
  const { t } = useTranslation('myAccount');

  const {
    interfaceStore: { hasFeatureFlag },
  } = useStores();

  const getDateEndString = () => {
    let dateEndString = '';
    switch (true) {
      case policyStatusCode === POLICY_STATUS_CODE.COMPANY_DECLINED:
        dateEndString = t('expiryNoticeNew.companyDeclined.date', {
          value: formattedDate,
        });
        break;
      case remainingDays === 0 && (!autoRenewal || policyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED):
        dateEndString = t('expiryNoticeNew.autoRenewalOff.endsTonight', {
          date: formattedDate,
        });
        break;
      case remainingDays >= 1 &&
        remainingDays <= 10 &&
        (!autoRenewal || policyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED):
        dateEndString = t('expiryNoticeNew.autoRenewalOff.endsOn', {
          date: formattedDate,
        });
        break;
      case remainingDays > 10 && !autoRenewal && policyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED:
        dateEndString = t('expiryNoticeNew.autoRenewalOff.endsOn', {
          date: formattedDate,
        });
        break;
      case remainingDays > 10 && (!autoRenewal || policyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED):
        dateEndString = t('expiryNoticeNew.autoRenewalOff.validUntil', {
          date: formattedDate,
        });
        break;
      case remainingDays === 0 && autoRenewal:
        dateEndString = t('expiryNoticeNew.autoRenewalOn.renewsTonight', {
          date: formattedDate,
        });
        break;
      case remainingDays >= 1 && remainingDays <= 10 && autoRenewal:
        dateEndString = t('expiryNoticeNew.autoRenewalOn.autoRenew', {
          date: formattedDate,
        });
        break;
      case remainingDays > 10 && autoRenewal:
        dateEndString = t('expiryNoticeNew.autoRenewalOn.yourAutoRenew', {
          date: formattedDate,
        });
        break;
    }

    return dateEndString;
  };

  const getSeverity = (): AlertProps['severity'] => {
    let severity: AlertProps['severity'] = 'info';

    if (policyStatusCode === POLICY_STATUS_CODE.COMPANY_DECLINED || remainingDays <= 3) {
      severity = 'error';
    } else if (
      (remainingDays >= 4 && remainingDays <= 10) ||
      (remainingDays > 10 && policyStatusCode === POLICY_STATUS_CODE.CUSTOMER_DECLINED)
    ) {
      severity = 'warning';
    }

    return severity;
  };

  return showNotification ? (
    hasFeatureFlag('newRenewalMta') ? (
      <div className={classes.root}>
        <Alert
          className="expiry-box"
          severity={getSeverity()}
          icon={getSeverity() === 'error' ? <IconWarning /> : ''}
          data-testid="alert"
        >
          <AlertTitle className="title">{title}</AlertTitle>

          {vrm != null && (
            <Grid className="info-plate">
              <RegistrationPlate active label={vrm} />
            </Grid>
          )}

          <Box>
            <Typography variant="body2">{getDateEndString()}</Typography>
          </Box>

          {showRenewalLink && (
            <Box pt={2}>
              <Button variant="outlined" color="secondary" onClick={handleClick} endIcon={<IconArrowForward />}>
                {linkText}
              </Button>
            </Box>
          )}

          {showSavedQuoteLink && (
            <Box className={'viewQuote'}>
              <Typography variant="body2" className="viewQuoteLink" color="secondary">
                <BookmarkBorderIcon />
                {savedQuoteLinkText}
              </Typography>
            </Box>
          )}
        </Alert>
      </div>
    ) : (
      <div className={classes.root}>
        <Alert
          className="expiry-box-old"
          severity={remainingDays <= 3 ? 'error' : 'warning'}
          icon={<IconNotificationImportant />}
          data-testid="alert"
        >
          <AlertTitle className="noMargin">{title}</AlertTitle>
          {showRenewalLink && (
            <Typography data-test-id="notification-renewal-link" variant="body2">
              <Link className="link" onClick={handleClick}>
                {linkText}
              </Link>
            </Typography>
          )}
        </Alert>
      </div>
    )
  ) : (
    <></>
  );
};
