import { Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { DATE_TYPES } from '../../../../../../service/constant';
import { formatDate } from '../../../../../../service/util/formatDate';
import SelfService from '../../../../../../service/util/selfService';

export const PolicyCardRenewalQuote: React.FC<{
  autoRenewal: boolean | null;
  expirationDate: string;
}> = ({ autoRenewal, expirationDate }) => {
  let title = '';
  const remainingDays = autoRenewal
    ? SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate)
    : SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;
  const { t } = useTranslation('myAccount');

  let unformattedDate: string;

  if (autoRenewal) {
    unformattedDate = expirationDate;
  } else {
    unformattedDate = SelfService.calculatePolicyEndDate(expirationDate).toString();
  }

  const formattedDate = formatDate(unformattedDate, DATE_TYPES.WEEKDAY_LONG);

  switch (true) {
    case remainingDays < 1 && autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOn.sameDay');
      break;
    case remainingDays === 1 && autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOn.oneDay', {
        date: formattedDate,
      });
      break;
    case remainingDays >= 2 && remainingDays <= 10 && autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOn.willEnd', {
        date: formattedDate,
        days: 'days',
        value: remainingDays,
      });
      break;
    case remainingDays > 10 && autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOn.willEndInMoreThan10days', {
        date: formattedDate,
        days: 'days',
        insuranceType: 'home',
        value: remainingDays,
      });
      break;
    case remainingDays === 0 && !autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOff.sameDay', {
        date: formattedDate,
      });
      break;
    case remainingDays === 1 && !autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOff.oneDay', {
        date: formattedDate,
      });
      break;
    case remainingDays >= 2 && remainingDays <= 10 && !autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOff.willEnd', {
        date: formattedDate,
        days: 'days',
        value: remainingDays,
      });
      break;
    case remainingDays > 10 && !autoRenewal:
      title = t('expiryNotice.policyCardAutoRenewalOff.willEndInMoreThan10days', {
        date: formattedDate,
        days: 'days',
        value: remainingDays,
      });
      break;
  }
  return <Typography>{title}</Typography>;
};
