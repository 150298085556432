/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  CommonContainer,
  Grid,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonAccordion, { CommonAccordionProps } from '../../../../../../component/common/commonAccordion';
import { DD_PREMIUM_DETAILS_TYPE, DD_STATUS } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { DDPremiumDetails } from '../DDPremiumDetails';
import { DDTableData, DDTableIcon, DirectDebitTable } from '../directDebitTable';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '& .accordion-container': {
      gap: spacing(1),
      [breakpoints.up('sm')]: {
        marginTop: spacing(4),
      },
    },
    '& .initial-container': {
      display: 'grid',
      gap: spacing(1),
    },
    '& .premium-details-container': {
      padding: spacing(4, 0, 0, 2),
    },
    '& .footer-component': {
      '& .cell': {
        fontSize: 16,
        fontWeight: 700,
        [breakpoints.down('xs')]: {
          padding: spacing(1, 2),
        },
        [breakpoints.up('sm')]: {
          padding: spacing(1, 0),
        },
      },
      '& td:not(:first-child)': {
        textAlign: 'end',
      },
    },
  },
}));

export const getIcon = (data: DDTableData[]) => {
  const icon: DDTableIcon = { iconName: '', iconType: 'solid', color: 'dark' };
  data.map(({ Status }) => {
    switch (Status) {
      case DD_STATUS.PAID:
        icon.iconName = 'check';
        icon.color = 'success';
        break;
      case DD_STATUS.MISSED_PAYMENT:
        icon.iconName = 'close';
        icon.color = 'error';
        break;
      case DD_STATUS.PENDING:
        icon.iconName = 'clock';
        icon.iconType = 'line';

        break;
      default:
        return null;
    }
    return icon;
  });
  return icon;
};

export const Instalments: React.FC = observer(function Instalments() {
  const classes = useStyles();
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const { t } = useTranslation('myAccount');

  const InitialPaymentData: DDTableData[] = [
    isDesktop
      ? {
          Date: 'Sun 18, May 2022',
          Amount: '£140.12',
        }
      : {
          Date: 'Sun 18, May 2022',
          Amount: '£140.12',
        },
  ];

  const DDInstalmentsData: DDTableData[] = isDesktop
    ? [
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
      ]
    : [
        {
          'Due date': 'Sun 18, Jan 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, Feb 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, Nov 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 12, Oct 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 17, Sep 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 16, Dec 2022',
          Amount: '£62.19',
        },
        {
          'Due date': 'Sun 18, May 2022',
          Amount: '£62.19',
        },
      ];

  const DDPaymentsSummaryData: DDTableData[] = [
    {
      Detail: 'Total annual premium',
      Amount: '£824.24',
    },
    {
      Detail: 'Amount paid to date',
      Amount: '£140.12',
    },
  ];

  const SummaryPaymentsTableFooter = () => {
    return (
      <TableFooter>
        <TableRow className="footer-component">
          <TableCell className="cell">Remaining cost to pay</TableCell>
          <TableCell className="cell">£684.12</TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  const accordions: CommonAccordionProps[] = [
    {
      headerTitle: t('DDAccordionHeaders.initialPayment'),
      customComponent: (
        <DirectDebitTable
          data={InitialPaymentData}
          headers={Object.keys(InitialPaymentData[0])}
          icon={getIcon(InitialPaymentData)}
        />
      ),
    },
    {
      headerTitle: t('DDAccordionHeaders.DDInstalments'),
      customComponent: (
        <DirectDebitTable
          data={DDInstalmentsData}
          headers={Object.keys(DDInstalmentsData[0])}
          icon={getIcon(DDInstalmentsData)}
        />
      ),
    },
    {
      headerTitle: t('DDAccordionHeaders.summaryPayments'),
      customComponent: (
        <DirectDebitTable
          data={DDPaymentsSummaryData}
          headers={Object.keys(DDPaymentsSummaryData[0])}
          tableFooter={<SummaryPaymentsTableFooter />}
        />
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={isDesktop ? 8 : 12}>
          <Grid container direction="column" wrap="nowrap" className="accordion-container">
            {accordions.map((props, index) =>
              isDesktop ? (
                <CommonContainer color="secondary" variant="outlined" className="initial-container" key={index}>
                  <Typography variant="h4">{props.headerTitle}</Typography>
                  {props.customComponent}
                </CommonContainer>
              ) : (
                <CommonAccordion defaultBehaviour={false} key={index} expanded={false} {...props} />
              ),
            )}
          </Grid>
        </Grid>
        <Grid item xs={isDesktop ? 4 : 12}>
          <DDPremiumDetails type={DD_PREMIUM_DETAILS_TYPE.INSTALMENTS} />
        </Grid>
      </Grid>
    </div>
  );
});
