import { Instance, applySnapshot, flow, getRoot, getSnapshot, toGenerator, types } from 'mobx-state-tree';

import { IRootStore } from '..';
import { ROUTE } from '../../../constant';
import { postMotorRenewalQuoteDetails } from '../../../network/api/dashboard';
import { Header } from '../../../util/interfaceModels/interfaceModels';

import { IRenewalDetails, RenewalDetails, RenewalQuotePayload } from './renewalDetails';

const ChangeMadeObject = types.model('ChangeMadeObject', {
  changeMade: types.optional(types.boolean, false),
  id: types.identifier,
});

export const RenewalChangeStore = types
  .model('RenewalChangeStore', {
    changeMade: types.map(ChangeMadeObject),
    gotQuote: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    nextRoute: types.optional(types.string, ''),
    pendingMTAQuoteSelected: types.optional(types.boolean, false),
    renewalDetails: types.optional(RenewalDetails, () => RenewalDetails.create()),
    selectedDriverUUID: types.optional(types.string, ''),
  })
  .volatile((self) => ({
    initialState: getSnapshot(self),
  }))
  .views((self) => ({
    getChangeMade(policyNumber: string): boolean {
      return self.changeMade.get(policyNumber)?.changeMade ?? false;
    },
    get rootStore(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    addSavedQuote(renewalDetails: IRenewalDetails) {
      self.renewalDetails = renewalDetails;
    },
    setChangeMade(policyNumber: string, changeMade: boolean) {
      self.changeMade.put({ changeMade, id: policyNumber });
    },
    setGotQuote() {
      self.gotQuote = true;
    },
    setIsLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setNextRoute(route: string) {
      self.nextRoute = route;
    },
    setNotGotQuote() {
      self.gotQuote = false;
    },
    setPendingMTAQuoteSelected(pendingMTAQuoteSelected: boolean) {
      self.pendingMTAQuoteSelected = pendingMTAQuoteSelected;
    },
  }))
  .actions((self) => ({
    clearStore() {
      applySnapshot(self, self.initialState);
    },
    saveChanges: flow(function* (policyNumber: string, getQuote: boolean, usePendingMTAQuote: boolean) {
      /* istanbul ignore next */
      try {
        const headers: Header = self.rootStore.getCustomerHeader();

        const data: RenewalQuotePayload = {
          drivers: self.renewalDetails.drivers,
          endorsementReasons: self.renewalDetails.endorsementReasons,
          insured: self.renewalDetails.insured,
          rateQuote: getQuote,
          usePendingMTAQuote: usePendingMTAQuote,
          vehicle: self.renewalDetails.vehicle,
        };

        const res = yield* toGenerator(postMotorRenewalQuoteDetails(policyNumber, { headers }, data));

        const policyRenewalQuoteDetails = res.data.results;

        if (policyRenewalQuoteDetails.length > 0) {
          self.setIsLoading(false);
          self.setChangeMade(policyNumber, true);

          if (getQuote) {
            if (usePendingMTAQuote) {
              self.rootStore.dashboardStore.insurance.auto.addPendedQuoteDetails(
                policyNumber,
                policyRenewalQuoteDetails[0],
              );
            } else {
              self.rootStore.dashboardStore.insurance.auto.addRenewalQuoteDetails(
                policyNumber,
                policyRenewalQuoteDetails[0],
              );
            }

            const pendingQuote = self.rootStore.dashboardStore.insurance.auto.getPendedQuoteDetails(policyNumber);
            if (pendingQuote) {
              self.rootStore.renewalChangeStore.renewalDetails.addRenewalQuoteDetails(pendingQuote);
            }
          } else {
            self.rootStore.dashboardStore.insurance.auto.addPendedQuoteDetails(
              policyNumber,
              policyRenewalQuoteDetails[0],
            );
            const pendingQuote = self.rootStore.dashboardStore.insurance.auto.getPendedQuoteDetails(policyNumber);
            if (pendingQuote) {
              self.rootStore.renewalChangeStore.renewalDetails.addRenewalQuoteDetails(pendingQuote);
              self.setPendingMTAQuoteSelected(true);
            }
          }
          self.rootStore.renewalChangeStore.renewalDetails.setRemovedDrivers([]);

          self.rootStore.interfaceStore.dialog.closeDialog();
          self.rootStore.renewalChangeStore.renewalDetails.clearEndorsementReasons();

          self.setNextRoute(getQuote ? ROUTE.YOUR_NEW_QUOTE : ROUTE.YOUR_COVER_DETAILS);
        } else {
          self.rootStore.interfaceStore.dialog.closeDialog();
          self.rootStore.dashboardStore.insurance.auto.setShouldUpdatePolicy(true, policyNumber);
          self.setNextRoute(ROUTE.QUOTE_INELIGIBLE);
        }

        return res;
      } catch (err) {
        // update policy and renewal status
        self.rootStore.dashboardStore.insurance.auto.setShouldUpdatePolicy(true, policyNumber);

        self.rootStore.interfaceStore.dialog.closeDialog();
        self.setNextRoute(ROUTE.QUOTE_INELIGIBLE);
      }
    }),
    setSelectedDriverUUID(uuid: string) {
      self.selectedDriverUUID = uuid;
    },
  }));

export type IRenewalChangeStore = Instance<typeof RenewalChangeStore>;
