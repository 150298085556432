import axios from 'axios';

import { emails, url } from '../config';

export const replaceDpoEmail = (str: string): string => {
  return str.split('dpo@esure.com').join(emails.dpo);
};

export const replaceLinksDomain = (str: string): string => {
  return str.split('esure.com').join(url.domain);
};
export const capitalizeWords = (string: string): string => {
  const words = string.split(' ');
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return capitalizedWords.join(' ');
};

export const postCodeFormatter = (postcode: string): string => {
  const postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
  return postcode.replace(postcodeRegEx, '$1 $2').toUpperCase();
};

/**
 * Safely handles stringifying an error object
 * @param error
 * @returns the error as a string
 */
export const stringifyError = (error: Error) => {
  // If it's an axios error, then we can just stringify the error
  if (axios.isAxiosError(error)) {
    return JSON.stringify(error);
  } else {
    // This safely stringifies the Error object, without the second parameter, the result would just be an empty object
    return JSON.stringify(error, Object.getOwnPropertyNames(error));
  }
};
