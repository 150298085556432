// compares 2 objects and returns a list of changed properties
export const changedKeys = <T>(objA: T, objB: T, keysToCheck: Record<string, string>): string[] => {
  const updatedKeys: string[] = [];
  if (typeof objA === 'object' && objA !== null) {
    Object.keys(objA)
      .filter((key) => {
        return Object.values(keysToCheck).includes(key);
      })
      .forEach((key) => {
        if (objA[key as keyof T] !== objB[key as keyof T]) {
          updatedKeys.push(key);
        }
      });
  }
  return updatedKeys;
};

// utility function which brings all the properties of a nested object on the first level
export const objectFlatMap = <T>(obj: T): Record<string, unknown> => {
  // intial values of the object
  const flattened: Record<string, unknown> = {};

  // map the object keys
  Object.keys(obj || {}).forEach((k: string) => {
    // get the value of each key
    const value = obj[k as keyof T];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === null) {
      Object.assign(flattened, { [`${k}`]: value });
    }

    // if that value type is an object, re-call the function
    if (typeof value === 'object') {
      Object.assign(flattened, objectFlatMap(value as Record<string, unknown>));
    } else {
      // otherwise add this value in the initial object
      Object.assign(flattened, { [`${k}`]: value });
    }
  });

  return flattened;
};

export const getCreditCardImage = (type: string | null): string | undefined => {
  switch (type?.toLocaleLowerCase()) {
    case 'visa':
      return `./assets/images/visa.svg`;
    case 'mastercard':
      return `./assets/images/mc.svg`;
    case 'mc':
      return `./assets/images/mc.svg`;
    default:
      return undefined;
  }
};
