import { Theme, themeEsure, themeFirstAlternative, themeSheilasWheels } from '@esure-cloud/react-components';

export const checkTheme = (): Theme => {
  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'development';
  const forceThemeSW = document.cookie.indexOf('force-theme-sw=true') > -1 && isDev;
  const forceThemeFA = document.cookie.indexOf('force-theme-fa=true') > -1 && isDev;

  if (forceThemeSW || process.env.REACT_APP_BRAND === 'sw') {
    return themeSheilasWheels;
  }
  if (forceThemeFA || process.env.REACT_APP_BRAND === 'fa') {
    return themeFirstAlternative;
  }
  return themeEsure;
};
