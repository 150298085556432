import { AlertProps } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CarRegistrationFormComponent from '../../../../../../../../component/common/mta/carRegistration';
import { useMTAChatBotAttributes } from '../../../../../../../../component/common/utils/useMTAChatBotAttributes';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { JOURNEY_TYPE, ROUTE } from '../../../../../../../../service/constant';
import { ResponseVehicleLookup } from '../../../../../../../../service/network/api/dashboard';
import { useStores } from '../../../../../../../../service/state/store';
import { useForm } from '../../../../../../../../service/util/customHooks/useForm';
import { useSegment } from '../../../../../../../../service/util/customHooks/useSegment';
import { MTAFooter } from '../../common/components/footer';
import { useStepperContext } from '../../common/utils/stepperContext';

interface CarRegistrationComponentProps {
  changeCarJourney?: boolean;
  /**
   * This is a property that enables extended CarRegistration.
   * When true, after 3 failed attempts will show additional CarNotFound component.
   * It's used only in change registration number journey.
   * When false, it will show default CarRegistration component.
   * It's used everywhere else.
   */
  showCarNotFoundPrompt?: boolean;
}

export interface CarRegistrationForm {
  registrationNo: string;
}

export interface CarRegistrationProps {
  additionalText?: string;
  alert?: {
    description: string;
    severity?: AlertProps['severity'];
    title?: string;
  };
  button: string;
  description?: string;
  form?: {
    label: string;
    placeholder: string;
  };
  title: string;
}

export const CarRegistration: React.FC<CarRegistrationComponentProps> = observer(function CarRegistration({
  showCarNotFoundPrompt,
  changeCarJourney,
}) {
  const { t } = useTranslation('myAccount');
  const {
    dashboardStore: {
      fetchVehicleLookup,
      insurance: {
        auto: { mainVehicle },
      },
    },
    mtaStore: {
      pendedMTA: { setVehicle, vehicle, setVehicleLookUp, vehicleLookUp, setVehicleFound },
    },
    interfaceStore: {
      chatBot: { startChat },
      getStepAutoComplete,
      setStepAutoComplete,
      hasQuoteError,
    },
  } = useStores();

  const history = useHistory();

  const {
    nextStep,
    setTitle,
    stepsData: { journeyType, reviewStep },
    editMode,
    setEditMode,
    setStep,
    step,
  } = useStepperContext();

  const view: CarRegistrationProps = t('MTAJourney.CarRegistration.enterDetails', { returnObjects: true });
  const [content, setContent] = useState<CarRegistrationProps>(view);
  const [attempts, setAttempts] = useState(0);
  const chatBotAttr = useMTAChatBotAttributes('ssMTAVehicleNoVRN');
  const [submitting, setSubmitting] = useState(false);
  const { eventTrack } = useSegment();

  const autoCompleteCode =
    journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR ? 'ChangeYourCarVRNStep' : 'ChangeYourRegVRNStep';

  const initialCarRegValue = getStepAutoComplete(autoCompleteCode) ?? '';

  const { errors, formData, handleChange, handleSubmit, doSubmit, isSubmitting, handleResetForm, handleValidation } =
    useForm<CarRegistrationForm>({
      initialValues: { registrationNo: initialCarRegValue },
      validations: {
        registrationNo: {
          pattern: {
            allowWhiteSpaces: false,
            message: t('validations.regNoNotValid'),
            value: '^[A-Za-z0-9]{1,8}$',
          },
          required: {
            message: t('validations.regNoNotValid'),
            value: true,
          },
        },
      },
    });

  const findCar = () => {
    const { isValid } = handleValidation();
    if (formData.registrationNo !== undefined && formData.registrationNo !== '' && isValid) {
      setSubmitting(true);
      const stringNoSpace = formData.registrationNo.replace(/\s/g, '');
      fetchVehicleLookup(stringNoSpace)
        .then((res) => {
          if (res === undefined) {
            setAttempts(attempts + 1);
            const formatedRegNo = stringNoSpace.toUpperCase() || '';
            const {
              bodyTypeCode,
              engineSize,
              fuelTypeCode,
              make,
              model,
              numberOfDoors,
              numberOfSeats,
              manufactureYear,
              transmissionTypeCode,
            } = mainVehicle;
            setVehicle({
              ...vehicle,
              bodyTypeCode,
              engineSize,
              fuelTypeCode,
              make,
              manufactureYear,
              model,
              numberOfDoors,
              numberOfSeats,
              transmissionTypeCode,
              vrm: formatedRegNo,
            });
            setStepAutoComplete(autoCompleteCode, formatedRegNo);
            setVehicleFound(false);
            !changeCarJourney && nextStep();
          } else {
            const vehicleResponse: ResponseVehicleLookup = res.data.results[0];
            const {
              bodyStyleCode,
              engineCapacity,
              fuelTypeCode,
              make,
              model,
              numberOfDoors,
              numberOfSeats,
              manufactureYear,
              transmissionCode,
              vrm,
            } = vehicleResponse;
            setStepAutoComplete(autoCompleteCode, vrm);
            setVehicle({
              ...vehicle,
              bodyTypeCode: bodyStyleCode,
              engineSize: engineCapacity,
              fuelTypeCode,
              make,
              manufactureYear,
              model,
              numberOfDoors,
              numberOfSeats,
              transmissionTypeCode: transmissionCode,
              vrm,
            });
            setVehicleLookUp({
              ...vehicleLookUp,
              bodyTypeCode: bodyStyleCode,
              engineSize: engineCapacity,
              fuelTypeCode,
              make,
              manufactureYear,
              model,
              numberOfDoors,
              numberOfSeats,
              transmissionTypeCode: transmissionCode,
              vrm,
            });
            setVehicleFound(true);
            nextStep();
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  useEffect(() => {
    setTitle(
      journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
        ? t('MTAJourney.CarRegistration.enterDetails.title')
        : t('MTAJourney.CarRegistration.enterDetails.newRegTitle'),
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // If showCarNotFoundPrompt is true, we are showing the DvlaCheck component after 1 failed attempt, and so we do not
    // want to display any alert messages
    if (attempts < 3 && attempts !== 0 && !showCarNotFoundPrompt && changeCarJourney) {
      setContent({
        ...view,
        alert: t('MTAJourney.CarRegistration.carNotFound.alert', { returnObjects: true }),
      });
      eventTrack('Unable to find car reg alert viewed', { journeyType });
    } else if (attempts === 3) {
      handleResetForm();
      setContent({
        ...view,
        additionalText: t('MTAJourney.CarRegistration.registrationNotFound.additionalText'),
        alert: undefined,
        button: t('buttons.speakToASpecialist'),
      });
    }
  }, [attempts]);

  useEffect(() => {
    if (hasQuoteError) {
      history.push(ROUTE.QUOTE_INELIGIBLE);
    }
  }, [hasQuoteError]);

  const buttonClick = () => {
    if (attempts < 3) {
      findCar();
      doSubmit();
    } else {
      startChat({
        initiator: 'Finding car registration number failed - Step 2',
        ...(chatBotAttr ?? {}),
      });
      eventTrack('Invalid car reg speak to specialist link clicked', { journeyType });
    }
  };

  const handleBack = () => {
    if (editMode) {
      setEditMode(false);
      setStep(reviewStep);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <>
      <CarRegistrationFormComponent
        description={content.description}
        alert={content.alert}
        form={content.form}
        additionalText={content.additionalText}
        button={content.button}
        regNumber={formData.registrationNo}
        regError={errors.registrationNo}
        handleChange={handleChange}
        findCar={findCar}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        journeyType={journeyType}
        showTooltip={false}
      />
      <MTAFooter
        backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_REGISTRATION_NUMBER}
        cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_REGISTRATION_NUMBER}
        primaryButton={{
          buttonId: COMPONENT_ID.MTA_FIND_CAR_BUTTON,
          disabled: submitting,
          handleContinue: buttonClick,
          text: content.button,
        }}
        handleBack={handleBack}
      />
    </>
  );
});
