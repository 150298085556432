import { Grid, InputLabel, Typography } from '@esure-cloud/react-components';

import { useTranslation } from 'react-i18next';

import { IInsuredAddress } from '../../service/state/models/dashboard/autoPolicyModel';
import SelfService from '../../service/util/selfService';
import { FindAddress } from '../../view/pages/policies/policy/makeAchange/mta/common/components/findAddress';

import { Choice } from './choice';

export interface OvernightAddressProps {
  address: IInsuredAddress | undefined;
  sameOvernight: boolean;
  selectedAddress: IInsuredAddress | undefined;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  setSameOvernight: (value: React.SetStateAction<boolean>) => void;
  setSelectedAddress: (value: React.SetStateAction<IInsuredAddress | undefined>) => void;
}

export const ChangeOvernightAddress: React.FC<OvernightAddressProps> = ({
  address,
  setSameOvernight,
  sameOvernight,
  setSelectedAddress,
  selectedAddress,
  setAttempts,
}) => {
  const { t } = useTranslation('myAccount');

  return (
    <>
      <InputLabel>{t('MTAJourney.CarUsage.CarOvernight.homeAddress')}</InputLabel>
      <Typography variant="body1" className="home-address">
        <span
          dangerouslySetInnerHTML={{
            __html: SelfService.formatAddress(address, true).full,
          }}
        />
      </Typography>
      <Choice
        identifier="sameOvernight"
        onChange={(event) => setSameOvernight(event.target.value === 'true')}
        left={{ label: t('labels.no') }}
        right={{ label: t('labels.yes') }}
        radioValue={sameOvernight}
      />
      {!sameOvernight && (
        <Grid container direction="column" className="overnight-address">
          <Typography variant="h3" className="overnight-address_title">
            {t('MTAJourney.CarUsage.CarOvernight.overnightAddress')}
          </Typography>
          <FindAddress
            hideFooter
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            setNoOfAttempts={setAttempts}
          />
        </Grid>
      )}
    </>
  );
};
