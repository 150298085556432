import { Button, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { COMPONENT_ID } from '../../../service/analytics';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { ChatWithUs } from '../../../view/pages/policies/policy/makeAchange/mta/common/components/chatWithUs';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .container': {
      '&-text': {
        maxWidth: '300px',
      },
      alignItems: 'center',
      gap: spacing(4),
      padding: spacing(5),
    },
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));

export const GenericTechnicalErrorDialog: React.FC = () => {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');
  const timeoutRef = useRef<number | undefined>();

  const {
    interfaceStore: {
      dialog: { closeDialog },
      chatBot: { startChat },
    },
  } = useStores();

  const handleRefresh = () => {
    // Using a slight delay before reloading to try and give enough time for the button event tracking events to have finished
    timeoutRef.current = window.setTimeout(() => {
      closeDialog();
      window.location.reload();
    }, 500);
  };

  const handleChat = () => {
    startChat({
      initiator: 'Generic technical error dialog',
    });
  };

  useEffect(() => {
    eventTrack('Tech error viewed', {
      type: TRACK_TYPE.VIEW,
    });

    return () => {
      // Clear the timeout when component is destroyed
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.root}>
      <Grid container item className="container" alignItems="center" direction="column">
        <SVGWrapper alt="technical-error" src="technical-error.svg" width="45%" />
        <Typography variant="h2" align="center" className="container-text">
          {t('genericTechnicalErrorDialog.title')}
        </Typography>
        <Typography variant="body1">{t('genericTechnicalErrorDialog.description')}</Typography>
        <Button
          data-testid="genericTechErrorRefresh"
          color="primary"
          variant="contained"
          onClick={handleRefresh}
          size="large"
        >
          {t('buttons.refresh')}
        </Button>
        <ChatWithUs
          linkId={COMPONENT_ID.GENERIC_TECHNICAL_ERROR_DIALOG_CHAT_LINK}
          buttonText="buttons.chatWithUs"
          description={t('appbar.needHelp')}
          customHandleClick={handleChat}
        />
      </Grid>
    </Grid>
  );
};
