import { Theme } from '@esure-cloud/react-components';

import { DateTime, Duration, Settings as luxonSettings } from 'luxon';

import { LOGIN_TYPE, PARAMS_CODE, POLICY_STATUS, POLICY_STATUS_CODE, POLICY_TYPE_CODE, THEME_NAME } from '../constant';
import { AccessToken } from '../network/api/dashboard';
import {
  IAutoPolicy,
  IDriver,
  IInsuredAddress,
  IVehiclePackCoverages,
} from '../state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../state/models/dashboard/homePolicyModel';

import { sortPolicies } from './customSort';
import { LOCAL_TIMEZONE, getDateTimeNow } from './dateUtils';

export type IPolicies = IAutoPolicy[] & IHomePolicy[];

export type PolicyTypeLabel = 'home' | 'auto';

export interface PoliciesByStatus {
  [POLICY_TYPE: string]: IPolicies;
}

export interface SortedPolicies {
  [PolicyTypeLabel: string]: PoliciesByStatus;
}

luxonSettings.defaultZoneName = LOCAL_TIMEZONE;

class SelfService {
  getPolicyStatus(policy?: IAutoPolicy | IHomePolicy): POLICY_STATUS {
    if (policy) {
      const { policyStatusCd, timedPolicyStatusCd, effectiveDate } = policy;
      let response: POLICY_STATUS;
      switch (policyStatusCd) {
        case POLICY_STATUS_CODE.CANCELLED:
          if (timedPolicyStatusCd === POLICY_STATUS_CODE.IN_FORCE_PENDING) {
            if (effectiveDate && DateTime.fromISO(effectiveDate.toString()) > getDateTimeNow()) {
              response = POLICY_STATUS.PENDING;
            } else {
              response = POLICY_STATUS.ACTIVE;
            }
          } else {
            response = POLICY_STATUS.CANCELLED;
          }
          break;
        case POLICY_STATUS_CODE.EXPIRED:
          response = POLICY_STATUS.LAPSED;
          break;
        case POLICY_STATUS_CODE.IN_FORCE:
          response = POLICY_STATUS.ACTIVE;
          break;
        case POLICY_STATUS_CODE.ISSUED:
          if (timedPolicyStatusCd === '' || timedPolicyStatusCd === POLICY_STATUS_CODE.IN_FORCE) {
            response = POLICY_STATUS.ACTIVE;
          } else if (timedPolicyStatusCd === POLICY_STATUS_CODE.EXPIRED) {
            response = POLICY_STATUS.LAPSED;
          } else {
            response = POLICY_STATUS.PENDING;
          }
          break;
        default:
          response = POLICY_STATUS.CANCELLED;
      }
      return response;
    }
    return POLICY_STATUS.CANCELLED;
  }

  policiesHaveNoDuplicate(policies: IAutoPolicy[] | IHomePolicy[], policyNo: string): boolean {
    return !policies.some((policy: IAutoPolicy | IHomePolicy) => policy.policyNumber === policyNo);
  }

  getPoliciesByStatus(pols: IPolicies) {
    const expiredStatusesArray: string[] = [POLICY_STATUS.LAPSED, POLICY_STATUS.CANCELLED];

    const policies = pols.reduce(
      (acc: SortedPolicies, item: IAutoPolicy | IHomePolicy) => {
        const status = expiredStatusesArray.includes(item.policyStatus) ? POLICY_STATUS.EXPIRED : item.policyStatus;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        acc.home[status] = acc.home[status] || [];
        if (this.isHomePolicy(item)) {
          acc.home[status].push(item as IHomePolicy);
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        acc.auto[status] = acc.auto[status] || [];
        if (!this.isHomePolicy(item)) {
          acc.auto[status].push(item as IAutoPolicy);
        }

        return acc;
      },
      { auto: {}, home: {} },
    );

    return sortPolicies(policies);
  }

  toCurrency(value: number | string): string {
    const formatter = new Intl.NumberFormat('en-GB', {
      currency: 'GBP',
      minimumFractionDigits: 2,
      style: 'currency',
    });

    return formatter.format(Number(value));
  }

  calculatePolicyEndDate(endDate = ''): DateTime {
    const endPolicyDate = DateTime.fromISO(endDate);
    return endPolicyDate.minus({ days: 1 });
  }

  calculatePolicyTerm(start = '', end = ''): string {
    const e = DateTime.fromISO(end);
    const s = DateTime.fromISO(start);
    const obj = e.diff(s, ['months', 'days']).toObject();
    const result = Math.round(Duration.fromObject(obj).as('months'));

    return result <= 1 ? `${result} Month` : `${result} Months`;
  }

  vehiclePacks(policy: IAutoPolicy): IVehiclePackCoverages[] {
    let packCoverages: IVehiclePackCoverages[] = [];
    if (policy.vehicles.length > 0) {
      packCoverages = policy.vehicles[0].packCoverages;
    }
    return packCoverages;
  }

  formatAddress(address?: IInsuredAddress, multipleLines?: boolean) {
    const addressFormat = {
      end: '',
      full: '',
      home: '',
      initial: '',
    };

    if (address) {
      const { subPremises, houseName, houseNumber, street, postalTown, postcode, county, locality } = address;

      const f = [subPremises, houseName, houseNumber, street, postalTown, postcode, county];
      const i = [houseNumber, street, postalTown];
      const e = [postcode, county];
      const home = [subPremises, houseName, houseNumber, street, locality, postalTown, county];

      const formatInline = (item: (string | null)[]) => item.filter((x) => x).join(', ');

      //display on first line the houseNumber and the street, and then the rest of info, each item on new line
      //based on figma format
      const formatMultipleLines = (item: (string | null)[]) =>
        item
          .filter((x) => x)
          .map((x, index) => (index > 0 ? `${x ?? ''}<br> ` : `${x ?? ''} `))
          .join('');

      addressFormat.full = multipleLines ? formatMultipleLines(f) : formatInline(f);
      addressFormat.initial = multipleLines ? formatMultipleLines(i) : formatInline(i);
      addressFormat.end = multipleLines ? formatMultipleLines(e) : formatInline(e);
      addressFormat.home = multipleLines ? formatMultipleLines(home) : formatInline(home);
    }

    return addressFormat;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchByKey(obj: Record<string, any>, key: string): unknown {
    let result = null;

    if (obj instanceof Array) {
      for (let i = 0; i < obj.length; i++) {
        result = this.searchByKey(obj[i], key);
        if (result) break;
      }
    } else {
      for (const k in obj) {
        if (k === key) return obj[k];

        if (obj[k] instanceof Object || obj[k] instanceof Array) {
          result = this.searchByKey(obj[k], key);
          if (result) break;
        }
      }
    }

    return result;
  }

  getAccessTokenBody(urlParams: string): AccessToken | void {
    const params = new URLSearchParams(urlParams);
    let keyCode = '';
    let loginType: LOGIN_TYPE = LOGIN_TYPE.NONE;

    if (params.has(PARAMS_CODE.ACCESS_CODE)) {
      keyCode = PARAMS_CODE.ACCESS_CODE;
      loginType = LOGIN_TYPE.LOGIN;
    } else if (params.has(PARAMS_CODE.ACTIVATION_CODE)) {
      keyCode = PARAMS_CODE.ACTIVATION_CODE;
      loginType = LOGIN_TYPE.ACTIVATION;
    } else if (params.has(PARAMS_CODE.VERIFICATION_CODE)) {
      keyCode = PARAMS_CODE.VERIFICATION_CODE;
      loginType = LOGIN_TYPE.CIBA;
    }

    if (keyCode && loginType !== LOGIN_TYPE.NONE) {
      return {
        code: keyCode !== '' ? (params.get(keyCode) as string) : '',
        loginType: loginType,
        uid: params.has(PARAMS_CODE.UID) ? (params.get(PARAMS_CODE.UID) as string) : '',
      };
    }
  }

  orderedDrivers(policy: IAutoPolicy): IDriver[] {
    // This is here to handle the cases where the policy lookup failed, but we still need to add the policy into the state
    // In this case, there will be no vehicles, we do a check also for length, just in case something changes in future
    // where vehicles is an empty array
    if (!Array.isArray(policy.vehicles) || !policy.vehicles.length) {
      return [];
    }
    const mainDriverUuid = policy.vehicles[0].driverAssignments.filter((item) => item.mainDriverInd)[0].driverUuid;
    const mainDriver = policy.drivers.filter((driver) => driver.uuid === mainDriverUuid);
    const drivers = [...policy.drivers];
    const sortedItems: IDriver[] = [...mainDriver];
    drivers.forEach((driver) => {
      if (driver.uuid !== mainDriverUuid) {
        sortedItems.push(driver);
      }
    });
    return sortedItems;
  }

  //obtain format yyyy-mm-dd

  formatDate = (date: string) => {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };

  calculateRemainingDaysUntilSpecificDate = (date1: string, date2?: string) => {
    const endDate = DateTime.fromISO(date1);
    const startDate = date2 ? DateTime.fromISO(date2) : getDateTimeNow();

    const diff = endDate.diff(startDate, ['days']);
    if (diff.days < 0) {
      return -1;
    } else {
      const lessThanHalfDayLeft = diff.days % 1 > 0 && diff.days % 1 < 0.5;
      return lessThanHalfDayLeft ? Math.round(diff.days) + 1 : Math.round(diff.days);
    }
  };

  getDayOfTheWeek = (date: string) => {
    const dayOfTheWeek = DateTime.fromISO(date);
    return dayOfTheWeek.toFormat('EEE');
  };

  howMuchTimeHasPassedSince = (date: DateTime) => {
    const startDate = getDateTimeNow();

    const diff = startDate.diff(date, ['years', 'days']);

    return diff;
  };

  isHomePolicy = (policy: IHomePolicy | IAutoPolicy) =>
    policy.policyTypeCd === POLICY_TYPE_CODE.EIS_HOME || policy.policyTypeCd === POLICY_TYPE_CODE.TIA_HOME;

  themeClass = (theme: Theme) => {
    if (theme.themeName === THEME_NAME.SHEILASWHEELS) {
      return 'sheilas';
    } else if (theme.themeName === THEME_NAME.FIRSTALTERNATIVE) {
      return 'firstAlternative';
    } else {
      return '';
    }
  };

  isPolicyExpired = (policyStatus: IHomePolicy['policyStatus'] | IAutoPolicy['policyStatus']): boolean => {
    return (
      policyStatus === POLICY_STATUS.EXPIRED ||
      policyStatus === POLICY_STATUS.CANCELLED ||
      policyStatus === POLICY_STATUS.LAPSED
    );
  };
}

const instance = new SelfService();
export default instance;
