import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StepTemplate } from '../../common/components/stepTemplate';
import { Usage } from '../../common/components/usage';
import { useStepperContext } from '../../common/utils/stepperContext';

export const CarUsage: React.FC = () => {
  const { t } = useTranslation('myAccount');
  const {
    setTitle,
    nextStep,
    editMode,
    stepsData: { reviewStep },
    setStep,
  } = useStepperContext();

  useEffect(() => {
    setTitle(t('MTAJourney.CarUsage.title'));
  }, []);

  return (
    <StepTemplate>
      <Usage
        handleBack={() => setStep(reviewStep)}
        handleSave={editMode ? () => setStep(reviewStep) : () => nextStep()}
      />
    </StepTemplate>
  );
};
