import { makeStyles } from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { BannerCard } from './bannerCard';

export interface ICard {
  description: string;
  extras?: {
    backgroundColor?: string;
    layout?: 'grid';
    loc: 'alert' | 'feed' | 'feed2' | 'feed3';
  };
  imageUrl: string;
  linkText?: string;
  title: string;
  url?: string;
}

export interface IFeatureFlag {
  enabled?: boolean;
}

export type cardsFilterFunction = (cards: ICard[]) => ICard[];

declare global {
  interface Window {
    braze: {
      getCachedContentCards: () => { cards: ICard[] };
      getFeatureFlag: (name: string) => IFeatureFlag | null;
      logContentCardClick: (card: ICard) => void;
      logContentCardImpressions: (cards: ICard[]) => void;
      logFeatureFlagImpression: (name: string) => void;
      showContentCards: (unknownParam: null, cardsFilterFunction: cardsFilterFunction) => void;
    } | null;
  }
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  dot: {
    '&.active': {
      opacity: 1,
    },
    backgroundColor: palette.secondary.main,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-block',
    height: '20px',
    opacity: 0.32,
    width: '20px',
  },
  dotsContainer: {
    display: 'flex',
    gap: '8px',
    left: '50%',
    marginTop: '10px',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  proposalBanners: {
    '&.multiple': {
      marginBottom: spacing(5),
    },
    boxShadow: '0px 0px 24px -10px rgba(66, 68, 90, 0.3)',
    marginBottom: `${spacing(2)}px`,
  },
}));

export const ProposalBanners: React.FC<{ commsBanner?: boolean }> = observer(function ProposalBanners({
  commsBanner = false,
}) {
  const [cards, setCards] = useState<ICard[]>([]);
  const [index, setIndex] = useState(0);

  const classes = useStyles();

  const getCurrentCard = () => cards[index];

  useEffect(() => {
    const cachedCards = window.braze?.getCachedContentCards();
    const feed2Cards = (cachedCards?.cards ?? []).filter((it) => it.extras?.loc === 'feed2');
    const feed3Cards = (cachedCards?.cards ?? []).filter((it) => it.extras?.loc === 'feed3');

    // uncomment to use test data for braze cards
    // const cachedCards = brazeTestCards;
    // const feed2Cards = cachedCards.filter((it) => it.extras?.loc === 'feed2');
    // const feed3Cards = cachedCards.filter((it) => it.extras?.loc === 'feed3');
    setCards(commsBanner ? feed3Cards : feed2Cards);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = index + 1;
      if (newIndex < cards.length) {
        setIndex(newIndex);
      } else {
        setIndex(0);
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [cards, index]);

  useEffect(() => {
    const impressionLogged = window.sessionStorage.getItem('braze-cards-impression-logged');
    if (impressionLogged !== 'true' && cards.length) {
      window.braze?.logContentCardImpressions(cards);
      window.sessionStorage.setItem('braze-cards-impression-logged', 'true');
    }
  }, [cards]);

  const renderDots = () => {
    if (cards.length < 2) {
      return null;
    }

    return (
      <div className={classes.dotsContainer} data-testid="dots-container">
        {cards.map((card, itIndex) => {
          return (
            <span
              key={card.title}
              className={clsx(classes.dot, itIndex === index ? 'active' : null)}
              onClick={() => setIndex(itIndex)}
              onKeyPress={() => void 0}
            />
          );
        })}
      </div>
    );
  };

  if (!cards.length) {
    return null;
  }

  return (
    <div className={clsx(classes.proposalBanners, cards.length < 2 ? null : 'multiple')}>
      <BannerCard card={getCurrentCard()} />
      {renderDots()}
    </div>
  );
});
