import { useEffect, useState } from 'react';

export const getIsDocumentHidden = (): boolean => {
  return !document.visibilityState;
};

export const usePageVisibility = (): boolean => {
  const [isHidden, setIsHidden] = useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsHidden(!isHidden);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });

  return isHidden;
};
