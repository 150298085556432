import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { camelCaseString } from '../../../service/analytics';
import { useStores } from '../../../service/state/store';

import { CommonButton, CommonButtonProps } from './commonButton';

export const CloseBtn: React.FC<CommonButtonProps> = observer(function CloseBtn(props) {
  const { t } = useTranslation('myAccount');
  const {
    interfaceStore: {
      dialog: { closeDialog },
    },
  } = useStores();

  const { origin = 'unknownOrigin' } = props;

  const closePopup = () => {
    closeDialog();
  };

  return (
    <CommonButton
      {...props}
      color="secondary"
      variant="outlined"
      text={t('cancelPolicyPopup.closed.closeBtn')}
      onClick={closePopup}
      data-testid={`${camelCaseString(origin)}Close`}
      size="large"
    />
  );
});
