import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMui,
  TableRow,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DATE_TYPES } from '../../service/constant';
import { formatDate } from '../../service/util/formatDate';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '& .MuiTableCell-root': {
      padding: '8px',
    },
    '& .MuiTableRow-root': {
      '&:nth-last-child(1)': {
        border: 0,
      },
      alignItems: 'center',
      borderBottom: `1px solid ${palette.secondary.dark}40`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

interface Instalments {
  amount: number | null;
  dueDate?: string;
}

export interface TableListProps {
  cells: Instalments[];
  headers: string[];
}

export const TableList: React.FC<TableListProps> = observer(function TableList({ cells, headers }) {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  return (
    <TableContainer className={clsx(classes.root)} data-testid="monthly-payments">
      <TableMui>
        <TableHead>
          <TableRow>
            {headers.map((head, index) => {
              return <TableCell key={index}>{head}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {cells.map((row, index) => {
            return (
              <TableRow key={index} data-testid="table-row">
                <TableCell>{formatDate(row.dueDate, DATE_TYPES.SHORT_SPACE)}</TableCell>
                <TableCell>{t('core:price.pound', { value: row.amount })}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableMui>
    </TableContainer>
  );
});
