import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { SnapshotIn } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DriverVehicleDamage } from '../../service/state/models/dashboard/driversVehicleDamage';
import { useStores } from '../../service/state/store';
import { sortInPredefinedOrder } from '../../service/util/customSort';
import { ContainerMultipleLines, PolicyData, PolicyExtras } from '../common/containerMultipleLines';

export interface ExcessesTypes {
  [paramName: string]: string;
}

export const Excesses: React.FC = observer(function Excesses() {
  const { t } = useTranslation('myAccount', {});
  const excessesTypes: ExcessesTypes = t('excesses.excessTypes', { returnObjects: true });
  const [policyData, setPolicyData] = useState<PolicyData>({ title: t('excesses.title') });

  const {
    dashboardStore: {
      setCoverages,
      setDriversVehicleDamage,
      insurance: {
        auto: { mainPolicy, mainVehicle },
      },
    },
  } = useStores();

  useEffect(() => {
    const unorderedExcesses: PolicyExtras[] = [];

    const coverages = toJS(mainVehicle.coverages);
    coverages.map((item) => {
      if (item.coverageCd && item.deductibleAmount) {
        const policyExtrasItem = {
          amount: item.deductibleAmount,
          code: item.coverageCd,
          item: excessesTypes[item.coverageCd],
        };
        if (item.coverageCd === 'ad') {
          const amount = calculateMaximumTotalAccident(item.deductibleAmount);
          unorderedExcesses.push({ ...policyExtrasItem, amount: amount, details: t('excesses.upTo') });
        } else {
          unorderedExcesses.push(policyExtrasItem);
        }
      }
      return unorderedExcesses;
    });

    const ownRepairerExcess = mainVehicle.ownRepairerExcess;
    if (ownRepairerExcess) {
      unorderedExcesses.push({
        amount: ownRepairerExcess,
        code: 'ownRepairerExcess',
        item: excessesTypes['ownRepairerExcess'],
      });
    }

    if (mainVehicle.windscreenRepairExcess && mainVehicle.windscreenReplacementExcess) {
      unorderedExcesses.push({
        amount: mainVehicle.windscreenRepairExcess,
        code: 'windscreen_windscreenRepair',
        item: excessesTypes['windscreen_windscreenRepair'],
      });

      unorderedExcesses.push({
        amount: mainVehicle.windscreenReplacementExcess,
        code: 'windscreen',
        item: excessesTypes['windscreen'],
      });
    } else {
      const subCoverages = toJS(mainVehicle.subCoverages);
      const windscreenReplacement = subCoverages.filter(
        (item) => item.coverageCd === 'windscreen_windscreenReplacement',
      );
      if (windscreenReplacement.length > 0 && windscreenReplacement[0].deductibleAmount)
        unorderedExcesses.push({
          amount: windscreenReplacement[0].deductibleAmount,
          code: 'windscreen_windscreenRepair',
          item: excessesTypes['windscreen_windscreenRepair'],
        });
    }
    const orderedExcesses: PolicyExtras[] = sortInPredefinedOrder(Object.values(excessesTypes), unorderedExcesses);
    setPolicyData({ ...policyData, items: orderedExcesses });
    setCoverages({ policyExtras: orderedExcesses });
  }, [mainPolicy]);

  const calculateMaximumTotalAccident = (deductibleAmount: number) => {
    let maximumValue = deductibleAmount;
    const driversVehicleDamage: SnapshotIn<typeof DriverVehicleDamage>[] = [];

    mainPolicy.drivers.map((driver) => {
      const voluntaryExcess = mainVehicle.voluntaryExcessCode ? Number.parseInt(mainVehicle.voluntaryExcessCode) : 0;
      const totalAccidental = voluntaryExcess + deductibleAmount;

      driversVehicleDamage.push({
        compulsoryExcess: deductibleAmount,
        name: driver.person.fullName(),
        totalAccidental: totalAccidental,
        voluntaryExcess: voluntaryExcess,
      });

      if (totalAccidental > maximumValue) {
        maximumValue = totalAccidental;
      }
      return driver;
    });

    setDriversVehicleDamage({ drivers: driversVehicleDamage });
    return maximumValue;
  };
  return <ContainerMultipleLines dataPolicy={policyData} />;
});
