import { Alert, AlertTitle, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useStores } from '../../service/state/store';

interface EmergencyAlertData {
  content: string;
  enabled: boolean;
  title: string;
}

export const EmergencySiteAlert: React.FC = observer(function EmergencySiteAlert() {
  const {
    interfaceStore: { getFeatureFlagData },
  } = useStores();

  const [showAlert, setShowAlert] = useState(true);

  const alertDataAsString = getFeatureFlagData('emergencySiteAlert');
  const parsedAlertData = JSON.parse(alertDataAsString ?? '{}') as EmergencyAlertData;

  if (parsedAlertData.enabled && showAlert) {
    return (
      <Alert
        severity="info"
        onClose={() => {
          setShowAlert(false);
        }}
        style={{ marginBottom: '16px' }}
      >
        <AlertTitle>{parsedAlertData.title}</AlertTitle>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: parsedAlertData.content,
          }}
        />
      </Alert>
    );
  } else {
    return <></>;
  }
});
