import { Instance, types } from 'mobx-state-tree';

import { DIALOG_TYPE } from '../../../constant';

export const Dialog = types
  .model('Dialog', {
    chatbotAction: types.optional(types.string, ''),
    disableBackdropClick: types.optional(types.boolean, false),
    isFullScreen: types.optional(types.boolean, false),
    open: types.optional(types.boolean, false),
    title: types.optional(types.string, ''),
    type: types.maybeNull(types.enumeration<DIALOG_TYPE>(Object.values(DIALOG_TYPE))),
  })
  .actions((self) => ({
    closeDialog() {
      self.title = '';
      self.type = null;
      self.open = false;
    },
    openDialog(options: DialogOptions) {
      self.title = options.title ?? '';
      self.type = options.type;
      self.isFullScreen = options.isFullScreen ?? false;
      self.disableBackdropClick = options.disableBackdropClick ?? false;
      self.open = true;
      self.chatbotAction = options.chatbotAction ?? '';
    },
  }));

export type IDialog = Instance<typeof Dialog>;

export interface DialogOptions {
  chatbotAction?: string;
  disableBackdropClick?: boolean;
  isFullScreen?: boolean;
  title?: string;
  type: DIALOG_TYPE;
}
