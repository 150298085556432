import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueTableContainer } from '../../../../../../../../component/common/blueTableContainer';
import { COMPONENT_ID } from '../../../../../../../../service/analytics';
import { DIALOG_TYPE, LOOKUP_TYPE_PAGE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useDecodedLookupsMotor } from '../../../../../../../../service/util/customHooks/useDecodedLookups';
import { SingleColumnEditPage } from '../../carDetails/components/singleColumnEditPage';
import { useEditPage } from '../../carDetails/utils/useEditPage';
import { ChatWithUs } from '../../common/components/chatWithUs';
import { MTAFooter } from '../../common/components/footer';
import { ReusableRadioPage } from '../../common/components/reusableRadioPage';
import { useStepperContext } from '../../common/utils/stepperContext';
import { DateOfBirth } from '../../drivers/steps/dateOfBirth';
import { DriverLicense } from '../../drivers/steps/driverLicense';
import { DriverOccupation } from '../../drivers/steps/driverOccupation';
import { DriverRelationship } from '../../drivers/steps/driverRelationship';

import { DriverNameChangeDriver } from './driverNameChangeDriver';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .blue-container-content': {
      '&__bold-item': {
        fontWeight: 700,
      },
      padding: spacing(3),
    },
    gap: spacing(3),
  },
}));

export interface PagesProps {
  [key: string]: JSX.Element;
}

export interface DriverEditPageProps {
  handleBackDriverEdit?: () => void;
  handleSaveDriverEdit?: () => void;
}

export const EditDriver: React.FC = observer(function EditDriver() {
  const {
    mtaStore: {
      pendedMTA: { selectedDriver, driverFullName, changedDrivers, setSelectedDriverUUID },
    },
  } = useStores();
  const classes = useStyles();
  const { t } = useTranslation('myAccount');
  const { setTitle, nextStep, editPage, setEditPage, setStep, step, setOverrideStepCount } = useStepperContext();

  const { isDecodedLookupsFetched } = useDecodedLookupsMotor({
    driver: { ...selectedDriver },
    options: ['driver', 'insured'],
  });
  const { handleBack: handleBackDefault, handleSave, getDriverRows: rows } = useEditPage();

  const currentSelectedDriver = (uuid: string) => uuid === selectedDriver.uuid;
  const stepNo = step + 1;

  const currentIndexOfSelectedDriver = changedDrivers.findIndex(currentSelectedDriver);

  useEffect(() => {
    if (editPage === undefined) {
      setTitle(t('editDriver.title'));
    }
  }, [editPage]);

  const handleBack = () => {
    handleBackDefault();
    setTitle(t('editDriver.title'));
  };

  useEffect(() => {
    setOverrideStepCount(stepNo + currentIndexOfSelectedDriver);
  }, [currentIndexOfSelectedDriver]);

  const handleContinue = () => {
    const nextDriverIndex = (currentIndexOfSelectedDriver + 1) % changedDrivers.length;

    if (currentIndexOfSelectedDriver !== changedDrivers.length - 1) {
      setSelectedDriverUUID(changedDrivers[nextDriverIndex]);
    } else {
      nextStep();
      setOverrideStepCount(stepNo + changedDrivers.length);
    }
    window.scrollTo(0, 0);
  };

  const handleBackNonEdit = () => {
    const previousDriverIndex = (currentIndexOfSelectedDriver + (changedDrivers.length - 1)) % changedDrivers.length;

    if (currentIndexOfSelectedDriver !== 0) {
      setSelectedDriverUUID(changedDrivers[previousDriverIndex]);
    } else {
      setStep(step - 1);
      setOverrideStepCount(0);
    }
    window.scrollTo(0, 0);
  };

  const EditComponent: PagesProps = {
    [LOOKUP_TYPE_PAGE.MARITAL_STATUS]: (
      <ReusableRadioPage
        id={LOOKUP_TYPE_PAGE.MARITAL_STATUS}
        tooltipTitle={t('tooltips.maritalStatus')}
        radioTitle={t('MTAJourney.relationshipStatus.radioTitle')}
        handleBack={handleBack}
        handleSave={handleSave}
      />
    ),
    [LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS]: (
      <ReusableRadioPage id={LOOKUP_TYPE_PAGE.ACCESS_TO_OTHER_CARS} handleBack={handleBack} handleSave={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.TITLE]: (
      <DriverNameChangeDriver handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.RELATIONSHIP_TO_PROPOSER]: (
      <DriverRelationship handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.DATE_OF_BIRTH]: (
      <DateOfBirth handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.LICENCE_TYPE]: (
      <DriverLicense handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.PRIMARY_OCCUPATION]: (
      <DriverOccupation handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.SECONDARY_OCCUPATION]: (
      <DriverOccupation handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.RESIDENT_SINCE_BIRTH]: (
      <DateOfBirth handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
    [LOOKUP_TYPE_PAGE.EMPLOYMENT]: (
      <DriverOccupation handleBackDriverEdit={handleBack} handleSaveDriverEdit={handleSave} />
    ),
  };

  return (
    <Grid container direction="column">
      {editPage ? (
        <>{isDecodedLookupsFetched && EditComponent[editPage]}</>
      ) : (
        isDecodedLookupsFetched && (
          <Grid container className={classes.root}>
            <Grid container direction="column">
              {<Typography variant="body1">{t('editDriver.description')}</Typography>}
              <Grid>
                <SingleColumnEditPage
                  rows={rows(selectedDriver)}
                  tableHeader={driverFullName(selectedDriver)}
                  handleEdit={setEditPage}
                />
              </Grid>
            </Grid>
            <BlueTableContainer
              title={t('MTAJourney.ReviewChanges.reviewAddDriver.claims')}
              children={
                <Grid container className="blue-container-content" direction="column">
                  <Typography> {t('driverHistory.section3')}</Typography>
                  <Typography variant="body1" className="blue-container-content__bold-item">
                    {selectedDriver.claimsFive ? t('labels.yes') : t('labels.no')}
                  </Typography>
                </Grid>
              }
            />
            <BlueTableContainer
              title={t('MTAJourney.ReviewChanges.reviewAddDriver.convictions')}
              children={
                <Grid container className="blue-container-content" direction="column">
                  <Typography> {t('driverHistory.section4')}</Typography>
                  <Typography variant="body1" className="blue-container-content__bold-item">
                    {selectedDriver.claimsFive ? t('labels.yes') : t('labels.no')}
                  </Typography>
                </Grid>
              }
            />
            <ChatWithUs
              description={t('editDriver.footer')}
              dialogType={DIALOG_TYPE.FETCH_ADVISER_CLAIMS_CONV}
              linkId={COMPONENT_ID.CHAT_WITH_US_BUTTON}
            />
          </Grid>
        )
      )}
      {editPage === undefined && (
        <MTAFooter
          backButtonId={COMPONENT_ID.MTA_BACK_BUTTON_EDIT_DRIVER}
          cancelButtonId={COMPONENT_ID.MTA_CANCEL_BUTTON_EDIT_DRIVER}
          primaryButton={{ buttonId: COMPONENT_ID.MTA_CONTINUE_BUTTON_EDIT_DRIVER, handleContinue }}
          handleBack={handleBackNonEdit}
        />
      )}
    </Grid>
  );
});
