import { CommonContainer, Grid, IconButton, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTE } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';

import { CommonButton } from './button/commonButton';
import IconSure from './icon';
import { downloadDocument } from './utils/documentds';

const useStyles = makeStyles((theme) => ({
  buttonWidth: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  buttonsContainer: {
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  icon: {
    display: 'flex',
  },
  textContainer: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
  },
}));

export interface DocumentCardProps {
  contentText?: string;
  date: string;
  dateLabel?: string;
  documentCode: string;
  documentId: string;
  hasOlderDocuments?: boolean;
  headerText: string;
  policyNumber: undefined | null | string;
}

export const DocumentCard: React.FC<DocumentCardProps> = observer(function DocumentCard({
  contentText,
  date: dateAdded,
  dateLabel,
  documentId,
  headerText,
  policyNumber,
  documentCode,
  hasOlderDocuments,
}) {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');
  const { getDocumentsHeader } = useStores();

  const {
    interfaceStore: { isMobile, notification, isCaura },
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const setSelectedDocumentTypeCode = isHome ? home.setSelectedDocumentTypeCode : auto.setSelectedDocumentTypeCode;

  const handleDownload = () => {
    downloadDocument({
      documentId: documentId,
      eventTrack: eventTrack,
      header: getDocumentsHeader(),
      headerText: headerText,
      isCaura,
      onFail: () => {
        notification.openNotification({
          message: t('downloadFailed.content'),
          severity: 'error',
        });
      },
      optimizelyTrackKey: `My_Account_${headerText}_click`,
      policyNumber: policyNumber,
    });

    if (isHome) {
      eventTrack(`Home  | ${headerText} downloaded`, { type: TRACK_TYPE.DOWNLOAD });
    }
  };
  return (
    <Grid>
      <CommonContainer color="secondary" variant="outlined">
        <Grid container>
          <Grid container item md={4} xs>
            <Grid item>
              <IconSure color="secondary" icon="document" className={classes.icon} />
            </Grid>
            <Grid item className={classes.textContainer} xs>
              <Typography color="textPrimary" variant="h5" data-testid="headerText">
                {headerText}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {contentText}
              </Typography>
              {isMobile && (
                <>
                  <Typography color="textSecondary" variant="caption">
                    {dateLabel ?? t('documentCard.addedText')}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    &nbsp;{dateAdded}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item md={4} container justifyContent="center">
              <Typography color="textSecondary" variant="caption">
                &nbsp;{dateAdded}
              </Typography>
            </Grid>
          )}
          {isMobile && !hasOlderDocuments && (
            <Grid item>
              <IconButton size="small" aria-label="download" onClick={handleDownload}>
                <IconSure icon="download-2" size="20" color="secondary" />
              </IconButton>
            </Grid>
          )}
          {
            <Grid item xs={12} md={4}>
              <Grid container className={classes.buttonsContainer} justifyContent="flex-end">
                {hasOlderDocuments && (
                  <Grid className={classes.buttonWidth} item>
                    <CommonButton
                      color="secondary"
                      variant="outlined"
                      url={ROUTE.OLDER_DOCUMENTS}
                      text={t('documentCard.viewOlderVersions')}
                      data-testid="viewOlderDocuments"
                      onClick={() => {
                        eventTrack('View Older Documents Button Clicked');
                        setSelectedDocumentTypeCode(documentCode);
                      }}
                    />
                  </Grid>
                )}
                {((hasOlderDocuments && isMobile) || !isMobile) && (
                  <Grid className={classes.buttonWidth} item>
                    <CommonButton
                      data-testid="downloadButton"
                      color="primary"
                      onClick={handleDownload}
                      className={classes.buttonWidth}
                      variant="contained"
                      text={t('documentCard.viewButton')}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        </Grid>
      </CommonContainer>
    </Grid>
  );
});
