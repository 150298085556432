import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IInsuredAddress } from '../../../../../../../../service/state/models/dashboard/autoPolicyModel';
import { useStores } from '../../../../../../../../service/state/store';
import { FindAddress } from '../../common/components/findAddress';
import { useStepperContext } from '../../common/utils/stepperContext';

export const HomeAddress: React.FC = observer(function HomeAddress() {
  const [selectedAddress, setSelectedAddress] = useState<IInsuredAddress | undefined>();
  const {
    mtaStore: {
      pendedMTA: { insured, insuredChangedAddress, setInsuredChangedAddress, setInsuredAddress },
    },
  } = useStores();
  const { setTitle } = useStepperContext();
  const { t } = useTranslation('myAccount');

  useEffect(() => {
    setTitle(t('MTAJourney.homeAddress.title'));
    if (insuredChangedAddress) {
      setSelectedAddress(toJS(insured.address));
    }
  }, []);

  return (
    <FindAddress
      selectedAddress={selectedAddress}
      setSelectedAddress={setSelectedAddress}
      onContinue={(nextStep) => {
        if (selectedAddress) {
          setInsuredChangedAddress(true);
          setInsuredAddress(selectedAddress);
          nextStep();
        }
      }}
      chatbotAttr="ssMTAVehicleNoAddress"
    />
  );
});
