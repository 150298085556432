import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CommonContainer,
  CommonContainerProps,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  alpha,
  darken,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { memo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { useStores } from '../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../service/util/customHooks/useSegment';
import IconSure, { IconType as HeaderIconType } from '../common/icon';

const useStyles = makeStyles((theme) => ({
  accordionContent: {
    padding: theme.spacing(2, 3),
  },
  accordionContentSummary: {
    '&::after': {
      background: theme.palette.divider,
      bottom: 0,
      content: '""',
      height: '1px',
      position: 'absolute',
      width: '96%',
    },
    position: 'relative',
  },
  accordionFooter: {
    borderTop: `1px solid ${theme.palette.divider}`,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 3),
    minHeight: 65,
    padding: theme.spacing(2, 0),
  },
  accordionFooterButton: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  accordionHeader: {
    padding: theme.spacing(0.7, 1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  accordionHeaderIcon: {
    marginRight: theme.spacing(2),
  },
  accordionHeaderInfo: {
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      '&.has-icon': {
        paddingLeft: theme.spacing(5),
      },
      justifyContent: 'space-between',
    },
  },
  accordionHeaderLabel: {
    '&.error': {
      background: alpha(theme.palette.error.main, 0.1),
      color: darken(theme.palette.error.main, 0.6),
    },
    '&.success': {
      background: alpha(theme.palette.success.main, 0.1),
      color: darken(theme.palette.success.main, 0.6),
    },
    '&.warning': {
      background: alpha(theme.palette.warning.main, 0.1),
      color: darken(theme.palette.warning.main, 0.6),
    },
    borderRadius: 5,
    padding: theme.spacing(0.4, 1),
  },
  bold: {
    '& span': {
      fontWeight: 700,
    },
  },
  footerIcon: {
    display: 'flex',
    marginTop: '8px',
  },
  footerText: {
    margin: '4px',
  },
  heading: {
    '& span': {
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  indented: {
    marginLeft: theme.spacing(3.5),
    width: `calc(100% - ${theme.spacing(3)}px)`,
  },
  listIcon: {
    margin: 0,
    marginLeft: -4,
    minWidth: theme.spacing(4),
  },
  listText: {
    margin: 0,
  },
  mobileThirdPartyLogo: {
    marginTop: theme.spacing(-2.2),
  },
  paddingTop2: {
    padding: theme.spacing(2, 2),
  },

  root: {
    '& a': {
      cursor: 'pointer',
    },
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      '& .column-no-title': {
        marginTop: -45,
        paddingTop: 0,
      },
      '& .first-item-title': {
        marginTop: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .column-border': {
        '&:before': {
          backgroundColor: theme.palette.divider,
          content: `''`,
          height: '85%',
          left: -20,
          position: 'absolute',
          top: 15,
          width: 1,
        },
      },
      '& .column-no-title': {
        marginTop: 24,
      },
      '& .first-item-title': {
        marginTop: 0,
        paddingTop: 0,
      },
    },
  },
}));

export type IconType = 'check' | 'times' | 'circle';
export type LabelStatus = 'success' | 'warning' | 'error' | '';

export interface ListItemWithOptions {
  listText: string;
  options: string[];
}

export interface ColumnsProps {
  description?: string;
  icon?: IconType;
  list?: string[];
  listWithOptions?: ListItemWithOptions[];
  subtitle?: string;
  title?: string;
  value?: string;
}

export interface ProfileOptions {
  description?: string;
  title?: string;
}

export interface CommonAccordionProps {
  color?: CommonContainerProps['color'];
  columns?: ColumnsProps[];
  customComponent?: React.ReactElement;
  defaultBehaviour?: boolean;
  downloadLink?: string | React.ReactElement;
  eventId?: string;
  expanded?: boolean;
  footerButtonText?: string;
  footerComponent?: React.ReactElement;
  footerIcon?: string;
  footerIconText?: string;
  footerIconType?: HeaderIconType;
  footerSubtitle?: string;
  footerTitle?: string;
  headerIcon?: string;
  headerIconType?: HeaderIconType;
  headerInfo?: string;
  headerLabel?: string;
  headerLabelColour?: LabelStatus;
  headerSubtitle?: string;
  headerTitle?: string;
  onChange?: () => void;
  onClick?: () => void;
  onFooterButton?: () => void;
  options?: (ProfileOptions | boolean)[];
  redirectUrl?: string;
  showIcon?: boolean;
  summary?: string;
  thirdPartyLogo?: string;
}

const CommonAccordion: React.FC<CommonAccordionProps> = observer(function CommonAccordion(props) {
  const classes = useStyles();
  const { eventTrack } = useSegment();

  const ref = useRef<null | HTMLDivElement>(null);

  const {
    interfaceStore: { isDesktop },
  } = useStores();

  const scroll = () => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const {
    customComponent,
    color = 'secondary',
    defaultBehaviour = true,
    eventId,
    headerTitle = '',
    headerIcon,
    headerIconType,
    footerIcon,
    footerIconType,
    footerIconText,
    headerInfo,
    headerLabel,
    headerLabelColour = 'default',
    summary,
    columns,
    footerTitle,
    footerSubtitle,
    footerButtonText,
    footerComponent,
    headerSubtitle,
    downloadLink,
    thirdPartyLogo,
    redirectUrl,
    expanded = false,
    showIcon = true,
    onChange,
    onClick,
  } = props;

  const [open, setOpen] = useState(expanded);

  const getIconColor = (icon: IconType) => (icon === 'check' ? 'success' : icon === 'times' ? 'error' : 'secondary');

  const handleChange = () => {
    if (onChange) onChange();
    if (!open) eventTrack('Accordion Opened', { item: headerTitle, type: TRACK_TYPE.ACCORDION });

    setTimeout(() => scroll(), 250);

    setOpen(!open);
  };

  return (
    <CommonContainer
      className={clsx(classes.root, 'classNameBorder')}
      color={open ? 'secondary' : color}
      variant="outlined"
    >
      <Accordion
        expanded={defaultBehaviour ? expanded : open}
        onChange={handleChange}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          ref={ref}
          expandIcon={<IconSure color="secondary" icon="chevron-down" size="26" data-testid="accordion-summary" />}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.accordionHeader}
          >
            <Grid container item alignItems="center">
              {headerIcon && (
                <IconSure
                  className={classes.accordionHeaderIcon}
                  icon={headerIcon}
                  color={color}
                  size="26"
                  type={headerIconType}
                />
              )}
              <Typography className="headerTitle" variant="h4">
                {headerTitle}
              </Typography>
            </Grid>

            <Grid
              container
              item
              justifyContent="flex-end"
              className={clsx(classes.accordionHeaderInfo, headerIcon && 'has-icon')}
            >
              {headerLabel && (
                <Typography variant="h5" className={clsx(classes.accordionHeaderLabel, headerLabelColour)}>
                  {headerLabel}
                </Typography>
              )}
              {headerInfo && <Typography>{headerInfo}</Typography>}
            </Grid>
          </Grid>
        </AccordionSummary>

        {customComponent && customComponent}

        <AccordionDetails className={clsx(classes.accordionContent, 'accordionContent')}>
          <Grid container spacing={5} className="accordionGrid">
            {summary && (
              <Grid item xs={12} className={classes.accordionContentSummary}>
                <Typography variant="body2">
                  <Trans i18nKey={summary} components={[<strong key={Symbol().toString()} />]} />
                </Typography>
              </Grid>
            )}
            {headerSubtitle && (
              <Grid item className="multiGridItem">
                <Typography variant="h4">{headerSubtitle}</Typography>
              </Grid>
            )}

            {columns?.map(({ title, subtitle, description, list, listWithOptions, icon = 'check' }, index) => {
              const iconColor = getIconColor(icon);

              return (
                <Grid container item direction="column" xs={12} key={index} className="multiGridItem">
                  <Grid
                    container
                    direction="row"
                    item
                    justifyContent={title ? 'space-between' : 'flex-end'}
                    wrap="nowrap"
                  >
                    {title && (
                      <Grid item>
                        <Typography variant="h5">
                          <Trans>{title}</Trans>
                        </Typography>
                      </Grid>
                    )}
                    {thirdPartyLogo && index === 1 && isDesktop && (
                      <Grid item>
                        <img alt="rac" src={thirdPartyLogo} />
                      </Grid>
                    )}
                    {thirdPartyLogo && index === 0 && !isDesktop && (
                      <Grid item>
                        <img alt="rac" src={thirdPartyLogo} />
                      </Grid>
                    )}
                  </Grid>

                  {subtitle && <Typography variant="body2">{subtitle}</Typography>}

                  {description && <Typography variant="h4">{description}</Typography>}

                  <List
                    dense
                    className={clsx(
                      index > 0 ? 'column-border' : '',
                      (!title && !isDesktop) || (!title && !thirdPartyLogo) ? 'column-no-title' : '',
                      listWithOptions ? 'first-item-title' : '',
                    )}
                  >
                    {list?.map((item, _index) => (
                      <ListItem dense disableGutters key={_index} alignItems="flex-start">
                        {showIcon ? (
                          <ListItemIcon className={classes.listIcon}>
                            <IconSure icon={icon} color={iconColor} strokeWidth="2.5" />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}

                        <ListItemText
                          className={classes.listText}
                          primary={<Trans i18nKey={item} components={[<strong key={Symbol().toString()} />]} />}
                        />
                      </ListItem>
                    ))}

                    {listWithOptions?.map((item, _index) => (
                      <ListItem
                        dense
                        disableGutters
                        key={_index}
                        alignItems="flex-start"
                        className={clsx(
                          item.options.indexOf('indented') > -1 ? classes.indented : '',
                          item.options.indexOf('bold') > -1 ? classes.bold : '',
                          item.options.indexOf('heading') > -1 ? classes.heading : '',
                        )}
                      >
                        {item.options.indexOf('noIcon') < 0 && (
                          <ListItemIcon className={classes.listIcon}>
                            <IconSure icon={icon} color={iconColor} strokeWidth="2.5" />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          className={classes.listText}
                          primary={
                            <Trans i18nKey={item.listText} components={[<strong key={Symbol().toString()} />]} />
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              );
            })}

            {showIcon ? '' : <Divider orientation="horizontal" />}
          </Grid>
        </AccordionDetails>

        {(footerTitle || footerSubtitle || footerComponent || footerButtonText || downloadLink || footerIcon) && (
          <AccordionActions className={clsx(classes.accordionFooter, 'footerClass')}>
            <Grid container direction="column">
              {downloadLink &&
                (typeof downloadLink === 'string' ? (
                  <Grid container item alignItems="center">
                    <IconSure icon="download" color="secondary" size="20" />
                    <Link variant="body2">{downloadLink}</Link>
                  </Grid>
                ) : (
                  downloadLink
                ))}

              {(footerTitle || footerSubtitle) && (
                <Grid item>
                  <Typography variant="h5">{footerTitle}</Typography>
                  <Typography variant="body2">
                    <Trans>{footerSubtitle}</Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>

            {footerButtonText && (
              <Grid container className={classes.accordionFooterButton}>
                <Link underline="none" target="_blank" href={redirectUrl}>
                  <Button color="primary" variant="contained">
                    {footerButtonText}
                  </Button>
                </Link>
              </Grid>
            )}

            {footerComponent && footerComponent}

            {footerIcon && (
              <Grid container className="borderStyle">
                <Link
                  underline="none"
                  target="_blank"
                  onClick={onClick}
                  className={classes.footerIcon}
                  data-testid={eventId}
                >
                  <IconSure
                    className={classes.accordionHeaderIcon}
                    icon={footerIcon}
                    color={color}
                    size="26"
                    type={footerIconType}
                  />
                  <Typography className={classes.footerText} variant="h4">
                    {footerIconText}
                  </Typography>
                </Link>
              </Grid>
            )}
          </AccordionActions>
        )}
      </Accordion>
    </CommonContainer>
  );
});

export default memo(CommonAccordion);
