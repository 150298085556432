import { makeStyles } from '@esure-cloud/react-components';

import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSafeAreaTop, sendCloseHook } from '../../../lib/caura-bridge';

const useStyles = makeStyles(() => ({
  closeButton: {
    backgroundColor: '#2663da',
    border: 'none',
    bottom: '24px',
    color: '#FFFFFF',
    position: 'absolute',
    right: '12px',
  },
  root: {
    backgroundColor: '#2663da',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    position: 'relative',
  },
  text: {
    fontSize: '24px',
    fontWeight: 'bolder',
  },
}));

export interface CauraHeaderProps {
  isCaura: boolean;
}

export const CauraHeaderComponent: React.FC<CauraHeaderProps> = ({ isCaura = false }) => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  if (!isCaura) return null;

  const safeAreaTop = getSafeAreaTop();
  const paddingTop = `${Number(safeAreaTop) || 24}px`;

  return (
    <div style={{ paddingTop }} className={classes.root}>
      <div data-testid="caura-header-title" className={classes.text}>
        {t('caura.headerTitle')}
      </div>
      <button data-testid="caura-header-close-button" className={classes.closeButton} onClick={() => sendCloseHook()}>
        <CloseIcon />
      </button>
    </div>
  );
};
