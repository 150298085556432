import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Accordion as MuiAccordion,
  Typography,
  makeStyles,
} from '@esure-cloud/react-components';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { memo, useState } from 'react';

import { useStores } from '../../../service/state/store';
import IconSure, { IconType } from '../icon';

export interface ItemAccordion {
  label?: string;
  value: string | null;
}
export interface ColumnValues {
  values: ItemAccordion[];
}
export interface AccordionProps {
  columns?: ColumnValues[];
  expanded?: boolean;
  footer?: {
    additionalText?: string;
    buttonText?: string;
  };
  header?: {
    headerButtonText?: string;
    showToggleText?: boolean;
  };
  icon: string;
  iconType?: IconType;
  onChange?: () => void;
  onClick?: (value?: string) => void;
  subtitle: string;
  title: string;
}
const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    '& .content': {
      '&_cols': {
        [breakpoints.down('sm')]: {
          flexDirection: 'column',
          gap: spacing(1),
        },
        [breakpoints.between(960, 1036)]: {
          gap: spacing(5),
        },
        '&-item': {
          borderBottom: `1px solid ${palette.divider}`,
          padding: spacing(1.5, 0),
        },
        gap: spacing(21),
      },
      border: `2px solid ${palette.secondary.main}70`,
      borderRadius: spacing(0, 0, 1, 1),
      borderTop: 'none',
      gap: spacing(2),
      padding: spacing(2),
      paddingTop: 0,
    },
    '& .description': { lineHeight: '1.75rem', marginLeft: spacing(1) },
    '& .footer': {
      '&_button': {
        margin: spacing(2, 0),
      },
    },
    '& .header': {
      '&_container': {
        '&-extra': {
          '&_icon': {
            '&-open': {
              transform: 'rotate(180deg)',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            transform: 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '&_link': {
            fontWeight: 700,
            marginBottom: spacing(4),
          },
          '&_text': {
            marginTop: spacing(2),
          },
          marginTop: spacing(2),
        },
        '&-icon': {
          marginRight: spacing(2),
        },
        '&-title': {
          marginBottom: spacing(3),
        },
      },
      border: `2px solid ${palette.secondary.main}70`,
      borderRadius: spacing(1),
      padding: spacing(2),
    },
    '& .title': { whiteSpace: 'nowrap' },
    '&.open': {
      '& .header': {
        borderBottom: 'none',
        borderRadius: spacing(1, 1, 0, 0),
      },
    },
  },
}));
const Accordion: React.FC<AccordionProps> = observer(function Accordion(props) {
  const {
    interfaceStore: { isDesktop },
  } = useStores();
  const { expanded, onChange, icon, iconType = 'line', title, subtitle, columns, header, onClick, footer } = props;
  const [open, setOpen] = useState(expanded ?? false);
  const classes = useStyles();
  const handleChange = () => {
    setOpen(!open);
    onChange?.();
  };

  const handleClick = () => {
    onClick?.();
  };

  const handleHeaderClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleClick();
  };
  const transformToId = (title: string) => {
    const buttonId = title.toLowerCase().replace(/ /g, '-');
    return `${buttonId}-accordion-button`;
  };
  return (
    <MuiAccordion
      data-testid="accordion"
      className={clsx(classes.root, expanded ? 'open' : 'closed')}
      expanded={expanded}
    >
      <AccordionSummary expandIcon={null} data-testid="accordion-header" onClick={handleChange} className="header">
        <Grid container direction="column" className="header_container">
          <Grid container item alignItems="center" xs className="header_container-title">
            <IconSure color="secondary" icon={icon} type={iconType} size="26" className="header_container-icon" />
            <Typography variant="h2">{title}</Typography>
          </Grid>
          <Typography variant="body1">{subtitle}</Typography>
          {header?.headerButtonText && (
            <Grid item xs className="header_container-extra">
              <Grid container justifyContent="space-between" className="header_container-extra_link">
                <Typography color="secondary">{header.showToggleText ? 'Show less' : 'Show more'}</Typography>
                <IconSure
                  className={`header_container-extra_icon${header.showToggleText ? '-open' : ''}`}
                  color="secondary"
                  icon="chevron-down"
                  size="26"
                />
              </Grid>
              {!header.showToggleText && (
                <>
                  {header.headerButtonText && (
                    <Button
                      color="secondary"
                      onClick={handleHeaderClick}
                      variant="outlined"
                      data-testid={transformToId(title)}
                    >
                      {header.headerButtonText}
                    </Button>
                  )}
                  {footer?.additionalText && (
                    <Typography className="header_container-extra_text">{footer.additionalText}</Typography>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <Grid container component={AccordionDetails} className="content" data-testid="expanded-accordion">
        <Grid container item className="content_cols">
          {columns?.map(({ values }, index) => {
            return (
              <Grid container item direction={isDesktop ? 'column' : 'row'} xs key={index}>
                {values.map(
                  ({ label, value }, valueIndex) =>
                    value && (
                      <Grid
                        container
                        className="content_cols-item"
                        justifyContent="space-between"
                        wrap="nowrap"
                        key={valueIndex}
                      >
                        {label && (
                          <Typography variant="body1" className="title">
                            {label}
                          </Typography>
                        )}
                        <Typography
                          variant="h4"
                          className="description"
                          align="right"
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      </Grid>
                    ),
                )}
              </Grid>
            );
          })}
        </Grid>
        {footer?.buttonText ? (
          <Grid className="footer" container justifyContent="flex-start" direction="column" alignItems="flex-start">
            {footer.buttonText && (
              <Button className="footer_button" color="secondary" onClick={handleClick} variant="outlined">
                {footer.buttonText}
              </Button>
            )}
            {footer.additionalText && <Typography>{footer.additionalText}</Typography>}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </MuiAccordion>
  );
});
export default memo(Accordion);
