import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonButton, CommonButtonProps } from './commonButton';
import { useDispatchMakeAClaim } from './useDispatchMakeAClaim';

export const MakeAClaimBtn: React.FC<CommonButtonProps> = observer(function MakeAClaimBtn(props) {
  const { t } = useTranslation('myAccount');
  const { url, text } = props;

  const dispatchMakeAClaim = useDispatchMakeAClaim();

  const isThirdParty = dispatchMakeAClaim.isThirdParty();
  const overrideUrl = isThirdParty ? '' : url;

  return (
    <CommonButton
      data-testid="makeaclaim-button"
      {...props}
      onClick={() => {
        dispatchMakeAClaim.dispatch(overrideUrl);
      }}
      text={text ?? t('appbar.makeClaim')}
      // url={overrideUrl ?? ROUTE.CLAIMS}
    />
  );
});
