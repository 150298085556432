import { ItemList } from '../../view/pages/policies/policy/makeAchange/mta/common/components/navigationLayout';
import { JOURNEY_TYPE } from '../constant';
import { ICompletedMTAJourney } from '../state/models/mta';

export const removeCardByTitle = (cards: ItemList[], titlesToRemove: string[]): void => {
  for (const title of titlesToRemove) {
    const index = cards.findIndex((card) => card.title === title);
    if (index > -1) {
      cards.splice(index, 1);
    }
  }
};

export const allAllowedChanges = [
  'Change your Car',
  'Registration number',
  'Car mileage and usage',
  'Car modifications',
  'Other changes',

  'Home address',
  'Personal details',

  'Add driver',
  'Change driver details',
  'Remove driver',
];
export const ChangeYourCar = [
  'Change your car',
  'Registration number',
  'Car mileage and usage',
  'Car modifications',
  'Other changes',
  'Employment details',
];
export const RegisterationNumber = ['Change your car', 'Registration number', 'Employment details'];
export const CarMileageAndUsage = ['Car mileage and usage', 'Employment details'];
export const CarModifications = ['Car modifications', 'Employment details'];
export const OtherChanges = ['Other changes', 'Employment details'];
export const HomeAddress = ['Home address', 'Employment details'];
export const PersonalDetails = ['Personal details', 'Employment details'];
export const AddDriver = ['Add driver', 'Employment details'];
export const ChangeDriverDetails = ['Change driver details', 'Employment details'];
export const RemoveDriver = ['Remove driver', 'Employment details'];

export const removeCompletedJourneyByTitle = (
  cards: ItemList[],
  completedMTAJourneys: ICompletedMTAJourney[],
): void => {
  if (completedMTAJourneys.length === 0) {
    return;
  }
  if (cards.length === 0) {
    return;
  }
  completedMTAJourneys.forEach((journey) => {
    switch (journey.completedJourney) {
      case JOURNEY_TYPE.ADD_DRIVER:
        removeCardByTitle(cards, AddDriver);
        break;
      case JOURNEY_TYPE.CAR_MILEAGE_AND_USAGE:
        removeCardByTitle(cards, CarMileageAndUsage);
        break;
      case JOURNEY_TYPE.CAR_MODIFICATIONS:
        removeCardByTitle(cards, CarModifications);
        break;
      case JOURNEY_TYPE.CHANGE_DRIVER:
        removeCardByTitle(cards, ChangeDriverDetails);
        break;
      case JOURNEY_TYPE.CHANGE_HOME_ADDRESS:
        removeCardByTitle(cards, HomeAddress);
        break;
      case JOURNEY_TYPE.CHANGE_PERSONAL_DETAILS:
        removeCardByTitle(cards, PersonalDetails);
        break;
      case JOURNEY_TYPE.CHANGE_YOUR_CAR:
        removeCardByTitle(cards, ChangeYourCar);
        break;
      case JOURNEY_TYPE.OTHER_CHANGES:
        removeCardByTitle(cards, OtherChanges);
        break;
      case JOURNEY_TYPE.REGISTRATION_NUMBER:
        removeCardByTitle(cards, RegisterationNumber);
        break;
      case JOURNEY_TYPE.REMOVE_DRIVER:
        removeCardByTitle(cards, RemoveDriver);
        break;
    }
  });
};
