import { ClaimsApp } from '../view/pages/claims/ClaimsApp';
import { Landing } from '../view/pages/landing';
import { Login } from '../view/pages/login';
import { PoliciesApp } from '../view/pages/policies/PoliciesApp';
import { HowToMakeAClaim } from '../view/pages/policies/homePolicy/howToMakeAClaim';
import { PoliciesDashboardMobile } from '../view/pages/policies/policiesDashboards/mobile';
import { PolicyDetails } from '../view/pages/policies/policy';
import { QuoteIneligible } from '../view/pages/policies/policy/makeAchange/mta/carDetails/components/quoteIneligible';
import { RenewalQuote } from '../view/pages/policies/policy/renewals/pages/renewalQuote';
import { RenewalSummary } from '../view/pages/policies/policy/renewals/renewalSummary';
import { RenewalMakeChange } from '../view/pages/policies/policy/renewals/yourCoverDetails/editDetails';
import { YourCoverDetails } from '../view/pages/policies/policy/renewals/yourCoverDetails/yourCoverDetails';
import { YourNewQuote } from '../view/pages/policies/policy/renewals/yourQuote/yourNewQuote';
import { YourSavedQuote } from '../view/pages/policies/policy/renewals/yourQuote/yourSavedQuote';
import { YourRenewalQuotes } from '../view/pages/policies/policy/renewals/yourRenewalQuotes/yourRenewalQuotes';
import { SettingsApp } from '../view/pages/settings/SettingsApp';
import { PaymentMethods } from '../view/pages/settings/accountManagement/paymentMethods';

import { ROUTE } from './constant';
import { claimsChildren, motorPolicyDetailsChildren } from './util/routes';

export interface RouteData {
  children?: RouteData[];
  component: null | React.FC;
  exact: boolean;
  name?: string;
  path: string;
  showInSidebar?: boolean;
}

export const routeConfig: RouteData[] = [
  {
    children: [
      {
        children: [...motorPolicyDetailsChildren],
        component: PolicyDetails,
        exact: true,
        path: ROUTE.POLICY_DETAILS,
      },
      {
        component: RenewalQuote,
        exact: true,
        path: ROUTE.RENEWAL_QUOTE_DETAILS,
      },
      {
        component: RenewalSummary,
        exact: true,
        path: ROUTE.YOUR_RENEWAL_SUMMARY,
      },
      {
        component: YourNewQuote,
        exact: true,
        path: ROUTE.YOUR_NEW_QUOTE,
      },
      {
        component: YourRenewalQuotes,
        exact: true,
        path: ROUTE.YOUR_RENEWAL_QUOTES,
      },
      {
        component: YourSavedQuote,
        exact: true,
        path: ROUTE.YOUR_SAVED_QUOTE,
      },
      {
        children: [
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CHANGE_CAR,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CHANGE_MODIFICATIONS,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CHANGE_REG,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CHANGE_SECURITY,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_LEGAL_OWNER,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_REGISTERED_KEEPER,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CAR_MILEAGE_AND_USAGE,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_OVERNIGHT_LOCATION,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DAYTIME_LOCATION,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_NAME,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DRIVER_NAME,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_RELATIONSHIP_STATUS,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_HOME_ADDRESS,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_RESIDENTIAL_STATUS,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CHILDREN_AT_HOME,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_CARS_AT_HOME,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_ADD_DRIVER,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_REMOVE_DRIVER,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_MARITAL_STATUS,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DRIVER_RELATIONSHIP,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_OCCUPATION,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DRIVER_OCCUPATION,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DRIVER_LICENSE,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_DRIVER_HISTORY,
          },
          {
            component: RenewalMakeChange,
            exact: true,
            path: ROUTE.YOUR_COVER_DETAILS_YOUR_LICENSE,
          },
        ],
        component: YourCoverDetails,
        exact: true,
        path: ROUTE.YOUR_COVER_DETAILS,
      },
    ],
    component: PoliciesApp,
    exact: true,
    name: 'breadcrumbs.policies',
    path: ROUTE.DASHBOARD,
    showInSidebar: true,
  },
  {
    component: Landing,
    exact: true,
    path: ROUTE.LANDING,
  },
  {
    component: Login,
    exact: true,
    path: ROUTE.LOGIN,
  },
  {
    children: [...claimsChildren],
    component: ClaimsApp,
    exact: true,
    name: 'breadcrumbs.claims',
    path: ROUTE.CLAIMS,
  },
  {
    component: SettingsApp,
    exact: true,
    name: 'breadcrumbs.settings',
    path: ROUTE.ACCOUNT,
    showInSidebar: true,
  },
  {
    component: SettingsApp,
    exact: false,
    path: ROUTE.COMMUNICATION,
  },
  {
    component: PaymentMethods,
    exact: true,
    path: ROUTE.PAYMENT_METHODS,
  },
  {
    component: QuoteIneligible,
    exact: true,
    path: ROUTE.QUOTE_INELIGIBLE,
  },
  {
    component: PoliciesDashboardMobile,
    exact: false,
    path: ROUTE.DASHBOARD,
  },
  {
    component: PoliciesDashboardMobile,
    exact: false,
    path: ROUTE.DOCUMENTS_MOBILE_TAB,
  },
  {
    component: PoliciesDashboardMobile,
    exact: false,
    path: ROUTE.CLAIMS_MOBILE_TAB,
  },
  {
    component: HowToMakeAClaim,
    exact: false,
    path: ROUTE.HOW_TO_MAKE_A_CLAIM_HOME,
  },
];
