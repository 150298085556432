import { Grid, Link, Typography, makeStyles } from '@esure-cloud/react-components';

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../../../component/common/icon';
import { DIALOG_TYPE } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .chat-section': {
      '&__link': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
      gap: 10,
    },
    paddingTop: spacing(2),
  },
}));

export interface ChatWithUsProps {
  buttonText?: string;
  customHandleClick?: () => void;
  description: string;
  dialogType?: DIALOG_TYPE;
  linkId?: string;
}

export const ChatWithUs: React.FC<ChatWithUsProps> = ({
  description: title,
  customHandleClick,
  buttonText = 'buttons.chatWithASpecialist',
  dialogType = DIALOG_TYPE.FETCH_ADVISER_CHANGES,
  linkId,
}) => {
  const {
    interfaceStore: {
      dialog: { openDialog },
    },
  } = useStores();
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const handleChat = () => {
    if (customHandleClick) {
      customHandleClick();
    } else {
      openDialog({
        title: t('fetchAdviserPopup.details.title'),
        type: dialogType,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" className="chat-section">
        <Typography>{title}</Typography>
        <IconSure icon="chat" color="secondary" type="solid" />
        <Link data-testid={linkId} onClick={handleChat} className="chat-section__link">
          <Typography>{t(buttonText)}</Typography>
        </Link>
      </Grid>
    </div>
  );
};
