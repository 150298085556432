import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { JOURNEY } from '../../../service/analytics';
import { DIALOG_TYPE, ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { useSegment } from '../../../service/util/customHooks/useSegment';
import SelfService from '../../../service/util/selfService';
import { ButtonOfSelectedPolicyProps } from '../../common/button/commonButton';

export function useDispatchMakeAChange({ policy }: ButtonOfSelectedPolicyProps) {
  const { t } = useTranslation('myAccount');
  const { eventTrack } = useSegment();
  const history = useHistory();

  const {
    dashboardStore: {
      insurance: {
        auto: {
          setSelectedPolicyNumber: setSelectedPolicyNumberAuto,
          policySelected: { paymentPlan },
        },
        home: { setSelectedPolicyNumber: setSelectedPolicyNumberHome },
        isHome,
      },
    },
    interfaceStore: {
      dialog: { openDialog },
      hasFeatureFlag,
    },
    mtaStore: { clearStore },
  } = useStores();

  const { brand, policyNumber, futureMtaEffectiveDate, expirationDate, hasPendingInstallments } = policy;
  const isDDCustomer = paymentPlan !== '' && paymentPlan !== 'YearlyCardPayment';

  const remainingDays = SelfService.calculateRemainingDaysUntilSpecificDate(expirationDate) - 1;

  const makeAChange = () => {
    eventTrack('Change Policy Started', {
      brand: brand,
      journey: JOURNEY.SERVICE,
      policyNumber,
      product: isHome ? 'Home' : 'Motor',
      productType: isHome ? 'Core' : 'Flex',
    });
    isHome ? setSelectedPolicyNumberHome(policyNumber) : setSelectedPolicyNumberAuto(policyNumber);
    clearStore();
    switch (true) {
      case isHome:
        openDialog({
          title: t('fetchAdviserPopup.discussWithSpecialist'),
          type: DIALOG_TYPE.UNABLE_TO_HELP_ONLINE,
        });
        break;
      case !!futureMtaEffectiveDate:
        openDialog({
          title: t('fetchAdviserPopup.title'),
          type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
        });
        break;
      //TODO remove the commented code below if business is happy with all brands activating mta
      // case isDDCustomer && !migrated && brand !== BRAND.ESURE && !hasFeatureFlag('BDILMM-2900'): //DD (SW and FA) non-migrated customers are not allowed to do MTAs
      // case isDDCustomer && migrated && brand !== BRAND.FIRST_ALTERNATIVE && !hasFeatureFlag('isDDMigratedEnabled'): // DD migrated FA customers are not allowed to do MTAs and ESURE/SW DD migrated customers are allowed only in app06
      // case migrated && brand === BRAND.FIRST_ALTERNATIVE && !hasFeatureFlag('BDILMM-3257'): // migrated FirstALt  customers are not allowed to do MTAs
      //   openDialog({
      //     title: t('fetchAdviserPopup.details.title'),
      //     type: DIALOG_TYPE.FETCH_ADVISER_CHANGES,
      //   });
      //   break;
      case (remainingDays <= 32 && remainingDays >= 0 && paymentPlan !== 'YearlyCardPayment') ||
        (remainingDays <= 32 && remainingDays >= 0 && !hasFeatureFlag('renewalMtaPhase2Active')) ||
        (!hasPendingInstallments && isDDCustomer && hasFeatureFlag('DDInstallmentsCheck')):
        openDialog({
          title: t('fetchAdviserPopup.autoRenewalOnChatWithUs.title'),
          type: DIALOG_TYPE.FETCH_ADVISER_CHANGES_REN,
        });
        break;
      case futureMtaEffectiveDate === null:
        history.push(ROUTE.MAKE_CHANGE);
        break;
    }
  };
  return {
    dispatch: makeAChange,
  };
}
