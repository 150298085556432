import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';

import { useStores } from '../../../service/state/store';
import { sendJwtToIframe } from '../../../service/util/jwtUtils';

export const ClaimsApp: React.FC = observer(function ClaimsApp() {
  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected: autoPolicySelected },
        home: { policySelected: homePolicySelected },
        isHome,
      },
    },
    interfaceStore: { accessToken },
  } = useStores();

  const jwtPolicy = isHome ? homePolicySelected : autoPolicySelected;

  const getWindowHeight = () => {
    return (window.innerHeight - 265).toString();
  };
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(getWindowHeight());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <Iframe
        url={process.env.REACT_APP_CLAIM_DASHBOARD_URL ?? ''}
        id="claim-dashboard"
        title="claim-dashboard"
        width="100%"
        height={windowHeight}
        position="relative"
        frameBorder={0}
        onLoad={() => {
          sendJwtToIframe('claim-dashboard', accessToken, jwtPolicy);
        }}
      />
    </>
  );
});
