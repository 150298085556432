import { DateTime } from 'luxon';

const localStorageDate = localStorage.getItem('server_date_time_iso') ?? '';

export const LOCAL_TIMEZONE = 'Europe/London';

export const getDateTimeNow = (): DateTime => {
  if (localStorageDate === '') return DateTime.now();

  return DateTime.fromISO(localStorageDate);
};
