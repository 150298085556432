import { CommonContainer, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AmountSummary } from '../../../../../../component/common/amountSummary';
import { CommonButton } from '../../../../../../component/common/button/commonButton';
import { ConfirmationMessage } from '../../../../../../component/common/confirmationMessage';
import { IconListSection } from '../../../../../../component/common/iconListSection';
import { getExistingAddress } from '../../../../../../component/common/paymentTemplate';
import { COMPONENT_ID } from '../../../../../../service/analytics';
import { DIALOG_TYPE, ROUTE } from '../../../../../../service/constant';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

import CostSummary from './costSummary';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  button: {
    padding: spacing(0, 1),
  },
  root: {
    '& .MuiButton-root': {
      width: '100%',
    },
    '& .common-container': {
      backgroundColor: palette.secondary.main,
      borderColor: palette.secondary.main,
      [breakpoints.down('xs')]: {
        borderRadius: 0,
        margin: spacing(-4, -2, 0, -2),
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      color: palette.secondary.contrastText,
    },
    '& .monthly-payment-section': {
      '& .content': {
        '& .MuiDivider-root': {
          width: '100%',
        },
        '& p': {
          maxWidth: '75%',
        },
        gap: spacing(2),
        padding: spacing(2),
      },
      '& .header': {
        backgroundColor: `${palette.secondary.light}20`,
        borderBottom: `2px solid ${palette.secondary.light}`,
        padding: spacing(2),
      },
      border: `2px solid ${palette.secondary.light}`,
      borderRadius: spacing(2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    '& .title': {
      margin: spacing(5, 0),
    },
    gap: spacing(5),
  },
}));

export const RenewalSummary: React.FC = observer(function RenewalSummary() {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const {
    dashboardStore: {
      insurance: { auto, home, isHome, selectedPolicyType },
    },
    interfaceStore: {
      dialog: { openDialog, closeDialog },
      hasFeatureFlag,
    },
    renewalChangeStore: { getChangeMade, setNotGotQuote, setNextRoute, pendingMTAQuoteSelected },
    paymentStore: {
      isSubmitting: isPaymentSubmitting,
      paymentComplete,
      paymentError,
      renewalPayment,
      updatePolicyNumber,
      setIsHome,
      updateBillingAddress,
    },
  } = useStores();
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const policySelected = isHome ? home.policySelected : auto.policySelected;
  const { policyNumber } = policySelected;
  const changeMade = getChangeMade(policyNumber);

  const address = getExistingAddress(isHome, home.policySelected);
  const renewalRequirements: { content: string }[] = t('policyManagement.yourRenewalQuote.renewalRequirements', {
    returnObjects: true,
  });

  const isDDCustomer = policySelected.paymentPlan !== 'YearlyCardPayment';
  const renewal = policySelected.autoRenewal;

  useEffect(() => {
    if (typeof address !== 'string') {
      updateBillingAddress(address);
    }
  }, [address]);

  useEffect(() => {
    updatePolicyNumber(policyNumber);
  }, [policySelected]);

  useEffect(() => {
    setIsHome(isHome);
  }, [selectedPolicyType]);

  const handleClick = () => {
    if (!confirmed) {
      setIsSubmitting(true);
    } else if (!isDDCustomer) {
      history.push(ROUTE.RENEWAL_PAYMENT);
    } else {
      renewalPayment();
    }
  };

  const handleBack = () => {
    if (isHome) {
      history.push(ROUTE.RENEWAL_QUOTE_DETAILS);
    } else if (hasFeatureFlag('renewalMtaActive')) {
      if (changeMade) {
        history.push(ROUTE.YOUR_NEW_QUOTE);
      }
      if (!changeMade && pendingMTAQuoteSelected) {
        history.push(ROUTE.YOUR_SAVED_QUOTE);
      } else if (changeMade && pendingMTAQuoteSelected) {
        history.push(ROUTE.YOUR_NEW_QUOTE);
      } else {
        history.push(ROUTE.YOUR_COVER_DETAILS);
      }
    } else {
      history.push(ROUTE.YOUR_COVER_DETAILS);
    }
  };

  const { eventTrack } = useSegment();

  useEffect(() => {
    // resetting got quote in case we go back
    setNotGotQuote();
    // Set next route to empty
    setNextRoute('');
    const eventName = isHome
      ? 'Home Renewal - Renewal summary page shown - dd customer'
      : 'Motor Renewal - Renewal summary page shown - dd customer';

    eventTrack(eventName, {
      policyNumber,
      type: TRACK_TYPE.VIEW,
    });
  }, []);

  useEffect(() => {
    if (isPaymentSubmitting) {
      openDialog({ isFullScreen: true, type: DIALOG_TYPE.FETCH_QUOTE });
    } else {
      closeDialog();
    }
    if (paymentComplete) {
      history.push(ROUTE.RENEWAL_SETUP_SUCCESS);
    }
    if (paymentError) {
      openDialog({
        isFullScreen: true,
        type: DIALOG_TYPE.DD_RENEWAL_TECHNICAL_PROBLEM,
      });
    }
  }, [paymentComplete, paymentError, isPaymentSubmitting]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <CommonContainer className="common-container">
          <Typography variant="h3">{t('policyManagement.yourRenewalSummary.banner')}</Typography>
        </CommonContainer>
        <Typography className="title" variant="h1" color="secondary">
          {t('policyManagement.yourRenewalSummary.header')}
        </Typography>

        <AmountSummary type="renewalPayment" />
      </Grid>

      <CostSummary />

      <Grid container direction="column" wrap="nowrap">
        <Typography variant="h4">{t('policyManagement.yourRenewalSummary.readNote')}</Typography>
        <IconListSection className="requirements" color="success" icon="check" list={renewalRequirements} />
        <ConfirmationMessage
          hasError={!confirmed && isSubmitting}
          checked={confirmed}
          setChecked={setConfirmed}
          message={t('policyManagement.yourRenewalQuote.confirmationMessageRenewalDD')}
          isRenewalMessage={true}
        />
      </Grid>
      <Grid container direction="row" justifyContent="space-around">
        <Grid item className="button" xs={5}>
          <CommonButton
            text={t('buttons.goBack')}
            onClick={handleBack}
            size="large"
            color="secondary"
            colorIcon="black"
            variant="outlined"
            data-testid={COMPONENT_ID.RENEWAL_SUMMARY_BACK}
          />
        </Grid>

        {!renewal && !isDDCustomer ? (
          <Grid item className="button" xs={5}>
            <CommonButton
              onClick={handleClick}
              data-testid={COMPONENT_ID.RENEWAL_SUMMARY_CONTINUE_ANNUAL}
              size="large"
              variant="contained"
              color="primary"
              text={t('MTAJourney.button.paySecurely')}
            />
          </Grid>
        ) : (
          <Grid item className="button" xs={5}>
            <CommonButton
              onClick={handleClick}
              data-testid={COMPONENT_ID.RENEWAL_SUMMARY_CONTINUE_DD_AUTORENEW}
              size="large"
              variant="contained"
              color="primary"
              text={t('MTAJourney.button.confirm')}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
