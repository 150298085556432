import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOOKUP } from '../../../../../../../../service/constant';
import { ILookupItem } from '../../../../../../../../service/state/models/dashboard/lookups';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../components/editFooter';
import { MtaTooltip } from '../components/mtaTooltip';
import { useStepperContext } from '../utils/stepperContext';

import { EditPageProps, PageValues } from './reviewAll';

export const EditInsuredMaritalStatus: React.FC<EditPageProps> = observer(function EditInsuredMaritalStatus({
  handleBack,
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName },
    },
    mtaStore: {
      pendedMTA: { insured, setInsuredPerson },
    },
  } = useStores();
  const { setTitle } = useStepperContext();
  const { t } = useTranslation('myAccount');

  const [options, setOptions] = useState<ILookupItem[] | undefined>();
  const [isSubmitting] = useState<boolean>(false);

  const getPageVal: () => PageValues = () => {
    return { defaultOption: insured.person.maritalStatusCode, lookup: LOOKUP.MARITAL_STATUS };
  };
  const [value, setValue] = useState(getPageVal().defaultOption);
  const { isLookupsFetched } = useLookups({
    lookups: [getPageVal().lookup],
  });

  useEffect(() => {
    if (isLookupsFetched) {
      setOptions(lookupsByName[getPageVal().lookup]);
    }
  }, [isLookupsFetched, lookupsByName]);
  useEffect(() => {
    setTitle(t('MTAJourney.driverMaritalStatus.title'));
  }, []);
  const handleContinueOrSave = () => {
    setInsuredPerson({ ...insured.person, maritalStatusCode: value });
  };

  const handleSaveChanges = () => {
    handleContinueOrSave();
    handleBack();
  };

  return (
    <>
      {isLookupsFetched && (
        <>
          <MtaTooltip
            description={<Typography variant="body1">{t('MTAJourney.relationshipStatus.radioTitle')}</Typography>}
            tooltipTitle={t('tooltips.maritalStatus')}
          />

          <RadioGroup onChange={(event) => setValue(event.target.value)} value={value}>
            {options?.map((item, index) => {
              return (
                <FormControlLabel
                  key={`${item.code}-${index}`}
                  value={item.code}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
      {value === '' && isSubmitting && <FormHelperText error>{t('validations.noSelection')}</FormHelperText>}

      <EditFooter
        id="editInsuredMaritalStatus"
        validPage={true}
        handleBack={handleBack}
        handleSave={handleSaveChanges}
      />
    </>
  );
});
