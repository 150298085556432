import { Instance, cast, types } from 'mobx-state-tree';

import { DateTime, IDateTime } from '../../types/dateTime';
import { Driver, IDriver, IInsured, IModification, IVehicle, Insured, Vehicle } from '../dashboard/autoPolicyModel';

const string = types.optional(types.string, '');

export const DeviceCheck = types.model('DeviceCheck', {
  blackbox: string,
  brand: types.maybeNull(types.string),
});

export const PendedMTA = types
  .model('PendedMTA', {
    changedDrivers: types.optional(types.array(types.string), []),
    deviceCheck: types.optional(DeviceCheck, () => DeviceCheck.create()),
    drivers: types.optional(types.array(Driver), () => []),
    endorsementDate: types.optional(DateTime, null),
    endorsementReason: types.optional(types.string, ''),
    endorsementSubReasons: types.optional(types.array(types.string), []),
    insured: types.optional(Insured, () => Insured.create()),
    insuredChangedAddress: types.optional(types.boolean, false),
    insuredChangedOvernightAddr: types.optional(types.boolean, false),
    insuredChangedSameAddr: types.optional(types.boolean, false),
    isCarRegistrationManuallyChanged: types.optional(types.boolean, false),
    removedDrivers: types.optional(types.array(types.string), []),
    selectedDriverUUID: types.optional(types.string, ''),
    vehicle: types.optional(Vehicle, () => Vehicle.create()),
    vehicleFound: types.optional(types.boolean, false),
    vehicleLookUp: types.optional(Vehicle, () => Vehicle.create()),
  })
  .views((self) => ({
    driverFullName(driver: IDriver): string {
      return `${driver.person.firstName} ${driver.person.surname}`;
    },
    get selectedDriver(): IDriver {
      return self.drivers.find((driver) => driver.uuid === self.selectedDriverUUID) ?? Driver.create();
    },
    get vehicleDetails(): string {
      const { make, model } = self.vehicle;
      return `${make} ${model}`;
    },
  }))
  .actions((self) => ({
    getDriver(uuid: string) {
      const index = self.drivers.findIndex((driver) => driver.uuid === uuid);
      return self.drivers[index];
    },
    setChangedDrivers(value: string[]) {
      self.changedDrivers = cast(value);
    },
    setDriver(uuid: string, updatedDriver: IDriver) {
      const index = self.drivers.findIndex((driver) => driver.uuid === uuid);
      self.drivers[index] = updatedDriver;
    },
    setDrivers(drivers: IDriver[]) {
      self.drivers.replace(drivers);
    },
    setEndorsementDate(date: IDateTime) {
      self.endorsementDate = date;
    },
    setEndorsementReason(reason: string) {
      self.endorsementReason = reason;
    },
    setEndorsementSubReason(reason: string) {
      self.endorsementSubReasons.push(reason);
    },
    setExistingCarUsage(vehicle: IVehicle) {
      const {
        classOfUse,
        legalOwner,
        overnightLocation,
        registeredKeeper,
        daytimeLocation,
        overnightPostcode,
        sameOvernightAddress,
        businessMileageCode,
        socialMileageCode,
      } = vehicle;

      self.vehicle = {
        ...self.vehicle,
        businessMileageCode,
        classOfUse,
        daytimeLocation,
        legalOwner,
        overnightLocation,
        overnightPostcode,
        registeredKeeper,
        sameOvernightAddress,
        socialMileageCode,
      };
    },
    setHasModifications(value: boolean) {
      if (!value) {
        self.vehicle.modifications.replace([]);
      }
      self.vehicle.carModificationsInd = value;
    },
    setInsured(insured: IInsured) {
      self.insured = insured;
    },
    setInsuredAddress(address: IInsured['address']) {
      self.insured.address = address;
    },
    setInsuredChangedAddress(value: boolean) {
      self.insuredChangedAddress = value;
    },
    setInsuredChangedOvernightAddr(value: boolean) {
      self.insuredChangedOvernightAddr = value;
    },
    setInsuredChangedSameAddr(value: boolean) {
      self.insuredChangedSameAddr = value;
    },
    setInsuredPerson(person: IInsured['person']) {
      self.insured.person = person;
    },
    setIsCarRegistrationManuallyChanged(value: boolean) {
      self.isCarRegistrationManuallyChanged = value;
    },
    setModifications(value: IModification[]) {
      self.vehicle.modifications.replace(value);
    },
    setRemovedDrivers(value: string[]) {
      self.removedDrivers = cast(value);
    },
    setSelectedDriver(updatedDriver: IDriver) {
      const index = self.drivers.findIndex((driver) => driver.uuid === self.selectedDriverUUID);
      self.drivers[index] = updatedDriver;
    },
    setSelectedDriverUUID(uuid: string) {
      self.selectedDriverUUID = uuid;
    },
    setVehicle(vehicle: IVehicle) {
      self.vehicle = vehicle;
    },
    setVehicleFound(isVehicleFound: boolean) {
      self.vehicleFound = isVehicleFound;
    },
    setVehicleLookUp(vehicle: IVehicle) {
      self.vehicleLookUp = vehicle;
    },
    setVrm(vrm: string) {
      self.vehicle.vrm = vrm;
    },
  }));

export type IPendedMTA = Instance<typeof PendedMTA>;
export type IDeviceCheck = Instance<typeof DeviceCheck>;
