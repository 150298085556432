import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JOURNEY_TYPE } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { getDateTimeNow } from '../../service/util/dateUtils';
import { useStepperContext } from '../../view/pages/policies/policy/makeAchange/mta/common/utils/stepperContext';

export type ContainerType = 'payment' | 'renewalPayment' | 'annualCustomer' | 'ddCustomer';

export interface Info {
  cost: string;
  costDescription: string;
  description: string;
  monthlyCostDescription?: string;
  title?: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .dd-container': {
      color: palette.grey[700],
    },
    background: `${palette.grey.A100}50`,
    borderRadius: spacing(1),
    gap: spacing(1),
    padding: spacing(2),
  },
}));

export const AmountSummary: React.FC<{ type: ContainerType }> = observer(function AmountSummary({ type }) {
  const classes = useStyles();
  const [info, setInfo] = useState<Info>({ cost: '', costDescription: '', description: '' });
  const { t } = useTranslation('myAccount');
  const { stepsData } = useStepperContext();
  const {
    dashboardStore: {
      insurance: { home, auto, isHome },
    },
    mtaStore: {
      quote: {
        additionalPremium,
        administrationFee,
        paymentPlan: { newMonthlyInstallment, currentMonthlyInstallment },
      },
      pendedMTA: {
        vehicle: { purchaseDate },
      },
    },
    renewalChangeStore: { pendingMTAQuoteSelected },
  } = useStores();

  let currentYearTotalCost: number;
  if (isHome) {
    currentYearTotalCost = home.policySelected.policyRenewalQuoteDetails.currentYearTotalCost;
  } else if (pendingMTAQuoteSelected && auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote) {
    currentYearTotalCost = auto.policySelected.policyRenewalQuoteDetails.pendingMTAQuote.currentYearTotalCost;
  } else {
    currentYearTotalCost = auto.policySelected.policyRenewalQuoteDetails.currentYearTotalCost;
  }

  const totalAmount = additionalPremium + administrationFee;

  const isCarOwned =
    stepsData.journeyType === JOURNEY_TYPE.CHANGE_YOUR_CAR
      ? purchaseDate && DateTime.fromISO(purchaseDate) <= getDateTimeNow()
      : true;

  const getSummaryInfo = () => {
    switch (type) {
      case 'payment':
        setInfo({
          ...info,
          cost: t('core:price.pound', { value: Math.abs(additionalPremium + administrationFee) }),
          costDescription: t('MTAJourney.payment.total'),
          description: t('MTAJourney.payment.summary'),
        });
        break;
      case 'renewalPayment':
        setInfo({
          ...info,
          cost: t('core:price.pound', { value: Math.abs(currentYearTotalCost) }),
          costDescription: t('MTAJourney.payment.total'),
          description: t('MTAJourney.payment.noAdminFeesSummary'),
        });
        break;
      case 'annualCustomer':
        setInfo({
          ...info,
          cost: t('core:price.pound', { value: Math.abs(additionalPremium + administrationFee) }),
          costDescription:
            totalAmount >= 0 ? t('MTAJourney.QuoteReady.totalCost') : t('MTAJourney.QuoteReady.refundAmount'),
          description:
            totalAmount !== 0 ? t('MTAJourney.QuoteReady.costDescription') : t('MTAJourney.QuoteReady.noCharges'),
        });
        break;
      case 'ddCustomer':
        setInfo({
          ...info,
          cost: t('core:price.pound', {
            value: Math.abs(totalAmount !== 0 ? newMonthlyInstallment : currentMonthlyInstallment),
          }),
          costDescription:
            totalAmount !== 0
              ? t('MTAJourney.QuoteReady.DDCustomer.charge.summaryAmount.costDescription')
              : t('MTAJourney.QuoteReady.DDCustomer.noCharge.summaryAmount.costDescription'),
          description: t('MTAJourney.QuoteReady.DDCustomer.charge.summaryAmount.description'),
          monthlyCostDescription:
            totalAmount !== 0 || !isCarOwned
              ? t('MTAJourney.QuoteReady.DDCustomer.charge.summaryAmount.monthlyCostDescription')
              : '',
          title:
            totalAmount === 0 || !isCarOwned ? t('MTAJourney.QuoteReady.DDCustomer.noCharge.summaryAmount.title') : '',
        });
        break;
    }
  };

  useEffect(() => {
    getSummaryInfo();
  }, [additionalPremium, type]);

  const { costDescription, cost, description, monthlyCostDescription, title } = info;

  return (
    <Grid container className={classes.root} direction="column">
      {title !== '' && <Typography variant="h3">{title}</Typography>}

      <Typography variant="h3">{costDescription}</Typography>

      <Typography variant="h2">{cost}</Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {monthlyCostDescription !== '' && monthlyCostDescription !== undefined && (
        <Grid item container justifyContent="space-between" className="dd-container">
          <Typography variant="h3">{monthlyCostDescription}</Typography>
          <Typography variant="h3"> {t('core:price.pound', { value: currentMonthlyInstallment })}</Typography>
        </Grid>
      )}
    </Grid>
  );
});
