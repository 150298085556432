import { Grid, IconButton, List, ListItem, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import IconSure from '../../../../../../component/common/icon';
import { downloadDocument } from '../../../../../../component/common/utils/documentds';
import { useStores } from '../../../../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../../../../service/util/customHooks/useSegment';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& .item': {
      alignItems: 'flex-start',
      borderBottom: `1px solid ${palette.secondary.dark}40`,
      display: 'flex',
      gap: spacing(1),
      justifyContent: 'space-between',
      padding: 0,
      paddingBottom: spacing(2),
    },
    '& .right__icon': {
      justifyContent: 'flex-end',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const RenewalDocuments: React.FC = observer(() => {
  const { t } = useTranslation('myAccount');
  const classes = useStyles();

  const {
    interfaceStore: { notification, isCaura },
    dashboardStore: {
      insurance: { auto, home, isHome },
    },
  } = useStores();

  const { eventTrack } = useSegment();
  const { getDocumentsHeader } = useStores();
  const policySelected = isHome ? home.policySelected : auto.policySelected;
  const { renewalDocuments, policyNumber } = policySelected;

  // find motor renewal letter invite and move to front of array
  const sortedDocs = [...renewalDocuments];
  const documentIdx = sortedDocs.findIndex((document) => document.documentTypeCode === 'MOTOR_RENEWAL_LETTER_INVITE');
  if (documentIdx !== -1) {
    sortedDocs.unshift(...sortedDocs.splice(documentIdx, 1));
  }

  const handleClick = (item: typeof sortedDocs[number]) => {
    downloadDocument({
      documentId: item.documentId,
      eventTrack: eventTrack,
      header: getDocumentsHeader(),
      headerText: item.fileName,
      isCaura,
      onFail: () => {
        notification.openNotification({
          message: t('downloadFailed.content'),
          severity: 'error',
        });
      },
      optimizelyTrackKey: `My_Account_${item.fileName}_click`,
      policyNumber: policyNumber,
    });

    eventTrack(`${isHome ? 'Home Rnwl | ' : 'Renewal'} ${item.fileName} downloaded`, {
      policyNumber,
      position: `Renewal ${item.fileName} downloaded`,
      type: TRACK_TYPE.DOWNLOAD,
    });
  };

  const isRenderingDocument = (fileName: string) => {
    if (isHome) {
      return fileName !== t('policyManagement.yourRenewal.scheduleOfInsurance');
    } else {
      return fileName !== t('policyManagement.yourRenewal.renewalUpdate');
    }
  };

  return (
    <List className={classes.root} data-testid="renewal-documents-container">
      {sortedDocs.map(
        (item, index) =>
          isRenderingDocument(item.fileName) && (
            <ListItem key={index} className="item">
              <IconSure icon="document" color="secondary" type="line" />
              <Grid container>
                <Grid item>
                  <Typography variant="h4">{item.fileName}</Typography>
                  <Typography variant="body1">{item.description}</Typography>
                </Grid>
              </Grid>
              <IconButton
                className="right__icon"
                size="small"
                aria-label="download"
                data-testid="downloadRenewalDocumentButton"
                onClick={() => handleClick(item)}
              >
                <IconSure icon="download-2" size="20" color="#2663DA" />
              </IconButton>
            </ListItem>
          ),
      )}
    </List>
  );
});
