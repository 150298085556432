import { Divider, Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { Trans, useTranslation } from 'react-i18next';

import BasicAccordion from '../../../../../../../../component/common/accordion/basicAccordion';
import { useStores } from '../../../../../../../../service/state/store';
import RenewalCostCard from '../../../../renewals/components/renewalCostCard';

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    margin: spacing(2, 0, 2, 0),
  },
  gridSpacing: {
    gap: spacing(2),
  },
}));

export const UpdatedRenewalQuote: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: {
        auto: { policySelected },
      },
    },
  } = useStores();

  const {
    policyRenewalQuoteDetails: {
      currentYearMonthlyCost,
      currentYearTotalCost,
      pendingMTAQuote,
      previousYearMonthlyCost,
      previousYearTotalCost,
    },
  } = policySelected;

  const isDDCustomer = policySelected.paymentPlan !== '' && policySelected.paymentPlan !== 'YearlyCardPayment';
  const monthlyCost = t('MTAJourney.QuoteReady.updatedRenewalQuote.monthlyCost', {
    value: t('core:price.pound', {
      value: currentYearMonthlyCost,
    }),
  });
  const totalCost = t('MTAJourney.QuoteReady.updatedRenewalQuote.totalCost', {
    value: t('core:price.pound', {
      value: currentYearMonthlyCost,
    }),
  });

  return (
    <BasicAccordion title={t('MTAJourney.QuoteReady.updatedRenewalQuote.title')}>
      <Grid>
        <Grid container item className={classes.gridSpacing}>
          {pendingMTAQuote && (
            <RenewalCostCard
              isPrimary
              title={t('MTAJourney.QuoteReady.updatedRenewalQuote.newRenewalQuote')}
              value={t('core:price.pound', {
                value: pendingMTAQuote.currentYearTotalCost,
              })}
              showDD={isDDCustomer}
              ddValue={t('core:price.pound', {
                value: pendingMTAQuote.currentYearMonthlyCost,
              })}
            />
          )}

          <RenewalCostCard
            hasTooltip={true}
            isPrimary={true}
            title={t('MTAJourney.QuoteReady.updatedRenewalQuote.costLastYear')}
            value={t('core:price.pound', {
              value: previousYearTotalCost,
            })}
            showDD={isDDCustomer}
            ddValue={t('core:price.pound', {
              value: previousYearMonthlyCost,
            })}
            tooltipTitle={t('MTAJourney.QuoteReady.updatedRenewalQuote.tooltip')}
          />
        </Grid>
        <Divider orientation="horizontal" className={classes.divider} />
        <Grid container className={classes.gridSpacing}>
          <Grid item>
            <Typography variant="body1">{t('MTAJourney.QuoteReady.updatedRenewalQuote.description')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">{t('MTAJourney.QuoteReady.updatedRenewalQuote.currentRenewalQuote')}</Typography>
            {!isDDCustomer && (
              <Typography variant="h4">
                {t('core:price.pound', {
                  value: currentYearTotalCost,
                })}
              </Typography>
            )}
            {isDDCustomer && (
              <>
                <Typography variant="body1">
                  <Trans i18nKey={monthlyCost} t={t} components={[<strong key={Symbol().toString()} />]} />
                </Typography>
                <Typography variant="body1">
                  <Trans i18nKey={totalCost} t={t} components={[<strong key={Symbol().toString()} />]} />
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BasicAccordion>
  );
};
