import { Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { THEME_NAME } from '../../service/constant';

const useStyles = makeStyles((theme) => ({
  navLink: {
    background:
      theme.themeName === THEME_NAME.SHEILASWHEELS || theme.themeName === THEME_NAME.FIRSTALTERNATIVE
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    display: 'flex',
    padding: theme.spacing(2.5, 0),
    textDecoration: 'none',
    width: '100%',
  },
  typographyFont: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      paddingLeft: theme.spacing(4),
    },
    color: theme.palette.secondary.contrastText,
  },
}));

export interface OffersNavlinkProps {
  isMobile: boolean;
  onClick?: () => void;
}

export const OffersNavlink: React.FC<OffersNavlinkProps> = observer(function ProposalBanners(props) {
  const classes = useStyles();

  const onOffersClick = () => {
    if (props.isMobile && props.onClick) {
      props.onClick();
    }
    window.braze?.showContentCards(null, (cards) => cards.filter((card) => card.extras && card.extras.loc === 'feed'));

    setTimeout(() => {
      const anchorTags = document.querySelectorAll('.ab-card-body a');
      anchorTags.forEach((anchor) => {
        const clonedAnchor = anchor.cloneNode(true) as HTMLAnchorElement;
        clonedAnchor.setAttribute('target', '_blank');
        anchor.parentNode?.replaceChild(clonedAnchor, anchor);
      });
    }, 500);
  };

  if (!window.braze) {
    return null;
  }

  const featureFlag = window.braze.getFeatureFlag('notificationsFeed');
  window.braze.logFeatureFlagImpression('notificationsFeed');
  if (!featureFlag?.enabled) {
    return null;
  }

  return (
    <NavLink
      data-testid="offers-navlink"
      activeClassName="active"
      className={classes.navLink}
      key="route-offers"
      to="#"
      onClick={onOffersClick}
    >
      <Typography className={classes.typographyFont}>Offers</Typography>
    </NavLink>
  );
});
