import { Autocomplete, Grid, TextField, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Choice } from '../../../../../../../../component/common/choice';
import { LOOKUP } from '../../../../../../../../service/constant';
import { useStores } from '../../../../../../../../service/state/store';
import { useLookups } from '../../../../../../../../service/util/customHooks/useLookups';
import { EditFooter } from '../../common/components/editFooter';
import { MtaTooltip } from '../../common/components/mtaTooltip';
import { useStepperContext } from '../../common/utils/stepperContext';

import { EditPageProps } from './reviewAll';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& .MuiAutocomplete-clearIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiAutocomplete-inputRoot': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.secondary.main,
      },
    },
    '& .MuiAutocomplete-noOptions': {
      color: palette.error.dark,
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: palette.secondary.main,
    },
    '& .MuiFormLabel-root': {
      color: palette.common.black,
    },
    gap: 25,
    marginBottom: spacing(4),
  },
}));

export const EditDriverOccupation: React.FC<EditPageProps> = observer(function EditDriverOccupation({
  handleBack,
  uuid,
}) {
  const {
    dashboardStore: {
      lookups: { lookupsByName, getLookupValue, getLookupCode },
    },
    mtaStore: {
      pendedMTA: { setDriver, getDriver },
    },
  } = useStores();
  const { t } = useTranslation('myAccount');
  const classes = useStyles();
  const { setTitle } = useStepperContext();
  const [occupationList, setOccupationList] = useState<string[]>();
  const [industryList, setIndustryList] = useState<string[]>();
  const [employmentList, setEmploymentList] = useState<string[]>();
  const driver = getDriver(uuid ?? '');

  const [personState, setPersonState] = useState(driver.person);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    hasSecondaryOccupation,
    primaryOccupationCode,
    primaryOccupationIndustryCode,
    secondaryOccupationCode,
    secondaryOccupationIndustryCode,
    employmentStatusCode,
  } = personState;

  /* istanbul ignore next */
  const isNotValid = () => {
    return (
      employmentStatusCode === null ||
      primaryOccupationIndustryCode === null ||
      primaryOccupationCode === null ||
      hasSecondaryOccupation === null ||
      (hasSecondaryOccupation && secondaryOccupationCode === null) ||
      (hasSecondaryOccupation && secondaryOccupationIndustryCode === null)
    );
  };
  const handlePendedMta = () => {
    setIsSubmitting(true);
    !isNotValid() &&
      setDriver(uuid ?? '', {
        ...driver,
        person: {
          ...driver.person,
          employmentStatusCode,
          hasSecondaryOccupation,
          primaryOccupationCode,
          primaryOccupationIndustryCode,
          ...(hasSecondaryOccupation
            ? {
                secondaryOccupationCode,
                secondaryOccupationIndustryCode,
              }
            : {
                secondaryOccupationCode: null,
                secondaryOccupationIndustryCode: null,
              }),
        },
      });
  };

  const handleSave = () => {
    handlePendedMta();
    handleBack();
  };

  const handleBackClick = () => {
    handleBack();
  };

  const { isLookupsFetched } = useLookups({
    lookups: [LOOKUP.INDUSTRY, LOOKUP.OCCUPATION, LOOKUP.EMPLOYMENT],
  });

  useEffect(() => {
    setTitle(t('MTAJourney.driverOccupation.title'));
  }, []);

  useEffect(() => {
    if (
      isLookupsFetched &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (lookupsByName[LOOKUP.INDUSTRY] && lookupsByName[LOOKUP.EMPLOYMENT] && lookupsByName[LOOKUP.OCCUPATION]) !==
        undefined
    ) {
      setOccupationList(lookupsByName[LOOKUP.OCCUPATION].map((item) => item.value));
      setIndustryList(lookupsByName[LOOKUP.INDUSTRY].map((item) => item.value));
      setEmploymentList(lookupsByName[LOOKUP.EMPLOYMENT].map((item) => item.value));
    }
  }, [isLookupsFetched, lookupsByName]);

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        {employmentList?.length !== undefined && employmentList.length > 0 && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={employmentList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.employmentNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.employmentStatusLabel')}
                  error={isSubmitting && employmentStatusCode === null}
                  helperText={isSubmitting && employmentStatusCode === null && t('validations.selectEmployment')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                /* istanbul ignore next */
                setPersonState({
                  ...personState,
                  employmentStatusCode: value ? getLookupCode(LOOKUP.EMPLOYMENT, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.EMPLOYMENT, employmentStatusCode ?? '') || null}
            />
          </Grid>
        )}

        {occupationList?.length !== undefined && occupationList.length > 0 && (
          <Grid item xs={12} md={4}>
            <MtaTooltip
              tooltipTitle={t('tooltips.occupation')}
              description={t('MTAJourney.driverOccupation.occupationLabel')}
            />
            <Autocomplete
              disablePortal
              options={occupationList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.occupationNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={isSubmitting && primaryOccupationCode === null}
                  helperText={isSubmitting && primaryOccupationCode === null && t('validations.selectOccupation')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  primaryOccupationCode: value ? getLookupCode(LOOKUP.OCCUPATION, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.OCCUPATION, primaryOccupationCode ?? '') || null}
            />
          </Grid>
        )}

        {industryList?.length !== undefined && industryList.length > 0 && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={industryList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.industryNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.industryLabel')}
                  error={isSubmitting && primaryOccupationIndustryCode === null}
                  helperText={isSubmitting && primaryOccupationIndustryCode === null && t('validations.selectIndustry')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  primaryOccupationIndustryCode: value ? getLookupCode(LOOKUP.INDUSTRY, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.INDUSTRY, primaryOccupationIndustryCode ?? '') || null}
            />
          </Grid>
        )}

        <Typography>{t('MTAJourney.driverOccupation.secondaryOccupation')}</Typography>

        <Choice
          identifier="secondaryOccupationSelector"
          onChange={
            /* istanbul ignore next */ (event) =>
              setPersonState({ ...personState, hasSecondaryOccupation: event.target.value === 'true' })
          }
          left={{ label: t('labels.no') }}
          right={{ label: t('labels.yes') }}
          radioValue={hasSecondaryOccupation}
          error={isSubmitting && hasSecondaryOccupation === null}
        />

        {occupationList?.length !== undefined && occupationList.length > 0 && hasSecondaryOccupation && (
          /* istanbul ignore next */
          <Grid item xs={12} md={4}>
            <MtaTooltip
              tooltipTitle={t('tooltips.secondaryOccupation')}
              description={t('MTAJourney.driverOccupation.occupationLabel')}
            />
            <Autocomplete
              disablePortal
              options={occupationList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.occupationNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  error={isSubmitting && hasSecondaryOccupation && secondaryOccupationCode === null}
                  helperText={isSubmitting && secondaryOccupationCode === null && t('validations.selectOccupation')}
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  secondaryOccupationCode: value ? getLookupCode(LOOKUP.OCCUPATION, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.OCCUPATION, secondaryOccupationCode ?? '') || null}
            />
          </Grid>
        )}

        {industryList?.length !== undefined && industryList.length > 0 && hasSecondaryOccupation && (
          /* istanbul ignore next */
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              options={industryList}
              getOptionLabel={(item) => item}
              getOptionSelected={(option, value) => option === value}
              noOptionsText={t('validations.industryNotFound')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MTAJourney.driverOccupation.industryLabel')}
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  error={isSubmitting && hasSecondaryOccupation && secondaryOccupationIndustryCode === null}
                  helperText={
                    isSubmitting && secondaryOccupationIndustryCode === null && t('validations.selectIndustry')
                  }
                  placeholder={t('placeholders.typeLetter')}
                />
              )}
              onChange={(event, value: string | null) => {
                setPersonState({
                  ...personState,
                  secondaryOccupationIndustryCode: value ? getLookupCode(LOOKUP.INDUSTRY, value) : null,
                });
              }}
              value={getLookupValue(LOOKUP.INDUSTRY, secondaryOccupationIndustryCode ?? '') || null}
            />
          </Grid>
        )}
      </Grid>

      <EditFooter
        id="editDriverOccupation"
        validPage={!isNotValid()}
        handleSave={handleSave}
        handleBack={handleBackClick}
      />
    </>
  );
});
