import jwtDecode from 'jwt-decode';

import { IAutoPolicy } from '../state/models/dashboard/autoPolicyModel';
import { IHomePolicy } from '../state/models/dashboard/homePolicyModel';

export interface DecodedToken {
  eisCustomerId?: string;
  email: string;
  exp?: number;
}

export interface Token {
  customerEmail: string;
  customerId: string;
  expireTime: number;
}

export const decodeToken = (token: string): Token => {
  const decoded: DecodedToken = jwtDecode<DecodedToken>(token);

  return {
    customerEmail: decoded.email,
    customerId: decoded.eisCustomerId ?? '',
    expireTime: decoded.exp ?? 0,
  };
};

export const sendJwtToIframe = (
  iframeId: string,
  accessToken: string,
  policySelected?: IAutoPolicy | IHomePolicy,
): void => {
  const claimsDashIframeContent = (document.getElementById(iframeId) as HTMLIFrameElement).contentWindow;

  if (claimsDashIframeContent) {
    claimsDashIframeContent.postMessage(
      { jwt: accessToken, selectPolicy: JSON.stringify(policySelected) },
      `${process.env.REACT_APP_CLAIM_DASHBOARD_URL ?? ''}`,
    );
  }
};
