import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SVGWrapper } from '../../../images/SVGWrapper';
import { track } from '../../../lib/optimizely';
import { JOURNEY_TYPE, ROUTE } from '../../../service/constant';
import { useStores } from '../../../service/state/store';
import { TRACK_TYPE, useSegment } from '../../../service/util/customHooks/useSegment';
import { useStepperContext } from '../../../view/pages/policies/policy/makeAchange/mta/common/utils/stepperContext';
import { CommonButton } from '../../common/button/commonButton';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .container': {
      '&-text': {
        maxWidth: '300px',
      },
      alignItems: 'center',
      gap: spacing(4),
    },
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
}));

export const MTATechnicalErrorDialog: React.FC = () => {
  const classes = useStyles();
  const { eventTrack } = useSegment();
  const { t } = useTranslation('myAccount');

  const {
    interfaceStore: {
      dialog: { closeDialog },
    },
  } = useStores();
  const {
    step,
    stepsData: { journeyType },
  } = useStepperContext();

  const handleClick = () => {
    track('Back_To_My_Policy_button_click');
    eventTrack('Back To My Policy Button Clicked', {
      position: `${journeyType} - Step ${step + 1} MTA Technical Problems`,
      type: TRACK_TYPE.CTA,
    });
    closeDialog();
  };

  useEffect(() => {
    if (journeyType === JOURNEY_TYPE.CHANGE_AUTO_RENEWAL) {
      eventTrack('Renewal | Auto renew opt out Fail Technical Problems Viewed', {
        journeyType,
        type: TRACK_TYPE.VIEW,
      });
    } else {
      eventTrack('Get a quote tech error viewed', {
        journeyType,
        type: TRACK_TYPE.VIEW,
      });
    }
  }, []);

  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.root}>
      <Grid container item className="container" alignItems="center" direction="column">
        <SVGWrapper alt="technical-error" src="technical-error.svg" width="25%" />
        <Typography variant="h2" align="center" className="container-text">
          {t('MTAFailedPopup.title')}
        </Typography>
        <Typography variant="body1">{t('MTAFailedPopup.description')}</Typography>
        <CommonButton
          url={ROUTE.POLICY_DETAILS}
          text={t('MTAFailedPopup.button')}
          onClick={handleClick}
          startIcon="arrow-left"
          size="large"
        />
      </Grid>
    </Grid>
  );
};
