import { format } from 'date-fns';

import { DATE_TYPES } from '../constant';

export type DateDisplayType = DATE_TYPES;
export const formatDate = (input: string | null | undefined, type: DateDisplayType): string => {
  if (input && typeof input !== 'undefined' && input !== 'Invalid DateTime') {
    switch (type) {
      case DATE_TYPES.LONG:
        return format(new Date(input), 'dd LLLL yyyy');
      case DATE_TYPES.LONG_TIME:
        return format(new Date(input), 'dd LLLL yyyy HH:mm:ss');
      case DATE_TYPES.MINI:
        return format(new Date(input), 'dd LLL yy');
      case DATE_TYPES.MINI_YEAR_LINE:
        return format(new Date(input), 'dd-LL-yy');
      case DATE_TYPES.LONG_YEAR_LINE:
        return format(new Date(input), 'dd-LL-yyyy');
      case DATE_TYPES.MONTH_YEAR:
        return format(new Date(input), 'LLLL yyyy');
      case DATE_TYPES.SHORT:
        return format(new Date(input), 'dd/LL/yyyy');
      case DATE_TYPES.SHORT_SPACE:
        return format(new Date(input), 'dd LLL yyyy');
      case DATE_TYPES.SHORT_SLASH_MONTH_YEAR:
        return format(new Date(input), 'MM/yyyy');
      case DATE_TYPES.SHORT_TIME:
        return format(new Date(input), 'dd LLL yyyy HH:mm');
      case DATE_TYPES.SHORT_TIME_WITH_COMMA:
        return format(new Date(input), 'dd LLL yyyy, HH:mm');
      case DATE_TYPES.WEEKDAY_LONG:
        return format(new Date(input), 'EEE, dd MMM yyyy');
      case DATE_TYPES.WEEKDAY_SHORT:
        return format(new Date(input), 'dd MMM yyyy');
      case DATE_TYPES.MONTH:
        return format(new Date(input), 'LLL');
    }
  } else {
    return '';
  }
};
