import { Grid, Typography, makeStyles } from '@esure-cloud/react-components';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { PACKS } from '../../service/constant';
import { useStores } from '../../service/state/store';
import { LifestylePacksCard } from '../common/lifestylePacksCard';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(5),
  },
}));

interface AvailablePacks {
  description: string;
  title: string;
}

export const availablePacks: Record<string, AvailablePacks[]> = {
  [PACKS.SAFETY_NET]: [
    {
      description: 'Cover to replace your car keys if they’re lost, damaged or stolen.',
      title: 'Lost, damaged or stolen car keys',
    },
    {
      description:
        'Gives you additional financial benefit if you or your named driver are in an accident where you are deemed to be at fault and are injured. for life.',
      title: 'Enhanced personal accident benefit',
    },
  ],
  [PACKS.LONG_DISTANCE_TRAVELLER]: [
    {
      description:
        'Cover to repair or replace belongings that are damaged in an accident or stolen from your car.This excludes cash, as well as bank and credit cards.',
      title: 'Personal belongings',
    },
    {
      description:
        "If you’re in an accident away from home and your car is not safe to drive, we'll pay for you and your passengers to stay overnight in a hotel.",
      title: 'Overnight accommodation',
    },
  ],
  [PACKS.FOREIGN_TRAVELLER]: [
    { description: 'Full comprehensive cover wherever you drive in Europe.', title: 'Full comprehensive cover' },
    {
      description: "European hotel accommodation if something happens away from where you're staying.",
      title: 'Hotel accommodation',
    },
  ],
};

export const LifestylePacks: React.FC = observer(function LifestylePacks() {
  const classes = useStyles();
  const { t } = useTranslation('myAccount');

  const {
    dashboardStore: {
      insurance: {
        auto: {
          policyCover: { lifeStylePacks },
        },
      },
    },
  } = useStores();

  return lifeStylePacks.length > 0 ? (
    <Grid container direction="column" spacing={2} className={classes.gridContainer} alignItems="stretch">
      <Grid container item direction="column" alignItems="center">
        <Typography variant="h1">{t('lifestylePacks.title')}</Typography>
        <Typography variant="body1">{t('lifestylePacks.subtitle')}</Typography>
      </Grid>
      <Grid item container direction="row" alignItems="stretch" spacing={2}>
        {lifeStylePacks.map((item) => (
          <Grid item xs={12} sm={4} key={item.coverageCd}>
            <LifestylePacksCard pack={item} subCoverages={availablePacks[item.coverageCd].map((x) => x.title)} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : null;
});
